import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Container from './Container';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const FilterBlock = ({ categories, subCategories, match }) => {
  const [selectedSection, setSelectedSection] = useState(false);
  const [selectedTypology, setSelectedTypology] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const event = new CustomEvent(
        'bodyListFilterEvent', {
          detail: { type: 'category', value: selectedSection },
        },
      );
      window.dispatchEvent(event);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const event = new CustomEvent(
        'bodyListFilterEvent', {
          detail: { type: 'typology', value: selectedTypology },
        },
      );
      window.dispatchEvent(event);
    }
  }, [selectedTypology]);

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <Container className="flex items-start justify-between font-stratos font-semibold text-base mt-9 mb-0 tablet:mt-10 laptop:mt-14 laptop:mb-10 desktop:mt-16 desktop:mb-10">
            <div key="left" className="flex items-center justify-start laptop:w-1/2 flex-wrap">
              {categories.items && categories.items.map((item, index) => {
                const title = translateObj(item.title, localization.language);
                return (
                  <div
                    key={title + index}
                    className={`laptop:pt-1 mr-2 laptop:mr-3 mb-5 desktop:mb-6 trans border-t-4 cursor-pointer ${selectedSection === item.slugName ? 'border-red text-red' : 'border-black text-black hover:border-red hover:text-red'}`}
                    style={{ minWidth: '79px' }}
                    onClick={() => setSelectedSection(item.slugName)}
                  >
                    {title}
                  </div>
                );
              })}
            </div>
            <div key="right" className="text-red hidden laptop:block laptop:-mt-6 desktop:-mt-27px pt-2px -mr-1">
              <div className="flex items-stretch justify-end flex-wrap">
                {subCategories.items.map((item, index) => {
                  const title = translateObj(item.title, localization.language);
                  const block = (
                    <div
                      className={`p-2px border-3 border-red ${selectedTypology === item.slugName ? 'bg-red text-white' : 'bg-white'} mb-1 ml-10px trans cursor-pointer hover:bg-red hover:text-white text-center text-sm`}
                      style={{ minWidth: '79px' }}
                      onClick={() => setSelectedTypology(item.slugName)}
                    >
                      {title}
                    </div>
                  );

                  if (index === 0) {
                    return (
                      <div
                        key={title + index}
                        className="flex flex-col items-stretch justify-start"
                      >
                        <span className="block text-12 mb-2 ml-3">
                          {translateObj(subCategories.title, localization.language)}
                        </span>
                        {block}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={title + index}
                      className="flex flex-col items-stretch justify-end"
                    >
                      {block}
                    </div>
                  );
                })}
              </div>
            </div>
          </Container>
        );
      }}
    </RoutesContext.Consumer>
  );
};


export default FilterBlock;

FilterBlock.propTypes = {
  categories: PropTypes.shape(),
  subCategories: PropTypes.shape(),
  match: PropTypes.shape(),
};

FilterBlock.defaultProps = {
  categories: {},
  subCategories: {},
  match: {},
};
