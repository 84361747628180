/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import translateObj from '../../util/translateObj';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import isSectionWithSubPost from '../../util/isSectionWithSubPost';

const getWindowDimensions = () => {
  if (typeof window === 'undefined') return { width: 0 };
  const { innerWidth: width } = window;
  return {
    width,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
};

const Navigator = ({
  match,
  match: { params, path },
  url,
  position,
  title,
  section,
}) => {
  const navigatorStyles = position === 'absolute' ? { top: '16px' } : {};
  let localization = '';
  const { width } = useWindowDimensions();
  const classes = position === 'static' ? 'pt-4' : '';
  // TODO: rethink this location only gives the url in english,
  // we will need to find a way to translate it, maybe using keys and and translations???
  let sectionTitle = {};
  // Set tranlated title in news sub pages
  if (params.section === 'news') {
    sectionTitle = {
      en: 'news',
      es: 'noticias',
      ca: 'notícies',
    };
  }
  if (params.section === 'los-angeles') {
    sectionTitle = {
      en: 'los angeles',
      es: 'los angeles',
      ca: 'los angeles',
    };
  }
  if (params.section === 'primavera-pro') {
    sectionTitle = {
      en: 'primavera pro',
      es: 'primavera pro',
      ca: 'primavera pro',
    };
  }
  if (params.section === 'relive-primavera') {
    sectionTitle = {
      en: 'relive primavera',
      es: 'relive primavera',
      ca: 'relive primavera',
    };
  }

  const truncateString = (string) => {
    const stringLength = translateObj(string, localization.language).length;
    let trail = translateObj(string, localization.language);
    if (width >= 640 && width <= 992 && stringLength > 25) {
      trail = trail.substring(0, 25);
      if (trail.indexOf(' ') > -1) {
        trail = trail.substr(0, Math.min(trail.length, trail.lastIndexOf(' ')));
      }
      return `${trail}...`;
    }
    if (width < 640 && width >= 420 && stringLength > 12) {
      trail = trail.substring(0, 12);
      if (trail.indexOf(' ') > -1) {
        trail = trail.substr(0, Math.min(trail.length, trail.lastIndexOf(' ')));
      }
      return `${trail}...`;
    }
    if (width < 420 && stringLength > 7) {
      if (params.section === 'los-angeles') {
        trail = trail.substring(0, 7);
        if (trail.indexOf(' ') > -1) {
          trail = trail.substr(
            0,
            Math.min(trail.length, trail.lastIndexOf(' ')),
          );
        }
        return `${trail}...`;
      }
      trail = trail.substring(0, 7);
      if (trail.indexOf(' ') > -1) {
        trail = trail.substr(0, Math.min(trail.length, trail.lastIndexOf(' ')));
      }
      return `${trail}...`;
    }
    return trail;
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        localization = getLocalization(contextLanguage, enteredFrom, match);
        const { params: { section = '' } = { section: '' } } = match;
        const hasOnPsProOrOnPsLifeSection = Boolean(section && (section === 'pro-online' || section === 'pslife'));

        // return (
        //   <div
        //     className={`flex ${position} ${classes} z-radio w-full`}
        //     style={navigatorStyles}
        //   >
        //     <div className="bg-black text-white text-sm sm:text-xs font-light px-2 py-1 flex">
        //       {!hasOnPsProOrOnPsLifeSection ? (
        //         <Link
        //           to=""
        //           className="font-americaMonoRegular uppercase bg-black text-white font-light tracking-submenu text-xs underline cursor-pointer"
        //         >
        //         home
        //         </Link>
        //       ) : (
        //         <span className="font-americaMonoRegular uppercase bg-black text-white font-light tracking-submenu text-xs">home</span>
        //       )}

        //       {params.section && params.post && isSectionWithSubPost(params) ? (
        //         <Link
        //           to={`/${params.section}`}
        //           className="font-americaMonoRegular uppercase bg-black text-white font-light tracking-submenu text-xs cursor-pointer"
        //         >
        //           <span className="no-underline text-white px-2">></span>
        //           <span className="underline">
        //             {sectionTitle.en
        //               ? truncateString(sectionTitle)
        //               : section
        //                 ? truncateString(section)
        //                 : truncateString(params.section)}
        //           </span>
        //         </Link>
        //       ) : params.section ? (
        //         <span
        //           className={`font-americaMonoRegular uppercase ${
        //             params.post ? 'text-white' : `text-${params.section}`
        //           } tracking-submenu text-xs bg-black font-light`}
        //         >
        //           <span className="text-white px-2">></span>
        //           {sectionTitle.en
        //             ? truncateString(sectionTitle)
        //             : section
        //               ? truncateString(section)
        //               : truncateString(params.section)}
        //         </span>
        //       ) : null}
        //       {params.post ? (
        //         <span
        //           className={`font-americaMonoRegular uppercase ${
        //             isSectionWithSubPost && params.section !== 'news'
        //               ? `text-${params.section}`
        //               : 'bg-black text-default font-light'
        //           } tracking-submenu text-xs`}
        //         >
        //           <span className="bg-black text-white font-light px-2">></span>
        //           {title ? truncateString(title) : truncateString(params.post)}
        //         </span>
        //       ) : null}
        //     </div>
        //   </div>
        // );
        <></>
      }}
    </RoutesContext.Consumer>
  );
};

Navigator.propTypes = {
  position: PropTypes.string,
};

Navigator.defaultProps = {
  position: 'absolute',
};

export default withRouter(Navigator);
