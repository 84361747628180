/* eslint-disable camelcase */
import React from 'react';
import {
  TwitchEmbed, TwitchChat, TwitchClip, TwitchPlayer,
} from 'react-twitch-embed';
import parse from 'html-react-parser';
import { PropTypes } from 'prop-types';
import ReactPlayer from 'react-player';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const VideoPlayer = ({
  image, url, youtube, vimeo, twitchChannel, match, description, title_multilang, size,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      let videoUrl;
      if (url) {
        videoUrl = url;
      } else if (youtube) {
        videoUrl = `https://youtu.be/${youtube}`;
      } else if (vimeo) {
        videoUrl = `https://player.vimeo.com/video/${vimeo}`;
      }

      const getClassesByWidth = () => {
      // If no width given, return as usual
        if (!(size) || size === 'full') {
          return 'w-full mb-10 laptop:mb-15';
        }

        // If size given, align right and set width to tailwind units
        const sizeToTailwind = {
          tiny: '1/3',
          small: '1/2',
          medium: '2/3',
          large: '4/5',
        };
        return `w-${sizeToTailwind[size]} float-right`;
      };


      return (
        <div className={getClassesByWidth()}>
          <div>
            <div className="player-wrapper mt-10 laptop:mt-15">
              {videoUrl && (
                <ReactPlayer
                  config={{
                    youtube: {
                      playerVars: { modestbranding: 1 },
                    },
                  }}
                  controls
                  className="react-player"
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  light={image}
                />
              )
              }
              {
                twitchChannel && (
                <>
                  <TwitchEmbed
                    channel={twitchChannel}
                    theme="dark"
                    onVideoPause={() => console.log(':(')}
                  />
                  {/* <TwitchChat channel="chess" theme="dark" /> */}
                </>
                )
              }
            </div>
            {description ? <div className="mt-4 text-left font-stratos text-base text-grey font-normal text-11 laptop:text-base leading-20 innerTextPadding hasLinksAndBolds">{parse(translateObj(description, localization.language))}</div> : <span className="select-none block my-4">&nbsp;</span>}
            {title_multilang ? <div className="text-xxs font-stratos mt-4 pb-4 sm:h-4 innerTextPadding hasLinksAndBolds">{parse(translateObj(title_multilang, localization.language))}</div> : null}
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

VideoPlayer.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
  youtube: PropTypes.string,
  vimeo: PropTypes.string,
  twitchChannel: PropTypes.string,
  size: PropTypes.string,
  description: PropTypes.shape({}),
  title_multilang: PropTypes.shape({}),
  match: PropTypes.shape().isRequired,
};

VideoPlayer.defaultProps = {
  url: null,
  youtube: null,
  vimeo: null,
  twitchChannel: null,
  size: null,
  description: null,
  title_multilang: null,
};

export default withRouter(VideoPlayer);
