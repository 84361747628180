/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { withRouter } from 'react-router-dom';

import useQuery from '../../../../util/useQueryParam';
import translateObj from '../../../../util/translateObj';

import { useUser } from '../../../../context/userProfileContext';
import { useRoutes } from '../../../../context/routesContext';
import userWebVerification from '../../../../api/graphql/mutations/userWebVerification';
import userDeleteAccount from '../../../../api/graphql/mutations/userDeleteAccount';
import userSetPaymentMethods from '../../../../api/graphql/mutations/userSetPaymentMethods';
import userSetPreferredPaymentMethod from '../../../../api/graphql/mutations/userSetPreferredPaymentMethod';
import userGetPaymentMethods from '../../../../api/graphql/queries/userGetPaymentMethods';
import getPaymentForProductGroup from '../../../../api/graphql/queries/getPaymentForProductGroup';
import setSubscriptionOptions from '../../../../api/graphql/mutations/setSubscriptionOptions';
import getPayments from '../../../../api/graphql/queries/getPayments';
import api from '../../Payments/psWorldPaymentsApi';

import Container from '../../Container';
import DropdownContainer from './DropdownContainer';
import AreYouSurePopup from './components/AreYouSurePopup';
import ProfileLogOutButton from './components/ProfileLogOutButton';
import ProductChosenModal from '../../Purchases/ProductChosenModal';


import ProfileFormsy from './components/ProfileFormsy';
import PasswordFormsy from './components/PasswordFormsy';
import SubscriptionFormsy from './components/SubscriptionFormsy';
import StripeCardsFormsy from './components/StripeCardsFormsy';
import NewsletterFormsy from './components/NewsletterFormsy';
import GiftFormsy from './components/GiftFormsy';
import DeleteFormsy from './components/DeleteFormsy';
import AddressForm from './components/AddressForm';

const products = [
  'rockdelux-subscription-1-month',
  'rockdelux-subscription-1-year',
];

const ProfileForm = ({
  history,
  match,
  location,
  title,
  fields,
  infoFields,
  validation,
  submitMessage,
  sourceWeb,
  newsletterScopes,
}) => {
  const {
    userEmail,
    token,
    isLinkedWithSpotify,
    isUserVerified,
    userSubscriptionDetails,
    userRoles,
    loadTokenForLS,
    logoutUser,
  } = useUser();

  const { language = 'en' } = useRoutes(match);
  const [hasClickedOnMailVerification, setHasClickedOnMailVerification] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');
  console.log("🚀 ~ file: ProfileForm.jsx ~ line 70 ~ activeDropdown", activeDropdown)
  const [paymentProvider, setPaymentProvider] = useState('stripe');
  const [customerInfo, setCustomerInfo] = useState(null);
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(null);
  const [preferredCard, setPreferredCard] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupPayload, setPopupPayload] = useState();
  const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState();
  const selectedPaymentMethodIDRef = useRef(selectedPaymentMethodID);
  const productGroup = 'rdl-subscription';
  const [subscriptionData, setSubscriptionData] = useState();
  const [activeProduct, setActiveProduct] = useState();
  const [renewalProduct, setRenewalProduct] = useState();
  const [renewSubscription, setRenewSubscription] = useState();
  const [addCardModal, setAddCardModal] = useState(false);
  const [sendGift, setSendGift] = useState(false);
  const [allPresents, setAllPresents] = useState(null);
  const [lastRenewalError, setLastRenewalError] = useState(null);
  const [subscriptionNextPaymentDay, setSubscriptionNextPaymentDay] = useState();
  const [subscriptionSectionError, setSubscriptionSectionError] = useState('');
  const isLoaded = useRef(null);
  const query = useQuery();

  useEffect(() => {
    isLoaded.current = true;
    return () => { isLoaded.current = null; };
  }, []);

  const sectionTitles = {
    personalData: {
      en: 'Datos personales',
      es: 'Datos personales',
    },
    shipment: {
      en: 'Dirección de envío',
    },
    password: {
      en: 'Contraseña',
      es: 'Contraseña',
    },
    subscription: {
      en: 'Suscripción',
      es: 'Suscripción',
    },
    paymentMethod: {
      en: 'Método de pago',
      es: 'Método de pago',
    },
    presents: {
      en: 'Regalos que has hecho',
      es: 'Regalos que has hecho',
    },
    deleteAccount: {
      en: 'Eliminar cuenta',
      es: 'Eliminar cuenta',
    },
    renewalErrorTitle: {
      en: 'Error al renovar tu suscripción',
      es: 'Error al renovar tu suscripción',
    },
  };

  // onSubmit notification (triggered by all profile forms)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (didSubmit) setDidSubmit(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [didSubmit]);

  useEffect(() => {
    // * if user is not logged in redirect to homescreen
    if (!userEmail) {
      setTimeout(() => {
        // history.push(`/${language}/login-screen${sourceWeb && `-${sourceWeb}`}?e=sign-in`);
        history.push('/');
      }, 1000);
    } else {
      const body = document.getElementById('bodyChildContainer');
      body.childNodes[0].setAttribute('style', 'border-top: none');
    }
  }, []);

  useEffect(() => {
    if (subscriptionData) {
      setSubscriptionNextPaymentDay(subscriptionData.subscriptionLastDate);
      setActiveProduct(subscriptionData.productSlugName);
      setRenewalProduct(subscriptionData.subscriptionRenewalProductSlugName);
      setRenewSubscription(subscriptionData.renewSubscription);
    }
  }, [subscriptionData]);

  // Get customer info
  const getCustomerInfo = () => {
    if (paymentProvider === 'stripe' && customerInfo === null && userEmail) {
      const cleanUserEmail = userEmail.trim();
      api.getOrCreateCustomer(cleanUserEmail).then((customerData) => {
        if (isLoaded.current) {
          setCustomerInfo(customerData);
          if (!customerData || !Object.keys(customerData).length) {
            console.error(`Customer ${userEmail} could not be retrieved nor created`);
          }
        }
      });
    }
  };

  const getCustomerPaymentMethods = async () => {
    if (!userEmail) {
      console.error('Please log in to get payment methods');
      return;
    }

    const paymentMethods = await userGetPaymentMethods();
    if (!paymentMethods || !isLoaded.current) {
      return;
    }

    const preferredMethod = paymentMethods.find(card => card.preferred === true);
    if (preferredMethod) {
      setPreferredCard(preferredMethod.token);
    } else if (customerPaymentMethods && customerPaymentMethods.length) {
      setPreferredCard(paymentMethods[0].token);
    }
  };

  const setPreferredPaymentMethod = async (receivedToken) => {
    if (isLoaded.current) {
      const result = userSetPreferredPaymentMethod({ token: receivedToken });
      result.then(() => { setPreferredCard(receivedToken); });
      if (result.error) console.log('userSetPreferredPaymentMethod error', result.error);
    }
  };

  // Change preferred payment method
  const handleSetPreferredCard = (receivedToken) => {
    setPreferredPaymentMethod(receivedToken);
  };

  // Get customer preferred payment method
  useEffect(() => {
    getCustomerPaymentMethods();
  }, [customerInfo, preferredCard]);

  // Get subscription info
  const getSubscriptionInfo = () => {
    getPaymentForProductGroup(productGroup).then((data) => {
      if (isLoaded.current) {
        setSubscriptionData(data);
      }
    });
  };

  // Get last renewal error, if any:
  const getLastRenewalError = (payments) => {
    const validPayments = payments.filter((payment) => {
      const {
        contractType,
        payed,
        provider,
      } = payment;

      return payed && contractType === 'subscription' && provider !== 'free';
    });
    const sortedPayments = validPayments.sort((a, b) => b.datePayment - a.datePayment);
    if (sortedPayments && sortedPayments.length) {
      const lastPayment = sortedPayments[0];
      return lastPayment.subscriptionRenewalLastError;
    }
    return null;
  };

  // Get payments info
  const getPaymentsInfo = () => {
    getPayments(productGroup).then((payments) => {
      if (isLoaded.current) {
        setAllPresents(payments.filter(payment => payment.contractType === 'present'));
        setLastRenewalError(getLastRenewalError(payments));
      }
    });
  };

  // Run get customer/subscription/payments info
  useEffect(() => {
    if (customerInfo === null) {
      getCustomerInfo();
    }
    getSubscriptionInfo();
    getPaymentsInfo();
  }, [paymentProvider, userEmail, customerInfo]);

  // Get customer payments methods from customer info
  const getCustomerPaymentMethodsFromStripe = async () => {
    if (!userEmail) {
      console.error('Please log in to get payment methods');
      return;
    }
    if (!customerInfo || !customerInfo.id) {
      console.error('Please get customer info first');
      return;
    }

    // Get payment methods from api (stripe)
    api.getCustomerPaymentMethods(customerInfo.id).then(async (paymentMethods) => {
      const paymentMethodsSend = paymentMethods.map((method) => {
        const {
          id: token,
          card: {
            last4, brand, exp_month, exp_year,
          },
          created,
        } = method;

        const createdAt = new Date(created * 1000);

        return {
          provider: 'stripe',
          token,
          card: {
            last4,
            brand,
            exp_month,
            exp_year,
          },
          createdAt,
        };
      });
      // Save payment methods in our database
      await userSetPaymentMethods({ provider: 'stripe', paymentMethods: paymentMethodsSend }); // TODO: get the preferred method from response and set it with setPaymentMethodId()
      if (isLoaded.current) {
        setCustomerPaymentMethods(paymentMethodsSend);
      }
    });
  };

  // Request user payment methods when customerInfo exists
  useEffect(() => {
    if (customerInfo !== null) {
      getCustomerPaymentMethodsFromStripe();
    }
  }, [customerInfo]);

  const removeSelectedPaymentMethod = async (paymentMethodId) => {
    if (!customerInfo || !customerInfo.id) {
      console.error('Please get customer info first');
      return;
    }
    if (!paymentMethodId) {
      console.error('Please select a payment method first');
      return;
    }

    api.removePaymentMethod(paymentMethodId)
      .then((result) => {
        console.log(result.error);
        if (result) {
          // Update all paymentMethods
          getCustomerPaymentMethodsFromStripe();
        }
      });
  };

  // Listen for updates on customer payment methods
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('updatePaymentMethod', getCustomerInfo);
    }
    return () => window.addEventListener('updatePaymentMethod', getCustomerInfo);
  }, []);

  const confirmRemoveCard = () => {
    const cardToken = selectedPaymentMethodIDRef.current;
    removeSelectedPaymentMethod(cardToken);
    setShowPopup(false);
  };

  const doNotRemoveCard = () => {
    setSelectedPaymentMethodID(undefined);
    selectedPaymentMethodIDRef.current = undefined;
    setShowPopup(false);
    setPopupPayload(undefined);
  };

  // Hydrate popup
  const handleRemoveCard = (paymentMethodId) => {
    setSelectedPaymentMethodID(paymentMethodId);
    selectedPaymentMethodIDRef.current = paymentMethodId;
    setPopupPayload({
      text: {
        en: '¿Estás seguro de que quieres eliminar esta tarjeta? Esta acción no se puede deshacer.',
        es: '¿Estás seguro de que quieres eliminar esta tarjeta? Esta acción no se puede deshacer.',
      },
      primaryButtonText: {
        en: 'Eliminar tarjeta',
        es: 'Eliminar tarjeta',
      },
      primaryCallback: confirmRemoveCard,
      secondaryButtonText: {
        en: 'Volver atrás',
        es: 'Volver atrás',
      },
      secondaryCallback: doNotRemoveCard,
    });
    setShowPopup(true);
  };

  // Handle add card to payment methods
  const handleAddCard = () => {
    setAddCardModal(true);
  };

  // Toggle automatic subscription renewal
  const toggleRenewSubscription = () => {
    if (subscriptionData) {
      setSubscriptionOptions({ productGroup, renewSubscription: !renewSubscription })
        .then((result) => {
          if (result.error) {
            setSubscriptionSectionError(result.error);
          } else {
            setRenewSubscription(!renewSubscription);
            subscriptionData.renewSubscription = !renewSubscription;
            setSubscriptionData(subscriptionData);
          }
        })
        .catch(error => setSubscriptionSectionError(error.message));
    }
  };

  const confirmRenewSubscription = () => {
    toggleRenewSubscription();
    setShowPopup(false);
  };

  // Hydrate popup
  const handleSubscriptionRenewal = () => {
    const currentState = renewSubscription === true ? { es: 'Automático', en: 'Automático' } : { es: 'Ninguno', en: 'Ninguno' };
    const newState = renewSubscription === true ? { es: 'Ninguno', en: 'Ninguno' } : { es: 'Automático', en: 'Automático' };
    setSubscriptionSectionError('');
    setPopupPayload({
      text: {
        en: `El estado actual de renovación de tu suscripción es: ${translateObj(currentState, language)}. ¿Estás seguro de que quieres cambiarlo por "${translateObj(newState, language)}"?`,
        es: `El estado actual de renovación de tu suscripción es: ${translateObj(currentState, language)}. ¿Estás seguro de que quieres cambiarlo por "${translateObj(newState, language)}"?`,
      },
      primaryButtonText: {
        en: 'Cambiar',
        es: 'Cambiar',
      },
      primaryCallback: confirmRenewSubscription,
      secondaryButtonText: {
        en: 'Cancelar',
        es: 'Cancelar',
      },
      secondaryCallback: () => setShowPopup(false),
    });
    setShowPopup(true);
  };

  // Send email verification on verification status button click
  const handleResendVerification = () => {
    if (userEmail && !hasClickedOnMailVerification) {
      userWebVerification({ email: userEmail }).then((res) => {
        if (res) {
          setHasClickedOnMailVerification(true);
          if (res.error !== null) console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ API: Error`, res.error);
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - webVerification failed to submit!`);
        }
      });
    }
  };

  // TODO: Unsubsscribe from rockdelux function
  const handleUnsubscribe = () => {
    console.log('Unsubscribe function @ ProfileForm.jsx');
  };

  const getDesiredProductFrom = () => {
    if (renewalProduct && renewalProduct !== activeProduct) {
      return renewalProduct;
    }
    return activeProduct;
  };

  const getDesiredProductTo = () => {
    if (renewalProduct && renewalProduct !== activeProduct) {
      return activeProduct;
    }
    return products.find(product => activeProduct !== product);
  };

  const confirmChangeSubscription = () => {
    const newProduct = getDesiredProductTo();
    // changeSubscription();
    setSubscriptionOptions({ productGroup, subscriptionRenewalProductSlugName: newProduct })
      .then((result) => {
        if (result.error) {
          setSubscriptionSectionError(result.error);
        } else {
          setRenewalProduct(newProduct);
        }
      })
      .catch(error => setSubscriptionSectionError(error.message));
    setShowPopup(false);
  };

  // Hydrate popup
  const handleSubscriptionChange = () => {
    const currentProduct = getDesiredProductFrom() === 'rockdelux-subscription-1-month' ? { en: 'suscripción mensual', es: 'suscripción mensual' } : { en: 'suscripción anual', es: 'suscripción anual' };
    const newProduct = getDesiredProductTo() === 'rockdelux-subscription-1-month' ? { en: 'suscripción mensual', es: 'suscripción mensual' } : { en: 'suscripción anual', es: 'suscripción anual' };
    setSubscriptionSectionError('');
    setPopupPayload({
      text: {
        en: `¿Estás seguro de que quieres cambiar tu ${translateObj(currentProduct, language)} por una ${translateObj(newProduct, language)}?`,
        es: `¿Estás seguro de que quieres cambiar tu ${translateObj(currentProduct, language)} por una ${translateObj(newProduct, language)}?`,
      },
      primaryButtonText: {
        en: 'Cambiar suscripción',
        es: 'Cambiar suscripción',
      },
      primaryCallback: () => confirmChangeSubscription(),
      secondaryButtonText: {
        en: 'Cancelar',
        es: 'Cancelar',
      },
      secondaryCallback: () => setShowPopup(false),
    });
    setShowPopup(true);
  };

  // TODO: handle newsletter preferences
  const handleNewsletter = () => {
    console.log('Handle newsletter preference function @ ProfileForm.jsx');
  };

  // Handle gift subscription
  const handleSendGift = () => {
    setSendGift(true);
  };

  // Logout utility
  const _logout = (lang, webSource = '') => {
    logoutUser();
    if (typeof localStorage !== 'undefined') {
      // history.push(`/${lang}${webSource && `/${webSource}`}`);
      history.push('/');
      loadTokenForLS();
    }
  };

  // Delete account
  const deleteAccount = (email) => {
    userDeleteAccount({ email }).then((response) => {
      if (response.isDeleted) {
        _logout(language, loadTokenForLS);
      } else {
        console.warn('error when trying to delete user', response.error);
      }
    });
  };

  const confirmDeleteAccount = () => {
    setShowPopup(false);
    setPopupPayload(undefined);
    deleteAccount(userEmail);
  };

  const doNotDeleteAccount = () => {
    setShowPopup(false);
    setPopupPayload(undefined);
  };

  // Hydrate popup
  const handleAccountDeletion = () => {
    setShowPopup(true);
    setPopupPayload({
      text: {
        en: '¿Estás seguro de que quieres eliminar tu cuenta? Esta acción no se puede deshacer.',
        es: '¿Estás seguro de que quieres eliminar tu cuenta? Esta acción no se puede deshacer.',
      },
      primaryButtonText: {
        en: 'Eliminar cuenta',
        es: 'Eliminar cuenta',
      },
      primaryCallback: confirmDeleteAccount,
      secondaryButtonText: {
        en: 'Volver atrás',
        es: 'Volver atrás',
      },
      secondaryCallback: doNotDeleteAccount,
    });
  };

  // Ensure only one active dropdown
  const dropdownClick = (id) => {
    if (activeDropdown === id) {
      setActiveDropdown('');
    } else setActiveDropdown(id);
  };

  useEffect(() => {
    const section = query.get('section');
    dropdownClick(section);
  }, []);

  return (
    <>
      <AnimatePresence initial={false}>
        {showPopup && (
          <motion.div
            key="areYouSurePopup"
            transition={{ type: 'tween', duration: '0.2', ease: 'linear' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <AreYouSurePopup
              match={match}
              text={popupPayload.text}
              primaryButtonText={popupPayload.primaryButtonText}
              primaryCallback={popupPayload.primaryCallback}
              secondaryButtonText={popupPayload.secondaryButtonText}
              secondaryCallback={popupPayload.secondaryCallback}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {addCardModal && (
        <ProductChosenModal
          match={match}
          parentComponent="ProfileForm"
          forceState={addCardModal}
          onClose={() => setAddCardModal(false)}
          buttonText={{}}
        />
      )}
      {sendGift && (
        <ProductChosenModal
          match={match}
          parentComponent="ProfileForm"
          section="sendGift"
          forceState={sendGift}
          onClose={() => setSendGift(false)}
          buttonText={{}}
        />
      )}
      <div className="desktop:mx-auto max-w-rdl-xxl">
        <Container className="relative px-0 tablet:px-6 laptop:px-40 desktop:px-0 mt-16 tablet:mt-36 laptop:mt-52 pb-10">
          <div key="logout" className="flex items-center justify-end mb-4 sm:pt-10 md:pt-10">
            {token && <ProfileLogOutButton sourceWeb={sourceWeb} language={language} />}
          </div>
          <div key="title" className="uppercase font-stratos font-bold text-26 text-red mb-7">
            {translateObj(title, language)}
          </div>
          {lastRenewalError && lastRenewalError.message && (
            <div key="renewalError" className="font-stratos font-bold text-20 text-red mb-7">
              {translateObj(sectionTitles.renewalErrorTitle, language)}
              {': '}
              {lastRenewalError.message}
            </div>
          )}
          <DropdownContainer
            index="datosPersonales"
            activeDropdown={activeDropdown}
            callback={dropdownClick}
            title={translateObj(sectionTitles.personalData, language)}
          >
            <ProfileFormsy
              {... {
                title,
                fields,
                infoFields,
                validation,
                language,
                setDidSubmit,
                history,
                sourceWeb,
              }}
              resendVerificationCallback={handleResendVerification}
              clickedOnMailVerification={hasClickedOnMailVerification}
            />
          </DropdownContainer>
          <DropdownContainer
            index="direccionEnvio"
            activeDropdown={activeDropdown}
            callback={dropdownClick}
            title={translateObj(sectionTitles.shipment, language)}
          >
            <AddressForm />
          </DropdownContainer>
          <DropdownContainer
            index="password"
            activeDropdown={activeDropdown}
            callback={dropdownClick}
            title={translateObj(sectionTitles.password, language)}
          >
            <PasswordFormsy
              {... {
                title,
                fields,
                infoFields,
                validation,
                language,
                setDidSubmit,
                history,
                sourceWeb,
              }}
            />
          </DropdownContainer>
          <DropdownContainer
            index="suscripcion"
            activeDropdown={activeDropdown}
            callback={dropdownClick}
            title={translateObj(sectionTitles.subscription, language)}
          >
            <SubscriptionFormsy
              match={match}
              unsubscribeCallback={handleSubscriptionRenewal}
              changeSubscriptionCallback={handleSubscriptionChange}
              renewSubscription={renewSubscription}
              activeSubscription={activeProduct}
              subscriptionData={subscriptionData}
              cards={customerPaymentMethods}
              renewalProduct={renewalProduct}
              nextPaymentDay={subscriptionNextPaymentDay}
              subscriptionSectionError={subscriptionSectionError}
            />
          </DropdownContainer>
          <DropdownContainer
            index="metodoDePago"
            activeDropdown={activeDropdown}
            callback={dropdownClick}
            title={translateObj(sectionTitles.paymentMethod, language)}
          >
            {(subscriptionData?.provider !== 'paypal') ? (
              <StripeCardsFormsy
                match={match}
                cards={customerPaymentMethods}
                favCard={preferredCard}
                addCardCallback={handleAddCard}
                removeCardCallback={handleRemoveCard}
                selectPreferredCallback={handleSetPreferredCard}
              />
            ) : (
              <div className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5">
                <div className="font-stratos text-20 text-black col-span-4 tablet:col-span-8 laptop:col-span-5 tablet:col-start-2 laptop:col-start-2">
                  <p className="mb-7">
                    Paypal
                  </p>
                </div>
              </div>
            )}
          </DropdownContainer>
          <DropdownContainer
            index="newsletter"
            activeDropdown={activeDropdown}
            callback={dropdownClick}
            title="Newsletter"
          >
            <NewsletterFormsy
              userEmail={userEmail}
              userRoles={userRoles}
              match={match}
              scopes={newsletterScopes}
              location={location}
              newsletterCallback={handleNewsletter}
            />
          </DropdownContainer>
          <DropdownContainer
            index="gifts"
            activeDropdown={activeDropdown}
            callback={dropdownClick}
            title={translateObj(sectionTitles.presents, language)}
          >
            <GiftFormsy
              match={match}
              giftCallback={handleSendGift}
              allGifts={allPresents}
            />
          </DropdownContainer>
          <DropdownContainer
            index="eliminarCuenta"
            activeDropdown={activeDropdown}
            callback={dropdownClick}
            title={translateObj(sectionTitles.deleteAccount, language)}
            borderBottom
          >
            <DeleteFormsy
              match={match}
              deleteCallback={handleAccountDeletion}
              renewalCallback={handleSubscriptionRenewal}
            />
          </DropdownContainer>
          {didSubmit && (
            <div className="absolute pin-t pin-l pin-r flex items-center justify-center font-stratos text-center font-bold">
              <h3>{translateObj(submitMessage, language)}</h3>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};


ProfileForm.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  validation: PropTypes.shape().isRequired,
  infoFields: PropTypes.shape().isRequired,
  submitMessage: PropTypes.shape().isRequired,
  sourceWeb: PropTypes.string,
  newsletterScopes: PropTypes.arrayOf(PropTypes.shape()),
  // menu: PropTypes.shape(),
  // conditions: PropTypes.shape().isRequired,
  // submitButtonText: PropTypes.shape().isRequired,
  // resetButtonText: PropTypes.shape().isRequired,
  // submitMessageButton: PropTypes.shape().isRequired,
};

ProfileForm.defaultProps = {
  // menu: null,
  sourceWeb: '',
  newsletterScopes: [],
};

export default withRouter(ProfileForm);
