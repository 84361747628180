import React from 'react';
import { PropTypes } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';
import LinkButtonRounded from './Buttons/LinkButtonRounded';
import NativeVideoPlayer from './NativeVideoPlayer';

// TODO: add proptypes
const VideoPlayer = ({ src, overlayImage, premiumLogo, format }) => (
  <div className="relative h-full w-full overflow-hidden">
    <video
      autoPlay
      muted
      loop
      className="w-full h-full"
      src={src}
      type={`video/${format}`}
      style={{ objectFit: 'cover' }}
    />
    <div className="absolute pin-b pin-r p-8">
      <div
        className="cursor-pointer"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <svg className="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.736 56.736">
          <g data-name="g3204" fill="none" stroke="#fff">
            <path data-name="l114" d="M28.368 53.736a25.368 25.368 0 0025.368-25.368A25.368 25.368 0 0028.368 3 25.368 25.368 0 003 28.368a25.368 25.368 0 0025.368 25.368z" strokeWidth="6" />
            <path data-name="l116" d="M23.311 19.098v18.539" strokeLinecap="round" strokeWidth="4" />
            <path data-name="l117" d="M33.425 19.098v18.539" strokeLinecap="round" strokeWidth="4" />
          </g>
        </svg>
      </div>
    </div>
    {overlayImage && premiumLogo}
  </div>
);

const checkLocalVideoFile = (src) => {
  if (!src) return { isLocalVideo: false };
  const arr = src.split('.');
  const format = arr[arr.length - 1];
  if (['mp4', 'ogg', 'webm'].includes(format)) return { isLocalVideo: true, format };
  return { isLocalVideo: false };
};

const Image = ({ src, alt, color, preTitle, title, text, match, link, overlayImage, premiumLogo }) => (
  <div className="relative">
    <div
      className="h-full"
    >
      <img src={imageModifier(src, '1024x0')} alt={alt} />
    </div>
    <div
      className={`absolute pin-t pin-b pin-r pin-l z-10 px-5 py-4 tablet:py-8 tablet:px-8 font-stratos font-bold text-${color} flex flex-col justify-stretch items-start h-full`}
    >
      <span className="mb-2 laptop:font-sm">{preTitle}</span>
      <h3 className="max-w-xs mb-4 leading-none text-24 tablet:text-50 tablet:max-w-sm tablet:mb-6">
        {title}
      </h3>
      <p className="flex-1 max-w-xs pr-4 text-base font-normal leading-tight tablet:text-20 tablet:max-w-sm">
        {text}
      </p>
      {link.url !== '' && (
      <div className="flex items-center justify-center w-full tablet:justify-end mt-6 cursor-pointer">
        <LinkButtonRounded
          text={link.text}
          url={link.url}
          match={match}
          color={color}
          isLowercase
          isDisabled
        />
      </div>
      )}
    </div>
    {overlayImage && premiumLogo}
  </div>
);

const Video = ({ desktopVideoUrl, premiumLogo, overlayImage }) => {
  const { isLocalVideo, format } = checkLocalVideoFile(desktopVideoUrl);
  if (isLocalVideo) return <VideoPlayer src={desktopVideoUrl} format={format} overlayImage={overlayImage} premiumLogo={premiumLogo} />;
  return <NativeVideoPlayer desktopUrl={desktopVideoUrl} />;
};

const Item = ({ image, premiumLogo, overlayImage, link, alt, color, preTitle, title, text, match, desktopVideoUrl }) => {
  if (desktopVideoUrl) return <Video desktopVideoUrl={desktopVideoUrl} src={image} overlayImage={overlayImage} premiumLogo={premiumLogo} />;
  return <Image src={image} {...{ link, alt, color, preTitle, title, text, match }} />;
};

const bannersRowItem = ({
  color, bgColor, bgImage, preTitle, title, text, link, match, bannerClick, overlayImage, desktopVideoUrl,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const premiumLogo = (
        <div className="absolute pin-t pin-r mt-2 mr-2">
          <img src={imageModifier(overlayImage, '45x45')} alt="Premium content" />
        </div>
      );

      return (
        <LinkItem link={bannerClick} url={link.url}>
          <Item
            image={translateObj(bgImage, localization.language)}
            title={translateObj(title, localization.language)}
            text={translateObj(text, localization.language)}
            preTitle={translateObj(preTitle, localization.language)}
            desktopVideoUrl={translateObj(desktopVideoUrl, localization.language)}
            {...{ premiumLogo, overlayImage, link, color, match }}
          />
        </LinkItem>
      );
    }}
  </RoutesContext.Consumer>
);

const getLinkType = (link) => {
  if (link && link !== '') return (link.includes('https://') || link.includes('http://')) ? 'external' : 'internal';
  return 'default';
};

const LinkItem = ({ link, url, children }) => {
  const linkType = getLinkType(link);
  switch (linkType) {
    case 'external':
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    case 'internal':
      return (
        <Link to={link}>
          {children}
        </Link>
      );
    case 'default':
      return (
        <Link to={`${url}`} className="cursor-default">
          {children}
        </Link>
      );
    default:
      return null; // Handle unexpected cases or provide a fallback.
  }
};

export default withRouter(bannersRowItem);

bannersRowItem.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  bgImage: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  preTitle: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  title: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  text: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  link: PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  match: PropTypes.shape().isRequired,
  bannerClick: PropTypes.string,
  overlayImage: PropTypes.string,
};

bannersRowItem.defaultProps = {
  color: 'white',
  bgColor: '#D8D8DA',
  preTitle: { en: '', es: '' },
  bgImage: { en: '', es: '' },
  title: { en: '', es: '' },
  text: { en: '', es: '' },
  link: { text: { en: '', es: '' }, url: '' },
  bannerClick: '',
  overlayImage: null,
  desktopVideoUrl: { en: '', es: '', ca: '' },
};
