import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AdModule = () => {
  const [deviceScreen, setDeviceScreen] = useState();

  // TODO: Props?
  return (
    <div className="w-full flex items-start justify-center tablet:justify-end pt-18 tablet:pt-12 laptop:pt-15">
      <div className="h-600px w-300px flex justify-center items-center">
        <Helmet>
          {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

            <ins class="adsbygoogle"
              style="display:block"
              data-ad-client="ca-pub-9017218117669661"
              data-ad-slot="6396904659"
              data-ad-format="auto"
              data-full-width-responsive="true">
            </ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({ });
          </script> */}
        </Helmet>
      </div>
    </div>
  );
};

export default withRouter(AdModule);
