import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import RoutesContext from '../../../context/routesContext';
import SubscriptionTag from '../SubscriptionTag';

class NewsPostPage extends Component {
  static contextType = RoutesContext

  state = {
    deviceScreen: null,
  };

  responsiveUtility = React.createRef();

  componentDidMount() {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.handleResize(deviceScreen);
    this.setParentComponent();
  }

  componentDidUpdate() {
    this.setParentComponent();
  }

  handleResize = (deviceScreen) => {
    this.setState({ deviceScreen });
  }

  setParentComponent = () => {
    const { components } = this.props;
    if (components && components.length > 0) {
      components.forEach((component) => {
        Object.assign(component, { parentComponent: 'NewsPostPage' });
        if ((component.type === 'SharingBlock' && component.size !== 'small')
        || component.type === 'AuthorBlock' || component.type === 'DateBlock'
        || component.type === 'MagazineBlock') {
          Object.assign(component, { isArticleHeader: true });
        }
        if (component.type === 'CommentsBox') {
          Object.assign(component, { forceMobileLayout: true });
        }
        if (Object.keys(component).includes('text')) {
          if (Object.keys(component.text).length > 0 && component.text.en) {
            if (['<p></p>', '<p><br></p>'].includes(component.text.en)) {
              Object.assign(component, { hideWhileEmptyText: true });
            }
          }
        }
      });
    }
  }

  render() {
    const { currentSlugName, currentRolesAllowed: { rolesAllowed } } = this.context;
    const { deviceScreen } = this.state;
    let { components } = this.props;

    const filteredComponents = components.filter(component => !component.hideWhileEmptyText || component.hideWhileEmptyText !== true);
    components = filteredComponents;

    let latest = {};
    let ad = {};
    let tag = {};
    let title = {};
    let subTitle = {};
    let author = {};
    let date = {};
    let magazine = {};
    let socialTop = {};
    let enhanced = {};
    let image = {};
    let socialBottom = {};
    let tags = {};
    let comments = {};
    let related = {};
    let body = [];

    if (components && components.length > 0) {
      latest = components.find(component => component.type === 'Latest') || {};
      ad = components.find(component => component.type === 'AdModule') || {};
      tag = components.find(component => component.type === 'TaggedItem') || {};
      title = components.find(component => component.type === 'TitleLineH1') || {};
      subTitle = components.find(component => ['TitleLineH2', 'MediaTitle'].includes(component.type)) || {};
      author = components.find(component => component.type === 'AuthorBlock') || {};
      date = components.find(component => component.type === 'DateBlock') || {};
      magazine = components.find(component => component.type === 'MagazineBlock') || {};
      socialTop = components.find(component => component.type === 'SharingBlock' && component.size !== 'small') || {};
      enhanced = components.find(component => component.type === 'NormalBodyText' && component.enhanced === true) || {};
      image = components.find(component => component.type === 'MediaDescription') || {};
      socialBottom = components.find(component => component.type === 'SharingBlock' && component.size === 'small') || {};
      tags = components.find(component => component.type === 'TagsBlock') || {};
      comments = components.find(component => component.type === 'CommentsBox') || {};
      related = components.find(component => component.type === 'GroupedItems') || {};

      const bodyItems = [];
      components.forEach((item) => {
        if (![latest, ad, tag, title, subTitle, author, date, magazine, socialTop, enhanced, image, socialBottom, tags, comments, related].includes(item)) {
          bodyItems.push(item);
        }
      });
      body = bodyItems;
    }

    return (
      <div key={currentSlugName} className="full-width">
        <PSResponsiveUtility ref={this.responsiveUtility} deviceScreenCallback={this.handleResize} rdlSizes />
        <div className="w-full h-full mt-20 tablet:mt-28 laptop:mt-60 desktop:mt-68">
          {['laptop', 'desktop'].includes(deviceScreen) ? (
            <div className="w-full grid grid-cols-12 gap-4 px-90px max-w-rdl-xxl mx-auto">
              <div className="col-start-2 col-end-9">
                {components && (tag || title || subTitle) && (
                  <ComponentIterator components={[tag, title, subTitle]} />
                )}
                {components && (author || date || magazine) && (
                <div className="flex flex-row justify-start">
                  <ComponentIterator components={[author, date, magazine]} />
                </div>
                )}
                {components && (socialTop || enhanced || image) && (
                  <ComponentIterator components={[socialTop, enhanced, image]} />
                )}
                <SubscriptionTag className="mb-14" rolesAllowed={rolesAllowed} />
                {components && body && body.length > 0 && (
                  <ComponentIterator components={body} />
                )}
                {components && (socialBottom || tags) && (
                  <ComponentIterator components={[socialBottom, tags]} />
                )}
                <div className="mt-12">
                  {components && comments && (
                  <ComponentIterator components={[comments]} />
                  )}
                </div>
              </div>
              <div className="col-start-10 col-end-13">
                {components && (latest) && (
                  <ComponentIterator components={[latest]} />
                )}
              </div>
            </div>
          ) : (
            <div className="w-full grid grid-cols-6 tablet:grid-cols-12 gap-3 px-4 tablet:px-11">
              {deviceScreen === 'tablet' ? (
                <div className="col-start-1 col-end-13">
                  {components && (tag || title || subTitle) && (
                  <ComponentIterator components={[tag, title, subTitle]} />
                  )}
                  {components && (author || date || magazine) && (
                  <div className="flex flex-row justify-start">
                    <ComponentIterator components={[author, date, magazine]} />
                  </div>
                  )}
                  {components && (socialTop || enhanced || image) && (
                    <ComponentIterator components={[socialTop, enhanced, image]} />
                  )}
                  <SubscriptionTag className="mb-14" rolesAllowed={rolesAllowed} />
                  {components && body && body.length > 0 && (
                    <ComponentIterator components={body} />
                  )}
                </div>
              ) : (
                <div className="col-start-1 col-end-7">
                  {components && (tag || title || subTitle || enhanced) && (
                    <ComponentIterator components={[tag, title, subTitle, enhanced]} />
                  )}
                  <div className="grid grid-cols-6 gap-3 mt-6 tablet:mt-0">
                    <div className="col-start-1 col-end-4">
                      {components && (author || date || magazine) && (
                        <ComponentIterator components={[author, date, magazine]} />
                      )}
                    </div>
                    <div className="col-start-5 col-end-7">
                      {components && socialTop && (
                        <ComponentIterator components={[socialTop]} />
                      )}
                    </div>
                  </div>
                  {components && image && (
                    <ComponentIterator components={[image]} />
                  )}
                  <SubscriptionTag className="mb-14" rolesAllowed={rolesAllowed} />
                  {components && body && body.length > 0 && (
                    <ComponentIterator components={body} />
                  )}
                </div>
              )}
              <div className="col-start-1 col-end-7 tablet:col-end-13 border-black border-b-6 laptop:border-b-0 mb-5 tablet:mb-6 mt-6 tablet:mt-0 laptop:mb-0 pb-14 laptop:pb-0">
                {components && (socialBottom || tags) && (
                  <ComponentIterator components={deviceScreen === 'tablet' ? [socialBottom, tags] : [tags, socialBottom]} />
                )}
                {components && comments && (
                <ComponentIterator components={[comments]} />
                )}
              </div>
              <div className="col-start-1 col-end-7 tablet:col-end-6 grid grid-cols-6 gap-3 tablet:block">
                <div className="col-span-4 col-start-2">
                  {components && latest && (
                    <ComponentIterator components={[latest]} />
                  )}
                </div>
              </div>
              <div className="col-start-1 col-end-7 tablet:col-start-7 tablet:col-end-13 tablet:mt-14 laptop:mt-15">
                {components && ad && (
                  <ComponentIterator components={[ad]} />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="w-full mt-20 tablet:mt-12 laptop:mt-20 desktop:mt-40">
          {components && related && (
            <ComponentIterator components={[related]} />
          )}
        </div>
      </div>
    );
  }
}

NewsPostPage.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
};

NewsPostPage.defaultProps = {
  components: null,
};

export default NewsPostPage;
