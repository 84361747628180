/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import SearchOverlay from '../SearchOverlay';
import { UserProfileContextConsumer } from '../../../context/userProfileContext';
import ComponentIterator from '../../../containers/ComponentIterator';
import UserMenuPopup from '../UserMenuPopup';
import ButtonLinkWithBadge from './ButtonLinkWithBadge';
import useResponsiveUtility from '../../../util/responsiveUtility';


const TopHeaderSubmenuItemsContainer = ({
  name, params, match,
}) => {
  const [ssrDone, setSsrDone] = useState(false);
  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const [isActiveSearch, setIsActiveSearch] = useState(false);
  // const [activeLoginMenu, setActiveLoginMenu] = useState(false);
  // console.log('TopHeaderSubmenuItemsContainer', activeLoginMenu);
  const [activeSubmenuItem, setActiveSubmenuItem] = useState();
  const { isMobile } = useResponsiveUtility();

  // On client side render
  useEffect(() => {
    setSsrDone(true);
  }, []);

  // Handle search overlay
  function handleSearchOverlay() {
    setIsActiveMenu(false);
    setIsActiveSearch(!isActiveSearch);
  }

  // Handle search route
  function handleSearch(query, lang) {
    props.history.push(`/search?query=${query}&lang=${lang}`);
    handleSearchOverlay();
  }

  // Close menu handler
  function handleCloseEvent() {
    setIsActiveMenu(false);
  }

  // Create dropdown response event on client side render
  let dropdownResponseEvent;
  useEffect(() => {
    dropdownResponseEvent = new CustomEvent(
      'dropdownResponseEvent', {
        detail: { active: activeSubmenuItem },
      },
    );
  }, [ssrDone, activeSubmenuItem]);

  // Dropdown event handler
  function handleDropdownEvent(event) {
    if (event.detail.isOpened) {
      setActiveSubmenuItem(event.detail.name.en);
    } else {
      setActiveSubmenuItem(undefined);
    }
  }

  // Handle active dropdown
  useEffect(() => {
    window.dispatchEvent(dropdownResponseEvent);
  }, [activeSubmenuItem]);

  // Login menu event handler
  // function handleLoginMenuEvent(event) {
  //   if (event.detail.close === false) {
  //     setActiveLoginMenu(true);
  //     setIsActiveMenu(false);
  //   } else {
  //     setActiveLoginMenu(false);
  //   }
  // }

  // Handle event listeners on client side render
  useEffect(() => {
    if (ssrDone && typeof window !== 'undefined') {
      window.addEventListener('closeMegaMenu', handleCloseEvent);
      window.addEventListener('dropdownEvent', handleDropdownEvent);
      // window.addEventListener('loginMenuEvent', handleLoginMenuEvent);
    }

    return () => {
      window.removeEventListener('closeMegaMenu', handleCloseEvent);
      window.removeEventListener('dropdownEvent', handleDropdownEvent);
      // window.removeEventListener('loginMenuEvent', handleLoginMenuEvent);
    };
  }, [ssrDone]);

  // Lock body scroll when menu is opened
  useEffect(() => {
    if (ssrDone && typeof window !== 'undefined') {
      if (isActiveMenu) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isActiveMenu]);

  return (
    <UserProfileContextConsumer>
      {({
        userName, userLastName, token, loadTokenForLS,
      }) => (
        <RoutesContext.Consumer>
          {({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            const ButtonLink = ({ link }) => (
              <span className="flex items-center justify-center w-4 tablet:w-8 h-8 -mb-px uppercase font-stratos mx-2 tablet:mx-0">
                {link.icon && <span className={`${link.icon} font-icon text-black text-18 tablet:text-20 laptop:text-30`} />}
              </span>
            );

            ButtonLink.propTypes = {
              link: PropTypes.shape().isRequired,
            };

            return (
              <div className="relative">
                <div
                  id={name}
                  className="flex -mt-10 absolute desktop:-mt-10 md:-mt-8 xs:-mt-27px xssm:-mt-8 items-center justify-center pin-r sm:pb-0 pb-3 laptop:pr-4 tablet:pr-6 transition-opacity"
                >
                  {(isMobile) ? null : <ButtonLinkWithBadge />}

                  {/* {activeLoginMenu && (
                    <div key="activeLoginMenuWrapper">
                      <UserMenuPopup
                        ButtonLink={ButtonLink}
                        // link={link}
                        localization={localization}
                        userName={userName}
                        userLastName={userLastName}
                        token={token}
                        language={localization.language}
                        loadTokenForLS={loadTokenForLS}
                        // history={this.props.history}
                        // formText={link.popUpFormText}
                        // sourceWeb={link.sourceWeb}
                      />
                    </div>
                  )} */}
                  {params.map((link) => {
                    if (link.key === 'login') {
                      // First we will check we have a correct login at local storage
                      const fullName = userName ? `${userName || ''} ${userLastName || ''}` : null;

                      const currentUserNameProfile = fullName && fullName.length ? {
                        en: `${fullName.length < 20 ? fullName : `${fullName.substring(0, 20)}...`}`,
                      } : {
                        en: 'INICIA SESIÓN',
                        es: 'INICIA SESIÓN',
                      };

                      link.link = userName ? `/user-profile${(link.sourceWeb && `-${link.sourceWeb}`) || ''}` : `/login-screen${(link.sourceWeb && `-${link.sourceWeb}`) || ''}?e=sign-in`;
                      link.text = currentUserNameProfile;

                      // if not will show current button for login
                      // if we have a login we will change icon, text, and link for a user-profile page.
                    }

                    switch (link.key) {
                      case 'login': return (
                        <React.Fragment key={link.key}>
                          <UserMenuPopup
                            ButtonLink={ButtonLink}
                            link={link}
                            localization={localization}
                            userName={userName}
                            userLastName={userLastName}
                            token={token}
                            language={localization.language}
                            loadTokenForLS={loadTokenForLS}
                            history={this.props.history}
                            formText={link.popUpFormText}
                            sourceWeb={link.sourceWeb}
                          />
                        </React.Fragment>
                      );
                      case 'hamburguermenu': {
                        return (
                          <div
                            key={link.key}
                            className="flex items-center justify-center"
                          >
                            <div
                              className="cursor-pointer"
                              onClick={() => setIsActiveMenu(!isActiveMenu)}
                            >
                              <ButtonLink link={link} localization={localization} />
                            </div>
                            {
                              isActiveMenu && (
                                <React.Fragment key={link.key}>
                                  <ComponentIterator components={link.components} />
                                </React.Fragment>
                              )
                            }
                          </div>
                        );
                      }
                      default: return (
                        <Link key={link.key} to={`${link.link}`} className="flex flex-row mx-4">
                          <ButtonLink link={link} localization={localization} />
                        </Link>
                      );
                    }
                  })}
                </div>
                {isActiveSearch ? <SearchOverlay closeCallback={handleSearchOverlay}searchCallback={handleSearch} /> : null}
              </div>
            );
          }}
        </RoutesContext.Consumer>
      )
      }
    </UserProfileContextConsumer>
  );
};

TopHeaderSubmenuItemsContainer.propTypes = {
  name: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  mainParent: PropTypes.string,
  params: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  textColor: PropTypes.string,
};

TopHeaderSubmenuItemsContainer.defaultProps = {
  mainParent: null,
  textColor: 'white',
};

export default withRouter(TopHeaderSubmenuItemsContainer);
// export default withRouter(withStyles(styles)(TopHeaderSubmenuItemsContainer));
