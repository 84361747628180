import React from 'react';
import { PropTypes } from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { acceptInvitation, declineInvitation } from '../utils/helpers';
import { useChat } from '../context/chatContext';

const InviteButton = ({
  channel,
}) => {
  const { removeToast } = useToasts();
  const { userData } = useChat();

  const handleDeclineInvitation = async () => {
    if (channel) await declineInvitation({ channelUrl: channel.url, inviter: channel.inviter, userData });
    removeToast(channel.url);
  };

  const handleAcceptInvitation = async () => {
    if (channel) {
      await acceptInvitation({
        channelUrl: channel.url, inviter: channel.inviter, userData, name: channel.name,
      });
    }
    removeToast(channel.url);
  };

  return (
    <div className="flex flex-wrap justify-between text-sm normal-case w-full">
      <div className="font-americaRegular">
        <span className="font-americaBold">{channel.inviter.nickname}</span>
        {' '}
wants to talk with you
      </div>
      <div>
        <button className="text-base text-white hover:text-psworld-grey trans" onClick={handleAcceptInvitation} type="button">
          <span className="font-icon1 icon-verified" />
        </button>
        <button className="text-base pl-4 text-white hover:text-psworld-grey trans" onClick={handleDeclineInvitation} type="button">
          <span className="font-icon1 icon-deny" />
        </button>
      </div>
    </div>
  );
};

InviteButton.propTypes = {
  channel: PropTypes.shape({}).isRequired,
};

export default InviteButton;
