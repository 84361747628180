import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import stripTags from './helpers/stripTags';
import translateObj from '../../util/translateObj';

const FullLink = ({
  text, textColor = '#FF4646', linkTo, match, parentComponent,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const textColorTailwind = textColor && !textColor.includes('#');
      const textColorHex = textColor && textColor.includes('#');

      if (parentComponent === 'StandardArticlePage') {
        return (
          <h3
            style={textColorHex ? { color: textColor } : null}
            className={
                `${textColorTailwind && `text-${textColor}`} text-24 leading-28 laptop:text-36 laptop:leading-39 font-stratos font-bold laptop:mb-6 laptop:mt-4`}
          >
            {stripTags(translateObj(text, localization.language))}
          </h3>
        );
      }

      return (
        <a style={{}} href={`${linkTo}`}>
          <h3
            style={textColorHex ? { color: textColor } : null}
            className={
              `${textColorTailwind && `text-${textColor}`} text-24 leading-28 laptop:text-36 laptop:leading-39 font-stratos font-bold my-10`}
          >
            {stripTags(translateObj(text, localization.language))}
          </h3>

        </a>
      );
    }}
  </RoutesContext.Consumer>
);

export default withRouter(FullLink);

FullLink.propTypes = {
  textColor: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  parentComponent: PropTypes.string,
};

FullLink.defaultProps = {
  textColor: 'black',
  text: null,
  parentComponent: '',
};
