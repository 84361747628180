import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link, withRouter } from 'react-router-dom';

import Tag from '../Buttons/Tag';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import ImageLoader from './ImageLoader';

const PlusTag = ({ handleMoreTagClick, isVisibleTags }) => (
  <span
    onClick={handleMoreTagClick}
    className="leading-normal trans hover:bg-white hover:text-black hover:border-white inline-block ml-0 font-americaMonoBold text-xxs cursor-pointer text-white align
    rounded px-2 py-3px bg-black mb-1 border-solid border border-black"
  >
    <span className="uppercase">{isVisibleTags ? '-' : '+'}</span>
  </span>
);

const LauncherItemTriple = ({
  itemLayout, image, date, title, tags, url, match, category, changeCategory,
}) => {
  const [isVisibleTags, setVisibilityTags] = useState(false);

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const urlWithSlash = url[0] === '/' || /^https?:\/\//.test(url) ? url : `/${url}`;
        let tagsCount = 0;
        if (tags) {
          tagsCount = tags.length;
        }
        return (
          <div className={`flex launcherItemData items-center ${itemLayout}`} style={{ overflow: isVisibleTags ? 'visible' : 'hidden' }}>
            <div className="w-1/3 overflow-hidden">
              <Link to={urlWithSlash}>
                <img src={imageModifier(translateObj(image, localization.language), '400x400')} alt={title.en} />
                {/* <img src={imageModifier(translateObj(image, localization.language), '400x400')} alt={title.en} /> */}
              </Link>
            </div>
            <div className="w-2/3 px-4 lg:px-2 items-start justify-center text-black sm:px-2 md:px-2 relative">
              {date && <span className="font-americaMonoRegular text-xs sm:text-xxs">{date}</span>}
              {title && (
              <Link to={urlWithSlash}>
                <h2 className="text-18 lg:text-14 text-black font-americaBlack sm:my-2 my-3 sm:text-14 md:text-12">{translateObj(title, localization.language)}</h2>
              </Link>
              )}
              <div className="flex">
                {tags && (
                <div className="flex flex-wrap overflow-hidden" style={{ maxHeight: '25px', maxWidth: '84%' }}>
                  {tags.map((tag, i) => <Tag changeCategory={() => changeCategory(tag)} text={tag} key={`${tag}-${i}`} category={tag} />).slice(0, 2)}
                </div>
                )}
                <div className="flex flex-wrap overflow-hidden">
                  {tagsCount > 2 && <PlusTag handleMoreTagClick={() => setVisibilityTags(!isVisibleTags)} isVisibleTags={isVisibleTags} />}
                </div>
              </div>
              {isVisibleTags && (
              <div
                className="tag-box absolute pin-r p-4 bg-white h-100 flex flex-col justify-center"
                style={{
                  top: '50%', transform: 'translateY(-50%)', zIndex: 2,
                }}
              >
                {tags.map((tag, i) => <Tag changeCategory={() => changeCategory(tag)} text={tag} key={`${tag}-${i}`} category={tag} />)}
                <span className="absolute pin-r pin-t pr-1 pt-1 font-icon text-black text-xxs icon-close cursor-pointer" onClick={() => setVisibilityTags(!isVisibleTags)} />
              </div>
              )}
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

LauncherItemTriple.propTypes = {
  itemLayout: PropTypes.string.isRequired,
  match: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.shape({
    es: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  url: PropTypes.string.isRequired,
}.isRequired;

export default withRouter(LauncherItemTriple);
