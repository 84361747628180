/* eslint-disable no-nested-ternary */
import React, { Component, Fragment } from 'react';
import Swiper from 'react-id-swiper';
import { PropTypes } from 'prop-types';
import TicketMain from './TicketMain';
import TicketConcert from './TicketConcert';
import Ticket from './Ticket';
import getTicketsByEventSlugName from '../../api/graphql/queries/getTicketsByEventSlugName';
import getTicketsBySlugName from '../../api/graphql/queries/getTicketsBySlugName';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';

const params = {
  slidesPerView: 'auto',
  spaceBetween: 0,
  Swiper: 'carousel-tickets',
  pagination: {
    el: '.swiper-pagination-tickets',
    dynamicBullets: false,
  },
  keyboard: true,
  grabCursor: true,
  watchOverflow: true,
};

export default class CarouselTickets extends Component {
  state = {
    tickets: null,
    isLoading: true,
    deviceScreen: null,
    parentWidth: 0,
  };

  responsiveUtility = React.createRef();

  parentWidth = React.createRef();

  componentDidMount() {
    this.processTickets();
    this.handleResize();
    this.setState({
      parentWidth: this.parentWidth.current.clientWidth,
    });
  }

  componentDidUpdate(prevProps) {
    const { eventSlugnames, data, ticketData } = this.props;
    if (prevProps.eventSlugnames !== eventSlugnames || prevProps.data !== data || prevProps.ticketData !== ticketData) {
      this.processTickets();
    }
  }

  handleResize = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.setState({
      deviceScreen,
      parentWidth: this.parentWidth.current.clientWidth,
    });
  };

  processTickets = () => {
    const { eventSlugnames, data, ticketData } = this.props;
    if (eventSlugnames && eventSlugnames.length) {
      getTicketsByEventSlugName(eventSlugnames).then((tickets) => {
        this.setState({ tickets, isLoading: false });
      });
    } else if (data && data.length) {
      const ticketsSlugNamesArray = [];
      data.forEach(ticket => ticketsSlugNamesArray.push(ticket.ticketSlugName));
      getTicketsBySlugName(ticketsSlugNamesArray).then((res) => {
        this.setState({
          tickets: res,
          isLoading: false,
        });
      });
    } else if (ticketData) {
      this.setState({ tickets: ticketData, isLoading: false });
    }
  }

  renderCarousel = () => {
    const {
      tickets, isLoading, deviceScreen, parentWidth,
    } = this.state;
    const { category } = this.props;
    if (tickets && tickets.length) {
      const width = 16 * tickets.length - 1 + (tickets.length * (deviceScreen === 'md' ? 230 : deviceScreen === 'sm' ? 260 : 330));
      let styles = {
        width: `${width}px`,
      };
      if (width > parentWidth) {
        styles = {};
      }
      return !isLoading && tickets ? (
        tickets.length === 1 ? (
          <div className="w-full sm:mb-4">
            <div className="c-tickets">
              <TicketMain {...tickets[0]} category={category} />
            </div>
          </div>
        ) : (
          <div className="w-full full-width sm:mb-4">
            <div className="c-tickets" style={styles}>
              <Swiper {...params}>
                {tickets.map((ticket, i) => (ticket.ticketSlugName ? (
                  <div className="w-auto mb-8" key={i}>
                    <TicketMain {...ticket} category={category} />
                  </div>
                ) : (
                  <div className="sm:pb-8" key={i}>
                    <Ticket {...ticket} />
                  </div>
                )))}
              </Swiper>
            </div>
          </div>
        )
      ) : (
        'loading'
      );
    }
    return <div />;
  };

  renderCarouselIternary = () => {
    const { tickets, isLoading, deviceScreen } = this.state;
    const { isConcert, category } = this.props;
    return !isLoading && tickets ? (
      deviceScreen === 'sm' ? (
        <div className="w-full full-width sm:mb-4">
          <div className="c-tickets">
            <Swiper {...params} className="pb-20">
              {tickets.map((ticket, i) => (isConcert ? (
                <div className="w-auto" key={i}>
                  <TicketConcert {...ticket} category={category} />
                </div>
              ) : ticket.ticketSlugName ? (
                <div className="w-auto" key={i}>
                  <TicketMain {...ticket} category={category} />
                </div>
              ) : (
                <div className="w-auto" key={i}>
                  <Ticket {...ticket} />
                </div>
              )))}
            </Swiper>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap -mx-2 justify-center">
          {tickets.map((ticket, i) => (
            <Fragment key={i}>
              {isConcert ? (
                <div className="mx-2 my-2">
                  <TicketConcert {...ticket} category={category} />
                </div>
              ) : ticket.ticketSlugName ? (
                <div className="mx-2 my-2">
                  <TicketMain {...ticket} category={category} />
                </div>
              ) : (
                <div className="mx-2 my-2">
                  <Ticket {...ticket} />
                </div>
              )}
            </Fragment>
          ))}
        </div>
      )
    ) : (
      'loading'
    );
  };

  render() {
    const { isGrid } = this.props;
    const { isLoading } = this.state;

    return (
      <>
        <PSResponsiveUtility ref={this.responsiveUtility} deviceWidthCallback={this.handleResize} />
        <div ref={this.parentWidth} className="w-full" />
        {!isLoading ? (isGrid ? this.renderCarouselIternary() : this.renderCarousel()) : null}
      </>
    );
  }
}

CarouselTickets.propTypes = {
  eventSlugnames: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  ticketData: PropTypes.arrayOf(PropTypes.shape({})),
  isGrid: PropTypes.bool,
  isConcert: PropTypes.bool,
  category: PropTypes.string,
};

CarouselTickets.defaultProps = {
  eventSlugnames: null,
  data: null,
  ticketData: null,
  isGrid: false,
  isConcert: false,
  category: 'black',
};
