/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { propTypes, withFormsy } from 'formsy-react';

class FormSelect extends Component {
  changeValue = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.value);
  };

  validate = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.value);
  };

  render() {
    const {
      name,
      title,
      showError,
      getErrorMessage,
      isValid,
      options: optionsProps,
      getValue,
    } = this.props;

    const errorMessage = getErrorMessage();

    const options = [];

    optionsProps.map((option, i) => {
      if (i === 0) {
        options.push(<option key={option.title + option.value + 1} value="" disabled hidden>{option.value}</option>);
      } else {
        options.push(
          <option key={option.title + option.value} value={option.value}>
            {option.title}
          </option>,
        );
      }
      return options;
    });

    return (
      <div className="px-3 mb-7.5 sm:w-full md:w-full w-1/3">
        <label className="flex flex-col" htmlFor={name}>
          {title}
          <select
            onChange={this.changeValue}
            onBlur={this.validate}
            name={name}
            value={getValue() || ''}
            className={`mt-4 appearance-none rounded-full border-1 p-4 bg-inherit ${showError() ? 'border-red text-red' : 'border-black text-black'} ${isValid() ? 'border-green text-green' : 'border-black text-black'}`}
          >
            {options}
          </select>
        </label>
        <span className="flex flex-col text-red mt-4">{errorMessage}</span>
      </div>
    );
  }
}

FormSelect.propTypes = {
  ...propTypes,
};

export default withFormsy(FormSelect);
