import React from 'react';
import { PropTypes } from 'prop-types';
import AddEvent from '../../AddEvent';

import imageModifier from '../../helpers/imageModifier';

const className = 'absolute pin-t pin-l w-full h-full flex justify-end items-start';

const CoverScreenPlayer = ({
  addeventId,
  coverImageUrl,
  PLAYER_RATIO,
}) => {
  const imageurl = `url(${coverImageUrl})`;
  const backgroundStyle = {
    backgroundImage: imageurl,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  return (
    <>
      <div className="bg-black flex flex-col justify-center">
        {coverImageUrl && (
        <div className={`${'relative w-full bg-black'} `} style={{ paddingTop: PLAYER_RATIO }}>
          <div
            className={className}
            style={backgroundStyle}
          >
            {addeventId && (
            <div className="mr-8 mt-16 sm:mt-10">
              <AddEvent addeventId={addeventId} />
            </div>
            )}
          </div>
        </div>
        )}
      </div>
    </>
  );
};

CoverScreenPlayer.propTypes = {
  addeventId: PropTypes.string,
  coverImageUrl: PropTypes.string.isRequired,
  PLAYER_RATIO: PropTypes.string,

};

CoverScreenPlayer.defaultProps = {
  addeventId: null,
  PLAYER_RATIO: '56.25%',
};

export default CoverScreenPlayer;
