import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { animateScroll as scroll } from 'react-scroll';
import ReCAPTCHA from 'react-google-recaptcha';

// import Collapse from '@material-ui/core/Collapse';
// import List from '@material-ui/core/List';

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { buttonClasses } from '../OnDemand/OnDemandUtils/onDemandUtils';
// import SpotifyLogin from 'react-spotify-login';
import SpotifyLogin from './SpotifyLogin/SpotifyLogin';

// import ListItemBlock from './ListItemBlock';
import userSocialLogin from '../../../api/graphql/mutations/userSocialLogin';
import userWebLogin from '../../../api/graphql/mutations/userWebLogin';

import NormalBodyText from '../Text/NormalBodyText';
import getLocalization from '../../../util/localization';
import RoutesContext from '../../../context/routesContext';
import { UserProfileContextConsumer } from '../../../context/userProfileContext';
import translateObj from '../../../util/translateObj';

const responseGoogle = async (payload) => {
  console.log('payload', payload);
  const result = await userSocialLogin(payload.tokenId, 'google').then((response) => {
    if (response && response.token) {
      localStorage.setItem(process.env.AUTH_TOKEN, response.token);
      document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
      return response;
    } if (response.error) {
      return response;
    }
    return { isValid: false, needToRegister: true, userPayload: payload };
  }).catch((err) => {
    console.log('Some error happened on fetch of fetchPostDescription:', err);
  });

  return result;
};

const responseSpotify = async (payload, sourceWeb = '') => {
  console.log('payload spotify', payload);
  const result = await userSocialLogin(payload.code, 'spotify', sourceWeb).then((response) => {
    if (response && response.token) {
      localStorage.setItem(process.env.AUTH_TOKEN, response.token);
      document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
      return response;
    } if (response.error) {
      return response;
    }
    let userPayload = {};

    if (response && response.userDetails && Object.keys(response.userDetails).length) {
      const [name, surname] = response.userDetails.display_name.split(' ');
      userPayload = {
        profileObj: {
          spotifyToken: response.userDetails.spotifyToken,
          email: response.userDetails.email,
          givenName: name,
          familyName: surname,
          spotifyId: response.userDetails.userID,
        },
      };
    }


    return { isValid: false, needToRegister: true, userPayload };
  }).catch((err) => {
    console.log('Some error happened on fetch of fetchPostDescription:', err);
  });
  return result;
};

const responseFacebook = async (payload) => {
  console.log(payload);

  const result = await userSocialLogin(payload.accessToken, 'facebook').then((response) => {
    if (response && response.token) {
      localStorage.setItem(process.env.AUTH_TOKEN, response.token);
      document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
      return response;
    } if (response.error) {
      return response;
    }
    const [name, surname] = payload.name.split(' ');
    const userPayload = {
      profileObj: {
        email: payload.email,
        givenName: name,
        familyName: surname,
        facebookId: payload.userID,
      },
    };
    return { isValid: false, needToRegister: true, userPayload };
  }).catch((err) => {
    console.log('Some error happened on fetch of fetchPostDescription:', err);
  });

  return result;
};

const style = {
  list: {
    padding: 0,
    marginBottom: 30,
  },
  root: {
    backgroundColor: 'white',
    height: '2px',
    padding: 0,
  },
  item: {
    padding: 0,
  },
};

const userTitle = {
  es: 'CORREO ELECTRONICO',
  en: 'E-MAIL',
  ca: 'CORREU ELECTRÒNIC',
  pt: 'CORREIO ELETRÔNICO',
};

const passwordTitle = {
  es: 'CONTRASEÑA',
  en: 'PASSWORD',
  ca: 'CONTRASENYA',
  pt: 'password',
};

const submitTitle = {
  es: 'ACCEDER',
  en: 'SIGN IN',
  ca: 'ACCEDIR',
  pt: 'Entrar',
};

// const registerTitle = {
//   es: 'REGISTRATE',
//   en: 'REGISTER',
//   ca: 'REGISTRA\'T',
//   pt: 'REGISTER',
// };

const forgotPassword = {
  es: 'He olvidado mi contraseña.',
  en: 'I forgot my password.',
  ca: 'He oblidat la meva contrasenya.',
  pt: 'Esqueci a minha senha.',
};

const recoverPassword = {
  es: 'Recuperar contraseña',
  en: 'Recover password',
  ca: 'Recuperar contrasenya',
  pt: 'Recuperar senha',
};

const recaptchaRef = React.createRef();

class SignInForm extends Component {
  state = {
    open: true,
    isFilled: false,
    hasError: false,
    loginStatus: 'notLogged',
    isReCaptchaDone: false,
    name: '',
    password: '',
    currentData: {},
    showPassword: false,
  };


  handleClick = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { name, password } = this.state;
    if (prevState.name !== name || prevState.password !== password) {
      if (name.length > 4 && password.length > 4 && name.includes('@') && name.includes('.')) {
        this.setState({ isFilled: true });
      } else {
        this.setState({ isFilled: false });
      }
    }
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e, tokenFn, lang) => {
    e.preventDefault();
    this.setState({
      isReCaptchaDone: false,
      loginStatus: 'notLogged',
      hasError: false,
    });
    const { searchParamForRegisterRedirect, sourceWeb } = this.props;
    const { name, password } = this.state;
    const loginObj = {};
    loginObj.email = name.toLowerCase();
    loginObj.password = password;
    userWebLogin({ ...loginObj }).then((res) => {
      if (res) {
        if (res.isValid) {
          this.setState({
            loginStatus: 'logged',
            name: '',
            password: '',
          });
          localStorage.setItem(process.env.AUTH_TOKEN, res.token);
          document.cookie = `${process.env.AUTH_TOKEN}=${res.token}; Secure; HttpOnly`;
          tokenFn();
          scroll.scrollToTop({ smooth: 'linear' });
          const redirectPath = searchParamForRegisterRedirect || `/${lang}${sourceWeb && `/${sourceWeb}`}`;
          setTimeout(() => {
            this.props.history.push(redirectPath);
          }, 1000);
          // this.props.history.push(`/${lang}/user-profile`);
        } else if (res.token) {
          let loginStatusQuery = 'notLogged';
          switch (res.token) {
            case 'notActive':
              loginStatusQuery = 'notActive';
              break;

            case 'notFound':
              loginStatusQuery = 'userIncorrect';
              break;

            case 'wrongPassword':
              loginStatusQuery = 'userIncorrect';
              break;

            case 'tooManyPasswordAttempts':
              loginStatusQuery = 'tooManyPasswordAttempts';
              break;

            default:
              break;
          }

          this.setState({
            loginStatus: loginStatusQuery,
            hasError: true,
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - loginForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - loginForm failed to submit!`);
      }
    });
    console.log('form submited');
  };

  registerUserFromSocial = (userPayload, lang, socialNetwork) => {
    const { getCurrentData } = this.props;
    const { profileObj } = userPayload;
    const currData = {
      ...profileObj,
      isSocialLogin: socialNetwork,
    };
    this.setState({
      open: false,
      currentData: currData,
    });
    getCurrentData(currData, lang);
  }

  handleShowPassword = () => {
    this.setState((prevState) => {
      this.setState({
        showPassword: !prevState.showPassword,
      });
    });
  }


  _loginForm = (language, loadTokenForLS) => {
    const {
      text, socialLogin, formText,
    } = this.props;
    const {
      open, isFilled, loginStatus, hasError, showPassword, isReCaptchaDone,
    } = this.state;
    const fullWidth = true;
    const enableButtonIfCaptchaIsMissingOrNotFilled = loginStatus === 'tooManyPasswordAttempts' ? isReCaptchaDone && isFilled : isFilled;
    return (
      <div className="flex flex-wrap bg-white">
        <div className={`w-1/4 sm:hidden ml-8 -mr-8 ${fullWidth && 'hidden'}`}>
          <span className="invisible">X</span>
        </div>
        <div className={`${fullWidth ? 'w-full p-8 sm:py-6' : 'w-3/4 pr-8 md:pr-24 sm:w-full sm:pr-0'} `}>
          {text && (
            <div className={`pb-8 border-black border-dotted ${!fullWidth && 'sm:mx-5'} border-b-1`}>
              <NormalBodyText text={text} />
            </div>
          )}
          <form className={`flex flex-row ${!fullWidth && 'sm:mx-5'} py-8 flex-wrap sm:pb-4 ${!text && 'pt-0'} `} onSubmit={e => this.handleSubmit(e, loadTokenForLS, language)}>
            <label className="flex flex-col pr-6 font-americaMonoRegular text-12 sm:pr-0 sm:w-full w-1/3">
              {translateObj(formText.userTitle, language)}
              <input type="text" name="name" onChange={this.handleChange} className="mt-4 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 w-full" />
            </label>
            <label className="flex flex-col pr-6 font-americaMonoRegular sm:pt-6 sm:pr-0  sm:w-full w-1/3 text-12 relative">
              {translateObj(formText.passwordTitle, language)}
              <input type={showPassword ? 'text' : 'password'} name="password" onChange={this.handleChange} className="mt-4 rounded-full border-1 p-4 pr-10 sm:pr-20 bg-inherit border-black text-black w-auto text-12 w-full " />
              <button type="button" onClick={this.handleShowPassword} className=" px-1 text-black rounded-xl absolute pin-r pin-b mr-8 mb-3" style={{ bottom: '2px' }}>
                <span className={`font-icon1 text-18 text-black ${showPassword ? 'icon-visibility-4' : 'icon-visibility-3'} `} />
              </button>
            </label>
            <div className="w-1/3 sm:w-full flex items-center">
              <input
                disabled={!enableButtonIfCaptchaIsMissingOrNotFilled}
                type="submit"
                value={translateObj(formText.submitTitle, language)}
                className={`rounded-full border-1 bg-inherit ${enableButtonIfCaptchaIsMissingOrNotFilled ? 'bg-black text-white border-1 border-black' : 'bg-gray200 text-black border-grey'}  text-12 font-americaMonoBold sm:static sm:mt-6 sm:mx-0 sm:w-full cursor-pointer   text-center xl:w-s15 lg:w-s15 sm:w-full xl:max-w-xs md:w-s23 lg:max-w-xs md:mt-4`}
                style={{
                  alignSelf: 'flex-end',
                  minHeight: '3rem',
                }}
              />

            </div>
            {!fullWidth && (
              <div className="font-tiemposRegular text-14 mt-4 sm:w-full md:w-s23 md:ml-6 md:pb-0 pb-4 cursor-default">
                {translateObj(formText.forgotPassword, language)}
                {' '}
                <Link to="/recover-password">
                  <span className="text-black underlinefont-bold font-underlined cursor-pointer">
                    {translateObj(formText.recoverPassword, language)}
                  </span>
                </Link>
              </div>
            )}
            {
              loginStatus === 'tooManyPasswordAttempts'
                ? (
                  <div className="mt-4">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      onChange={() => this.setState({
                        isReCaptchaDone: true,
                      })}
                      sitekey="6LeVsbsUAAAAAI8Crd2wQ8eNINyoLzAT0uM01KKe"
                    />

                  </div>
                ) : null
            }

          </form>
          {fullWidth && (
            <div className="sm:mt-0 font-tiemposRegular text-14 cursor-default sm:text-center">
              {translateObj(formText.forgotPassword, language)}
              {' '}
              <Link to="/recover-password">
                <span className="font-bold font-underlined cursor-pointer">
                  <span className="text-black underline">
                    {translateObj(formText.recoverPassword, language)}
                  </span>
                </span>
              </Link>
            </div>
          )}
          {(hasError)
            && <div className="font-americaMonoRegular text-12 text-red mt-4">{translateObj(formText[loginStatus], language)}</div>}
          {socialLogin && (
            <div className={`mt-4 border-black border-dotted border-t-1 flex flex-col ${!fullWidth && 'sm:mx-5 py-8 sm:pb-4'} pt-8 sm:pb-0 sm:pt-8 flex-wrap  ${!text && 'pt-0'} `}>
              <label className="flex flex-col pr-6 font-americaMonoRegular text-12 sm:pr-0 sm:w-full">
                {translateObj(socialLogin.headline, language)}
              </label>
              <div className={`flex flex-row pt-4 xl:pb-0 lg:pb-0 flex-wrap ${!fullWidth && 'md:flex-col py-4'}`}>
                {socialLogin.facebook && this._socialButton('facebook', language, loadTokenForLS)}
                <div className="sm:mx-0 sm:w-full">
                  {socialLogin.google && this._socialButton('google', language, loadTokenForLS)}
                </div>
                <div className="sm:mx-0 sm:w-full">
                  {socialLogin.spotify && this._socialButton('spotify', language, loadTokenForLS)}
                </div>
                {socialLogin.twitter && this._socialButton('twitter', language, loadTokenForLS)}
              </div>
            </div>
          )}
          <div />
        </div>
      </div>
    );
  }

  _socialButton = (type, lang, loadTokenForLS) => {
    const { searchParamForRegisterRedirect, sourceWeb } = this.props;
    let jsxReturned = (<span />);
    const fullWidth = false;

    switch (type) {
      case 'twitter':
        jsxReturned = (
          <button
            type="button"
            className={`rounded-full bg-inherit bg-twitter text-white text-12 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center sm:w-full md:mt-4 px-12 lg:px-16 xl:px-16  py-2 ml-4 flex items-center sm:ml-0 justify-center ${fullWidth && 'md:px-5 sm:h-10 sm:mt-4'} ${!fullWidth && 'md:ml-0 md:px-16 sm:mt-4 md:h-10 sm:h-10 '}`}
          >
            <div className="font-icon text-white icon-twitter-simple text-20" />
            <div className="mx-4">TWITTER CONNECT</div>
          </button>
        );
        break;
      case 'google':
        jsxReturned = (
          <GoogleLogin
            clientId="374001476805-jdr4d87slvoaobdajc0bas161r661rfs.apps.googleusercontent.com"
            render={renderProps => (
              <button
                type="button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className={`rounded-full bg-inherit bg-google text-white text-12 font-americaMonoBold sm:static sm:mx-0 my-1 sm:w-full w-74 cursor-pointer text-center px-12 lg:px-16 xl:px-16 py-2 flex items-center justify-center ${fullWidth && 'md:px-5 sm:h-10 sm:mt-2'} ${!fullWidth && 'md:px-16 h-10 sm:mt-2 mr-6'}`}
              >
                {/* <div className="font-icon text-white icon-google-plus text-18 -mt-px mr-2" /> */}
                <div className="mx-0 xs:text-xxs sm:text-xxs sm:mx-0">SIGN IN WITH GOOGLE</div>
              </button>
            )}
            onSuccess={async (data) => {
              const response = await responseGoogle(data);
              if (response) {
                const {
                  isValid, needToRegister, userPayload, error,
                } = response;
                if (isValid) {
                  loadTokenForLS();
                  const redirectPath = searchParamForRegisterRedirect || `/${lang}${sourceWeb && `/${sourceWeb}`}`;
                  this.props.history.push(redirectPath);
                  // this.props.history.push(`/${lang}/user-profile`);
                } else {
                  if (error) {
                    this.setState({
                      hasError: true,
                      loginStatus: error,
                    });
                  }
                  if (needToRegister) {
                    // alert("usuario no registrado completa tu registro")
                    this.registerUserFromSocial(userPayload, lang, 'google');
                  } else {
                    console.log('**** User Not Valid by Server, Sorry ****');
                  }
                }
              }
            }}
            onFailure={() => { console.log('google login failed'); }}
            cookiePolicy="single_host_origin"
          />
        );
        break;

      case 'spotify':
        const getRedirectUriSpotifyBasedOnSourceWeb = (source) => {
          switch (source) {
            case 'pslife':
              return 'https://onps.life/en/';
            case 'pro-online':
              return 'https://onps.pro/en/';
            case '':
              return `${process.env.FRONT_URL}/en/`;
            default:
              return `${process.env.FRONT_URL}/en/`;
          }
        };
        const redirectUriSpotify = getRedirectUriSpotifyBasedOnSourceWeb(sourceWeb);
        jsxReturned = (
          <>
            <SpotifyLogin
              clientId="76a75784cbb541e69bc57569652757e2"
              icon={false}
              redirectUri={redirectUriSpotify}
              className={`rounded-full bg-inherit bg-spotify uppercase text-white text-12 xs:text-xxs sm:text-xxs my-1 font-americaMonoBold sm:static sm:mx-0 sm:w-full w-74 cursor-pointer text-center px-12 lg:px-16 xl:px-16 py-2 flex items-center justify-center ${fullWidth && 'md:px-5 sm:h-10 sm:mt-2 mr-4'} ${!fullWidth && 'md:px-16 h-10 sm:mt-2 mr-6'}`}
              onSuccess={async (data) => {
                const response = await responseSpotify(data, sourceWeb);
                if (response) {
                  const {
                    isValid, needToRegister, userPayload, error,
                  } = response;
                  if (isValid) {
                    loadTokenForLS();
                    const redirectPath = searchParamForRegisterRedirect || `/${lang}${sourceWeb && `/${sourceWeb}`}`;
                    this.props.history.push(redirectPath);
                    // this.props.history.push(`/${lang}/user-profile`);
                  } else {
                    if (error) {
                      this.setState({
                        hasError: true,
                        loginStatus: error,

                      });
                    }
                    if (needToRegister) {
                      // alert("usuario no registrado completa tu registro")
                      this.registerUserFromSocial(userPayload, lang, 'spotify');
                    } else {
                      console.log('**** User Not Valid by Server, Sorry ****');
                    }
                  }
                }
              }}
              // onSuccess={async (data) => { const response = await responseSpotify(data); }}

              onFailure={(res) => { console.log('spotify login failed: ', res); }}
            />
          </>
        );
        // https://accounts.spotify.com/authorize?client_id=76a75784cbb541e69bc57569652757e2&response_type=code&redirect_uri=http://localhost:3000&scope=user-read-private%20user-read-email

        // https://accounts.spotify.com/en/login?continue=https:%2F%2Faccounts.spotify.com%2Fauthorize%3Fscope%3Duser-read-private%26response_type%3Dcode%26redirect_uri%3Dhttp%253A%252F%252Flocalhost%253A3000%26client_id%3D76a75784cbb541e69bc57569652757e2
        break;

      case 'facebook':
        jsxReturned = (
          <FacebookLogin
            // dev
            // appId="633651647366807"
            // prod
            // appId="830365707464738"
            appId="2691426617774080"
            // rdl
            fields="name,email,picture"
            render={renderProps => (
              <button
                type="button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className={`rounded-full bg-inherit bg-facebook text-white text-12 font-americaMonoBold sm:static sm:mx-0 sm:w-full w-74 cursor-pointer text-center px-12 lg:px-16 xl:px-16 py-2 flex items-center my-1 justify-center ${fullWidth && 'md:px-5 sm:h-10 sm:mt-2 mr-4'} ${!fullWidth && 'md:px-16 md:h-10 sm:h-10 sm:mt-2 mr-6'}`}
              >
                {/* <div className="font-icon text-white icon-facebook-simple text-18 mr-2 sm:mr-1 -mt-px" /> */}
                <div className="mx-0 xs:text-xxs sm:text-xxs sm:mx-0">SIGN IN WITH FACEBOOK</div>
              </button>
            )}
            callback={async (data) => {
              const response = await responseFacebook(data);
              if (response) {
                const {
                  isValid, needToRegister, userPayload, error,
                } = response;
                if (isValid) {
                  loadTokenForLS();
                  const redirectPath = searchParamForRegisterRedirect || `/${lang}${sourceWeb && `/${sourceWeb}`}`;
                  this.props.history.push(redirectPath);
                  // this.props.history.push(`/${lang}/user-profile`);
                } else {
                  if (error) {
                    this.setState({
                      hasError: true,
                      loginStatus: error,
                    });
                  }
                  if (needToRegister) {
                    // alert("usuario no registrado completa tu registro")
                    this.registerUserFromSocial(userPayload, lang, 'facebook');
                  } else {
                    console.log('**** User Not Valid by Server, Sorry ****');
                  }
                }
              }
            }}
            onFailure={() => { console.log('failed'); }}
          />

        );
        break;

      default:
        console.log('siginform invalid social type');
      // case 'facebook':
      //   jsxReturned = (
      //     <button type="button" className={`rounded-full bg-inherit bg-facebook text-white text-12 font-americaMonoBold sm:static sm:mx-0 sm:w-full cursor-pointer text-center sm:w-full md:mt-4 px-12 lg:px-16 xl:px-16 py-2 flex items-center justify-center ${fullWidth && 'md:px-5 sm:h-10 sm:mt-2'} ${!fullWidth && 'md:px-16 md:h-10 sm:h-10 sm:mt-2'}`}>
      //       <div className="font-icon text-white icon-facebook-simple text-16" />
      //       <div className="mx-4">FACEBOOK CONNECT</div>
      //     </button>
      //   );
      //   break;
      // default:
      //   break;
    }
    return jsxReturned;
  }

  render() {
    const {
      match,
    } = this.props;

    return (
      <UserProfileContextConsumer>
        {({ loadTokenForLS, token }) => (
          <RoutesContext.Consumer>
            {({ language: contextLanguage, enteredFrom }) => {
              const localization = getLocalization(contextLanguage, enteredFrom, match);
              return (
                <div className="w-full">
                  {this._loginForm(localization.language, loadTokenForLS)}
                </div>
              );
            }}
          </RoutesContext.Consumer>
        )}
      </UserProfileContextConsumer>
    );
  }
}

SignInForm.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  loginTitle: PropTypes.shape({}),
  loginTitleLink: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  socialLogin: PropTypes.shape({}),
  formText: PropTypes.shape({}),
  searchParamForRegisterRedirect: PropTypes.string,
  sourceWeb: PropTypes.string,
};

SignInForm.defaultProps = {
  text: null,
  loginTitle: null,
  loginTitleLink: null,
  fullWidth: false,
  socialLogin: null,
  searchParamForRegisterRedirect: '',
  formText: {
    userTitle, passwordTitle, submitTitle, forgotPassword, recoverPassword,
  },
  sourceWeb: '',
};


export default withRouter(SignInForm);
