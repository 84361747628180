import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link, withRouter } from 'react-router-dom';
import imageModifier from './helpers/imageModifier';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';
import LoadIcon from './LoadIcon';
import MenuPopUpLoginButton from './Login/MenuPopUpLoginButton';

// const { isFavoritesActive } = process.env;
const isFavoritesActive = true;

class LineupTableItem extends PureComponent {
  state = {
    active: false,
    starDisabled: false,
  };

  activeCard = () => {
    this.setState(prevState => ({ active: !prevState.active }));
  };

  handleFavoriteLogic = (artistSetSlugName, lang) => {
    const { starDisabled } = this.state;
    const { isUserLoggedIn, history, handleSetArtistSetFavorite } = this.props;

    if (!starDisabled) {
      if (isUserLoggedIn) {
        this.setState({ starDisabled: true });
        // logic to update user favs
        handleSetArtistSetFavorite([artistSetSlugName]);
        setTimeout(() => {
          this.setState({ starDisabled: false });
        }, 2000);
      } else {
        // please log in
        history.push(`/${lang}/login-screen?e=sign-in`);
      }
    }
  }

  render() {
    const {
      artist: {
        artistName, artistSetReadableName, image, dateTimeStartReal, venueReadableName, artistSetIsFavorite, artistSlugName, artistSetSlugName, shortTitle,
      },
      showTime,
      width,
      reset,
      setReset,
      eventSlug,
      match,
      showDate,
      showDateTime,
      showVenue,
      timezone,
      category,
      isUserLoggedIn,
    } = this.props;

    if (reset) {
      this.setState({ active: false });
      setReset();
    }
    const { active, starDisabled } = this.state;
    const artistStartDate = moment(dateTimeStartReal / 1000, 'X');

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          // moment.locale(localization.language);
          return (
            <>
              <tr className={`border-b border-black relative ${active && width > 640 ? 'bg-barcelona' : ''}`}>
                <td>{starDisabled ? <LoadIcon height={15} width={15} classes="w-auto block justify-start pl-8 sm:pl-0" color={category} /> : (isFavoritesActive && isUserLoggedIn) ? <span className={`font-icon cursor-pointer ${artistSetIsFavorite ? 'icon-star-solid ' : 'icon-star'} text-${category} pl-8 sm:pl-0`} onClick={() => this.handleFavoriteLogic(`${artistSlugName}|${artistSetSlugName}`, localization.language)} /> : <MenuPopUpLoginButton showText={false} isCentered iconClasses=" sm:pl-0 text-base sm:text-base md:text-base" textColor={category} sourceWeb={category === 'pro-online' ? 'pro-online' : ''} language={localization.language} icon="font-icon  icon-star" />}</td>
                <td className="flex items-center sm:items-start sm:my-5 my-2 ">
                  {translateObj(image, localization.language) && <img className="sm:block hidden ml-2" src={imageModifier(translateObj(image, localization.language), '70x60')} alt={translateObj(artistSetReadableName, localization.language)} />}
                  {translateObj(image, localization.language) && <img className="sm:hidden block" src={imageModifier(translateObj(image, localization.language), '97x50')} alt={translateObj(artistSetReadableName, localization.language)} />}
                  <div className="flex flex-col ml-5 relative w-full pr-4">
                    <Link to={`/${eventSlug}/${artistSlugName}`}>
                      <h3 className="font-tiemposBold text-16 sm:text-14 text-black">
                        {artistSetReadableName && translateObj(artistSetReadableName, localization.language) ? translateObj(artistSetReadableName, localization.language) : artistName}
                      </h3>
                      <span className="text-12 uppercase text-black font-americaMonoBold">{shortTitle ? translateObj(shortTitle, localization.language) : ''}</span>
                    </Link>
                    <p className="font-tiemposRegular text-12 my-2 sm:block hidden">
                      {/* {moment(Number(dateTimeStartReal)).format('LT')} */}
                      {showDate && showDateTime ? artistStartDate.tz(timezone).format('HH:mm') : 'TBD'}
                    </p>
                    <p className="font-tiemposRegular m-0 text-12 sm:block hidden">{translateObj(venueReadableName, localization.language)}</p>
                  </div>
                  {/* <div className="hidden sm:block absolute self-center pin-r" onClick={width < 640 ? this.activeCard : undefined}><span className="font-icon text-12 icon-arrow-down text-black pr-5" /></div> */}
                </td>
                <td>
                  <p className="font-tiemposRegular text-16 sm:hidden">{showDate && showDateTime ? artistStartDate.tz(timezone).format('HH:mm') : 'TBD'}</p>
                </td>
                {showVenue && (
                  <td>
                    <p className="font-tiemposRegular text-16 sm:hidden">{translateObj(venueReadableName, localization.language)}</p>
                  </td>
                )}
              </tr>
            </>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

LineupTableItem.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  handleSetArtistSetFavorite: PropTypes.func.isRequired,
  category: PropTypes.string,
};

LineupTableItem.defaultProps = {
  isUserLoggedIn: false,
  category: 'black',
};

export default withRouter(LineupTableItem);
