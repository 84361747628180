import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const VALIDATE_COUPON = gql`
  query validateCoupon($couponCode: String!, $fetchDate: String, $productSlugName: String) {
    validateCoupon(
      couponCode: $couponCode,
      fetchDate: $fetchDate,
      productSlugName: $productSlugName,
      ){
        valid
        couponName
        discountPercent
        discountAmount
        requireCard
        paymentNecessary
        numInitialDaysFree
        newAmount
        error
    }
}`;

const validateCoupon = async (formData) => {
  try {
    const result = await clientWeb
      .query({
        query: VALIDATE_COUPON,
        variables: {
          ...formData,
          fetchDate: new Date().getTime().toString(),
        },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { validateCoupon: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: validateCoupon Error: ${error}`);
    return null;
  }
};

export default validateCoupon;
