import React from 'react';
import PropTypes from 'prop-types';


const LogosContainer = ({
  name, classNames, logos, text,
}) => (
  <div id={name} className={classNames}>

    {text && <p className="font-americaMonoRegular mb-4">{text}</p>}

    {
      logos.map(logo => (
        <a key={logo.src} href={logo.link} className="ml-2">
          <img src={logo.src} alt={logo.name} className="ml-2" />
        </a>
      ))
    }
  </div>
);

LogosContainer.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
  classNames: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default LogosContainer;
