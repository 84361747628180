import React, { useState, useEffect } from 'react';
import { useChat } from '../context/chatContext';
import { useFirebaseAuth, firebaseauth } from '../context/FirebaseAuthContext';
import {
  MESSAGES, MUTED_USERS, MESSAGE_LIST_LIMIT,
} from '../utils/constants';
import {
  messagesRef, channelsRef, videoCallsRef,
} from '../utils/helpers';

export const useMessages = ({ channel, isQuestions }) => {
  const { userData } = useChat();
  const { isModerator } = useFirebaseAuth();
  const [messages, setMessages] = useState([]);
  const [qaMessagesAdmin, setQaMessagesAdmin] = useState([]);
  const [isLoadingMessages, setLoadingMessages] = useState(true);

  const listeners = []; // list of listeners
  let start = null; // start position of listener

  const loadMessageArray = (docs) => {
    const newMessages = docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setMessages(newMessages);
    setLoadingMessages(false);
  };

  const getMessages = () => {
    const ref = messagesRef.doc(channel).collection(MESSAGES).where('isVisible', '==', true);
    ref.orderBy('createdAt', 'desc')
      .limit(MESSAGE_LIST_LIMIT)
      .get()
      .then((snapshots) => {
        let query;
        const { docs } = snapshots;
        if (docs && docs.length) {
          loadMessageArray(docs);
          start = docs[snapshots.docs.length - 1];
          query = ref.orderBy('createdAt')
            .startAt(start);
        } else {
          setMessages([]);
          query = ref.orderBy('createdAt');
        }

        const listener = query
          .onSnapshot((snapshot) => {
            const { docs: messageDocs } = snapshot;
            if (messageDocs && messageDocs.length) {
              const newMessages = messageDocs.map(doc => ({
                id: doc.id,
                ...doc.data(),
              }));
              setMessages(newMessages);
            } else {
              setMessages([]);
            }
            setLoadingMessages(false);
          });
        listeners.push(listener);
      });
  };

  const getMessagesQa = () => {
    // query reference for the messages we want
    let ref = messagesRef.doc(channel).collection(MESSAGES).where('isVisible', '==', true).where('isPending', '==', false)
      .orderBy('updatedAt');
    if (isModerator) {
      ref = messagesRef.doc(channel).collection(MESSAGES).where('isVisible', '==', true);
    }
    const listener = ref
      .onSnapshot((snapshot) => {
        const { docs: messageDocs } = snapshot;
        if (snapshot.empty) {
          setMessages([]);
          setQaMessagesAdmin({
            acceptedMessages: [],
            pendingMessages: [],
          });
        }
        if (messageDocs && messageDocs.length) {
          const newMessages = messageDocs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          if (isModerator) {
            const acceptedMessages = newMessages.filter(message => !message.isPending);
            const pendingMessages = newMessages.filter(message => message.isPending);
            setQaMessagesAdmin({
              acceptedMessages,
              pendingMessages,
            });
          } else {
            setMessages(newMessages);
          }
        }
        setLoadingMessages(false);
      });
    listeners.push(listener);
  };

  useEffect(() => {
    if (channel) {
      if (!isQuestions) {
        getMessages();
      } else {
        getMessagesQa();
      }
    }
  }, [channel, userData]);

  useEffect(() => () => {
    listeners.forEach(listener => listener());
  }, []);

  return {
    messages, setMessages, isLoadingMessages, qaMessagesAdmin,
  };
};

export const useMutedUsers = ({ channel }) => {
  const { isModerator, firebaseUser } = useFirebaseAuth();
  const [userIsMuted, setUserIsMuted] = useState(false);
  const [mutedUsers, setMutedUsers] = useState([]);

  const listeners = []; // list of listeners

  const { currentUser } = firebaseauth;
  useEffect(() => {
    if (channel && firebaseUser && currentUser) {
      const unSubscribeMuted = channelsRef.doc(channel).collection(MUTED_USERS).onSnapshot((snapshot) => {
        const { docs } = snapshot;
        if (docs && docs.length) {
          const muted = docs.map(doc => (doc.id));
          if (isModerator) setMutedUsers(muted);
          const amIMuted = muted.indexOf(firebaseUser.userId) > -1;
          setUserIsMuted(amIMuted);
        } else {
          setUserIsMuted(false);
        }
      });
      listeners.push(unSubscribeMuted);
    }
  }, [channel, firebaseUser, currentUser]);

  useEffect(() => () => {
    listeners.forEach(listener => listener());
  }, []);

  return {
    userIsMuted, mutedUsers,
  };
};

export const useVideoCalls = () => {
  const { firebaseUser } = useFirebaseAuth();
  const [activeVideoCall, setActiveVideoCall] = useState(null);
  const listeners = []; // list of listeners
  const { currentUser } = firebaseauth;

  useEffect(() => {
    if (firebaseUser && currentUser) {
      const unSubscribeaActiveVideoCalls = videoCallsRef.where('participants', 'array-contains', firebaseUser.userId).onSnapshot((snapshot) => {
        const { docs } = snapshot;
        if (docs && docs.length) {
          const activeCall = docs[0];
          setActiveVideoCall({ room: activeCall.id, ...activeCall.data() });
        } else {
          setActiveVideoCall(null);
        }
      });
      listeners.push(unSubscribeaActiveVideoCalls);
    }
  }, [firebaseUser, currentUser]);

  useEffect(() => () => {
    listeners.forEach(listener => listener());
  }, []);

  return { activeVideoCall };
};

export const usePendingVideoCalls = () => {
  const { firebaseUser } = useFirebaseAuth();
  const [pendingVideoCalls, setPendingVideoCalls] = useState([]);
  const listeners = []; // list of listeners
  const { currentUser } = firebaseauth;

  useEffect(() => {
    if (firebaseUser && currentUser) {
      const unSubscribePendingVideoCalls = videoCallsRef.where('pending', 'array-contains', firebaseUser.userId).onSnapshot((snapshot) => {
        const { docs } = snapshot;
        if (docs && docs.length) {
          const myVideoCalls = docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPendingVideoCalls(myVideoCalls);
        } else {
          setPendingVideoCalls([]);
        }
      });
      listeners.push(unSubscribePendingVideoCalls);
    }
  }, [firebaseUser, currentUser]);

  useEffect(() => () => {
    listeners.forEach(listener => listener());
  }, []);

  return { pendingVideoCalls };
};
