import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import ProductChosenModalContent from './ProductChosenModalContent';
import ProductChosenModalContentAddCard from './ProductChosenModalContentAddCard';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

const modalStyles = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    zIndex: 1005,
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.68)',
    zIndex: 9999,
  },
  closeButton: {
    top: '18px',
    right: '18px',
  },
};

const modalStylesMobile = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    zIndex: 1005,
    padding: '0',
  },
  overlay: {
    background: 'rgba(255, 255, 255, 1)',
    zIndex: 9999,
    padding: '0',
  },
  closeButton: {
    top: '18px',
    right: '18px',
  },
};

const ProductChosenModal = (props) => {
  const {
    match,
    buttonText,
    layout,
    parentComponent,
    section,
    onOpen,
    onClose,
    forceState,
  } = props;

  const [isModalOpen, setModalOpen] = useState(forceState !== null ? forceState : false);
  const [screen, setScreen] = useState();
  const responsiveUtility = useRef();

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setScreen(deviceScreen);
  };

  const onOpenModal = () => {
    onOpen();
    setModalOpen(true);
  };

  const onCloseModal = () => {
    onClose();
    setModalOpen(false);
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);

        return (
          <>
            <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} rdlSizes />
            {parentComponent !== 'ProfileForm' && (
              <button
                className={`${layout === 'red' ? 'w-full h-16 bg-red text-white text-20' : 'text-14 text-red border-red border-6 py-1 w-32 mb-4'} uppercase font-bold font-stratos flex flex-row justify-center items-center`}
                type="button"
                onClick={onOpenModal}
              >
                {translateObj(buttonText, localization.language)}
              </button>
            )}
            <Modal
              open={isModalOpen}
              onClose={onCloseModal}
              center
              styles={screen !== 'mobile' ? modalStyles : modalStylesMobile}
              closeOnOverlayClick
              focusTrapped={false}
            >
              <div className="bg-white tablet:mx-auto border-red tablet:border-10 tablet:max-w-342 tablet:max-h-692 overflow-auto h-screen w-full flex flex-col items-stretch justify-start">
                <div key="button-wrapper" className="flex justify-end items-start px-6 pt-2 pb-2">
                  <button
                    type="button"
                    className="focus:outline-none font-icon icon-close text-red text-18 tablet:text-20"
                    onClick={() => onCloseModal()}
                  />
                </div>
                <div data-name="logo-wrapper" className="flex items-center justify-center px-6 pb-6">
                  <svg className="w-auto h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.225 35.303">
                    <path d="M215.153 21.122l8.688-12.991h-7.629l-4.849 7.786-4.8-7.786h-7.825l8.689 13.091-9.026 13.486h7.632l5.184-8.28 5.182 8.28h7.825zm-25.01 13.586h7.3V8.131h-7.3v14.826c0 3.57-1.777 5.4-4.56 5.4s-4.417-1.834-4.417-5.4V8.131h-7.294v17.206c0 6.1 3.215 9.867 8.736 9.867 3.695 0 5.855-2.033 7.536-4.264zm-27.229 0h7.3V.808h-7.3zm-2.688-10.661c.048-.694.095-1.387.095-1.983 0-7.536-3.935-14.429-12.527-14.429-7.488 0-12.721 6.248-12.721 13.834v.1c0 8.131 5.712 13.734 13.441 13.734a12.711 12.711 0 0010.512-4.958l-4.177-3.818a8.3 8.3 0 01-6.24 2.727 6.013 6.013 0 01-6.287-5.207zm-7.009-4.71h-10.991c.576-3.421 2.544-5.652 5.568-5.652 3.072 0 4.992 2.281 5.423 5.652m-27.822 15.371h7.295V.808h-7.295v10.79a9.776 9.776 0 00-8.017-3.967c-6.1 0-11.712 4.859-11.712 13.734v.1c0 8.876 5.713 13.734 11.712 13.734a9.373 9.373 0 008.017-4.314zm.095-13.239c0 4.413-2.832 7.338-6.239 7.338s-6.288-2.926-6.288-7.338v-.1c0-4.463 2.879-7.339 6.288-7.339s6.239 2.926 6.239 7.339zm-28.25-2.876l9.792-10.463h-8.736l-8.544 9.668V8.129h-7.3v26.579h7.3v-8.082l2.592-2.827 6.576 10.909h8.449zm-33 2.876v-.1c0-3.917 2.592-7.239 6.24-7.239 2.592 0 4.225 1.141 5.857 2.926l4.463-4.958c-2.4-2.778-5.425-4.464-10.272-4.464-7.872 0-13.489 6.248-13.489 13.834v.1c0 7.585 5.664 13.734 13.393 13.734a12.8 12.8 0 0010.514-4.859l-4.273-4.463c-1.777 1.735-3.455 2.826-5.905 2.826-3.984 0-6.528-3.272-6.528-7.338m-8.147 0v-.1A13.582 13.582 0 0042.222 7.635a13.716 13.716 0 00-13.968 13.834v.1a13.582 13.582 0 0013.872 13.734 13.715 13.715 0 0013.969-13.834m-7.2.1c0 3.917-2.5 7.238-6.672 7.238-4.031 0-6.768-3.421-6.768-7.338v-.1c0-3.917 2.5-7.239 6.672-7.239 4.032 0 6.769 3.421 6.769 7.338zM28.032 11.553v-.1a11.1 11.1 0 00-2.88-7.934c-2.208-2.28-5.52-3.52-9.792-3.52H-.001v34.709H7.39v-11.11h5.809l7.2 11.106h8.64l-8.209-12.4c4.273-1.637 7.2-5.157 7.2-10.76m-7.487.4c0 2.926-2.064 4.909-5.665 4.909H7.389V6.886h7.345c3.6 0 5.809 1.687 5.809 4.958z" fill="#ff4646" />
                  </svg>
                </div>
                <div key="content-wrapper" className="flex-1 h-full px-6">
                  {parentComponent !== 'ProfileForm' ? (
                    <ProductChosenModalContent {...props} />
                  ) : (
                    <>
                      {section && section === 'sendGift'
                        ? <ProductChosenModalContent match={match} productSlugName="rockdelux-present-subscription-1-year" buttonText={buttonText} parentComponent={parentComponent} />
                        : <ProductChosenModalContentAddCard {...props} closeCallback={onCloseModal} />
                      }
                    </>
                  )}
                </div>
              </div>
            </Modal>
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default withRouter(ProductChosenModal);

ProductChosenModal.propTypes = {
  buttonText: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  layout: PropTypes.string,
  parentComponent: PropTypes.string,
  section: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  forceState: PropTypes.bool,
};

ProductChosenModal.defaultProps = {
  layout: '',
  parentComponent: '',
  section: null,
  onOpen: null,
  onClose: null,
  forceState: null,
};
