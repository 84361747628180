import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import userPasswordRecoverUserSendsEmail from '../../api/graphql/mutations/userPasswordRecoverUserSendsEmail';
import SimpleButton from './Buttons/SimpleButton';

// import getLocalization from '../../util/localization';
// import RoutesContext from '../../context/routesContext';
import { useRoutes } from '../../context/routesContext';
import translateObj from '../../util/translateObj';

const RecoverPassword = ({
  text, userTitle, submitTitle, history, match, errorsText: { errorUserDoesNotExist, errorUserLoggedWithSocialNetwork }, afterSubmitedText, sourceWeb,
}) => {
  const { language = 'en' } = useRoutes(match);
  const [email, setEmail] = useState('');
  const [isPasswordRecoveryEmailSubmited, setIsPasswordRecoveryEmailSubmited] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (!email && error) {
      setError('');
    }
  }, [email]);

  useEffect(() => {
    if (isPasswordRecoveryEmailSubmited) {
      const redirectTo = sourceWeb ? `/${language}/${sourceWeb}` : '/';
      setTimeout(() => {
        history.push(redirectTo);
      }, 2500);
    }
  }, [isPasswordRecoveryEmailSubmited]);

  const sendRecoveryEmail = (lang) => {
    if (email) {
      userPasswordRecoverUserSendsEmail({ email, sourceWeb }).then((res) => {
        if (res) {
          if (res.isValid) { setIsPasswordRecoveryEmailSubmited(true); } else if (res.error) {
            switch (res.error) {
              case 'noUserFound':
                setError(translateObj(errorUserDoesNotExist, lang));
                break;
              case 'userLoggedWithSocialNetwork':
                setError(translateObj(errorUserLoggedWithSocialNetwork, lang));
                break;
              case 'emailNotSent':
                setError('E-mail was not sent');
                break;
              case 'userCouldNotBeUpdatedinDB':
                setError('Please contact your administrator');
                break;

              default:
                setError('Please contact your administrator');
                break;
            }
          } else {
            console.log('no error was received and res is not valid, please contact your administrator');
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - recover password failed to submit!`);
        }
      });
    } else {
      setError('Dirección de correo electrónico incorrecta');
    }
  };

  const validateIfEmailIsCorrect = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailCorrect = re.test(String(email).toLowerCase());
    const err = !isEmailCorrect ? 'Por favor, verifica tu dirección correo electrónico' : '';
    setError(err);
  };

  return (
    <div className="mt-24 laptop:mt-32">
      {!isPasswordRecoveryEmailSubmited ? (
        <div>
          <div className={`${'w-full tablet:p-8 flex flex-col items-center'}`}>
            <p className="font-stratos m-0">
              {translateObj(text, 'es')}
            </p>
            <div className="tablet:flex w-full pb-8 pt-4 tablet:flex-col tablet:items-center">
              <label className="flex-col pr-6 text-12 font-stratos font-light text-black uppercase sm:pr-0 sm:w-full uppercase">
                {translateObj(userTitle, 'es')}
              </label>
              <div className="flex-col items-end justify-start mt-4 w-full tablet:w-92">
                <div className="w-full mb-4">
                  <input
                    type="text"
                    name="name"
                    placeholder="Correo electrónico"
                    onChange={handleChange}
                    className="px-6 font-stratos text-12 border-1 p-4 bg-inherit border-black text-black text-12 w-full laptop:w-92"
                    value={email}
                    onBlur={validateIfEmailIsCorrect}
                  />
                </div>
                <div className="w-full flex items-center justify-end">
                  <button
                    onClick={() => sendRecoveryEmail(language)}
                    type="submit"
                    disabled={error || !email}
                    // className={`border-4 bg-white ${error || !email ? 'text-grey border-grey' : 'text-red border-red'} text-12 font-stratos sm:static sm:mt-6 sm:mx-0 sm:w-full  cursor-pointer text-center xl:w-s15 lg:w-s15 sm:w-full xl:max-w-xs md:w-s15 font-bold lg:max-w-xs`}
                    // style={{
                    //   minHeight: '3rem',
                    // }}
                  >
                    <SimpleButton
                      isDisabled
                      isLowercase
                      isFullWidth
                      className="min-w-cta py-6px laptop:py-1 desktop:py-2 text-base tablet:text-sm laptop:text-base px-6px laptop:px-4"
                      theme={error || !email ? { base: 'disabled' } : { base: 'red', hover: 'red' }}
                      text={{
                        en: 'Enviar',
                        es: 'Enviar',
                      }}
                    />
                  </button>
                </div>
              </div>
              <span className="text-red mt-4 font-stratos uppercase font-light text-12">{error}</span>
            </div>
          </div>
        </div>
      )
        : <div className="font-stratos font-bold uppercase w-full h-full flex items-center justify-center">{translateObj(afterSubmitedText, 'es') || 'please check your email in order to reset your password'}</div>
            }
    </div>
  );
};

RecoverPassword.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  userTitle: PropTypes.shape({}),
  submitTitle: PropTypes.shape({}),
  sourceWeb: PropTypes.string,
};

RecoverPassword.defaultProps = {
  text: null,
  userTitle: null,
  submitTitle: null,
  sourceWeb: '',

};


export default withRouter(RecoverPassword);
