import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../util/link';


const stylesContainer = (parent) => {
  if (parent === 'header') {
    return 'hidden sm:flex md:flex border-black border-t-1 border-dashed pt-6 md:justify-center';
  }
  return 'flex';
};

const LanguageSelector = ({
  mainParent, languages, match, location, style = {},
}) => (
  <div parent={mainParent} className={`sm:w-full sm:justify-center justify-end items-center mb-6 xs:mt-4 sm:mt-1 w-full h-auto ${style || ' pr-4'} sm:order-2 md:pr-0 ${stylesContainer(mainParent)}`}>
    {
        languages.map((lang, i) => {
          // check what element is render, the last one musn't have border
          let border = 'border-r';
          if (i === languages.length - 1) {
            border = '';
          } else {
            border = 'border-r';
          }
          return (
            <Fragment key={lang.url}>
              {match.path.includes('radio')
                ? (
                  <Link
                    to={`/radio${match.path.includes('shows') ? '/shows' : ''}${match.path.includes('explore') ? '/explore' : ''}${match.params.channel ? `/${match.params.channel}` : ''}${match.params.post ? `/${match.params.post}` : ''}${match.params.episode ? `/${match.params.episode}` : ''}`}
                    target="_self"
                    className={`font-americaMonoRegular uppercase  tracking-submenu px-3 text-xs sm:border-transparent sm:pt-4 ${style || 'text-black border-black'} ${border}`}
                  >
                    {lang.lang && <span>{lang.lang}</span>}
                  </Link>
                )
                : match.params.assetSlugName
                  ? (
                    <Link
                      to={`/share/${match.params.assetSlugName}`}
                      target="_self"
                      className={`font-americaMonoRegular uppercase tracking-submenu px-3 text-xs sm:border-transparent sm:pt-4 ${style || 'text-black border-black'} ${border}`}
                    >
                      {lang.lang && <span>{lang.lang}</span>}
                    </Link>
                  )
                  : (
                    <Link
                      to={`${match.params.section ? `/${match.params.section}` : ''}${match.params.post ? `/${match.params.post}` : ''}${location && location.search ? `${location.search}` : ''}`}
                      target="_self"
                      className={`font-americaMonoRegular uppercase tracking-submenu px-3 text-xs sm:border-transparent sm:pt-4 ${style || 'text-black border-black'} ${border}`}
                    >
                      {lang.lang && <span>{lang.lang}</span>}
                    </Link>
                  )
              }
            </Fragment>
          );
        })
      }
  </div>
);

LanguageSelector.propTypes = {
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  mainParent: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  style: PropTypes.string,
};

LanguageSelector.defaultProps = {
  style: '',
};

export default withRouter(LanguageSelector);
