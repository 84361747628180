import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import ParagraphLineSM from './Text/ParagraphLineSM';
import translateObj from '../../util/translateObj';

const Image = ({
  fileReadableName, url, match, handleClick, tags, i, isInGrid,
}) => (

  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const backgroundStyle = {
          height: '260px',
          background: '#a8a8a8',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center 2rem',
          backgroundImage: `url(${url})`,
          backgroundSize: '80%',
        };
        const zoomText = {
          en: 'Zoom',
          es: 'ampliar',
          ca: 'ampliar',
        };
        const downloadText = {
          en: 'Download',
          es: 'Descargar',
          ca: 'descarregar',
        };
        return (
          <div className={`${isInGrid ? 'w-full md:w-1/2 lg:w-1/3 xl:w-1/4 py-2 px-2 sm:px-0' : ''}`}>
            <div className="cursor-pointer mb-2" onClick={() => handleClick(i)}>
              <p className="font-americaBlack text-18 flex items-end pb-2 h-12">{translateObj(fileReadableName, localization.language) ? translateObj(fileReadableName, localization.language).substring(0, 75) : ''}</p>
              {tags.includes('material')
                ? (
                  <div
                    className="relative pt-8 overflow-hidden"
                    style={backgroundStyle}
                  />
                )
                : (
                  <div className="relative">
                    <img className="block w-full" src={imageModifier(url, '502x376')} alt={fileReadableName.en} />
                  </div>
                )
              }
            </div>
            <div className="flex justify-between">
              <div onClick={() => handleClick(i)}>
                <ParagraphLineSM classes="cursor-pointer uppercase font-bold text-xxs" iconClass="font-icon icon-zoom" text={zoomText} />
              </div>
              <a href={url} download className="text-black">
                <ParagraphLineSM classes="cursor-pointer uppercase font-bold text-xxs" iconClass="font-icon icon-download1" text={downloadText} />
              </a>
            </div>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

Image.propTypes = {
  fileReadableName: PropTypes.shape({}),
  handleClick: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  isInGrid: PropTypes.bool,
  i: PropTypes.number,
};

Image.defaultProps = {
  fileReadableName: {},
  tags: [],
  isInGrid: true,
  i: 0,
};

export default withRouter(Image);
