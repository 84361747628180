import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { useRoutes } from '../../context/routesContext';

import RadioPlayIcon from './Radio/RadioPlayIcon';
import translateObj from '../../util/translateObj';

const timezone = 'Europe/Madrid';

const LineupIsOnNowTable = ({
  items, text1, text2, text3, text4, icon1, icon2, category, match, isLoading, isLight, isClickable,
}) => {
  const { language } = useRoutes(match);
  const [pre, setPre] = useState(false);
  const [post, setPost] = useState(false);
  const [middles, setMiddles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isIntervalOn, setIsIntervalOn] = useState(false);


  const findLiveIndex = () => {
    let currentIndexWithinFindLiveIndex = currentIndex;
    items.forEach((item, index) => {
      if (index !== items.length - 1) {
        item.isLive = moment().tz(timezone).isBetween(moment(item.dateTimeStartHuman / 1000, 'X').tz(timezone), moment(items[index + 1].dateTimeStartHuman / 1000, 'X'));
        item.minutesToStart = moment.duration(moment(item.dateTimeStartHuman / 1000, 'X').tz(timezone).diff(moment())).asMinutes();
      } else if (index === items.length - 1) {
        item.isLive = moment().tz(timezone).isBetween(moment(item.dateTimeStartHuman / 1000, 'X').tz(timezone), moment(item.dateTimeStartHuman / 1000, 'X').tz(timezone).add(item.duration || 60, 'minutes'));
        item.minutesToStart = moment.duration(moment(item.dateTimeStartHuman / 1000, 'X').tz(timezone).diff(moment())).asMinutes();
      }
      if (item.isLive) {
        currentIndexWithinFindLiveIndex = index;
      }
    });

    if (currentIndexWithinFindLiveIndex < 0) {
      let currentMinutesToStart = 60 * 24;
      items.forEach((item, index) => {
        if (item.minutesToStart > 0 && item.minutesToStart < currentMinutesToStart) {
          currentIndexWithinFindLiveIndex = index;
          currentMinutesToStart = item.minutesToStart;
        }
      });
      if (currentIndexWithinFindLiveIndex < 0) {
        currentIndexWithinFindLiveIndex = 0;
      }
    }
    return currentIndexWithinFindLiveIndex;
  };

  const processItems = () => {
    let currentIndexWithinkProcessItems = currentIndex;
    if (currentIndex < 0) {
      currentIndexWithinkProcessItems = findLiveIndex();
    }

    let preWithinProcessItems = false;
    let postWithinProcessItems = false;
    const middlesWithinProcessItems = [];

    if (typeof items[currentIndexWithinkProcessItems - 1] !== 'undefined') {
      preWithinProcessItems = items[currentIndexWithinkProcessItems - 1];
    }
    for (let i = currentIndexWithinkProcessItems; i < currentIndexWithinkProcessItems + 4; i++) {
      if (typeof items[i] !== 'undefined') {
        middlesWithinProcessItems.push(items[i]);
      }
    }

    if (typeof items[currentIndexWithinkProcessItems + 4] !== 'undefined') {
      postWithinProcessItems = items[currentIndexWithinkProcessItems + 4];
    }

    if (currentIndexWithinkProcessItems && currentIndexWithinkProcessItems >= 0) setCurrentIndex(currentIndexWithinkProcessItems);
    setPre(preWithinProcessItems);
    setPost(postWithinProcessItems);
    setMiddles(middlesWithinProcessItems);
  };


  useEffect(() => {
    let intervalId;
    if (items && items.length > 0) {
      setCurrentIndex(-1);
      if (!isIntervalOn) {
        intervalId = setInterval(() => {
          if (currentIndex) processItems();
        }, 300000);
        setIsIntervalOn(true);
      } else {
        processItems();
      }
    }
    return () => { clearInterval(intervalId); };
  }, [items]);

  useEffect(() => {
    processItems();
  }, [currentIndex]);

  const handleArrowClick = (top, item) => {
    if (top) {
      if (currentIndex > 1) {
        setCurrentIndex(prevState => prevState - 1);
      }
    } else {
      if (currentIndex < items.length - 1) {
        setCurrentIndex(prevState => prevState + 1);
      }
    }
  };


  const TopBottomArrow = ({ top, item }) => {
    if (top && currentIndex === 1) {
      return null;
    } if (!top && currentIndex >= items.length - middles.length - 1) {
      return null;
    }
    return <span className={`h-8 cursor-pointer absolute pin-t ${top ? icon1 : icon2}`} onClick={() => handleArrowClick(top, item)} />;
  };

  const PrePostRow = ({ item, top }) => (
    <div className={`flex py-4 border-solid border-black items-center ${top ? 'border-b-1' : 'border-t-1'}`}>
      <div className="w-1/6 flex items-center justify-center sm:items-start relative h-8">
        <TopBottomArrow top={top} item={item} />
      </div>
      <div className="w-5/6 flex sm:block">
        <div className="w-1/5 sm:w-full flex items-center font-americaMonoRegular text-xs sm:mb-2">
          {moment(item.dateTimeStartHuman / 1000, 'X').tz(timezone).format('HH:mm')}
        </div>
        <div className="w-3/5 sm:w-full flex items-center font-tiemposRegular sm:mb-3">
          {isClickable ? (
            <Link to={item.postUri}>
              <span className={`pr-2 text-${isLight ? 'black' : 'white'}`}>
                {translateObj(item.artistSetReadableName, language || 'en')}
              </span>
            </Link>
          ) : (
            <span className={`pr-2 text-${isLight ? 'black' : 'white'}`}>
              {translateObj(item.artistSetReadableName, language || 'en')}
            </span>
          )}
        </div>
        {/* <div className="w-1/5 sm:w-full flex items-center">
                  <ComponentIterator components={item.tags} />
                </div> */}
      </div>
    </div>
  );

  const Row = ({
    item, isFirst, isLast,
  }) => {
    const { isLive } = item;
    const pathStyle = {
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      // zIndex: 3,
    };
    return (
      <div className="flex">
        <div className="w-1/6 flex items-center justify-center py-5 relative">
          <div className={`absolute bg-${category} w-px ${isFirst ? 'pin-ht' : 'pin-t'} ${isLast ? 'pin-hb' : 'pin-b'}`} />
          {isLive
            ? (
              <div className="w-12 h-12 relative">
                <div style={pathStyle}>
                  <RadioPlayIcon isLive bgWave={category} />
                </div>
              </div>
            )
            : (
              <div className="font-icon icon-current2 absolute pin-t w-12 h-12" style={pathStyle}>
                <span className={`path1 absolute text-${category}`} style={pathStyle} />
              </div>
            )}
        </div>
        <div className={`w-5/6 flex sm:block py-7 ${isLast ? '' : 'border-solid border-black border-b-1'}`}>
          <div className="w-1/5 sm:w-full flex items-center sm:block sm:mb-2 font-americaMonoRegular text-xs">
            {isLive ? <span className={`text-${category} font-americaMonoBold uppercase `}>{text4[language || 'en']}</span> : moment(item.dateTimeStartHuman / 1000, 'X').tz(timezone).format('HH:mm')}
          </div>
          <div className="w-3/5 sm:w-full flex items-center font-tiemposBold sm:mb-3 ">
            {isClickable ? (
              <Link to={item.postUri}>
                <span className={`pr-2 text-${isLight ? 'black' : 'white'}`}>
                  {translateObj(item.artistSetReadableName, language || 'en')}
                </span>
              </Link>
            ) : (
              <span className={`pr-2 text-${isLight ? 'black' : 'white'}`}>
                {translateObj(item.artistSetReadableName, language || 'en')}
              </span>
            )}

          </div>
          {/* <div className="w-1/5 sm:w-full flex items-center">
                    <ComponentIterator components={item.tags} />
                  </div> */}
        </div>
      </div>
    );
  };

  return (
    <div className={`w-full text-${isLight ? 'black' : 'white'}`}>
      {/* <InfoRow text1={text1} text2={text2} text3={text3} /> */}
      {pre ? <PrePostRow item={pre} top /> : null}
      <div>
        {middles.map((item, i) => (
          <Row key={item.artistSetSlugName} item={item} isFirst={i === 0} isLast={i === middles.length - 1} />
        ))}
      </div>
      {post ? <PrePostRow item={post} top={false} /> : null}
    </div>
  );
};


LineupIsOnNowTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  text1: PropTypes.shape().isRequired,
  text2: PropTypes.shape().isRequired,
  text3: PropTypes.shape().isRequired,
  text4: PropTypes.shape().isRequired,
  icon1: PropTypes.string.isRequired,
  icon2: PropTypes.string.isRequired,
  category: PropTypes.string,
  isLight: PropTypes.bool,
  isClickable: PropTypes.bool,
};

LineupIsOnNowTable.defaultProps = {
  isLight: false,
  isClickable: true,
  category: 'pro-online',

};

export default withRouter(LineupIsOnNowTable);
