import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import TitleArtistLineH1 from '../Text/TitleArtistLineH1';
import Navigator from '../Navigator';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

const SliderBannerArtistPD = ({
  image, title, prevArtist, nextArtist, match, eventSlugName, url,
}) => {
  const [deviceWidth, setDeviceWidth] = useState('lg');
  const responsiveUtility = useRef();

  const getWidth = () => {
    // actions when window is resized
    setDeviceWidth(responsiveUtility.current.deviceScreen(true));
  };
  const styleNavButtons = (img, lang) => ({
    backgroundImage: `url(${imageModifier(translateObj(img, lang), '200x200')})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  });

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        let imageurl = `url(${imageModifier(translateObj(image, localization.language), '1400x620')})`;
        let backgroundPosition = 'center';
        if (deviceWidth === 'xxl') { imageurl = `url(${imageModifier(translateObj(image, localization.language), '1900x820')})`; backgroundPosition = 'center top'; }
        if (deviceWidth === 'lg') imageurl = `url(${imageModifier(translateObj(image, localization.language), '1400x620')})`;
        if (deviceWidth === 'md') imageurl = `url(${imageModifier(translateObj(image, localization.language), '1400x620')})`;
        if (deviceWidth === 'sm') imageurl = `url(${imageModifier(translateObj(image, localization.language), '414x420')})`;
        const backgroundStyle = {
          backgroundImage: imageurl,
          backgroundPosition,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };

        return (
          <>
            <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getWidth} />
            <Navigator title={title} />
            <div className="w-full full-width">
              <div key={translateObj(image, localization.language)} className="h-carouselXL md:h-carouselMD sm:h-carouselSM lg:h-620 w-full sliderComponent relative" style={backgroundStyle}>
                <div className="text-white flex items-end h-full relative">
                  {prevArtist && (
                  <Link to={`/${eventSlugName}/${prevArtist.slugName}`} localizationpath={localization.path}>
                    <div
                      className="flex cursor-pointer ml-7.5 sm:ml-2 md:ml-5 z-10"
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <img src="https://assets.primaverasound.com/ps/images/arrow_left_white.svg" alt="Prev Artists" />
                      <div className="w-80px md:w-60px sm:w-50px h-80px md:h-60px sm:h-50px ml-5 md:ml-2 sm:ml-2 rounded-full" style={styleNavButtons(prevArtist.postDescription.image, localization.language)} />
                    </div>
                  </Link>
                  )}
                  <div className="flex justify-around sm:flex-col sm:justify-end w-full px-36 md:px-20 sm:px-5 h-full items-end pb-20 sm:pb-5">
                    <div className="w-2/3 sm:w-full h-full sm:h-auto flex flex-col justify-end mr-12 sm:mr-0 sm:mb-4 ">
                      <div className="mt-4 relative z-10">
                        <TitleArtistLineH1 artist={translateObj(title, localization.language)} />
                      </div>
                    </div>
                    <div className="w-1/3 sm:w-full flex flex-col justify-start">
                      {/* <div className="mt-4 relative z-10">
                      {tags && tags.length && tags.map((tag) => {
                        const tagsWithSpaces = tag.split(' ');
                        return tagsWithSpaces.length < 2
                          ? <SimpleTag text={{ en: tag }} key={tag} category={tag} borderColor={tag} />
                          : <SimpleTag text={{ en: tag }} key={tag} category={tagsWithSpaces[0]} borderColor={tagsWithSpaces[0]} />;
                      })
                      }
                    </div> */}
                    </div>
                  </div>
                  {nextArtist && (
                  <Link to={`/${eventSlugName}/${nextArtist.slugName}`} localizationpath={localization.path}>
                    <div
                      className="flex cursor-pointer mr-7.5 sm:mr-2 md:mr-5 z-10"
                      style={{
                        position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)',
                      }}
                    >
                      <div className="w-80px md:w-60px sm:w-50px h-80px md:h-60px sm:h-50px mr-5 md:ml-2 sm:mr-2 rounded-full" style={styleNavButtons(nextArtist.postDescription.image, localization.language)} />
                      <img src="https://assets.primaverasound.com/ps/images/arrow_right_white.svg" alt="next Artists" />
                    </div>
                  </Link>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

SliderBannerArtistPD.propTypes = {
  image: PropTypes.shape({}),
  title: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.string),
  match: PropTypes.shape().isRequired,
  prevArtist: PropTypes.shape(),
  nextArtist: PropTypes.shape(),
  eventSlugName: PropTypes.string,
};

SliderBannerArtistPD.defaultProps = {
  prevArtist: null,
  nextArtist: null,
  title: null,
  tags: null,
  image: null,
  eventSlugName: '',
};

export default withRouter(SliderBannerArtistPD);
