/* eslint-disable react/forbid-prop-types */

import React, {useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import PsWorldStripeCardForm from './StripeCardForm';
import api from '../Payments/psWorldPaymentsApi';

const PsWorldStripeCardFormWrapper = (props) => {
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    api.getPublicStripeKey().then(key => setStripe(loadStripe(key)));
  }, []);

  return (
    <Elements stripe={stripe}>
      <PsWorldStripeCardForm {...props} />
    </Elements>
  );
};

PsWorldStripeCardFormWrapper.propTypes = {
  customerInfo: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  clientSecret: PropTypes.string,
  publicKey: PropTypes.string,
};

PsWorldStripeCardFormWrapper.defaultProps = {
  clientSecret: null,
  publicKey: null,
};
export default PsWorldStripeCardFormWrapper;

