import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OnDemandModalLogin from './OnDemandModalLogin';
import OnDemandModalRegister from './OnDemandModalRegister';


const OnDemandModalNotLogged = (props) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [userSocialInfo, setUserSocialInfo] = useState(null);

  const handleRegisteringStatus = (registeringStatus = false, userPayload, socialNetwork) => {
    setUserSocialInfo(userPayload || {});
    setIsRegistering(registeringStatus);
  };

  return (
    <>
      {
      isRegistering
        ? <OnDemandModalRegister {...props} userSocialInfo={userSocialInfo} />
        : <OnDemandModalLogin {...props} handleRegisteringStatus={handleRegisteringStatus} />
      }
    </>
  );
};

OnDemandModalNotLogged.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
};

OnDemandModalNotLogged.defaultProps = {
  language: 'en',
};

export default OnDemandModalNotLogged;
