import React, { useRef, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import stripTags from '../helpers/stripTags';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

const NormalBodyText = ({
  text,
  match,
  firstLetterBig,
  parentComponent,
  enhanced,
  quote,
  creditslayout,
  firstInBody,
}) => {
  const responsiveUtility = useRef();
  const quoteText = useRef();
  const [deviceScreen, setDeviceScreen] = useState();
  const [squareHeight, setSquareHeight] = useState();

  const handleResize = () => {
    if (typeof quoteText.current !== 'undefined' && typeof deviceScreen !== 'undefined') {
      if (deviceScreen === 'mobile' || deviceScreen === 'tablet') {
        setSquareHeight(160);
      } else {
        setSquareHeight(224);
      }
    }
  };

  const parseParagraph = (rawParagraph) => {
    const formattedParagraph = parse(rawParagraph, { replace: (domNode) => {
      if (domNode.attribs?.href?.includes('&')) {
        const formatted = domNode.attribs.href.replace('&', '%26');
        domNode.attribs.href = formatted;
      }
    } });
    return formattedParagraph;
  };

  useEffect(() => {
    const screen = responsiveUtility.current.deviceScreen(true);
    setDeviceScreen(screen);
    setTimeout(() => {
      // TODO remove timeout
      handleResize();
    }, 100);
  }, []);


  useEffect(() => {
    handleResize();
  }, [deviceScreen]);

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={handleResize} rdlSizes />
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const multiLangText = translateObj(text, localization.language);
          const paragraphs = multiLangText
            .split('<p>')
            .filter(p => p.length)
            .map((paragraph, i) => {
              const StrippedString = paragraph.replace(/(<([^>]+)>)/gi, '');
              if (i === 0 && firstLetterBig === true) {
                const dropCapTag = `<span class="text-122 tablet:text-162 laptop:text-188 desktop:text-192 leading-112 tablet:leading-140 laptop:leading-160 desktop:leading-180 tracking-firstLetterBig float-left">${StrippedString[0]}</span>`;
                const restOfParagraph = paragraph.replace(StrippedString[0], StrippedString[0].substring(1));
                return `${dropCapTag + restOfParagraph}`;
              }
              return paragraph;
            });

          if (creditslayout) {
            return (
              <div className="w-full laptop:max-w-66%">
                {paragraphs.map((paragraph, i) => (
                  <div
                    className="text-14 laptop:text-base font-stratos font-normal text-black pb-4 innerTextPadding hasLinksAndBolds"
                    key={`${paragraph}-${i}`}
                  >
                    {parseParagraph(paragraph)}
                  </div>
                ))}
              </div>
            );
          }

          if (quote) {
            return (
              <div className={`relative mb-6 laptop:mb-0 ${['StandardArticlePage', 'CoverStoryPostPage'].includes(parentComponent) ? '' : 'pt-12'}`}>
                <div className="absolute pin-t pin-b flex items-center w-1/3 tablet:w-1/4 laptop:w-1/5 desktop:w-1/4 justify-start pr-5 tablet:pr-0 desktop:pr-3">
                  <div style={{ height: squareHeight }} className="w-full h-full border-t-22 border-b-22 border-r-22 border-red" />
                </div>
                <div style={{ minHeight: squareHeight }} className={`flex grid flex-row w-full h-auto grid-cols-6 gap-3 px-4 items-between tablet:grid-cols-12 laptop:gap-4 tablet:px-11 laptop:px-90px ${['StandardArticlePage', 'CoverStoryPostPage'].includes(parentComponent) ? '' : 'pb-16'}`}>
                  <div ref={quoteText} className="flex flex-col items-start justify-center col-start-3 col-end-7 tablet:col-start-4 tablet:col-end-13 laptop:col-start-3 laptop:pl-8 desktop:pl-0 laptop:col-end-12 desktop:col-start-4">
                    {paragraphs.map((paragraph, i) => (
                      <div
                        className={`max-w-rdl-xl laptop:leading-40 font-stratos text-red text-20 tablet:text-22 laptop:text-32 font-semibold ${i === paragraphs.length - 1 ? '' : 'pb-7 laptop:pb-8'} innerTextPadding hasLinksAndBolds`}
                        key={`${paragraph}-${i}`}
                      >
                        {parseParagraph(paragraph)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          }

          if (enhanced) {
            if (parentComponent === 'ListsPostPage') {
              return (
                <div className="mt-7 tablet:mt-13 laptop:mt-8 desktop:mt-16 pr-7 pb-7 tablet:pr-8 tablet:pb-6 laptop:pr-10 laptop:pb-6 desktop:pr-12 desktop:pb-12 border-r-10 tablet:border-r-12 laptop:border-r-20 border-b-10 tablet:border-b-12 laptop:border-b-20 border-red">
                  {paragraphs.map((paragraph, i) => (
                    <div
                      className="font-semibold text-black font-stratos text-20 tablet:text-18 laptop:text-22 desktop:text-24 leading-28 desktop:leading-30 innerTextPadding hasLinksAndBolds"
                      key={`${paragraph}-${i}`}
                    >
                      {parseParagraph(paragraph)}
                    </div>
                  ))}
                </div>
              );
            }
            if (parentComponent === 'StandardArticlePage') {
              return (
                <div className={`col-span-10 col-start-2 pb-6 pr-8 ${firstInBody ? 'laptop:mb-16' : 'mt-10 mb-0 laptop:mb-18 desktop:mb-18'} border-r-10 tablet:border-r-12 laptop:border-r-20 border-b-10 tablet:border-b-12 laptop:border-b-20 border-red`}>
                  {paragraphs.map((paragraph, i) => (
                    <div
                      className="font-semibold text-black font-stratos text-20 tablet:text-18 laptop:text-22 desktop:text-24 leading-26 laptop:leading-28 desktop:leading-30 innerTextPadding hasLinksAndBolds"
                      key={`${paragraph}-${i}`}
                    >
                      {parseParagraph(paragraph)}
                    </div>
                  ))}
                </div>
              );
            }
            if (parentComponent === 'CoverStoryPostPage') {
              return (
                <div className="w-full laptop:max-w-rdl-md">
                  {paragraphs.map((paragraph, i) => (
                    <div
                      className="font-semibold text-black leading-28 tablet:leading-30 laptop:leading-36 desktop:leading-40 font-stratos text-22 laptop:text-28 desktop:text-32 innerTextPadding hasLinksAndBolds"
                      key={`${paragraph}-${i}`}
                    >
                      {parseParagraph(paragraph)}
                    </div>
                  ))}
                </div>
              );
            }
            if (parentComponent === 'NewsPostPage') {
              return (
                <div className="col-span-10 col-start-2 pb-12 pr-12 mt-4 border-r-16 border-b-16 border-red laptop:mb-15">
                  {paragraphs.map((paragraph, i) => (
                    <div
                      className="font-semibold font-stratos text-22 tablet:text-24 leading-28 tablet:leading-30 laptop:leading-32 desktop:leading-30 innerTextPadding hasLinksAndBolds"
                      key={`${paragraph}-${i}`}
                    >
                      {parseParagraph(paragraph)}
                    </div>
                  ))}
                </div>
              );
            }
            if (parentComponent === 'RecordsPostPage') {
              return (
                <div className="col-span-10 col-start-2 pb-12 pr-12 mt-10 mb-10 laptop:mb-20 border-r-20 border-b-20 border-red">
                  {paragraphs.map((paragraph, i) => (
                    <div
                      className="font-bold font-stratos text-22 leading-28 desktop:leading-32 innerTextPadding hasLinksAndBolds"
                      key={`${paragraph}-${i}`}
                    >
                      {parseParagraph(paragraph)}
                    </div>
                  ))}
                </div>
              );
            }

            return (
              <div className="col-span-10 col-start-2 pb-12 pr-12 mt-10 mb-10 laptop:mb-32 border-r-20 border-b-20 border-red">
                {paragraphs.map((paragraph, i) => (
                  <div
                    className="font-bold font-stratos text-32 innerTextPadding hasLinksAndBolds"
                    key={`${paragraph}-${i}`}
                  >
                    {parseParagraph(paragraph)}
                  </div>
                ))}
              </div>
            );
          }

          if (parentComponent === 'ListsPostPage') {
            return (
              <div className="w-full">
                {paragraphs.map((paragraph, i) => (
                  <div
                    className={`text-base font-light text-black leading-wide font-merriw laptop:text-18 desktop:text-20 innerTextPadding hasLinksAndBolds ${i + 1 === paragraphs.length ? 'laptop:pb-3' : 'pb-7 laptop:pb-8'}`}
                    key={`${paragraph}-${i}`}
                  >
                    {parseParagraph(paragraph)}
                  </div>
                ))}
              </div>
            );
          }

          if (parentComponent === 'NewsPostPage') {
            return (
              <div className="w-full">
                {paragraphs.map((paragraph, i) => (
                  <div
                    className={`text-18 font-light leading-30 text-black leading-wide font-merriw laptop:text-18 desktop:text-20 innerTextPadding hasLinksAndBolds ${i + 1 === paragraphs.length ? 'laptop:pb-3' : 'pb-7 laptop:pb-8'}`}
                    key={`${paragraph}-${i}`}
                  >
                    {parseParagraph(paragraph)}
                  </div>
                ))}
              </div>
            );
          }

          if (parentComponent === 'StandardArticlePage') {
            return (
              <div className="w-full">
                {paragraphs.map((paragraph, i) => (
                  <div
                    className={`text-base font-light text-black leading-wide font-merriw laptop:text-18 desktop:text-20 innerTextPadding hasLinksAndBolds ${i + 1 === paragraphs.length ? 'laptop:pb-8' : 'pb-7 laptop:pb-8'}`}
                    key={`${paragraph}-${i}`}
                  >
                    {parseParagraph(paragraph)}
                  </div>
                ))}
              </div>
            );
          }

          return (
            <div className="w-full">
              {paragraphs.map((paragraph, i) => (
                <div
                  className={`text-base font-light text-black leading-wide font-merriw laptop:text-18 desktop:text-20 innerTextPadding hasLinksAndBolds ${i + 1 === paragraphs.length ? 'laptop:pb-3' : 'pb-7 laptop:pb-8'}`}
                  key={`${paragraph}-${i}`}
                >
                  {parseParagraph(paragraph)}
                </div>
              ))}
            </div>
          );
        }}
      </RoutesContext.Consumer>
    </>
  );
};

NormalBodyText.propTypes = {
  text: PropTypes.shape({}),
  match: PropTypes.shape().isRequired,
  firstLetterBig: PropTypes.bool,
  enhanced: PropTypes.bool,
  parentComponent: PropTypes.string,
  quote: PropTypes.bool,
  creditslayout: PropTypes.bool,
  firstInBody: PropTypes.bool,
};

NormalBodyText.defaultProps = {
  text: null,
  parentComponent: null,
  firstLetterBig: false,
  enhanced: false,
  quote: false,
  creditslayout: false,
  firstInBody: false,
};

export default withRouter(NormalBodyText);
