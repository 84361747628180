import React from 'react';
import { PropTypes } from 'prop-types';
// import Container from '../Container';
import parse from 'html-react-parser';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import imageModifier from '../helpers/imageModifier';

const ExplodedModule = ({
  title,
  subTitle = false,
  text,
  images,
  reusableCompsList,
  bgColor,
  match,
}) => (
  <RoutesContext.Consumer>

    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const {language = 'en'} = localization;
      const background = bgColor.includes('#') ? bgColor : `#${bgColor}`;
      return (
        <div
          className="mt-12 text-black grid col-span-6 tablet:col-span-12 laptop:col-span-10 laptop:col-start-2 grid-cols-6 tablet:grid-cols-12 laptop:grid-cols-10 gap-x-4 font-stratos mb-10 tablet:mb-16 laptop:mb-18 pb-2 laptop:pb-8"
          style={{ backgroundColor: background }}
        >
          <div className="col-span-6 tablet:col-span-10 tablet:col-start-2 laptop:col-start-1">
            <h3 className="m-5 tablet:mx-0 tablet:mt-9 tablet:mb-10 laptop:mx-10 laptop:mt-10 laptop:mb-6 desktop:mb-12 font-bold text-24 tablet:text-30 desktop:text-36">{translateObj(title, language)}</h3>
          </div>
          {subTitle &&
            <div className="col-span-6 tablet:col-span-10 tablet:col-start-2 laptop:col-start-1">
              <h4 className="mb-5 mx-5 desktop:-mt-5 desktop:mb-5 desktop:mx-10
              laptop:mb-5 laptop:mx-10 tablet:-mt-5 tablet:mb-5 tablet:mx-0
              text-14 laptop:text-15 desktop:text-18 hasLinksAndBolds">{parse(translateObj(subTitle, language))}</h4>
            </div>
          }
          {images === false ? (
            <div className="col-span-6 tablet:col-span-9 laptop:col-span-8 tablet:col-start-3 laptop:col-start-3 desktop:col-start-3 tablet:pb-6 laptop:pb-0">
              {text.hasOwnProperty('en') && (
                <div className="block font-stratos text-black text-base tablet:text-18 font-semilight leading-26 pb-6 ml-5 tablet:ml-0 mr-4 laptop:mr-10 innerTextPadding hasLinksAndBolds">
                  {parse(translateObj(text, language))}
                </div>
              )}
            </div>
          ) : (
            <>
              <div key="images" className="col-span-6 tablet:col-span-9 laptop:col-span-5 desktop:col-span-10 desktop:flex">
                {images && images.map((image, index) => {
                  const caption = image.caption.hasOwnProperty('en') && image.caption.en !== '' ? translateObj(image.caption, language) : '';
                  const imgUrl = image.image.hasOwnProperty('en') && image.image.en !== '' ? image.image : null;

                  return (
                    <div key={`${index + 1}`} className={`mt-1 mb-4 desktop:w-1/2 ${index === 0 ? 'desktop:pr-5' : 'desktop:pl-5'}`}>
                      {imgUrl ? (
                        <img
                          className="w-full block"
                          src={imageModifier(translateObj(imgUrl, language), '1024x0')}
                          alt={caption || imgUrl.en}
                        />
                      ) : (
                        <div className="w-full bg-grey" style={{ paddingBottom: '62.256%' }} />
                      )}
                      {caption !== '' && <span className="block text-11 tablet:text-sm laptop:text-sm desktop:text-base text-grey mt-2 laptop:mt-10px desktop:mt-2 px-5 mb-3 tablet:px-7 laptop:px-5 tablet:mb-10 laptop:mb-5">{caption}</span>}
                    </div>
                  );
                })}
              </div>
              <div className="col-span-6 tablet:col-span-9 tablet:col-start-3 laptop:col-start-0 laptop:col-span-5 desktop:col-start-3 desktop:col-span-8">
                {text.hasOwnProperty('en') && (
                  <div className="block px-5 tablet:px-0 pb-6 font-stratos text-black mt-14px tablet:mt-0 text-base font-semilight leading-26 tablet:text-18 tablet:pb-12 laptop:pb-6 laptop:mr-10 innerTextPadding hasLinksAndBolds">
                    {parse(translateObj(text, language))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

export default ExplodedModule;

ExplodedModule.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.shape({
    en: PropTypes.string,
  }),
  text: PropTypes.shape({
    en: PropTypes.string,
  }),
  images: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          en: PropTypes.string,
        }),
        caption: PropTypes.shape({
          en: PropTypes.string,
        }),
      }),
    ),
  ]),
  reusableCompsList: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
      }),
    ),
  ]),
  bgColor: PropTypes.string,
};

ExplodedModule.defaultProps = {
  title: {
    en: '',
  },
  text: {
    en: '',
  },
  images: false,
  reusableCompsList: false,
  bgColor: '#ECECE7',
};
