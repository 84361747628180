import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const InnerLink = ({
  linkTo, text, icon, textColor, textSize, match,
}) => {
  const iconSize = Number(textSize) - 2;
  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div className="py-12 sm:py-8 md:py-8">
            <Link to={`${localization.path}/radio/shows/${linkTo}`}>
              {' '}
              <span className={`font-icon ${icon} text-${iconSize} text-${textColor}`} />
              {' '}
              <span className={`font-americaMonoBold text-${textSize} text-${textColor} leading-normal uppercase`}>
                {translateObj(text, localization.language)}
              </span>
            </Link>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

InnerLink.propTypes = {
  textColor: PropTypes.string,
  textSize: PropTypes.string,
  text: PropTypes.shape({}).isRequired,
  linkTo: PropTypes.string.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  match: PropTypes.shape().isRequired,
};

InnerLink.defaultProps = {
  textColor: 'black',
  icon: 'icon-arrow-right',
  textSize: '14',
};

export default withRouter(InnerLink);
