import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';
// import RoutesContext from '../../context/routesContext';
// import getLocalization from '../../util/localization';
// import SearchBasicLayout from './SearchBasicLayout';
// import SearchRepeat from './SearchRepeat';
// import getSearchPosts from '../../api/graphql/queries/searchPosts';
// Import components
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
// import SearchResultsDataInfo from './Search/SearchResultsDataInfo';
import InputSearch from './Search/InputSearch';
import SearchResultsList from './Search/SearchResultsList';
import SearchResultsByTag from './Search/SearchResultsByTag';
import SearchResultsByTagSearch from './Search/SearchResultsByTagSearch';
import SearchResultsRelatedArtists from './Search/SearchResultsRelatedArtists';
import translateObj from '../../util/translateObj';
import { useRoutes } from '../../context/routesContext';
import AlgoliaSearchByTag from './Search/AlgoliaSearchByTag';
// beb2b1dd8b0e8db3d2da8eebbe162754
// RZ9FRJCM47
// allItems

const algoliaClient = algoliasearch(
  'RZ9FRJCM47',
  'beb2b1dd8b0e8db3d2da8eebbe162754',
  { _useRequestCache: true },
);

// "custom" : "authorIndex"

const searchClient = {
  search(requests) {
    // console.log('🚀 ~ file: SearchPage.jsx ~ line 30 ~ search ~ requests', requests);

    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

export const ALGOLIA_INDEX = `${process.env.NODE_ENV === 'development' ? 'dev_' : ''}allItems`;

const SearchPage = ({
  searchTerm,
  categoryItems,
  categoryItemsSub,
  layout,
  hasSortOption,
  match,
  custom,
  exactSearch,
}) => {
  const { language } = useRoutes(match);
  const location = (typeof window !== 'undefined') ? useLocation() : { search: '' };
  const urlParams = new URLSearchParams(location.search);

  const [filterType, setFilterType] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [queryFromUrl, setQueryFromUrl] = useState('');
  const [tagFromUrl, setTagFromUrl] = useState('');
  const [categoryFromUrl, setCategoryFromUrl] = useState('');
  const [sorting, setSorting] = useState('createdAt_desc');
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (urlParams.has('q')) {
      let q = urlParams.get('q');
      if (exactSearch && !/^".*"$/.test(q.trim())) {
        // Search string has no double quotes at start and end, so add them to have an exact search
        q = `"${q}"`;
      }
      setQueryFromUrl(q);
    }
    if (urlParams.has('tag')) {
      const q = urlParams.get('tag');
      setTagFromUrl(q);
    }
    if (urlParams.has('categoria')) {
      const cat = urlParams.get('categoria');
      setCategoryFromUrl(cat);
    }
  }, []);

  const updateSearchState = (config) => {
    if (config.sortBy) {
      setSorting(config.sortBy);
    }
    if (config.attributeForMyQuery) {
      setQuery(config.attributeForMyQuery);
    } else {
      setQuery('');
    }
  };

  if (searchTerm) {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categoriesTitle, setCategoriesTitle] = useState('');
    const [subCategoriesTitle, setSubCategoriesTitle] = useState('');

    useEffect(() => {
      const { title: currentTitleCategory, items: currentCategoryItems } = categoryItems;
      const { title: currentTitleCategoryItemsSub, items: currentCategoryItemsSub } = categoryItemsSub;
      if (currentTitleCategory) setCategoriesTitle(translateObj(currentTitleCategory, language));
      if (currentTitleCategory) setSubCategoriesTitle(translateObj(currentTitleCategoryItemsSub, language));
      if (currentCategoryItems) setCategories(currentCategoryItems);
      if (currentCategoryItemsSub) setSubCategories(currentCategoryItemsSub);
      if (urlParams.has('cat')) {
        setCurrentCategory(urlParams.get('cat'));
      }
    }, [language, categoryItems, categoryItemsSub, searchTerm]);

    useEffect(() => {
      categories.forEach((item) => {
        if (item.slugName === currentCategory) {
          setFilterType('category');
        }
      });
      subCategories.forEach((item) => {
        if (item.slugName === currentCategory) {
          setFilterType('subcategory');
        }
      });
    }, [currentCategory]);

    const updateFilterType = (fType) => setFilterType(fType);
    const searchTagDefaultOptions = {
      attribute: 'postCategory',
      filterType,
      isListPage: hasSortOption,
      updateFilterType,
      currentCategory,
    };

    // User typed search
    return (
      <div className="static w-full pin-t pin-l pin-r bg-white z-top transition-background-04s desktop:mt-18 mt-16">
        <InstantSearch
          searchClient={algoliaClient}
          indexName={ALGOLIA_INDEX}
          onSearchStateChange={updateSearchState}
        >
          {searchTerm && (
            <Configure
              filters={`postCategory:${searchTerm}`}
              distinct
              hitsPerPage={40}
            />
          )}
          <div className="mx-2 tablet:flex desktop:flex laptop:flex flex-row justify-between items-start tablet:mx-26px laptop:mx-16">
            <div className="w-full tablet:mb-8 tablet:mt-7">
              <SearchResultsByTag
                {...searchTagDefaultOptions}
                title={categoriesTitle}
                type="category"
                checkCategories={categories}
                currentCategory={currentCategory}
              />
            </div>
            {subCategories && subCategories.length > 0 && (
            <div className="w-full tablet:mb-8">
              <SearchResultsByTag
                {...searchTagDefaultOptions}
                color="red"
                title={subCategoriesTitle}
                type="subcategory"
                checkCategories={subCategories}
                currentCategory={currentCategory}
              />
            </div>
            )}
          </div>
          <div className="mx-2 tablet:mx-0 -mt-2 tablet:-mt-12 laptop:-mt-20 desktop:-mt-12 mb-8 tablet:mb-20">
            <InputSearch isListPage />
          </div>
          <div className="tablet:mx-18px laptop:mx-13">
            <SearchResultsList sorting={sorting} query={query} isListPage listPageType={layout || ''} custom={custom} />
          </div>
        </InstantSearch>
      </div>
    );
  }

  if (tagFromUrl && !query) {
    return (
      <AlgoliaSearchByTag
        algoliaClient={algoliaClient}
        updateSearchState={updateSearchState}
        tag={tagFromUrl}
      />
    );
  }

  if (categoryFromUrl && !query) {
    return (
      <div className="static w-full pin-t pin-l pin-r bg-white z-top transition-background-04s">
        <InstantSearch
          searchClient={algoliaClient}
          indexName={ALGOLIA_INDEX}
          // onSearchStateChange={updateSearchState}
        >
          <Configure
            filters={`postCategory:${categoryFromUrl}`}
            distinct
            hitsPerPage={40}
          />
          <div className="mt-16 tablet:mt-20 mb-8 laptop:mt-36 desktop:mt-32 w-full">
            <InputSearch isListPage />
          </div>
          <div className="tablet:mx-18px laptop:mx-13">
            <SearchResultsList sorting={sorting} query={query} isListPage listPageType={layout || ''} custom={custom} />
          </div>
        </InstantSearch>
      </div>
    );
  }

  // Search by querystring, like /artista?q=billie%20eilish
  return (
    <div className="static w-full pin-t pin-l pin-r bg-white z-top transition-background-04s">
      <InstantSearch
        searchClient={searchClient}
        createURL={(searchState) => `?q=${searchState.query}`}
        indexName={custom || ALGOLIA_INDEX}
        onSearchStateChange={updateSearchState}
      >
        <Configure
          query="rosa"
            // filters={`postCategory:${searchTerm}`}
          distinct
          hitsPerPage={40}
          {...(custom === 'artistsIndex' && urlParams.get('q') && { facetFilters: [`postDescription.relatedArtists.name:${urlParams.get('q')}`] })}
        />
        <div className="mx-2 desktop:mx-3 px-px">
          <InputSearch queryFromUrl={queryFromUrl} custom={custom} />
        </div>

        {query.length > 0 && (
        <div className={`tablet:mx-26px laptop:mx-16 pt-10 ${queryFromUrl ? 'mt-20' : ''}`}>
          <div className="mx-2 desktop:mx-3">
            {/* <SearchResultsDataInfo /> */}
            {custom !== 'authorIndex' && <SearchResultsRelatedArtists custom={custom} queryFromUrl={queryFromUrl} query={query} />}
            {custom !== 'authorIndex' && <SearchResultsByTagSearch />}
          </div>
          <SearchResultsList query={query} custom={custom} />
        </div>
        )}

      </InstantSearch>
    </div>
  );
};

SearchPage.propTypes = {
  searchTerm: PropTypes.string,
  categoryItems: PropTypes.shape(),
  categoryItemsSub: PropTypes.shape(),
  layout: PropTypes.string,
  hasSortOption: PropTypes.bool,
  match: PropTypes.shape(),
  custom: PropTypes.string,
  exactSearch: PropTypes.bool,
};

SearchPage.defaultProps = {
  searchTerm: null,
  categoryItems: null,
  categoryItemsSub: null,
  layout: null,
  hasSortOption: false,
  match: null,
  custom: '',
  exactSearch: false,
};

export default withRouter(SearchPage);
