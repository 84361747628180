import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import SearchResult from '../SearchResult';

const InfiniteHits = ({
  hits,
  hasMore,
  refineNext,
  isListPage,
  listPageType,
  sorting,
  query,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const history = useHistory();
  const buttonRef = useRef();
  const hitRef = useRef();

  const handleLoadMore = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = urlParams.get('page') || 0;
    const search = history.location.search.toLowerCase().split('?');

    if (search.length > 2) {
      urlParams.set('page', Number(page.split('?')[0]) + 1);
    } else {
      urlParams.set('page', Number(page) + 1);
    }

    history.replace(`${history.location.pathname}?${urlParams}`);
    setFirstLoad(false);
    refineNext();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const params = urlParams.get('page') ? urlParams.get('page').split('?') : 0;
    const page = params[0];

    if (firstLoad && page > 0) {
      setFirstLoad(false);
      for (let i = 1; i <= page; i++) {
        setTimeout(() => {
          if (buttonRef && buttonRef.current) {
            const evt = new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            buttonRef.current.dispatchEvent(evt);

            setTimeout(() => {
              const elements = Array.from(hitRef.current.children);
              const element = elements[i * 40];
              const header = document.querySelector('#header');
              const item = document.getElementById(`${params[1]}`);

              if (Number(params.length) <= 1 && Number(page) === Number(i) && element) {
                window.scrollTo({ top: element.offsetTop - header.offsetHeight, behavior: 'smooth' });
              }

              if (i >= page && item) {
                item.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
              }
            }, 300);
          }
        }, 300 + 300 * i);
      }
    }
  }, []);

  return (
    <div className="w-full">
      <div ref={hitRef} className={`w-full flex ${listPageType === 'records' || listPageType === 'hemeroteca' ? 'flex-row flex-wrap' : 'flex-col tablet:flex-row tablet:flex-wrap '}`}>
        {hits.map((hit) => (
          <SearchResult
            showFeatured={(sorting.includes('desc') && query.length === 0 && isListPage && listPageType !== 'hemeroteca')}
            key={`${hit.slugName}_${hit.__position}`}
            {...hit}
            isAlgolia
            index={hit.__position}
            isListPage={isListPage}
            listPageType={listPageType}
            query={query}
          />
        ))}
        {hits.length === 0 && <div className="font-stratos text-black mb-24 text-center">No se han encontrado resultados.</div>}
      </div>
      {hasMore && (
      <div className="text-center">
        <button className="font-stratos text-black mb-12 text-20 font-semibold hover:underline" type="button" onClick={handleLoadMore}>Cargar más</button>
        <button className="hidden" type="button" onClick={refineNext} ref={buttonRef} />
      </div>
      )}
    </div>
  );
};

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

export default CustomInfiniteHits;

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  hits: PropTypes.arrayOf(PropTypes.shape()),
  refineNext: PropTypes.func,
  isListPage: PropTypes.bool,
  listPageType: PropTypes.string,
  sorting: PropTypes.string,
  query: PropTypes.string,
  custom: PropTypes.string,
};

InfiniteHits.defaultProps = {
  hits: [],
  refineNext: () => {},
  isListPage: false,
  listPageType: null,
  sorting: 'desc',
  query: '',
  custom: '',
};
