/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { useRoutes } from '../../../context/routesContext';

import CoverScreenPlayer from './mainVideoScreens/CoverScreenPlayer';
import DashVideoScreen from './mainVideoScreens/DashVideoScreen';
import LowDashVideoScreen from './mainVideoScreens/LowDashVideoScreen';
import VimeoVideoScreen from './mainVideoScreens/VimeoVideoScreen';
import MobileVimeoVideoScreen from './mainVideoScreens/MobileVimeoVideoScreen';
import YoutubeVideoScreen from './mainVideoScreens/YoutubeVideoScreen';
import VimeoVideoScreenPlayer from './mainVideoScreens/VimeoVideoScreenPlayer';
import MobileVideoScreen from './mainVideoScreens/MobileVideoScreen';
import translateObj from '../../../util/translateObj';

const PLAYER_RATIO = '56.25%'; /* Player ratio: 100 / (1280 / 720) */
const COVER = 'cover';
const FINAL_COVER = 'finalCover';
const DASH = 'dash';
const LOW_DASH = 'lowDash';
const VIMEO = 'vimeo';
const VIMEOPLAYER = 'vimeoPlayer';
const YOUTUBE = 'youtube';

const StreamingVideoPlayer = ({
  addeventId, videos, source, smalHlsVideoUrl, coverImageUrl, activeSlide, device, getDeviceWidth, match, initialCoverImage, finalCoverImage,
}) => {
  const { language = 'en' } = useRoutes(match);
  const [countIsReady, setCountIsReady] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [arrayOfVideos] = useState(videos);
  const [mainVideo, setMainVideo] = useState(null);
  const [mainMobileVideo, setMainMobileVideo] = useState(null);
  const [shouldSmallVideoStart, setShouldSmallVideoStart] = useState(false);
  const [shouldMainVideoStart, setShouldMainVideoStart] = useState(false);

  //

  // ! remove
  // const [componentShouldGetQuality, setComponentShouldGetQuality] = useState(false);

  // const getQualityFn = (quality, url) => {
  //   setTimeout(() => {
  //     setComponentShouldGetQuality(false);
  //   }, 2000);
  // };

  // useEffect(() => {
  //   if (window) { console.log('window navigator vendor!!!', window.navigator.vendor); }
  // }, []);

  //* handle mobile vs desktop views
  useEffect(() => {
    if (device) {
      const isTheScreenDesktop = device !== 'sm' && device !== 'md';
      if (!isTheScreenDesktop) {
        setMainMobileVideo(smalHlsVideoUrl || (arrayOfVideos && arrayOfVideos.length && arrayOfVideos[0].url) || '');
        setIsMobile(true);
        setIsLoading(false);
      } else {
        setIsMobile(false);
      }
    }
  }, [device]);

  useEffect(() => {
    if (videos && videos.length) {
      setMainVideo(videos[0].url);
      // setIsLoading(false);
    }
  }, [videos]);

  const handlePlayingSmallScreens = (shouldStart) => {
    setShouldSmallVideoStart(shouldStart);
  };

  const handleOnReadyTrueForAll = () => {
    setCountIsReady(prevState => prevState + 1);
  };

  // * control when players are ready
  useEffect(() => {
    if (source === DASH) {
      if (arrayOfVideos.length === 1 && countIsReady === arrayOfVideos.length) {
        setIsLoading(false);
      } else if (countIsReady === arrayOfVideos.length * 2) {
        setIsLoading(false);
      }
    } else if (source === LOW_DASH) {
      if (countIsReady === arrayOfVideos.length) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [countIsReady]);


  const handleSwitchingVideos = (url) => {
    setShouldMainVideoStart(true);

    setMainVideo(url);
  };

  useEffect(() => {
    if (!isLoading && device === 'sm' && getDeviceWidth) getDeviceWidth();
  }, [isLoading]);

  const decideCoverImageSource = (currentSource) => {
    if (!initialCoverImage || !finalCoverImage || !initialCoverImage.hasOwnProperty('en') || !initialCoverImage.hasOwnProperty('en')) {
      return coverImageUrl;
    }
    if (currentSource === COVER) {
      return translateObj(initialCoverImage, language) || coverImageUrl;
    }
    if (currentSource === FINAL_COVER) {
      return translateObj(finalCoverImage, language) || coverImageUrl;
    }

    return coverImageUrl;
  };

  const renderFunction = () => {
    if (source === COVER || source === FINAL_COVER) {
      const coverImageSourceToBeUsed = decideCoverImageSource(source);
      return (
        <CoverScreenPlayer
          addeventId={addeventId}
          coverImageUrl={coverImageSourceToBeUsed || coverImageUrl}
          PLAYER_RATIO={PLAYER_RATIO}
        />
      );
    }
    if (source === DASH) {
      return (
        <DashVideoScreen {...{
          isLoading,
          mainVideo,
          arrayOfVideos,
          handlePlayingSmallScreens,
          shouldMainVideoStart,
          handleOnReadyTrueForAll,
          PLAYER_RATIO,
          handleSwitchingVideos,
          shouldSmallVideoStart,
          activeSlide,
        }
        }
        />

      );
    }

    if (source === LOW_DASH) {
      return (
        <LowDashVideoScreen {...{
          isLoading,
          mainVideo,
          arrayOfVideos,
          handlePlayingSmallScreens,
          shouldMainVideoStart,
          handleOnReadyTrueForAll,
          PLAYER_RATIO,
          handleSwitchingVideos,
          shouldSmallVideoStart,
          activeSlide,
        }
        }
        />
      );
    }
    if (source === VIMEOPLAYER) {
      return (
        <VimeoVideoScreenPlayer {...{
          isLoading,
          mainVideo,
          PLAYER_RATIO,
          activeSlide,
        }
    }
        />
      );
    }

    if (source === VIMEO) {
      return (
        <VimeoVideoScreen {...{
          isLoading,
          mainVideo,
          // arrayOfVideos,
          // handlePlayingSmallScreens,
          // shouldMainVideoStart,
          // handleOnReadyTrueForAll,
          PLAYER_RATIO,
          // handleSwitchingVideos,
          // shouldSmallVideoStart,
          // activeSlide,
        }
        }
        />
      );
    }
    return (
      <YoutubeVideoScreen {...{
        isLoading,
        mainVideo,
        PLAYER_RATIO,
        activeSlide,
      }
      }
      />
    );
  };

  const renderMobile = () => {
    if (source === COVER || source === FINAL_COVER) {
      const coverImageSourceToBeUsed = decideCoverImageSource(source);
      return (
        <CoverScreenPlayer
          addeventId={addeventId}
          coverImageUrl={coverImageSourceToBeUsed || coverImageUrl}
          PLAYER_RATIO={PLAYER_RATIO}
        />
      );
    }

    if (source === VIMEO) {
      return (
        <MobileVimeoVideoScreen
          mainVideo={mainMobileVideo || mainVideo}
          isLoading={isLoading}
          PLAYER_RATIO={PLAYER_RATIO}
        />
      );
    }

    return (
      <MobileVideoScreen {...{
        isLoading,
        mainVideo,
        mainMobileVideo,
        PLAYER_RATIO,
        source,
      }
      }
      />
    );
  };

  return (
    <>
      {isMobile ? (
        renderMobile()
      ) : renderFunction()
      }
    </>
  );
};

StreamingVideoPlayer.propTypes = {
  match: PropTypes.shape().isRequired,
  addeventId: PropTypes.string,
  videos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  source: PropTypes.string,
  smalHlsVideoUrl: PropTypes.string,
  coverImageUrl: PropTypes.string,
  activeSlide: PropTypes.number,
  initialCoverImage: PropTypes.PropTypes.shape(),
  finalCoverImage: PropTypes.PropTypes.shape(),
};

StreamingVideoPlayer.defaultProps = {
  addeventId: null,
  source: 'vimeo',
  smalHlsVideoUrl: '',
  coverImageUrl: '',
  activeSlide: 0,
  initialCoverImage: null,
  finalCoverImage: null,
};

export default withRouter(StreamingVideoPlayer);
