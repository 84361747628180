/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import RoutesContext from '../../../../../context/routesContext';
import getLocalization from '../../../../../util/localization';
import translateObj from '../../../../../util/translateObj';
import SimpleButton from '../../../Buttons/SimpleButton';
import SimpleRadioButton from '../../../Buttons/SimpleRadioButton';
import NewsLetterSettingsModule from '../../../NewsLetterSettingsModule';
import { UserProfileContext } from '../../../../../context/userProfileContext';
import getQueryVariable from '../../../../../util/getQueryVariable';
import postNewsletterForm from '../../../../../api/graphql/mutations/postNewsletterForm';
import deleteNewsletterData from '../../../../../api/graphql/mutations/deleteNewsletterData';
import getNewsletterSettings from '../../../../../api/graphql/queries/getNewsletterSettings';

const NewsletterFormsy = ({
  userEmail,
  userRoles,
  match,
  scopes,
  location,
  newsletterCallback,
}) => {
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [scopesToRender, setScopes] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const translatedTexts = {
    select: {
      en: 'Selecciona los temas que te interesan.',
      es: 'Selecciona los temas que te interesan.',
    },
    userNotFound: {
      en: 'Para acceder a esta sección debes validar tu registro con el email que te hemos enviado.',
      es: 'Para acceder a esta sección debes validar tu registro con el email que te hemos enviado.',
    },
    loggedUserRequired: {
      en: 'Para cambiar este valor debes loguearte.',
      es: 'Para cambiar este valor debes loguearte.',
    },
  };

  const code = getQueryVariable(location.search, 'code') || userEmail;

  const processNewsLetterSettings = (res) => {
    const { result } = res;
    if (result) {
      if (result.custom_fields) {
        const activeScopes = scopes.map((sc) => {
          const modSc = { ...sc };
          const scIndex = result.custom_fields.findIndex(field => field.name === sc.scope);
          if (scIndex !== -1) {
            modSc.isSubscribed = result.custom_fields[scIndex].value === '1';
          }
          modSc.isLoading = false;

          // If requiredRoles is present, the user has to have at least one of the roles specified
          if (sc.active && sc.requiredRoles && sc.requiredRoles.length) {
            const userHaseRequiredRole = userRoles.some(role => sc.requiredRoles.includes(role));
            modSc.active = userHaseRequiredRole;

            if (!userEmail) {
              sc.inactiveReason = 'loggedUserRequired';
              modSc.inactiveReason = 'loggedUserRequired';
            } else if (userEmail && !userHaseRequiredRole) {
              sc.inactiveReason = 'roleRequired';
              modSc.inactiveReason = 'roleRequired';
            }

            sc.active = modSc.active;
          }
          return modSc;
        });
        setScopes(activeScopes);
        setSettings(result);
        setIsLoading(false);
      }
    }
  };

  const updateNewsLetterSettings = (res) => {
    if (res) {
      if (res.scopes) {
        const activeScopes = scopes.map((sc) => {
          const modSc = { ...sc };
          const scIndex = res.scopes.findIndex(field => field.name === sc.scope);
          if (scIndex !== -1) {
            modSc.isSubscribed = res.scopes[scIndex].value === '1';
          }
          modSc.isLoading = false;
          return modSc;
        });
        setScopes(activeScopes);
        setSettings(res);
      }
    }
  };

  const getSettings = async () => {
    const res = await getNewsletterSettings(code);
    if (res && res.result) {
      processNewsLetterSettings(res);
      return;
    }
    const { message } = res;
    if (message === 'No user found') {
      setErrorMessage(translatedTexts.userNotFound);
    }
  };

  const toggleSubscription = (scope) => {
    setErrorMessage();
    const currentScope = [...scopesToRender].find(sc => sc.scope === scope);
    if (currentScope) {
      if (currentScope.inactiveReason === 'loggedUserRequired') {
        setErrorMessage(translatedTexts.loggedUserRequired);
        return;
      }
      if (currentScope.inactiveReason === 'roleRequired') {
        setErrorMessage(currentScope.requiredRolesErrorMessage);
        return;
      }

      currentScope.isLoading = true;
      const currentScopeIndex = scopesToRender.indexOf(currentScope);
      const newScopes = [...scopesToRender];
      newScopes[currentScopeIndex] = currentScope;
      setScopes(newScopes);
      const isSubscribing = !currentScope.isSubscribed;
      postNewsletterForm({
        email: settings.email, language: settings.language, scopes: [scope], isSubscribing, isOriginSettings: true,
      }).then((res) => {
        if (res.isValid) {
          updateNewsLetterSettings(res);
        }
      });
    }
  };

  useEffect(() => {
    if (code) {
      getSettings();
    }
  }, []);

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div>
            <NewsLetterSettingsModule match={match} location={localization} scopes={scopes} />
            <div className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5">
              <div className="font-stratos text-20 text-black col-span-4 tablet:col-span-9 laptop:col-span-5 tablet:col-start-2 laptop:col-start-2">
                <p>{translateObj(errorMessage || translatedTexts.select, localization.language)}</p>
              </div>
              <div className="flex flex-col tablet:flex-wrap tablet:flex-row items-stretch justify-start tablet:items-start tablet:justify-start mt-4 col-span-4 tablet:col-span-9 laptop:col-span-5 tablet:col-start-2 laptop:col-start-2 font-stratos text-sm font-bold tex-black">
                {scopesToRender.map(sc => (
                  <div className="tablet:w-1/3 flex items-center justify-start mb-6" key={sc.scope}>
                    <button type="button" onClick={() => { toggleSubscription(sc.scope); }}>
                      <SimpleRadioButton active={sc.isSubscribed} />
                    </button>
                    <span className={`ml-10px ${!sc.active && 'text-grey'}`}>{sc.title.es || sc.title.en}</span>
                  </div>
                ))}
              </div>
              <div
                className="col-span-2 col-end-5 tablet:col-span-3 tablet:col-end-11 laptop:col-span-2 mt-1 laptop:col-end-9 row-start-3 cursor-pointer flex items-center justify-end"
                onClick={() => newsletterCallback()}
              >
                {/* <SimpleButton
                  isDisabled
                  isLowercase
                  isFullWidth
                  theme={{
                    base: 'red',
                    hover: 'red',
                  }}
                  text={{
                    en: 'Save changes',
                    es: 'Guardar cambios',
                  }}
                /> */}
              </div>
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default NewsletterFormsy;

NewsletterFormsy.propTypes = {
  match: PropTypes.shape({}).isRequired,
  newsletterCallback: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string),
  scopes: PropTypes.arrayOf(PropTypes.shape()),
};

NewsletterFormsy.defaultProps = {
  userEmail: '',
  userRoles: [],
  scopes: [],
};
