import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import imageModifier from '../helpers/imageModifier';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

const SliderBannerRecords = ({
  image, left, right, match, areSlidesShort,
}) => {
  const [deviceWidth, setDeviceWidth] = useState();
  const responsiveUtility = useRef();

  const getWidth = () => {
    // actions when window is resized
    setDeviceWidth(responsiveUtility.current.deviceScreen(true));
  };

  useEffect(() => {
    getWidth();
  }, []);

  useEffect(() => {
  }, [deviceWidth]);
  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const hasLabel = left.length > 0 ? left[0].text : {};
        const label = translateObj(hasLabel, localization.language);
        const resizedImage = imageModifier(translateObj(image, localization.language), '514x0');
        return (
          <>
            <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getWidth} rdlSizes />
            <div
              className="
              relative w-full grid grid-cols-6 gap-x-3 flex items-end
              tablet:grid-cols-12 tablet:mt-0
              laptop:grid-cols-10 laptop:gap-x-4 laptop:pb-4
              desktop:pb-0"
            >
              <div
                className="
                uppercase col-start-1 col-end-5 mb-8 pr-4
                tablet:col-start-9 tablet:col-end-13 tablet:mb-52 tablet:pr-0
                laptop:mb-230px laptop:col-start-1 laptop:col-end-3 laptop:mb-0"
              >
                {hasLabel
                  && (
                  <h1 className="
                  font-stratos text-20 font-semibold break-words text-black
                  laptop:laptop:text-22
                  desktop:text-28"
                  >
                    <span className="text-red block">{label.substr(0, 3)}</span>
                    {label.substr(2, label.length)}
                  </h1>
                  )
                }
              </div>
              <div
                className="
                w-full row-start-2 col-start-1 col-span-6 mb-8
                tablet:row-start-1 tablet:col-start-1 tablet:col-span-7 tablet:mb-12
                laptop:col-start-4 laptop:col-span-7 laptop:mb-0"
              >
                <div className="w-auto">
                  <img
                    {...(resizedImage === translateObj(image, localization.language) && { width: '514' })}
                    src={resizedImage}
                    alt={translateObj(left.text, localization.language)}
                  />
                </div>
              </div>
            </div>
          </>
        );
      }
      }
    </RoutesContext.Consumer>
  );
};

SliderBannerRecords.propTypes = {
  image: PropTypes.shape({}),
  right: PropTypes.arrayOf(PropTypes.shape()),
  left: PropTypes.arrayOf(PropTypes.shape()),
  areSlidesShort: PropTypes.bool,
  match: PropTypes.shape().isRequired,
};

SliderBannerRecords.defaultProps = {
  right: null,
  left: null,
  image: {},
  areSlidesShort: false,
};

export default withRouter(SliderBannerRecords);
