/* eslint-disable object-curly-newline */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { useTheme } from '../context/themeContext';
import { LOGIN_URL, getStringSet } from '../utils/constants';
import { useRoutes } from '../../../../context/routesContext';

const LoginButton = ({ isQuestions, match, shouldToggle, location }) => {
  const { theme, color } = useTheme();
  const { language } = useRoutes(match);
  const stringSet = getStringSet(language);

  return (
    <div className={`font-americaMonoRegular border-black border-t ${color === 'black' ? 'bg-white' : `bg-${theme}-box`} w-full p-4 absolute pin-b pin-r ${shouldToggle ? 'mb-10' : ''}`}>
      <h3 className="text-white uppercase text-xs mb-4">{isQuestions ? stringSet.QUESTIONS_BUTTON : stringSet.CHAT_BUTTON }</h3>
      <div className="relative">
        <Link to={`${LOGIN_URL},${location ? location.pathname : ''}`}>
          <div className={`font-americaRegular w-full h-8 text-xs p-2 bg-grey-darker rounded pr-8 border border-${theme}-grey active:border-barcelona text-${color} ${color === 'black' ? 'bg-white' : 'bg-grey-darker'}`}>
            {stringSet.LOGIN_BUTTON}
          </div>
        </Link>
      </div>
    </div>
  );
};

LoginButton.propTypes = {
  isQuestions: PropTypes.bool,
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

LoginButton.defaultProps = {
  isQuestions: false,
};


export default withRouter(LoginButton);
