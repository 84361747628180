import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import withRadioPlayerContext from '../../context/withRadioPlayerContext';
import withAllContexts from '../../context/withAllContexts';

const isInViewport = (elem) => {
  if (elem) {
    const bounding = elem.getBoundingClientRect();
    return (
      {
        isInView: bounding.top >= 0
        && bounding.bottom <= (window.innerHeight + elem.offsetHeight || document.documentElement.clientHeight + elem.offsetHeight),
        footerHeight: elem.offsetHeight,
      }
    );
  }
  return {
    isInView: false,
    footerHeight: 0,
  };
};
class ScrollToTop extends Component {
  state = {
    isVisible: false,
    isFooterInView: false,
    footerHeight: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      location: { search, pathname },
    } = this.props;
    if (pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { isVisible, isFooterInView, footerHeight } = this.state;
    const offsetY = window.pageYOffset;
    if (document && document.getElementById('footer')) {
      const footer = document.getElementById('footer');
      const footerIsInViewport = isInViewport(footer);
      if (offsetY > 500 && !isVisible) {
        this.setState({ isVisible: true });
      }
      if (offsetY <= 500 && isVisible) {
        this.setState({ isVisible: false });
      }
      if (isFooterInView !== footerIsInViewport.isInView) {
        this.setState({
          isFooterInView: !isFooterInView,
        });
      }
      if (footerHeight !== footerIsInViewport.footerHeight) {
        this.setState({
          footerHeight: footerIsInViewport.footerHeight,
        });
      }
    }
  }

  onClick = () => {
    scroll.scrollToTop({ smooth: 'linear' });
  };

  setStyles = (isFooterInView, playerPosition, showRadioPlayer) => {
    const { footerHeight } = this.state;
    if ((isFooterInView && playerPosition === 'pin-b') || (!showRadioPlayer && isFooterInView)) {
      return { bottom: footerHeight + 10, position: 'absolute' };
    }
    if (isFooterInView) {
      return { bottom: footerHeight + 20, position: 'absolute' };
    }
    return { };
  }

  render() {
    const { showRadioPlayer, playerPosition } = this.props;
    const { isVisible, isFooterInView } = this.state;
    return (
      <div id="scrollToTop" className={`mb-4 pin-b pin-r mr-4 scrollToTop-Button-Wrapper fixed hidden laptop:block trans-opacity ${!isVisible ? 'z-neg' : 'z-499'}`} style={this.setStyles(isFooterInView, playerPosition, showRadioPlayer)}>
        <div onClick={this.onClick} className={`scrollToTop-Button-new trans rounded-full bg-white shadow-lg ${isVisible ? 'scrollbutton__in' : 'scrollbutton__out'}`}>
          <svg viewBox="0 0 48 48">
            <path className="scrollToTop-Button-Arrow" d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z" />
          </svg>
        </div>
      </div>
    );
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default withRouter(withRadioPlayerContext(withAllContexts(ScrollToTop)));
