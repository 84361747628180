import React, { useState, useEffect, useRef } from 'react';
import Swiper from 'react-id-swiper';
import { PropTypes } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ComponentIterator from '../../containers/ComponentIterator';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';

const paramsMobile = {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: '.swiper-pagination-mobile',
    clickable: true,
  },
  runCallbacksOnInit: true,
  rebuildOnUpdate: true,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
};

const params = {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  touchEventsTarget: 'wrapper',
  rebuildOnUpdate: true,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
};
const Carousel = ({
  components, match, areSlidesShort, isFooterSlider, isHomePage, showOpacity,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [device, setDevice] = useState(null);

  const responsiveUtility = useRef();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setDevice(deviceScreen);
  };

  const handleRectangleHeight = () => {
    switch (device) {
      case 'mobile': return '83%';
      case 'tablet': return '100%';
      case 'laptop': return '95%';
      default:
      case 'desktop': return '100%';
    }
  };

  const Rectangle = () => (
    <div
      className="absolute z-10 flex items-center justify-center pointer-events-none pin"
      style={{
        mixBlendMode: 'multiply',
        height: handleRectangleHeight(),
      }}
    >
      <div
        style={{
          borderStyle: 'solid',
          borderWidth: device === 'mobile' ? '10px' : '1.875vw',
          borderColor: '#FF4646',
          boxSizing: 'border-box',
          width: device === 'mobile' ? '64vw' : '58vw',
          height: '14.5vw',
          minHeight: '65px',
          mixBlendMode: 'multiply',
        }}
      />
    </div>
  );

  const checkForUrl = (component) => {
    if (component.type !== 'LinkButtonRounded') return undefined;
    if (
      component.hasOwnProperty('url')
      && typeof component.url === 'string'
      && component.url !== ''
    ) {
      component.isDiv = true;
      return component.url;
    }
    return undefined;
  };

  const slidesRenderer = () => {
    if (isLoading) {
      return (
        <div className={`${areSlidesShort && 'h-screen40 md:h-carouselMD sm:h-carouselSM'} ${showOpacity ? 'sliderComponent' : ''} h-screen md:h-carouselMD sm:hidden w-full full-width mb-8 tablet:mb-0 laptop:mb-24`} />
      );
    }
    if (components && components.length > 1) {
      if (device === 'mobile') {
        return (
          <div className="relative w-full mb-8 h-620 h-screen65 tablet:h-screen55 full-width">
            <Swiper {...paramsMobile} className="relative">
              {components.map((component, index) => {
                let href;
                component.left.forEach((comp) => {
                  href = checkForUrl(comp);
                });
                if (typeof href !== 'undefined') {
                  return (
                    <Link key={`${component.type}-${index + 1}`} to={href}>
                      <ComponentIterator components={[component]} />
                    </Link>
                  );
                }
                return (
                  <div key={`${component.type}-${index + 1}`}>
                    <ComponentIterator components={[component]} />
                  </div>
                );
              })}
            </Swiper>
          </div>
        );
      }
      return (
        <div className={`${!areSlidesShort ? 'desktop:h-screen90 laptop:h-screen80 tablet:h-screen65 h-screen65' : 'h-screen40'} w-full relative ${showOpacity ? 'sliderComponent' : ''} ${isFooterSlider ? '-mb-16 sm:px-0' : 'full-width mb-8 tablet:mb-0 laptop:mb-24'}`}>
          <Rectangle />
          <Swiper {...params} className="relative">
            {components.map((component, index) => {
              let href;
              component.left.forEach((comp) => {
                href = checkForUrl(comp);
              });
              if (typeof href !== 'undefined') {
                return (
                  <Link to={href} key={`${component.type}-${index + 1}`}>
                    <ComponentIterator components={[component]} />
                  </Link>
                );
              }
              return (
                <div key={`${component.type}-${index + 1}`}>
                  <ComponentIterator components={[component]} />
                </div>
              );
            })}
          </Swiper>
        </div>
      );
    }

    if (components.length === 1) { Object.assign(components[0], { notSlider: true }); }

    return (
      <div className={`${areSlidesShort && 'h-screen40 md:h-carouselMD sm:h-carouselSM'} w-full ${isFooterSlider ? '-mb-16 sm:px-0' : 'full-width'} ${showOpacity ? 'sliderComponent' : ''}`}>
        {components.length > 0 && <ComponentIterator components={[components[0]]} />}
      </div>
    );
  };

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} rdlSizes />
      {slidesRenderer()}
    </>
  );
};

Carousel.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape()),
  classNames: PropTypes.string,
  areSlidesShort: PropTypes.bool,
  isFooterSlider: PropTypes.bool,
  match: PropTypes.shape().isRequired,
  parentComponent: PropTypes.string,
  showOpacity: PropTypes.bool.isRequired,
};

Carousel.defaultProps = {
  classNames: '',
  areSlidesShort: false,
  isFooterSlider: false,
  parentComponent: null,
  components: [],
};

export default withRouter(Carousel);
