import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';

import { Link, withRouter } from 'react-router-dom';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const MediaTitle = ({
  primaryText, secondaryText, internalLink, match, category, parentComponent,
}) => {
  const LinkText = ({ localization }) => {
    const adjustedInternalLink = internalLink === '/' ? `/${internalLink}` : internalLink;
    return (
      <span>
        {internalLink ? (
          <Link to={adjustedInternalLink}>
            <span className="text-black font-stratos text-15 tablet:text-base laptop:text-base desktop:text-21 font-light leading-normal underline cursor-pointer">
              {translateObj(secondaryText, localization.language)}
            </span>
          </Link>
        ) : (
          <span className="text-black font-stratos text-15 tablet:text-base laptop:text-base desktop:text-21 font-light leading-normal">
            {translateObj(secondaryText, localization.language)}
          </span>
        )}
      </span>
    );
  };

  LinkText.propTypes = {
    localization: PropTypes.shape({}).isRequired,
  };

  if (parentComponent === 'NewsPostPage') {
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <>
              <div className="w-full flex flex-col items-start">
                <h2
                  className="tracking-1.7px pt-3 text-34 font-stratos font-normal leading-42"
                >
                  {translateObj(primaryText, localization.language)}
                </h2>
                <div className="flex flex-row">
                  <span className="tracking-1.7px pb-6 text-34 font-stratos font-normal leading-42"> &rsaquo; </span>
                  {secondaryText?.en && secondaryText.en !== '' && (
                  <span className="text-21 font-stratos font-normal ml-2 pt-2">
                    {translateObj(secondaryText, localization.language)}
                  </span>
                  )}
                </div>
              </div>
            </>
          );
        }}
      </RoutesContext.Consumer>
    );
  }

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <>
            <div className="w-full">
              <span
                className="text-34 tablet:text-36 laptop:text-48 desktop:text-52 font-stratos text-black font-light leading-40 tablet:leading-mid desktop:leading-tight tracking-small desktop:tracking-mid"
              >
                {translateObj(primaryText, localization.language)}
                {secondaryText && secondaryText.en && secondaryText.en !== '' && <span> &rsaquo; </span>}
              </span>
              <span className="tracking-mid desktop:tracking-tight">
                {secondaryText && secondaryText.en && secondaryText.en !== '' && <LinkText localization={localization} />}
              </span>
            </div>
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

MediaTitle.propTypes = {
  match: PropTypes.shape().isRequired,
  internalLink: PropTypes.string,
  category: PropTypes.string,
  primaryText: PropTypes.shape().isRequired,
  secondaryText: PropTypes.shape(),
  parentComponent: PropTypes.string,
};
MediaTitle.defaultProps = {
  secondaryText: null,
  category: 'primavera-pink',
  internalLink: null,
  parentComponent: null,
};
export default withRouter(MediaTitle);
