import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { useRoutes } from '../../../../context/routesContext';
import { useUser } from '../../../../context/userProfileContext';
import translateObj from '../../../../util/translateObj';

import ProOnDemandModalScreen from './ProOnDemandModalScreen';

const modalStyles = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    // padding: '2rem',
  },
  overlay: {
    // background: 'rgba(0, 0, 0, 0.5)',

  },
  closeButton: {
    top: '18px',
    right: '18px',
  },
};

const backgroundColor = { backgroundColor: 'rgba(255, 102, 0)' };

const ProOnDemandAccessButton = ({
  match, image, isCentered, watchNowButtonText, sourceWeb, postVideoUrl, postVideoRole, translations,
}) => {
  const { language } = useRoutes(match);
  const userProps = useUser();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUserLoggedWithTheNeededRole, setIsUserLoggedWithTheNeededRole] = useState(false);

  const onOpenModal = () => {
    window.scrollTo(0, 0);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const { userEmail = '', userRoles = [] } = userProps;
    const doesUserHaveTheRequiredRole = userRoles.includes(postVideoRole) || userRoles.includes('admin');
    if (userEmail && doesUserHaveTheRequiredRole) {
      setIsUserLoggedWithTheNeededRole(true);
    } else {
      setIsUserLoggedWithTheNeededRole(false);
    }
  }, [userProps]);

  return (
    <>
      {isUserLoggedWithTheNeededRole && !postVideoUrl
        ? null : (
          <div className={`w-full flex ${isCentered ? 'justify-center' : 'justify-start'}`}>
            <button
              type="button"
              className="rounded-full bg-inherit  text-black font-americaMonoBold cursor-pointer  text-center my-4 px-16 uppercase py-4 "
              onClick={onOpenModal}
              style={backgroundColor}
            >
              {translateObj(watchNowButtonText, language)}
            </button>
            <Modal open={isModalOpen} onClose={onCloseModal} center styles={modalStyles} closeOnOverlayClick focusTrapped={false}>
              <ProOnDemandModalScreen {...userProps} language={language} translations={translations} closeModal={onCloseModal} postVideoUrl={postVideoUrl} postVideoRole={postVideoRole} sourceWeb={sourceWeb} image={image} />
            </Modal>
          </div>
        )
    }
    </>

  );
};

ProOnDemandAccessButton.propTypes = {
  match: PropTypes.shape().isRequired,
  watchNowButtonText: PropTypes.shape(),
  translations: PropTypes.shape(),
  postVideoRole: PropTypes.string.isRequired,
  postVideoUrl: PropTypes.string.isRequired,
  isCentered: PropTypes.bool,
  sourceWeb: PropTypes.string,
  image: PropTypes.shape({}),
};

ProOnDemandAccessButton.defaultProps = {
  image: null,
  isCentered: false,
  sourceWeb: '',
  watchNowButtonText: {
    en: 'go to video',
    es: 'ir al video',
    ca: 'anar al video',
    pt: 'go to video',
  },
  translations: {
    mainScreen: {
      onDemandMainText: {
        es: '',
        en: '',
        ca: '',
        pt: '',
      },
      watchNowButtonText: {
        es: 'ver ahora',
        en: 'watch now',
        ca: 'veure ara',
        pt: 'watch now',
      },
      shareButtonText2: {
        es: 'compartir',
        en: 'share',
        ca: 'compartir',
        pt: 'compartilhar',
      },
      priceText: {
        es: 'accede por',
        en: 'access paying',
        ca: 'accedeix per',
        pt: 'access paying',
      },
    },
    registerModal: {
      name: {
        es: 'Nombre',
        en: 'First name',
        ca: 'Nom',
        pt: 'nome',
      },
      lastname: {
        es: 'Apellidos',
        en: 'Last name',
        ca: 'Cognoms',
        pt: 'apelido',
      },
      selectDateMessage: {
        es: 'Selecciona la fecha',
        en: 'Select date',
        ca: 'Selecciona la data',
        pt: 'Seleccione data',
      },
      email: {
        es: 'Correo electrónico',
        en: 'Email',
        ca: 'Correu electrònic',
        pt: 'e-mail',
      },
      phone: {
        es: 'Teléfono',
        en: 'Phone',
        ca: 'Telefon',
        pt: 'telefone',
      },
      choose: {
        es: 'Eligir',
        en: 'Choose',
        ca: 'Escullir',
        pt: 'escolher',
      },
      country: {
        es: 'PAÍS',
        en: 'COUNTRY',
        ca: 'PAIS',
        pt: 'PAÍS',
      },
      countryError: {
        es: 'EL PAÍS NO EXISTE',
        en: 'COUNTRY DOES NOT EXIST',
        ca: 'EL PAIS NO EXISTEIX',
        pt: 'COUNTRY DOES NOT EXIST',
      },
      city: {
        es: 'ciudad',
        en: 'CITY',
        ca: 'ciutat',
        pt: 'cidade',
      },
      postalcode: {
        es: 'CÓDIGO POSTAL',
        en: 'POSTAL CODE',
        ca: 'CODI POSTAL',
        pt: 'código postal',
      },
      password: {
        es: 'CREAR CONTRASEÑA',
        en: 'CREATE PASSWORD',
        ca: 'CREA CONTRASENYA',
        pt: 'criar senha',
      },
      repeatPasswordError: {
        es: 'Por favor, vuelve a intentarlo las contraseñas no coinciden',
        en: 'Please double check, your passwords do not match',
        ca: 'Torna a intentar-ho, les contrasenyes no coincideixen',
        pt: 'volte a tentar, as palavras passe não coincidem ',
      },
      inValidName: {
        es: 'El nombre no es válido',
        en: 'This is not a valid name',
        ca: 'El nom no és vàlid',
        pt: 'O nome não é válido',
      },
      longName: {
        es: 'El nombre es demasiado largo',
        en: 'This name is too long',
        ca: 'El nom és massa llarg',
        pt: 'O nome é demasiado longo',
      },
      inValidEmail: {
        es: 'El correo electrónico no és válido',
        en: 'This is not a valid email',
        ca: 'El correu electrònic no és vàlid',
        pt: 'O e-mail não é válido',
      },
      longEmail: {
        es: 'El correo electrónico és demasiado largo',
        en: 'This email is too long',
        ca: 'El correu electrònic és massa llarg',
        pt: 'o e-mail é demasiado longo',
      },
      inValidPhone: {
        es: 'This is not a valid phone number',
        en: 'This is not a valid phone number',
        ca: 'This is not a valid phone number',
        pt: 'o número não é valido',
      },
      longPhone: {
        es: 'This phone number is too long',
        en: 'This phone number is too long',
        ca: 'This phone number is too long',
        pt: 'o número é demasiado longo',
      },
      invalidpostalcode: {
        es: 'El código postal es incorrecto',
        en: 'This is not a valid postal code',
        ca: 'El codi postal es incorrecte',
        pt: 'o código postal não é válido',
      },
      shortPassword: {
        es: 'Las contraseña es demasiado corta',
        en: 'This password is too short',
        ca: 'La contrasenya es massa curta',
        pt: 'a palavra passe é demasiado curta',
      },
      repeatPassword: {
        es: 'Vuelve a introducir tu password:',
        en: 'Re-enter your password:',
        ca: 'Torna a introduir el teu password:',
        pt: 'voltar a introduzir a palavra passe',
      },
      text: {
        es: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Roc Boronat 142, 3ª planta, 08018 de Barcelona.',
        en: 'We inform you que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Roc Boronat 142, 3ª planta, 08018 de Barcelona.',
        ca: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Roc Boronat 142, 3ª planta, 08018 de Barcelona.',
        pt: 'informamos que os dados pessoais introduzidos passam a fazer parte de um ficheiro, da responsabilidade da PRIMAVERA SOUND S.L., para que possa ser feita a gestão da inscrição. É possível exercer os direitos de acesso, rectificação, cancelamento ou oposição ao tratamentos dos dados através do email lopd@primaverasound.com ou na sede, situada em C/ Roc Boronat 142, 3ª planta, 08018 Barcelona.',
      },
      conditions: {
        acceptText: {
          es: 'Acepto las ',
          en: 'I accept the ',
          ca: 'Accepto les ',
          pt: 'I accept the ',
        },
        conditionsText: {
          es: 'CONDICIONES DE ACCESO',
          en: 'ACCESS CONDITIONS',
          ca: 'CONDICIONS D’ACCÉS',
          pt: 'ACCESS CONDITIONS',
        },
        conditionsLink: {
          es: 'https://www.primaverasound.com/es/user-register-conditions-for-primavera-sound-barcelona',
          en: 'https://www.primaverasound.com/en/user-register-conditions-for-primavera-sound-barcelona',
          ca: 'https://www.primaverasound.com/ca/user-register-conditions-for-primavera-sound-barcelona',
          pt: 'https://www.primaverasound.com/pt/user-register-conditions-for-primavera-sound-barcelona',
        },
        dataTreatmentText: {
          es: ' y el tratamiento de datos y la elaboración de perfiles según la ',
          en: ' and data processing and profiling according to the ',
          ca: " i el tractament de dades i l'elaboracio de perfils segons la ",
          pt: ' and data processing and profiling according to the ',
        },
        privacyPolicyText: {
          es: 'POLÍTICA DE PRIVACIDAD',
          en: 'PRIVACY POLICY',
          ca: 'POLÍTICA DE PRIVACITAT',
          pt: 'PRIVACY POLICY',
        },
        privacyPolicyUrl: {
          es: 'https://www.primaverasound.com/es/privacy-policy',
          en: 'https://www.primaverasound.com/en/privacy-policy',
          ca: 'https://www.primaverasound.com/ca/privacy-policy',
          pt: 'https://www.primaverasound.com/pt/privacy-policy',
        },
      },
      newsletter: {
        es: 'DESEO SUSCRIBIRME A LA NEWSLETTER DE CONFORMIDAD CON LA',
        en: 'I WANT TO SUBSCRIBE TO THE NEWSLETTER BASED ON YOUR',
        ca: 'Desitjo suscriure’m a la newsletter de conformitat amb la',
        pt: 'SUBSCREVER A NOSSA NEWSLETTER aceitando nossa',
      },
      newsletterLinkText: {
        es: 'Política de Privacidad',
        en: 'Privacy policy',
        ca: 'Política de Privacitat',
        pt: 'Política de Privacidade',
      },
      newsletterLinkUrl: {
        es: 'https://www.primaverasound.com/es/privacy-policy',
        en: 'https://www.primaverasound.com/en/privacy-policy',
        ca: 'https://www.primaverasound.com/ca/privacy-policy',
        pt: 'https://www.primaverasound.com/pt/privacy-policy',
      },
      submitButtonText: {
        es: 'ENVIAR',
        en: 'SUBMIT',
        ca: 'ENVIAR',
        pt: 'ENVIAR',
      },
      resetButtonText: {
        es: 'BORRAR',
        en: 'RESET',
        ca: 'BORRAR',
        pt: 'APAGAR',
      },
      submitMessage: {
        es: 'GRACIAS, SE HA CREADO TU CUENTA',
        en: 'THANKS, YOUR ACCOUNT HAS BEEN CREATED',
        ca: "GRÀCIES, S'HA CREAT EL TEU COMPTE",
        pt: 'OBRIGADO, A CONTA FOI ACTUALIZADA',
      },
      birthDateText: {
        es: 'fecha de nacimiento',
        en: 'date of birth',
        ca: 'data de naixement',
        pt: 'data de nascimento',
      },
      passwordInfo: {
        es: 'la contraseña debe contener al menos 8 caracteres',
        en: 'your password has to be at least 8 characters long',
        ca: 'La contrasenya ha de contenir un mínim de 8 caràcters',
        pt: 'your password has to be at least 8 characters long',
      },
    },
    loggedModal: {
      subscribeTitle: {
        es: 'Suscríbete por 2€ al Mes',
        en: 'Subscribe for 2€/Month',
        ca: 'Subscriu-te per 2€ al Mes',
        pt: 'Subscribe for 2€/Month',
      },
      subscribeDescription: {
        es: 'Esta suscripción da acceso a todo el catálogo de conciertos y directos en streaming.',
        en: 'This subscription provides access to all our concerts and live streaming feeds',
        ca: 'Aquesta subscripció dona accés a tot el catàleg de concerts i directes en streaming',
        pt: 'This subscription provides access to all our concerts and live streaming feeds',
      },
      subscribeConditions: {
        es: 'Podrás cancelar la renovación de tu suscripción en cualquier momento.',
        en: 'You will be able to cancel the renewal of your subscription at any time',
        ca: 'Podràs cancel·lar la renovació de la teva subscripció en qualsevol moment',
        pt: 'You will be able to cancel your subscription at any time',
      },
      subscribeConfigurePayment: {
        es: 'CONFIGURA TU MÉTODO DE PAGO',
        en: 'CONFIGURE YOUR PAYMENT METHOD',
        ca: 'CONFIGURE EL TEU MÈTODE DE PAGAMENT',
        pt: 'CONFIGURE YOUR PAYMENT METHOD',
      },
      subscribeAddCard: {
        es: 'AÑADIR TU TARJETA',
        en: 'ADD CREDIT CARD',
        ca: 'AFEGEIX LA TEVA TARJETA',
        pt: 'ADD CREDIT CARD',
      },
      payTitle: {
        es: 'Suscríbete por 2€ al Mes',
        en: 'Subscribe for 2€/Month',
        ca: 'Subscriu-te per 2€ al Mes',
        pt: 'Subscribe for 2€/Month',
      },
      payDescription: {
        es: 'Esta suscripción da acceso a todo el catálogo de conciertos y directos en streaming.',
        en: 'This subscription provides access to all our concerts and live streaming feeds',
        ca: 'Aquesta subscripció dona accés a tot el catàleg de concerts i directes en streaming',
        pt: 'This subscription provides access to all our concerts and live streaming feeds',
      },
      payConditions: {
        es: 'Podrás cancelar la renovación de tu suscripción en cualquier momento.',
        en: 'You will be able to cancel the renewal of your subscription at any time',
        ca: 'Podràs cancel·lar la renovació de la teva subscripció en qualsevol moment',
        pt: 'You will be able to cancel your subscription at any time',
      },
      payConfirmationText1: {
        es: 'conftext1',
        en: 'conftext1',
        ca: 'conftext1',
        pt: 'conftext1',
      },
      payConfirmationText2: {
        es: 'conftext2',
        en: 'conftext2',
        ca: 'conftext2',
        pt: 'conftext2',
      },
      trialTitle: {
        es: 'Free X months trial',
        en: 'Free X months trial',
        ca: 'Free X months trial',
        pt: 'Free X months trial',
      },
      trialDescription: {
        es: 'Esta suscripción da acceso a todo el catálogo de conciertos y directos en streaming.',
        en: 'This subscription provides access to all our concerts and live streaming feeds',
        ca: 'Aquesta subscripció dona accés a tot el catàleg de concerts i directes en streaming',
        pt: 'This subscription provides access to all our concerts and live streaming feeds',
      },
      trialConditions: {
        es: 'Una vez acabada la prueba te podrás de alta para seguir disfrutando de nuestro contenido',
        en: 'Once the trial finishes you will be able to subscribe in order to continue enjoying our content',
        ca: "Un cop finalitzada la prova et podràs donar d'alta per seguir gauding del nostre contingut",
        pt: 'Once the trial finishes you will be able to subscribe in order to continue enjoying our content',
      },
      trialConfirmationText1: {
        es: 'conftext1',
        en: 'conftext1',
        ca: 'conftext1',
        pt: 'conftext1',
      },
      trialConfirmationText2: {
        es: 'conftext2',
        en: 'conftext2',
        ca: 'conftext2',
        pt: 'conftext2',
      },
    },
    notLoggedModal: {
      userTitle: {
        es: 'CORREO ELECTRONICO',
        en: 'E-MAIL',
        ca: 'CORREU ELECTRÒNIC',
        pt: 'CORREIO ELETRÔNICO',
      },
      passwordTitle: {
        es: 'CONTRASEÑA',
        en: 'PASSWORD',
        ca: 'CONTRASENYA',
        pt: 'PASSWORD',
      },
      submitTitle: {
        es: 'ACCEDER',
        en: 'SIGN IN',
        ca: 'ACCEDIR',
        pt: 'ENTRAR',
      },
      forgotPassword: {
        es: 'He olvidado mi contraseña.',
        en: 'I forgot my password.',
        ca: 'He oblidat la meva contrasenya.',
        pt: 'Esqueci a minha senha.',
      },
      recoverPassword: {
        es: 'Recuperar contraseña',
        en: 'Recover password',
        ca: 'Recuperar contrasenya',
        pt: 'Recuperar senha',
      },
      userIncorrect: {
        es: 'Usuario o contraseña incorrectos',
        en: 'Incorrect user or password',
        ca: 'Usuari o contrasenya incorrectes',
        pt: 'Incorrect user or password',
      },
      createAccount: {
        es: 'CREAR CUENTA',
        en: 'CREATE ACCOUNT',
        ca: 'CREAR COMPTE',
        pt: 'CREATE ACCOUNT',
      },
      accessPrimavera: {
        es: 'Entra en Primavera Sound',
        en: 'Access Primavera Sound',
        ca: 'Accedeix al Primavera Sound',
        pt: 'Access Primavera Sound',
      },
      notAUser: {
        es: '¿No eres usuario de Primavera Sound?',
        en: 'Do not have a user yet?',
        ca: 'No tens usuari?',
        pt: 'Do not have a user yet?',
      },
      registerPrimavera: {
        es: 'Regístrate',
        en: 'Register',
        ca: 'Registrat',
        pt: 'Register',
      },
      emailAlreadyInUse: {
        es: 'Ya existe una cuenta con este mismo correo',
        en: 'There is already an account with this same email',
        ca: 'Ja existeix un compte amb aquest correu',
        pt: 'There is already an account with this same email',
      },
    },

  },
};
export default withRouter(ProOnDemandAccessButton);
