/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const ExpandLessBlack = props => <span {...props} className="font-icon icon-arrow-up font-black text-black text-xs ml-2" />;
const ExpandMoreBlack = props => <span {...props} className="font-icon icon-arrow-down font-black text-black text-xs ml-2" />;

const style = theme => ({
  menu: {
    marginTop: '3rem',
    zIndex: 100000,
    color: 'black',
  },
  button: {
    color: 'black',
    width: '100%',
    backgroundColor: 'inherit',
    border: 'solid 1px black',
    padding: '1rem',
    marginTop: '1rem',
    borderRadius: 999,
    fontSize: '100%',
    lineHeight: 1.15,
    fontFamily: 'GT-America-Mono-Regular',
    justifyContent: 'flex-start',
    textTransform: 'none',
  },
});

class FormSelectStyle extends Component {
  state = {
    anchorEl: null,
    choosen: '',
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  changeValue = (value) => {
    const { setValue } = this.props;
    this.setState({ choosen: value });
    setValue(value);
    this.handleClose();
  };

  validate = (value) => {
    const { setValue } = this.props;
    setValue(value);
  };

  render() {
    const {
      name, title, showError, getErrorMessage, isValid, options: optionsProps, getValue, classes, match, required, titleClassName, doNotChangeValidColor,
    } = this.props;

    const { anchorEl, choosen } = this.state;

    const errorMessage = getErrorMessage();

    const options = [];

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          return (
            <div className="px-3 mb-7.5 sm:w-full md:w-full w-1/3">
              <label className="flex flex-col" htmlFor={name}>
                <span className={titleClassName}>
                  {title}
                  {required && <span className="text-14 align-top text-red-light">*</span>}
                </span>
                <div>
                  <Button classes={{ root: classes.button }} aria-owns={anchorEl ? title : undefined} aria-haspopup="true" onClick={this.handleClick}>
                    <div className="flex justify-between w-full items-center">
                      {choosen || 'Choose'}
                      {anchorEl ? <ExpandLessBlack className="p-2" /> : <ExpandMoreBlack className="p-2" />}
                    </div>
                  </Button>
                  <Menu classes={{ paper: classes.menu }} id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
                    {optionsProps.map((option, i) => (
                      <MenuItem
                        key={option.value + i}
                        name={translateObj(option.title, localization.language)}
                        onClick={() => {
                          this.changeValue(option.value);
                        }}
                        style={{
                          paddingRight: '8rem',
                          paddingLeft: '2rem',
                          fontSize: '1rem',
                          color: 'black',
                        }}
                        onBlur={() => {
                          this.validate(option.value);
                        }}
                      >
                        {translateObj(option.title, localization.language)}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </label>
              <span className="flex flex-col text-red mt-4">{errorMessage}</span>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

FormSelectStyle.propTypes = {
  ...propTypes,
};

export default withFormsy(withStyles(style)(FormSelectStyle));
