import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Link from '../../util/link';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';
import Tag from './Buttons/Tag';
import TitleLineH2 from './Text/TitleLineH2';
import SubtitleLineH2 from './Text/SubtitleLineH2';
import LauncherItemSingle from './Launcher/LauncherItemSingle';

const SearchResult = ({
  showFeatured, match, slugName, postCategory, index, isListPage, listPageType, isAlgolia, query, rolesAllowed, postDescription: {
    title, image, tags, subtitle, description, url, date, authors, relatedMagazine,
  },
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const relink = url || `/${postCategory.length && `/${postCategory[0]}`}/${slugName}`;
      let urlWithSlash = url[0] === '/' || /^https?:\/\//.test(url) ? url : `/${url}`;
      const isEven = (index % 2) === 0;
      let dateOrMagazine = isAlgolia ? moment(date).format('DD.MM.YYYY') : moment(Number(date)).format('DD.MM.YYYY');

      if (relatedMagazine && relatedMagazine.title) {
        dateOrMagazine = relatedMagazine.title;
      }
      const isFeatured = (showFeatured && Number(index) === 1 && listPageType !== 'records' && listPageType !== 'hemeroteca');
      let width = `${listPageType === 'records' || listPageType === 'hemeroteca' ? 'w-1/2 tablet:w-1/3 laptop:w-1/4' : 'w-full tablet:w-1/2 laptop:w-1/3'}`;
      if (isFeatured) {
        width = 'w-full';
      }

      // NOTE: uncomment when the pdf highlight is resolved
      if (postCategory[0] === 'magazine-page') {
        urlWithSlash += `&search=${encodeURIComponent(query)}`;
      }

      return (
        <div id={slugName} key={slugName} className={`${width} ${isFeatured ? 'mb-12 tablet:mb-7 laptop:mb-18 desktop:mb-20' : 'px-2 laptop:px-3 mb-12 tablet:mb-8 laptop:mb-10'}`}>
          <LauncherItemSingle
            key={slugName}
            isFeatured={isFeatured}
            postCategory={postCategory}
            title={title}
            subtitle={subtitle}
            description={description}
            tags={tags}
            url={urlWithSlash}
            image={image}
            authors={authors}
            date={dateOrMagazine}
            isListPage={isListPage}
            listPageType={listPageType}
            rolesAllowed={rolesAllowed}
            slugName={slugName}
          />
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

SearchResult.propTypes = {
  match: PropTypes.shape().isRequired,
  slugName: PropTypes.string,
  postCategory: PropTypes.arrayOf(PropTypes.string),
  postDescription: PropTypes.shape(),
  isListPage: PropTypes.bool,
  isAlgolia: PropTypes.bool,
  listPageType: PropTypes.string,
  index: PropTypes.number,
  rolesAllowed: PropTypes.arrayOf(PropTypes.string),
};

SearchResult.defaultProps = {
  slugName: '',
  postCategory: [],
  postDescription: {},
  isListPage: false,
  isAlgolia: false,
  listPageType: null,
  index: 0,
  rolesAllowed: [],
};

export default withRouter(SearchResult);
