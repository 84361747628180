import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const GET_PAYMENT_FOR_PRODUCTGROUP_QUERY = gql`
  query Get($productGroup: String!, $fetchDate: String) {
    getPaymentForProductGroup(productGroup: $productGroup, fetchDate: $fetchDate) {
      provider
      user
      productSlugName
      subscriptionRenewalProductSlugName
      contractType
      productGroup
      subscriptionLastDate
      renewSubscription
      provider
      payed
      datePayment
    }
  }
`;

const getPaymentForProductGroup = async (productGroup) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_PAYMENT_FOR_PRODUCTGROUP_QUERY,
        variables: { productGroup, fetchDate: new Date().getTime().toString() },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    if (result) {
      const {
        data: { getPaymentForProductGroup: getPaymentForProductGroupData },
      } = result;

      if (getPaymentForProductGroupData) {
        return getPaymentForProductGroupData;
      }
    }
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getPaymentForProductGroup Error: ${error}`);
    return null;
  }
};

export default getPaymentForProductGroup;
