import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import Formsy from 'formsy-react';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

import postContactForm from '../../api/graphql/mutations/postContactForm';
import FormInput from './Form/FormInput';
import FormCheckbox from './Form/FormCheckbox';
import FormTextarea from './Form/FormTextarea';
import translateObj from '../../util/translateObj';

const recaptchaRef = React.createRef();
const contactFields = {
  title: {
    es: 'DATOS DE CONTACTO',
    en: 'CONTACT FORM',
    ca: 'DADES DE CONTACTE',
    pt: 'DADOS DE CONTACTO',
  },
  fields: {
    firstName: {
      es: 'NOMBRE',
      en: 'FIRST NAME',
      ca: 'NOM',
      pt: 'NOME',
    },
    lastName: {
      es: 'APELLIDOS',
      en: 'LAST NAME',
      ca: 'COGNOMS',
      pt: 'SOBRENOMES',
    },
    email: {
      es: 'CORREO ELECTRONICO',
      en: 'EMAIL',
      ca: 'CORREU ELECTRÒNIC',
      pt: 'CORREIO ELECTRONICO',
    },
    phone: {
      es: 'TELEFONO',
      en: 'PHONE',
      ca: 'TELEFONO',
      pt: 'TELEFONE',
    },
    choose: {
      es: 'Eligir',
      en: 'Choose',
      ca: 'Eligir',
      pt: 'Escolha',
    },
    country: {
      es: 'PAÍS',
      en: 'COUNTRY',
      ca: 'PAÍS',
      pt: 'PAÍS',
    },
    city: {
      es: 'CIUDAD',
      en: 'CITY',
      ca: 'CIUTAT',
      pt: 'CIDADE',
    },
    subject: {
      es: 'ASUNTO',
      en: 'SUBJECT',
      ca: 'ASSUMPTE',
      pt: 'ASSUNTO',
    },
    message: {
      es: 'MENSAJE',
      en: 'MESSAGE',
      ca: 'MISSATGE',
      pt: 'MENSAGEM',
    },
  },
  validation: {
    inValidName: {
      es: 'No es un nombre válido',
      en: 'This is not a valid name',
      ca: 'No és un nom vàlid',
      pt: 'Não é nome válido',
    },
    longName: {
      es: 'El nombre es demasiado largo',
      en: 'This name is too long',
      ca: 'Aquest nom és molt llarg',
      pt: 'O nome é muito longo',
    },
    inValidEmail: {
      es: 'No es un correo electrónico válido',
      en: 'This is not a valid email',
      ca: 'Aquesta no és una adreça de correu electrònic vàlida',
      pt: 'O correio electrónico não é válido',
    },
    longEmail: {
      es: 'Este correo electrónico es demasiado largo.',
      en: 'This email is too long',
      ca: 'Aquest correu electrònic és molt llarg',
      pt: 'O correio electrónico é muito longo',
    },
    inValidPhone: {
      es: 'No es un número de teléfono válido',
      en: 'This is not a valid phone number',
      ca: 'Aquest no és un número de telèfon vàlid',
      pt: 'Não é número de telefone válido',
    },
    longPhone: {
      es: 'El número de teléfono es demasiado largo',
      en: 'This phone number is too long',
      ca: 'Aquest número de telèfon és molt llarg',
      pt: 'O número de telefone é muito longo',
    },
    longSubject: {
      es: 'El asunto es demasiado largo',
      en: 'The subject is too long',
      ca: "L'assumpte és molt llarg",
      pt: 'O assunto é muito longo',
    },
    longMessage: {
      es: 'El mensaje es demasiado largo',
      en: 'The message is too long',
      ca: 'El missatge és molt llarg',
      pt: 'A tua mensagem é muito longa',
    },
  },
  text: {
    es: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Roc Boronat 142, 3ª, 08018 de Barcelona.',
    en: 'We inform you that the personal data you provide will become part of a file under the responsibility of PRIMAVERA SOUND S.L. to manage your request. You can exercise the rights of access, rectification, cancellation or opposition to the processing of your data in the e-mail indicated lopd@primaverasound.com or at the address located at C / Roc Boronat 142, 3rd, 08018 of Barcelona.',
    ca: "T'informem que les dades personals que faciliteu passaran a formar part d'un fitxer responsabilitat de PRIMAVERA SOUND S.L. per gestionar la teva petició. Pots exercir els drets d'accés, rectificació, cancel·lació o oposició al tractament de les teves dades en l'e-mail assenyalat lopd@primaverasound.com o al domicili situat en C / Roc Boronat 142, 3a, 08018 de Barcelona.",
    pt: 'Informamos que os dados pessoais que forneças passarão a formar parte de um arquivo de responsabilidade de PRIMAVERA SOUND S.L. para a gestão da tua requisição. Podes exercer os direitos de acesso, retificação, cancelamento ou oposição ao tratamento dos teus dados por meio do correio electrónico lopd@primaverasound.com ou no domicilio situado a C/ Roc Boronat 142, 3ª, 08018 - Barcelona.',
  },
  conditions: {
    es: 'He leído y acepto las condiciones y la Ley de tratamiento de datos.',
    en: 'I have read and accept the conditions and the data protection law.',
    ca: 'Va llegir i acceptar les condicions i la llei de tractament de dades.',
    pt: 'Li e aceito as condições e a lei de tratamento de dados',
  },
  submitButtonText: {
    es: 'ENVIAR',
    en: 'SUBMIT',
    ca: 'ENVIAR',
    pt: 'enviar',
  },
  resetButtonText: {
    es: 'BORRAR',
    en: 'RESET',
    ca: 'BORRAR',
    pt: 'ELIMINAR',
  },
  submitMessage: {
    es: 'Gracias! Tu mensaje se ha enviado',
    en: 'Gracias! Tu mensaje se ha enviado',
    ca: 'Gracias! Tu mensaje se ha enviado',
    pt: 'Gracias! Tu mensaje se ha enviado',
  },
};
class ContactFormReusable extends Component {
  state = {
    canSubmit: false,
    didSubmit: false,
    captchaValue: false,
    events: '',
  };

  componentDidMount = () => {
    const { events, match: { params: { lang } } } = this.props;
    if (events === 'press-porto') {
      if (lang && lang === 'pt') {
        this.setState({ events: 'press-porto-national' });
      } else {
        this.setState({ events: 'press-porto-international' });
      }
    } else {
      this.setState({ events });
    }
  }

  onHandleCollapsibleMenuSelect = (value) => {
    this.setState({ events: value });
  }

  submitForm = (formData) => {
    const { events } = this.state;
    this.setState({ canSubmit: false });
    const data = { ...formData, events };

    postContactForm({ ...data }).then((response) => {
      if (response) {
        if (response.isValid) {
          this.onReset();
          this.setState({
            didSubmit: true,
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  };

  onChange = (captchaValue) => {
    this.setState({ captchaValue: true });
  }

  onReset = () => {
    this.form.reset();
    this.firstName.setValue('');
    this.lastName.setValue('');
    this.email.setValue('');
    this.subject.setValue('');
    this.message.setValue('');
    this.conditions.setValue(false);
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  render() {
    const {
      match,
    } = this.props;
    const {
      title,
      fields,
      validation,
      text,
      conditions,
      submitButtonText,
      submitMessage,
    } = contactFields;

    const { canSubmit, didSubmit, captchaValue } = this.state;
    return (
      <RoutesContext.Consumer>
        {
          ({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            return (
              <Fragment>
                <div className="w-full font-americaMonoRegular">
                  <div className="my-8">{translateObj(title, localization.language)}</div>
                  <Formsy ref={(c) => { this.form = c; return this.form; }} onValidSubmit={this.submitForm} onValid={this.enableButton} onInvalid={this.disableButton} className="flex sm:flex-col flex-wrap -mx-3">
                    <FormInput
                      ref={(c) => { this.firstName = c; return this.firstName; }}
                      name="firstName"
                      title={translateObj(fields.firstName, localization.language)}
                      validations={{ isSpecialWords: true, maxLength: 50 }}
                      validationErrors={{ isSpecialWords: translateObj(validation.inValidName, localization.language), maxLength: translateObj(validation.longName, localization.language) }}
                      required
                    />
                    <FormInput
                      ref={(c) => { this.lastName = c; return this.lastName; }}
                      name="lastName"
                      title={translateObj(fields.lastName, localization.language)}
                      validations={{ isSpecialWords: true, maxLength: 50 }}
                      validationErrors={{ isSpecialWords: translateObj(validation.inValidName, localization.language), maxLength: translateObj(validation.longName, localization.language) }}
                      required
                    />
                    <FormInput
                      ref={(c) => { this.email = c; return this.email; }}
                      name="email"
                      title={translateObj(fields.email, localization.language)}
                      validations={{ isEmail: true, maxLength: 50 }}
                      validationErrors={{ isEmail: translateObj(validation.inValidEmail, localization.language), maxLength: translateObj(validation.longEmail, localization.language) }}
                      required
                    />
                    <FormInput
                      ref={(c) => { this.subject = c; return this.subject; }}
                      name="subject"
                      title={translateObj(fields.subject, localization.language)}
                      validations={{ maxLength: 250 }}
                      validationError={validation.longSubject[localization.language]}
                      required
                    />
                    <FormTextarea
                      ref={(c) => { this.message = c; return this.message; }}
                      name="message"
                      title={translateObj(fields.message, localization.language)}
                      validations={{ maxLength: 500 }}
                      validationError={validation.longMessage[localization.language]}
                      required
                    />
                    { !didSubmit && (
                    <>
                      <div className="flex justify-end mt-8 sm:mt-0">
                        <div className="w-1/2 sm:w-full md:w-full sm:px-3 md:pl-3 font-tiemposRegular text-12">
                          <p style={{ marginTop: 0 }}>
                            {translateObj(text, localization.language)}
                          </p>
                          <div>
                            <FormCheckbox
                              ref={(c) => { this.conditions = c; return this.conditions; }}
                              name="conditions"
                              title={translateObj(conditions, localization.language)}
                              validations="isTrue"
                              validationError=""
                            />
                          </div>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            onChange={this.onChange}
                            sitekey="6LeVsbsUAAAAAI8Crd2wQ8eNINyoLzAT0uM01KKe"
                          />
                          <div className="hidden sm:block sm:w-full mt-12">
                            {/* <button type="button" onClick={this.onReset} className="bg-black h-12 text-white rounded-full mx-4 py-2 mb-4">{resetButtonText[localization.language]}</button> */}
                            <button type="submit" disabled={!canSubmit && captchaValue} className={`${canSubmit && captchaValue ? 'bg-black' : 'bg-grey'} h-12 text-white rounded-full px-3 w-full py-2 font-americaMonoBold`}>{translateObj(submitButtonText, localization.language)}</button>
                          </div>
                        </div>
                        <div className="flex flex-col w-1/4 sm:hidden">
                          {/* <button type="button" onClick={this.onReset} className="bg-black h-12 text-white rounded-full mx-4 py-2 mb-4">{resetButtonText[localization.language]}</button> */}
                          <button type="submit" disabled={!canSubmit && captchaValue} className={`${canSubmit && captchaValue ? 'bg-black' : 'bg-grey'} h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold`}>{translateObj(submitButtonText, localization.language)}</button>
                        </div>
                      </div>
                    </>
                    )}
                    { didSubmit && (
                    <div className="font-americaMonoRegular px-3">
                      <h3 className="text-green text-sm">{translateObj(submitMessage, localization.language)}</h3>
                    </div>
                    ) }
                    <div className="m-4 flex items-center ml-auto" />
                  </Formsy>
                </div>
              </Fragment>
            );
          }
        }
      </RoutesContext.Consumer>
    );
  }
}

ContactFormReusable.propTypes = {
  match: PropTypes.shape().isRequired,
  events: PropTypes.string,
};

ContactFormReusable.defaultProps = {
  events: '',
};

export default withRouter(ContactFormReusable);
