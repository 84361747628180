import React, { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import userPasswordRecoverUserSendsEmail from '../../../../../api/graphql/mutations/userPasswordRecoverUserSendsEmail';


import FormInput from '../../../Form/FormInput';
import SimpleButton from '../../../Buttons/SimpleButton';

import {
  reducer,
  initialState,
} from '../ProfileFormUtils';

const PasswordFormsy = ({
  sourceWeb,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isPasswordRecoveryEmailSubmited, setIsPasswordRecoveryEmailSubmited] = useState(false);
  const [error, setError] = useState('');
  const { canSubmit } = state;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isPasswordRecoveryEmailSubmited) setIsPasswordRecoveryEmailSubmited(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isPasswordRecoveryEmailSubmited]);

  const enableButton = () => {
    dispatch({ type: 'canSubmit' });
  };

  const disableButton = () => {
    dispatch({ type: 'cannotSubmit' });
  };

  const handleButtonTheme = () => {
    if (canSubmit) return { base: 'red', hover: 'red' };
    return { base: 'disabled' };
  };

  const sendRecoveryEmail = (email) => {
    if (email) {
      userPasswordRecoverUserSendsEmail({ email, sourceWeb }).then((res) => {
        if (res) {
          if (res.isValid) {
            if (error !== '') setError('');
            setIsPasswordRecoveryEmailSubmited(true);
          } else if (res.error) {
            switch (res.error) {
              case 'noUserFound':
                setError('El usuario no existe');
                break;
              case 'userLoggedWithSocialNetwork':
                setError('El usuario ha iniciado sesión mediante redes sociales');
                break;
              case 'emailNotSent':
                setError('El email no se ha enviado');
                break;
              case 'userCouldNotBeUpdatedinDB':
                console.log('Please contact your administrator');
                break;

              default:
                console.log('Please contact your administrator');
                break;
            }
          } else {
            console.log('no error was received and res is not valid, please contact your administrator');
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - recover password failed to submit!`);
        }
      });
    } else {
      setError('Email incorrecto');
    }
  };

  return (
    <div>
      <Formsy
        onValid={enableButton}
        onInvalid={disableButton}
        onValidSubmit={formData => sendRecoveryEmail(formData.email)}
        className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5"
      >
        <div className="col-span-4 tablet:col-span-7 laptop:col-span-4 tablet:col-start-2 laptop:col-start-2">
          <FormInput
            name="email"
            title="Cambiar contraseña"
            validations={{ isEmail: true, maxLength: 50 }}
            validationErrors={{
              isEmail: 'No es un email válido',
              maxLength: 'El email es demasiado largo',
            }}
            required
            titleClassName="flex uppercase"
            className="w-full"
            doNotChangeValidColor
          />
          {error && (<div className="text-red mt-4 font-stratos">{error}</div>)}
          {!isPasswordRecoveryEmailSubmited
            ? <div className="text-grey mt-4 font-stratos">Te enviaremos un email con un enlace para que puedas restablecer tu contraseña.</div>
            : (
              <>
                <div className="text-black mt-4 font-stratos">Te hemos enviado un email para restablecer tu contraseña.</div>
                <div className="text-black mt-2 font-stratos font-semibold">Revisa tu bandeja de entrada.</div>
              </>
            )
          }
        </div>
        <div className="col-span-3 laptop:col-span-2 col-end-5 tablet:col-end-11 laptop:col-end-9 row-start-3 cursor-pointer flex flex-col items-stretch justify-end">
          <button
            type="submit"
            className="w-full"
            disabled={!canSubmit}
          >
            <SimpleButton
              isDisabled
              isLowercase
              isFullWidth
              theme={handleButtonTheme()}
              text={{
                en: 'Enviar email',
                es: 'Enviar email',
              }}
            />
          </button>
        </div>
      </Formsy>
    </div>
  );
};


PasswordFormsy.propTypes = {
  sourceWeb: PropTypes.string,
};

PasswordFormsy.defaultProps = {
  sourceWeb: '',
};

export default PasswordFormsy;
