import React from 'react';
import { PropTypes } from 'prop-types';
// import Container from '../Container';
import parse from 'html-react-parser';
import translateObj from '../../util/translateObj';
import { useRoutes } from '../../context/routesContext';

const BackCatalogue = ({
  title,
  elements,
  mainSubTitle,
}) => {
  const { language } = useRoutes();
  return (
    <div
      className="px-5 tablet:px-0 text-black grid col-span-6 tablet:col-span-12 laptop:col-span-10 laptop:col-start-2 grid-cols-6 tablet:grid-cols-12 laptop:grid-cols-10 gap-x-4 laptop:pr-8 font-stratos my-10 tablet:my-16 laptop:my-18 laptop:pb-2 desktop:pb-8"
      style={{ backgroundColor: 'rgb(236, 236, 231)' }}
    >
      <div className="col-span-6 tablet:col-span-10 tablet:col-start-2 laptop:col-start-1">
        <h3 className="my-5 tablet:ml-0 tablet:my-9 laptop:ml-10 laptop:my-10 desktop:mb-12 font-bold text-24 tablet:text-30 desktop:text-36">{title.en}</h3>
      </div>
      {mainSubTitle
            && (
            <div className="col-span-6 tablet:col-span-10 tablet:col-start-2 laptop:col-start-1">
              <h4 className="mb-5 mx-5 desktop:-mt-5 desktop:mb-5 desktop:mx-10
              laptop:mb-5 laptop:mx-10 tablet:-mt-5 tablet:mb-5 tablet:mx-0
              text-14 laptop:text-15 desktop:text-18 hasLinksAndBolds"
              >
                {parse(translateObj(mainSubTitle, language))}
              </h4>
            </div>
            )}
      {elements && elements.map((element, index) => {
        let imageSrc = '';
        if (element.image.hasOwnProperty('en') && element.image.en !== '') {
          imageSrc = element.image.en;
        }
        return (
          <div key={index} className="pt-5 tablet:pt-0 col-span-6 tablet:col-start-2 tablet:col-span-10 laptop:col-start-3 laptop:col-span-8 grid grid-cols-6 tablet:grid-cols-10 laptop:grid-cols-8 gap-x-4 border-t-3 border-black pb-3">
            <div className="col-span-3 tablet:col-span-4 laptop:col-span-3 tablet:py-5 tablet:pr-3 laptop:p-5">
              {imageSrc ? (
                <img
                  className="w-full block"
                  src={imageSrc}
                  alt=""
                />
              ) : (
                <div className="w-full bg-grey" style={{ paddingBottom: '100%' }} />
              )}
            </div>
            <div className="col-span-6 tablet:col-span-6 laptop:col-span-5 py-5 font-stratos">
              {element.title.hasOwnProperty('en') && (
                <div className="block text-black font-bold text-18 tablet:text-20 hasLinksAndBolds">
                  {parse(element.title.en)}
                </div>
              )}
              {element.subtitle.hasOwnProperty('en') && (
                <div className="block text-black font-normal text-13 mt-2 mb-8 hasLinksAndBolds">
                  {parse(element.subtitle.en)}
                </div>
              )}
              {element.text.hasOwnProperty('en') && (
                <div className="block text-black text-base font-semilight leading-26 tablet:text-18 innerTextPadding hasLinksAndBolds">
                  {parse(element.text.en)}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BackCatalogue;

BackCatalogue.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.shape({
    en: PropTypes.string,
  }),
  mainSubTitle: PropTypes.shape({
    en: PropTypes.string,
  }),
  elements: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.shape({
      en: PropTypes.string,
    }),
    subtitle: PropTypes.shape({
      en: PropTypes.string,
    }),
    image: PropTypes.shape({
      en: PropTypes.string,
    }),
    text: PropTypes.shape({
      en: PropTypes.string,
    }),
    itemId: PropTypes.number,
  })),
};

BackCatalogue.defaultProps = {
  title: {
    en: '',
  },
  mainSubTitle: {
    en: '',
  },
  elements: [],
};
