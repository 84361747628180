import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import RoutesContext from '../../context/routesContext';

const DateBlock = ({ parentComponent, isArticleHeader }) => {
  const [postDescription, setPostDescription] = useState(null);
  const { currentPostDescription = false } = useContext(RoutesContext);

  const getContainerStyles = () => {
    let styles = 'w-full';
    if (isArticleHeader) {
      styles = 'laptop:float-left w-full tablet:w-auto';
    }

    return styles;
  };

  const getWrapperStyles = () => {
    let styles = '';
    switch (parentComponent) {
      case 'NewsPostPage':
        styles = 'border-t border-black border-solid w-full mb-7 tablet:border-none tablet:w-auto tablet:mb-0';
        break;
      default:
        styles = 'border-t border-black border-solid w-full mb-7 tablet:mb-9 desktop:mb-16';
        break;
    }
    return styles;
  };

  const getTextStyles = () => {
    let styles = '';
    switch (parentComponent) {
      case 'NewsPostPage':
        styles = 'font-light tablet:font-normal text-13 tablet:text-14 font-stratos text-black tablet:text-grey pt-1 tablet:pt-0';
        break;
      default:
        styles = 'text-black font-stratos text-13 laptop:text-14 desktop:text-base pt-1 laptop:pt-4 desktop:pt-3 font-light';
        break;
    }
    return styles;
  };

  const getFormattedDate = () => {
    try {
      // Received as a Date string
      return moment(postDescription.date).format('DD. MM. YYYY');
    } catch (err) {
      // Received as ms from OG Date
      return moment(Number(postDescription.date)).format('DD. MM. YYYY');
    }
  };
  useEffect(() => {
    if (currentPostDescription) {
      setPostDescription(currentPostDescription);
    }
  }, [currentPostDescription]);

  return (
    <div className={getContainerStyles()}>
      <div className={getWrapperStyles()}>
        {postDescription && postDescription.date
          && (
            <p className={getTextStyles()}>
              {getFormattedDate()}
            </p>
          )}
      </div>
    </div>
  );
};

DateBlock.propTypes = {
  parentComponent: PropTypes.string,
  isArticleHeader: PropTypes.bool,
};

DateBlock.defaultProps = {
  parentComponent: null,
  isArticleHeader: false,
};

export default DateBlock;
