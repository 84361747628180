import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const USER_WEB_LOGIN = gql`
  mutation Post($productSlugName: String!, $donationAmount: Int, $customerId: String, $paymentMethodId: String, $couponCode: String, $presentData: String) {
    purchaseProduct(
      productSlugName: $productSlugName
      donationAmount: $donationAmount
      customerId: $customerId
      paymentMethodId: $paymentMethodId
      couponCode: $couponCode
      presentData: $presentData
      ){
        result
        error
    }
}`;

const purchaseProduct = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: USER_WEB_LOGIN,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { purchaseProduct: validatedFormData } } = result;
    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: purchaseProduct Error: ${error}`);
    return null;
  }
};

export default purchaseProduct;
