import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withRouter, Redirect } from 'react-router-dom';

import RoutesContext from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';
import getLocalization from '../../../util/localization';

const hourInPxColumn = 80;
const hourInEmptyPxColumn = 24;
const minuteInPxColumn = hourInPxColumn / 60;
const minuteInEmptyPxColumn = hourInEmptyPxColumn / 60;

const hourInPxRow = 220;
const hourInEmptyPxRow = 24;
const minuteInPxRow = hourInPxRow / 60;
const minuteInEmptyPxRow = hourInEmptyPxRow / 60;

// const { isFavoritesActive } = process.env;
const isFavoritesActive = true;
const timezone = 'Europe/Madrid';

class ArtistBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfLines: 1,
    };
    this.main = React.createRef();
    this.title = React.createRef();
  }

  componentDidMount() {
    this.calculateNumberOfLines();
  }

  calculateNumberOfLines() {
    const el = this.title.current;
    const lineHeight = window.getComputedStyle(el, null).getPropertyValue('line-height');
    const numberOfLines = el.offsetHeight / Number(lineHeight, 10);
    this.setState({ numberOfLines });
  }

  handleArtistBoxClick(language) {
    const {
      artist, showArtistCallback, hasConferences, eventSlug, history,
    } = this.props;

    if (hasConferences) {
      history.push(`/${language}/${eventSlug || 'conference'}${artist.postUri}`);
    } else {
      showArtistCallback(artist, this.main.current, 'click');
    }
  }

  render() {
    const {
      numberOfLines,
    } = this.state;

    const {
      match,
      layout,
      artist,
      color,
      showArtistCallback,
      startAndEndHours,
      day,
      hasConferences,
    } = this.props;

    let previousEmptyHours = 0;
    const startingTime = moment(artist.dateTimeStartReal / 1000, 'X').tz(timezone).format('YYYY-MM-DD HH:00');
    const hoursKeys = Object.keys(startAndEndHours[day].hours);
    for (let i = 0; i < hoursKeys.length; i++) {
      const hour = hoursKeys[i];
      if (hour < startingTime) {
        if (startAndEndHours[day].hours[hour] === false) {
          previousEmptyHours += 1;
        }
      } else {
        break;
      }
    }

    const emptyHourMinutesFromStartDate = previousEmptyHours * 60;
    const fullHourMinutesFromStartDate = artist.minutesFromStartDate - emptyHourMinutesFromStartDate;

    let style;
    if (layout === 'columns') {
      const top = emptyHourMinutesFromStartDate * minuteInEmptyPxColumn + fullHourMinutesFromStartDate * minuteInPxColumn;
      style = {
        top,
        height: artist.duration * minuteInPxColumn + 1,
        left: -1,
        width: hourInPxRow + 2,
      };
    } else if (layout === 'rows') {
      const left = emptyHourMinutesFromStartDate * minuteInEmptyPxRow + fullHourMinutesFromStartDate * minuteInPxRow - 1;
      style = {
        left,
        width: artist.duration * minuteInPxRow + 1,
        top: -1,
        height: hourInPxColumn + 1,
      };
    }

    const extraClassName = [];
    if (layout === 'rows') {
      extraClassName.push('flex justify-end p-2');
    } else {
      if (artist.duration > 40) {
        extraClassName.push('p-2');
      } else {
        extraClassName.push('px-2 flex justify-center');
      }
    }

    let artistFont = 'text-sm';
    if (artist.duration <= 40 || numberOfLines > 1) artistFont = 'text-xs';
    if (artist.duration <= 40 && numberOfLines > 1) artistFont = 'text-xxs';


    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div
              ref={this.main}
              key={artist.artistSetSlugName}
              className={`${extraClassName.join(' ')} ${artist.favourite === 1 ? 'pr-4' : 'pr-2'} flex flex-col border-t-1 border-solid border-transparent absolute overflow-hidden cursor-pointer border-grayE2DDDB border-1 ${artist.favourite ? `bg-${color}` : 'bg-white'} ${layout === 'columns' ? 'w-lineupHour' : 'h-lineupHour'}`}
              style={style}
              onMouseMove={() => { !hasConferences && showArtistCallback(artist, this.main.current, 'mouseover'); }}
              onClick={() => { this.handleArtistBoxClick(localization.language); }}
            >
              {
                artist.favourite === 1 && isFavoritesActive && (
                  <div className="absolute pin-r pin-t mr-2 mt-2">
                    <span className="font-icon text-black icon-star-solid text-lg" />
                  </div>
                )}

              <div>
                {(layout === 'rows' || (artist.duration >= 40 || numberOfLines === 1))
                  && (
                    <span className="block font-americaMonoRegular text-xxs">
                      {artist.scheduleHuman}
                    </span>
                  )}
                <span ref={this.title} className={`block font-tiemposBold ${artistFont}`}>
                  {artist.artistName || translateObj(artist.artistSetReadableName, localization.language) || artist.artistSetReadableName.es || ''}
                </span>
              </div>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

ArtistBox.propTypes = {
  layout: PropTypes.string.isRequired,
  artist: PropTypes.shape().isRequired,
  eventSlug: PropTypes.shape().isRequired,
  showArtistCallback: PropTypes.func.isRequired,
  color: PropTypes.string,
  startAndEndHours: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  day: PropTypes.string.isRequired,
  hasConferences: PropTypes.bool,
};

ArtistBox.defaultProps = {
  color: 'radio',
  hasConferences: false,
};

export default withRouter(ArtistBox);
