import React from 'react';
import PropTypes from 'prop-types';
import EmptySlot from './EmptySlot';
import ArtistBox from './ArtistBox';

const VenueRow = ({
  day,
  artists,
  layout,
  startAndEndHours,
  showArtistCallback,
  hideArtistCallback,
  hasConferences,
  eventSlug,
}) => (
  <div className="border-r-1 border-b-1 border-solid border-white h-lineupHour">
    <div className="inline-flex relative">
      <div className="inline-flex">
        {startAndEndHours && (
          Object.keys(startAndEndHours[day].hours).map((hour, index) => (
            <EmptySlot index={index} hasEvent={startAndEndHours[day].hours[hour]} layout={layout} key={hour} />
          )))}
      </div>
      <div className="absolute pin-t pin-x z-1 h-lineupHour">
        {artists.map(artist => (
          <ArtistBox
            eventSlug={eventSlug}
            day={day}
            layout={layout}
            key={artist.artistSetSlugName}
            artist={artist}
            showArtistCallback={showArtistCallback}
            hideArtistCallback={hideArtistCallback}
            startAndEndHours={startAndEndHours}
            hasConferences={hasConferences}
          />
        ))}
      </div>
    </div>
  </div>
);

VenueRow.propTypes = {
  day: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  startAndEndHours: PropTypes.shape().isRequired,
  showArtistCallback: PropTypes.func.isRequired,
  hideArtistCallback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  artists: PropTypes.array.isRequired,
  hasConferences: PropTypes.bool,

};

VenueRow.defaultProps = {
  hasConferences: false,
};

export default VenueRow;
