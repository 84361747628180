import React from 'react';
import PropTypes from 'prop-types';

import PopupWindow from './PopupWindow';
import userLinkSpotify from '../../../../api/graphql/mutations/userLinkSpotify';
import userUnLinkSpotify from '../../../../api/graphql/mutations/userUnLinkSpotify';
import userSocialLogin from '../../../../api/graphql/mutations/userSocialLogin';
import { useUser } from '../../../../context/userProfileContext';

const SPOTIFY_CLIENT_ID = '9b99d801cde4434f856a06cf380011a4';

const getDomainName = () => window?.location.origin;

const btnDefaultStyles = 'w-auto h-auto rounded-full bg-inherit bg-spotify text-white text-12 font-stratos cursor-pointer text-center flex items-center justify-center uppercase  xs:text-xxs md:text-xs sm:text-xxs';
const inside = 'font-icon icon-spotify-alt text-white text-28 h-13 w-13 flex flex-row items-center justify-center';

const parseSpotifyUserDetails = (userDetails) => {
  if (!userDetails) return {};

  const [name, surname] = userDetails.display_name?.split(' ');

  return {
    profileObj: {
      spotifyToken: userDetails.spotifyToken,
      email: userDetails.email,
      givenName: name,
      familyName: surname,
      spotifyId: userDetails.userID,
      isSocialLogin: 'spotify',
    },
  };
};

export const getSpotifyResponse = async (code, redirectUri) => {
  const res = await userSocialLogin(code, 'spotify', redirectUri);

  if (!res) return {};

  // NOTE: if there is no token the user needs to register.
  const { userDetails } = res;
  return { ...res, userPayload: parseSpotifyUserDetails(userDetails) };
};

const Button = ({ className, icon, onClick }) => (
  <button className={className} onClick={onClick} type="button">
    <div className={`${icon && 'font-icon icon-spotify-alt'} ${inside}`} />
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: '',
  icon: true,
  onClick: null,
};

const SpotifyLogin = ({ buttonText, className, icon, isLinkAccountButton, onFailure, onSuccess, scope }) => {
  const { isLinkedWithSpotify, userEmail, setNewAuthToken } = useUser();

  const redirectUri = getDomainName();

  const handleLinkSpotify = (code) => {
    userLinkSpotify(code, userEmail, redirectUri)
      .then((res) => {
        onSuccess(res);
        setNewAuthToken(res.token);
      })
      .catch(onFailure);
  };

  const handleUnlinkSpotify = () => {
    userUnLinkSpotify(userEmail, 'spotify', redirectUri)
      .then((res) => {
        onSuccess(res);
        setNewAuthToken(res.token);
      })
      .catch(onFailure);
  };

  const handleSignWithSpotify = async (code) => {
    const res = await getSpotifyResponse(code, redirectUri);
    const { error, token, userPayload } = res;

    if (token) {
      setNewAuthToken(token);
      return onSuccess(res);
    }

    return onSuccess({ error, isValid: false, needToRegister: true, userPayload });
  };

  const handleAuth = isLinkAccountButton ? handleLinkSpotify : handleSignWithSpotify;

  const search = {
    client_id: SPOTIFY_CLIENT_ID,
    response_type: 'code',
    redirect_uri: getDomainName(),
    scope,
  };

  const params = new URLSearchParams(search).toString();

  if (isLinkedWithSpotify && isLinkAccountButton) return <Button {...{ className, buttonText, icon, isLinkedWithSpotify, onClick: handleUnlinkSpotify }} />;

  return (
    <PopupWindow
      url={`https://accounts.spotify.com/authorize?${params}`}
      onAuth={handleAuth}
      title="spotify-authorization"
      cancelNewWindow={isLinkAccountButton}
    >
      <div className="h-10 w-10 bg-spotify rounded-full">
        <Button {...{ className, text: buttonText, icon }} />
      </div>
    </PopupWindow>
  );
};

export default SpotifyLogin;

SpotifyLogin.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.bool,
  isLinkAccountButton: PropTypes.bool,
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
  scope: PropTypes.string,
};

SpotifyLogin.defaultProps = {
  buttonText: 'Access with Spotify',
  children: null,
  className: btnDefaultStyles,
  scope: 'user-read-private user-read-email user-top-read playlist-read-private user-follow-read',
  isLinkAccountButton: false,
  icon: true,
  onFailure: () => { },
  onSuccess: () => { },
};
