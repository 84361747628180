import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import getPostsListWithTotal from '../../../api/graphql/queries/getPostsListWithTotal';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import LauncherItemSingle from './LauncherItemSingle';
import LauncherItemDouble from './LauncherItemDouble';
import LauncherItemTriple from './LauncherItemTriple';
import LauncherItemConcert from './LauncherItemConcert';
import LauncherItemRadio from './LauncherItemRadio';
import LauncherInstagram from './LauncherInstagram';
import LauncherTwitter from './LauncherTwitter';
import LauncherCarousel from './LauncherCarousel';
import NewsSkeleton from './NewsSkeleton';
import LauncherSkeleton from './LauncherSkeleton';
import SingleSkeleton from './SingleSkeleton';
import Filter from '../Collapsible/Filter';
import getPostsBySlugNameQuery from '../../../api/graphql/queries/getPostsBySlugNameQuery';

const menu = {
  text: {
    en: 'FILTER CONTENT',
    es: 'FILTRAR CONTENIDO',
    ca: 'FILTRAR CONTINGUT',
  },
  filter: [
    {
      filterHeading: {
        es: 'POR EVENTO',
        en: 'BY EVENT',
        ca: 'POR EVENTO',
      },
      menuTitle: {
        text: {
          es: 'TODOS LOS Eventos',
          en: 'All events',
        },
      },
      filterType: 'byEvent',
      radio: true,
      filterItems: [
        {
          value: 'barcelona',
          category: 'barcelona',
          text: {
            es: 'BARCELONA - PRIMAVERA SOUND',
            en: 'BARCELONA - PRIMAVERA SOUND',
            ca: 'BARCELONA - PRIMAVERA SOUND',
          },
        },
        {
          value: 'porto',
          category: 'oporto',
          text: {
            es: 'NOS - PRIMAVERA SOUND',
            en: 'NOS - PRIMAVERA SOUND',
            ca: 'NOS - PRIMAVERA SOUND',
          },
        },
        {
          value: 'benidorm',
          category: 'benidorm',
          text: {
            es: 'Benidorm - PRIMAVERA SOUND',
            en: 'Benidorm - PRIMAVERA SOUND',
            ca: 'Benidorm - PRIMAVERA SOUND',
          },
        },
        {
          value: 'los-angeles',
          category: 'los-angeles',
          text: {
            es: 'Los Angeles - PRIMAVERA SOUND',
            en: 'Los Angeles - PRIMAVERA SOUND',
            ca: 'Los Angeles - PRIMAVERA SOUND',
          },
        },
        {
          value: 'primavera-pro',
          category: 'pro',
          text: {
            es: 'Primavera Pro - PRIMAVERA SOUND',
            en: 'Primavera Pro - PRIMAVERA SOUND',
            ca: 'Primavera Pro - PRIMAVERA SOUND',
          },
        },
        {
          value: 'tours',
          category: 'tours',
          text: {
            es: 'tours',
            en: 'tours',
            ca: 'tours',
          },
        },
      ],
    },
  ],
};

let itemsCounterMedium = 0;
let itemsCounterBig = 0;
let itemsLastRow = 0;
const LAUNCHERSIZES = {
  firstRowLauncherBig: 4,
  rowLauncherBigOdd: 5,
  firstRowLauncherMedium: 3,
  rowLauncherBig: 9,
  rowLauncherMedium: 3,
  numofNewsBig: 7,
  numofNewsMedium: 4,
  rowLauncherNewsMedium: 6,
  rowMobile: 3,
  rowSingleBig: 3,
  rowSingleMedium: 2,
};
const Launcher2Content = ({
  findCategory, findCategoryOperator, slugNames, quantity, rows: itemRows, isNews, mustBeSingle, showLoadMore, hasFilters, isTours, showAll, scrollable, layout, parentComponent, sortByPostLauncherDate,
}) => {
  // const filteredSlugnames = slugNames;

  const [highlights, setHighlights] = useState([]);
  const [highlightsBig, setHighlightsBig] = useState([]);
  const [highlightsMedium, setHighlightsMedium] = useState([]);
  const [fetchResult, setFetchResult] = useState([]);
  const [status, setStatus] = useState('loading');
  const [from, setFrom] = useState(0);
  const [category, setCategory] = useState(findCategory);
  const [deviceWidth, setdeviceWidth] = useState(null);
  const [device, setDevice] = useState(null);
  const isCancelled = React.useRef(false);
  const responsiveUtility = useRef();
  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const [isDeviceReady, setDeviceReady] = useState(false);
  const [rows, setRows] = useState(itemRows);
  const [totalItems, setTotalItems] = useState(slugNames && slugNames.length ? slugNames.length : null);
  const isMounted = useRef(null);

  const getWidth = () => {
    const dv = responsiveUtility.current.deviceWidth(true);
    setdeviceWidth(dv);
    if (dv > 640 && dv < 1280) {
      setDevice('medium');
    } else if (dv > 1279) {
      setDevice('big');
    } else if (dv > 0 && dv <= 640) {
      setDevice('small');
    }
  };

  const createHighlightsArrayItem = (index, l, item, size = 'small') => {
    const {
      postDescription, postCategory, slugName, rolesAllowed,
    } = item;
    let singleDouble = 'concert';
    if (mustBeSingle) {
      singleDouble = 'single';
    }
    if (!mustBeSingle && !isNews && !isTours) {
      if (size === 'medium') {
        if ((itemsCounterMedium === 0 && l < LAUNCHERSIZES.firstRowLauncherMedium)) {
          singleDouble = 'launcherBanner';
        } else {
          singleDouble = itemsCounterMedium === 0 || itemsCounterMedium === 5 ? 'single' : 'double';
          itemsCounterMedium++;
          if (itemsCounterMedium >= 6) itemsCounterMedium = 0;
        }
      } else if (size === 'big') {
        if ((itemsCounterBig === 0 && l < LAUNCHERSIZES.firstRowLauncherBig) || l === 1) {
          singleDouble = 'launcherBanner';
        } else {
          singleDouble = itemsCounterBig === 0 || itemsCounterBig === 2 ? 'single' : 'double';
          itemsCounterBig++;
          if (itemsCounterBig >= 3) itemsCounterBig = 0;
        }
      } else if (size === 'small') {
        singleDouble = 'single';
      }
    }
    if (isNews) {
      singleDouble = index === 0 ? 'single' : 'triple';
    }

    if (size !== 'small') {
      if (l === 1) {
        if (!isTours) {
          singleDouble = 'launcherBanner';
        } else {
          singleDouble = 'launcherBannerTours';
        }
      }
      if (l === 2) {
        if (!isTours) {
          singleDouble = 'launcherBannerHalf';
        } else {
          singleDouble = 'launcherBannerToursHalf';
        }
      }
    }

    if (typeof postDescription.image === 'string') {
      // TODO: change 'en' by current user language
      postDescription.image = { en: postDescription.image };
    }
    let newLayout = layout;
    if (postDescription.itemLayout && (postDescription.itemLayout === 'twitter' || postDescription.itemLayout === 'instagram' || postDescription.itemLayout === 'newsletter')) {
      newLayout = postDescription.itemLayout;
    }

    const date = typeof postDescription.date === 'string' ? moment(postDescription.date, 'x') : null;
    const highlight = {
      date: date ? date.format('DD.MM.YYYY') : '',
      unformattedDate: Number(postDescription.date),
      image: postDescription.image,
      itemLayout: singleDouble,
      position: postDescription.position,
      tags: postDescription.tags,
      title: postDescription.title,
      subtitle: postDescription.subtitle,
      description: postDescription.description,
      url: postDescription.url || `/${postCategory[0]}/${slugName}`,
      cta: postDescription.cta ? postDescription.cta : {},
      layout: newLayout,
      authors: postDescription.authors,
      featured: postDescription.itemLayout === 'disco-de-la-semana',
      relatedMagazine: postDescription.relatedMagazine && postDescription.relatedMagazine.title,
      postCategory,
      overlayImage: postDescription.overlayImage,
      rolesAllowed,
    };

    return highlight;
  };

  const prepareGridGrid = (postsList) => {
    const hl = postsList.map((item, index) => createHighlightsArrayItem(index, postsList.length, item));
    const hlMedium = postsList.map((item, index) => createHighlightsArrayItem(index, postsList.length, item, 'medium'));
    const hlBig = postsList.map((item, index) => createHighlightsArrayItem(index, postsList.length, item, 'big'));
    if (isTours && !sortByPostLauncherDate) {
      hl.sort(
        (a, b) => a.unformattedDate - b.unformattedDate,
      );
      hlMedium.sort(
        (a, b) => a.unformattedDate - b.unformattedDate,
      );
      hlBig.sort(
        (a, b) => a.unformattedDate - b.unformattedDate,
      );
    }
    setHighlights(hl);
    setHighlightsMedium(hlMedium);
    setHighlightsBig(hlBig);
    setStatus('loaded');
  };

  useEffect(() => {
    isMounted.current = true;
    getWidth();
    setCategory(findCategory);
    return () => { isMounted.current = null; };
  }, []);

  const fetchHighlights = (cat, reset = false) => {
    itemsCounterMedium = 0;
    itemsCounterBig = 0;
    let q = quantity;
    let fr = from;
    let lmCount = loadMoreCount;
    if (showAll) q = 1000;
    if (reset) { fr = 0; lmCount = 0; }
    if (!cat) return;
    if (fetchResult.length < totalItems || lmCount === 0) {
      // console.log('Fetch query', cat, fr, q);
      const [postSlugName] = window.location.pathname.split('/').slice(-1);

      if (isMounted.current === true) {
        const options = { category: cat, findCategoryOperator, from: fr, to: q, postSlugName, sortByPostLauncherDate, overrideTotal: true };
        getPostsListWithTotal(options).then((postsList) => {
          if (!isCancelled.current) { // To avoid warning: Can't perform a React state update on an unmounted component...
            // console.log('Fetch result', postsList);
            if (postsList) {
              const { posts } = postsList;
              if (posts && posts.length) {
                let allPosts = posts;
                if (!reset) {
                  allPosts = [...fetchResult, ...posts];
                }
                setFrom(fr + quantity);
                setFetchResult(allPosts);
                prepareGridGrid(allPosts);
              } else {
                setStatus('end');
              }
            } else {
              prepareGridGrid(fetchResult);
            }
          }
        }).catch(err => console.log(err));
      }
    }
  };

  const getNumOfItems = (array) => {
    if (device) {
      let numOfItems = 0;
      if (device === 'big') {
        if (!mustBeSingle && !isTours) {
          for (let i = 0; i < rows; i++) {
            if (!isNews) {
              if (i % 2 === 0) {
                numOfItems += LAUNCHERSIZES.firstRowLauncherBig;
              } else {
                numOfItems += LAUNCHERSIZES.rowLauncherBigOdd;
              }
            } else {
              if (i === 0) {
                numOfItems += LAUNCHERSIZES.numofNewsBig;
              } else {
                numOfItems += LAUNCHERSIZES.rowLauncherBig;
                itemsLastRow = LAUNCHERSIZES.rowLauncherBig;
              }
            }
          }
        } else {
          numOfItems = LAUNCHERSIZES.rowMobile * rows;
          while (numOfItems > array.length) {
            numOfItems -= LAUNCHERSIZES.rowMobile;
            itemsLastRow = LAUNCHERSIZES.rowMobile;
          }
        }
        let i = rows % 2;
        while (numOfItems > array.length) {
          if (!isNews && !mustBeSingle && !isTours) {
            if (i % 2 === 0) {
              numOfItems -= LAUNCHERSIZES.rowLauncherBigOdd;
              itemsLastRow = LAUNCHERSIZES.rowLauncherBigOdd;
            } else {
              numOfItems -= LAUNCHERSIZES.firstRowLauncherBig;
              itemsLastRow = LAUNCHERSIZES.firstRowLauncherBig;
            }
          } else if (mustBeSingle || isTours) {
            numOfItems -= LAUNCHERSIZES.rowSingleBig;
            itemsLastRow = LAUNCHERSIZES.rowSingleBig;
          } else {
            numOfItems -= LAUNCHERSIZES.rowLauncherBig;
            itemsLastRow = LAUNCHERSIZES.rowLauncherBig;
          }
          i++;
        }
      }
      if (device === 'medium') {
        if (!isNews) {
          if (!mustBeSingle && !isTours) {
            numOfItems = LAUNCHERSIZES.rowLauncherMedium * rows;
          } else {
            numOfItems = LAUNCHERSIZES.rowSingleMedium * rows;
          }
        } else {
          for (let i = 0; i < rows; i++) {
            if (i === 0) {
              numOfItems += LAUNCHERSIZES.numofNewsMedium;
            } else {
              numOfItems += LAUNCHERSIZES.rowLauncherNewsMedium;
              itemsLastRow = LAUNCHERSIZES.rowLauncherNewsMedium;
            }
          }
        }
        while (numOfItems > array.length) {
          if (!isNews && !mustBeSingle && !isTours) {
            numOfItems -= LAUNCHERSIZES.rowLauncherMedium;
            itemsLastRow = LAUNCHERSIZES.rowLauncherMedium;
          } else if (mustBeSingle || isTours) {
            numOfItems -= LAUNCHERSIZES.rowSingleMedium;
            itemsLastRow = LAUNCHERSIZES.rowSingleMedium;
          } else {
            numOfItems -= LAUNCHERSIZES.rowLauncherNewsMedium;
            itemsLastRow = LAUNCHERSIZES.rowLauncherNewsMedium;
          }
        }
      }
      if (device === 'small') {
        itemsLastRow = 0;
        numOfItems = LAUNCHERSIZES.rowMobile * rows;
        while (numOfItems > array.length) {
          numOfItems -= 1;
        }
      }
      return numOfItems;
    }
  };

  const fetchPostsBySlugNames = (slugnames) => {
    itemsCounterMedium = 0;
    itemsCounterBig = 0;
    setFrom(slugnames.length);
    if (isMounted.current === true) {
      getPostsBySlugNameQuery(slugnames, sortByPostLauncherDate, quantity)
        .then((result) => {
          if (isMounted.current === true) {
            if (result && result.length) {
              const postsList = result.filter(x => !!x);
              // console.log(postsList);
              setTotalItems(postsList.length);
              prepareGridGrid(postsList);
            } else {
              setStatus('end');
            }
          }
        })
        .catch((err) => {
          setStatus('end');
          console.log('Some error happened on fetch of fetchPostDescription:', err);
        });
    }
  };

  // const filterSlugnames = () => {
  //   if (!itemsProperties || !itemsProperties.length) {
  //     return;
  //   }

  //   // Remove launcher item if it is scheduled in the future:
  //   slugNames.forEach((slugName) => {
  //     if (itemsProperties[slugName] && itemsProperties[slugName].scheduledDate && new Date(itemsProperties[slugName].scheduledDate) > new Date()) {
  //       delete filteredSlugnames[slugName];
  //     }
  //   });

  //   const orderedSchedulesDesc = filteredSlugnames.sort((a, b) => b.scheduledDate - a.scheduledDate);

  //   if (quantity <= slugNames.length) {
  //     return;
  //   }
  //   // Handle scheduled items

  // };

  useEffect(() => {
    isCancelled.current = false;
    if (isDeviceReady) {
      if (slugNames && slugNames.length) {
        if (loadMoreCount === 0) fetchPostsBySlugNames(slugNames);
      } else if (!slugNames.length) {
        fetchHighlights(category);
      }
    }
    return () => { isCancelled.current = true; };
  }, [loadMoreCount, isDeviceReady]);

  useEffect(() => {
    if (deviceWidth) setDeviceReady(true);
    return () => {
      setDeviceReady(false);
    };
  }, [deviceWidth]);

  const onHandleClick = () => {
    itemsCounterMedium = 0;
    itemsCounterBig = 0;
    setRows(rows + 1);
    setLoadMoreCount(loadMoreCount + 1);
  };

  const onHandleCollapsibleMenuSelect = (filters) => {
    let events = ['news'];
    if (filters.byEvent && filters.byEvent.length) {
      events = [...filters.byEvent, ...events];
    }
    setStatus('loading');
    setLoadMoreCount(0);
    setHighlights([]);
    setHighlightsMedium([]);
    setHighlightsBig([]);
    setFetchResult([]);
    setCategory(events);
    fetchHighlights(events, true);
  };

  const launcherItemArrayBuilder = (array, itemsCount, isSlider) => {
    // helper to make arrays of items depending on how many items are contained in each LauncherItem
    let reducedItems = array;
    if (isSlider) {
      return reducedItems;
    }
    if (itemsCount && itemsCount > 0) {
      reducedItems = array.slice(0, itemsCount);
    }
    if (reducedItems.length) {
      const newArray = [];
      reducedItems.map((highlight) => {
        const lastNewArrayItem = newArray[newArray.length - 1];
        // check if last item in newArray is an array if so,
        // check if it accepts 2 o 3 children and fill them until completed
        // if last itemLayoutin newArray is not an Array just push the element

        if (Array.isArray(lastNewArrayItem)) {
          if (lastNewArrayItem[0].itemLayout === 'triple' && newArray[newArray.length - 1].length < 3) {
            newArray[newArray.length - 1].push(highlight);
          } else if (lastNewArrayItem[0].itemLayout === 'double' && newArray[newArray.length - 1].length < 2) {
            newArray[newArray.length - 1].push(highlight);
          } else {
            if (highlight.itemLayout !== 'triple' && highlight.itemLayout !== 'double') {
              newArray.push(highlight);
            } else {
              newArray.push(Array.of(highlight));
            }
          }
        } else {
          if (highlight.itemLayout !== 'triple' && highlight.itemLayout !== 'double') {
            newArray.push(highlight);
          } else {
            newArray.push(Array.of(highlight));
          }
        }
      });
      return newArray;
    }
  };

  // const SocialMedia = ({ className, index }) => (
  //   <div
  //     data-name={`${index}`}
  //     className={`w-full laptop:w-1/3 px-3 laptop:px-4 laptop:mb-24 ${className}`}
  //   />
  // );

  const checkItemType = (highlight) => {
    const { itemLayout } = highlight[0];
    // eslint-disable-next-line max-len
    return itemLayout === 'double' ? highlight.map((content, index) => <LauncherItemDouble {...content} position={index} key={`launcherItem-${index}`} />) : highlight.map((content, index) => <LauncherItemTriple {...content} position={index} key={`launcherItem-${index}`} />);
  };

  // TODO: propTypes
  const LauncherItemBuilder = ({ highlight, index }) => {
    if (highlight.itemLayout === 'concert') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full py-8px px-5px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemConcert {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'launcherBannerTours') {
      return (
        <div className="w-full launcherItem py-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemConcert isBanner {...highlight} position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'launcherBannerToursHalf') {
      return (
        <div className="launcherItem llg:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemConcert device={device} {...highlight} isHalf position={index} key={`launcherItem-${index}`} />
        </div>
      );
    }
    if (highlight.itemLayout === 'launcherBanner') {
      return (
        <div className="w-full launcherItem py-8px sm:px-0 mb-8 tablet:mb-12 laptop:mb-20" key={`launcherContainer-${index}`}>
          <LauncherItemSingle {...highlight} isBanner position={index} />
        </div>
      );
    }
    if (highlight.itemLayout === 'single') {
      return (
        <div key={`launcherContainer-${highlight.url}-${index}`} className={`w-full tablet:w-1/2 laptop:w-1/3 px-0 tablet:px-3 laptop:px-4 ${parentComponent === 'RecordsPostPage' ? 'mb-4 tablet:mb-0' : 'mb-8 tablet:mb-10 laptop:mb-24'}`}>
          <div className="launcherItem">
            <LauncherItemSingle isNews={isNews} {...highlight} position={index} />
          </div>
        </div>
      );
    }
    if (highlight.itemLayout === 'launcherBannerHalf') {
      return (
        <div className="launcherItem llg:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0 tablet:mb-12 laptop:mb-20" key={`launcherContainer-${index}`}>
          <LauncherItemSingle isHalf isNews={isNews} {...highlight} position={index} />
        </div>
      );
    }
    if (highlight.itemLayout === 'radio') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherItemRadio {...highlight} position={index} />
        </div>
      );
    } if (highlight.itemLayout === 'instagram') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherInstagram {...highlight} position={index} />
        </div>
      );
    } if (highlight.itemLayout === 'twitter') {
      return (
        <div className="launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full p-8px sm:px-0" key={`launcherContainer-${index}`}>
          <LauncherTwitter {...highlight} position={index} />
        </div>
      );
    }
    return (
      <div className={`${Array.isArray(highlight) && highlight.length > 0 && highlight[0].itemLayout === 'triple' ? `${highlight[0].itemLayout} py-0` : 'py-8px'} launcherItem llg:w-1/3 lg:w-1/2 md:w-1/2 sm:w-full px-5px sm:px-0`} key={`launcher-${index}`}>
        {checkItemType(highlight)}
      </div>
    );
  };


  // TODO: propTypes
  const MainNewsBuilder = ({ highlight, index }) => (
    <div className={`
      ${index === 3 ? 'col-span-12 laptop:col-span-6' : 'col-span-12 tablet:col-span-6 laptop:col-span-4'}
      ${index === 1 && 'laptop:col-start-6'}
      ${index === 2 && 'tablet:row-start-2'}
      ${index === 3 && 'tablet:row-start-3 laptop:row-start-2'}
      ${index === 3 && 'laptop:col-start-7'}
    `}
    >
      <div key={`launcherItem-${index}`} className="launcherItem">
        <LauncherItemSingle mainNews={layout === 'mainNews'} {...highlight} position={index} key={`launcherItem-${index}`} />
      </div>
    </div>
  );

  let hls = [];
  if (device === 'small') {
    hls = highlights;
  } else if (device === 'medium') {
    hls = highlightsMedium;
  } else if (device === 'big') {
    hls = highlightsBig;
  }
  let itemsCount = hls.length;
  if (!showAll) itemsCount = getNumOfItems(hls);

  const isSlider = ['discos', 'magazine', 'curatedList', 'noticias', 'smallPictures', 'smallPicturesTextInWhite'].includes(layout) || scrollable === true;

  const highlightsArray = launcherItemArrayBuilder(hls, itemsCount, isSlider);
  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getWidth} />
      {hasFilters && (
      <Filter
        {...menu}
        hasSearchBar={false}
        onHandleCollapsibleMenuSelect={onHandleCollapsibleMenuSelect}
      />
      )}
      {status === 'loaded' && device ? (
        <>
          <>
            {isSlider ? (
              <LauncherCarousel items={highlightsArray} news={layout === 'noticias'} defaultSingleItems={!layout && scrollable === true} />
            ) : (
              <>
                {layout === 'mainNews'
                  ? (
                    <div className="full-width px-5 tablet:px-42px laptop:px-24 grid grid-cols-12 tablet:gap-x-4 gap-y-12 pb-12 laptop:pb-24">
                      {highlightsArray && highlightsArray.map((highlight, index) => <MainNewsBuilder key={`h-${highlight.url}`} highlight={highlight} index={index} />)}
                    </div>
                  )
                  : (
                    <div className="full-width flex flex-wrap items-stretch justify-start px-5 tablet:px-7 laptop:px-20 mb-4 tablet:mb-0">
                      {highlightsArray && highlightsArray.map((highlight, index) => <LauncherItemBuilder key={`h-${highlight.url}`} highlight={highlight} index={index} />)}
                    </div>
                  )
                }
              </>
            )}
          </>
          {showLoadMore && highlightsArray && highlightsArray.length && status === 'loaded' && (itemsCount !== totalItems) && (itemsCount + itemsLastRow <= totalItems) && !showAll && (
          <div className="flex justify-center w-full mt-2 py-8px">
            <span className="py-1 text-center text-black uppercase border-b-2 border-black font-stratos font-bold text-14">
              <span onClick={onHandleClick}>
                <span className="font-icon icon-arrow-down text-12 bold" />
                <span className="px-4 cursor-pointer">Load More</span>
                <span className="font-icon icon-arrow-down text-12 bold" />
              </span>
            </span>
          </div>
          )}
        </>
      ) : (
        status === 'loading'
        && (
        <div className="flex flex-wrap w-full -mx-2 launcher sm:-mx-0">
          {isNews && <NewsSkeleton />}
          {(!mustBeSingle && !isNews && !isTours) && <LauncherSkeleton deviceWidth={deviceWidth} />}
          {(mustBeSingle || isTours || (deviceWidth <= 640 && !isNews)) && <SingleSkeleton />}
        </div>
        )
      )}
      {totalItems !== null && status === 'end' && (
      <div className="flex justify-center w-full py-8 mt-2">
        <span className="py-1 text-center text-black uppercase border-b-2 border-black font-stratos font-bold text-14">
          <span>
            <span className="px-4 ">No items</span>
          </span>
        </span>
      </div>
      )
      }
    </>
  );
};

const Launcher2 = (props) => {
  const isMounted = useRef(null);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = null; };
  }, []);

  if (isMounted) {
    return (
      <Launcher2Content key="launcher2" {...props} />
    );
  }
  return null;
};

Launcher2Content.propTypes = {
  quantity: PropTypes.number,
  findCategory: PropTypes.arrayOf(PropTypes.string),
  findCategoryOperator: PropTypes.string,
  slugNames: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.number,
  isNews: PropTypes.bool,
  mustBeSingle: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  hasFilters: PropTypes.bool,
  isTours: PropTypes.bool,
  showAll: PropTypes.bool,
  scrollable: PropTypes.bool,
  layout: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  parentComponent: PropTypes.string,
  sortByPostLauncherDate: PropTypes.bool,
};

Launcher2Content.defaultProps = {
  quantity: 9,
  findCategory: null,
  findCategoryOperator: 'or',
  slugNames: null,
  rows: null,
  isNews: false,
  mustBeSingle: false,
  showLoadMore: false,
  hasFilters: false,
  isTours: false,
  showAll: false,
  scrollable: false,
  layout: false,
  parentComponent: null,
  sortByPostLauncherDate: false,
};

export default Launcher2;
