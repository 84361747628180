import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Link from '../../../util/link';
import translateObj from '../../../util/translateObj';
import { useRoutes } from '../../../context/routesContext';

const MultioptionsButtons = ({
  buttons, match,
}) => {
  const { language } = useRoutes(match);
  return (
    <div className="flex justify-center sm:flex-col sm:px-5 w-full md:flex-col md:px-5">
      {
        <>
          {
           buttons.map(button => (
             <Link to={translateObj(button.url)} className="flex">
               <button
                 className={` sm:truncate sm:break-all sm:w-screen-95 sm:text-sm md:truncate md:break-all md:w-screen-95 md:text-base  hover:bg-white hover:text-black text-${button.textColor || 'white'} whitespace-pre trans rounded-full uppercase font-americaMonoBold my-4 px-20 mx-3 py-4 cursor-pointer text-center bg-${button.backgroundColor || 'black'}`}
                 type="button"
               >
                 <span className="">{translateObj(button.name, language)}</span>
               </button>
             </Link>
           ))
         }
        </>
     }
    </div>
  );
};

MultioptionsButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape()),
  match: PropTypes.shape().isRequired,
};

MultioptionsButtons.defaultProps = {
  buttons: [],
};


export default withRouter(MultioptionsButtons);
