import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Navigator from 'ComponentsPath/Navigator';

import getNavigatorSection from '../../../util/getNavigatorSection';
import LoginHandler from '../LoginHandler';

class Body extends Component {
  state = {
    bannerHeight: 0,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('checkBodyTopSpacing', this.checkBodyTopSpacing);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('checkBodyTopSpacing', this.checkBodyTopSpacing);
  }

  checkBodyTopSpacing = (event) => {
    const banner = document.getElementById('banner');
    if (event.detail && event.detail.closed) {
      this.setState({ bannerHeight: 0 });
    } else if (banner) {
      this.setState({ bannerHeight: banner.offsetHeight });
    } else {
      this.setState({ bannerHeight: 0 });
    }
  }

  render() {
    const { children, match } = this.props;
    const { components } = children.props;
    const { bannerHeight } = this.state;

    return (
      <div
        id="body"
        className="w-full flex flex-col items-center relative min-h-screen-80 bg-white overflow-hidden"
        style={{ paddingTop: `${bannerHeight}px` }}
      >
        {children}
        {components.length > 0 && components[0].type && components[0].type === 'Carousel' && getNavigatorSection(match.params) && <Navigator />}
        {/* TODO: LoginHandler should be one level higher loger */}
        <LoginHandler />
      </div>
    );
  }
}

Body.propTypes = {
  children: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};


export default withRouter(Body);
