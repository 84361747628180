import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createConnector, connectSortBy } from 'react-instantsearch-dom';
import { TweenMax } from 'gsap';
import { useParams } from 'react-router-dom';
import BodyChildContainer from '../../../containers/BodyChildContainer';

const connectWithQuery = createConnector({
  displayName: 'WidgetWithQuery',
  getProvidedProps(props, searchState) {
    // Since the `attributeForMyQuery` searchState entry isn't
    // necessarily defined, we need to default its value.
    const currentRefinement = searchState.attributeForMyQuery || '';

    // Connect the underlying component with the `currentRefinement`
    return { currentRefinement };
  },
  refine(props, searchState, nextRefinement) {
    // When the underlying component calls its `refine` prop,
    // we update the searchState with the provided refinement.
    return {
      // `searchState` represents the search state of *all* widgets. We need to extend it
      // instead of replacing it, otherwise other widgets will lose their respective state.
      ...searchState,
      attributeForMyQuery: nextRefinement,
    };
  },
  getSearchParameters(searchParameters, props, searchState) {
    // When the `attributeForMyQuery` state entry changes, we update the query
    return searchParameters.setQuery(searchState.attributeForMyQuery || '');
  },
  cleanUp(props, searchState) {
    // When the widget is unmounted, we omit the entry `attributeForMyQuery`
    // from the `searchState`, then on the next request the query will
    // be empty
    const { attributeForMyQuery, ...nextSearchState } = searchState;

    return nextSearchState;
  },
});

const MySearchBox = ({
  currentRefinement,
  refine,
  isListPage,
  updateSorting,
  queryFromUrl = '',
  custom = false,
}) => {
  const rotateClose = (entering = true) => {
    TweenMax.to('[data-name="searchoverlay"] [data-name="close"]', 0.4, {
      rotation: entering ? 180 : 0,
    });
  };

  const [sorting, setSorting] = useState('desc');
  const rotateOpen = () => rotateClose(false);
  const cleanSearch = () => refine('');
  const changeSearch = (e) => {
    refine(e.currentTarget.value);
    // updateIsSearchingText(e.currentTarget.value.length > 0);
  };

  const { section } = useParams();
  const ref = useRef();

  const isSearchPage = section === 'search';

  useEffect(() => {
    if (queryFromUrl !== '') refine(queryFromUrl);
  }, [queryFromUrl]);

  useEffect(() => {
    if (ref?.current && isSearchPage) ref.current.focus();
  }, [ref, isSearchPage]);

  // if has custom index we will not need optionable fields, so no inputbox
  if (custom) return <></>

  return (
    <div
      className="w-full flex flex-column items-center relative"
      data-name="searchoverlay"
    >
      <BodyChildContainer noHorizontalPadding>
        <div className="tablet:flex tablet:items-end tablet:mx-26px laptop:mx-16">
          <div
            className={`${isListPage
              ? 'mt-4 flex flex-row border-b border-black border-solid flex-1'
              : 'mt-16 tablet:mt-20 laptop:mt-36 desktop:mt-32 w-full'}
              relative`}
            data-name="title"
          >
            <span className={`${isListPage ? 'mt-3 mr-8' : 'mb-8'} font-stratos text-black inline-block text-20 font-semibold`}>
              Buscar
            </span>
            <input
              type="text"
              className={`appearance-none w-full outline-none capitalize font-stratos
              text-38 font-semibold bg-transparent text-black
              pin-t pin-l pin-r ${!isListPage ? 'border-b border-black border-solid' : ''}`}
              style={{ caretColor: '#FF4646' }}
              ref={ref}
              value={currentRefinement}
              onChange={changeSearch}
            />
            {currentRefinement && currentRefinement.length && (
              <span
                data-name="close"
                className={`cursor-pointer absolute z-10
                 pin-r mr-2
                ${isListPage ? 'pin-t' : 'pin-b mb-2'}`}
                onClick={cleanSearch}
                onMouseEnter={rotateOpen}
                onMouseLeave={rotateClose}
              >
                <span className="font-icon icon-close text-black text-lg" />
              </span>
            )}
          </div>
          {isListPage && (
          <CustomSortBy
            defaultRefinement="createdAt_desc"
            items={[
              { value: 'createdAt_desc', label: 'Lo último' },
              { value: 'createdAt_asc', label: 'Lo más antiguo' },
            ]}
          />
          )}
        </div>
      </BodyChildContainer>
    </div>
  );
};

const SortBy = ({
  items,
  currentRefinement,
  refine,
}) => (
  <div className="float-right tablet:float-none relative">
    <select
      className="select-appearance-none rounded-none pl-1 pr-6 tablet:ml-8 font-stratos font-bold text-red border-5 border-red bg-white mt-4 tablet:mt-0 tablet:h-1/2"
      onChange={e => refine(e.target.value)}
      value={currentRefinement}
    >
      {items.map((item, index) => <option key={`${item.value}_${index}`} value={item.value}>{`${item.label}`}</option>)}
    </select>
    <div className="absolute pin-t pin-r pin-b flex items-center justify-center pt-4 tablet:pt-0 h-full pr-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="16.68" height="9.778"><path data-name="4585" d="M8.34 9.778L16.68 0H0z" fill="#ff4646"/></svg>
    </div>
  </div>
);


const ConnectedSearchBox = connectWithQuery(MySearchBox);
const CustomSortBy = connectSortBy(SortBy);

MySearchBox.propTypes = {
  refine: PropTypes.func,
  isListPage: PropTypes.bool,
  currentRefinement: PropTypes.string,
};

MySearchBox.defaultProps = {
  refine: null,
  isListPage: false,
  currentRefinement: null,
};

export default ConnectedSearchBox;
