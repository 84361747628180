/* eslint-disable react/forbid-prop-types */

/**
 * This component shows a card form so the customer can enter the card data
 * Normally it will be called when the customer has no card stored, calling stripe.confirmCardSetup
 *
 * If can also be called when a user uses a stored card but this failed for some reason. In this case,
 * a paymentIntentClientSecret of the paymentIntent from server is passed and it will do a stripe.confirmCardPayment
 */
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
// import jwt from 'jsonwebtoken';
import api from './psWorldPaymentsApi';
// import translateObj from '../../util/translateObj';
// import getProducts from '../../api/graphql/queries/getProducts';
// import FormInput from '../Form/FormInput';


const useOptions = () => {
  const fontSize = '18px';
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          width: '400px',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
  );

  return options;
};

const PsWorldStripeCardForm = ({
  customerInfo,
  product,
  paymentIntentClientSecret, // If present, it will do a stripe.confirmCardPayment. If not: stripe.confirmCardSetup
  publicKey,
  resultCallback,
}) => {
  const [cardSetupSecret, setCardSetupSecret] = useState(null);
  const [setupIntent, setSetupIntent] = useState(null);
  const [error, setError] = useState(null);
  const [resultInfo, setResultInfo] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const inputClass = 'mt-4 rounded-full border-1 p-4 bg-inherit border-black text-black border-black text-black';

  useEffect(() => {
    if (!customerInfo) {
      return;
    }

    if (paymentIntentClientSecret) {
      // If there is already a client secret passed as prop, don't create setup intent!
      return;
    }

    api
      .createSetupIntent({
        customer: customerInfo.id,
      })
      .then(async (setupIntentObject) => {
        setSetupIntent(setupIntentObject);
        setCardSetupSecret(setupIntentObject.client_secret);
      })
      .catch((err) => {
        setError(err.message);
        setProcessing(false);
      });
  }, [customerInfo]);

  /* Shows a success / error message when the payment is complete */
  const orderComplete = (subscription) => {
    setProcessing(false);
    setSucceeded(true);
    setResultInfo(`Subscription Status: ${subscription.status}`);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    setError(false);

    if (paymentIntentClientSecret) {
      // If paymentIntentClientSecret prop is passed, itmeans we have a payment intent (due to a failed offline payment intent) instead of a setupintent
      stripe
        .confirmCardPayment(paymentIntentClientSecret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
            save_payment_method: true,
          })
        .then(async (result) => {
          if (result.error) {
            setError(`Payment failed: ${result.error.message}`);
            setProcessing(false);
            console.log('[error]', result.error);
            resultCallback({ status: 'failed' });
          } else {
            setError(null);
            setSucceeded(true);
            setProcessing(false);
            setResultInfo('Payment succeeded');
            console.log('[PaymentIntent]', result);
            resultCallback({ status: 'succeeded' });
          }
        })
        .catch((err) => {
          setError(err.message);
          setProcessing(false);
        });
    } else {
      // Normal first time get card details
      stripe
        .confirmCardSetup(cardSetupSecret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
            // save_payment_method: true,
          })
        .then(async (result) => {
          if (result.error) {
            setError(`Card setup failed: ${result.error.message}`);
            setProcessing(false);
            console.log('[error]', result.error);
            resultCallback({ status: 'failed' });
          } else {
            setError(null);
            setSucceeded(true);
            setProcessing(false);
            setResultInfo('Card setup succeeded');
            console.log('[SetupIntent]', result);
            resultCallback({ status: 'succeeded' });
          }
        })
        .catch((err) => {
          setError(err.message);
          setProcessing(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <p>
        {product && product.title ? product.title.en : ''}
      </p>

      <h2>
        Please enter your card details:
      </h2>

      <label className="flex flex-col" htmlFor="cardnumber">
        <span className="font-stratos text-10 text-black font-semilight">CARD NUMBER</span>
        <CardNumberElement
          options={options}
          className={inputClass}
          onReady={() => {
            console.log('CardNumberElement [ready]');
          }}
          onChange={(event) => {
            console.log('CardNumberElement [change]', event);
            if (event && event.error) {
              setError(event.error.code);
            }
          }}
          onBlur={() => {
            console.log('CardNumberElement [blur]');
          }}
          onFocus={() => {
            console.log('CardNumberElement [focus]');
          }}
        />
      </label>
      <label className="flex flex-col" htmlFor="expirationDate">
        <span className="font-stratos text-10 text-black font-semilight">EXPIRATION DATE</span>
        <CardExpiryElement
          options={options}
          className={inputClass}
          onReady={() => {
            console.log('CardNumberElement [ready]');
          }}
          onChange={(event) => {
            console.log('CardNumberElement [change]', event);
          }}
          onBlur={() => {
            console.log('CardNumberElement [blur]');
          }}
          onFocus={() => {
            console.log('CardNumberElement [focus]');
          }}
        />
      </label>
      <label className="flex flex-col" htmlFor="cvc">
        <span className="font-stratos text-10 text-black font-semilight">CVC</span>
        <CardCvcElement
          options={options}
          className={inputClass}
          onReady={() => {
            console.log('CardNumberElement [ready]');
          }}
          onChange={(event) => {
            console.log('CardNumberElement [change]', event);
          }}
          onBlur={() => {
            console.log('CardNumberElement [blur]');
          }}
          onFocus={() => {
            console.log('CardNumberElement [focus]');
          }}
        />
      </label>
      <div className="flex justify-end mt-8 sm:mt-4">
        <div className="flex flex-col w-1/2">
          <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold">
            BACK
          </button>
        </div>
        <div className="flex flex-col w-1/2">
          {/* <button type="submit" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" disabled={processing || !paymentIntentClientSecret || !stripe}> */}
          <button type="submit" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" disabled={processing || !stripe}>
            {processing ? 'PROCESSING…' : 'CONTINUE'}
          </button>
        </div>
      </div>
      <div className="flex justify-end mt-8 sm:mt-0">
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="w-full card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? 'w-full result-message' : 'w-full result-message hidden'}>
          Card setup, see the result in your
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://dashboard.stripe.com/test/payments"
          >
            {' '}
            Stripe dashboard.
          </a>
        </p>
        {resultInfo && (
          <p>
            {resultInfo}
          </p>
        )}
      </div>
    </form>
  );
};

PsWorldStripeCardForm.propTypes = {
  // customerInfo: PropTypes.objectOf(PropTypes.object()).isRequired,
  customerInfo: PropTypes.shape({}).isRequired,
  product: PropTypes.shape({}).isRequired,
  paymentIntentClientSecret: PropTypes.string, // If present, it will do a stripe.confirmCardPayment. If not: stripe.confirmCardSetup
  publicKey: PropTypes.string,
  resultCallback: PropTypes.func.isRequired,
};

PsWorldStripeCardForm.defaultProps = {
  paymentIntentClientSecret: null,
  publicKey: null,
};

export default PsWorldStripeCardForm;
