import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

const SearchNumberOfResultsFound = ({
  match,
  numberOfResults,
  searchQuery,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const textPart1 = {
          en: 'Results for',
          ca: 'Resultats per',
          es: 'Resultados para',
          pt: 'Resultados para',
        };
        const textPart2 = {
          en: 'results found match your search',
          ca: 'resultats coincideixen amb la cerca',
          es: 'resultados coinciden con la búsqueda',
          pt: 'resultados encontrados correspondem à sua pesquisa',
        };

        const searchQueryNice = searchQuery.replace(/^"(.*)"$/, '$1');

        return (
          <>
            <div className="pb-2 laptop:pb-4 w-full text-black">
              <span className="font-stratos font-semibold text-20 tablet:text-base laptop:text-22">
                {textPart1[localization.language]}
                {' '}
                {`"${searchQueryNice}"`}
              </span>
            </div>
            <div className="pb-6 laptop:pb-8 w-full">
              <span className="font-stratos font-regular text-12 laptop:text-14 text-left">
                <span>{numberOfResults}</span>
                {' '}
                {textPart2[localization.language]}
                {' '}
                <span>
                  {`"${searchQueryNice}"`}
                </span>
              </span>
            </div>
          </>
        );
      }
    }
  </RoutesContext.Consumer>
);

SearchNumberOfResultsFound.propTypes = {
  match: PropTypes.shape().isRequired,
  numberOfResults: PropTypes.number.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

export default withRouter(SearchNumberOfResultsFound);
