import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import stripTags from '../helpers/stripTags';
import translateObj from '../../../util/translateObj';

const TitleLineH2 = ({
  text, color, margin, match, mainNews, textAlign, parentComponent, includesLink, onOpen, enhanced, showAlt, altText,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const textColorTailwind = color && !color.includes('#');
      const textColorHex = color && color.includes('#');
      const titleText = stripTags(translateObj(showAlt ? altText : text, localization.language));
      const hereText = {
        es: 'aquí',
        en: 'aquí',
      };

      if (parentComponent === 'Paywall') {
        return (
          <h2
            style={textColorHex ? { color } : null}
            className={`${textColorTailwind && `text-${color}`} text-${textAlign} font-stratos text-base font-normal leading-22`}
          >
            {includesLink
              ? (
                <>
                  {`${titleText.slice(0, -5)} `}
                  <span><a onClick={() => onOpen()} className="cursor-pointer text-red hover:underline">{translateObj(hereText, localization.language)}</a></span>
                </>
              )
              : titleText
            }
          </h2>
        );
      }

      const textStyle = () => {
        let textClasses = 'font-semibold text-24 leading-28 laptop:text-26 laptop:leading-30 font-stratos tablet:text-26 tablet:leading-30';
        switch (parentComponent) {
          case 'ListsPostPage':
            textClasses = 'col-span-6 font-normal text-black tablet:col-span-12 laptop:col-span-10 laptop:col-start-2 text-34 tablet:text-36 laptop:text-48 desktop:text-52 font-stratos tracking-1.7px';
            break;
          case 'StandardArticlePage':
            textClasses = `col-span-6 font-normal text-black tablet:col-span-12 laptop:col-span-10 laptop:col-start-2 text-34 tablet:text-36 laptop:text-48 desktop:text-52 font-stratos tracking-1.7px ${enhanced ? 'pt-8 laptop:pb-20' : ''}`;
            break;
          case 'CoverStoryPostPage':
            textClasses = 'py-8 font-bold font-stratos text-red text-28 tablet:text-32 laptop:text-46 desktop:text-52';
            break;
          case 'NewsPostPage':
            textClasses = 'tracking-1.7px pb-6 pt-3 text-34 font-stratos font-normal leading-42';
            break;
          default:
            textClasses = 'font-semibold text-24 leading-28 laptop:text-26 laptop:leading-30 font-stratos tablet:text-26 tablet:leading-30';
            break;
        }
        return textClasses;
      };

      if (parentComponent && parentComponent !== 'Paywall') {
        return (
          <h2 className={`${textStyle()}`}>
            {titleText}
          </h2>
        );
      }

      return (
        <h2
          style={textColorHex ? { color } : null}
          className={
            `${textColorTailwind && `text-${color}`} ${textAlign && `text-${textAlign}`} ${margin} ${mainNews ? 'tablet:text-22 tablet:leading-26' : 'tablet:text-26 tablet:leading-30'} text-24 leading-28 laptop:text-26 laptop:leading-30 font-stratos font-semibold`}
        >
          {stripTags(translateObj(text, localization.language))}
        </h2>
      );
    }}
  </RoutesContext.Consumer>
);

export default withRouter(TitleLineH2);

TitleLineH2.propTypes = {
  color: PropTypes.string,
  textAlign: PropTypes.string,
  margin: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  mainNews: PropTypes.bool,
  parentComponent: PropTypes.string,
  includesLink: PropTypes.bool,
  onOpen: PropTypes.func,
  enhanced: PropTypes.bool,
  showAlt: PropTypes.bool,
  altText: PropTypes.shape({}),
};

TitleLineH2.defaultProps = {
  color: 'black',
  textAlign: 'left',
  margin: 'my-6',
  text: null,
  mainNews: false,
  parentComponent: null,
  includesLink: false,
  onOpen: null,
  enhanced: false,
  showAlt: false,
  altText: null,
};
