import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useUser } from '../../../../../context/userProfileContext';

import translateObj from '../../../../../util/translateObj';

const ProfileLogOutButton = ({ language, history, sourceWeb }) => {
  const { loadTokenForLS, logoutUser } = useUser();

  const _logout = (lang, webSource = '') => {
    logoutUser();
    if (typeof localStorage !== 'undefined') {
      // history.push(`/${lang}${webSource && `/${webSource}`}`);
      history.push(`/${webSource && `/${webSource}`}`);
      loadTokenForLS();
    }
  };

  return (
    <button
      type="button"
      onClick={() => _logout(language, sourceWeb)}
      className="font-stratos text-base font-semibold text-red flex items-center justify-center"
    >
      <span className="font-icon icon-power text-20 mt-px mr-2" />
      {translateObj({
        en: 'Cerrar sesión',
        es: 'Cerrar sesión',
      }, language)}
    </button>
  );
};

ProfileLogOutButton.propTypes = {
  language: PropTypes.string,
  history: PropTypes.shape().isRequired,
  sourceWeb: PropTypes.string,

};

ProfileLogOutButton.defaultProps = {
  language: 'en',
  sourceWeb: '',

};
export default withRouter(ProfileLogOutButton);
