import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';

class FooterBody extends Component {
  state= {}

  render() {
    const { components } = this.props;

    return (
      <div data-name="footer-body" className="w-full py-8 text-white bg-red laptop:pt-16 laptop:pb-18 tablet:pt-34px tablet:pb-6">
        <div data-name="grid-wrapper" className="grid grid-cols-12 px-5 full-width tablet:px-42px laptop:px-24 tablet:gap-x-4 tablet:gap-y-12 max-w-rdl-xxl mx-auto">
          <div data-name="footer-logo-wrapper" className="col-span-12 mb-8 mt-2px tablet:mt-0 tablet:col-span-4 tablet:pl-2px desktop:pl-10 tablet:pr-28px laptop:pr-0">
            {components.map((item, index) => {
              if (item.type === 'FooterLogo') return <ComponentIterator key={`${item.name}-${index}`} components={[item]} />;
              return null;
            })}
          </div>
          <div data-name="footer-other-wrapper" className="flex flex-row flex-wrap items-stretch justify-between col-span-12 laptop:justify-start tablet:-mt-1 tablet:col-span-7 tablet:col-end-13 laptop:col-span-8 laptop:flex-no-wrap">
            {components.map((item, index) => {
              if (item.type !== 'FooterLogo' && item.type !== 'FooterParagraphLine') return <ComponentIterator key={`${item.name}-${index}`} components={[item]} />;
              return null;
            })}
          </div>
        </div>
        {/* <div className="items-center justify-start hidden px-4 mt-12 laptop:flex">
          {components.map((item, index) => {
            if (item.type === 'FooterParagraphLine') return <ComponentIterator key={`${item.name}-${index}`} components={[item]} />;
            return null;
          })}
        </div> */}
      </div>
    );
  }
}

FooterBody.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

export default FooterBody;
