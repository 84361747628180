import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const ButtonTag = ({
  text, category, margin, textColor, borderColor, hidePound, match, url,
}) => (
  // color of the tag is determined by the tag category
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const category_css_valid = category ? category.replace(' ', '_') : 'default';
      const link_to_own_category = category ? category.replace(' ', '-') : 'home';

      return (
        <div>
          <Link to={`${url || link_to_own_category}`}>
            <span
              className={`${margin} ml-0 inline-block font-americaMonoMedium mr-1 text-sm ${textColor.length > 0 ? `text-${textColor}` : 'text-white'} align
                rounded px-2 py-1 sm:text-xxs md:text-xxs leading-normal bg-${category_css_valid} ${margin || ''} ${borderColor.length > 0 ? `border-solid border border-${borderColor}` : ''}`}
            >
              <span className="uppercase">{`${hidePound ? ' ' : '#'}${translateObj(text, localization.language)}`}</span>
            </span>
          </Link>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

ButtonTag.propTypes = {
  match: PropTypes.shape().isRequired,
  category: PropTypes.string.isRequired,
  hidePound: PropTypes.bool,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }).isRequired,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
};

ButtonTag.defaultProps = {
  margin: '',
  category: 'barcelona',
  textColor: '',
  borderColor: '',
  hidePound: true,
};

export default withRouter(ButtonTag);
