import React from 'react';
import PropTypes from 'prop-types';

const TopHeader = ({ children, name }) => (
  <div id={name} className="flex h-32 relative h-full bg-transparent">
    { children }
  </div>
);

TopHeader.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
};

export default TopHeader;
