import React, {
  useRef, useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import ComponentIterator from '../../../containers/ComponentIterator';
import RoutesContext from '../../../context/routesContext';

function LandingSpecial({ bgColor = "#ffffff", components }) {

  const { currentSlugName = false } = useContext(RoutesContext);

  return (
    <div key={currentSlugName} className="full-width" style={{ backgroundColor: `${bgColor}`}} >
      <div className="flex flex-wrap items-center justify-center">
        <ComponentIterator components={components} />
      </div>
    </div>
  );
}

LandingSpecial.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
};

LandingSpecial.defaultProps = {
  components: null,
};

export default LandingSpecial;
