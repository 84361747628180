import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useRoutes } from '../../../../context/routesContext';
import { useTheme } from '../context/themeContext';
import { useChat } from '../context/chatContext';
import LoadIcon from './LoadIcon';
import usePrivateChannels from '../hooks/usePrivateChannels';

import InviteButton from './InviteButton';
import InviteCallButton from './InviteCallButton';
import ChannelListItem from './ChannelListItem';
import PrivateChannel from './PrivateChannel';

import {
  getStringSet,
} from '../utils/constants';

const ChannelList = ({ match }) => {
  const { language } = useRoutes(match);
  const stringSet = getStringSet(language);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [channeName, setChanneName] = useState(null);
  const { theme } = useTheme();
  const { addToast } = useToasts();
  const { pendingVideoCalls, activeVideoCall, userData } = useChat();

  const {
    channelList, isLoadingChannels, pendingChannels,
  } = usePrivateChannels();

  useEffect(() => {
    if (pendingChannels && pendingChannels.length) {
      pendingChannels.map(channel => (
        addToast(<InviteButton key={channel.id} channel={channel} />, { appearance: 'info', id: channel.url })
      ));
    }
  }, [pendingChannels]);

  useEffect(() => {
    if (pendingVideoCalls && pendingVideoCalls.length) {
      pendingVideoCalls.map(call => (
        addToast(<InviteCallButton key={call.id} inviter={call.roomOwner} room={call.id} />, { appearance: 'info', id: call.id })
      ));
    }
  }, [pendingVideoCalls]);

  return (
    <>
      {isLoadingChannels
        ? (
          <div className="h-full flex flex-col justify-center text-center">
            <LoadIcon />
          </div>
        )
        : (
          <div className={`sm:w-200% h-full w-double-chat bg-${theme}-box trans-fast flex ${currentChannel ? 'sm:-translate-x-w-full -translate-x-w-chat' : 'translate-x-0'}`}>
            <div className="relative channels-list sm:w-full w-chat overflow-y-auto overflow-x-hidden relative scrollbar__streampage h-screen80 pb-2">
              {channelList && channelList.length ? channelList.map(channel => (
                <ChannelListItem channel={channel} onChannelSelect={() => { setChanneName(channel.name); setCurrentChannel(channel.url); }} key={channel.id} />
              ))
                : (
                  <div className="p-4 break-words leading-normal text-psworld-orange flex justify-between">
                    <span className="font-americaRegular italic">{stringSet.NO_PRIVATE_CHANNELS}</span>
                  </div>
                )
              }
              {
                pendingChannels && pendingChannels.length ? pendingChannels.map(channel => (
                  <div key={channel.id} className="flex relative uppercase text-sm font-americaMonoRegular px-4 h-8  py-5 justify-between items-center bg-psworld-box text-white border-b border-black cursor-pointer hover:bg-black trans">
                    <InviteButton channel={channel} />
                  </div>
                )) : null
              }
            </div>
            <div className="single-channel w-chat sm:w-full bg-black flex flex-col justify-between h-94%">
              {currentChannel && <PrivateChannel channelName={channeName} setActiveChannel={setCurrentChannel} activeChannel={currentChannel} />}
            </div>
          </div>
        )}
    </>
  );
};

ChannelList.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(ChannelList);
