import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ParagraphLineSM from './Text/ParagraphLineSM';
import translateObj from '../../util/translateObj';
import ShareMedia from './ShareMedia';

const AssetDescription = ({
  fileReadableName, url, assetSlugName, match, black, description,
}) => (

  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const downloadText = {
          en: 'Download',
          es: 'Descargar',
          ca: 'descarregar',
        };
        return (
          <React.Fragment>
            <div className="flex sm:flex-col md:flex-col justify-between items-center lg:w-2/3 xl:w-2/3 w-full absolute pin-b pin-l mt-4" style={{ left: '50%', transform: 'translateX(-50%)' }}>
              {translateObj(description, localization.language)
                ? <p className="text-xs font-tiemposRegular w-1/2 sm:w-full md:w-full text-left md:mb-4 sm:mb-4">{translateObj(description, localization.language)}</p>
                : <p className="text-xs font-tiemposRegular w-1/2 sm:w-full md:w-full text-left md:mb-4 sm:mb-4">{translateObj(fileReadableName, localization.language)}</p>
              }
              <div className="flex w-1/2 sm:w-full md:w-full items-center justify-end sm:justify-start md:justify-start">
                <a href={url} download className={`${black ? 'text-white' : 'text-black'} text-xxs`}>
                  <ParagraphLineSM size="text-xs" classes="cursor-pointer font-bold mt-0 font-americaMonoRegular text-xs uppercase" iconClass="font-icon icon-download1" text={downloadText} />
                </a>
                <span className="px-2">|</span>
                <div className="z-50">
                  <ShareMedia black={black} assetSlugName={assetSlugName} description={fileReadableName} />
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }
  </RoutesContext.Consumer>
);

AssetDescription.propTypes = {
  fileReadableName: PropTypes.shape({}),
  match: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired,
  black: PropTypes.bool,
};

AssetDescription.defaultProps = {
  fileReadableName: {},
  black: true,
};

export default withRouter(AssetDescription);
