/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import ParagraphLineSM from '../Text/ParagraphLineSM';
import DropDownMenu from './DropDownMenu';
import ActiveFilters from './ActiveFilters';
import FilterButton from '../Buttons/FilterButton';
import InputTextButton from '../InputTextButton';
import getLocalization from '../../../util/localization';
import RoutesContext from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const debounce = (func, delay) => {
  let inDebounce;
  return (...args) => {
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const FilterCheckbox = withStyles({
  root: {
    color: 'white',
    padding: 0,
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const styles = theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 0,
    overflow: 'visible',
  },
});

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-sm" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down text-white text-sm font-black" />;
class Filter extends React.Component {
  state = {
    open: true,
    filters: {},
    checkedItems: [],
    radioItem: '',
    hasActiveFilter: false,
    activeFilterText: {},
    isLoading: true,
    queryTag: '',
  }

  handleOnChange = debounce((query, lang) => {
    const { filters } = this.state;
    const { handleSearchInput } = this.props;
    handleSearchInput(query, lang, filters);
  }, 300)

  componentDidMount() {
    const {
      filter, location: { pathname }, match: { params }, radioTags,
    } = this.props;
    // If query param is set in Radio-Explore
    const selectedTag = params.tag;
    if (selectedTag) {
      this.setState({
        filters: {
          byTag: [selectedTag],
        },
        checkedItems: [selectedTag],
        queryTag: selectedTag,
      }, () => { this.setActiveFilters(); });
    }
    this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: { params } } = this.props;
    const selectedTag = params.tag;
    const { queryTag, filters } = this.state;
    if (queryTag && selectedTag && (selectedTag !== queryTag)) {
      this.setState({
        filters: {
          byTag: [selectedTag],
        },
        checkedItems: [selectedTag],
        queryTag: selectedTag,
      }, () => { this.setActiveFilters(); });
    }
  }

  setActiveFilters = () => {
    const { filters } = this.state;
    const { onHandleCollapsibleMenuSelect } = this.props;
    this.checkActiveFilter(filters);
    onHandleCollapsibleMenuSelect(filters);
  }

  checkActiveFilter = (filters) => {
    const checkedItems = Object.values(filters).reduce((accum, current) => accum.concat(current), []);
    if (checkedItems.length) {
      this.setState({
        hasActiveFilter: true,
      });
    } else {
      this.setState({
        hasActiveFilter: false,
      });
    }
  }

  onHandleChange = (value, filterType) => {
    const { radio, onHandleCollapsibleMenuSelect } = this.props;
    const { checkedItems, filters } = this.state;
    const index = checkedItems.findIndex((item => item === value));
    const updatedState = [...checkedItems];

    // this case is in press home filter to switch between content
    if (radio) {
      if (checkedItems.length) {
        checkedItems.pop();
      } else { checkedItems.push(value); }
      return (this.setState({
        radioItem: value,
        checkedItems,
      }, () => onHandleCollapsibleMenuSelect(value)));
    }

    // this case is in press-area filter to accept only one value
    if (index >= 0 && filterType !== 'byMaterial') {
      updatedState.splice(index, 1);
      this.updateFilters(value, filterType);
      return this.setState({ checkedItems: updatedState });
    }
    if ((filterType === 'byMaterial')) {
      const checkedMaterialIndex = checkedItems.findIndex((item => item === filters.byMaterial[0]));
      updatedState.splice(checkedMaterialIndex, 1);
    }
    if (filterType === 'byName') {
      const checkedNameIndex = checkedItems.findIndex((item => item === filters.byName[0]));
      updatedState.splice(checkedNameIndex, 1);
      filters.byTag = [];
    }
    updatedState.push(value);
    this.updateFilters(value, filterType);
    return this.setState({ checkedItems: updatedState });
  }

  updateFilters = (value, filterType) => {
    const { filters } = this.state;
    const { onHandleCollapsibleMenuSelect } = this.props;
    if (!filters[filterType]) {
      filters[filterType] = [value];
    } else {
      if (filters[filterType].includes(value) && (filterType !== 'byMaterial' || filterType !== 'byName')) {
        const index = filters[filterType].findIndex(item => item === value);
        filters[filterType].splice(index, 1);
      } else if (filterType === 'byMaterial' || filterType === 'byName') {
        filters[filterType] = [value];
      } else {
        filters[filterType].push(value);
      }
    }
    this.setState({
      filters,
    }, () => {
      this.checkActiveFilter(this.state.filters);
      onHandleCollapsibleMenuSelect(this.state.filters, filterType);
    });
  }

  getQueryVariable = (variable) => {
    const { location: { search } } = this.props;
    const vars = search.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === `?${variable}`) { return pair[1]; }
    }
    return (false);
  }

  render() {
    const {
      match, filter, text, hasSearchBar, slugname, key,
    } = this.props;
    const {
      open, filters, checkedItems, radioItem, hasActiveFilter, activeFilterText, isLoading,
    } = this.state;
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div className="w-full">
              <List key={`list-${filter.filterKey || 'xxxx'}`}>
                <div className="w-full text-white font-americaMonoRegular bg-black p-8 ">
                  <ListItem
                    className="flex pb-6 mb-6 border-dotted border-white border-b-1 justify-between "
                    onClick={() => {
                      this.setState({ open: !open });
                    }}
                  >
                    <ListItemText primary={<span className="font-americaMonoBold uppercase text-white">{translateObj(text, localization.language)}</span>} />
                    <span className="mr-4">{open ? <ExpandLess /> : <ExpandMore />}</span>
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit key={`collapse-${filter.filterKey || 'xxx'}`}>
                    <div className="flex flex-wrap ">
                      {filter.map((menuItem, i) => (
                        <React.Fragment key={menuItem.filterType + i}>
                          { menuItem.filterType === 'byEvent' || menuItem.showAs === 'button'
                            ? (
                              <React.Fragment>
                                <div className="w-full sm:hidden">
                                  {menuItem.filterHeading && <ParagraphLineSM classes="text-white m-0 pt-7 mb-6" text={menuItem.filterHeading} />}
                                  {menuItem.filterItems.map(item => (
                                    <FilterButton
                                      key={item.value}
                                      params={item}
                                      filterType={menuItem.filterType}
                                      filters={filters}
                                      radioItem={radioItem}
                                      onHandleButtonSelect={this.onHandleChange}
                                    />
                                  ))}
                                </div>
                                <div className="w-full sm:block md:hidden lg:hidden xl:hidden">
                                  {!isLoading && (
                                  <DropDownMenu
                                    menuTitle={menuItem.menuTitle}
                                    filterType={menuItem.filterType}
                                    menuItems={menuItem.filterItems}
                                    text={menuItem.filterHeading}
                                    checkedItems={checkedItems}
                                    filters={filters}
                                    onHandleChange={this.onHandleChange}
                                    slugname={slugname}
                                    selected={activeFilterText[localization.language]}
                                  />
                                  )}
                                </div>
                              </React.Fragment>
                            )
                            : menuItem.showAs === 'checkbox'
                              ? (
                                <div className="w-full sm:hidden">
                                  {menuItem.filterHeading && <ParagraphLineSM classes="text-white m-0 pt-7 mb-6" text={menuItem.filterHeading} />}

                                  {menuItem.filterItems.map(item => (
                                    <React.Fragment key={item.value}>
                                      <FilterCheckbox checked={checkedItems.includes(item.value)} onChange={() => this.onHandleChange(item.value, menuItem.filterType)} value="checkedA" />
                                      <span className="uppercase text-xs pr-4 pl-1" onClick={() => this.onHandleChange(item.value, menuItem.filterType)}>{item.text.en}</span>
                                    </React.Fragment>
                                  ))}
                                </div>
                              )
                              : !isLoading && (
                              <DropDownMenu
                                menuTitle={menuItem.menuTitle}
                                filterType={menuItem.filterType}
                                menuItems={menuItem.filterItems}
                                text={menuItem.filterHeading}
                                checkedItems={checkedItems}
                                filters={filters}
                                onHandleChange={this.onHandleChange}
                                slugname={slugname}
                                selected={activeFilterText[localization.language]}
                              />
                              ) }
                        </React.Fragment>
                      ))}
                    </div>
                  </Collapse>
                </div>
              </List>
              {hasSearchBar
              && (
              <div className="-mt-2 bg-white p-8 flex items-center sm:block md:block items-center rounded-bl-lg rounded-br-lg">
                <div className="bg-white flex-1">
                  <InputTextButton
                    name="searchQuery"
                    params={{ text: { es: 'buscar', en: 'search', ca: 'cerca' }, placeholder: { es: '', en: '', ca: '' }, searchIcon: 'font-icon icon-search' }}
                    handleOnChange={this.handleOnChange}
                  />
                </div>
              </div>
              )
              }
              <ActiveFilters
                filters={filters}
                hasActiveFilter={hasActiveFilter}
                radioItem={radioItem}
                onHandleChange={this.onHandleChange}
              />
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

Filter.propTypes = {
  hasSearchBar: PropTypes.bool,
  handleSearchInput: PropTypes.func,
  slugname: PropTypes.string,
  onHandleCollapsibleMenuSelect: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  radioTags: PropTypes.arrayOf(PropTypes.string),
};

Filter.defaultProps = {
  hasSearchBar: true,
  slugname: '',
  handleSearchInput: () => {},
  radioTags: null,
};
export default withRouter(withStyles(styles)(Filter));
