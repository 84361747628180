import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
// import { select } from 'async';
import Filter from '../Collapsible/Filter';
import getRadioShows from '../../../api/graphql/queries/getRadioShows';
import getRadioTags from '../../../api/graphql/queries/getRadioTags';
import getRadioTitles from '../../../api/graphql/queries/getRadioTitles';
import getRadioShowsEpisodes from '../../../api/graphql/queries/getRadioShowsEpisodes';
import ViewToggler from './ViewToggler';

import RadioShowCard from './RadioShowCard';
import RadioShowCardListItem from './RadioShowCardListItem';
import RadioEpisodeCard from './RadioEpisodeCard';
import RadioEpisodeListItem from './RadioEpisodeListItem';
import ParagraphLineSM from '../Text/ParagraphLineSM';
import NavigatorRadio from '../NavigatorRadio';


const menuFilter = {
  text: {
    es: 'OPCIONES DE VISUALIZACIÓN | FILTRAR CONTENIDO',
    en: 'OPTIONS OF VISUALISATION | FILTER CONTENT',
    ca: 'OPCIONS DE VISUALITZACIÓ | FILTRAR CONTINGUT',
  },
  filter: [
    {
      filterHeading: {
        es: 'POR TIPO DE SHOW',
        en: 'BY TYPE OF SHOW',
        ca: 'PER TIPUS DE SHOW',
      },
      filterType: 'byCategory',
      showAs: 'checkbox',
      menuTitle: {
        text: {
          es: 'TODOS LOS shows',
          en: 'ALL shows',
          ca: 'TOTS ELS shows',
        },
      },
      filterItems: [],
    },
    {
      filterHeading: {
        es: 'POR GENERO',
        en: 'BY GENRE',
        ca: 'Per GÈNERE',
      },
      filterType: 'byTag',
      showAs: 'button',
      menuTitle: {
        text: {
          es: 'TODOS LOS generos',
          en: 'ALL genres',
          ca: 'TOTS ELS gèneres',
        },
      },
      filterItems: [],
    },
  ],
};

class RadioExplore extends React.Component {
  state = {
    filteredEpisodes: [],
    filteredShows: [],
    programsByName: [],
    episodesByName: [],
    menu: {},
    isLoadingFilter: true,
    isLoading: true,
    radioTags: [],
    activeViewEpisodes: 'list',
    activeViewShows: 'list',
    selectedTags: [],
  };

  componentDidMount() {
    const radioTags = getRadioTags();
    const radioTitles = getRadioTitles();

    Promise.all([radioTags, radioTitles])
      .then((result) => {
        this.generateFilterTags(result[0]);
        this.setState({ radioTags: result[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generateFilterTags = (radioTags) => {
    const tags = [];
    const categories = [];
    // TODO add categories they might act as a tag as well
    for (let i = 0; i < radioTags.length; i++) {
      const tag = {
        category: 'radio',
        text: {
          es: radioTags[i].tagName_multilang.es,
          en: radioTags[i].tagName_multilang.en,
          ca: radioTags[i].tagName_multilang.ca,
        },
        value: radioTags[i].tagName_multilang.en,
        isCategory: radioTags[i].isCategory,
      };
      if (!tag.isCategory) {
        if (!tags.length || tags.some(el => el.value !== radioTags[i].tagName_multilang.en)) {
          tags.push(tag);
        }
      } else {
        if (!categories.length || categories.some(el => el.value !== radioTags[i].tagName_multilang.en)) {
          categories.push(tag);
        }
      }
    }
    menuFilter.filter[1].filterItems = tags;
    menuFilter.filter[0].filterItems = categories;
    this.setState({
      menu: menuFilter,
      isLoadingFilter: false,
    });
  }

  generatePrograms = (programTitles) => {
    const titles = [];
    for (let i = 0; i < programTitles.length; i++) {
      const title = {
        text: {
          es: programTitles[i].title_multilang.es,
          en: programTitles[i].title_multilang.en,
          ca: programTitles[i].title_multilang.ca,
        },
        value: programTitles[i].slug,
      };
      if (!titles.length || !titles.some(el => el.value === programTitles[i].slug)) {
        titles.push(title);
      }
    }
    titles.sort((a, b) => a.value.localeCompare(b.value));
    menuFilter.filter[1].filterItems = titles;
    this.setState({
      menu: menuFilter,
      isLoadingFilter: false,
    });
  }

  generateProgramTags = (programs) => {
    const tags = [];
    for (let i = 0; i < programs.length; i++) {
      programs[i].tags_array_multilang.forEach((item) => {
        const tag = {
          category: 'transparent',
          text: {
            es: item.es,
            en: item.en,
            ca: item.ca,
          },
          value: item.en,
        };
        if (!tags.length || !tags.some(t => t.value === item.en)) {
          tags.push(tag);
        }
      });
    }
    menuFilter.filter[2].filterItems = tags;
    this.setState({
      menu: menuFilter,
      isLoadingFilter: false,
    });
  }

  onHandleCollapsibleMenuSelect = (filters, filterType) => {
    const { radioTags } = this.state;
    // if program title already is selected, don't go to the database to get tags.
    // if (filters.byName && filters.byName.length && filterType === 'byTag') {
    //   return this.filterEpisodes(filters);
    // }

    // if program title is selected, go fetch by slug
    // if (filters.byName && filters.byName.length) {
    //   return this.filterByName(filters.byName[0]);
    // }

    this.fetchSelectedTag(filters);
  };

  filterEpisodes = (filters) => {
    const {
      filteredEpisodes, filteredShows, programsByName, episodesByName,
    } = this.state;
    const filterEpisodesByTag = [];
    const filterShowsByTag = [];

    filteredEpisodes.forEach((param) => {
      const tags = param.tags_array_multilang.reduce((accum, current) => {
        accum.push(current.en);
        return accum;
      }, []);
      if (filters.byTag.some(item => tags.includes(item))) {
        filterEpisodesByTag.push(param);
      }
    });
    filteredShows.forEach((param) => {
      const tags = param.tags_array_multilang.reduce((accum, current) => {
        accum.push(current.en);
        return accum;
      }, []);
      if (filters.byTag.some(item => tags.includes(item))) {
        filterShowsByTag.push(param);
      }
    });
    if (!filters.byTag.length) {
      return this.setState({
        filteredShows: programsByName,
        filteredEpisodes: episodesByName,
      });
    }
    this.setState({
      filteredEpisodes: filterEpisodesByTag,
      filteredShows: filterShowsByTag,
    });
  }

  fetchSelectedTag = (filters) => {
    const tagsAND = Object.keys(filters).reduce((accum, current) => accum.concat(filters[current]), []);
    this.setState({
      isLoading: true,
      selectedTags: tagsAND,
    });
    const episodes = [];
    const shows = [];
    if (tagsAND.length) {
      getRadioShows({ tagsAND })
        .then((radioProgramsAll) => {
          if (radioProgramsAll !== null) {
            radioProgramsAll.forEach((el) => {
              // we need to differentiate epidoses from shows
              if (el.parentSlug === null) {
                shows.push(el);
              } else {
                episodes.push(el);
              }
            });
            this.setState({ filteredEpisodes: episodes, filteredShows: shows, isLoading: false });
          } else {
            this.setState({ filteredEpisodes: [], filteredShows: [], isLoading: false });
          }
        });
    } else {
      this.setState({ filteredEpisodes: [], filteredShows: [] });
    }
  }

  filterByName = (slug) => {
    const getEpisodes = getRadioShowsEpisodes({ parentSlug: slug });
    const getShows = getRadioShows({ slug });
    Promise.all([getEpisodes, getShows])
      .then((result) => {
        if (result !== null) {
          const all = result[0].concat(result[1]);
          this.generateProgramTags(all);
          this.setState({
            filteredEpisodes: result[0], filteredShows: result[1], episodesByName: result[0], programsByName: result[1],
          });
        } else {
          this.setState({ filteredEpisodes: [], filteredShows: [] });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearchInput = (query, lang, filters) => {
    const episodes = [];
    const shows = [];
    if (query && query.length >= 3) {
      getRadioShows({ search: query })
        .then((result) => {
          if (result) {
            result.forEach((el) => {
              // we need to differentiate epidoses from shows
              if (el.parentSlug === null) {
                shows.push(el);
              } else {
                episodes.push(el);
              }
            });
            this.setState({ filteredEpisodes: episodes, filteredShows: shows });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (!query) {
      const tagsAND = Object.keys(filters).reduce((accum, current) => accum.concat(filters[current]), []);
      if (tagsAND.length) {
        this.onHandleCollapsibleMenuSelect(filters);
      } else {
        this.setState({ filteredEpisodes: [], filteredShows: [] });
      }
    }
  };

  updateView = (view, type) => {
    if (type === 'episode') {
      this.setState({ activeViewEpisodes: view });
    } else {
      this.setState({
        activeViewShows: view,
      });
    }
  }

  renderRadioShows = () => {
    const { activeViewShows } = this.state;
    const headLine2 = {
      es: 'Shows',
      en: 'Shows',
      ca: 'Shows',
    };
    return (
      <>
        <ParagraphLineSM classes="uppercase font-bold text-xxs w-full my-4" text={headLine2} />
        <ViewToggler type="show" handleUpdateView={this.updateView} activeView={activeViewShows} />
        {this.renderShowCards()}
      </>
    );
  };

  renderShowCards = () => {
    const { filteredShows, activeViewShows } = this.state;
    if (activeViewShows === 'grid') {
      return (
        <div className="md:-mx-2 lg:-mx-2 xl:-mx-2 flex flex-wrap w-full">
          { filteredShows.map(show => (
            <div key={show.id} className="sm:w-full md:w-1/2 smlg:w-1/4 lg:w-1/3 xl:w-1/4 py-2 px-2">
              <RadioShowCard
                favouriteStar={false}
                {...show}
              />
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="pt-2 w-full">
        {filteredShows.map(show => (
          <RadioShowCardListItem
            key={show.id}
            {...show}
          />
        ))}
      </div>
    );
  };

  renderRadioEpisodes = () => {
    const { activeViewEpisodes } = this.state;
    const headLine1 = {
      es: 'Episodios',
      en: 'Episodes',
      ca: 'Episodis',
    };
    return (
      <>
        <ParagraphLineSM classes="uppercase font-bold text-xxs w-full my-4" text={headLine1} />
        <ViewToggler type="episode" handleUpdateView={this.updateView} activeView={activeViewEpisodes} />
        {this.renderEpisodeCards()}
      </>
    );
  };

  renderEpisodeCards = () => {
    const { filteredEpisodes, activeViewEpisodes } = this.state;
    if (activeViewEpisodes === 'grid') {
      return (
        <div className="md:-mx-2 lg:-mx-2 xl:-mx-2 flex flex-wrap w-full">
          {filteredEpisodes.map(show => (
            <div key={show.id} className="sm:w-full md:w-1/2 smlg:w-1/4 lg:w-1/3 xl:w-1/4 py-2 px-2 sm:px-0">
              <RadioEpisodeCard
                {...show}
              />
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="pt-2 w-full sm:px-0">
        {filteredEpisodes.map(show => (
          <RadioEpisodeListItem
            key={show.id}
            {...show}
          />
        ))}
      </div>
    );
  };

  renderNoMatch = () => {
    const { isLoading } = this.state;
    return !isLoading ? <p className="w-full px-2 pb-4">Choose filter to get some result.</p> : null;
  };

  render() {
    const { slug, hasFilters } = this.props;
    const {
      filteredEpisodes, filteredShows, isLoading, isLoadingFilter, menu, radioTags, selectedTags,
    } = this.state;

    return (
      <React.Fragment>
        <div className="mt-3 md:mt-4 sm:mt-4 w-full">
          <NavigatorRadio margin="md:-mt-1 sm:-mt-1 mb-1" />
          {!isLoadingFilter && hasFilters && <Filter {...menu} slugname="radio-shows" onHandleCollapsibleMenuSelect={this.onHandleCollapsibleMenuSelect} handleSearchInput={this.handleSearchInput} slug={slug} />}
          {
         filteredEpisodes
          && (
          <>
            <div className="flex w-full flex-wrap">{filteredEpisodes.length ? this.renderRadioEpisodes() : null}</div>
          </>
          )
        }
          {
         filteredShows
          && (
          <>
            <div className="flex w-full flex-wrap">{filteredShows.length ? this.renderRadioShows() : null}</div>
          </>
          )
        }
          {!isLoading && !filteredEpisodes.length && !filteredShows.length && selectedTags.length ? (
            <div className="py-2 w-full flex justify-center mt-2">
              <span className="font-americaMonoBold text-black text-center text-14 uppercase border-b-2 border-black py-1">
              No se ha encontrado ningun contenido
              </span>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

RadioExplore.propTypes = {
  hasFilters: PropTypes.bool,
  slug: PropTypes.string,
  menu: PropTypes.shape({}),
};

RadioExplore.defaultProps = {
  hasFilters: false,
  slug: null,
  menu: {},
};

export default withRouter(RadioExplore);
