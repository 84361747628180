import React from 'react';
import PropTypes from 'prop-types';

import { Link, withRouter } from 'react-router-dom';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const ButtonMainMenu = ({
  name,
  mainParent,
  params: { text, url, icon },
  match,
}) => (
    <RoutesContext.Consumer>
      {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const Button = () => (
            <span
              className={`inline-block border-black ${mainParent === 'mobilemenu' ? 'mr-6 mt-3 w-165px md:mr-14 sm:mr-14 xs:w-auto xs:px-4 py-2' : 'py-3 md:py-2'} flex justify-center trans lg:hover:bg-white xl:hover:bg-white items-center font-americaMonoBold  px-5 md:px-4 rounded-full text-14 uppercase text-black w-165px
            ${name}
            ${mainParent === 'megamenu' ? 'trans bg-black text-white hover:text-black sm:bg-gray200 md:bg-gray200' : ''} `}
            >
              {icon && <span className={`${icon} text-xl pr-3`} />}
              {translateObj(text, localization.language)}
            </span>
          );

          const classNames = 'inline-block text-black  pin-r mr-0 sm:w-full sm:my-8 md:my-8 ml-2 sm:ml-0 md:mr-0 md:ml-0 -mt-8px flex justify-center';
          return (
            <React.Fragment>
              <Link
                to={url}
                className={`${mainParent === 'mobilemenu' ? 'xl:hidden lg:hidden tickets-button' : classNames}`}
                style={{ top: '1rem' }}
              >
                <Button />
              </Link>
            </React.Fragment>
          );
          // return (
          //   <React.Fragment>
          //     {
          //       /^https?:\/\//.test(url)
          //         ? (
          //           <a href={url} target="_blank" className={classNames} rel="noopener noreferrer" style={{ top: '1rem' }}>
          //             <Button />
          //           </a>
          //         )
          //         : (
          //           <Link to={`${localization.path}${url}`} className={classNames} style={{ top: '1rem' }}>
          //             <Button />
          //           </Link>
          //         )
          //     }
          //   </React.Fragment>
          // );
        }
      }
    </RoutesContext.Consumer>
  );

ButtonMainMenu.propTypes = {
  name: PropTypes.string.isRequired,
  params: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/require-default-props
  mainParent: PropTypes.string,
};
ButtonMainMenu.defaultProps = {
  mainParent: '',
};

export default withRouter(ButtonMainMenu);
