import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Link from '../../util/link';
import translateObj from '../../util/translateObj';
import MenuPopUpLoginButton from './Login/MenuPopUpLoginButton';
import { useUser } from '../../context/userProfileContext';

const styles = theme => ({
  paper: {
    // right: 0,
    // left: "auto !important",
  },
});

const formTextInComponent = {
  submitTitle: {
    en: 'sign in',
    es: 'iniciar sesión',
    ca: 'iniciar sessió',
    pt: 'iniciar sessāo',
  },
  registerPreTitle: {
    es: '¿No tienes cuenta?',
    en: 'Don\'t have an account?',
    ca: 'No tens compte?',
    pt: 'Não tem conta?',
  },
  registerTitle: {
    es: 'Registrate',
    en: 'Register',
    ca: 'Registra"t',
    pt: 'Registe-se',
  },

  faqsTitle: {
    es: 'AYUDA',
    en: 'HELP',
    ca: 'AJUDA',
    pt: 'AJUDA',
  },
  logoutTitle: {
    es: 'DESCONECTATE',
    en: 'LOG OUT',
    ca: 'DESCONECTA"T',
    pt: 'LOG OUT',
  },
  favoritesTitle: {
    es: 'FAVORITOS',
    en: 'FAVORITES',
    ca: 'FAVORITS',
    pt: 'FAVORITES',
  },
  userProfileTitle: {
    es: 'PERFIL DE USUARIO',
    en: 'USER PROFILE',
    ca: 'PERFIL D"USUARI',
    pt: 'USER PROFILE',
  },
};

const UserMenuPopup = ({
  classes,
  ButtonLink,
  link,
  localization,
  language,
  loadTokenForLS,
  border,
  formText,
  sourceWeb,
  userName,
  token,
  history,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tokenState, setTokenState] = useState(null);
  const user = useUser();

  useEffect(() => {
    setTokenState(token);
  }, [token, userName]);

  // popover user logic

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (lang, loadTokenForLS, sourceWeb = '') => {
    user.logoutUser();
    if (typeof localStorage !== 'undefined') {
      loadTokenForLS();
      handleClose();
      history.push(`/${lang}${sourceWeb && `/${sourceWeb}`}`);
    }
  };

  const userMenuPopUpRenderer = () => {
    // popover logic in render
    const open = Boolean(anchorEl);

    if (tokenState) {
      return (
        <>
          {/* <div className={`${token && 'hidden'} sm:block md:block`}>
            <Link to={`${link.link}`} localizationpath={`${localization.path}`} className="flex no-underline">
              <ButtonLink link={link} border={border} localization={localization} />
            </Link>
          </div> */}
          <div
            className="cursor-pointer sm:hidden md:hidden"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handleClick}
            onMouseLeave={handleClose}
          >
            <ButtonLink link={link} localization={localization} border={border} />
            <Popover
              id="mouse-over-popover"
            // id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{ paper: classes.paper }}
              disableRestoreFocus
            >

              <div className="max-w-xs p-8 text-white bg-black">
                {/* <>
                  <div className={`${!sourceWeb && 'border-b-1 border-white border-dotted'} -mx-5`}>
                    <div className="py-4 mx-5 border-white border-dotted  border-b-1">
                      <Link to={`/user-profile${sourceWeb && `-${sourceWeb}`}`} localizationpath={`${localization.path}`}>
                        <div className="flex justify-between text-white uppercase cursor-pointer font-americaMonoRegular text-12">
                          <span className="pr-20" onClick={handleClose}>{translateObj(formText.userProfileTitle, language)}</span>
                          <span>></span>
                        </div>
                      </Link>
                    </div>
                    {!sourceWeb && (
                    <div className="py-4 mx-5 ">
                      <Link to="/user-favorites" localizationpath={`${localization.path}`}>
                        <div className="flex justify-between text-white uppercase cursor-pointer font-americaMonoRegular text-12">
                          <span className="pr-20" onClick={handleClose}>{translateObj(formText.favoritesTitle, language)}</span>
                          <span>></span>
                        </div>
                      </Link>
                    </div>
                    )}
                  </div>
                  <div className="pt-4">
                    <button
                      className="w-full px-4 py-2 text-center text-black uppercase rounded-full cursor-pointer font-americaMonoRegular bg-red tracking-submenu text-12"
                      onClick={() => {
                        handleLogout(language, loadTokenForLS, sourceWeb);
                      }}
                    >
                      {translateObj(formText.logoutTitle, language)}
                    </button>
                  </div>
                </> */}
              </div>
            </Popover>
          </div>
        </>
      );
    }
    return (
      <>
        <MenuPopUpLoginButton
          textColor={!sourceWeb ? 'white' : ''}
          sourceWeb={sourceWeb}
          language={language}
          icon={link.icon}
        />
      </>
    );
  };


  return (
    <>
      {userMenuPopUpRenderer()}
    </>
  );
};

UserMenuPopup.propTypes = {
  border: PropTypes.string,
  sourceWeb: PropTypes.string,
  link: PropTypes.shape(),
  localization: PropTypes.shape(),
  userName: PropTypes.string,
  token: PropTypes.string,
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  ButtonLink: PropTypes.func.isRequired,
  // history: PropTypes.shape().isRequired,
  history: PropTypes.shape(),
  formText: PropTypes.shape(),
};

UserMenuPopup.defaultProps = {
  border: '',
  sourceWeb: '',
  link: {},
  localization: {},
  userName: '',
  token: '',
  language: 'en',
  formText: formTextInComponent,
  history: undefined,
};

export default withStyles(styles)(UserMenuPopup);
