import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translateObj from '../../../util/translateObj';
import userWebVerification from '../../../api/graphql/mutations/userWebVerification';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

// add translations for text

const resendVerificationText = {
  en: 'Reenvía verificación',
  es: 'Reenvía verificación',
};

const checkYourEmailText = {
  es: 'Confirma tu correo electrónico',
  en: 'Confirma tu correo electrónico',
};

class VerificationBanner extends Component {
  responsiveUtility = React.createRef();

  bannerRef = React.createRef();

  state = {
    userActivationRequestSent: false,
    isUserVerified: null,
    userEmail: '',
    isBannerClosed: false,
    bannerHeight: 0,
  }

  componentDidMount() {
    this.renderBanner();
  }

  componentDidUpdate(prevProps, prevState) {
    const event = new CustomEvent('checkBodyTopSpacing');
    window.dispatchEvent(event);
    const { context } = this.props;
    if (context && prevProps.context !== context) {
      this.setBannerState();
    }
  }

  getBannerHeight = () => {
    const event = new CustomEvent('checkBodyTopSpacing');
    window.dispatchEvent(event);
    const banner = this.bannerRef.current;
    if (banner && banner.current) {
      this.setState({ bannerHeight: banner.offsetHeight });
      this.dispatchCustomEvent();
    }
  };

  renderBanner = () => {
    const { isBannerClosed } = this.state;
    if (!isBannerClosed) {
      this.getBannerHeight();
    }
  }

  setBannerState = () => {
    const { sendUserInfoBack } = this.props;
    const { isUserVerified, userEmail, isActivationBarOpen } = this.props.context;
    const heightAdj = (userEmail && isUserVerified === false && isActivationBarOpen) ? '145px' : '105px';
    this.setState({
      isUserVerified, userEmail, headerHeight: heightAdj, isBannerClosed: !isActivationBarOpen,
    }, () => { sendUserInfoBack(isUserVerified, userEmail, heightAdj); });
  }

  closeBanner = () => {
    const { handleActivationBar } = this.props.context;
    handleActivationBar(false);
    this.setState({ isBannerClosed: true });
    const event = new CustomEvent('checkBodyTopSpacing', {
      detail: {
        closed: true,
      },
    });
    window.dispatchEvent(event);
  }

  activateUser = () => {
    const { userEmail } = this.props.context;
    if (userEmail) {
      userWebVerification({ email: userEmail }).then((res) => {
        if (res) {
          this.setState({ userActivationRequestSent: true });
          const event = new CustomEvent('checkBodyTopSpacing');
          window.dispatchEvent(event);
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - webVerification failed to submit!`);
        }
      });
    }
  }

  render() {
    const {
      userEmail, isUserVerified, userActivationRequestSent, isBannerClosed,
    } = this.state;
    const { context: { language = 'en' } = {} } = this.props;
    return (
      <>
        {(!isBannerClosed && userEmail && isUserVerified === false) ? (
          <>
            <PSResponsiveUtility ref={this.responsiveUtility} deviceWidthCallback={this.getBannerHeight} />
            <div id="banner" ref={this.bannerRef} className="bg-red w-full p-5 pr-5 laptop:pr-10 laptop:pr-12 desktop:p-6 desktop:pr-15 flex items-center z-radio justify-between">
              <div className="pl-1 flex items-center justify-start">
                <span className="font-icon icon-caution-circle font-black text-white text-26" />
                <span className="ml-1 tablet:ml-2 text-sm laptop:text-18 desktop:text-20 font-stratos font-bold text-white mr-2">{translateObj(checkYourEmailText, language)}</span>
              </div>
              <div className="flex items-center justify-end">
                {(userEmail && isUserVerified === false) && !userActivationRequestSent && (
                  <button
                    type="button"
                    className="flex justify-center trans items-center font-stratos text-sm laptop:text-base desktop:text-sm border-5 border-white font-bold hover:text-red hover:border-red hover:bg-white px-6 py-6px text-white"
                    onClick={this.activateUser}
                  >
                    <span>{translateObj(resendVerificationText, language)}</span>
                  </button>
                )}
                <button type="button" className="ml-3 tablet:ml-4 laptop:ml-8 desktop:ml-7" onClick={this.closeBanner}>
                  <span className="font-icon icon-close font-black text-white text-18 laptop:text-20" />
                </button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

VerificationBanner.propTypes = {
  context: PropTypes.shape().isRequired,
  sendUserInfoBack: PropTypes.func.isRequired,
};

export default VerificationBanner;
