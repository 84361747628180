/* eslint-disable react/forbid-prop-types */

import React, {useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import PayWithStoredCard from './PayWithStoredCardStripe';
import api from '../Payments/psWorldPaymentsApi';

const PsWorldStripeCardFormWrapper = (props) => {
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    api.getPublicStripeKey().then(key => setStripe(loadStripe(key)));
  }, []);

  return (
    <Elements stripe={stripe}>
      <PayWithStoredCard {...props} />
    </Elements>
  );
};

// PsWorldStripeCardFormWrapper.propTypes = {
//   customerInfo: PropTypes.object.isRequired,
//   product: PropTypes.object.isRequired,
// };

export default PsWorldStripeCardFormWrapper;
