import React from 'react';
import { PropTypes } from 'prop-types';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

class RadioPlayButton extends React.Component {
  state = {
    iAmPlaying: false,
  }

  togglePlay = () => {
    const { iAmPlaying } = this.state;
    this.setState({
      iAmPlaying: !iAmPlaying,
    });
  }


  render() {
    const {
      togglePlaying, playing, channel, radioActive, size, color, showPlayer,
    } = this.props;
    const { iAmPlaying } = this.state;
    // if channel is passed, the radio play correspond to a radio tunner card, if not it belong to the player
    const RenderButton = () => {
      if (channel) {
        if ((playing && (channel === radioActive))) {
          return <span className={`font-icon icon-pause text-${color} text-${size} cursor-pointer`} />;
        }
        return <span className={`font-icon icon-player text-${color} text-${size} cursor-pointer`} />;
      }
      if (playing) return <span className={`font-icon icon-pause text-${color} text-${size} cursor-pointer`} />;
      return <span className={`font-icon icon-player text-${color} text-${size} cursor-pointer`} />;
    };

    return (
      <div onClick={() => { togglePlaying(channel); this.togglePlay(); showPlayer(); }}>
        <RenderButton />
      </div>
    );
  }
}

RadioPlayButton.propTypes = {
  playing: PropTypes.bool.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

RadioPlayButton.defaultProps = {
  size: 52,
  color: 'white',
};

export default withRadioPlayerContext(RadioPlayButton);
