import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import RoutesContext from '../../../../../context/routesContext';
import getLocalization from '../../../../../util/localization';
import translateObj from '../../../../../util/translateObj';
import SimpleButton from '../../../Buttons/SimpleButton';

const GiftFormsy = ({ match, giftCallback, allGifts }) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const giftName = { en: '1 año de suscripción', es: '1 año de suscripción' };
      const noGiftsText = { en: 'Todavía no has regalado Rockdelux', es: 'Todavía no has regalado Rockdelux' };
      return (
        <div>
          <div className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5">
            <div className="font-stratos text-20 text-black col-span-4 tablet:col-span-9 laptop:col-span-5 tablet:col-start-2 laptop:col-start-2">
              {allGifts && allGifts.length > 0
                ? (
                  <div className="flex flex-col w-full">
                    {allGifts.map(gift => (
                      <div className="font-stratos text-base text-black font-normal mb-2">
                        <span className="font-icon icon-arrow text-14 text-red pr-6" />
                        {translateObj(giftName, localization.language)}
                        <span className="text-14 pl-2 text-grey">{`(${moment(Number(gift.datePayment)).format('DD.MM.YYYY')})`}</span>
                      </div>
                    ))}
                  </div>
                )
                : <p>{translateObj(noGiftsText, localization.language)}</p>
              }
            </div>
            <div
              className="col-span-3 laptop:col-span-2 mt-3 laptop:mt-1 col-end-5 tablet:col-end-11 laptop:col-end-9 row-start-2 cursor-pointer flex items-center justify-end"
              onClick={() => giftCallback()}
            >
              <SimpleButton
                isDisabled
                isLowercase
                isFullWidth
                theme={{
                  base: 'red',
                  hover: 'red',
                }}
                text={{
                  en: 'Regalar Rockdelux',
                  es: 'Regalar Rockdelux',
                }}
              />
            </div>
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

export default GiftFormsy;

GiftFormsy.propTypes = {
  match: PropTypes.shape({}).isRequired,
  giftCallback: PropTypes.func.isRequired,
  allGifts: PropTypes.arrayOf(PropTypes.shape({})),
};

GiftFormsy.defaultProps = {
  allGifts: null,
};
