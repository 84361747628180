// Dependencies

import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import getPostsList from '../../api/graphql/queries/getPostsList';
import translateObj from '../../util/translateObj';
import { useRoutes } from '../../context/routesContext';
import imageModifier from './helpers/imageModifier';

const Latest = ({
  title,
  layout,
  category,
  match,
}) => {
  const { language } = useRoutes(match);
  const [articles, setArticles] = useState();
  const [deviceScreen, setDeviceScreen] = useState();
  const responsiveUtility = useRef();

  const handleResize = (screenSize) => {
    setDeviceScreen(screenSize);
  };

  const fetchPostsByCategory = () => {
    getPostsList(category, 0, 5)
      .then((result) => {
        if (result && result.length) {
          const articlesList = [];
          result.forEach((r) => {
            articlesList.push({
              title: r.postDescription.title,
              description: r.postDescription.description,
              slugName: r.slugName,
              date: r.postDescription.date,
              image: r.postDescription.image,
              cta: r.postDescription.cta,
              tags: r.postDescription.tags,
              updatedAt: r.updatedAt,
            });
          });
          setArticles(articlesList);
        }
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      });
  };

  useEffect(() => {
    fetchPostsByCategory();
  }, []);

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={handleResize} rdlSizes />
      <div className={`w-full ${deviceScreen === 'mobile' ? 'flex justify-center tablet:border-t-4 tablet:border-b-4 border-black border-solid items-center pb-7' : 'pb-8'}`}>
        <div className={`w-full ${deviceScreen === 'mobile' ? '' : 'float-right'}`}>
          <h3 className="text-semibold text-36 text-red pb-4 laptop:pb-12 font-stratos tracking-1.7px">
            {title.en}
          </h3>
          <div className="tablet:border-t-2 border-black border-solid">
            {articles && articles.map(art => (
              <Link to={art.slugName} key={art.slugName} className="text-black hover:text-red flex border-t-2 laptop:border-t-0 laptop:border-b-2 border-black border-solid py-5 text-black">
                <div className="flex flex-col flex-1 font-stratos">
                  <p className="text-18 pb-4 font-medium mr-2">{translateObj(art.title, language)}</p>
                  {/* <p className="text-14 font-semilight">{translateObj(art.description, language)}</p> */}
                </div>
                <div className="w-1/2">
                  {
                    art.image.hasOwnProperty('en') && art.image.en !== '' && art.image.en !== null ? (
                      <img src={imageModifier(translateObj(art.image), '600x375')} alt={art.title.en} className="w-full bg-grey" />
                    ) : (
                      <div className="w-full bg-grey" style={{ paddingBottom: '62.256%' }} />
                    )
                  }
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

Latest.propTypes = {
  title: PropTypes.shape(),
  layout: PropTypes.string,
  category: PropTypes.string,
  match: PropTypes.shape().isRequired,
};

Latest.defaultProps = {
  title: {
    en: '',
    es: '',
  },
  layout: 'vertical',
  category: 'noticias',
};

export default withRouter(Latest);
