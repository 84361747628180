import React from 'react';
import { PropTypes } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const TaggedItem = ({
  color,
  text,
  parentComponent,
  overlap,
  match,
  link,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const colorIs = color !== 'black' ? color : '#ff4646';
      const content = (
        <div className={`flex flex-row justify-start ${overlap ? '-mt-4 tablet:-mt-5 laptop:-mt-6 mb-6 tablet:mb-10 laptop:mb-16' : 'mb-4 tablet:mb-6'}`}>
          <div
            className={` ${link ? 'hover:bg-red hover:text-white' : ''}
              bg-white w-auto h-auto tablet:min-w-taggedItemTablet laptop:min-w-0 text-center
              border-solid border-4 tablet:border-6 laptop:border-6
              p-px px-4 tablet:p-1 laptop:px-6 laptop:py-2 flex items-center justify-center
            `}
            style={{ minHeight: '2rem', color: colorIs, borderColor: colorIs }}
          >
            <h2 className="font-stratos font-bold text-14 tracking-mid tablet:text-18">
              {translateObj(text, localization.language)}
            </h2>
          </div>
        </div>
      );

      if (link) {
        return (
          <Link to={link} className="cursor-pointer">
            {content}
          </Link>
        );
      }

      return content;
    }}
  </RoutesContext.Consumer>
);

export default withRouter(TaggedItem);

TaggedItem.propTypes = {
  color: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
    ca: PropTypes.string,
  }),
  parentComponent: PropTypes.string,
  overlap: PropTypes.bool,
  match: PropTypes.shape().isRequired,
  link: PropTypes.string,
};

TaggedItem.defaultProps = {
  color: 'black',
  text: {
    en: '',
    es: '',
    ca: '',
  },
  parentComponent: '',
  overlap: false,
  link: null,
};
