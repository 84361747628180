import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';

class SimpleVideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
    };
    this.video = React.createRef();
    this.toggle = this.toggle;
  }

  componentDidMount() {
  }

  toggle = () => {
    const { playing } = this.state;
    if (playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  play() {
    this.setState({ playing: true });
    this.video.current.play();
  }

  pause() {
    this.setState({ playing: false });
    this.video.current.pause();
  }

  render() {
    const {
      poster, url, icon1,
    } = this.props;

    const { playing } = this.state;

    // TODO decide whether using caption or not
    const video = (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video ref={this.video} className="w-full" poster={poster}>
        <source src={url} type="video/mp4" />
      </video>
    );

    return (
      <div className="w-full relative">
        <div className="absolute pin flex items-center justify-center z-10" onClick={this.toggle.bind(this)}>
                {playing ? null : <span className={`sm:w-16 sm:h-16 ${icon1}`} />}
              </div>
        {playing ? null : <img className="absolute pin" src={poster} />}
        <video ref={this.video} className="w-full" poster={poster}>
                <source src={url} type="video/mp4" />
              </video>
      </div>
    );
  }
}


SimpleVideoPlayer.propTypes = {
  poster: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon1: PropTypes.string.isRequired,
};

export default withRouter(SimpleVideoPlayer);
