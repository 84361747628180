import { useState, useEffect, useRef } from 'react';

const RDL_API = 'AIzaSyAxGEoOX0JH4b9_b7kiKzDPdl2QVC-4-jw';

const loadGoogleMapsApi = (callback) => {
  const existingScript = document.getElementById('googlemaps');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${RDL_API}&libraries=places&language=es`;
    script.id = 'googlemaps';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const getAddressComponentValues = (components) => {
  console.log("🚀 ~ file: useAddress.jsx ~ line 20 ~ getAddressComponentValues ~ components", components)
  const address = {};
  components.forEach((component) => {
    const componentType = component.types[0];
    switch (componentType) {
      case 'street_number': {
        address.streetNumber = component.long_name;
        break;
      }
      case 'address1': {
        address.address1 = component.long_name;
        break;
      }
      case 'route': {
        address.route = component.short_name;
        break;
      }

      case 'postal_code': {
        address.postCode = component.long_name;
        break;
      }
      case 'locality':
        address.city = component.long_name;
        break;

      case 'administrative_area_level_1': { // Comunidad
        address.administrativeAreaLevel1 = component.long_name;
        break;
      }

      case 'administrative_area_level_2': { // Province
        address.administrativeAreaLevel2 = component.long_name;
        break;
      }

      case 'country':
        address.country = component.long_name;
        address.countryShort = component.short_name;
        break;

      default: address[componentType] = component.long_name;
    }
  });
  return address;
};

const useAddress = () => {
  const [autocomplete, setAutocomplete] = useState();
  const [values, setValues] = useState({});
  const addressRef = useRef();

  const fillInAddress = () => {
    const place = autocomplete?.getPlace();
    const addressComponents = getAddressComponentValues(place.address_components);
    setValues(addressComponents);
  };

  useEffect(() => {
    loadGoogleMapsApi(() => {
      if (!window.google || !addressRef.current) return;
      const autocompleteService = new window.google.maps.places.Autocomplete(addressRef.current, {
        fields: ['address_components'],
        types: ['address'],
      });
      setAutocomplete(autocompleteService);
    });
  }, []);

  useEffect(() => {
    if (!autocomplete) return;
    autocomplete.addListener('place_changed', fillInAddress);
  }, [autocomplete]);

  return ({ addressRef, values });
};

export default useAddress;
