import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import RoutesContext from '../../../context/routesContext';
import SubscriptionTag from '../SubscriptionTag';

class CoverStoryPostPage extends Component {
  static contextType = RoutesContext

  state = {
    deviceScreen: null,
  };

  responsiveUtility = React.createRef();

  componentDidMount() {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.handleResize(deviceScreen);
    this.setParentComponent();
  }

  componentDidUpdate() {
    this.setParentComponent();
  }


  handleResize = (deviceScreen) => {
    this.setState({ deviceScreen });
  }

  setParentComponent = () => {
    const { components } = this.props;
    if (components && components.length > 0) {
      components.forEach((component) => {
        Object.assign(component, { parentComponent: 'CoverStoryPostPage' });
        if (component.type === 'TaggedItem') {
          Object.assign(component, { overlap: true });
        }
        if (component.type === 'SharingBlock') {
          Object.assign(component, { isArticleHeader: true });
        }
        if (Object.keys(component).includes('text')) {
          if (Object.keys(component.text).length > 0 && component.text.en) {
            if (['<p></p>', '<p><br></p>'].includes(component.text.en)) {
              Object.assign(component, { hideWhileEmptyText: true });
            }
          }
        }
      });
    }
  }

  render() {
    const { currentSlugName, currentRolesAllowed: { rolesAllowed } } = this.context;
    const { deviceScreen } = this.state;
    let { components } = this.props;

    const filteredComponents = components.filter(component => !component.hideWhileEmptyText || component.hideWhileEmptyText !== true);
    components = filteredComponents;

    let header = {};
    let tag = {};
    let preTitle = {};
    let title = {};
    let credits = {};
    let social = {};
    let enhanced = {};
    let related = {};
    let comments = {};
    let body = {};

    if (components && components.length > 0) {
      header = components.find(component => component.type === 'MediaDescription' && typeof component.layout !== 'undefined' && ['head', 'head_alternative'].includes(component.layout)) || {};
      tag = components.find(component => component.type === 'TaggedItem') || {};
      preTitle = components.find(component => component.type === 'TitleLineH2') || {};
      title = components.find(component => component.type === 'TitleLineH1') || {};
      credits = components.find(component => component.type === 'NormalBodyText' && component.creditslayout === true) || {};
      social = components.find(component => component.type === 'SharingBlock') || {};
      enhanced = components.find(component => component.type === 'NormalBodyText' && component.enhanced === true) || {};
      comments = components.find(component => component.type === 'CommentsBox') || {};
      related = components.find(component => component.type === 'GroupedItems') || {};

      const bodyItems = [];
      components.forEach((item) => {
        if (![header, tag, preTitle, title, credits, social, enhanced, comments, related].includes(item)) {
          bodyItems.push(item);
        }
      });
      body = bodyItems;
    }

    return (
      <div key={currentSlugName} className="full-width">
        <PSResponsiveUtility ref={this.responsiveUtility} deviceScreenCallback={this.handleResize} rdlSizes />
        <div className="w-full">
          <div className="w-full">
            {components && header && (
              <ComponentIterator components={[header]} />
            )}
          </div>

          <div className="px-4 tablet:px-11 laptop:px-90px desktop:mx-auto max-w-rdl-xxl">
            <div className="w-full flex flex-row justify-start tablet:justify-center items-center">
              <div className="z-10">
                {components && tag && (
                  <ComponentIterator components={[tag]} />
                )}
              </div>
            </div>
            <div key="grid-module" className="grid grid-cols-6 tablet:grid-cols-12 gap-3 laptop:gap-4 relative">
              <div className="col-span-6 tablet:col-start-3 tablet:col-end-12">
                {components && (preTitle || title) && (
                  <ComponentIterator components={[preTitle, title]} />
                )}
              </div>
            </div>
          </div>

          <div className="mt-10 tablet:-mt-12">
            <div key="grid-module" className="grid grid-cols-6 tablet:grid-cols-12 gap-3 laptop:gap-4">
              <div key="credits" className="col-span-4">
                <div className="flex items-end justify-end h-160px laptop:h-224px pl-4 tablet:pl-11 pr-3 tablet:pr-6 laptop:pl-90px pb-5 tablet:pb-0 laptop:pb-6 border-solid border-red border-r-12 tablet:border-r-16 laptop:border-r-22 border-t-12 tablet:border-t-16 laptop:border-t-22 border-b-12 tablet:border-b-16 laptop:border-b-22">
                  {components && credits && (
                    <ComponentIterator components={[credits]} />
                  )}
                </div>
                {deviceScreen !== 'mobile' && (
                  <div className="hidden tablet:block pl-4 tablet:pl-11 tablet:pt-6 laptop:pl-90px">
                    {components && social && (
                      <ComponentIterator components={[social]} />
                    )}
                  </div>
                )}
              </div>
              {deviceScreen === 'mobile' && (
                <div className="col-span-2">
                  <div className="tablet:hidden pr-4 tablet:pr-11 tablet:pr-6 laptop:pr-90px">
                    {components && social && (
                      <ComponentIterator components={[social]} />
                    )}
                  </div>
                </div>
              )}
              <div className="col-span-6 tablet:col-end-13 laptop:col-end-12 tablet:col-span-7 laptop:col-span-6 px-4 tablet:pr-11 mt-10 tablet:mt-20 laptop:mt-28 mb-16 tablet:mb-17 laptop:pl-6 laptop:pr-90px">
                {components && enhanced && (
                  <ComponentIterator components={[enhanced]} />
                )}
              </div>
            </div>
          </div>

          <div className="w-full">
            <div rolesAllowed={rolesAllowed} className="w-full grid grid-cols-6 tablet:grid-cols-12 gap-3 laptop:gap-4 px-4 tablet:px-11 laptop:px-90px desktop:mx-auto max-w-rdl-xxl mb-16 tablet:mb-17">
              <div rolesAllowed={rolesAllowed} className="col-span-6 tablet:col-span-12 laptop:col-start-2 laptop:col-end-12">
                <SubscriptionTag rolesAllowed={rolesAllowed} />
              </div>
            </div>
            {components && body && body.map((bodyItem) => {
              // MediaDescription layout variables
              if (bodyItem.type === 'MediaDescription') {
                if (bodyItem.layout === 'none') {
                  return (
                    <div key={`CoverStoryPostPage_${bodyItem.compId}`}>
                      <ComponentIterator components={[bodyItem]} />
                    </div>
                  );
                }
                if (['over_text', 'width_text'].includes(bodyItem.layout)) {
                  return (
                    <div key={`CoverStoryPostPage_${bodyItem.compId}`} className="w-full grid grid-cols-6 tablet:grid-cols-12 gap-3 laptop:gap-4 px-4 tablet:px-11 laptop:px-90px desktop:mx-auto max-w-rdl-xxl">
                      <div className="col-span-6 tablet:col-span-12 laptop:col-start-2 laptop:col-end-12">
                        <ComponentIterator components={[bodyItem]} />
                      </div>
                    </div>
                  );
                }
              }
              // Dynamic components
              if ((bodyItem.type === 'NormalBodyText' && bodyItem.quote === true) || bodyItem.type === 'GroupedItems') {
                return (
                  <div key={`CoverStoryPostPage_${bodyItem.compId}`}>
                    <ComponentIterator components={[bodyItem]} />
                  </div>
                );
              }
              return (
                <div key={`CoverStoryPostPage_${bodyItem.compId}`} className="w-full grid grid-cols-6 tablet:grid-cols-12 gap-3 laptop:gap-4 px-4 tablet:px-11 laptop:px-90px desktop:mx-auto max-w-rdl-xxl">
                  <div className="col-span-6 tablet:col-span-12 laptop:col-start-2 laptop:col-end-12">
                    <ComponentIterator components={[bodyItem]} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full mt-20 laptop:mt-20">
            <div className="grid grid-cols-6 tablet:grid-cols-12 gap-3 tablet:gap-4 px-4 tablet:px-11 laptop:px-90px">
              <div className="col-start-1 col-end-7 tablet:col-end-13 laptop:col-start-2 laptop:col-end-12">
                {components && comments && (
                  <ComponentIterator components={[comments]} />
                )}
              </div>
            </div>
            {components && related && (
              <ComponentIterator components={[related]} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

CoverStoryPostPage.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
};

CoverStoryPostPage.defaultProps = {
  components: null,
};

export default CoverStoryPostPage;
