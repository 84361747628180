import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, ErrorMessage, Form, Field, useFormikContext, useField } from 'formik';

import { useUser } from '../../../../../context/userProfileContext';
import useAddress from '../../../../../util/useAddress';
import profileUserEdit from '../../../../../api/graphql/mutations/profileUserEdit';
import getCountryNamesByCode from '../../../../../util/getCountryNamesByCode';

const FORM_FIELDS = {
  name: 'addressName',
  lastname: 'addressLastname',
  street: 'addressStreet',
  number: 'addressStreetNumber',
  apartment: 'addressApartment',
  city: 'addressCity',
  province: 'addressProvince',
  postalcode: 'addressPostalCode',
  country: 'addressCountry',
};

const FormField = React.forwardRef(({ name, required, label, children, ...props }, ref) => {
  const [field] = useField(name);
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label className="flex flex-col items-stretch" htmlFor={name}>
      <span className="text-12 font-light text-black uppercase">
        {label}
        {required && <span className="align-top text-red-light">*</span>}
      </span>
      <div className="relative mt-2">
        <Field
          innerRef={ref}
          className="appearance-none w-full leading-22 rounded-none border-1 placeholder-gray p-4 border-black text-black"
          {...field}
          {...props}
        >
          {children}
        </Field>
        <ErrorMessage name={name} component="div" className="flex flex-col text-red mt-4" />
      </div>
    </label>
  );
});

const VARIANTS = {
  success: 'spotify',
  error: 'red',
};

const Notification = ({ text, variant }) => {
  if (!text) return null;
  return (
    <div className={`mt-4 text-right text-${VARIANTS[variant]}`}>
      {text}
    </div>
  );
};

const AddressForm = () => {
  const [notification, setNotification] = useState();
  const { addressName, addressLastname, addressStreet, addressStreetNumber, addressApartment, addressPostalCode, addressProvince, addressCity, addressCountry, setNewAuthToken, userEmail } = useUser();

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification();
      }, 4000);
    }
  }, [notification]);

  return (
    <Formik
      initialValues={{
        [FORM_FIELDS.name]: addressName,
        [FORM_FIELDS.lastname]: addressLastname,
        [FORM_FIELDS.street]: addressStreet,
        [FORM_FIELDS.number]: addressStreetNumber,
        [FORM_FIELDS.apartment]: addressApartment,
        [FORM_FIELDS.city]: addressCity,
        [FORM_FIELDS.province]: addressProvince,
        [FORM_FIELDS.postalcode]: addressPostalCode,
        [FORM_FIELDS.country]: addressCountry,
      }}
      validate={(values) => {
        const errors = {};
        if (!values[FORM_FIELDS.name]) errors[FORM_FIELDS.name] = 'Requerido';
        if (!values[FORM_FIELDS.lastname]) errors[FORM_FIELDS.lastname] = 'Requerido';
        if (!values[FORM_FIELDS.street]) errors[FORM_FIELDS.street] = 'Requerido';
        if (!values[FORM_FIELDS.number]) errors[FORM_FIELDS.number] = 'Requerido';
        if (!values[FORM_FIELDS.city]) errors[FORM_FIELDS.city] = 'Requerido';
        if (!values[FORM_FIELDS.province]) errors[FORM_FIELDS.province] = 'Requerido';
        if (!values[FORM_FIELDS.postalcode]) errors[FORM_FIELDS.postalcode] = 'Requerido';
        if (!values[FORM_FIELDS.country]) errors[FORM_FIELDS.country] = 'Requerido';
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setNotification();
        profileUserEdit({ email: userEmail, ...values }).then((res) => {
          if (res?.isEdited && res?.token) {
            setNotification({ text: 'Tus datos se han guardado con éxito', variant: 'success' });
            setNewAuthToken(res.token);
            setSubmitting(false);
          } else {
            setNotification({ text: 'Lo sentimos, tu petición no se ha podido completar', variant: 'error' });
          }
        }).catch((err) => {
          console.error(err);
          setNotification({ text: 'Lo sentimos, tu petición no se ha podido completar', variant: 'error' });
        });
      }}
    >
      <>
        <FormContainer />
        <Notification text={notification?.text} variant={notification?.variant} />
      </>
    </Formik>
  );
};

const FormContainer = () => {
  const { setFieldValue, isSubmitting } = useFormikContext();
  const { addressRef, values: addressValues } = useAddress();
  const { route, streetNumber, city, postCode, administrativeAreaLevel2, country } = addressValues;

  const countryList = getCountryNamesByCode('es').sort();

  const handleButtonClasses = () => {
    if (isSubmitting) return 'text-grey border-grey cursor-not-allowed';
    return 'cursor-pointer text-red border-red hover:text-white hover:bg-red';
  };

  useEffect(() => {
    if (!route) return;
    setFieldValue(FORM_FIELDS.street, route || '');
    setFieldValue(FORM_FIELDS.number, streetNumber || '');
    setFieldValue(FORM_FIELDS.city, city || '');
    setFieldValue(FORM_FIELDS.province, administrativeAreaLevel2 || '');
    setFieldValue(FORM_FIELDS.postalcode, postCode || '');
    setFieldValue(FORM_FIELDS.country, country || '');
  }, [route, streetNumber, city, postCode, administrativeAreaLevel2, country]);

  return (
    <Form className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5 font-stratos">
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-4">
        <FormField type="text" name={FORM_FIELDS.name} label="Nombre" required />
      </div>
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-4">
        <FormField type="text" name={FORM_FIELDS.lastname} label="Apellidos" required />
      </div>
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-4">
        <FormField type="text" name={FORM_FIELDS.street} label="Calle" ref={addressRef} required />
      </div>
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-2">
        <FormField type="text" name={FORM_FIELDS.number} label="Número" required />
      </div>
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-2">
        <FormField type="text" name={FORM_FIELDS.apartment} label="Apartamento, edificio, piso" />
      </div>
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-2">
        <FormField type="text" name={FORM_FIELDS.city} label="Ciudad" required />
      </div>
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-2">
        <FormField type="text" name={FORM_FIELDS.province} label="Provincia" required />
      </div>
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-2">
        <FormField type="text" name={FORM_FIELDS.postalcode} label="Código Postal" required />
      </div>
      <div className="col-start-1 tablet:col-start-2 laptop:col-start-auto col-span-4 tablet:col-span-7 laptop:col-span-2">
        {/* <FormField type="text" name={FORM_FIELDS.country} label="País" required /> */}
        <FormField as="select" name={FORM_FIELDS.country} label="País" placeholder="Selecciona un páis" required>
          <option key="placeholder" value="">
            Selecciona un país
          </option>
          {countryList.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FormField>
      </div>
      <div className="col-span-3 laptop:col-span-2 col-end-5 tablet:col-end-11 laptop:col-end-9 cursor-pointer flex flex-col items-stretch justify-end">
        <button type="submit" className="w-full sm:mt-4 md:mt-4" disabled={isSubmitting}>
          <span className={`py-6px laptop:py-1 desktop:py-2 text-base tablet:text-sm laptop:text-base px-6px laptop:px-4 text-center font-stratos font-bold whitespace-pre trans inline-block bg-transparent border-5 laptop:border-4 ${handleButtonClasses()} w-full uppercase`}>
            Guardar cambios
          </span>
        </button>
      </div>
    </Form>
  );
};

Notification.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
};
Notification.defaultProps = {
  text: null,
  variant: 'success',
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node,
};
FormField.defaultProps = {
  required: false,
  children: null,
};

export default AddressForm;
