import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { motion } from 'framer-motion';

const SearchInputDropdown = ({ items }) => {

  const [opened, setOpened] = useState(false);

  const dropdownVariants = {
    closed: { height: 0 },
    opened: { height: 'auto' },
  };

  const dropdownArrowVariants = {
    closed: { rotate: 0 },
    opened: { rotate: 180 },
  };

  const transition = {
    type: 'tween',
    duration: 0.5,
    ease: [0.87, 0, 0.13, 1],
  };

  return (
    <div
      key="dropdown-container"
      className="border-4 border-red cursor-pointer text-red font-bold flex flex-col items-stretch justify-start tablet:min-w-tablet laptop:min-w-dropdownLaptop desktop:min-w-dropdownDesktop"
      // onClick={() => setOpened(!opened)}
    >
      <div className="flex items-center justify-between px-4 py-2">
        <span>[Lo último]</span>
        <motion.div
          animate={opened ? 'opened' : 'closed'}
          initial={{ rotate: 0 }}
          transition={transition}
          variants={dropdownArrowVariants}
        >
          <svg className="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10">
            <path data-name="t4818" d="M8 10l8-10H0z" fill="#ff4646" />
          </svg>
        </motion.div>
      </div>
      <motion.div
        animate={opened ? 'opened' : 'closed'}
        initial={{ height: 0 }}
        transition={transition}
        variants={dropdownVariants}
        className="w-full flex flex-col items-stretch justify-start overflow-hidden"
      >
        {items.length > 0 && items.map((item, index) => {
          // Logic
          return (
            <div
              key={item + index}
              className="px-4 py-2 border-t border-gray"
            >
              {item}
            </div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default SearchInputDropdown;

SearchInputDropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
};

SearchInputDropdown.defaultProps = {
  items: [],
};
