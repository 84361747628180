import React from 'react';

const TopHeaderMegamenuDivider = () => (
  <div
    data-name="megamenu-section-divider"
    className="hidden w-px h-full bg-grey laptop:block"
  />
);

export default TopHeaderMegamenuDivider;
