import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { buttonClasses } from '../OnDemandUtils/onDemandUtils';
import translateObj from '../../../../util/translateObj';
import getCountryNamesByCode, { getCodeFromCountry } from '../../../../util/getCountryNamesByCode';

import signupUserForm from '../../../../api/graphql/mutations/signupUserForm';

import FormInput from '../../Form/FormInput';
import FormCheckboxConditions from '../../Form/FormCheckboxConditions';
import LoadIcon from '../../LoadIcon';
import { useUser } from '../../../../context/userProfileContext';

const defaultConditionsText = {
  acceptText: {
    es: 'Acepto las ',
    en: 'Acepto las',
    ca: 'Acepto las',
    pt: 'Acepto las',
  },
  conditionsText: {
    es: 'CONDICIONES DE ACCESO',
    en: 'CONDICIONES DE ACCESO',
    ca: 'CONDICIONES DE ACCESO',
    pt: 'CONDICIONES DE ACCESO',
  },
  conditionsLink: {
    es: '/condiciones-de-registro',
    en: '/condiciones-de-registro',
  },
  dataTreatmentText: {
    es: ' y el tratamiento de datos y la elaboración de perfiles según la ',
    en: ' y el tratamiento de datos y la elaboración de perfiles según la ',
    ca: ' y el tratamiento de datos y la elaboración de perfiles según la ',
    pt: ' y el tratamiento de datos y la elaboración de perfiles según la ',
  },
  privacyPolicyText: {
    es: 'POLÍTICA DE PRIVACIDAD',
    en: 'POLÍTICA DE PRIVACIDAD',
    ca: 'POLÍTICA DE PRIVACIDAD',
    pt: 'POLÍTICA DE PRIVACIDAD',
  },
  privacyPolicyUrl: {
    es: '/politica-de-privacidad',
    en: '/politica-de-privacidad',
  },
};

const OnDemandModalRegister = (props) => {
  const {
    loadTokenForLS,
    handleOnDemandScreenStatus,
    translations,
    translations: { registerModal: formText = {} } = {},
    language,
    userSocialInfo: { profileObj: preFilledValues = {} } = {},
  } = props;

  const refForm = useRef(null);
  const refName = useRef(null);
  const refLastName = useRef(null);
  const refEmail = useRef(null);
  const refPostalCode = useRef(null);
  const refCountry = useRef(null);
  const refPassword = useRef(null);
  const refRepeatPassword = useRef(null);

  const [canSubmit, setCanSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [currentValues, setCurrentValues] = useState(null);
  const user = useUser();

  useEffect(() => {
    if (Object.keys(preFilledValues).length) {
      const {
        isSocialLogin, givenName, familyName, email, googleId, facebookId, spotifyId,
      } = preFilledValues;
      const currentValuesObj = {};

      switch (isSocialLogin) {
        case 'google':
          currentValuesObj.name = givenName;
          currentValuesObj.lastname = familyName;
          currentValuesObj.email = email;
          currentValuesObj.isSocialLogin = isSocialLogin;
          currentValuesObj.oauthId = googleId;
          break;
        case 'facebook':
          currentValuesObj.name = givenName;
          currentValuesObj.lastname = familyName;
          currentValuesObj.email = email;
          currentValuesObj.isSocialLogin = isSocialLogin;
          currentValuesObj.oauthId = facebookId;
          break;
        case 'spotify':
          currentValuesObj.name = givenName;
          currentValuesObj.lastname = familyName;
          currentValuesObj.email = email;
          currentValuesObj.isSocialLogin = isSocialLogin;
          currentValuesObj.oauthId = spotifyId;
          break;
        default:
          break;
      }
      setCurrentValues(currentValuesObj);
    }
  }, [preFilledValues]);

  const enableButton = () => {
    setCanSubmit(true);
  };
  const disableButton = () => {
    setCanSubmit(false);
  };

  const onReset = () => {
    refForm.reset();
    refName.setValue('');
    refLastName.setValue('');
    refEmail.setValue('');
    refPostalCode.setValue('');
    refCountry.setValue('');
    refPassword.setValue('');
    refRepeatPassword.setValue('');
  };

  const handleDate = (name, date) => {
    setDateOfBirth(date);
  };

  const submitForm = (formData, lang, socialnetwork) => {
    const { spotifyToken } = preFilledValues;
    setCanSubmit(false);
    setIsLoading(true);

    const formDataUpdated = {
      ...formData, language, dateOfBirth, socialnetwork,
    };

    // * add country ISO code instead of country name
    let countryISOCode = '';
    if (formData && formData.inputCountry) {
      countryISOCode = getCodeFromCountry(formData.inputCountry, language) || 'ES';
    }

    if (countryISOCode) {
      formDataUpdated.inputCountry = countryISOCode;
    }

    // add spotify initial code in order to sign in user
    if (socialnetwork === 'spotify') {
      formDataUpdated.spotifyToken = spotifyToken;
    }

    // Sign in newsletter by default
    formDataUpdated.newsletter = true;

    signupUserForm({ ...formDataUpdated }).then((response) => {
      if (response) {
        if (response.isValid) {
          // this.onReset();
          setCanSubmit(true);
          user.setNewAuthToken(response.token);
          setIsLoading(false);
          window.location.reload();
          // !!! need to check this no need to handle ondemandscreenstatus as userEmail in context is being updated already
          // handleOnDemandScreenStatus('logged');

          // Redirect to payment page
          // history.push(`/${language}`);
        } else if (response.error) {
          formData.email = '';
          setCurrentValues(formData);
          setErrorMsg(response.error);
          setIsLoading(false);
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  };

  const registerFormRender = () => {
    if (isLoading) return (<LoadIcon height={15} width={15} color="black" type="ThreeDots" />);
    return (
      <div className="w-full h-full text-12 bg-transparent overflow-y-auto">
        <Formsy
          ref={refForm}
          onValidSubmit={e => submitForm(e, language, currentValues && currentValues.isSocialLogin)}
          onValid={enableButton}
          onInvalid={disableButton}
          className="flex flex-col justify-between w-full h-full px-8 relative pt-3"
        >
          <div className="flex-1">
            <div className="flex flex-col w-full mb-3 font-base text-15">
              <FormInput
                ref={refName}
                backgroundColor="white"
                name="name"
                doNotChangeValidColor
                title={translateObj(formText.name, language)}
                validations={{ isSpecialWords: true, maxLength: 50 }}
                validationErrors={{ isSpecialWords: translateObj(formText.inValidName, language), maxLength: translateObj(formText.longName, language) }}
                value={(currentValues && currentValues.name) || ''}
                required
                className="w-full"
              />
            </div>
            <div className="flex flex-col w-full mb-3 text-15">
              <FormInput
                ref={refLastName}
                backgroundColor="white"
                name="lastname"
                doNotChangeValidColor
                title={translateObj(formText.lastname, language)}
                validations={{ isSpecialWords: true, maxLength: 50 }}
                validationErrors={{ isSpecialWords: translateObj(formText.inValidName, language), maxLength: translateObj(formText.longName, language) }}
                value={(currentValues && currentValues.lastname) || ''}
                required
                className="w-full"
              />
            </div>
            <div className="flex flex-col w-full mb-3 text-15">
              <FormInput
                ref={refEmail}
                backgroundColor="white"
                name="email"
                doNotChangeValidColor
                isError={Boolean(errorMsg)}
                title={translateObj(formText.email, language)}
                validations={{ isEmail: true, maxLength: 100 }}
                validationErrors={{ isEmail: translateObj(formText.inValidEmail, language), maxLength: translateObj(formText.longEmail, language) }}
                disabled={(currentValues && currentValues.email)}
                value={(currentValues && currentValues.email) || ''}
                required
                className="w-full"
              />
            </div>
            {!currentValues || !currentValues.isSocialLogin
              ? (
                <>
                  <div className="flex flex-col w-full mb-3 text-15">
                    <FormInput
                      ref={refPassword}
                      backgroundColor="white"
                      name="password"
                      title={translateObj(formText.password, language)}
                      validations={{ minLength: 8 }}
                      validationError={formText.shortPassword[language]}
                      type="password"
                      required
                      className="w-full"
                      titleClassName="flex uppercase"
                      infoText={translateObj(formText.passwordInfo, language)}
                    />
                  </div>
                  <div className="flex flex-col w-full mb-3 text-15">
                    <FormInput
                      ref={refRepeatPassword}
                      backgroundColor="white"
                      name="repeatPassword"
                      title={translateObj(formText.repeatPassword, language)}
                      validations="equalsField:password"
                      validationError={translateObj(formText.repeatPasswordError, language)}
                      type="password"
                      required
                      className="w-full"
                    />
                  </div>
                </>
              ) : (
                <div className="flex flex-col w-full mb-3 text-15">
                  <FormInput
                    name="oauthId"
                    backgroundColor="white"
                    title="Social Id Code"
                    value={(currentValues && currentValues.oauthId) || ''}
                    hidden
                  />
                </div>
              )
            }
          </div>

          {errorMsg && <span className="pl-3 text-red mt-4">{errorMsg}</span>}

          <div className="flex items-start justify-between -ml-2">
            <div>
              <FormCheckboxConditions
                required
                acceptText={translateObj(defaultConditionsText.acceptText, language)}
                conditionsText={translateObj(defaultConditionsText.conditionsText, language)}
                conditionsLink={translateObj(defaultConditionsText.conditionsLink, language)}
                dataTreatmentText={translateObj(defaultConditionsText.dataTreatmentText, language)}
                privacyPolicyText={translateObj(defaultConditionsText.privacyPolicyText, language)}
                privacyPolicyUrl={translateObj(defaultConditionsText.privacyPolicyUrl, language)}
                name="conditions"
                validations="isTrue"
                validationError=""
              />
            </div>
          </div>
          <div className="w-full flex items-center justify-end pb-6">
            <button
              disabled={!canSubmit}
              type="submit"
              value={translateObj(translations.accessAccount, language)}
              className={`mt-6 border-6 ${canSubmit ? 'bg-white text-red border-6 border-red cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'} text-14 font-stratos font-bold w-1/2 text-center`}
              style={{
                alignSelf: 'flex-end',
                minHeight: '2.5rem',
              }}
            >
              Entrar
            </button>
          </div>
          {/* <div className="flex flex-col w-1/4 sm:hidden">
            <button type="submit" disabled={!canSubmit} className={`${canSubmit ? 'bg-black text-white' : 'bg-white text-gray200'} h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold`}>{translateObj(formText.submitButtonText, language)}</button>
          </div> */}
        </Formsy>
      </div>
    );
  };


  return (
    <>
      {registerFormRender()}
    </>
  );
};

OnDemandModalRegister.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
};

OnDemandModalRegister.defaultProps = {
  language: 'en',
};

export default OnDemandModalRegister;
