import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const GET_COMMENTS_QUERY = gql`
  query Get($postSlugName: String!) {
    getCommentsForPost(postSlugName: $postSlugName) {
      commentId
      userId
      nickname
      commentText
      createdAt
      hasBadge
      deleted
      censored
    }
  }
`;

const getComments = async (postSlugName) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_COMMENTS_QUERY,
        variables: { postSlugName },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getCommentsForPost: getCommentsData },
    } = result;

    if (getCommentsData) {
      return getCommentsData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getComments`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getComments Error: ${error}`);
    return null;
  }
};

export default getComments;
