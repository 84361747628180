import uuid from 'react-uuid';
import getRandomColor from './getRandomColor';

import {
  OPEN, PRIVATE, PARTICIPANTS, WAITINGLIST, CHANNELS, PRIVATE_CHANNELS, PENDING_PRIVATE_CHANNELS, MESSAGES, USERS, MUTED_USERS, VIDEO_CALLS,
} from './constants';
import {
  fieldValue, firestore, arrayUnion, arrayRemove, firebaseauth,
} from '../context/FirebaseAuthContext';

export const messagesRef = firestore.collection(MESSAGES);
export const usersRef = firestore.collection(USERS);
export const channelsRef = firestore.collection(CHANNELS);
export const videoCallsRef = firestore.collection(VIDEO_CALLS);
const listeners = [];

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return userDocument.data();
  } catch (error) {
    console.error('Error fetching user', error);
  }
};

export const generateUserDocument = async (user) => {
  const { userId } = user;
  if (!user) return;
  const userRef = firestore.doc(`users/${userId}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    try {
      await userRef.set({
        userId,
        nickname: user.nickname,
        nicknameColor: getRandomColor(),
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  } else {
    const { nickname, nicknameColor } = snapshot.data();
    if (!snapshot.data().userId) {
      const newUserData = { ...snapshot.data(), userId };
      try {
        await userRef.set(newUserData);
      } catch (error) {
        console.error('Error creating user document', error);
      }
    }
    if (nickname !== user.nickname) {
      const newUserData = { ...snapshot.data(), nickname: user.nickname };
      try {
        await userRef.set(newUserData);
      } catch (error) {
        console.error('Error creating user document', error);
      }
    }
    if (!nicknameColor) {
      const newUserData = { ...snapshot.data(), nicknameColor: getRandomColor() };
      try {
        await userRef.set(newUserData);
      } catch (error) {
        console.error('Error creating user document', error);
      }
    }
  }
  const userDocument = await getUserDocument(user.userId);
  return userDocument;
};

const sortString = (str) => {
  const arr = str.split('');
  let tmp;
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      /* if ASCII code greater then swap the elements position */
      if (arr[i] > arr[j]) {
        tmp = arr[i];
        arr[i] = arr[j];
        arr[j] = tmp;
      }
    }
  }
  return arr.join('');
};

export const createOpenChannel = ({ name }) => {
  const url = `pslife_open_room_${uuid()}`;
  const newChannel = {
    createdAt: fieldValue.serverTimestamp(),
    url,
    name,
    channelPrivacy: OPEN,
  };

  const channel = messagesRef.doc(url).set(newChannel);
  const channelDev = channelsRef.doc(url).set(newChannel);
  return Promise.all([channel, channelDev]).then(res => `Created ok ${url}`).catch(err => console.log(err));
};

export const stopListen = () => {
  listeners.forEach(listener => listener());
};

export const getChannel = async ({ channelUrl }) => {
  const res = await channelsRef.doc(channelUrl).get();
  return res;
};

export const docExists = ({ ref, doc }) => ref.doc(doc).get().then(snapshot => snapshot.exists);

export const getOnlineUsers = async ({ channelUrl, from, limit }, cb) => {
  const dateAgo = new Date(new Date().getTime() - 5 * 60000);
  let snapshot;
  if (!from) {
    snapshot = await channelsRef.doc(channelUrl).collection(PARTICIPANTS)
      .where('isOnline', '==', true)
      .where('lastSeen', '>=', dateAgo)
      .orderBy('lastSeen')
      .orderBy('nickname')
      .limit(limit);
  } else {
    snapshot = await channelsRef.doc(channelUrl).collection(PARTICIPANTS)
      .where('isOnline', '==', true)
      .where('lastSeen', '>=', dateAgo)
      .orderBy('lastSeen')
      .orderBy('nickname')
      .limit(limit)
      .startAfter(from);
  }

  snapshot.get()
    .then((snapshots) => {
      cb(snapshots);
    });
};

export const enterChannel = ({ channel, userData }) => getChannel({ channelUrl: channel }).then((channelDoc) => {
  if (channelDoc.exists) {
    const setParticipantInChannel = channelsRef.doc(channel).collection(PARTICIPANTS).doc(userData.userId).set({ ...userData, isOnline: true, lastSeen: new Date() });
    const setParticipantInMessages = messagesRef.doc(channel).collection(PARTICIPANTS).doc(userData.userId);
    return Promise.all([setParticipantInChannel, setParticipantInMessages]).then(res => res);
  }
  return { error: 'channel not found' };
});

export const updateLastSeen = async ({ channel }) => {
  getChannel({ channelUrl: channel }).then((channelDoc) => {
    if (channelDoc.exists) {
      const { currentUser } = firebaseauth;
      if (!currentUser) { return; }
      channelsRef.doc(channel).collection(PARTICIPANTS).doc(currentUser.uid).update({ lastSeen: new Date() })
        .then(res => res);
    }
  });
};

export const enterPrivateChannel = ({ channel, userData }) => getChannel({ channelUrl: channel }).then((channelDoc) => {
  if (channelDoc.exists) {
    const setParticipantInChannel = channelsRef.doc(channel).collection(PARTICIPANTS).doc(userData.userId).set({ ...userData, isOnline: true });
    const setParticipantInMessages = messagesRef.doc(channel).collection(PARTICIPANTS).doc(userData.userId);
    const updateUserChannel = usersRef.doc(userData.userId).collection(PRIVATE_CHANNELS).doc(channel).update({
      unread: 0,
      lastRead: new Date(),
    });
    return Promise.all([setParticipantInChannel, setParticipantInMessages, updateUserChannel]).then(res => userData);
  }
  return 'channel not found';
});

export const sendMessage = ({ channelUrl, data }) => {
  const newMessage = {
    ...data,
    createdAt: fieldValue.serverTimestamp(),
    isVisible: true,
  };
  messagesRef.doc(channelUrl).collection(MESSAGES).doc(data.messageId)
    .set(newMessage)
    .catch(err => console.log(err));
};

export const deleteMessage = ({ channelUrl, id }) => {
  const singleMessageRef = messagesRef.doc(channelUrl).collection(MESSAGES).doc(id);
  const visibleState = singleMessageRef.update({
    isVisible: false,
  });
  return visibleState.then(result => 'Deleted').catch(err => console.log('Error on delete:', err));
};

export const acceptQaMessage = ({ channelUrl, id }) => {
  const singleMessageRef = messagesRef.doc(channelUrl).collection(MESSAGES).doc(id);
  const acceptState = singleMessageRef.update({
    isPending: false,
    updatedAt: fieldValue.serverTimestamp(),
  });
  return acceptState.then(result => 'Accepted').catch(err => console.log('Error on accept:', err));
};

export const updateMutedUsersArray = ({
  channelUrl, userId, type,
}) => {
  const mutedUsersCollection = channelsRef.doc(channelUrl).collection(MUTED_USERS);
  let promise;
  switch (type) {
    case 'mute':
      promise = mutedUsersCollection.doc(userId).set({ muted: true });
      break;
    case 'unmute':
      promise = mutedUsersCollection.doc(userId).delete();
      break;
    default:
      break;
  }
  if (promise) {
    return promise.then(res => `ok ${type}`).catch(err => console.log(err));
  }
};

// Private channels

export const createPrivateChannel = ({
  creator, invited,
}) => {
  const url = `pslife_private_room_${uuid()}`;
  const merged = invited.userId + creator.userId;
  const channelId = sortString(merged);

  const docRef = channelsRef.where('channelId', '==', channelId);
  return docRef.get().then((doc) => {
    if (doc && doc.empty) {
      const newPrivateChannel = {
        createdAt: fieldValue.serverTimestamp(),
        url,
        channelId,
        channelPrivacy: PRIVATE,
        ownerShip: creator.userId,
      };
      const privateChannelinfo = {
        members: [creator.nickname, invited.nickname],
        unread: 0,
        lastRead: new Date(),
        inviter: creator,
      };
      const channel = messagesRef.doc(url).set(newPrivateChannel);
      const channelDev = channelsRef.doc(url).set(newPrivateChannel);
      const setParticipants = channelsRef.doc(url).collection(PARTICIPANTS).doc(creator.userId).set(creator);
      const setWaitingList = channelsRef.doc(url).collection(WAITINGLIST).doc(invited.userId).set(invited);
      const setPrivateChannels = usersRef.doc(creator.userId).collection(PRIVATE_CHANNELS).doc(url).set(privateChannelinfo);
      const setPendingChannels = usersRef.doc(invited.userId).collection(PENDING_PRIVATE_CHANNELS).doc(url).set(privateChannelinfo);
      return Promise.all([channel, channelDev, setPrivateChannels, setPendingChannels, setParticipants, setWaitingList]).then(res => `Created ok ${url}`).catch(err => console.log(err));
    }
    return 'channel exists';
  }).catch(err => console.log(err));
};

export const inviteToChannel = ({
  channelUrl, inviter, invited,
}) => {
  const setWaitingList = channelsRef.doc(channelUrl).collection(WAITINGLIST).doc(invited.userId).set(invited);
  const setPendingChannels = usersRef.doc(invited.userId).collection(PENDING_PRIVATE_CHANNELS).doc(channelUrl).set({ members: [inviter.nickname, invited.nickname], inviter });
  const updateChannelId = channelsRef.doc(channelUrl).update({ channelId: uuid() });
  return Promise.all([setPendingChannels, setWaitingList, updateChannelId]).then(res => 'Invitation sent').catch(err => console.log(err));
};

export const updatePendingPrivateCall = async ({ room, userId, type }) => {
  try {
    if (type === 'add') {
      return await videoCallsRef.doc(room).update(
        {
          pending: arrayUnion(userId),
        },
      );
    }
    return await videoCallsRef.doc(room).update(
      {
        pending: arrayRemove(userId),
      },
    );
  } catch (error) {
    console.error('Error updating call', error);
  }
};

export const addParticipantToCall = async ({ room, userId }) => {
  try {
    return await videoCallsRef.doc(room).update(
      {
        participants: arrayUnion(userId),
      },
    );
  } catch (error) {
    console.error('Error updating call', error);
  }
};

export const inviteToPrivateCall = async ({ room, inviter, userId }) => {
  const docIsCreated = await docExists({ ref: videoCallsRef, doc: room });
  if (docIsCreated) {
    await updatePendingPrivateCall({ room, userId, type: 'add' });
    return ({ room, userId: inviter.userId });
  }
  const setVideoCall = videoCallsRef.doc(room).set(
    {
      pending: [userId],
      participants: [inviter.userId],
      roomOwner: inviter.nickname,
    },
  );
  return setVideoCall;
};

export const endCurrentPrivateCall = async ({ room, userId }) => {
  try {
    return await videoCallsRef.doc(room).update(
      {
        participants: arrayRemove(userId),
      },
    );
  } catch (error) {
    console.error('Error updating call', error);
  }
};

export const hangUpPrivateCall = uid => videoCallsRef.where('participants', 'array-contains', uid).get().then((snapshot) => {
  const { docs } = snapshot;
  if (docs && docs.length) {
    docs.map(doc => videoCallsRef.doc(doc.id).update({
      participants: arrayRemove(uid),
    }));
  }
});

export const changeVideoRoom = async ({ previousRoom, newRoom, userId }) => {
  await endCurrentPrivateCall({ room: previousRoom, userId });
  await updatePendingPrivateCall({ room: newRoom, userId, type: 'remove' });
  await addParticipantToCall({ room: newRoom, userId });
};

export const acceptInvitation = ({ channelUrl, userData, inviter }) => {
  const channel = messagesRef.doc(channelUrl);
  const channelDev = channelsRef.doc(channelUrl);
  usersRef.doc(inviter.userId).collection(PRIVATE_CHANNELS).doc(channelUrl).get()
    .then((channelDoc) => {
      if (channelDoc.exists) {
        const { members } = channelDoc.data();
        if (channelDoc.data() && members && members.length) {
          let newMembers = members;
          if (!members.includes(userData.nickname)) {
            newMembers = [...members, userData.nickname];
            const setMembersInviter = usersRef.doc(inviter.userId).collection(PRIVATE_CHANNELS).doc(channelUrl).set({ members: newMembers, inviter });
            const setMembersInvited = usersRef.doc(userData.userId).collection(PRIVATE_CHANNELS).doc(channelUrl).set({
              members: newMembers,
              inviter,
              unread: 0,
              lastRead: new Date(),
            });
            return Promise.all([setMembersInviter, setMembersInvited]);
          }
          const setMembersInvited = usersRef.doc(userData.userId).collection(PRIVATE_CHANNELS).doc(channelUrl).set({
            members: newMembers,
            inviter,
            unread: 0,
            lastRead: new Date(),
          });
          return setMembersInvited;
        }
      }
      return channelDoc;
    })
    .then((res) => {
      const setParticipant = channel.collection(PARTICIPANTS).doc(userData.userId).set(userData);
      const setWaitingList = channel.collection(WAITINGLIST).doc(userData.userId).delete();
      const setParticipantDev = channelDev.collection(PARTICIPANTS).doc(userData.userId).set(userData);
      const setWaitingListDev = channelDev.collection(WAITINGLIST).doc(userData.userId).delete();
      const setPendingChannels = usersRef.doc(userData.userId).collection(PENDING_PRIVATE_CHANNELS).doc(channelUrl).delete();
      return Promise.all([setParticipant, setWaitingList, setParticipantDev, setWaitingListDev, setPendingChannels]).then(res => 'Accept invitation').catch(err => console.log(err));
    });
};

export const declineInvitation = ({ channelUrl, userData, inviter }) => {
  const channel = messagesRef.doc(channelUrl);
  const channelDev = channelsRef.doc(channelUrl);

  const removePrivateChannelFromUser = usersRef.doc(inviter.userId).collection(PRIVATE_CHANNELS).doc(channelUrl).delete();
  const setWaitingList = channel.collection(WAITINGLIST).doc(userData.userId).delete();
  const setWaitingListDev = channelDev.collection(WAITINGLIST).doc(userData.userId).delete();
  const setPendingChannels = usersRef.doc(userData.userId).collection(PENDING_PRIVATE_CHANNELS).doc(channelUrl).delete();
  const removeChannel = channel.delete();
  const removeChannelDev = channelDev.delete();
  return Promise.all([removePrivateChannelFromUser, setWaitingList, setWaitingListDev, setPendingChannels, removeChannel, removeChannelDev]).then(res => 'Declined invitation').catch(err => console.log(err));
};

export const disconnectUser = ({ channelUrl }) => {
  const { currentUser } = firebaseauth;
  if (!currentUser) return null;
  const channelParticipant = channelsRef.doc(channelUrl).collection(PARTICIPANTS).doc(currentUser.uid);
  const setNotactive = channelParticipant.update({ isOnline: false });
  return setNotactive.then(res => 'Logged out').catch(err => console.log(err));
};

export const hashCode = (s) => {
  /* Simple hash function. */
  let a = 1;
  let c = 0;
  let h;
  let o;
  if (s) {
    a = 0;
    /* eslint plusplus:false eslint:false */
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = (a << 6 & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = c !== 0 ? a ^ c >> 21 : a;
    }
  }
  return String(a);
};
