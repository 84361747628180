import React from 'react';
import PropTypes from 'prop-types';

const FooterBottom = ({ children, style }) => (
  <div data-name="footer-bottom" className={`relative ${style}`}>
    { children }
  </div>
);

FooterBottom.propTypes = {
  children: PropTypes.shape().isRequired,
  style: PropTypes.string,
};

FooterBottom.defaultProps = {
  style: 'width-full bg-red text-white',
};

export default FooterBottom;
