import React, { useState, useEffect, useContext } from 'react';
import PropTypes, { checkPropTypes } from 'prop-types';
import { connectMenu } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import RoutesContext from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const handleSearchParams = (history, slugName) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const currentCategory = urlSearchParams.get('cat');
  if (currentCategory !== slugName) {
    urlSearchParams.delete('page');
  }
  if (!slugName) {
    urlSearchParams.delete('cat');
  } else {
    urlSearchParams.set('cat', slugName);
  }
  history.replace(`${history.location.pathname}?${urlSearchParams}`);
};

const Tag = ({
  item,
  refine,
  color,
  isListPage,
  type,
  activeSlug,
}) => {
  const history = useHistory();
  const {
    title,
    slugName,
  } = item;
  const isActive = activeSlug === slugName;
  const { language } = useContext(RoutesContext);
  if (isListPage && type === 'category') {
    return (
      <div
        key={slugName}
        onClick={() => {
          handleSearchParams(history, slugName);
          refine(slugName);
        }}
        className={`cursor-pointer border-t-4 border-solid
        border-${isActive ? 'red' : 'black'} hover:border-red
        text-${isActive ? 'red' : 'black'} hover:text-red pt-2 pr-8 mr-3 mb-2`}
      >
        <span
          className="font-stratos font-semibold text-base capitalize"
        >
          {translateObj(title, language)}
        </span>
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        refine(item.value);
      }}
      className={`cursor-pointer border-4 border-solid
      border-${color} py-1 px-2 tablet:px-4 bg-${color}
      tablet:bg-${isActive ? color : 'white'} mr-2 mb-2`}
    >
      <span
        className={`hidden tablet:block laptop:block
        desktop:block font-stratos font-semibold
        text-${isActive ? 'white' : color} text-base
        capitalize`}
      >
        {translateObj(title, language)}
      </span>
      <span
        className="tablet:hidden laptop:hidden desktop:hidden
        font-stratos font-semibold text-white text-base
        capitalize"
      >
        {translateObj(title, language)}
      </span>
    </div>
  );
};

const SearchResultsByTag = ({
  color,
  title,
  attribute,
  checkCategories,
  isListPage,
  type,
  updateFilterType,
  filterType,
  currentCategory,
}) => {
  const [activeSlug, setActiveSlug] = useState(currentCategory);
  const [categories, setCategories] = useState(checkCategories);

  useEffect(() => {
    setCategories(checkCategories);
  }, [checkCategories]);

  useEffect(() => {
    setActiveSlug(currentCategory);
  }, [currentCategory]);

  const customRefine = (slugName) => {
    setActiveSlug(slugName);
    updateFilterType(type);
  };

  return (
    <div className="pb-6 tablet:pb-12 laptop:pb-20 desktop:pb-12 w-full">
      <h2 className={`font-stratos font-semibold text-${color}
      ${isListPage ? 'text-12 pb-3' : 'text-20 tablet:text-base desktop:text-22 pb-4'}
      ${!title || title.length === 0 ? 'hidden' : ''}
      `}
      >
        {!title || title.length === 0 ? 'Categorías' : title}
      </h2>
      <div className="flex flex-wrap">
        <CustomMenu
          attribute={attribute}
          checkCategories={categories}
          activeSlug={filterType === type ? activeSlug : false}
          color={color}
          isListPage={isListPage}
          type={type}
          customRefine={customRefine}
          defaultRefinement={filterType === type && activeSlug !== false ? activeSlug : null}
          limit={20}
        />
      </div>
    </div>
  );
};

const menuList = ({
  refine,
  checkCategories,
  color,
  isListPage,
  type,
  items,
  activeSlug,
  customRefine,
}) => {
  if (isListPage) {
    return checkCategories.map((item) => (item ? (
      <Tag
        key={item.slugName}
        item={item}
        items={items}
        refine={(slugName) => {
          customRefine(slugName);
          refine(slugName);
        }}
        color={color}
        isListPage={isListPage}
        type={type}
        activeSlug={activeSlug}
      />
    ) : null));
  }
  return items.map((item) => (item ? (
    <Tag
      key={item.value}
      item={item}
      items={items}
      refine={(slugName) => {
        refine(slugName);
      }}
      color={color}
      isListPage={isListPage}
      type={type}
      activeSlug={activeSlug}
    />
  ) : null));
};
const CustomMenu = connectMenu(menuList);

Tag.propTypes = {
  item: PropTypes.shape(),
  refine: PropTypes.func,
  color: PropTypes.string,
  isListPage: PropTypes.bool,
  type: PropTypes.string,
  activeSlug: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

Tag.defaultProps = {
  item: null,
  refine: null,
  color: 'red',
  isListPage: false,
  type: 'category',
  activeSlug: null,
};

SearchResultsByTag.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  attribute: PropTypes.string,
  checkCategories: PropTypes.arrayOf(PropTypes.string),
  isListPage: PropTypes.bool,
  type: PropTypes.string,
  currentCategory: PropTypes.string,
  filterType: PropTypes.string,
  updateFilterType: PropTypes.func,
};

SearchResultsByTag.defaultProps = {
  color: 'red',
  title: 'Categorías',
  attribute: 'postCategory',
  checkCategories: [
    'actualidad',
    'musica',
    'discos',
    'cultura',
    'lifestyle',
    'listas',
    'opinion',
    'en-portada',
  ],
  isListPage: false,
  type: 'category',
  filterType: '',
  currentCategory: '',
  updateFilterType: () => {},
};

export default SearchResultsByTag;
