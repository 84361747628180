import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import RadioTag from '../Buttons/RadioTag';

import getRadioShowsEpisodesForEpisode from '../../../api/graphql/queries/getRadioShowsEpisodesForEpisode';
import getRadioShows from '../../../api/graphql/queries/getRadioShows';

import withAllContexts from '../../../context/withAllContexts';
import Separator from '../Separator';
import MediaTitle from '../MediaTitle';
import RadioEpisodeListItem from './RadioEpisodeListItem';
import RadioShowCard from './RadioShowCard';
import MediaDescription from '../MediaDescription';
import FavoritesShare from '../FavoritesShare';
import RadioItemType from '../RadioItemType';
import TitleLineH1 from '../Text/TitleLineH1';
import TitleLineH2 from '../Text/TitleLineH2';
import TagsWrapper from '../TagsWrapper';
import NormalBodyText from '../Text/NormalBodyText';
import Carousel75 from '../Carousel75';
import NavigatorRadio from '../NavigatorRadio';
import PaginationComponent from '../PaginationComponent';
import LoadIcon from '../LoadIcon';

class RadioShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currShow: null,
      latestEpisodes: [],
      limit: 6,
      currentPage: 1,
      itemsPerPage: 6,
    };

    this.currentShow = null;
    this.relatedShows = null;
    this.latestEpisodes = null;
    this.latestEpisodesMobile = null;
    this._isMounted = false;
  }

  componentWillMount = () => {
    const {
      radioShowsContext,
    } = this.props;
    let showSlugName = null;
    if (radioShowsContext.singleRadioShowData) {
      showSlugName = radioShowsContext.singleRadioShowData[0].slug;
      this.processSingleRadioShowData(radioShowsContext.singleRadioShowData);
      radioShowsContext.clear('singleRadioShowData');
      if (radioShowsContext.singleRadioShowRelatedData) {
        this.processSingleRadioShowRelatedData(radioShowsContext.singleRadioShowRelatedData, showSlugName);
        radioShowsContext.clear('singleRadioShowRelatedData');
      }

      if (radioShowsContext.singleRadioShowEpisodesData) {
        this.processSingleRadioShowEpisodesData(radioShowsContext.singleRadioShowEpisodesData);
        radioShowsContext.clear('singleRadioShowEpisodesData');
      }

      if (this.currentShow
        && this.relatedShows
        && this.latestEpisodes
        && this.latestEpisodesMobile) {
        this.updateState();
      }
    }
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.initQueries();
  }

  componentDidUpdate = (prevProps) => {
    const { match } = this.props;
    const prev = prevProps.match.url;
    const current = match.url;

    if (prev !== current) {
      this.initQueries();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initQueries = async () => {
    const { match } = this.props;
    const { limit } = this.state;
    let showSlugName = null;
    let documentTitle = null;

    if (match.params.post) {
      showSlugName = match.params.post;
    }

    const radioShowData = await getRadioShows({ slug: showSlugName });
    if (radioShowData && radioShowData.length) {
      documentTitle = `Radio Primavera Sound - ${radioShowData[0].title_multilang.en}`;
      document.title = documentTitle;
      this.processSingleRadioShowData(radioShowData);
      await getRadioShows({ limit: 3 })
        .then((radioShowsData) => {
          if (radioShowsData) {
            this.processSingleRadioShowRelatedData(radioShowsData, showSlugName);
          } else {
            console.log('radioShowsData = null');
          }
        });

      if (showSlugName) {
        await getRadioShowsEpisodesForEpisode({ parentSlug: showSlugName })
          .then((episodesData) => {
            if (episodesData) {
              this.processSingleRadioShowEpisodesData(episodesData);
            } else {
              console.log('episodesData = null');
            }
          });
      }
      this.updateState();
    } else {
      console.log('radioShowData = null');
      return this.sendtoShows();
    }
  }

  processSingleRadioShowData = (radioShowData) => {
    const data = radioShowData[0];
    this.currentShow = data;
  }

  processSingleRadioShowRelatedData = (radioShowsData, showSlugName) => {
    const otherShows = radioShowsData
      .filter(el => el.slug !== showSlugName)
      .sort((a, b) => b.id - a.id);

    const relatedShows = otherShows.map((ep) => {
      const show = { ...ep, type: 'RadioShowCard' };
      return show;
    });

    this.relatedShows = relatedShows;
  }

  processSingleRadioShowEpisodesData = (episodesData) => {
    const sortedEpisodes = episodesData.sort((a, b) => (new Date(b.dateAir) - new Date(a.dateAir)));
    const latestEpisodes = sortedEpisodes;
    const latestEpisodesMobile = sortedEpisodes.slice(0, 3);

    this.latestEpisodes = latestEpisodes;
    this.latestEpisodesMobile = latestEpisodesMobile;
  }

  updateState = () => {
    if (this._isMounted) {
      this.setState({
        currShow: this.currentShow,
        relatedShows: this.relatedShows,
        latestEpisodes: this.latestEpisodes,
        latestEpisodesMobile: this.latestEpisodesMobile,
        totalEpisodes: this.latestEpisodes.length,
        isLoading: false,
      });
    }
  }

  handlePagination = (page) => {
    this.setState({ currentPage: page });
  }

  sendtoShows = () => {
    const { history, match: { params: { lang } } } = this.props;
    history.push(`/${lang}/radio/shows`);
  }

  render() {
    const {
      isLoading, currShow, relatedShows, latestEpisodes, currentPage, itemsPerPage,
    } = this.state;

    const { match: { params } } = this.props;

    const relatedText = {
      es: 'PROGRAMAS RELACIONADOS',
      en: 'RELATED SHOWS',
      ca: 'PROGRAMES RELACIONATS',
    };
    const latestText = {
      es: 'EPISODIOS RECIENTES',
      en: 'LATEST EPISODES',
      ca: 'EPISODIS RECENTS',
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = latestEpisodes.slice(indexOfFirstItem, indexOfLastItem);
    return (
      <React.Fragment>
        {currShow && !isLoading ? (
          <>
            <NavigatorRadio title={currShow.title_multilang} margin="self-start mt-9 mb-5 sm:mb-3 sm:mt-3" />
            <div className="w-full flex flex-wrap md:flex-col-reverse sm:flex-col-reverse">
              <div className="sm:w-full md:w-full lg:w-1/3 smlg:w-1/4 w-1/4">
                <Separator borderWidth={4} />
                <MediaTitle category="radio" primaryText={relatedText} margin="my-7.5" />
                <div className="sm:hidden md:flex md:-mx-2">
                  {relatedShows.map(show => (
                    <div key={show.id} className="md:px-2 mb-4"><RadioShowCard {...show} /></div>
                  ))}
                </div>
                <div className="hidden sm:block">
                  <Carousel75 components={relatedShows} />
                </div>
              </div>
              <div className="lg:w-2/3 smlg:w-3/4 w-3/4 sm:w-full mt-4 sm:mt-0 pl-6 sm:pl-0 sm:mb-4 md:w-full md:pl-0 md:mb-4">
                <RadioItemType title={currShow.categories_multilang} category="dark-grey" />
                <TitleLineH1 text={currShow.title_multilang} color="black" margin="mt-6" />
                {currShow.tags_array_multilang
                  && (
                  <div className="flex flex-wrap">
                    {currShow.tags_array_multilang.map((tag, i) => (
                      <Link key={tag.en + i} className="mb-1" to={`${params.lang}/radio/explore/${tag.en}`}>
                        <RadioTag category="transparent" text={tag} textColor="black" borderColor="black" margin="mr-2 py-0 font-americaMonoRegular md:text-11px" />
                      </Link>
                    ))}
                  </div>
                  )}
                {currShow.tags && <TagsWrapper components={currShow.tags} />}
                <MediaDescription imageUrl={currShow.image} classes="my-6" />
                <FavoritesShare />
                <div className="ml-32 sm:ml-0 md:ml-0 mb-24 md:mb-8 sm:mb-8">
                  {currShow.subtitle_multilang && currShow.subtitle_multilang.en
                    ? <TitleLineH2 text={currShow.subtitle_multilang} color="black" />
                    : null}
                  <NormalBodyText classes="py-8 sm:py-0 md:py-0" text={currShow.text_multilang} />
                  {/* <InnerLink linkTo="/" text={fullLinkText} /> */}
                  <RadioItemType title={currShow.categories_multilang} category="radio" />
                </div>
                <div className=" border-t-1 border-black border-dashed" />
                {currentItems && currentItems.length ? <MediaTitle category="radio" primaryText={latestText} /> : null}
                <div className="flex flex-wrap flex-col">
                  {currentItems.map(episode => (
                    <RadioEpisodeListItem {...episode} key={episode.id} />
                  ))}
                </div>
                {latestEpisodes.length > itemsPerPage
                  && (
                  <div>
                    <PaginationComponent page={currentPage} itemsPerPage={itemsPerPage} totalItems={latestEpisodes.length} category="radio" handleClick={this.handlePagination} />
                  </div>
                  )
  }
              </div>
            </div>
          </>
        ) : <div className="h-screen fixed pin-t pin-l w-full flex items-center justify-center"><LoadIcon color="radio" /></div>}
      </React.Fragment>
    );
  }
}

RadioShow.propTypes = {
  episode: PropTypes.shape(),
  show: PropTypes.string,
  radioShowsContext: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

RadioShow.defaultProps = {
  episode: {},
  show: '',
};

export default withRouter(withAllContexts(RadioShow));
