/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link, withRouter } from 'react-router-dom';

import Tag from '../Buttons/Tag';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import ImageLoader from './ImageLoader';

const PlusTag = ({ handleMoreTagClick, isVisibleTags }) => (
  <span
    onClick={handleMoreTagClick}
    className="leading-normal trans hover:bg-white hover:text-black hover:border-white inline-block ml-0 font-americaMonoBold text-xxs cursor-pointer text-white align
    rounded px-2 py-3px bg-black mb-1 border-solid border border-black"
  >
    <span className="uppercase">{isVisibleTags ? '-' : '+'}</span>
  </span>
);
const LauncherItemDouble = ({
  itemLayout, match, title, url, image, date, tags, position, category, changeCategory,
}) => {
  const [hovering, setHovering] = useState('');
  const [isVisibleTags, setVisibilityTags] = useState(false);

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const urlWithSlash = url[0] === '/' || /^https?:\/\//.test(url) ? url : `/${url}`;
        let tagsCount = 0;
        if (tags) {
          tagsCount = tags.length;
        }

        return (
          <div className={`flex launcherItemData items-center ${itemLayout}`}>
            {!position ? (
              <div
                className="w-1/2 overflow-hidden"
                onMouseOver={() => {
                  setHovering('hover');
                }}
                onMouseOut={() => {
                  setHovering('');
                }}
              >
                {' '}
                <Link to={urlWithSlash}>
                  <img src={imageModifier(translateObj(image, localization.language), '400x400')} alt={translateObj(title, localization.language)} />
                </Link>
              </div>
            ) : (
              ''
            )}
            <div className="relative w-1/2 px-6 lg:px-4 items-start justify-center text-black sm:px-5" style={{ overflow: isVisibleTags ? 'visible' : 'hidden' }}>
              {/* {date && <span className="font-americaMonoRegular text-12">{date}</span>} */}
              {title
                && (
                  <h2 className="text-base text-black font-americaBlack my-0 md:mb-2 mb-6 lg:mb-4 md:text-12 sm:text-12 w-full ">
                    <Link to={urlWithSlash}><span className={`text-black active:text-black hover:text-black visited:text-black cursor-pointer hover:underline ${hovering && 'underline'}`}>{translateObj(title, localization.language)}</span></Link>
                  </h2>
                )
              }
              <div className="flex">
                {tags && (
                <div className="flex flex-wrap overflow-hidden" style={{ maxHeight: '25px', maxWidth: '84%' }}>
                  {tags.map((tag, i) => <Tag changeCategory={() => changeCategory(tag)} text={tag} key={`${tag}-${i}`} category={tag} />).slice(0, 2)}
                </div>
                )}
                <div className="flex flex-wrap overflow-hidden">
                  {tagsCount > 2 && <PlusTag handleMoreTagClick={() => setVisibilityTags(!isVisibleTags)} isVisibleTags={isVisibleTags} />}
                </div>
              </div>              {isVisibleTags && (
              <div
                className="tag-box absolute pin-r p-4 bg-white h-100 flex flex-col justify-center"
                style={{
                  top: '50%', transform: 'translateY(-50%)', zIndex: 2,
                }}
              >
                {tags.map((tag, i) => <Tag changeCategory={() => changeCategory(tag)} text={tag} key={`${tag}-${i}`} category={tag} />)}
                <span className="absolute pin-r pin-t pr-1 pt-1 font-icon text-black text-xxs icon-close cursor-pointer" onClick={() => setVisibilityTags(!isVisibleTags)} />
              </div>
              )}
            </div>
            {position ? (
              <div
                className="w-1/2 overflow-hidden"
                onMouseOver={() => {
                  setHovering('hover');
                }}
                onMouseOut={() => {
                  setHovering('');
                }}
              >
                {' '}
                <Link to={urlWithSlash}>
                  <img src={imageModifier(translateObj(image, localization.language), '400x400')} alt={title.en} />
                </Link>
                {' '}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

LauncherItemDouble.propTypes = {
  itemLayout: PropTypes.string.isRequired,
  match: PropTypes.string.isRequired,
  title: PropTypes.shape({
    es: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
}.isRequired;

export default withRouter(LauncherItemDouble);
