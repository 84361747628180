import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import RoutesContext from '../../context/routesContext';
import { useUser } from '../../context/userProfileContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';
import LinkButtonRounded from './Buttons/LinkButtonRounded';
import postNewsletterForm from '../../api/graphql/mutations/postNewsletterForm';


const Newsletter = ({
  match,
  title,
  text,
  caption,
  captionResponse,
  buttonText,
}) => {
  const [value, setValue] = useState('');
  const [canSubmit, setCanSubmit] = useState(true);
  const [didSubmit, setDidSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [isValidStatus, setIsValidStatus] = useState('');

  const {
    userName = '',
    userLastName,
    userEmail = '',
    userNickName,
    userPostalCode,
    userDateOfbirth,
    userCountry,
    loggedFromSocialNetwork,
    isLinkedWithSpotify,
    isUserInNewsLetter,
    isUserInSplio,
    isUserVerified,
    loadTokenForLS = () => {},
  } = useUser();

  function submitForm(formData) {
    setCanSubmit(false);
    const language = 'es';
    const email = formData;
    postNewsletterForm({ email, language, scopes: ['scope_rdl'] }).then((response) => {
      setError(false);
      if (!response.isValid) {
        const emailErrorsArray = ['invalid', 'short', 'long'];
        if (emailErrorsArray.includes(response.email)) {
          setIsValidStatus('emailError');
        } else {
          setIsValidStatus('error');
        }
        setError(true);
        setCanSubmit(true);
      } else {
        setIsValidStatus('valid');
        setValue('');
        setCanSubmit(false);
      }
      setDidSubmit(true);
    });
  }

  let realButtonText = buttonText;
  if (isValidStatus === 'valid') {
    realButtonText = 'Gracias!';
  }
  if (error) {
    realButtonText = 'Error. Prueba otra vez';
  }

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div data-name="newsletter" className="w-full full-width bg-beige pt-6 pb-12 tablet:pt-12 tablet:pb-42px laptop:pt-10 desktop:pt-8 laptop:pb-18 text-red font-stratos mt-8 tablet:mt-42px laptop:mt-0">
            <div key="container" className="px-5 tablet:px-42px laptop:px-24 w-full desktop:mx-auto desktop:pt-2px desktop:max-w-rdl-xxl grid grid-cols-6 gap-3 tablet:grid-cols-12 laptop:gap-4">
              <div className="col-span-6 tablet:col-span-12 laptop:col-start-2 laptop:col-end-12">
                <h3 className="font-bold text-2rem laptop:text-42 desktop:text-50 leading-none mb-4 laptop:mb-7 desktop:mb-6">{translateObj(title, localization.language)}</h3>
                <p className="mb-5 tablet:mb-8 laptop:mb-12 desktop:mb-8 max-w-sm laptop:max-w-md text-base font-light tablet:text-20 laptop:text-base desktop:text-20">{translateObj(text, localization.language)}</p>
                <form className="flex flex-col justify-center items-stretch tablet:flex-row tablet:items-stretch tablet:justify-between laptop:-mb-1">
                  <input
                    required
                    type="email"
                    className="flex-1 bg-beige border-b laptop:border-b-4 border-red placeholder-red font-normal leading-tight text-20 text-red px-0 pt-0 pb-3 laptop:mr-24"
                    placeholder={translateObj(caption, localization.language)}
                    value={value}
                    onChange={event => setValue(event.target.value)}

                  />
                  <button
                    type="submit"
                    className="mt-12 tablet:mt-0 tablet:ml-18 laptop:ml-0"
                    onClick={(event) => {
                      if (isValidStatus === 'valid') return;
                      event.preventDefault();
                      submitForm(value);
                    }}
                  >
                    <LinkButtonRounded
                      text={realButtonText}
                      url=""
                      color="red"
                      isLowercase
                      isDisabled
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default Newsletter;


Newsletter.propTypes = {
  title: PropTypes.shape({
    en: PropTypes.string,
  }).isRequired,
  text: PropTypes.shape({
    en: PropTypes.string,
  }).isRequired,
  caption: PropTypes.shape({
    en: PropTypes.string,
  }).isRequired,
  captionResponse: PropTypes.shape({
    en: PropTypes.string,
  }).isRequired,
  buttonText: PropTypes.shape({
    en: PropTypes.string,
  }).isRequired,
};
