/* eslint-disable camelcase */
import React, {
  useEffect, useState, useRef,
} from 'react';
import Swiper from 'react-id-swiper';
import { PropTypes } from 'prop-types';
import TheaterModeBtn from './TheaterModeBtn';
import StreamingVideoPlayer from '../MultiVideo/StreamingVideoPlayer';
import Chat from '../Chat/Chat';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import { useChatVisibility } from '../Chat/context/chatVisibilityContext';
import { usePrivatePlusZoneContext } from '../Chat/context/privatePlusOneZoneContext';
import { useUser } from '../../../context/userProfileContext';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import ZoneSectionWithinStreamModule from './ZoneSectionWithinStreamModule';
import { useDecideStreamingSourceTiming, useCalculateTimeToShow } from './streamingUtils/streamingTimeHooks';
// import zoneSectionObject from './zoneSectionObject';

const StreamModule = ({
  addeventId,
  handleWithIframe,
  chatChannelID,
  qaChannelID,
  moderators,
  chatRules,
  shouldInitPrivateChat,
  hideRadio,
  hidePlayer,
  showPlayer,
  setPlayerPosition,
  source,
  smalHlsVideoUrl,
  coverImageUrl,
  initialCoverImage,
  finalCoverImage,
  streamObjects,
  intro,
  zones,
  streamStartDateAndTime,
  streamEndDateAndTime,
  initialSource,
  finalSource,
  finalSourceCover,
  requiredRole,
}) => {
  const { userRoles } = useUser();
  const [hasRequiredRole, sethasRequiredRole] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const [swiper, updateSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [device, setDevice] = useState(null);
  const [streamContentHeight, setStreamContentHeight] = useState(null);
  const {
    isChatOpen, setChatVisibility, isFullscreen, setIsFullscreen, handleExitFullscreen,
  } = useChatVisibility();
  const { privateChatUrl } = usePrivatePlusZoneContext();

  // * logic to validate if user has the required roles
  useEffect(() => {
    if (requiredRole) {
      const userHasRequiredRoles = userRoles.includes(requiredRole) || userRoles.includes('admin');
      if (userHasRequiredRoles) {
        sethasRequiredRole(true);
      } else {
        sethasRequiredRole(false);
      }
    } else {
      sethasRequiredRole(true);
    }
  }, [requiredRole, userRoles]);


  // * logic to handle changing automatically from cover to stream and viceversa
  const streamingSource = source || useDecideStreamingSourceTiming(streamStartDateAndTime, streamEndDateAndTime, initialSource, finalSource, finalSourceCover);

  const responsiveUtility = useRef();
  const streamContentRef = useRef();

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
      setActiveSlide(1);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
      setActiveSlide(0);
    }
  };

  const updateSwiperSettings = () => {
    if (swiper !== null) {
      swiper.update();
    }
  };

  useEffect(() => {
    updateSwiperSettings();
  }, [isChatOpen]);

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setDevice(deviceScreen);
  };

  const getDeviceWidth = () => {
    setStreamContentHeight(streamContentRef.current.clientHeight);
  };

  const handleStreamHeight = () => {
    getDeviceWidth();
  };

  useEffect(() => {
    const isTheScreenDesktopOrTablet = device !== 'sm' && device !== 'md';
    if (!isTheScreenDesktopOrTablet) {
      setIsMobileOrTablet(true);
    } else {
      setIsMobileOrTablet(false);
    }
  }, [device]);

  // Make sure radio player isnt shown on this page
  useEffect(() => {
    setPlayerPosition('pin-b -mb-16');
    hidePlayer();
    hideRadio(true);
    return () => { hideRadio(false); showPlayer(); };
  }, []);

  const handleButtonSlider = (activeSlideFn) => {
    if (activeSlideFn) {
      goPrev();
    } else {
      goNext();
    }
  };

  const handleSetIsFullscreen = () => {
    setIsFullscreen();
    handleStreamHeight();
  };

  // * handle user acceses private zone one link
  useEffect(() => {
    if (privateChatUrl) {
      if (activeSlide === 0) {
        goNext();
      }
    }
  }, [privateChatUrl]);

  useEffect(() => {
    if (device === 'sm') setChatVisibility(false);
  }, [device]);

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} deviceWidthCallback={getDeviceWidth} />
      <div
        className="w-full full-width stream-content-wrapper"
        style={device === 'sm' ? { paddingTop: `${streamContentHeight}px` } : {}}
      >
        <div
          ref={streamContentRef}
          className={`${zones && zones.length && 'flex-col'} bg-black w-full relative sm:flex-wrap md:flex-wrap stream-window-wrapper`}
        >
          <div className="w-full relative bg-black">
            {streamObjects.length > 0
              && (
                <>
                  {(zones && zones.length > 0) && !isMobileOrTablet && hasRequiredRole && (
                  <div>
                    <Swiper getSwiper={updateSwiper} allowTouchMove={false}>
                      <div>
                        <StreamingVideoPlayer
                          device={device}
                          source={streamingSource}
                          videos={streamObjects}
                          smalHlsVideoUrl={smalHlsVideoUrl}
                          coverImageUrl={coverImageUrl}
                          initialCoverImage={initialCoverImage}
                          finalCoverImage={finalCoverImage}
                          activeSlide={activeSlide}
                          addeventId={addeventId}
                        />
                      </div>

                      <div>
                        { !activeSlide && <div className={`${'z-5 bg-black w-full h-full absolute'}`} />}
                        <div className="overflow-auto">
                          <ZoneSectionWithinStreamModule
                            handleWithIframe={handleWithIframe}
                            intro={intro}
                            zones={zones}
                            privatePlusOneZoneUrl={privateChatUrl}
                          />
                        </div>
                      </div>

                    </Swiper>
                    <div className="sm:hidden flex justify-between p-4 mb-2 border-b-4 border-psworld-box">
                      <TheaterModeBtn isOn={isFullscreen} onEnter={handleSetIsFullscreen} onExit={handleExitFullscreen} classes="sm:hidden md:hidden" />
                      <button
                        type="button"
                        className={`text-black font-americaMonoRegular text-center ${!activeSlide ? 'bg-psworld-zone-pink' : 'bg-psworld-zone-pink'} sm:hidden md:block rounded px-2 py-1 text-xs text-black uppercase cursor-pointer trans hover:opacity-85`}
                        onClick={() => { handleButtonSlider(activeSlide); }}
                      >
                        {!activeSlide ? 'Video Chat Rooms' : 'go back'}

                      </button>
                    </div>
                  </div>
                  )
                  }
                  {(!zones || !zones.length || isMobileOrTablet || !hasRequiredRole) && (
                  <div>
                    <StreamingVideoPlayer
                      getDeviceWidth={getDeviceWidth}
                      device={device}
                      source={streamingSource}
                      videos={streamObjects}
                      smalHlsVideoUrl={smalHlsVideoUrl}
                      coverImageUrl={coverImageUrl}
                      activeSlide={activeSlide}
                      initialCoverImage={initialCoverImage}
                      finalCoverImage={finalCoverImage}
                    />
                  </div>
                  )}
                </>
              )
              }
            {
                zones && !streamObjects.length && hasRequiredRole && (
                  <div className="overflow-auto h-full w-full min-h-screen-70">
                    <ZoneSectionWithinStreamModule
                      handleWithIframe={handleWithIframe}
                      intro={intro}
                      zones={zones}
                      privatePlusOneZoneUrl={privateChatUrl}
                    />
                  </div>
                )
              }
          </div>
          {hasRequiredRole && (chatChannelID || qaChannelID) && (
          <div className="flex justify-between w-full px-2 mt-4 pb-4 border-b-4 border-psworld-box lg:hidden xl:hidden">
            <TheaterModeBtn isOn={isFullscreen} onEnter={handleSetIsFullscreen} onExit={handleExitFullscreen} classes="hidden sm:block md:block" />
            <button
              type="button"
              className={`text-black font-americaMonoRegular text-center ${!isChatOpen ? 'bg-psworld-zone-pink' : 'bg-grey-darkest'} sm:block hidden rounded px-2 py-1 text-xs text-black uppercase cursor-pointer trans hover:opacity-85`}
              onClick={() => { setChatVisibility(!isChatOpen); handleStreamHeight(); }}
            >
              {!isChatOpen ? 'Live Chat' : 'X'}
            </button>
          </div>
          ) }
          {/* <div className="sm:hidden">
              <button type="button" className="bg-pink p-4" onClick={() => handleVideoVsJitsiStatus(true)}>1</button>
              <button type="button" className="bg-pink p-4" onClick={() => handleVideoVsJitsiStatus(false)}>2</button>
            </div> */}
          {
              hasRequiredRole && (chatChannelID || qaChannelID) && (
              <Chat {...{
                chatRules, zones, moderators, chatChannelID, qaChannelID, shouldInitPrivateChat, streamContentHeight, device,
              }}
              />
              )
            }
        </div>
      </div>

    </>
  );
};

StreamModule.propTypes = {
  addeventId: PropTypes.string,
  chatChannelID: PropTypes.string,
  qaChannelID: PropTypes.string,
  shouldInitPrivateChat: PropTypes.bool,
  streamObjects: PropTypes.arrayOf(PropTypes.shape({})),
  source: PropTypes.string,
  smalHlsVideoUrl: PropTypes.string,
  coverImageUrl: PropTypes.string,
  handleWithIframe: PropTypes.bool,
  zones: PropTypes.arrayOf(PropTypes.shape()),
  moderators: PropTypes.arrayOf(PropTypes.string),
  intro: PropTypes.PropTypes.shape(),
  streamStartDateAndTime: PropTypes.string,
  streamEndDateAndTime: PropTypes.string,
  initialSource: PropTypes.string,
  finalSource: PropTypes.string,
  finalSourceCover: PropTypes.string,
  requiredRole: PropTypes.string,
  initialCoverImage: PropTypes.PropTypes.shape(),
  finalCoverImage: PropTypes.PropTypes.shape(),
  chatRules: PropTypes.PropTypes.shape(),
};

StreamModule.defaultProps = {
  addeventId: null,
  chatChannelID: null,
  qaChannelID: null,
  shouldInitPrivateChat: true,
  streamObjects: [],
  source: '',
  smalHlsVideoUrl: '',
  coverImageUrl: '',
  handleWithIframe: true,
  zones: null,
  intro: {},
  streamStartDateAndTime: null,
  streamEndDateAndTime: null,
  initialSource: 'cover',
  finalSource: 'vimeo',
  finalSourceCover: 'cover',
  requiredRole: null,
  initialCoverImage: null,
  finalCoverImage: null,
  chatRules: null,
  moderators: [],
};

export default withRadioPlayerContext(StreamModule);


/* <button type="button" className={`ml-2 text-black font-americaMonoRegular text-center ${activeSlide ? 'bg-psworld-zone-pink' : 'bg-grey-darkest'} rounded px-2 py-1 text-xs text-black uppercase cursor-pointer trans hover:opacity-85`} onClick={goNext}>videochat</button> */
