import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const Navigator = ({ location, position, isWhite }) => {
  const links = location.split('/');
  const links32 = location.length > 32 ? `${location.substring(0, 32)}...` : location;
  const linksMobile = links32.split('/');
  const navigatorStyles = position === 'absolute' ? { top: '38px', left: '29px' } : {};
  const classes = position === 'static' ? 'py-6' : '';
  // TODO: rethink this location only gives the url in english,
  // we will need to find a way to translate it, maybe using keys and and translations???

  return (
    <div className={`flex ${position} ${classes}`} style={navigatorStyles}>
      <div className={` ${isWhite ? 'bg-white text-black font-normal ' : 'bg-black text-white font-light'} sm:block text-sm sm:text-xs font-light p-2 hidden `}>
        {linksMobile.map((link, i) => (
          <span key={link}>
            {'>'}
            <a className={`${isWhite ? 'text-black' : 'text-white'} mx-2 font-americaMono ${i === 0 && 'underline'} ${i === links.length - 1 && 'text-pink'}`} href={link}>
              {link.toUpperCase()}
            </a>
          </span>
        ))}
      </div>
      <div className={` ${isWhite ? 'bg-white text-black font-normal' : 'bg-black text-white font-light'} flex text-sm sm:text-xs font-light p-2 sm:hidden `}>
        {links.map((link, i) => (
          <Link key={link} to={`/${link}`}>
            <span className={`${isWhite ? 'text-black' : 'text-white'}`}>{'>'}</span>
            <span className={`${isWhite ? 'text-black' : 'text-white'} mx-2 font-americaMono ${i === 0 && 'underline'} ${i === links.length - 1 && 'text-pink'}`}>{link.toUpperCase()}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

Navigator.propTypes = {
  location: PropTypes.string.isRequired,
  position: PropTypes.string,
  isWhite: PropTypes.bool,
};

Navigator.defaultProps = {
  position: 'absolute',
  isWhite: false,
};

export default withRouter(Navigator);
