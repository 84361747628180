import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
// import { some } from 'async';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import slugify from '../../../util/slugify';
import dictionaryCategories from '../dictionaryCategories';

const MAGAZINE_PAGE_POST_CATEGORY = 'magazine-page';
const HEMEROTECA = 'hemeroteca';

const Tag = ({
  text, margin, textColor, borderColor, category, match, hidePound, changeCategory, url, mainNews,
}) => (
  // color of the tag is determined by the tag category
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      let categoryCssValid = category ? slugify(category) : 'default';
      const linkToOwnCategory = category ? slugify(category) : 'home';
      const validCategories = ['barcelona', 'primavera-pro', 'benidorm', 'weekender', 'los-angeles', 'tours', 'artist', 'porto', 'oporto', 'tickets', 'radio', 'labels', 'nits-del-forum'];
      if (!validCategories.some(item => item === categoryCssValid)) {
        categoryCssValid = 'default';
      }

      // ${textColor ? (categoryCssValid === 'los-angeles' ? 'text-black' : `text-${textColor}`) : categoryCssValid === 'los-angeles' ? 'text-black' : 'text-white'}

      // TODO: change dictionaryCategories to a proper data structure as it should return label and link
      const tt_text = dictionaryCategories[text] ? dictionaryCategories[text] : text;

      // NOTE: pdf magazines have the postCategory magazine-page but we need to link them to hemeroteca
      const link = linkToOwnCategory === MAGAZINE_PAGE_POST_CATEGORY ? HEMEROTECA : linkToOwnCategory;

      return (
        <Link className="inline-block" to={`/${link}`}>
          <span
            className={`uppercase trans bg-black ${textColor ? `text-${textColor}` : 'text-white'} hover:bg-white hover:text-black mr-1 cursor-pointer px-3 py-1`}
          >
            <span className={`font-stratos text-10 tablet:text-12 laptop:text-13 font-bold ${mainNews ? 'laptop:font-bold' : 'laptop:font-semibold'}`}>{`${hidePound ? ' ' : '#'}${tt_text}`}</span>
          </span>
        </Link>
      );
    }}
  </RoutesContext.Consumer>
);

Tag.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.string,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  category: PropTypes.string,
  hidePound: PropTypes.bool,
};

Tag.defaultProps = {
  margin: 'mb-1',
  textColor: 'white',
  category: 'barcelona',
  text: 'music',
  hidePound: true,
};

export default withRouter(Tag);
