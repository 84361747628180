import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const SubtitleLineH2 = ({
  text, align, color, margin, match, slider, mainNews,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div
            className={`font-stratos leading-tight hasLinksAndBolds
              ${align} ${margin} text-${color} ${slider ? 'mb-0 text-22 tablet:text-18 laptop:text-30 desktop:text-34 font-semibold'
              : `text-base tablet:text-18 ${mainNews ? 'laptop:text-20 leading-20 tablet:leading-22 laptop:leading-25' : 'laptop:text-18'} leading-20 tablet:leading-22 text-base tablet:text-18 font-semilight`}
            `}
          >
            {parse(translateObj(text, localization.language))}
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

export default withRouter(SubtitleLineH2);

SubtitleLineH2.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
  match: PropTypes.shape().isRequired,
  slider: PropTypes.bool,
  mainNews: PropTypes.bool,
};

SubtitleLineH2.defaultProps = {
  margin: '',
  color: 'white',
  align: 'align-left',
  slider: false,
  mainNews: false,
};
