/* eslint-disable camelcase */
/**
 * sections post component is like this:
                 {
                    "type" : "BodyChildContainer",
                    "components" : [
                        {
                            "type" : "PsWorldStripeTester",
                            "title" : {
                                "es" : "Donación",
                                "en" : "Donation",
                                "ca" : "Donació"
                            },
                            "productSlugName" : "donation",
                            "donationAmounts" : [
                                1.0,
                                5.0,
                                10.0,
                                20.0
                            ]
                        }
                    ]
                }
 */
import React, { useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';
import api from './psWorldPaymentsApi';
import DatePickerFormsy from '../Login/DatePickerFormsy';
import PsWorldStripeCardFormWrapper from './PsWorldStripeCardFormWrapper';
import PayWithStoredCardStripeWrapper from './PayWithStoredCardStripeWrapper';
import getProducts from '../../../api/graphql/queries/getProducts';
import userSetPaymentMethods from '../../../api/graphql/mutations/userSetPaymentMethods';
import userSetPreferredPaymentMethod from '../../../api/graphql/mutations/userSetPreferredPaymentMethod';
// import userPaymentMethodRemove from '../../../api/graphql/mutations/userPaymentMethodRemove';
import userGetPaymentMethods from '../../../api/graphql/queries/userGetPaymentMethods';
// import getPayments from '../../../api/graphql/queries/getPayments';
import getPaymentForProductGroup from '../../../api/graphql/queries/getPaymentForProductGroup';
import setSubscriptionOptions from '../../../api/graphql/mutations/setSubscriptionOptions';
import validateCoupon from '../../../api/graphql/queries/validateCoupon';

// const productSlugName = 'donation';
// const productSlugName = 'subscription-1-month';

const PsWorldStripeTester = () => {
  const [productGroup, setProductGroup] = useState('rdl-subscription');
  const [product, setProduct] = useState({});
  const [productList, setProductList] = useState([]);
  const [productListOptions, setProductListOptions] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [productListSubscriptionRenewalOptions, setProductListSubscriptionRenewalOptions] = useState([]);
  const [subscriptionRenewalProductId, setSubscriptionRenewalProductId] = useState('');
  const [customerEmail, setCustomerEmail] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(null);
  const [customerPaymentMethodsSelectorOptions, setCustomerPaymentMethodsSelectorOptions] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [paymentProvider, setPaymentProvider] = useState('stripe');
  const [paymentActive, setPaymentActive] = useState(null);
  const [renewSubscription, setRenewSubscription] = useState(false);
  const [presentDestinationName, setPresentDestinationName] = useState('');
  const [presentDestinationEmail, setPresentDestinationEmail] = useState('');
  const [presentDeliveryDate, setPresentDeliveryDate] = useState('');
  const [presentMessage, setPresentMessage] = useState('');
  // const [customerPaymentInfo, setCustomerPaymentInfo] = useState(null);
  // const [publicKey, setPublicKey] = useState(null);
  // const [clientSecret, setClientSecret] = useState(null);
  const [showGetCardInfo, setShowGetCardInfo] = useState(false);
  const [showPayWithStoredCard, setShowPayWithStoredCard] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponValid, setCouponValid] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  // Get available products
  useEffect(() => {
    getProducts(null, productGroup).then((productListReceived) => {
      setProductList(productListReceived);
      const subscriptionRenewalOptions = [];
      const options = productListReceived.map((productOption) => {
        const {
          slugName, title,
        } = productOption;
        const option = (
          <option key={slugName} value={slugName}>
            {title.es}
          </option>
        );

        if (!productOption.productPresent) {
          subscriptionRenewalOptions.push(option);
        }

        return option;
      });
      setProductListOptions(options);
      setProductListSubscriptionRenewalOptions(subscriptionRenewalOptions);
      setSelectedProductId(productListReceived[0].slugName); // Set first item as default payment method
      setProduct(productListReceived[0]);
    });
  }, [paymentActive]);

  // Handle product selection
  useEffect(() => {
    const p = productList.find(item => item.slugName === selectedProductId);
    setProduct(p);
  }, [selectedProductId]);

  // Handle selected productGroup
  useEffect(() => {
    getPaymentForProductGroup(productGroup).then((payment) => {
      if (payment) {
        setPaymentActive(payment);
        setRenewSubscription(payment.renewSubscription);
      }
    });
  }, [productGroup]);

  useEffect(() => {
    if (paymentActive) {
      setSubscriptionRenewalProductId(paymentActive.subscriptionRenewalProductSlugName || paymentActive.productSlugName);
    }
  }, [paymentActive]);

  // Handle payment card selection
  useEffect(() => {
    if (customerPaymentMethods) {
      const options = customerPaymentMethods.map((paymentOption) => {
        const {
          token, card: { last4 }, card: { exp_month }, card: { exp_year },
        } = paymentOption;
        // return { value: id, label: last4 };
        // return `<option value="${token}">**** **** **** ${last4}</option>`;
        return (
          <option key={token} value={token}>
            **** **** ****
            {' '}
            {last4}
            {' '}
            {exp_month}
            /
            {exp_year}
          </option>
        );
      });
      setCustomerPaymentMethodsSelectorOptions(options);
      const preferredMethod = customerPaymentMethods.find(method => method.preferred === true);
      if (preferredMethod) {
        setPaymentMethodId(preferredMethod.token);
      } else if (customerPaymentMethods.length) {
        setPaymentMethodId(customerPaymentMethods[0].token);
      }
    }
  }, [customerPaymentMethods]);

  // Handle user session
  useEffect(() => {
    if (process.env.AUTH_TOKEN) {
      if (typeof localStorage === 'undefined') return;
      const token = localStorage.getItem(process.env.AUTH_TOKEN);
      if (!token || token === 'undefined') {
        setError('No user session. Please login');
        return;
      }

      const { email } = jwt.decode(token);
      setCustomerEmail(email);
    }
  }, [process.env.AUTH_TOKEN]);

  const setPaymentMethodHandler = async (newPaymentMethodId) => {
    setPaymentMethodId(newPaymentMethodId);
    // const result = userSetPreferredPaymentMethod({ token: newPaymentMethodId });
    // setMessage(result.error);
  };

  const getOrCreateCustomer = async (ev) => {
    ev.preventDefault();
    setError('');
    setMessage('');
    if (!customerEmail) {
      setError('Please log in to get customer info');
      return;
    }
    api.getOrCreateCustomer(customerEmail).then((customerData) => {
      setCustomerInfo(customerData);
      if (!customerData || !Object.keys(customerData).length) {
        setMessage(`Customer ${customerEmail} could not be retrieved nor created`);
        return;
      }

      setMessage('Customer OK');
    });
  };

  // const getCustomerSetupIntents = async (ev) => {
  //   ev.preventDefault();
  //   setError('');
  //   setMessage('');
  //   if (!customerEmail) {
  //     setError('Please log in to get payment methods');
  //     return;
  //   }
  //   if (!customerInfo || !customerInfo.id) {
  //     setError('Please get customer info first');
  //     return;
  //   }
  //   api.getCustomerSetupIntent(customerInfo.id).then((setupIntent) => {
  //     // setCustomerPaymentInfo(setupIntent);
  //     if (!setupIntent || !setupIntent.length) {
  //       setMessage(`No Customer setup intent info for ${customerEmail}`);
  //       return;
  //     }

  //     setMessage(JSON.stringify(setupIntent));
  //   });
  // };

  const getCustomerPaymentMethods = async () => {
    setError('');
    setMessage('');
    if (!customerEmail) {
      setError('Please log in to get payment methods');
      return;
    }

    const paymentMethods = await userGetPaymentMethods();
    setCustomerPaymentMethods(paymentMethods);
  };

  const getCustomerPaymentMethodsFromStripe = async () => {
    setError('');
    setMessage('');
    if (!customerEmail) {
      setError('Please log in to get payment methods');
      return;
    }
    if (!customerInfo || !customerInfo.id) {
      setError('Please get customer info first');
      return;
    }

    // Get payment methods from api (stripe)
    api.getCustomerPaymentMethods(customerInfo.id).then(async (paymentMethods) => {
      // if (!paymentMethods || !paymentMethods.length) {
      //   setMessage(`No Customer payent methods info for ${customerEmail}`);
      // }
      // Save payment methods to back:
      // [{"id":"pm_1HbiKRLnN1U6bnmqIF8oNpXa","object":"payment_method","billing_details":{"address":{"city":null,"country":null,"line1":null,"line2":null,"postal_code":null,"state":null},"email":null,"name":null,"phone":null},"card":{"brand":"visa","checks":{"address_line1_check":null,"address_postal_code_check":null,"cvc_check":"pass"},"country":"US","exp_month":12,"exp_year":2021,"fingerprint":"Urj9nfSO3phdXZQ9","funding":"credit","generated_from":null,"last4":"4242","networks":{"available":["visa"],"preferred":null},"three_d_secure_usage":{"supported":true},"wallet":null},"created":1602575632,"customer":"cus_HJ3wQPaIeVWSGb","livemode":false,"metadata":{},"type":"card"},{"id":"pm_1GlWqNLnN1U6bnmqoyCK732r","object":"payment_method","billing_details":{"address":{"city":null,"country":null,"line1":null,"line2":null,"postal_code":null,"state":null},"email":null,"name":null,"phone":null},"card":{"brand":"visa","checks":{"address_line1_check":null,"address_postal_code_check":null,"cvc_check":"pass"},"country":"US","exp_month":4,"exp_year":2024,"fingerprint":"Urj9nfSO3phdXZQ9","funding":"credit","generated_from":null,"last4":"4242","networks":{"available":["visa"],"preferred":null},"three_d_secure_usage":{"supported":true},"wallet":null},"created":1590138427,"customer":"cus_HJ3wQPaIeVWSGb","livemode":false,"metadata":{},"type":"card"}]
      // id, card.last4, card.brand, card.exp_month, card.exp_year

      const paymentMethodsSend = paymentMethods.map((method) => {
        const {
          id: token,
          card: {
            last4, brand, exp_month, exp_year,
          },
          created,
        } = method;

        const createdAt = new Date(created * 1000);

        return {
          provider: 'stripe',
          token,
          card: {
            last4,
            brand,
            exp_month,
            exp_year,
          },
          createdAt,
        };
      });
      // Save payment methods in our database
      await userSetPaymentMethods({ provider: 'stripe', paymentMethods: paymentMethodsSend }); // TODO: get the preferred method from response and set it with setPaymentMethodId()
      setCustomerPaymentMethods(paymentMethodsSend);
    });
  };

  const setPreferredPaymentMethod = async () => {
    setPaymentMethodId(paymentMethodId);
    const result = userSetPreferredPaymentMethod({ token: paymentMethodId });
    setMessage(result.error);
  };

  const removeSelectedPaymentMethod = async () => {
    if (!customerInfo || !customerInfo.id) {
      setError('Please get customer info first');
      return;
    }
    if (!paymentMethodId) {
      setError('Please select a payment method first');
      return;
    }

    // eslint-disable-next-line no-alert
    const r = window.confirm('Do you really want to remove this card?');
    if (r) {
      api.removePaymentMethod(paymentMethodId)
        .then((result) => {
          setMessage(result.error);
          if (result) {
            // Update all paymentMethods
            getCustomerPaymentMethodsFromStripe();
          }
        });
    }
  };

  const getCustomerCardInfo = (ev) => {
    ev.preventDefault();
    if (!customerInfo || !customerInfo.id) {
      setError('Please get customer info first');
      return;
    }
    setError('');
    setMessage('');
    setShowGetCardInfo(true);
  };

  const payWithStoredCard = (ev) => {
    ev.preventDefault();
    if (!customerInfo || !customerInfo.id) {
      setError('Please get customer info first');
      return;
    }
    setError('');
    setMessage('');
    setShowPayWithStoredCard(true);
  };

  const payWithStoredCardResult = (result) => {
    const { status } = result;

    if (status === 'succeeded') {
      setShowPayWithStoredCard(false);
      setMessage('Thank you, payment finished');
      return;
    }

    setMessage('Payment failed');
  };

  const storeNewCardResult = (result) => {
    const { status } = result;

    if (status === 'succeeded') {
      setShowGetCardInfo(false);
      getCustomerPaymentMethodsFromStripe();
      setMessage('Card stored. Select it to pay');
      return;
    }

    setMessage('Payment failed');
  };

  const toggleRenewSubscription = (value) => {
    if (paymentActive) {
      setRenewSubscription(value);
      paymentActive.renewSubscription = value;
      setPaymentActive(paymentActive);
      setSubscriptionOptions({ productGroup, renewSubscription: value });
    }
  };

  const handleSubscriptionRenewalChangeProduct = () => {
    if (paymentActive && subscriptionRenewalProductId) {
      setSubscriptionOptions({ productGroup, subscriptionRenewalProductSlugName: subscriptionRenewalProductId });
    }
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleValidateCoupon = async () => {
    const { slugName: productSlugName } = product;
    const result = await validateCoupon({ couponCode, productSlugName });
    setError(result.error);
    setMessage(result.valid ? 'Valid code' : 'Code not found or expired');
    setCouponValid(result.valid);
  };

  // const handleSubmit = async (ev) => {
  //   ev.preventDefault();
  // };

  return (
    <div style={{ width: '600px' }}>
      <p>
        Payment tester
      </p>

      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-3/4">
          Select subscription:
          <select onChange={newValue => setSelectedProductId(newValue.currentTarget.value)}>
            {productListOptions}
          </select>

          {product && product.productPresent && (
            <>
              <input type="text" placeholder="Nombre del destinatario" className="leading-22 px-6 mr-4 sm:mr-0 font-americaMonoRegular text-12 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-92 sm:w-full xl:w-92 lg:w-92 md:w-92" value={presentDestinationName} onChange={newValue => setPresentDestinationName(newValue.currentTarget.value)} />
              <input type="text" placeholder="Email del destinatario" className="leading-22 px-6 mr-4 sm:mr-0 font-americaMonoRegular text-12 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-92 sm:w-full xl:w-92 lg:w-92 md:w-92" value={presentDestinationEmail} onChange={newValue => setPresentDestinationEmail(newValue.currentTarget.value)} />
              <input type="text" placeholder="Fecha de entrega (yyyy-mm-dd hh:mm)" className="leading-22 px-6 mr-4 sm:mr-0 font-americaMonoRegular text-12 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-92 sm:w-full xl:w-92 lg:w-92 md:w-92" value={presentDeliveryDate} onChange={newValue => setPresentDeliveryDate(newValue.currentTarget.value)} />
              <textarea
                placeholder="Texto opcional que se enviará al destinatario"
                onChange={newValue => setPresentMessage(newValue.currentTarget.value)}
                value={presentMessage}
                className="mt-4 rounded-lg border-1 p-4 h-64 bg-inherit"
              />
            </>
          )}
        </div>
      </div>

      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col w-3/4">
          Please select payment method:
          <select onChange={newValue => setPaymentProvider(newValue.currentTarget.value)}>
            <option value="stripe">Stripe</option>
            <option value="paypal">Paypal</option>
          </select>
        </div>
      </div>

      {subscriptionRenewalProductId && (
        <fieldset style={{ border: '1px solid black', padding: '10px' }} className="sm:block md:block">
          <label htmlFor="showFilter">
            Auto renew subscription
            <input
              className="switch"
              type="checkbox"
              onChange={event => toggleRenewSubscription(event.currentTarget.checked)}
              checked={renewSubscription}
            />
          </label>
          <legend>Change your subscription plan</legend>
          <div className="flex mt-8 sm:mt-0">
            <div className="flex flex-col w-3/4">
              <select value={subscriptionRenewalProductId} onChange={newValue => setSubscriptionRenewalProductId(newValue.currentTarget.value)}>
                {productListSubscriptionRenewalOptions}
              </select>
              <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={() => handleSubscriptionRenewalChangeProduct()}>
                Change your subscription to selected product
              </button>
            </div>
          </div>
        </fieldset>
      )}

      <div className="flex mt-8 sm:mt-0">
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="w-full text-red-600" role="alert">
            Error:
            {' '}
            {error}
          </div>
        )}
        {message && (
          <div className="w-full">
            {message}
          </div>
        )}
      </div>

      <fieldset style={{ border: '1px solid black', padding: '10px', display: paymentProvider === 'stripe' ? 'block' : 'none' }} className="sm:block md:block">
        <legend>Stripe</legend>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={getOrCreateCustomer}>
              Get or create customer
            </button>
          </div>
        </div>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={getCustomerPaymentMethods}>
              Get customer existing payment methods
            </button>
          </div>
        </div>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={getCustomerPaymentMethodsFromStripe}>
              Refresh customer payment methods (get info from Stripe)
            </button>
          </div>
        </div>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            Select one of your stored cards:
            <select value={paymentMethodId} onChange={newValue => setPaymentMethodHandler(newValue.currentTarget.value)}>
              {customerPaymentMethodsSelectorOptions}
            </select>
          </div>
        </div>

        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={setPreferredPaymentMethod}>
              Set selected payment method as preferred
            </button>
          </div>
        </div>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={removeSelectedPaymentMethod}>
              Remove selected payment method
            </button>
          </div>
        </div>

        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={getCustomerCardInfo}>
              Get customer card
            </button>
          </div>
        </div>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={payWithStoredCard}>
              Pay with stored card
            </button>
          </div>
        </div>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            {showGetCardInfo && (
              <PsWorldStripeCardFormWrapper customerInfo={customerInfo} product={product} resultCallback={storeNewCardResult} />
            )}
          </div>
        </div>

        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <input type="text" placeholder="Coupon code" onChange={handleCouponChange} className="px-6 mr-4 sm:mr-0 font-americaMonoRegular text-12 rounded-full border-1 p-4 bg-inherit border-black text-black text-12 sm:w-92 sm:w-full xl:w-92 lg:w-92 md:w-92" value={couponCode} />
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={handleValidateCoupon}>
              Validate coupon
            </button>
          </div>
        </div>

        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            {showPayWithStoredCard && (
              <PayWithStoredCardStripeWrapper
                customerInfo={customerInfo}
                paymentMethodId={paymentMethodId}
                product={product}
                resultCallback={payWithStoredCardResult}
                couponCode={couponCode}
                couponValid={couponValid}
                presentData={{
                  presentDestinationName,
                  presentDestinationEmail,
                  presentDeliveryDate,
                  presentMessage,
                }}
              />
            )}
          </div>
        </div>
      </fieldset>


      <fieldset style={{ border: '1px solid black', padding: '10px', display: paymentProvider === 'paypal' ? 'block' : 'none' }} className="sm:block md:block">
        <legend>Stripe</legend>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={getOrCreateCustomer}>
              Get or create customer
            </button>
          </div>
        </div>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" onClick={payWithStoredCard}>
              Pay
            </button>
          </div>
        </div>
        <div className="flex mt-8 sm:mt-0">
          <div className="flex flex-col w-3/4">
            {showPayWithStoredCard && (
              <PayWithStoredCardStripeWrapper
                customerInfo={customerInfo}
                paymentMethodId={paymentMethodId}
                product={product}
                resultCallback={payWithStoredCardResult}
                couponCode={couponCode}
                couponValid={couponValid}
                presentData={{
                  presentDestinationName,
                  presentDestinationEmail,
                  presentDeliveryDate,
                  presentMessage,
                }}
              />
            )}
          </div>
        </div>
      </fieldset>
      <fieldset style={{ border: '1px solid black', padding: '10px' }} className="sm:block md:block">
        <legend>Cron url's</legend>
        <a href={`${process.env.PS_REST_SERVER}/payments/update-subscription-user-roles`} rel="noopener noreferrer" target="_blank">
          Update user subscription roles
        </a>
        <br />
        <a href={`${process.env.PS_REST_SERVER}/payments/renew-subscriptions`} rel="noopener noreferrer" target="_blank">
          Renew all users subscriptions if renewSubscription = true and dateEnd before today
        </a>
      </fieldset>
    </div>
  );
};

PsWorldStripeTester.propTypes = {
};

PsWorldStripeTester.defaultProps = {
};

export default PsWorldStripeTester;
