import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const GET_PAYMENT_METHODS_QUERY = gql`
  query Get($fetchDate: String) {
    userGetPaymentMethods(fetchDate: $fetchDate) {
      provider
      preferred
      card {
        last4
        brand
        exp_month
        exp_year
      }
      token
      fetchDate
    }
  }
`;

const userGetPaymentMethods = async () => {
  try {
    const result = await clientWeb
      .query({
        query: GET_PAYMENT_METHODS_QUERY,
        variables: { fetchDate: new Date().getTime().toString() },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { userGetPaymentMethods: userGetPaymentMethodsData },
    } = result;

    if (userGetPaymentMethodsData) {
      return userGetPaymentMethodsData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: userGetPaymentMethods`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: userGetPaymentMethods Error: ${error}`);
    return null;
  }
};

export default userGetPaymentMethods;
