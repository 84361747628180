import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const COMMENTS_CREATE = gql`
  mutation Post($postSlugName: String!, $commentText: String!) {
    commentCreate(postSlugName: $postSlugName, commentText: $commentText) {
      result
      error
    }
  }
`;

const commentCreate = async (postSlugName, commentText) => {
  try {
    const result = await clientWeb
      .mutate({
        mutation: COMMENTS_CREATE,
        variables: { postSlugName, commentText },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { commentCreate: commentCreateData },
    } = result;

    if (commentCreateData) {
      return commentCreateData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: commentCreate`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: commentCreate Error: ${error}`);
    return null;
  }
};

export default commentCreate;
