import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const PretitleLineH2 = ({
  text, align, color, margin, match, slider,
}) => (
  <RoutesContext.Consumer>
    {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <h2 className={`font-stratos mb-1 font-bold uppercase text-12 laptop:text-13 desktop:text-15 text-${color} ${align} ${margin}`}>
              {translateObj(text, localization.language)}
            </h2>
          );
        }
      }
  </RoutesContext.Consumer>
);

export default withRouter(PretitleLineH2);

PretitleLineH2.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape().isRequired,
  slider: PropTypes.bool,
};

PretitleLineH2.defaultProps = {
  margin: '',
  color: 'white',
  align: 'align-left',
  slider: false,
};
