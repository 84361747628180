import React from 'react';
import { PropTypes } from 'prop-types';
import {
  TimelineMax,
  Power1,
  TweenMax,
} from 'gsap';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import BodyChildContainer from '../../containers/BodyChildContainer';
import translateObj from '../../util/translateObj';
import SearchNumberOfResultsFound from './SearchNumberOfResultsFound';
import SearchBasicLayout from './SearchBasicLayout';
import SearchRepeat from './SearchRepeat';

const coincidences = [
  {
    label: {
      en: 'Conciertos',
      ca: 'Conciertos',
      es: 'Conciertos',
    },
    url: {
      en: 'conciertos',
      ca: 'conciertos',
      es: 'conciertos',
    },
  },
  {
    label: {
      en: 'Barcelona',
      ca: 'Barcelona',
      es: 'Barcelona',
    },
    url: {
      en: 'barcelona',
      ca: 'barcelona',
      es: 'barcelona',
    },
  },
  {
    label: {
      en: 'Oporto',
      ca: 'Oporto',
      es: 'Oporto',
    },
    url: {
      en: 'oporto',
      ca: 'oporto',
      es: 'oporto',
    },
  },
];

function capitalizeFirstLetter(str) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

class SearchOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      enterQuery: null,
      coincidence: false,
      selectedCoincidence: false,
    };
    this.input = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
    this.body = document.querySelector('body');
    this.body.setAttribute('style', 'overflow-y: auto');
    this.animateOverlay('showOverlay');
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
    this.body.setAttribute('style', 'overflow-y: auto');
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      const { closeCallback } = this.props;
      closeCallback();
    }
  }

  handleChange = (localization, e) => {
    const query = e.target.value;
    let coincidence = false;
    let selectedCoincidence = false;
    if (query.length > 0) {
      this.setState({ query });
      coincidence = '';
      coincidences.forEach((option) => {
        if (option.label[localization.language]) {
          const optionLabel = option.label[localization.language];
          if (optionLabel.toLowerCase().startsWith(query.toLowerCase())) {
            coincidence = optionLabel;
            selectedCoincidence = option;
          }
        }
      });
    }
    this.setState({ query, coincidence, selectedCoincidence });
  }

  handleKeyPress = (localization, e) => {
    const { selectedCoincidence, query } = this.state;
    // REVISAR - cuando se buscan articulos ("La", "El", etc), peta la página de búsqueda
    if (query.length > 0) {
      if (e.key === 'Enter' && selectedCoincidence) {
        const { searchCallback } = this.props;
        this.setState({ enterQuery: query });
        searchCallback(selectedCoincidence.url[localization.language], localization.language);
      } else {
        if (e.key === 'Enter') {
          const { searchCallback } = this.props;
          this.setState({ enterQuery: query });
          searchCallback(query, localization.language);
        }
      }
    }
  }

  rotateClose = (entering = true) => {
    TweenMax.to('[data-name="searchoverlay"] [data-name="close"]', 0.4, {
      rotation: entering ? 180 : 0,
    });
  }

  animateOverlay(type = 'showOverlay') {
    if (type === 'showOverlay') {
      const ease = Power1.easeOut;
      const tl = new TimelineMax();

      tl.fromTo('[data-name="searchoverlay"] > div', 0.5, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        ease,
      });

      tl.fromTo('[data-name="searchoverlay"] [data-name="title"]', 0.7, {
        y: '20%',
      }, {
        y: '0%',
        ease,
      }, '-=0.2');
      tl.fromTo('[data-name="searchoverlay"] [data-name="title"]', 1, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        ease,
      }, '-=0.7');

      tl.fromTo('[data-name="searchoverlay"] [data-name="close"]', 0.7, {
        autoAlpha: 0,
        y: '20%',
      }, {
        autoAlpha: 1,
        y: '0%',
        ease,
      }, '-=0.4');

      tl.eventCallback('onComplete', () => {
        if (this.input.current) {
          this.input.current.focus();
        }
      });
    }

    if (type === 'hideOverlay') {
      const {
        closeCallback,
      } = this.props;
      const tl = new TimelineMax();

      tl.fromTo('[data-name="searchoverlay"]', 0.5, {
        autoAlpha: 1,
      }, {
        autoAlpha: 0,
      });

      tl.eventCallback('onComplete', () => {
        closeCallback();
      });
    }
  }

  render() {
    const {
      match, closeCallback, searchCallback, numberOfResults, results, isLoading,
    } = this.props;
    const title = {
      en: 'Search',
      ca: 'Cercar',
      es: 'Buscar',
      pt: 'Pesquisa',
    };
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          const { query, coincidence, enterQuery } = this.state;
          return (
            <div className="static w-full pin-t pin-l pin-r bg-white z-top transition-background-04s" data-name="searchoverlay">

              <div className="w-full flex flex-column items-center relative">
                <span
                  data-name="close"
                  className="cursor-pointer absolute pin-t pin-r mr-2 tablet:mr-26px laptop:mr-20 mt-24 tablet:mt-28 laptop:mt-32 desktop:mt-36 z-10"
                  onClick={closeCallback}
                  onMouseEnter={() => this.rotateClose(true)}
                  onMouseLeave={() => this.rotateClose(false)}
                >
                  <span className="font-icon icon-close text-black text-lg" />
                </span>
                <BodyChildContainer noHorizontalPadding>
                  <>
                    <div className="mt-16 tablet:mt-20 laptop:mt-36 desktop:mt-32 tablet:mx-26px laptop:mx-16" data-name="title">
                      <span className="font-stratos text-black mb-8 inline-block text-20 font-semibold">{translateObj(title, localization.language)}</span>
                      <div className="relative">
                        <span
                          className="text-110 sm:text-40 text-black font-stratos select-none"
                        >
                          {coincidence ? capitalizeFirstLetter(coincidence) : ''}
                        </span>
                        <input
                          ref={this.input}
                          type="text"
                          className="appearance-none w-full outline-none font-stratos text-38 font-semibold bg-transparent text-black absolute pin-t pin-l pin-r border-b border-black border-solid"
                          style={{ caretColor: '#FF4646' }}
                          value={capitalizeFirstLetter(query)}
                          onChange={e => this.handleChange(localization, e)}
                          onKeyPress={e => this.handleKeyPress(localization, e)}
                        />
                      </div>
                    </div>
                    {!isLoading && (
                    <div className="tablet:mx-26px laptop:mx-16">
                      {numberOfResults !== 0 || query.length > 0 ? <SearchNumberOfResultsFound numberOfResults={numberOfResults} searchQuery={enterQuery} /> : null}
                      <SearchBasicLayout results={results} match={match} search={query} />
                      {/* <SearchRepeat handleEnter={searchCallback} /> */}
                    </div>
                    )}
                  </>
                </BodyChildContainer>
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

SearchOverlay.propTypes = {
  match: PropTypes.shape().isRequired,
  closeCallback: PropTypes.func.isRequired,
  searchCallback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // coincidences: PropTypes.array.isRequired,
};

export default withRouter(SearchOverlay);
