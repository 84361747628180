import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import Ticket from "./Ticket";
import RoutesContext from "../../context/routesContext";
import getLocalization from "../../util/localization";
import translateObj from "../../util/translateObj";
import CarouselTickets from "./CarouselTickets";
import MediaTitle from "./MediaTitle";

const SidebarNextDates = ({ match, data, headLine, isConcert }) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <React.Fragment>
          <MediaTitle
            primaryText={headLine}
            category="black"
            margin="mt-30px mb-4"
          />
          {/* <p className="font-americaMonoBold text-base my-7.5 uppercase">{translateObj(headLine, localization.language)}</p> */}
          <div className="md:flex md:flex-wrap md:-mx-2">
            <CarouselTickets ticketData={data} isGrid isConcert={isConcert} />
            {/* {data && data.map(ticket => <Ticket {...ticket} key={ticket.dateTimeStartReal} />)} */}
          </div>
        </React.Fragment>
      );
    }}
  </RoutesContext.Consumer>
);

SidebarNextDates.propTypes = {
  match: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isConcert: PropTypes.bool
};
SidebarNextDates.defaultProps = {
  data: null,
  isConcert: false
};
export default withRouter(SidebarNextDates);
