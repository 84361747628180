import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const PURCHASE_WITH_PAYPAL = gql`
  mutation Post($productSlugName: String!, $donationAmount: Int, $couponCode: String, $presentData: String, $paypalResponse: String) {
    purchaseWithPaypal(
      productSlugName: $productSlugName
      donationAmount: $donationAmount
      couponCode: $couponCode
      presentData: $presentData
      paypalResponse: $paypalResponse
      ){
        result
        error
    }
}`;

const purchaseWithPaypal = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: PURCHASE_WITH_PAYPAL,
      variables: {
        ...formData,
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { purchaseWithPaypal: validatedFormData } } = result;
    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: purchaseWithPaypal Error: ${error}`);
    return null;
  }
};

export default purchaseWithPaypal;
