import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MediaAlternative from './MediaAlternative';

import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';

class LogosRowModule extends PureComponent {
  state = {
    linksToShow: [],
  };

  componentDidMount = () => {
    const { slugnames } = this.props;
    this.fetchPostDrescriptionsBySlugnames(slugnames);
  };

  fetchPostDrescriptionsBySlugnames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((response) => {
        if (response) {
          this.setState({
            linksToShow: response,
          });
        } else {
          console.log('LogosRowModule no active posts were returned');
        }
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      });
  };

  _insertElements = () => {
    const { linksToShow } = this.state;

    const { slugnames, height, width } = this.props;

    const mediaComponents = [];
    const {
      match: { params },
    } = this.props;
    if (linksToShow && linksToShow.length > 0) {
      linksToShow.map((obj, index) => {
        const { slugName, postDescription } = obj;
        const { image, title, url } = postDescription || { image: { en: 'https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png', title: { en: '' } } };
        mediaComponents.push(<MediaAlternative text={{ en: '' }} height={height} width={width} key={`slugName_${index}`} image={image} slug={url || slugName} />);
      });
    } else {
      // This is for when normally is loading the elements or when something nasty happend on fetch
      // TODO : create some fade animations
      // TODO : Replace by a default image grey image
      slugnames.map((obj, i) => {
        mediaComponents.push(
          <MediaAlternative
            key={obj}
            image={{ en: 'https://assets.primaverasound.com/web/assets/default_image_placeholder_light_grey.png' }} // TO-DO : This needs to be an Language Object
            text={{ en: '' }}
            slug={params.section === 'editions' ? `${params.section}/${slugnames[i]}` : slugnames[i]}
            height={height}
            width={width}
          />,
        );
      });
    }

    return mediaComponents;
  };

  render() {
    const { column, color } = this.props;

    return (
      <div className="sm:w-full w-7/8">
        <div className={`${column && 'flex-col'} bg-${color || 'transparent'} flex flex-wrap sm:justify-center ${!column && '-mx-2'}`}>{this._insertElements()}</div>
      </div>
    );
  }
}

LogosRowModule.propTypes = {
  slugnames: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  column: PropTypes.bool,
  color: PropTypes.string,
};

LogosRowModule.defaultProps = {
  slugnames: ['home'],
  column: false,
  color: '',
};

export default withRouter(LogosRowModule);
