import React from 'react';
import PropTypes from 'prop-types';
import { PayPalButton } from 'react-paypal-button-v2';
import purchaseWithPaypal from '../../../api/graphql/mutations/purchaseWithPaypal';

const PaypalSubscriptionButton = ({
  clientId,
  currency,
  planId,
  amount,
  product,
  resultCallback,
  setShowPayWithPaypal,
  couponCode,
  presentData,
  setSucceeded,
  setProcessing,
  setError,
}) => (
  <PayPalButton
    options={{
      clientId,
      currency,
      disableFunding: 'credit,card',
      vault: true,
      intent: 'subscription',
    }}
    shippingPreference="NO_SHIPPING"
    createSubscription={(data, actions) => actions.subscription.create({
      plan_id: planId,
    })}
    createOrder={(data, actions) => actions.order.create({
      purchase_units: [{
        amount: {
          currency_code: currency,
          value: amount,
          description: product.title.en,
        },
      }],
      application_context: {
        shipping_preference: 'NO_SHIPPING', // default is "GET_FROM_FILE"
      },
    })} // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
    catchError={(err) => {
      console.log('PayPalButton catchError:', err);
      resultCallback({
        err,
        status: 'paymentError',
      });
      setShowPayWithPaypal(false);
    }}
    onError={(err) => {
      console.log('PayPalButton onError:', err);
      resultCallback({
        err,
        status: 'systemError',
      });
      setShowPayWithPaypal(false);
    }}
          // eslint-disable-next-line arrow-body-style
    onApprove={(data, actions) => {
    // Capture the funds from the transaction
      return actions.subscription.get().then((details) => {
      // Subscription completed
        purchaseWithPaypal({
          productSlugName: product.slugName,
          couponCode,
          presentData: JSON.stringify(presentData),
          paypalResponse: JSON.stringify({ details, data }),
        }).then((response) => {
          if (response.result) {
            setSucceeded(true);
            setProcessing(false);
            resultCallback({
              status: 'succeeded',
            });
          } else if (response.error) {
          // Oh no! The user payed but we could not save it!!!
            setSucceeded(false);
            setError(response.error);
          }
        });
      });
    }}
  />
);

PaypalSubscriptionButton.propTypes = {
  clientId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  planId: PropTypes.string,
  amount: PropTypes.number.isRequired,
  product: PropTypes.shape({}).isRequired,
  resultCallback: PropTypes.func.isRequired,
  setShowPayWithPaypal: PropTypes.func.isRequired,
  couponCode: PropTypes.string.isRequired,
  presentData: PropTypes.shape({
    presentDestinationName: PropTypes.string,
    presentDestinationEmail: PropTypes.string,
    presentDeliveryDate: PropTypes.instanceOf(Date),
    presentMessage: PropTypes.string,
  }),
  setSucceeded: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

PaypalSubscriptionButton.defaultProps = {
  planId: '',
  presentData: null,
};

export default PaypalSubscriptionButton;
