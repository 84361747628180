import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import InputTextButtonSimple from '../InputTextButtonSimple';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import RoutesContext from '../../../context/routesContext';
import PSSelect from './PSSelect';

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-xs" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down font-black text-white text-xs" />;
// const { isFavoritesActive } = process.env;
const isFavoritesActive = true;

const debounce = (func, delay) => {
  let inDebounce;
  return (...args) => {
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const throttle = (func, limit) => {
  let inThrottle;
  return (...args) => {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

class LineupFilter extends React.Component {
  constructor(props) {
    super(props);
    const { activeVenues } = props;
    this.state = {
      venuesValue: activeVenues,
      open: true,
    };
    this.handleSearchFilter = debounce(this.handleSearchFilter, 300);
    this.handleScroll = throttle(this.handleScroll, 50);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { open } = this.state;
    const parentEl = document.querySelector('.lineUpFilterWrapper');
    if (parentEl && parentEl.getBoundingClientRect().top === 0 && open) {
      this.setState({ open: false });
    }
  };

  setOpen = (open) => {
    this.setState({ open });
  };

  handleChangeMultipleVenues = (venuesValue) => {
    this.setState({
      venuesValue,
    });
    const { filterVenueSelected } = this.props;
    filterVenueSelected(venuesValue);
  };

  handleChangeMultipleDays = (event) => {
    const { updateActiveDays } = this.props;
    updateActiveDays(event.target.value);
  };

  handleSearchFilter = (searchValue) => {
    const { updateSearchFilter } = this.props;
    updateSearchFilter(searchValue);
  };

  handleOpenMenu = () => {
    const { open } = this.state;
    if (window && window.scrollY > 100) {
      window.scrollTo(0, 100);
    }
    this.setState({ open: !open });
  }

  render() {
    const {
      activeDays,
      match,
      dates,
      tags,
      activeTags,
      filterDaySelected,
      filterTagSelected,
      text0,
      text1,
      // text2,
      text3,
      text4,
      text5,
      text6,
      text7,
      text8,
      text9,
      text10,
      text11,
      text14,
      text15,
      icon2Url,
      icon3Url,
      icon4Url,
      venues,
      activeViewMode,
      updateViewMode,
      updateView,
      activeView,
      searchValue,
      showList,
      showTable,
      showGrid,
      showPoster,
      showDate,
      showDateTime,
      multipleLineUpsOnPage,
    } = this.props;

    const { venuesValue, open } = this.state;

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          const ViewButton = ({ title, icon, view }) => (
            <div onClick={() => updateView(view)} className={`cursor-pointer flex h-8 sm:h-10 items-center rounded-lg p-2 border-solid border-white border-1 ${activeView === view ? 'bg-white text-black' : ''}`}>
              <Checkbox1 view={view} />
              {icon ? <span className={`ml-1 text-sm font-icon ${icon} ${activeView === view ? 'text-black' : 'text-white '}`} /> : null}
              <span className="pl-1">{title}</span>
            </div>
          );

          const ViewModeButton = ({ title, icon, viewMode }) => (
            <div className="cursor-pointer flex items-center h-8 lg:text-sm md:text-sm sm:text-sm " onClick={() => updateViewMode(viewMode)}>
              <Checkbox2 viewMode={viewMode} />
              {icon ? <span className={`ml-1 text-white text-sm font-icon ${icon}`} /> : null}
              <span className="pl-1">{title}</span>
            </div>
          );

          const Checkbox1 = ({ view }) => <div className="cursor-pointer w-4 h-4 rounded-full bg-black border-solid border-white border-1 flex items-center justify-center">{activeView === view ? <div className="w-2 h-2 bg-radio rounded-full" /> : null}</div>;

          const Checkbox2 = ({ viewMode }) => <div className="cursor-pointer w-4 h-4 rounded-full bg-white flex items-center justify-center">{activeViewMode === viewMode ? <div className="w-2 h-2 bg-radio rounded-full" /> : null}</div>;

          const ActiveFilter = ({ title, callback }) => (
            <span className="bg-gray200 py-2 px-3 mr-2 mb-2 font-americaMonoBold text-xxs rounded-lg flex items-center uppercase text-white">
              <div onClick={callback} className="w-2 mr-2">
                <span className="font-icon text-white text-xxs icon-close cursor-pointer" />
              </div>
              {title}
            </span>
          );

          const Day = ({ active, day }) => (
            <div onClick={() => filterDaySelected(day)} className={`cursor-pointer rounded-lg h-8 flex items-center border-white border-solid border-1 px-5 py-1 text-xs mr-3 mb-3 ${active ? 'font-americaMonoBold text-black bg-white' : ''}`}>
              <span>{day}</span>
            </div>
          );

          const Tag = ({ active, tag }) => (
            <div onClick={() => filterTagSelected(tag)} className={`cursor-pointer rounded-lg h-8 flex items-center border-white border-solid border-1 px-5 py-1 text-xs mr-3 mb-3 ${active ? 'font-americaMonoBold text-black bg-white' : ''}`}>
              <span>{tag}</span>
            </div>
          );

          const PdfAndSearchInput = () => (
            <div className="flex">
              {/* <a href="#" className="sm:flex-1 font-americaMonoBold sm:text-10 text-xs black uppercase sm:mr-2 md:mr-2 items-center flex text-black bg-white rounded-full px-4 py-1 mr-4">
                <span className={`font-icon text-black text-xxs mr-2 ${icon5Url}`} />
                <span>{text12[localization.language]}</span>
              </a> */}
              <div className="block sm:hidden">
                <InputTextButtonSimple mobile={multipleLineUpsOnPage} query={searchValue} name="searchQuery" params={{ placeholder: text11, text: text11, searchIcon: 'font-icons icon-search' }} black handleOnChange={this.handleSearchFilter} />
              </div>
              <div className="hidden sm:block w-full">
                <InputTextButtonSimple mobile query={searchValue} name="searchQuery" params={{ placeholder: text11, text: text11, searchIcon: 'font-icons icon-search' }} black handleOnChange={this.handleSearchFilter} />
              </div>
            </div>
          );


          return (
            <div className="pb-12 sm:pb-4 w-full">
              <div className="w-full text-white font-americaMonoRegular bg-black px-5 sm:px-4 py-4 sm:py-2 rounded-b-lg">
                <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <div className="flex w-full justify-between items-center">
                    <div
                      className="flex sm:flex-1 cursor-pointer align-center sm:justify-between"
                      onClick={this.handleOpenMenu}
                    >
                      <ListItemText primary={<span className="font-americaMonoBold uppercase text-white">{translateObj(text0, localization.language)}</span>} />
                      <span className="ml-4">{open ? <ExpandLess /> : <ExpandMore />}</span>
                    </div>
                    <div className="sm:hidden">
                      <PdfAndSearchInput />
                    </div>
                  </div>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <div className="pb-4 sm:pb-0">
                    <div className="pt-6 sm:pt-4 mb-6 sm:mb-4 mt-4 sm:mt-2 border-dotted border-white border-t-1">
                      <div className="hidden sm:block sm:pb-4">
                        <PdfAndSearchInput />
                      </div>
                      {dates.length > 0 && dates.length !== 1 && dates[0] !== 'Invalid date' && (
                        <>
                          <span className="block uppercase mb-6 sm:mb-4 sm:hidden">{translateObj(text1, localization.language)}</span>
                          <div className="flex flex-wrap sm:hidden">
                            {dates.map((day) => {
                              const active = activeDays.length !== 0 && activeDays.indexOf(day) > -1;
                              if (showDate) {
                                return <Day key={day} day={day === 'Invalid date' ? 'TBD' : day} active={active} />;
                              }
                            })}
                          </div>
                          <div className="hidden sm:block">
                            <PSSelect multiple onChange={this.handleChangeMultipleDays} value={activeDays} options={dates} empty={translateObj(text1, localization.language)} />
                          </div>
                        </>
                      )
                      }
                    </div>
                    <div className="flex sm:block md:mt-6 sm:mt-4">
                      {Object.keys(venues).length > 0 && Object.keys(venues) !== 1 && Object.keys(venues)[0] !== 'tbd' && (
                        <div className="mr-12 sm:mr-0 w-1/4 sm:w-full">
                          <span className="block uppercase mb-6 sm:hidden">{translateObj(text3, localization.language)}</span>
                          <PSSelect
                            multiple
                            onChange={e => this.handleChangeMultipleVenues(e.target.value)}
                            value={venuesValue}
                            options={venues}
                            titleKey={localization.language}
                            empty={text4[localization.language]} // todo text
                          />
                        </div>
                      )}
                      <div className="w-3/4 sm:w-full">
                        <span className="block uppercase mb-6 sm:mb-4 sm:mt-4">{translateObj(text5, localization.language)}</span>
                        <div className="flex flex-wrap">
                          {showList && (
                            <div className="mr-3 sm:mb-3 md:mb-4">
                              <ViewButton view="list" title={translateObj(text6, localization.language)} />
                            </div>
                          )}
                          {showTable && (
                            <div className="mr-3 sm:mb-3 md:mb-4">
                              <ViewButton view="table" title={translateObj(text7, localization.language)} icon={icon2Url} />
                            </div>
                          )}
                          {showGrid && (
                            <div className="mr-3 sm:mb-3 md:mb-4">
                              <ViewButton view="grid" title={translateObj(text8, localization.language)} icon={icon3Url} />
                            </div>
                          )}
                          {showPoster && (
                            <div className="mr-3 sm:mb-3 md:mb-4">
                              <ViewButton view="poster" title={translateObj(text15, localization.language)} />
                            </div>
                          )}
                        </div>
                        {isFavoritesActive && (
                          <div className="flex flex-wrap">
                            <div className="flex lg:w-full lg:mt-4 md:w-full sm:w-full">
                              <div className="mr-3 flex items-center">
                                <ViewModeButton title={translateObj(text9, localization.language)} viewMode="all" />
                              </div>
                              <div className="mr-3 flex items-center">
                                <ViewModeButton title={translateObj(text10, localization.language)} viewMode="favourites" icon={icon4Url} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {tags.length > 0 && (
                      <div className="mt-6 sm:mt-4 w-full mr-12 sm:mr-0 ">
                        <span className="block uppercase mb-6 sm:mb-4 sm:hidden">{Object.keys(text14).length ? translateObj(text14, localization.language) : 'Genres'}</span>
                        <div className="flex flex-wrap sm:hidden">
                          {tags.map((tag) => {
                            const active = activeTags && activeTags.length !== 0 && activeTags.indexOf(tag) > -1;
                            return <Tag key={tag} tag={tag} active={active} />;
                          })}

                        </div>
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>

              {(activeDays.length > 0 || venuesValue.length > 0 || searchValue) && (
                <div className="flex pt-4 pb-2 flex-wrap">
                  {activeDays.length !== dates.length && activeDays.map(activeDay => <ActiveFilter key={activeDay} title={activeDay} callback={() => filterDaySelected(activeDay)} />)}

                  {venuesValue.length !== venues.length
                    && venuesValue.map(venue => (
                      <ActiveFilter
                        key={venue}
                        title={translateObj(venues[venue], localization.language)}
                        callback={() => {
                          this.handleChangeMultipleVenues(venuesValue.filter(item => item !== venue));
                        }}
                      />
                    ))}

                  {searchValue && <ActiveFilter key={searchValue} title={searchValue} callback={() => this.handleSearchFilter(null)} />}
                </div>
              )}
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

LineupFilter.propTypes = {
  showList: PropTypes.bool,
  showTable: PropTypes.bool,
  showGrid: PropTypes.bool,
  showPoster: PropTypes.bool,
  showDate: PropTypes.bool,
  showDateTime: PropTypes.bool,
  multipleLineUpsOnPage: PropTypes.bool,
  filterVenueSelected: PropTypes.func.isRequired,
  updateActiveDays: PropTypes.func.isRequired,
  filterDaySelected: PropTypes.func.isRequired,
  filterTagSelected: PropTypes.func.isRequired,
  text0: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  // text2: PropTypes.shape().isRequired,
  text3: PropTypes.shape().isRequired,
  text4: PropTypes.shape().isRequired,
  text5: PropTypes.shape().isRequired,
  text6: PropTypes.shape().isRequired,
  text7: PropTypes.shape().isRequired,
  text8: PropTypes.shape().isRequired,
  text9: PropTypes.shape().isRequired,
  text10: PropTypes.shape().isRequired,
  text11: PropTypes.shape().isRequired,
  text12: PropTypes.shape().isRequired,
  text14: PropTypes.shape(),
  text15: PropTypes.shape(),
  // text13: PropTypes.shape().isRequired,
  // icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
  icon3Url: PropTypes.string.isRequired,
  icon4Url: PropTypes.string.isRequired,
  icon5Url: PropTypes.string.isRequired,
  // icon6Url: PropTypes.string.isRequired,
  venues: PropTypes.shape().isRequired,
  activeViewMode: PropTypes.string.isRequired,
  updateViewMode: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
  activeView: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activeVenues: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activeDays: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dates: PropTypes.array.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/require-default-props
  searchValue: PropTypes.string,
  updateSearchFilter: PropTypes.func.isRequired,
};

LineupFilter.defaultProps = {
  showList: true,
  showTable: true,
  showGrid: true,
  showDate: false,
  showPoster: false,
  showDateTime: false,
  multipleLineUpsOnPage: false,
  text14: { es: 'Genres', en: 'Genres', ca: 'Genres' },
  text15: { es: 'Poster', en: 'Poster', ca: 'Poster' },
};

export default withRouter(LineupFilter);
