import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import imageModifier from '../helpers/imageModifier';
import TitleLineH1 from '../Text/TitleLineH1';
import Tag from '../Buttons/Tag';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import stripTags from '../helpers/stripTags';
import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';

const SliderBannerRadio = ({
  image, title_multilang, tags_array_multilang, match, text_multilang, slug,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <div
          key={image}
          className="h-620 md:h-carouselMD sm:h-carouselSM w-full sliderComponent relative"
          style={{
            background: `url(${imageModifier(translateObj(image, localization.language), '1440x620')})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="text-white flex items-end h-full">
            <div className="z-40 flex justify-around sm:flex-col sm:justify-end w-full px-36 md:px-20 sm:px-5 h-full items-end pb-20 sm:pb-13">
              <div className="w-2/3 sm:w-full h-full sm:h-auto flex flex-col justify-end mr-12 sm:mr-0 sm:mb-4 ">
                <div className="div">
                  <Link to={`/radio/shows/${slug}`} localizationpath={localization.path}>
                    <TitleLineH1 text={title_multilang} color="white" />
                    <p className="font-tiemposRegular text-base md:text-sm sm:text-xs mb-4 text-white">
                      {!text_multilang[localization.language] ? stripTags(text_multilang.en) : stripTags(text_multilang[localization.language])}
                    </p>
                  </Link>
                  <div className="flex mt-2">
                    {tags_array_multilang.map((tag, i) => tag[localization.language] && <Tag key={tag.en} category="radio" text={tag[localization.language]} textColor="white" borderColor="radio" />)}
                  </div>
                </div>
              </div>

              <div className="w-1/3 sm:w-full" />

            </div>
          </div>
        </div>
      );
    }
      }
  </RoutesContext.Consumer>
);
SliderBannerRadio.propTypes = {
  image: PropTypes.string.isRequired,

};

SliderBannerRadio.defaultProps = {

};

export default withRouter(SliderBannerRadio);
