import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import withRadioPlayerContext from '../../context/withRadioPlayerContext';
import getRadioShowsEpisodes from '../../api/graphql/queries/getRadioShowsEpisodes';
import getRadioShows from '../../api/graphql/queries/getRadioShows';
import RadioShowCardListItem from './Radio/RadioShowCardListItem';
import ParagraphLineSM from './Text/ParagraphLineSM';
import RadioTodayGrid from './Radio/RadioTodayGrid';
import RadioEpisodesList from './Radio/RadioEpisodesList';
import TitleLineH1 from './Text/TitleLineH1';
import NormalBodyText from './Text/NormalBodyText';
import RadioPlayIcon from './Radio/RadioPlayIcon';
import RadioHeader2 from './Radio/RadioHeader2';
import InnerLink from './InnerLink';
import SidebarChannelCard from './Radio/SidebarChannelCard';
import NavigatorRadio from './NavigatorRadio';

class RadioChannel extends Component {
  state = {
    highLights: [],
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    getRadioShows({ highlight: true, limit: 6 })
      .then((result) => {
        this.setState({
          highLights: result,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderHighLights = () => {
    const { highLights } = this.state;
    return highLights.map(show => <RadioShowCardListItem key={show.id} favouriteStar={false} {...show} />);
  };

  render() {
    const { highLights } = this.state;
    const {
      match: { params },
      ch1,
      ch2,
    } = this.props;

    const relatedText = {
      es: 'Otros canales',
      en: 'Other channels',
      ca: 'Altres canals',
    };

    const durationText = {
      en: 'MIN',
      ca: 'MIN',
      es: 'MIN',
    };

    const highLightText = { en: 'Featured shows', es: 'Programas destacados', ca: 'Programes destacats' };
    let activeChannel;
    let otherChannel;
    let otherChannelUrl;
    if (params.channel === 'rps') {
      if (ch1) {
        activeChannel = { ...ch1 };
      }
      if (ch2) {
        otherChannel = { ...ch2 };
        otherChannelUrl = '/radio/primavera-sounds';
      }
    } else if (params.channel === 'primavera-sounds') {
      if (ch2) {
        activeChannel = { ...ch2 };
      }
      if (ch1) {
        otherChannel = { ...ch1 };
        otherChannelUrl = '/radio/rps';
      }
    }
    return (
      <React.Fragment>
        <div>
          <NavigatorRadio />
          <div className="mt-4 sm:mt-3 md:mt-3 w-full flex flex-wrap">
            <div className="w-1/4 sm:w-full md:w-full sm:mb-4 md:mb-4">
              {activeChannel && (
                <SidebarChannelCard {...activeChannel} />
              )}
            </div>
            <div className="w-3/4 sm:w-full md:w-full pl-4 sm:pl-0 md:pl-0">
              <div className="mb-16 relative">{activeChannel && <RadioTodayGrid channel={activeChannel.channel} />}</div>
              {activeChannel && (
              <div className="mb-4 relative mt-4 border-black border-t-4 pt-4">
                <>
                  <div className="w-full pb-2">
                    <ParagraphLineSM classes="uppercase font-bold text-xxs" text={{ en: 'Recently added episodes', es: 'EPISODIOS AÑADIDOS RECIENTEMENTE', ca: 'EPISODIS AFEGITS RECENTMENT' }} />
                  </div>
                  <RadioEpisodesList limit={4} channel={activeChannel.channel} parentSlug={activeChannel.slug} activeView="list" />
                </>
              </div>
              )}
              {highLights && highLights.length
                ? (
                  <div className="mb-4 relative mt-4 border-black border-t-4 pt-4">
                    <div className="w-full pb-4">
                      <ParagraphLineSM classes="uppercase font-bold text-xxs" text={highLightText} />
                    </div>
                    {this.renderHighLights()}
                  </div>
                ) : null
  }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

RadioChannel.propTypes = {
  ch1: PropTypes.shape(),
  ch2: PropTypes.shape(),
  ch3: PropTypes.shape(),
};

RadioChannel.defaultProps = {
  ch1: null,
  ch2: null,
  ch3: null,
};

export default withRouter(withRadioPlayerContext(RadioChannel));
