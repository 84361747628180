/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { UserProfileContext } from '../../context/userProfileContext';
import getQueryVariable from '../../util/getQueryVariable';
import postNewsletterForm from '../../api/graphql/mutations/postNewsletterForm';
import deleteNewsletterData from '../../api/graphql/mutations/deleteNewsletterData';
import getNewsletterSettings from '../../api/graphql/queries/getNewsletterSettings';
import ParagraphLineSM from './Text/ParagraphLineSM';
import NewsletterSettingsCard from './NewsletterSettingsCard';
import translateObj from '../../util/translateObj';
import LoadIcon from './LoadIcon';

const removeSubscriptionText = {
  en: 'delete email',
  es: 'eliminar email',
  ca: 'suprimir email',
  pt: 'excluir email',
};
const iAmSureText = {
  en: 'I am sure',
  es: 'estoy seguro',
  ca: 'estic segur',
  pt: 'tenho certeza',
};
const subscriptionEmailText = {
  en: 'Subscription email:',
  es: 'Email de suscripción:',
  ca: 'Email de subscripció:',
  pt: 'Email de inscrição:',
};
const changeLanguageText = {
  en: 'Choose your language:',
  pt: 'Escolher idioma:',
  es: 'Elige el idioma para las comunicaciones:',
  ca: 'Escull el teu idioma:',
};
const activeRequired = {
  en: 'Sorry, this value cannot be changed',
  es: 'Lo siento, este valor no se puede cambiar',
};
const loggedUserRequired = {
  en: 'Para cambiar este valor debes loguearte.',
  es: 'Para cambiar este valor debes loguearte.',
};

const languages = ['en', 'es', 'ca', 'pt'];
const CheckButton = ({
  title,
  isOn,
  cb,
  hasBorder,
}) => (
  <div onClick={cb} className={`cursor-pointer flex h-8 sm:h-10 items-center rounded-lg p-2 ${hasBorder ? 'border-solid border-white border-1' : ''} ${isOn && hasBorder ? 'bg-white text-black' : ''}`}>
    <Checkbox1 isOn={isOn} hasBorder={hasBorder} />
    <span className={`pl-1 uppercase text-xs font-americaMonoRegular tracking-wide ${isOn && hasBorder ? 'text-black' : 'text-white '}`}>{title}</span>
  </div>
);
const Checkbox1 = ({
  isOn,
  hasBorder,
}) => (
  <div className={`cursor-pointer rounded-full border-solid ${isOn && hasBorder ? 'border-black' : 'border-white'} border-4 flex items-center justify-center`}>
    {isOn ? <div className="w-2 h-2 bg-red-coral rounded-full" /> : null}
    {!isOn && !hasBorder ? <div className="w-2 h-2 bg-white" /> : null}
  </div>
);
const onoff = {
  en: 'ON/OFF',
};
const NewsLetterSettingsModule = ({
  match,
  location,
  history,
  scopes,
}) => {
  const [settings, setSettings] = useState(null);
  const [isOn, toggleOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [areYouSure, setAreYouSure] = useState(false);
  const [scopesToRender, setScopes] = useState(null);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const userContext = useContext(UserProfileContext);

  const code = getQueryVariable(location.search, 'code');
  const { params: { lang } } = match;

  const toggleSubscriptionText = {
    en: 'Toggle all subsriptions',
    pt: isOn ? 'Desligue todas as subscrições' : 'Ativar todas as subscrições',
    es: isOn ? 'Desactiva todas las suscripciones' : 'Activa todas las suscripciones',
    ca: isOn ? 'Desactiva totes les subscripcions' : 'Activa totes les subscripcions',
  };

  const processNewsLetterSettings = (res) => {
    const { result } = res;
    const { userEmail, userRoles } = userContext;
    if (result) {
      if (result.custom_fields) {
        const activeScopes = scopes.map((sc) => {
          const modSc = { ...sc };
          const scIndex = result.custom_fields.findIndex(field => field.name === sc.scope);
          if (scIndex !== -1) {
            modSc.isSubscribed = result.custom_fields[scIndex].value === '1';
          }
          modSc.isLoading = false;

          // If requiredRoles is present, the user has to have at least one of the roles specified
          if (sc.active && sc.requiredRoles && sc.requiredRoles.length) {
            const userHaseRequiredRole = userRoles.some(role => sc.requiredRoles.includes(role));
            modSc.active = userHaseRequiredRole;
            sc.active = modSc.active;

            if (!userEmail) {
              sc.inactiveReason = 'loggedUserRequired';
              modSc.inactiveReason = 'loggedUserRequired';
            } else if (userEmail && !userHaseRequiredRole) {
              sc.inactiveReason = 'roleRequired';
              modSc.inactiveReason = 'roleRequired';
            }
          }
          return modSc;
        });
        setScopes(activeScopes);
        setSettings(result);
        setIsLoading(false);
      }
    }
  };
  const updateNewsLetterSettings = (res) => {
    if (res) {
      if (res.scopes) {
        const activeScopes = scopes.map((sc) => {
          const modSc = { ...sc };
          const scIndex = res.scopes.findIndex(field => field.name === sc.scope);
          if (scIndex !== -1) {
            modSc.isSubscribed = res.scopes[scIndex].value === '1';
          }
          modSc.isLoading = false;
          return modSc;
        });
        setScopes(activeScopes);
        setSettings(res);
      }
    }
  };
  const getSettings = async () => {
    const res = await getNewsletterSettings(code);
    if (res && res.result) {
      processNewsLetterSettings(res);
    } else {
      history.push(`/${lang}`);
    }
  };

  const toggleSubscription = (scope) => {
    setErrorMessage();
    const currentScope = [...scopesToRender].find(sc => sc.scope === scope);
    if (currentScope) {
      if (currentScope.inactiveReason === 'loggedUserRequired') {
        setErrorMessage(loggedUserRequired);
        return;
      }
      if (currentScope.inactiveReason === 'roleRequired') {
        setErrorMessage(currentScope.requiredRolesErrorMessage);
        return;
      }
      if (!currentScope.active) {
        setErrorMessage(activeRequired);
        return;
      }

      currentScope.isLoading = true;
      const currentScopeIndex = scopesToRender.indexOf(currentScope);
      const newScopes = [...scopesToRender];
      newScopes[currentScopeIndex] = currentScope;
      setScopes(newScopes);
      const isSubscribing = !currentScope.isSubscribed;
      postNewsletterForm({
        email: settings.email, language: settings.language, scopes: [scope], isSubscribing, isOriginSettings: true,
      }).then((res) => {
        if (res.isValid) {
          updateNewsLetterSettings(res);
        }
      });
    }
  };

  const handleToggleAll = () => {
    setErrorMessage();
    toggleOn(!isOn);
    const scopesToSend = scopesToRender.filter(item => item.active).map(item => item.scope);
    const loadingScopes = scopesToRender.map((item) => {
      const modItem = { ...item };
      if (modItem.active) {
        modItem.isLoading = true;
      }
      return modItem;
    });
    setScopes(loadingScopes);
    postNewsletterForm({
      email: settings.email, language: settings.language, scopes: scopesToSend, isSubscribing: !isOn, isOriginSettings: true,
    }).then((res) => {
      if (res.isValid) {
        updateNewsLetterSettings(res);
      }
    });
  };

  const removeSubscription = () => {
    setErrorMessage();
    setIsDeletingAccount(true);
    deleteNewsletterData({ email: settings.email }).then((res) => {
      if (res.isValid) {
        if (res.token) {
          const { setNewAuthToken } = userContext;
          setNewAuthToken(res.token);
        }
        setTimeout(() => {
          history.push(`/${lang}/newsletter-delete-confirmation`);
        }, 2000);
      }
    });
  };
  const handleSetLanguage = (l) => {
    if (l !== settings.language) {
      postNewsletterForm({
        email: settings.email, language: l, scopes: [], isOriginSettings: true,
      }).then((res) => {
        if (res.isValid) {
          updateNewsLetterSettings(res);
        }
      });
    }
  };

  useEffect(() => {
    if (code) {
      getSettings();
    }
  }, []);

  // useEffect(() => {
  //   alert(errorMessage);
  // }, [errorMessage]);

  const renderSettings = () => (
    <div className="md:-mx-2 lg:-mx-2 xl:-mx-2 flex flex-wrap">
      {scopesToRender.filter(item => item.active).map((sc, i) => (
        <div key={sc.scope} className="sm:w-full md:w-1/2 smlg:w-1/4 lg:w-1/3 xl:w-1/4 pb-2 px-2 sm:px-0">
          <NewsletterSettingsCard favouriteStar={false} {...sc} toggleSubscription={toggleSubscription} />
        </div>
      ))}
    </div>
  );

  return (
    <>
      {!isLoading && scopesToRender && (
      <div className="w-full">
        <div className="my-4 bg-black w-full flex flex-wrap justify-between items-center p-4">
          <ParagraphLineSM classes="uppercase font-bold text-xxs text-white m-0 sm:mb-2" text={toggleSubscriptionText} />
          <div className="flex flex-wrap">
            <div className="mr-3">
              <CheckButton cb={handleToggleAll} hasBorder isOn={isOn} title={translateObj(onoff, lang)} />
            </div>
          </div>
        </div>

        {errorMessage && (
          <div className="font-stratos text-20 text-red col-span-4 p-4">
            <p>{translateObj(errorMessage, settings.language)}</p>
          </div>
        )}

        {renderSettings()}

        <div className="my-4 bg-grey-nl-lang w-full flex flex-wrap justify-between items-center p-4">
          <ParagraphLineSM classes="uppercase font-bold text-xxs text-white m-0 sm:mb-2" text={changeLanguageText} />
          <div className="flex">
            {languages.map(language => (
              <CheckButton key={language} cb={() => handleSetLanguage(language)} hasBorder={false} isOn={language === settings.language} title={language} />
            ))}
          </div>
        </div>

        <div className="my-4 bg-red-coral text-white w-full flex flex-wrap justify-between items-center p-4">
          <div className="uppercase flex flex-wrap w-1/2 sm:w-full items-center sm:flex-col sm:mb-2">
            <ParagraphLineSM classes="uppercase font-light text-white my-1 mr-2" text={subscriptionEmailText} />
            <span className="text-xs font-americaMonoRegular tracking-wide uppercase font-bold text-xxs text-white my-1">{settings.email}</span>
          </div>

          {!areYouSure || isDeletingAccount
            ? (
              <button
                type="submit"
                disabled={isDeletingAccount}
                onClick={() => setAreYouSure(true)}
                className="bg-transparent border hover:bg-white hover:text-red-coral trans border-white h-12 w-1/4 sm:w-full md:w-1/2 text-white rounded-full py-2 font-americaMonoBold uppercase"
              >
                {!isDeletingAccount ? <span>{translateObj(removeSubscriptionText, lang)}</span> : <LoadIcon color="white" type="ThreeDots" />}
              </button>
            )
            : (
              <div className="w-1/2 sm:w-full">
                <button
                  type="submit"
                  onClick={removeSubscription}
                  className="bg-white h-12 w-1/2 sm:w-1/2 md:w-1/2 text-red rounded-full py-2 pr-2 font-americaMonoBold uppercase"
                >
                  {translateObj(iAmSureText, lang)}
                </button>
                <button
                  type="submit"
                  onClick={() => setAreYouSure(false)}
                  className="bg-red h-12 w-1/2 sm:w-1/2 md:w-1/2 text-white rounded-full py-2 font-americaMonoBold uppercase"
                >
                  Cancel
                </button>
              </div>
            )}
        </div>
      </div>
      )}
    </>
  );
};

NewsLetterSettingsModule.propTypes = {

};

NewsLetterSettingsModule.defaultProps = {

};

export default withRouter(NewsLetterSettingsModule);
