import React from 'react';
import { PropTypes } from 'prop-types';

import { Link, withRouter } from 'react-router-dom';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const SliderParagraphLine = ({
  margin, text, action, link, match,
}) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <Link to={`${localization.path}${link}`}>
            <p
              className={`${margin} font-americaMonoBold text-base
              sm:text-xs tracking-wide md:text-sm uppercase text-white opacity-85`}
            >
              {action === 'link' ? '> ' : '//'}
              {translateObj(text, localization.language)}
            </p>
          </Link>
        );
      }
  }
  </RoutesContext.Consumer>
);

SliderParagraphLine.propTypes = {
  action: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  margin: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape().isRequired,
};

SliderParagraphLine.defaultProps = {
  margin: '',
};


export default withRouter(SliderParagraphLine);
