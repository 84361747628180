import React from 'react';
import { PropTypes } from 'prop-types';

class RadioPlayButton extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      playing: props.playing
    }
  }

  togglePlaying() {
    let {playing} = this.state
    this.setState({playing: !playing})
  }

  render() {
    let {playing} = this.state
    let {icon1Url, icon2Url} = this.props
    return (
      <div onClick={this.togglePlaying.bind(this)}>
        <img src={playing ? icon2Url : icon1Url} />
      </div>
    )
  }
}

RadioPlayButton.propTypes = {
  playing: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
};

export default RadioPlayButton