import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const HourLabel = ({
  hour,
  layout,
  firstColumnRef,
  hasEvent,
  index,
}) => {
  const layoutClassNames = ['font-americaMonoRegular text-xs'];
  if (layout === 'columns') {
    if (index > 0) {
      layoutClassNames.push('border-t-1');
    }
    layoutClassNames.push('justify-center w-28 sm:w-16');
    if (hasEvent) {
      layoutClassNames.push('h-lineupHour p-2');
    } else {
      layoutClassNames.push('h-lineupEmptyHour px-2 flex items-center');
    }
  } else if (layout === 'rows') {
    layoutClassNames.push('border-r-1 items-center h-lineupHour');
    if (hasEvent) {
      layoutClassNames.push('w-lineupHour justify-start px-2');
    } else {
      layoutClassNames.push('w-lineupEmptyHour justify-center');
    }
  }
  return (
    <div
      ref={firstColumnRef}
      className={`flex border-solid border-white ${layoutClassNames.join(' ')}`}
    >
      <span className={`${layout === 'rows' && !hasEvent ? 'rotate180' : ''}`}>
        {layout === 'rows' && !hasEvent ? null : moment(hour).format('HH:00')}
      </span>
    </div>
  );
};

HourLabel.propTypes = {
  layout: PropTypes.string.isRequired,
  hour: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  firstColumnRef: PropTypes.object,
  hasEvent: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};
export default HourLabel;
