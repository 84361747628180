import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../../../context/routesContext';
import getLocalization from '../../../../../util/localization';
import translateObj from '../../../../../util/translateObj';
import SimpleButton from '../../../Buttons/SimpleButton';

const AreYouSurePopup = ({
  text, primaryButtonText, primaryCallback, secondaryButtonText, secondaryCallback, match,
}) => {
  useEffect(() => {
    document.body.setAttribute('style', 'overflow: hidden');
    return () => document.body.setAttribute('style', 'overflow: auto');
  }, []);

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div
            className="fixed pin flex items-center justify-center p-3 tablet:p-12 laptop:p-24"
            style={{ background: 'rgba(255, 255, 255, 0.68)', zIndex: 9999 }}
            onClick={secondaryCallback}
          >
            <div
              className="font-stratos bg-white w-full max-w-sm border-8 border-red flex flex-col p-8 tablet:p-12"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <p>{translateObj(text, localization.language)}</p>
              <div className="flex items-center justify-center mt-8 tablet:mt-12">
                <div className="flex-1 mr-2 tablet:mr-4" onClick={secondaryCallback}>
                  <SimpleButton
                    isDisabled
                    isLowercase
                    isFullWidth
                    theme={{
                      base: 'grey',
                      hover: 'red',
                    }}
                    text={secondaryButtonText}
                  />
                </div>
                <div className="flex-1" onClick={primaryCallback}>
                  <SimpleButton
                    isDisabled
                    isLowercase
                    isFullWidth
                    theme={{
                      base: 'red',
                      hover: 'red',
                    }}
                    text={primaryButtonText}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

AreYouSurePopup.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
  }).isRequired,
  primaryButtonText: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
  }),
  primaryCallback: PropTypes.func,
  secondaryButtonText: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
  }),
  secondaryCallback: PropTypes.func,
};

AreYouSurePopup.defaultProps = {
  primaryButtonText: undefined,
  primaryCallback: undefined,
  secondaryButtonText: undefined,
  secondaryCallback: undefined,
};

export default withRouter(AreYouSurePopup);
