import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import LinkButtonRounded from './Buttons/LinkButtonRounded';
import translateObj from '../../util/translateObj';


const Error404 = ({
  params,
  match,
  linkButtonRounded,
}) => (
  <RoutesContext.Consumer>
    {
    ({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const error = Math.floor(Math.random() * params.length);
      const artist = params[error];
      return (
        <div className="px-24 pt-20 flex flex-row sm:flex-col sm:pt-2 sm:px-3 md:pt-10 md:px-12">
          <div className="w-3/5 sm:w-full sm:text-center">
            <p className="uppercase font-americaMonoRegular sm:text-12 md:pr-1 md:text-sm mb-6">{`${translateObj(artist.title, localization.language)}`}</p>
            <p className="block font-americaBlack text-30 md:text-24 pr-20 sm:pr-0 md:pr-10 sm:text-lg mb-6">{`${translateObj(artist.text, localization.language)}`}</p>
            <img className="colorImg self-center hidden sm:inline" src={`${artist.image}`} alt={`${artist.image}`} />
            <div className="sm:pt-5 mt-10"><LinkButtonRounded {...linkButtonRounded} /></div>
          </div>
          <div className="flex sm:hidden">
            <img className="colorImg self-center md:self-center" src={`${artist.image}`} alt={`${artist.image}`} />
          </div>
        </div>
      );
    }
  }
  </RoutesContext.Consumer>
);


Error404.propTypes = {
  params: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
      ca: PropTypes.string,
    }),
    text: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
      ca: PropTypes.string,
    }),
    gif: PropTypes.string,
  }),
  match: PropTypes.shape().isRequired,
  linkButtonRounded: PropTypes.shape().isRequired,
}.isRequired;

export default withRouter(Error404);
