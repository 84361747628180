import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { InstantSearch, Configure } from 'react-instantsearch-dom';
import InputSearch from './InputSearch';
import SearchResultsList from './SearchResultsList';
import { ALGOLIA_INDEX } from '../SearchPage';

const AlgoliaSearchByTag = ({
  algoliaClient,
  updateSearchState,
  tag,
}) => (
  <div className="static w-full pin-t pin-l pin-r bg-white z-top transition-background-04s tablet:mt-16.5 laptop:mt-29 desktop:mt-30 mt-8">
    <InstantSearch
      searchClient={algoliaClient}
      indexName={ALGOLIA_INDEX}
      onSearchStateChange={updateSearchState}
    >
      <Configure
        filters={`postDescription.tags:${tag}`}
        distinct
        hitsPerPage={40}
      />
      <div className="mx-2 tablet:mx-0 mb-8 -mt-8 tablet:mt-0 tablet:mb-20">
        <InputSearch isListPage={false} />
      </div>
      <div className="tablet:mx-18px laptop:mx-13">
        <SearchResultsList query="" />
      </div>
    </InstantSearch>
  </div>
);
AlgoliaSearchByTag.propTypes = {
  algoliaClient: PropTypes.shape(),
  updateSearchState: PropTypes.shape(),
  tag: PropTypes.string,
};

AlgoliaSearchByTag.defaultProps = {
  algoliaClient: null,
  updateSearchState: null,
  tag: '',
};

export default withRouter(AlgoliaSearchByTag);
