import React from 'react';
import SearchNumberOfResultsFound from '../SearchNumberOfResultsFound';
import { connectStateResults } from 'react-instantsearch-dom';

const stateResults = ({ searchResults }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0;
  const nbHits = searchResults && searchResults.nbHits;
  const currentQuery = searchResults && searchResults.query || '';

  if( hasResults ) return <SearchNumberOfResultsFound numberOfResults={nbHits} searchQuery={currentQuery} />

  return (<></>);
}

const CustomStateResults = connectStateResults(stateResults);

export default CustomStateResults;