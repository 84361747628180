import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PropTypes } from 'prop-types';

const dropdownVariants = {
  closed: { height: 0 },
  opened: { height: 'auto' },
};

const dropdownArrowVariants = {
  closed: { rotate: 0 },
  opened: { rotate: 180 },
};

const transition = {
  type: 'tween',
  duration: 0.5,
  ease: [0.87, 0, 0.13, 1],
};

const DropdownContainer = ({
  index,
  children,
  title,
  subtitle,
  label,
  parentComponent,
  borderTop,
  borderBottom,
  className,
  ContainerclassName,
  callback,
  activeDropdown,
}) => {
  const shoudDropdownBeOpened = () => {
    if (typeof activeDropdown === 'string') {
      return activeDropdown === index ? 'opened' : 'closed';
    }
    if (typeof activeDropdown === 'boolean') {
      return activeDropdown ? 'opened' : 'closed';
    }
  };

  return (
    <>
      <div className={`${className}`}>
        <div
          className={`${borderTop && parentComponent !== 'NumberedList' ? 'border-t-2' : 'border-t-1'} ${parentComponent === 'CommentsBox' ? 'border-b-2' : ''} border-black py-4 flex items-center justify-between cursor-pointer`}
          onClick={() => callback(index)}
        >
          <div className="flex flex-col items-start justify-start">
            <h3 className={`text-black font-bold font-stratos ${parentComponent === 'NumberedList' ? 'text-18 tablet:text-22 laptop:text-26' : 'text-26'}`}>{title}</h3>
            {(subtitle || label) && (
              <h4 className={`${parentComponent === 'NumberedList' ? 'text-base tablet:text-18 laptop:text-20' : 'text-20'} text-red font-bold font-stratos mt-1 laptop:mt-2 desktop:mt-1`}>
                {subtitle}
                {label && <span className="text-13 font-semilight text-black"> &gt; {label}</span>}
              </h4>
            )}
          </div>
          <div className={`self-stretch flex ${subtitle ? 'items-start mt-2' : 'items-center'} justify-center`}>
            <div key="icon-container">
              <motion.div
                animate={shoudDropdownBeOpened()}
                initial={{ rotate: 0 }}
                transition={transition}
                variants={dropdownArrowVariants}
              >
                <span className="font-icon icon-triangle font-base text-red" />
              </motion.div>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {
            shoudDropdownBeOpened() && (
              <motion.div
                className="overflow-hidden"
                animate={shoudDropdownBeOpened()}
                initial={{ height: 0 }}
                transition={transition}
                variants={dropdownVariants}
                exit={{ height: 0 }}
              >
                <div className={`${ContainerclassName || 'mt-7 mb-5'}`}>
                  {children}
                </div>
              </motion.div>
            )
          }
        </AnimatePresence>
      </div>
      {borderBottom && parentComponent !== 'CommentsBox' && <div className="w-full bg-black" style={{ height: '2px' }} />}
    </>
  );
};

export default DropdownContainer;

DropdownContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
  className: PropTypes.string,
  parentComponent: PropTypes.string,
  ContainerclassName: PropTypes.string,
  callback: PropTypes.func.isRequired,
  activeDropdown: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

DropdownContainer.defaultProps = {
  className: '',
  index: '0',
  ContainerclassName: '',
  parentComponent: '',
  borderTop: true,
  borderBottom: false,
  subtitle: null,
  label: null,
};
