import React, { memo, useEffect, useContext, useState } from 'react';
import Cookies from 'js-cookie';
// import PropTypes from 'prop-types';
import { useUser } from '../../context/userProfileContext';
import AccessPaywallOverlay from './AccessPaywallOverlay';
import RoutesContext from '../../context/routesContext';
import ArticlesCounterPopup from './ArticlesCounterPopup';

const cookieName = 'rdl_usedarticles';
const cookieLastPostName = 'rdl_lastvisited';
const numberOfFreePosts = 3;
const adminUserRole = 'admin';
const freeUserRole = 'rdl-registration';
const AccessPaywall = () => {
  const [showPaywall, setShowPaywall] = useState(false);
  const [showPaywallConsumedArticles, setShowPaywallConsumedArticles] = useState(false);
  const [showPaywallMustPay, setShowPaywallMustPay] = useState(false);
  const [showArticlesCounter, setShowArticlesCounter] = useState(false);
  const [counterHidden, setCounterHidden] = useState(false);
  const [articlesLeft, setArticlesLeft] = useState(null);
  const [rolesAllowedInfo, setRolesAllowedInfo] = useState(false);
  const [mustVerify, setMustVerify] = useState(false);
  const { userRoles, isUserVerified, userEmail } = useUser();
  const { currentRolesAllowed = false } = useContext(RoutesContext);

  const lockScrolling = (lock) => {
    const body = document.querySelector('body');
    const fakeBody = document.querySelector('#body');
    if (lock) {
      body.classList.add('overflow-hidden');
      body.style.overflowY = 'hidden';
      body.style.height = '80vh';
      if (fakeBody) {
        fakeBody.classList.add('overflow-hidden');
        fakeBody.style.overflowY = 'hidden';
        fakeBody.style.height = '80vh';
      }
    } else {
      body.classList.remove('overflow-hidden');
      body.style.overflowY = 'auto';
      body.style.height = 'initial';
      if (fakeBody) {
        fakeBody.classList.remove('overflow-hidden');
        fakeBody.style.overflowY = 'auto';
        fakeBody.style.height = 'initial';
      }
    }
  };
  const readUsedArticles = () => {
    try {
      return JSON.parse(Cookies.get(cookieName) || '[]');
    } catch {
      return [];
    }
  };

  const handleCloseCounter = () => {
    setCounterHidden(true);
    setShowArticlesCounter(false);
  };

  useEffect(() => {
    if (currentRolesAllowed) {
      setRolesAllowedInfo(currentRolesAllowed);
    }
  }, [currentRolesAllowed]);

  useEffect(() => {
    lockScrolling(showPaywall);
  }, [showPaywall]);

  const checkLimitedAcces = () => {
    const { rolesAllowed, slugName } = rolesAllowedInfo;

    const usedArticles = readUsedArticles();
    const numberArticlesLeft = Number(numberOfFreePosts) - usedArticles.length;
    setArticlesLeft(numberArticlesLeft);
    if (rolesAllowed.includes(freeUserRole) && usedArticles.length < numberOfFreePosts && !counterHidden) {
      setShowArticlesCounter(true);
    }
    if (!usedArticles.includes(slugName) && usedArticles.length < numberOfFreePosts) {
      usedArticles.push(slugName);
      Cookies.set(cookieName, JSON.stringify(usedArticles), { expires: 30 });
    }
    // console.log('Is registered user with ', usedArticles.length, ' used articles of ', numberOfFreePosts);
    if (usedArticles.length >= numberOfFreePosts && !usedArticles.includes(slugName)) {
      setShowPaywall(true);
      setShowPaywallConsumedArticles(true);
    }
  };

  useEffect(() => {
    const { rolesAllowed, url } = rolesAllowedInfo;
    if (rolesAllowed) {
      // console.log('allowed', rolesAllowed);
      // console.log('user', userRoles.map(role => (role === 'web' ? freeUserRole : role)));
      if (rolesAllowed.length === 0) {
        // If the post doesn't have roles, we allow access to everybody
        setShowPaywall(false);
      } else {
        const userRolesWithoutRegistered = userRoles.map(role => (role === 'web' ? freeUserRole : role)).filter(item => item !== freeUserRole);
        const postRolesWithoutRegistered = rolesAllowed.filter(item => item !== freeUserRole);
        if (userRolesWithoutRegistered.includes(adminUserRole)) {
          // is admin
          setShowPaywall(false);
        } else {
          // not admin
          // hasRole tells us if there are coincidences in role between the post and the user
          const hasRole = userRolesWithoutRegistered.some(r => postRolesWithoutRegistered.indexOf(r) >= 0);
          if (hasRole) {
            // If we find the role, we allow access to the post
            setShowPaywall(false);
          } else {
            // We check if both the user and the post have the freeUserRole - if they do, they can access with a views limit
            const userRolesWithRegistered = userRoles.map(role => (role === 'web' ? freeUserRole : role)).filter(item => item === freeUserRole);
            const postRolesWithRegistered = rolesAllowed.filter(item => item === freeUserRole);
            const hasFreeRole = userRolesWithRegistered.some(r => postRolesWithRegistered.indexOf(r) >= 0);
            if (hasFreeRole) {
              if (!isUserVerified) {
                setShowPaywall(true);
                setMustVerify(true);
              } else {
                // It means the user is registered, and the post has the role. We check how many articles they have seen
                checkLimitedAcces();
              }
            } else {
              const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
              Cookies.set(cookieLastPostName, url, { expires: inFiveMinutes });
              // If they don't we show the paywall
              setShowPaywall(true);
              if (userRoles.length > 0) {
                setShowPaywallMustPay(true);
              }
            }
          }
        }
      }
    }
  }, [rolesAllowedInfo, userRoles]);

  if (showPaywall) {
    const { slugName } = rolesAllowedInfo;
    return <AccessPaywallOverlay consumedArticles={showPaywallConsumedArticles} mustPay={showPaywallMustPay} mustVerify={mustVerify} originPost={slugName} userEmail={userEmail} />;
  }
  if (showArticlesCounter && !counterHidden) {
    return <ArticlesCounterPopup consumedArticles={articlesLeft} closeCallback={handleCloseCounter} />;
  }
  return null;

  // return showPaywall ? <AccessPaywallOverlay consumedArticles={showPaywallConsumedArticles} mustPay={showPaywallMustPay} /> : null;
};
AccessPaywall.propTypes = {
};
AccessPaywall.defaultProps = {
};

export default memo(AccessPaywall);
