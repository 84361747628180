import React from 'react';
import PropTypes from 'prop-types';

import { Link, withRouter } from 'react-router-dom';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const LinkButtonRounded = ({
  iconUrl, text, url, category, margin, match, className, slider, isDisabled, is404, color, isLowercase, isMenu, alt, isDiv,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      let usableUrl = url;
      if (url.substring(0, 1) !== '/' && !/^https?:\/\//.test(url)) {
        usableUrl = `/${url}`;
      }
      if (url.length === 1 && url === '/') {
        usableUrl = '';
      }
      if (is404 && match.params && match.params.hasOwnProperty('post')) {
        usableUrl = `/${match.params.section || ''}`;
      }

      const handleColor = () => {
        if (alt) {
          return `text-white bg-${color} border-${color}`;
        }
        return `text-${color} border-${color}`;
      };

      const Button = () => (
        <span
          className={`${className || `hover:bg-white hover:text-red ${handleColor()} hover:border-red`}
          font-stratos font-bold whitespace-pre trans inline-block
          ${slider ? 'mt-3 px-5 tablet:px-7 laptop:px-62px laptop:mt-18px mr-2 tablet:mr-4 text-11 tablet:text-12 laptop:text-14 desktop:text-17 font-bold' : 'text-base'} ${margin}
          ${isLowercase ? `${isMenu ? 'desktop:py-2 laptop:py-1 tablet:text-sm py-2px px-4 laptop:text-base' : 'py-10px min-w-ctaMobile tablet:pr-42px tablet:min-w-cta tablet:text-left px-4 tablet:py-3px tablet:px-2 px-10 tablet:pl-2'} text-center border-6 tablet:border-4 laptop:border-5` : 'border-3 tablet:border-4 laptop:border-5 uppercase px-4 tablet:px-5 laptop:px-6 py-2px tablet:py-1 laptop:py-2'}
          `}
        >
          {iconUrl && <img className="pr-4" src={iconUrl} alt={translateObj(text, localization.language)} />}
          {translateObj(text, localization.language)}
        </span>
      );

      if (isDiv) {
        return (
          <div><Button /></div>
        );
      }

      return (
        <React.Fragment>
          {isDisabled ? (
            <Button />
          ) : (
            <Link to={`${usableUrl}`}>
              <Button />
            </Link>
          )}
        </React.Fragment>
      );
    }}
  </RoutesContext.Consumer>
);

LinkButtonRounded.propTypes = {
  isMenu: PropTypes.bool,
  isLowercase: PropTypes.bool,
  color: PropTypes.string,
  iconUrl: PropTypes.string,
  category: PropTypes.string,
  margin: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  slider: PropTypes.bool,
  isDisabled: PropTypes.bool,
  is404: PropTypes.bool,
  alt: PropTypes.bool,
  isDiv: PropTypes.bool,
};

LinkButtonRounded.defaultProps = {
  isMenu: false,
  isLowercase: false,
  color: 'white',
  iconUrl: null,
  category: 'transparent',
  margin: '',
  className: '',
  url: null,
  slider: false,
  isDisabled: false,
  is404: false,
  alt: false,
  isDiv: false,
};

export default withRouter(LinkButtonRounded);
