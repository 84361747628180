import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';

const RelatedMagazine = () => {
  const [postDescription, setPostDescription] = useState(false);
  const { currentPostDescription = false } = useContext(RoutesContext);

  useEffect(() => {
    if (currentPostDescription) {
      setPostDescription(currentPostDescription);
    }
  }, [currentPostDescription]);

  const { relatedMagazine } = postDescription;
  if (relatedMagazine) {
    const { title } = relatedMagazine;

    return (
      <div className="w-full">
        <div className="border-t border-black border-solid w-full mb-7 tablet:mb-9 desktop:mb-16">
          <p className="text-black font-stratos text-13 laptop:text-14 desktop:text-base pt-1 laptop:pt-4 desktop:pt-3 font-light">
            {title}
          </p>
        </div>
      </div>
    );
  }
  return <></>;
};

export default withRouter(RelatedMagazine);
