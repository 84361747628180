import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import translateObj from '../../../util/translateObj';

class SocialLogosContainer extends PureComponent {
  state = {
    origin: '',
  };

  // componentDidMount() {
  //   this.setState({
  //     currentUrl: window.location.href,
  //   });
  // }

  componentDidMount() {
    this.setState({
      origin: window.location.origin,
    });
  }

  componentDidUpdate() {
  }

  render() {
    const {
      logos,
    } = this.props;
    const { origin } = this.state;
    return (
      <div className="hidden full-width tablet:block relative w-full laptop:h-px laptop:-mt-px">
        <div className="laptop:absolute laptop:pin-t laptop:pin-r px-5 py-8 laptop:py-0 tablet:px-42px laptop:px-24 flex laptop:flex-col items-center justify-end laptop:justify-center laptop:items-start">
          {logos && logos.map(logo => (
            <a key={logo.name} target="_blank" href={logo.link} rel="noopener noreferrer" className="ml-1 laptop:ml-3 laptop:mb-2 flex items-center justify-center p-1">
              <span className={`text-black text-base laptop:text-32 font-icon laptop:w-10 text-center icon-${logo.name.toLowerCase()}`} />
            </a>
          ))}
        </div>
      </div>
    );
  }
}

SocialLogosContainer.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })).isRequired,
};

export default SocialLogosContainer;
