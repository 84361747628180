import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
// import Hammer from 'hammerjs';
import { TweenMax } from 'gsap';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

import MainLayout from './LineUp/MainLayout';
import NavigationArrows from './LineUp/NavigationArrows';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import FloatingArtistCard from './FloatingArtistCard';

function getTranslateX(element) {
  const style = window.getComputedStyle(element).getPropertyValue('transform');
  const matrix = style.replace(/[^0-9\-.,]/g, '').split(',');
  const x = matrix[12] || matrix[4];
  return (Number.isNaN(+x)) ? 0 : +x;
}

function isEventInElement(event, element) {
  const rect = element.getBoundingClientRect();
  const x = event.clientX;
  if (x < rect.left || x >= rect.right) return false;
  const y = event.clientY;
  if (y < rect.top || y >= rect.bottom) return false;
  return true;
}

class LineupGridDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentOverflows: false,
      artist: null,
      layout: 'columns', // columns = venue columns, rows = venue rows
      // layout: 'rows', // columns = venue columns, rows = venue rows
    };
    this.activeColumn = 0;
    this.wrapper = React.createRef();
    this.draggable = React.createRef();
    this.draggableHeader = React.createRef();
    this.fixedColumn = React.createRef();
    this.firstColumn = React.createRef();
    this.arrowWrapper = React.createRef();
    this.arrow = React.createRef();
    this.fixHead = React.createRef();

    this.floatingArtist = React.createRef();
    this.floatingArtistMain = React.createRef();
    this.responsiveUtility = React.createRef();

    this.pauseHover = false;
  }

  componentDidMount() {
    this.shouldInitHammer();
    window.addEventListener('scroll', this.handleScroll);
    this.setState({ contentOverflows: this.draggable.current.offsetWidth > this.wrapper.current.offsetWidth }, () => {
      this.handleScroll();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.pauseHoverTimeout) {
      clearTimeout(this.pauseHoverTimeout);
      this.pauseHoverTimeout = null;
    }
  }

  _updateDeviceScreen = (deviceScreen) => {
    this.setState({ deviceScreen });
  }

  handleScroll = () => {
    this.pauseHoverWhenNeeded();

    const { contentOverflows } = this.state;
    if (!contentOverflows) return;

    if (!this.draggable.current) return;
    if (!this.arrow.current) return;

    const { scrollTop } = document.documentElement;
    const { innerHeight } = window;
    const top = scrollTop + this.draggable.current.getBoundingClientRect().top;
    const middleOfScreen = scrollTop + innerHeight / 2;
    const bottom = top + this.draggable.current.offsetHeight - this.arrow.current.offsetHeight;
    const fixedHeadHeight = this.fixHead.current.offsetHeight;
    let y;
    if (middleOfScreen < top) {
      y = 0;
      if (this.oldY !== y) {
        this.hiddenArrow = true;
        TweenMax.to(this.arrow.current, 0.3, {
          autoAlpha: 0,
          y,
          top: fixedHeadHeight,
        });
      }
    } else if (middleOfScreen > bottom) {
      y = bottom - top;
      if (this.oldY !== y) {
        this.hiddenArrow = true;
        TweenMax.to(this.arrow.current, 0.3, {
          autoAlpha: 0,
          y,
          top: fixedHeadHeight,
        });
      }
    } else if (middleOfScreen >= top) {
      y = scrollTop + innerHeight / 2 - top;
      if (this.hiddenArrow === true) {
        this.hiddenArrow = false;
        TweenMax.to(this.arrow.current, 0.3, {
          autoAlpha: 1,
          y,
          top: fixedHeadHeight,
        });
      } else {
        TweenMax.to(this.arrow.current, 0, {
          force3D: true,
          y,
          top: fixedHeadHeight,
        });
      }
    }
    this.oldY = y;
  }

  useArrow = (forward) => {
    let newActiveColumn = this.activeColumn;
    if (forward) {
      newActiveColumn++;
    } else {
      newActiveColumn--;
    }
    this.moveTo(-this.firstColumn.current.offsetWidth * newActiveColumn, true);
  }

  toggleLayout = () => {
    const { layout } = this.state;
    this.moveTo(0, false);
    this.setState({ layout: layout === 'columns' ? 'rows' : 'columns' }, () => {
      this.shouldInitHammer();
      this.wrapper.current.scrollIntoView({ behavior: 'auto' });
      window.scrollBy(0, -document.querySelector('.lineUpFilterWrapper').offsetHeight);
    });
  }

  showArtist = (theArtist, el, openType, e) => {
    const { artist } = this.state;
    if (this.pauseHover || artist === theArtist) return;

    const deviceScreen = this.responsiveUtility.current.deviceScreen();

    if (
      (openType === 'mouseover' && ['sm', 'md'].indexOf(deviceScreen) > -1) || (openType === 'click' && ['sm', 'md'].indexOf(deviceScreen) === -1)
    ) {
      return;
    }

    this.setState({ artist: theArtist, artistEl: el }, () => {
      this.positionFloatingArtist(e, openType);
    });
  }

  hideFloatingArtistOnScroll = () => {
    if (window && window.scrollY > 0) {
      this.floatingArtist.hide(this.hideArtist);
    }
  }

  checkIfMouseInArtistEl = (e) => {
    const { artistEl } = this.state;
    const isIn = isEventInElement(e, artistEl);
    const isInFloating = isEventInElement(e, this.floatingArtistMain.current);

    if (!isIn && !isInFloating) {
      this.floatingArtist.hide(this.hideArtist);
    }
  }

  hideArtist = () => {
    this.setState({ artist: null, artistEl: false });
    if (this.wrapper.current) this.wrapper.current.removeEventListener('mousemove', this.checkIfMouseInArtistEl);
    document.removeEventListener('scroll', this.hideFloatingArtistOnScroll);
  }

  shouldInitHammer() {
    require('hammerjs');
    this.doesContentOverflow(() => {
      this.initHammer();
    });
  }

  positionFloatingArtist(e, openType) {
    if (openType === 'click') {
      TweenMax.to(this.floatingArtistMain.current, 0, {
        force3D: true,
        x: 0,
        y: 0,
      });
      return;
    }

    const { artistEl } = this.state;

    if (!this.floatingArtistMain.current || !artistEl) {
      this.hideArtist();
      return;
    }

    const w = this.responsiveUtility.current.deviceWidth();

    const elRect = artistEl.getBoundingClientRect();
    const floatingElRect = this.floatingArtistMain.current.getBoundingClientRect();

    const floatingElementWidth = floatingElRect.width;
    const floatingElementHeight = floatingElRect.height;

    let x = 0;
    let y = 0;

    if (elRect.right + floatingElementWidth < w) {
      x = elRect.right;
    } else {
      x = elRect.left - floatingElementWidth;
    }

    if (elRect.top + elRect.height / 2 - floatingElementHeight / 2 > 0) {
      y = elRect.top + elRect.height / 2 - floatingElementHeight / 2;
    } else {
      y = elRect.bottom - elRect.height;
    }

    TweenMax.to(this.floatingArtistMain.current, 0, {
      force3D: true,
      x,
      y,
    });

    this.wrapper.current.addEventListener('mousemove', this.checkIfMouseInArtistEl);
    document.addEventListener('scroll', this.hideFloatingArtistOnScroll);

    this.floatingArtist.show(openType);
  }

  moveTo(_translateX, animated = false) {
    let translateX = _translateX;
    const wrapperWidth = this.wrapper.current.offsetWidth;
    const draggableWidth = this.draggable.current.offsetWidth;
    const fixedColumnWidth = this.fixedColumn.current.offsetWidth;
    const maxTranslateX = wrapperWidth + Math.abs(translateX) - fixedColumnWidth;

    let movementIsAllowed = true;

    if (translateX > 0) {
      translateX = 0;
      movementIsAllowed = false;
    } else if (maxTranslateX > draggableWidth) {
      translateX = -(draggableWidth - wrapperWidth + fixedColumnWidth);
      movementIsAllowed = false;
    }

    if (animated) {
      TweenMax.to([this.draggable.current, this.draggableHeader.current], 0.3, {
        force3D: true,
        x: translateX,
      });
    } else {
      TweenMax.to([this.draggable.current, this.draggableHeader.current], 0, {
        force3D: true,
        x: translateX,
      });
    }

    this.doesContentOverflow();

    this.activeColumn = Math.floor(Math.abs(translateX) / this.firstColumn.current.offsetWidth);

    return movementIsAllowed;
  }

  doesContentOverflow(callback = null) {
    const draggableWidth = this.draggable.current.offsetWidth;
    const wrapperWidth = this.wrapper.current.offsetWidth;
    const newContentOverflows = draggableWidth > wrapperWidth;
    const { contentOverflows } = this.state;
    if (contentOverflows !== newContentOverflows) {
      this.setState({
        contentOverflows: newContentOverflows,
      }, () => {
        if (callback) callback();
      });
    } else {
      if (callback) callback();
    }
  }

  initHammer() {
    const isTouch = this.responsiveUtility.current.isTouch();
    const deviceScreen = this.responsiveUtility.current.deviceScreen();

    const options = isTouch ? { touchAction: 'auto' } : {};

    const multiplier = deviceScreen === 'sm' ? 34 : 24;
    const that = this;

    const scrollingElements = [this.draggable.current, this.draggableHeader.current];

    if (this.hammerManagers) {
      if (this.hammerManagers.length > 0) {
        this.hammerManagers.forEach((manager) => {
          manager.stop(true);
          manager.destroy();
        });
      }
    }

    this.hammerManagers = [];

    const { contentOverflows } = this.state;

    if (contentOverflows) {
      scrollingElements.forEach((scrollingElement) => {
        const sliderManager = new Hammer.Manager(scrollingElement, options);
        sliderManager.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_HORIZONTAL }));
        sliderManager.on('pan', (e) => {
          that.pauseHoverWhenNeeded();
          const translateX = getTranslateX(scrollingElement) + e.velocityX * multiplier;
          that.moveTo(translateX);
        });
        this.hammerManagers.push(sliderManager);
      });
    }
  }

  pauseHoverWhenNeeded() {
    if (this.pauseHoverTimeout) clearTimeout(this.pauseHoverTimeout);
    if (!this.pauseHover) this.pauseHover = true;
    this.pauseHoverTimeout = setTimeout(() => {
      this.pauseHover = false;
    }, 400);
  }

  render() {
    const {
      startAndEndHours,
      artistsByDay,
      venues,
      day,
      match,
      eventSlug,
      hasConferences,
      isUserLoggedIn,
      handleSetArtistSetFavorite,
      category,
    } = this.props;
    const { contentOverflows, layout, artist } = this.state;

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(
            contextLanguage,
            enteredFrom,
            match,
          );

          return (
            <div ref={this.wrapper} className="mb-12 w-full">
              <PSResponsiveUtility
                ref={this.responsiveUtility}
                deviceScreenCallback={this._updateDeviceScreen}
                deviceWidthCallback={this.hideArtist}
              />
              {artist && !hasConferences && (
                <FloatingArtistCard
                  wrappedComponentRef={(c) => { this.floatingArtist = c; }}
                  mainRef={this.floatingArtistMain}
                  style={{ zIndex: 1000 }}
                  artist={artist}
                  localization={localization}
                  hideArtistCallback={this.hideArtist}
                  eventSlug={eventSlug}
                  favorite={artist.artistSetIsFavorite}
                  isUserLoggedIn={isUserLoggedIn}
                  handleSetArtistSetFavorite={handleSetArtistSetFavorite}
                  color={category}
                />
              )}
              <div className="w-full relative z-0">
                <div className="flex-1 w-full">
                  <MainLayout
                    fixedColumnRef={this.fixedColumn}
                    toggleLayoutCallback={this.toggleLayout}
                    layout={layout}
                    day={day}
                    artistsByDay={artistsByDay}
                    venues={venues}
                    eventSlug={eventSlug}
                    contentOverflows={contentOverflows}
                    firstColumnRef={this.firstColumn}
                    localization={localization}
                    startAndEndHours={startAndEndHours}
                    showArtistCallback={this.showArtist}
                    hideArtistCallback={this.hideArtist}
                    hasConferences={hasConferences}
                    draggableRef={this.draggable}
                    draggableHeaderRef={this.draggableHeader}
                    fixHeadRef={this.fixHead}
                  />
                </div>
                {contentOverflows && <NavigationArrows arrowRef={this.arrow} useArrowCallback={this.useArrow} />}
              </div>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

LineupGridDay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  artistsByDay: PropTypes.any.isRequired,
  startAndEndHours: PropTypes.shape().isRequired,
  venues: PropTypes.shape().isRequired,
  day: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  eventSlug: PropTypes.string,
  hasConferences: PropTypes.bool,
};

LineupGridDay.defaultProps = {
  eventSlug: null,
  hasConferences: false,
};

export default withRouter(LineupGridDay);
