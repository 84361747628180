import { Component } from 'react';
import { PropTypes } from 'prop-types';

function debounce(func, wait, immediate) {
  let timeout;
  return () => {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function _isTouch() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = query => window.matchMedia(query).matches;

  // eslint-disable-next-line no-undef, no-mixed-operators
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

class PSResponsiveUtility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceWidth: 0,
      deviceHeight: 0,
      deviceScreen: '',
    };

    this.isTouch = this.isTouch;
    this.deviceScreen = this.deviceScreen;
    this.deviceWidth = this.deviceWidth;
    this.updateDimensions = debounce(() => this._updateDimensions(false), 50);
  }


  componentDidMount() {
    window.addEventListener('orientationchange', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    this._updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('orientationchange', this.updateDimensions);
  }

  deviceScreen = (forceUpdate = false, rdlSizes = false) => {
    if (forceUpdate) {
      const sizes = this._updateDimensions(forceUpdate, rdlSizes);
      return sizes.deviceScreen;
    }
    const { deviceScreen } = this.state;
    return deviceScreen;
  }

  deviceWidth = () => {
    const { deviceWidth } = this.state;
    return deviceWidth;
  }

  _updateDimensions = (forceUpdate = false) => {
    const { rdlSizes } = this.props;
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    let _deviceScreen;

    if (rdlSizes) {
      _deviceScreen = 'mobile';
      if (width >= 1440) {
        _deviceScreen = 'desktop';
      } else if (width >= 1280) {
        _deviceScreen = 'laptop';
      } else if (width >= 714) {
        _deviceScreen = 'tablet';
      }
    } else {
      _deviceScreen = 'sm';
      if (width >= 1800) {
        _deviceScreen = 'xxl';
      } else if (width >= 1200) {
        _deviceScreen = 'xl';
      } else if (width >= 992) {
        _deviceScreen = 'lg';
      } else if (width >= 640) {
        _deviceScreen = 'md';
      }
    }

    const nDO = (window.orientation === -90 || window.orientation === 90) ? 'landscape' : 'portrait';

    const {
      deviceScreen,
      deviceHeight,
      deviceWidth,
      deviceOrientation,
    } = this.state;

    if (forceUpdate) {
      return {
        deviceScreen: _deviceScreen,
        width,
        height,
        deviceOrientation: nDO,
      };
    }

    if (deviceScreen !== _deviceScreen) {
      this.setState({ deviceScreen: _deviceScreen }, () => {
        const { deviceScreenCallback } = this.props;
        if (typeof deviceScreenCallback !== 'undefined') {
          deviceScreenCallback(_deviceScreen);
        }
      });
    }
    if (deviceWidth !== width) {
      this.setState({ deviceWidth: width }, () => {
        const { deviceWidthCallback } = this.props;
        if (typeof deviceWidthCallback !== 'undefined') {
          deviceWidthCallback(width);
        }
      });
    }
    if (deviceHeight !== height) {
      this.setState({ deviceHeight: height });
    }
    if (deviceOrientation !== nDO) {
      this.setState({ deviceOrientation: nDO });
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isTouch() {
    return _isTouch();
  }

  render() {
    return null;
  }
}

PSResponsiveUtility.propTypes = {
  // eslint-disable-next-line react/require-default-props
  deviceScreenCallback: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  deviceWidthCallback: PropTypes.func,
  rdlSizes: PropTypes.bool,
};

export default PSResponsiveUtility;
