import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const SimpleButton = ({
  text, url, match, className, borders, isDisabled, theme, isLowercase, isFullWidth, callback,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      let usableUrl = url;
      if (url !== '' && url.substring(0, 1) !== '/' && !/^https?:\/\//.test(url)) {
        usableUrl = `/${url}`;
      }
      if (url !== '' && url.length === 1 && url === '/') {
        usableUrl = '';
      }

      const handleTheme = () => {
        switch (theme.base) {
          case 'red': return `${borders} cursor-pointer text-red border-red ${theme.hover === 'red' ? 'hover:text-white hover:bg-red' : 'hover:text-white hover:border-white'}`;
          case 'bgred': return 'cursor-pointer bg-red border-red border-12 text-white cursor-pointer hover:bg-white hover:text-red';
          case 'grey': return `${borders} cursor-pointer text-grey border-grey ${theme.hover === 'red' ? 'hover:text-white hover:bg-red hover:border-red' : 'hover:text-white hover:border-white'}`;
          case 'white': return `${borders} cursor-pointer text-white border-white ${theme.hover === 'red' ? 'hover:text-red hover:border-red' : 'hover:text-red hover:bg-white'}`;
          case 'disabled': return `${borders} text-grey border-grey cursor-not-allowed`;
          default:
            return `${borders}`;
        }
      };

      // TODO new component with button inner?
      // eslint-disable-next-line react/prop-types
      const Button = ({ onClick }) => (
        <span onClick={onClick} className={`${className !== '' ? className : 'py-6px laptop:py-1 desktop:py-2 text-base tablet:text-sm laptop:text-base px-6px laptop:px-4'} text-center font-stratos font-bold whitespace-pre trans inline-block bg-transparent ${handleTheme()} ${isFullWidth ? 'w-full' : ''} ${isLowercase ? '' : 'uppercase'}`}>
          {translateObj(text, localization.language)}
        </span>
      );

      return (
        <>
          {(isDisabled || typeof callback === 'function') && url === '' ? (
            <Button onClick={callback} />
          ) : (
            <Link to={`${usableUrl}`}>
              <Button />
            </Link>
          )}
        </>
      );
    }}
  </RoutesContext.Consumer>
);

SimpleButton.propTypes = {
  isFullWidth: PropTypes.bool,
  isLowercase: PropTypes.bool,
  isDisabled: PropTypes.bool,
  borders: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]).isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  callback: PropTypes.func,
  theme: PropTypes.shape({
    base: PropTypes.oneOf(['red', 'white', 'grey', 'disabled']).isRequired,
    hover: PropTypes.oneOf(['red', 'white']),
  }),
};

SimpleButton.defaultProps = {
  isFullWidth: false,
  isLowercase: false,
  className: '',
  url: '',
  borders: 'border-5 laptop:border-4',
  isDisabled: false,
  callback: () => {},
  theme: {
    base: 'red',
    hover: 'red',
  },
};

export default withRouter(SimpleButton);
