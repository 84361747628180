import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Tag from '../Buttons/Tag';
import TitleLineH2 from '../Text/TitleLineH2';
import SubtitleLineH2 from '../Text/SubtitleLineH2';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import NewsletterSubscribeWidgetReusable from '../NewsletterSubscribeWidgetReusable';
import dictionaryCategories from '../dictionaryCategories';
import { useRoutes } from '../../../context/routesContext';
import SubscriptionTag from '../SubscriptionTag';
import slugify from '../../../util/slugify';

const MAGAZINE_PAGE_POST_CATEGORY = 'magazine-page';
const IMAGE_SIZE = '1440x900';
const IMAGE_SIZE_HALF_HEIGHT = '1440x450';

const PlaceholderImageSquare = ({ image, title, language, rolesAllowed }) => (
  typeof image.en === 'string' && image.en.length > 0 ? (
    <div className="relative">
      <img loading="lazy" className="block" style={{ width: '100%' }} src={imageModifier(translateObj(image, language), '400x400')} alt={translateObj(title, language)} id={slugify(title.en.toLowerCase())} />
      <SubscriptionTag className="text-black" isAbsolute rolesAllowed={rolesAllowed} />
    </div>
  ) : (
    <div className="w-full bg-grey block" style={{ paddingBottom: '100%' }} />
  )
);

PlaceholderImageSquare.propTypes = {
  image: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string,
  }).isRequired,
  title: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string,
  }),
  language: PropTypes.string.isRequired,
  rolesAllowed: PropTypes.arrayOf(PropTypes.string),
};

PlaceholderImageSquare.defaultProps = {
  title: null,
  rolesAllowed: null,
};

const PlaceholderImagePortrait = ({ image, title, language }) => (
  typeof image.en === 'string' && image.en.length > 0 ? (
    <div
      className="relative min-w-full"
    >
      <img loading="lazy" className="block w-full" src={imageModifier(translateObj(image, language), '484x624')} alt={translateObj(title, language)} />
    </div>
  ) : (
    <div className="w-full bg-grey block" style={{ paddingBottom: '100%' }} />
  )
);

PlaceholderImagePortrait.propTypes = {
  image: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string,
  }).isRequired,
  title: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string,
  }),
  language: PropTypes.string.isRequired,
};

PlaceholderImagePortrait.defaultProps = {
  title: null,
};

const PlaceholderImage = ({ image, title, language, squareImage, isGif, imageSize }) => (
  typeof image !== 'undefined' && image && typeof image.en === 'string' && image.en.length > 0 ? (
    <>
      {
        squareImage ? (
          <>
            {isGif ? (
              <div
                className="min-w-full h-full relative"
                style={{
                  backgroundImage: `url(${imageModifier(translateObj(image, language), '/filters:blur(10)')})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div
                  className="h-full flex flex-row items-center justify-center py-3"
                  style={{
                    paddingRight: '21.88%',
                    paddingLeft: '21.88%',
                    paddingTop: '3.13%',
                    paddingBottom: '3.13%',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  }}
                >
                  <img loading="lazy" src={imageModifier(translateObj(image, language))} alt={translateObj(title, language)} />
                </div>
              </div>
            ) : (
              <div
                className="relative min-w-full"
              >
                <img className="min-w-full block" loading="lazy" src={imageModifier(translateObj(image, language), `${imageSize}/filters:blur(100)`)} alt={translateObj(title, language)} />
                <div className="absolute pin flex items-center justify-center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)' }}>
                  <img style={{ height: '90%', width: 'auto' }} loading="lazy" src={imageModifier(translateObj(image, language), '900x900')} alt={translateObj(title, language)} />
                </div>
              </div>
            )
          }
          </>
        ) : <img className="min-w-full block" loading="lazy" src={imageModifier(translateObj(image, language), imageSize)} alt={translateObj(title, language)} />
      }
    </>
  ) : (
    <>
      {
        squareImage ? (
          <div className="w-full bg-grey" style={{ paddingBottom: '100%' }} />
        ) : (
          <div className="w-full bg-grey" style={{ paddingBottom: '62.256%' }} />
        )
      }
    </>
  )
);

PlaceholderImage.propTypes = {
  image: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string,
  }).isRequired,
  title: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string,
  }),
  language: PropTypes.string.isRequired,
  squareImage: PropTypes.bool,
  isGif: PropTypes.bool,
  imageSize: PropTypes.string,
};

PlaceholderImage.defaultProps = {
  title: null,
  squareImage: null,
  isGif: null,
  imageSize: undefined,
};

const LauncherItemSingle = ({
  itemLayout,
  url,
  image,
  title,
  description,
  tags,
  changeCategory,
  isBanner,
  isNews,
  isHalf,
  layout,
  subtitle,
  date,
  unformattedDate,
  authors,
  postCategory,
  isFeatured,
  mainNews,
  isListPage,
  listPageType,
  relatedMagazine,
  overlayImage,
  rolesAllowed,
}) => {
  const { language } = useRoutes();

  const urlWithSlash = url?.[0] === '/' || /^https?:\/\//.test(url) ? url : `/${url}`;
  const backgroundStyle = {
    backgroundImage: `url(${imageModifier(translateObj(image, language), '1440x620')})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  const imageSize = isHalf ? IMAGE_SIZE_HALF_HEIGHT : IMAGE_SIZE;
  const squareImage = postCategory?.[0] === 'discos';

  // If first author is Rockdelux, then show only this author:
  const authorNames = authors && (authors.length && authors[0].slug === 'rockdelux-autor' ? authors[0].name : authors.map(author => author.name).join(', '));

  const prevPostCategory = postCategory && postCategory[1] ? postCategory[1] : null;
  const ttPostCategory = prevPostCategory && dictionaryCategories[prevPostCategory] ? dictionaryCategories[prevPostCategory] : prevPostCategory;
  const dateOrMagazine = relatedMagazine || date;
  const isGif = translateObj(image, language).includes('.gif');

  const history = useHistory();

  const setClicked = (slug) => {
    const slugReplace = slugify(slug.toLowerCase());
    const search = history.location.search.toLowerCase().split('?');

    history.replace(`${history.location.pathname}?${search[1]}?${slugReplace}`);

    if (search.length >= 3) {
      search.splice(2, 1);
    }
  };

  const CategoryAndAuthor = () => (
    <>
      {ttPostCategory
      && (
        <>
          <span className={`font-semibold ${mainNews ? 'laptop:font-semibold' : 'laptop:font-bold'}`}>
            {ttPostCategory.toUpperCase()}
          </span>
          {' '}
          /
        </>
      )
      }
      {' '}
      {authorNames && `Por ${authorNames}`}
      {' '}
      {dateOrMagazine && `→ ${dateOrMagazine}`}
    </>
  );

  const renderMagazine = () => (
    <div>
      <div className="relative mb-4 laptop:mb-5">
        <Link to={urlWithSlash}>
          <PlaceholderImagePortrait image={image} title={title} language={language} />
        </Link>
      </div>
      <Link to={urlWithSlash}>
        {title && <TitleLineH2 text={title} margin="mb-2 tablet:mb- w-full hover:text-red" color="black" />}
        {subtitle && <SubtitleLineH2 text={subtitle} margin="mb-5 tablet:mb-7 w-full" color="black" />}
      </Link>
    </div>
  );

  const renderRecord = () => (
    <div>
      <div className="relative mb-4 laptop:mb-5">
        <Link onClick={() => setClicked(title.en)} to={urlWithSlash}>
          <PlaceholderImageSquare image={image} title={title} rolesAllowed={rolesAllowed} language={language} />
        </Link>
        {/* TODO: handle tags */}
        {/* {tags && (
          <div className="absolute z-50 py-8 pin-b pin-l">
            <Tag
              changeCategory={() => changeCategory(postCategory[0])}
              text={postCategory[0]}
              category={postCategory[0]}
              mainNews={mainNews}
            />
          </div>
        )} */}
        {overlayImage && (
          <div className="absolute pin-t pin-r mt-2 mr-2">
            <img loading="lazy" src={imageModifier(overlayImage, '45x45')} alt="Premium content" />
          </div>
        )}
      </div>
      <Link onClick={() => setClicked(title.en)} to={urlWithSlash}>
        {title && <TitleLineH2 text={title} margin={`${mainNews ? 'mb-2' : 'mb-2 tablet:mb-4'} w-full hover:text-red`} color="black" mainNews={mainNews} />}
        {subtitle && <SubtitleLineH2 text={subtitle} margin={`${mainNews ? 'mb-5' : 'mb-5 tablet:mb-7'} w-full`} color="black" mainNews={mainNews} />}
      </Link>
    </div>
  );

  const isBrandedContent = postCategory?.includes('branded');

  const renderFeatured = () => (
    <div className="laptop:flex px-2 laptop:px-3">
      <div className="relative laptop:flex-1">
        <Link to={urlWithSlash}>
          {image.hasOwnProperty('en') && image.en !== '' && image.en !== null
            ? <img loading="lazy" className="hidden laptop:block h-full w-full" src={imageModifier(translateObj(image, language), '900x625')} alt={translateObj(title, language)} />
            : <div className="hidden laptop:block w-full h-full bg-grey" />
          }
          <div className="laptop:hidden">
            <PlaceholderImage image={image} title={title} language={language} squareImage={squareImage} isGif={isGif} imageSize={imageSize} />
          </div>
        </Link>
        <SubscriptionTag isAbsolute rolesAllowed={rolesAllowed} />
        {tags && !isBrandedContent && (
          <div className="absolute z-50 py-8 pin-b pin-l">
            <Tag
              changeCategory={() => changeCategory(postCategory[0])}
              text={postCategory[0]}
              category={postCategory[0]}
              mainNews={mainNews}
            />
          </div>
        )}
        {overlayImage && (
          <div className="absolute pin-t pin-r mt-2 mr-2">
            <img loading="lazy" src={imageModifier(overlayImage, '45x45')} alt="Premium content" />
          </div>
        )}
      </div>
      <div className="laptop:flex-1 bg-beige pl-8 laptop:pl-12 pt-8 pr-8 laptop:pr-12 laptop:pt-12 pb-8">
        <div className="h-full flex flex-col justify-between items-start">
          <div>
            <Link to={urlWithSlash} className="mb-8 block">
              {title && <h2 className="font-stratos leading-32 tablet:leading-42 laptop-leading:46 desktop:leading-46 text-black font-semibold text-26 tablet:text-38 hover:text-red">{translateObj(title, language)}</h2>}
              {subtitle && <h3 className="font-stratos text-black font-semilight tablet:text-22 mt-2">{translateObj(subtitle, language)}</h3>}
            </Link>
            {description && <div className="my-4 font-merriw mb-8 desktop:text-base tablet:text-15 text-14 text-black desktop:leading-25 tablet:leading-22 leading-20 font-light tablet:font-normal">{translateObj(description, language)}</div>}
          </div>
          <span className={`block text-grey font-stratos text-12 font-light ${mainNews ? 'laptop:text-12 laptop:text-13 laptop:font-light' : 'laptop:text-13 laptop:font-normal'}`}>
            <CategoryAndAuthor />
          </span>
        </div>
      </div>
    </div>
  );

  const isHemeroteca = postCategory?.[0] === MAGAZINE_PAGE_POST_CATEGORY;

  const renderDefault = () => (
    <div>
      <div className="relative mb-4 laptop:mb-5">
        <Link to={urlWithSlash}>
          {isHemeroteca ? <DefaultImageHemeroteca image={image} title={title} language={language} /> : <PlaceholderImage image={image} title={title} language={language} squareImage={squareImage} isGif={isGif} imageSize={imageSize} />}
        </Link>
        <SubscriptionTag className={`${itemLayout === 'launcherBanner' && 'pt-3 pl-3 text-xl font-stratos uppercase font-bold border-t-10 border-l-10'}`} isAbsolute rolesAllowed={rolesAllowed} />
        {tags && !isBrandedContent && (
          <div className="absolute z-50 py-8 pin-b pin-l">
            <Tag
              changeCategory={() => changeCategory(postCategory[0])}
              text={postCategory[0]}
              category={postCategory[0]}
              mainNews={mainNews}
            />
          </div>
        )}
        {overlayImage && (
          <div className={`absolute pin-t pin-r ${isBanner ? 'mt-4 mr-4' : 'mt-2 mr-2'}`}>
            <img loading="lazy" src={imageModifier(overlayImage, isBanner ? '90x90' : '45x45')} alt="Premium content" />
          </div>
        )}
      </div>
      <Link to={urlWithSlash}>
        {title && <TitleLineH2 text={title} margin={`${mainNews ? 'mb-2' : 'mb-2 tablet:mb-4'} w-full hover:text-red`} color="black" mainNews={mainNews} />}
        {subtitle && <SubtitleLineH2 text={subtitle} margin={`${mainNews ? 'mb-5' : 'mb-5 tablet:mb-7'} w-full`} color="black" mainNews={mainNews} />}
      </Link>
      <span className={`block text-grey font-stratos text-12 font-light ${mainNews ? 'laptop:text-12 tablet:text-13 laptop:font-light' : 'laptop:text-13 laptop:font-normal'}`}>
        {!isHemeroteca ? <CategoryAndAuthor /> : null}
      </span>
      {/* {description && <div className="mt-4 font-light font-merriw">{translateObj(description, language)}</div>} */}
    </div>
  );

  const renderSingleLayout = () => {
    if (isFeatured) {
      return renderFeatured();
    }
    if (isListPage) {
      if (listPageType === 'records') return renderRecord();
      if (listPageType === 'hemeroteca') return renderMagazine();
    }
    return renderDefault();
  };

  const renderNewsletterLayout = () => (
    <div className={`relative border-t border-transparent launcherItemData ${itemLayout} ${isNews && !isHalf ? 'single-news' : ''} ${isBanner ? 'sliderComponent' : ''}`}>
      {isBanner
        ? (
          <div
            key={image.en}
            className="w-full overflow-hidden sliderComponent sm:h-launcherSingle-sm md:h-launcherSingle-md lg:h-launcherSingle-lg llg:h-launcherSingle-llg"
            style={backgroundStyle}
          />
        ) : (
          <div className="relative launcherItemImg">
            <img loading="lazy" className="min-w-full" src={imageModifier(translateObj(image, language), imageSize)} alt={translateObj(title, language)} />
          </div>
        )}
      <div className={`absolute pin-l pin-b ${!isHalf ? 'md:p-10' : 'md:p-4'} lg:p-4 llg:p-10 text-white sm:p-5 w-full z-50 no-underline`}>
        {title && <TitleLineH2 text={title} margin="my-6 lg:my-4 w-full" color="white" />}
        <p
          className="mb-1 text-white uppercase whitespace-pre-line cursor-default font-americaMonoRegular text-14 md:text-12"
        >
          {translateObj(subtitle, language)}
        </p>
        <div className={`w-full ${isBanner ? 'md:w-2/3 lg:w-1/2 xl:w-1/2' : ''}`}>
          <NewsletterSubscribeWidgetReusable
            scopes={{
              en: tags,
            }}
            isLauncherItem
          />
        </div>
      </div>
    </div>
  );
  return (
    <>
      {layout !== 'newsletter'
        ? renderSingleLayout() : renderNewsletterLayout()}
    </>
  );
};

LauncherItemSingle.propTypes = {
  itemLayout: PropTypes.string.isRequired,
  layout: PropTypes.string,
  isFeatured: PropTypes.boolean,
  match: PropTypes.string.isRequired,
  title: PropTypes.shape().isRequired,
  isBanner: false,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  changeCategory: PropTypes.func,
  isListPage: PropTypes.bool,
  listPageType: PropTypes.string,
  rolesAllowed: PropTypes.arrayOf(PropTypes.string),
}.isRequired;

LauncherItemSingle.defaultProps = {
  rolesAllowed: [],
};

const DefaultImageHemeroteca = ({ image, title, language }) => (
  <div className="relative min-w-full">
    <img loading="lazy" className="min-w-full" src={imageModifier(translateObj(image, language), `${IMAGE_SIZE}/filters:blur(100)`)} alt={translateObj(title, language)} />
    <div className="absolute pin flex items-center justify-center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)' }}>
      <img loading="lazy" style={{ height: '90%', width: 'auto' }} src={imageModifier(translateObj(image, language), '940x1200')} alt={translateObj(title, language)} />
    </div>
  </div>
);

DefaultImageHemeroteca.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  title: PropTypes.shape().isRequired,
};

export default withRouter(LauncherItemSingle);
