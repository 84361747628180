import React from 'react';
import { PropTypes } from 'prop-types';

const Container = ({ children, className, noMargins }) => (
  <div className={`${noMargins ? '' : 'mx-5 tablet:mx-12 laptop:mx-24'} ${className}`}>
    {children}
  </div>
);

export default Container;

Container.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  noMargins: PropTypes.bool,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: '',
  noMargins: false,
};
