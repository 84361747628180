import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { UserProfileContext, useUser } from '../../../../context/userProfileContext';
import { translations } from '../../Login/LoginUtils/onDemandLoginSimpleTexts';
import SimpleOnDemandModalScreen from './SimpleOnDemandModalScreen';

const WrapperLoginScreen = ({ language, hideCloseButton, redirectTo, redirectToIfSubscribed }) => {
    const userProps = useUser();
    const history = useHistory();
    const userContext = useContext(UserProfileContext);

    const userAlreadySubscribed = userContext.userRoles.includes('rdl-subscription');

    const redirect = () => {
        if(userAlreadySubscribed) {
            history?.push(redirectToIfSubscribed)
        } else {
            history?.push(redirectTo);
        }
    };

    return (
        <div style={{ marginTop: '200px' }}>
          <SimpleOnDemandModalScreen 
          {...userProps} 
          hideCloseButton={hideCloseButton} 
          translations={translations} 
          language={language} 
          closeModal={redirect}
          sourceWeb=""
         />
         </div>
    )
};

WrapperLoginScreen.propTypes = {
  language: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  redirectTo: PropTypes.string,
  redirectToIfSubscribed: PropTypes.string,
};

WrapperLoginScreen.defaultProps = {
  language: 'es',
  hideCloseButton: true,
  redirectTo: '/suscribete-mes',
  redirectToIfSubscribed: '/',
};

export default WrapperLoginScreen;
