import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LineupTableItem from './LineupTableItem';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';

class LineupTable extends PureComponent {
  state = {
    showTime: true,
    showStage: true,
    width: null,
    reset: false,
  };

  responsiveUtility = React.createRef();

  getWidth = () => {
    const device = this.responsiveUtility.current.deviceWidth(true);
    this.setState({ width: device });
    if (device > 640) {
      this.setState({ reset: true });
    }
  };

  setReset = () => {
    this.setState({ reset: false });
  };

  render() {
    const {
      artistsByDay, venues, eventSlug, showDate, showDateTime, showVenue, timezone, hasConferences, isUserLoggedIn, handleSetArtistSetFavorite, category,
    } = this.props;
    const {
      showTime, showStage, width, reset,
    } = this.state;

    return (
      <div className="w-full">
        <PSResponsiveUtility ref={this.responsiveUtility} deviceWidthCallback={this.getWidth} />
        {Object.keys(artistsByDay).map(day => Object.keys(artistsByDay[day]).map(venue => (
          <div className="mb-12" key={`${day}-${venue}`}>
            <p className="uppercase font-americaMonoBold mb-12">
              {day === 'Invalid date' || !showDate ? 'TBD' : day}
              {' '}
              |
              <span className="text-barcelona ">{venues[venue] && (venues[venue].es || venues[venue].en)}</span>
              {' '}
            </p>
            <table className="w-full sm:border-t border-black">
              <thead className="sm:hidden">
                <tr className="bg-grayE2DDDB ">
                  <th className="w-24" />
                  <th className="text-left font-americaMonoRegular text-12 py-3">{hasConferences ? 'Conference' : 'Artist'}</th>
                  {showTime && <th className="text-left font-americaMonoRegular text-12 w-1/5">Time</th>}
                  {showStage && <th className="text-left font-americaMonoRegular text-12 w-1/5">{hasConferences ? 'Room' : 'Stage'}</th>}
                </tr>
              </thead>
              <tbody>
                {artistsByDay[day][venue]
                  .sort((a, b) => {
                    if (showDateTime) {
                      if (a.dateTimeStartReal > b.dateTimeStartReal) {
                        return 1;
                      }
                      if (a.dateTimeStartReal < b.dateTimeStartReal) {
                        return -1;
                      }
                    } else {
                      if (a.artistSetSlugName < b.artistSetSlugName) {
                        return -1;
                      }
                      if (a.artistSetSlugName > b.artistSetSlugName) {
                        return 1;
                      }
                    }

                    return 0;
                  })
                  .map(artist => (
                    <LineupTableItem timezone={timezone} key={`${artist.artistSlugName}-${artist.venueSlugName}-${artist.artistSetSlugName}`} reset={reset} eventSlug={eventSlug} setReset={this.setReset} width={width} artist={artist} showDate={showDate} showDateTime={showDateTime} showVenue={showVenue} favorite={artist.artistSetIsFavorite} isUserLoggedIn={isUserLoggedIn} handleSetArtistSetFavorite={handleSetArtistSetFavorite} category={category} />
                  ))}
              </tbody>
            </table>
          </div>
        )))}
      </div>
    );
  }
}

LineupTable.propTypes = {};

export default LineupTable;
