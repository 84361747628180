/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import translateObj from '../../util/translateObj';
// import getLocalization from '../../util/localization';
import RoutesContext from '../../context/routesContext';
import ComponentIterator from '../../containers/ComponentIterator';

const GroupedItems = ({
  bgColor, color, title, hasViewAll, components, parentComponent,
}) => {
  const { language } = useContext(RoutesContext);

  const getLayout = () => {
    const arr = [];
    components.forEach(component => (
      !arr.includes(component.layout) && arr.push(component.layout)
    ));

    if (arr.length === 1) return arr[0];
    return undefined;
  };

  const handleMargins = () => {
    if (['StandardArticlePage', 'ListsPostPage', 'RecordsPostPage', 'CoverStoryPostPage'].includes(parentComponent)) {
      return 'mt-6 mb-0';
    }
    if (['NewsPostPage'].includes(parentComponent)) {
      return 'mb-0';
    }

    if (!parentComponent) {
      switch (getLayout()) {
        case 'noticias': return 'mt-8 mb-12 tablet:mt-4 tablet:mb-16 laptop:mt-0';
        case 'curatedList': return 'mb-10 laptop:mb-24';
        case 'discos': return 'mt-6 mb-0 mb-10';
        default: return 'mt-6 mb-0 tablet:mb-8';
      }
    }
    return '';
  };

  const titleMargin = getLayout() === 'discos' ? 'mb-3' : 'mb-8 tablet:mb-10 laptop:mb-26px desktop:mb-29px';

  return (
    <div
      className={`w-full full-width py-8 tablet:py-42px ${parentComponent === 'RecordsPostPage' ? ' laptop:py-14' : 'laptop:py-12'} ${handleMargins()}`}
      style={{ backgroundColor: bgColor }}
    >
      <div className={`flex flex-row justify-between items-center mx-5 tablet:mx-42px laptop:mx-24 ${parentComponent === 'RecordsPostPage' ? 'mb-6 tablet:mb-8 laptop:mb-12' : titleMargin}`}>
        <h2
          className={`${parentComponent === 'RecordsPostPage' ? 'text-32 tablet:text-26 laptop:text-32 desktop:text-36 font-semibold' : 'text-32 laptop:text-42 font-bold'} font-stratos leading-tight`}
          style={{ color }}
        >
          {translateObj(title, language)}
        </h2>
        {
          hasViewAll
          && (
          <Link to={hasViewAll.link} className="flex items-center mt-3">
            <span
              className="leading-none uppercase font-stratos text-13 tablet:text-13 laptop:text-base desktop:text-base hover:underline mb-2px"
              style={{ color }}
            >
              {translateObj(hasViewAll.text, language)}
            </span>
            <span className="ml-2 font-icon icon-arrow text-10 laptop:text-12" style={{ color }} />
          </Link>
          )
        }
      </div>
      {components.map((component) => {
        Object.assign(component, { parentComponent });
        return (
          <div key={`groupedItems-${parentComponent}`}>
            <ComponentIterator components={[component]} />
          </div>
        );
      })}
    </div>
  );
};

GroupedItems.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.shape(),
  hasViewAll: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  components: PropTypes.arrayOf(PropTypes.object),
  parentComponent: PropTypes.string,
};

GroupedItems.defaultProps = {
  bgColor: 'transparent',
  color: 'transparent',
  title: null,
  hasViewAll: false,
  components: null,
  parentComponent: null,
};

export default GroupedItems;
