import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FloatingArtistCard from './FloatingArtistCard';
import translateObj from '../../util/translateObj';

class LineupListItem extends PureComponent {
  state = {
    top: 0,
    left: '-50%',
    cardHeight: 0,
    hideCard: true,
  };

  componentDidMount() {
    // If we are on SSR we don't have document so we will set dummy code
    this.body = { setAttribute: () => { } };

    // We will check if document is present, then we will assing
    if (document && document.querySelector('body')) {
      this.body = document.querySelector('body');
    }

    const { artist } = this.props;
    this.setState({
      currentArtist: artist.artistSlugName,
    });
  }

  artistCardRef = (e) => {
    if (e !== null) {
      const { height } = e.getElementsByClassName('relative')[0].getBoundingClientRect();
      this.setState({ cardHeight: height });
    }
  };

  getPositionMobile = (e) => {
    // get position of the artist clicked on mobile
    const { setArtistCard, getCardPosition, width } = this.props;
    // set card ad active or deactivate if already active
    setArtistCard(this.state.currentArtist);
    // reset opacity of card
    this.setState({ hideCard: true });

    this.body.setAttribute('style', 'overflow: hidden');

    // setting timeout to get the real position of the element tapped or clicked
    setTimeout(() => {
      // set opacity and left of card
      this.setState({ hideCard: false, left: '50%' });
      // set position
      getCardPosition();
    }, 100);

    // if (width > 768) {
    //   console.log('hello');
    //   this.body.setAttribute('style', 'overflow-y: auto');

    //   const containerPosition = document.getElementById('artistList').getBoundingClientRect().top;
    //   const parentContainer = e.currentTarget.parentNode;
    //   const elementActive = document.getElementsByClassName('artistCardActive')[0];
    //   parentContainer.classList.toggle('artistCardActive');
    //   e.currentTarget.parentNode.classList.toggle('artistCardActive');
    //   if (elementActive) elementActive.classList.remove('artistCardActive');
    //   const element = e.target.getBoundingClientRect();
    //   const position = element.top + element.height - containerPosition + 15;
    //   getCardPosition(position);
    //   e.target.scrollIntoView({ behavior: 'smooth' });
    // }

    this.setState({ left: '50%' });
  };

  getPositionDesktop = (e) => {
    this.setState({ hideCard: false });

    const element = e.target.getBoundingClientRect();
    let lastWidth;
    const { width, setArtistCard } = this.props;
    setArtistCard(this.state.currentArtist);

    lastWidth = element.width;

    if (element.x > width / 2) lastWidth = -290;

    this.setState({ left: lastWidth });
  };

  hideCard = () => {
    const { setArtistCard } = this.props;
    setArtistCard(this.state.currentArtist);
    this.body.setAttribute('style', 'overflow-y: auto');
  };

  render() {
    const {
      i, width, length, artist, activeArtist, cardPosition, underline, category, eventSlug, localization, isUserLoggedIn, handleSetArtistSetFavorite,
    } = this.props;
    const {
      currentArtist, top, left, cardHeight, hideCard,
    } = this.state;

    const artistSelected = activeArtist === currentArtist;
    return (
      <>
        <div
          className={`artistListItem flex-col relative sm:static ${artistSelected ? 'artistCardActive' : ''}`}
          style={{
            paddingBottom: artistSelected && width < 650 ? '0' : '0',
          }}
        >
          {width > 768
            ? (
              <h2 className="font-americaBlack text-center px-1 my-1 text-black" onMouseEnter={this.getPositionDesktop}>
                <Link to={`/${eventSlug || 'artist'}${artist.postUri}`}>
                  <span className={`font-americaBlack text-3xl sm:text-2xl text cursor-pointer text-black ${underline && 'underline'} active:text-${category} hover:text-${category} visited:text-black`}>
                    {' '}
                    {!underline && '•'}
                    {' '}
                    {artist.artistSetReadableName && translateObj(artist.artistSetReadableName, localization.language) ? translateObj(artist.artistSetReadableName, localization.language) : artist.artistName}
                    {' '}
                    {underline && ','}
                    {' '}
                  </span>
                </Link>
                {i === length - 1 && !underline && <span className="font-americaBlack text-3xl sm:text-2xl my-1"> • </span>}
              </h2>
            )
            : (
              <h2 className="font-americaBlack text-center px-1 my-1 text-black visited:text-black" onClick={this.getPositionMobile}>
                <span className={`font-americaBlack text-3xl sm:text-2xl cursor-pointer ${underline && 'underline'}`}>
                  {' '}
                  {!underline && '•'}
                  {' '}
                  {artist.artistSetReadableName && translateObj(artist.artistSetReadableName, localization.language) ? translateObj(artist.artistSetReadableName, localization.language) : artist.artistName}
                  {' '}
                  {underline && ','}
                  {' '}
                </span>
                {i === length - 1 && !underline && <span className="font-americaBlack text-3xl sm:text-2xl my-1"> • </span>}
              </h2>
            )
          }
          {artistSelected && (
            <FloatingArtistCard
              mainRef={this.artistCardRef}
              artist={artist}
              style={{
                zIndex: 1000,
                position: width < 769 ? 'fixed' : 'absolute',
                display: artistSelected ? 'block' : 'none',
                marginLeft: width < 769 ? '-148px' : '0',
                left: width < 769 ? '50%' : `${left}px`,
                top: width < 769 ? '50%' : `${cardPosition}px`,
                marginTop: width < 769 ? '-175px' : '0',
                right: 0,
                padding: 0,
                width: 0,
              }}
              color={category}
              hideArtistCallback={this.hideCard}
              top={top}
              eventSlug={eventSlug}
              favorite={artist.artistSetIsFavorite}
              isUserLoggedIn={isUserLoggedIn}
              handleSetArtistSetFavorite={handleSetArtistSetFavorite}
            />
          )}
        </div>
        {(artistSelected && width < 769) && <div className="pin-t pin-b pin-l h-screen w-full bg-black20 fixed" style={{ zIndex: 999 }} />}
      </>
    );
  }
}

export default LineupListItem;

LineupListItem.propTypes = {
  artist: PropTypes.shape().isRequired,
  width: PropTypes.number,
  i: PropTypes.number.isRequired,
  cardPosition: PropTypes.number.isRequired,
};

LineupListItem.defaultProps = {
  width: null,
};
