import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const ParagraphLineSM = ({
  text, align, classes, iconClass, size, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <p className={`${align} ${size} font-americaMonoRegular tracking-wide ${classes}`}>
          {translateObj(text, localization.language)}
          {' '}
          {iconClass && <span className={`${iconClass} pl-1`} />}
        </p>
      );
    }}
  </RoutesContext.Consumer>
);

ParagraphLineSM.propTypes = {
  align: PropTypes.string,
  classes: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
  }).isRequired,
  iconClass: PropTypes.string,
  size: PropTypes.string,
};

ParagraphLineSM.defaultProps = {
  align: '',
  classes: '',
  iconClass: '',
  size: 'text-xs',
};

export default withRouter(ParagraphLineSM);
