import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SocialOnDemandButton from './SocialOnDemandButton';
import translateObj from '../../../../../util/translateObj';
import { buttonClasses } from '../../OnDemandUtils/onDemandUtils';
import PSResponsiveUtility from '../../../LineUp/PSResponsiveUtility';

const SectionSocialOnDemand = ({
  language,
  loadTokenForLS,
  handleOnDemandScreenStatus,
  handleModalNotLoggedStatus,
  handleRegisteringStatus,
  translations: { notLoggedModal: translations = {} },
  sourceWeb,
  parentComponent,
}) => {
  const [error, setError] = useState('');
  const [screen, setScreen] = useState();
  const responsiveUtility = useRef();

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setScreen(deviceScreen);
  };

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} rdlSizes />
      <div className="flex flex-col items-center">
        <span className={`font-stratos text-red text-base font-medium ${screen === 'mobile' ? 'mb-4' : 'mb-6'}`}>{translateObj(translations.submitTitle, language)}</span>
        <div className={`flex flex-row w-full ${parentComponent === 'Paywall' ? 'tablet:px-10' : 'tablet:px-16'} ${screen !== 'mobile' ? 'justify-between' : 'justify-center'}`}>
          <div className={`${screen === 'mobile' ? 'mx-2' : ''}`}>
            <SocialOnDemandButton type="google" language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} handleRegisteringStatus={handleRegisteringStatus} setError={setError} />
          </div>
          <div className={`${screen === 'mobile' ? 'mx-2' : ''}`}>
            <SocialOnDemandButton type="facebook" language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} handleRegisteringStatus={handleRegisteringStatus} setError={setError} />
          </div>
          <div className={`${screen === 'mobile' ? 'mx-2' : ''}`}>
            <SocialOnDemandButton type="spotify" language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} handleRegisteringStatus={handleRegisteringStatus} setError={setError} sourceWeb={sourceWeb} />
          </div>
        </div>
        {(error)
            && <div className="font-stratos text-14 font-normal text-red mt-4">{translateObj(translations[error], language)}</div>}
      </div>
    </>
  );
};

SectionSocialOnDemand.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  handleModalNotLoggedStatus: PropTypes.func.isRequired,
  handleRegisteringStatus: PropTypes.func.isRequired,
  sourceWeb: PropTypes.string,
  parentComponent: PropTypes.string,
};

SectionSocialOnDemand.defaultTypes = {
  language: 'en',
  sourceWeb: '',
  parentComponent: '',
};

export default SectionSocialOnDemand;
