import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import ComponentIterator from '../../containers/ComponentIterator';
import translateObj from '../../util/translateObj';

class RadioTodayGridTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pre: false,
      post: false,
      middles: [],
      currentIndex: -1,
    };
  }

  componentDidMount() {
    this.processItems();
  }

  processItems() {
    const { items } = this.props;
    let { currentIndex } = this.state;

    if (currentIndex < 0) {
      currentIndex = this.findLiveIndex();
    }

    let pre = false;
    let post = false;
    const middles = [];

    if (typeof items[currentIndex - 1] !== 'undefined') {
      pre = items[currentIndex - 1];
    }
    for (let i = currentIndex; i < currentIndex + 4; i++) {
      if (typeof items[i] !== 'undefined') {
        middles.push(items[i]);
      }
    }

    if (typeof items[currentIndex + 4] !== 'undefined') {
      post = items[currentIndex + 4];
    }

    this.setState({
      currentIndex, pre, post, middles,
    });
  }

  findLiveIndex() {
    const { items } = this.props;
    let { currentIndex } = this.state;
    items.forEach((item, index) => {
      item.isLive = moment().isBetween(moment(item.startDate), moment(item.endDate));
      item.minutesToStart = moment.duration(moment(item.startDate).diff(moment())).asMinutes();
      if (item.isLive) {
        currentIndex = index;
      }
    });

    if (currentIndex < 0) {
      let currentMinutesToStart = 60 * 24;
      items.forEach((item, index) => {
        if (item.minutesToStart > 0 && item.minutesToStart < currentMinutesToStart) {
          currentIndex = index;
          currentMinutesToStart = item.minutesToStart;
        }
      });
      if (currentIndex < 0) {
        currentIndex = 0;
      }
    }
    return currentIndex;
  }

  handleArrowClick(top) {
    const { currentIndex } = this.state;
    const { items } = this.props;
    if (top) {
      if (currentIndex > 1) {
        this.setState({ currentIndex: currentIndex - 1 }, this.processItems.bind(this));
      }
    } else {
      if (currentIndex < items.length - 1) {
        this.setState({ currentIndex: currentIndex + 1 }, this.processItems.bind(this));
      }
    }
  }

  render() {
    const {
      text1, text2, text3, text4, items, icon1Url, icon2Url, icon3Url, category, match,
    } = this.props;
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          const InfoRow = ({ text1, text2, text3 }) => (
            <div className="flex font-americaMonoRegular bg-gray-light uppercase py-3 sm:hidden">
              <div className="w-1/6" />
              <div className="w-5/6 flex">
                <div className="w-1/5">{translateObj(text1, localization.language)}</div>
                <div className="w-3/5">{translateObj(text2, localization.language)}</div>
                <div className="w-1/5">{translateObj(text3, localization.language)}</div>
              </div>
            </div>
          );

          const TopBottomArrow = ({ top, item }) => {
            const { currentIndex, middles } = this.state;
            if (top && currentIndex == 1) {
              return null;
            } if (!top && currentIndex > middles.length) {
              return null;
            }
            return <img className="h-8 absolute pin-t" src={top ? icon1Url : icon2Url} alt={translateObj(item.title, localization.language)} onClick={this.handleArrowClick.bind(this, top)} />;
          };

          const PrePostRow = ({ item, top }) => (
            <div className={`flex py-4 border-solid border-black ${top ? 'border-b-1' : 'border-t-1'}`}>
              <div className="w-1/6 flex items-center justify-center sm:items-start relative h-8">
                <TopBottomArrow top={top} item={item} />
              </div>
              <div className="w-5/6 flex sm:block">
                <div className="w-1/10 sm:w-full flex items-center font-americaMonoRegular text-sm sm:mb-2 sm:text-xxs">
                  {moment(item.startDate).format('HH:mm')}
                </div>
                <div className="w-6/10 sm:w-full flex items-center font-tiemposRegular sm:mb-3">
                  <span className="pr-2">
                    {translateObj(item.title_multilang, localization.language)}
                  </span>
                </div>
                <div className="w-3/10 sm:w-full flex items-center">
                  <ComponentIterator components={item.tags} />
                </div>
              </div>
            </div>
          );

          const Row = ({ item, isFirst, isLast }) => {
            const isLive = moment().isBetween(moment(item.startDate), moment(item.endDate));
            return (
              <div className="flex">
                <div className="w-1/6 flex items-center justify-center py-5 relative">
                  <div className={`absolute bg-${category} w-px ${isFirst ? 'pin-ht' : 'pin-t'} ${isLast ? 'pin-hb' : 'pin-b'}`} />
                  {isLive
                    ? <div className="w-12 h-12"><img className="" src={icon3Url} /></div>
                    : <div className={`bg-${category} w-2 h-2 rounded-full`} />}
                </div>
                <div className={`w-5/6 flex sm:block py-7 ${isLast ? '' : 'border-solid border-black border-b-1'}`}>
                  <div className="w-1/10 sm:w-full flex items-center sm:block sm:mb-2 font-americaMonoRegular text-sm sm:text-xxs">
                    {isLive ? <span className={`text-${category} font-americaMonoBold uppercase `}>{translateObj(text4, localization.language)}</span> : moment(item.startDate).format('HH:mm')}
                  </div>
                  <div className="w-6/10 sm:w-full flex items-center font-tiemposBold sm:mb-3">
                    <span className="pr-2">
                      {translateObj(item.title_multilang, localization.language)}
                    </span>
                  </div>
                  <div className="w-3/10 sm:w-full flex items-center">
                    <ComponentIterator components={item.tags} />
                  </div>
                </div>
              </div>
            );
          };

          const { pre, middles, post } = this.state;

          return (
            <div className="w-full text-black">
              <InfoRow text1={text1} text2={text2} text3={text3} />
              {pre ? <PrePostRow item={pre} top /> : null}
              <div>
                {middles.map((item, i) => (
                  <Row key={i} item={item} isFirst={i == 0} isLast={i == middles.length - 1} />
                ))}
              </div>
              {post ? <PrePostRow item={post} top={false} /> : null}
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioTodayGridTable.propTypes = {
  items: PropTypes.array.isRequired,
  text1: PropTypes.shape().isRequired,
  text2: PropTypes.shape().isRequired,
  text3: PropTypes.shape().isRequired,
  text4: PropTypes.shape().isRequired,
  icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
  icon3Url: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default withRouter(RadioTodayGridTable);
