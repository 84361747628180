export const ORIGIN = 'user-profile';
export const SCOPES = ['scope_rdl'];
export const updateButtonText = {
  en: 'UPDATE',
  es: 'ACTUALIZAR',
  ca: 'ACTUALITZAR',
  pt: 'ACTUALIZAR',
};
export const initialState = {
  canSubmit: false,
  didSubmit: false,
  areYouSure: false,
  dateOfBirth: null,
  isAddingToNewsLetter: false,
  errorMsg: '',
};
