import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
// import Container from '../Container';
import parse from 'html-react-parser';
import DropdownContainer from '../Login/ProfileForm/DropdownContainer';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import imageModifier from '../helpers/imageModifier';

const NumberedList = ({
  open,
  sort,
  match,
  elements,
  parentComponent,
}) => {
  const [elementArray, setElementArray] = useState([...elements]);
  const [activeDropdown, setActiveDropdown] = useState('');
  const [activeDropdownArr, setActiveDropdownArr] = useState([]);

  useEffect(() => {
    if (sort === 'desc') {
      const arr = elementArray.reverse();
      setElementArray(arr);
    }

    if (open === 'all') {
      const arr = [...activeDropdownArr];
      elements.forEach((el, index) => {
        const i = index.toString();
        if (!activeDropdownArr.includes(i)) {
          arr.push(i);
        }
      });
      setActiveDropdownArr(arr);
    }

    if (open === 'last') {
      setActiveDropdown('0');
    }
  }, []);

  const dropdownClick = (id) => {
    if (open === 'all') {
      const arr = [...activeDropdownArr];
      if (arr.includes(id)) {
        const index = arr.indexOf(id);
        arr.splice(index, 1);
      } else {
        arr.push(id);
      }
      setActiveDropdownArr(arr);
    } else {
      if (activeDropdown === id) {
        setActiveDropdown('');
      } else setActiveDropdown(id);
    }
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom, currentSlugName}) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        // let addNumber = 0;
        // switch(currentSlugName){
        //   case 'listas-2020-discos-internacionales':
        //     addNumber = 40;
        //   break;
        //   case 'listas-2020-discos-nacionales':
        //     addNumber = 20;
        //   break;
        //   default:
        //     addNumber = 0;
        //   break;
        // }
        const containerClasses = () => {
          switch (parentComponent) {
            default:
            case '': return 'grid grid-cols-6 col-span-6 tablet:grid-cols-11 laptop:grid-cols-8 gap-x-4 font-stratos tablet:col-span-11 laptop:col-span-10';
            case 'ListsPostPage': return 'grid grid-cols-6 col-span-6 tablet:grid-cols-11 laptop:grid-cols-8 gap-x-4 font-stratos tablet:col-span-11 laptop:col-span-10';
          }
        };

        return (
          <div className={containerClasses()}>
            {elementArray.map((item, index) => {
              const dropdownNumber = elements.length - index;
              const handleImageSize = () => {
                switch (item.size) {
                  case '100': return 'w-full';
                  case '50': return 'w-1/2';
                  case '25': return 'w-1/3';
                  default: return '100';
                }
              };

              const content = (
                <div data-name="dropdown-content">
                  {item.image.hasOwnProperty('en') && (
                    <img src={imageModifier(translateObj(item.image, localization.language), '1024x0')} className={`${handleImageSize()} h-auto block mb-4 tablet:mb-8`} alt="" />
                  )}
                  {item.text.hasOwnProperty('en') && (
                    <div className="block leading-wide font-merriw text-black text-sm tablet:text-base laptop:text-18 pb-0 laptop:pb-6 innerTextPadding hasLinksAndBolds">
                      {parse(translateObj(item.text, localization.language))}
                    </div>
                  )}
                </div>
              );

              return (
                <React.Fragment key={index}>
                  <div className="col-span-1 text-36 laptop:text-52 text-red font-semibold text-center tablet:text-right tracking-small pt-4 laptop:pt-2">
                    {item.itemId && item.itemId < 10 ? '0' : ''}
                    {item.itemId}
                  </div>
                  <div className="col-span-5 tablet:col-span-10 laptop:col-span-7">
                    {open === 'all' ? (
                      <DropdownContainer
                        parentComponent="NumberedList"
                        activeDropdown={activeDropdownArr.includes(index.toString())}
                        index={index.toString()}
                        callback={dropdownClick}
                        title={translateObj(item.title, localization.language)}
                        subtitle={translateObj(item.subtitle, localization.language)}
                        label={translateObj(item.label, localization.language)}
                        ContainerclassName="mb-4 tablet:mb-5 laptop:mt-2 desktop:mt-7"
                      >
                        {content}
                      </DropdownContainer>
                    ) : (
                      <DropdownContainer
                        parentComponent="NumberedList"
                        activeDropdown={activeDropdown}
                        index={index.toString()}
                        callback={dropdownClick}
                        title={translateObj(item.title, localization.language)}
                        subtitle={translateObj(item.subtitle, localization.language)}
                        label={translateObj(item.label, localization.language)}
                        ContainerclassName="mb-4 tablet:mb-5 laptop:mt-2 desktop:mt-7"
                      >
                        {content}
                      </DropdownContainer>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default NumberedList;

NumberedList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  open: PropTypes.oneOf(['first', 'last', 'all', 1, false]),
  sort: PropTypes.oneOf(['asc', 'desc']),
  parentComponent: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        en: PropTypes.string,
      }),
      subtitle: PropTypes.shape({
        en: PropTypes.string,
      }),
      image: PropTypes.shape({
        en: PropTypes.string,
      }),
      size: PropTypes.oneOf(['25', '50', '100']),
      text: PropTypes.shape({
        en: PropTypes.string,
      }),
    }),
  ),
};

NumberedList.defaultProps = {
  open: 'last',
  sort: 'asc',
  parentComponent: '',
  elements: [''],
};
