import React from 'react';
import PropTypes from 'prop-types';

const LinkButton = ({ iconUrl, params: { text, link } }) => (
  <a href={link}>
    <button type="button" className="font-americaMonoBold py-4 px-5 rounded-full border text-base cursor-pointer text-white border-white">
      {iconUrl && <img className="pr-4" src={iconUrl} alt={params.text} />}
      {text.en}
    </button>
  </a>
);

LinkButton.propTypes = {
  iconUrl: PropTypes.string,
  params: PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string.isRequired,
      es: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

LinkButton.defaultProps = {
  iconUrl: null,
};

export default LinkButton;
