import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
// import LazyLoad from 'react-lazy-load';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import imageModifier from './helpers/imageModifier';
import translateObj from '../../util/translateObj';
import OnClickButtonRounded from './Buttons/OnClickButtonRounded';
import ImageLoader from './Launcher/ImageLoader';

const subscribedText = {
  en: 'subscribed', es: 'suscrito', ca: 'subscrit', pt: 'subscrito',
};
const notSubscribedText = {
  en: 'subscribe', es: 'suscribir', ca: 'subscriure', pt: 'subscrever',
};
const NewsletterSettingsCard = ({
  match, title, description, image, text, scope, toggleSubscription, isSubscribed, isLoading, active,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const langDescription = translateObj(description, localization.language);
      const langTitle = translateObj(title, localization.language);
      const langText = translateObj(text, localization.language);
      return (
        <div className={`w-full bg-white rounded-lg mb-4 ${!active ? 'opacity-50' : ''}`}>
          <div className="relative">
            <div className="relative h-cardlg overflow-hidden bg-grey sliderComponent flex flex-col justify-center">
              {image && (
              <ImageLoader className="min-w-full" src={imageModifier(image, '666x500')} alt={langTitle} />
              )}
              <div className="absolute pin-b pin-l ml-2 mb-2 z-10 w-48">
                {/* <OnClickButtonRounded isDisabled={!active} isLoading={isLoading} category={`${isSubscribed ? 'grey' : 'transparent'}`} hoverColor="white" border={`${isSubscribed ? 'grey' : 'white'}`} handleClick={() => toggleSubscription(scope)} text={isSubscribed ? subscribedText : notSubscribedText} /> */}
                <OnClickButtonRounded isLoading={isLoading} category={`${isSubscribed ? 'grey' : 'transparent'}`} hoverColor="white" border={`${isSubscribed ? 'grey' : 'white'}`} handleClick={() => toggleSubscription(scope)} text={isSubscribed ? subscribedText : notSubscribedText} />
              </div>
            </div>
            <div className="p-4 text-black flex flex-col justify-between">
              <div className="flex flex-col justify-between">
                <div className="mb-6">
                  <h2 className="font-americaBlack text-xl lg:text-lg mt-1 mb-2 md:h-12 lg:h-16 xl:h-12 sm:h-auto">
                    {langTitle}
                  </h2>
                  <p className="mb-2 leading-normal font-tiemposRegular h-24 md:h-16 xl:h-16 sm:h-auto">
                    {langDescription}
                  </p>
                </div>
                <h3 className={`text-black font-americaMonoRegular uppercase leading-regular text-12 h-8 py-2 ${active ? 'border-t border-grey-nl-lang' : ''}`}>
                  {langText}
                </h3>
              </div>
            </div>
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

NewsletterSettingsCard.propTypes = {
  match: PropTypes.shape().isRequired,
  description: PropTypes.shape(),
  title: PropTypes.shape(),
  text: PropTypes.shape(),
  image: PropTypes.string,
  isLoading: PropTypes.bool,
  scope: PropTypes.string,
  toggleSubscription: PropTypes.func,
  isSubscribed: PropTypes.bool,
  active: PropTypes.bool,
};

NewsletterSettingsCard.defaultProps = {
  isLoading: false,
  active: true,
  isSubscribed: false,
  description: { en: '' },
  title: { en: '' },
  text: { en: '' },
  image: { en: '' },
  scope: '',
  toggleSubscription: () => {},
};

export default withRouter(NewsletterSettingsCard);
