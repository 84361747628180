import React from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';

const TopHeaderMegamenuListsContainer = ({ components }) => {
  const componentsDesktopDividers = () => {
    const items = [...components];
    const divider = { type: 'TopHeaderMegamenuDivider' };
    const itemsWithDividers = (
      items.reduce((result, element, index, array) => {
        result.push(element);
        if (index < array.length - 1) {
          result.push(divider);
        }
        return result;
      }, [])
    );
    return itemsWithDividers;
  };

  return (
    <ComponentIterator components={componentsDesktopDividers()} />
  );
};

TopHeaderMegamenuListsContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  // mainParent: PropTypes.string,
};

export default TopHeaderMegamenuListsContainer;
