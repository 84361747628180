import userSocialLogin from '../../../../api/graphql/mutations/userSocialLogin';

export const responseGoogle = async (payload) => {
  const result = await userSocialLogin(payload.tokenId, 'google').then((response) => {
    if (response && response.token) {
      localStorage.setItem(process.env.AUTH_TOKEN, response.token);
      document.cookie = `${process.env.AUTH_TOKEN}=${response.token}`;
      return response;
    } if (response.error) {
      return response;
    }
    return { isValid: false, needToRegister: true, userPayload: payload };
  }).catch((err) => {
    console.log('Some error happened on fetch of fetchPostDescription:', err);
  });

  return result;
};

export const responseSpotify = async (payload, sourceWeb) => {
  const result = await userSocialLogin(payload.code, 'spotify', sourceWeb).then((response) => {
    if (response && response.token) {
      localStorage.setItem(process.env.AUTH_TOKEN, response.token);
      document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
      return response;
    } if (response.error) {
      return response;
    }
    let userPayload = {};

    if (response && response.userDetails && Object.keys(response.userDetails).length) {
      const [name, surname] = response.userDetails.display_name.split(' ');
      userPayload = {
        profileObj: {
          spotifyToken: response.userDetails.spotifyToken,
          email: response.userDetails.email,
          givenName: name,
          familyName: surname,
          spotifyId: response.userDetails.userID,
        },
      };
    }


    return { isValid: false, needToRegister: true, userPayload };
  }).catch((err) => {
    console.log('Some error happened on fetch of fetchPostDescription:', err);
  });
  return result;
};

export const responseFacebook = async (payload) => {
  const result = await userSocialLogin(payload.accessToken, 'facebook').then((response) => {
    if (response && response.token) {
      localStorage.setItem(process.env.AUTH_TOKEN, response.token);
      document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
      return response;
    } if (response.error) {
      return response;
    }
    const [name, surname] = payload.name.split(' ');
    const userPayload = {
      profileObj: {
        email: payload.email,
        givenName: name,
        familyName: surname,
        facebookId: payload.userID,
      },
    };
    return { isValid: false, needToRegister: true, userPayload };
  }).catch((err) => {
    console.log('Some error happened on fetch of fetchPostDescription:', err);
  });
  return result;
};
