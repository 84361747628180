/* eslint-disable no-nested-ternary */
import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';

import { PropTypes } from 'prop-types';
import ShareMedia from './ShareMedia';
import getArtistSetsByArtistSlugName from '../../api/graphql/queries/getArtistSetsByArtistSlugName';

import Separator from './Separator';
import getPostsBySlugNameQuery from '../../api/graphql/queries/getPostsBySlugNameQuery';
import ComponentIterator from '../../containers/ComponentIterator';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';
import SliderBannerArtistPD from './Sliders/SliderBannerArtistPD';

import { UserProfileContext } from '../../context/userProfileContext';
import userGetFavorites from '../../api/graphql/mutations/userGetFavorites';
import userSetFavorites from '../../api/graphql/mutations/userSetFavorites';

class SingleArtistPage extends Component {
  // static contextType = UserProfileContext;

  _isMounted = false;

  state = {
    topComponents: null,
    bottomComponents: [],
    deviceScreen: null,
    prevArtist: null,
    nextArtist: null,
    isLoading: true,
    bannerIsLoading: true,
    postDescription: {},
    eventSlugName: '',
    updatedComponents: [],
    userFavorites: [],
  };

  responsiveUtility = React.createRef();

  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params },
    } = this.props;

    let artistSlug;
    let eventSlugName = null;
    if (params.post) {
      artistSlug = params.post;
      if (params.section !== 'artist') {
        eventSlugName = params.section;
      }
    } else {
      artistSlug = params.section;
    }
    this.handleResize();
    this.getArtistSets(artistSlug, eventSlugName);
    this.setState({ eventSlugName });

    // get favorites
    const { token } = this.context;
    if (token) {
      userGetFavorites({ token, category: 'Concerts' }).then((res) => {
        if (res) {
          if (res.favorites) {
            this.setState({ userFavorites: res.favorites });
            this.getArtistSets(artistSlug, eventSlugName);
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - userGetFavorites failed to submit!`);
        }
      })
        .catch((err) => { console.log('error getting user favorites: ', err); });
    } else {
      this.getArtistSets(artistSlug, eventSlugName);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props;
    const prevParams = prevProps.match.params;
    let artistSlug;
    let eventSlugName = '';
    if (
      prevParams.section !== params.section
      || prevParams.post !== params.post
    ) {
      if (params.post) {
        artistSlug = params.post;
        eventSlugName = params.section;
      } else {
        artistSlug = params.section;
      }
      if (artistSlug && eventSlugName) {
        this.getArtistSets(artistSlug, eventSlugName);
      }
      this.setState({ eventSlugName });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getArtistSets = (artist, eventSlugName) => {
    const { userFavorites } = this.state;
    const slugnames = [artist];
    const currentArtistSlug = artist;
    let prevArtistSlug = '';
    let nextArtistSlug = '';
    getArtistSetsByArtistSlugName({
      artist,
      eventSlugName,
      maxPast: 100,
      maxFuture: 100,
    })
      .then((res) => {
        if (res) {
          const {
            future, past, prev, next,
          } = res;
          if (!future.length) this.setState({ topComponents: null });
          if (future.length && this._isMounted) {
            // logic to handle favorites in future
            const futureAdj = [...future];
            if (userFavorites.length) {
              const favsArray = userFavorites.map(f => f.split('|'));
              favsArray.forEach((favCombo) => {
                const artistSetIndex = future.findIndex(a => a.artistSetSlugName === favCombo[1]);
                if (artistSetIndex >= 0) {
                  futureAdj[artistSetIndex].artistSetIsFavorite = true;
                }
              });
            }

            this.setState({
              topComponents: [
                {
                  type: 'Separator',
                  borderWidth: 3,
                  marginTop: false,
                },
                {
                  type: 'SidebarNextDates',
                  headLine: {
                    es: 'PRÓXIMAS FECHAS',
                    en: 'Next dates',
                    ca: 'Properes Dates',
                  },
                  data: futureAdj,
                },
              ],
            });
          }

          if (past.length && this._isMounted) {
            // logic to handle favorites in past
            const pastAdj = [...past];
            if (userFavorites.length) {
              const favsArray = userFavorites.map(f => f.split('|'));
              favsArray.forEach((favCombo) => {
                const artistSetIndex = past.findIndex(a => a.artistSetSlugName === favCombo[1]);
                if (artistSetIndex >= 0) {
                  pastAdj[artistSetIndex].artistSetIsFavorite = true;
                }
              });
            }

            this.setState({
              bottomComponents: [
                {
                  type: 'Separator',
                  borderWidth: 2,
                },
                {
                  type: 'SidebarPastDates',
                  data: pastAdj,
                },
              ],
            });
          } else {
            this.setState({
              bottomComponents: [],
            });
          }
          if (prev) {
            if (prev.artistSlugName) {
              slugnames.push(prev.artistSlugName);
              prevArtistSlug = prev.artistSlugName;
            }
          }
          if (next) {
            if (next.artistSlugName) {
              slugnames.push(next.artistSlugName);
              nextArtistSlug = next.artistSlugName;
            }
          }
        }
      })
      .then(() => {
        this.fetchPostDrescriptionsBySlugnames(
          slugnames,
          currentArtistSlug,
          nextArtistSlug,
          prevArtistSlug,
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchPostDrescriptionsBySlugnames = (
    slugnames,
    currentArtistSlug,
    nextArtistSlug,
    prevArtistSlug,
  ) => {
    getPostsBySlugNameQuery(slugnames)
      .then((res) => {
        if (res) {
          const prevArtist = res.find(item => item.slugName === prevArtistSlug);
          const nextArtist = res.find(item => item.slugName === nextArtistSlug);
          const currentArtist = res.find(
            item => item.slugName === currentArtistSlug,
          );
          if (this._isMounted) {
            this.setState({
              postDescription: currentArtist.postDescription,
              nextArtist,
              prevArtist,
              bannerIsLoading: false,
            });
          }
        }
        this.displayDate();
      })
      .catch((err) => {
        console.log(
          'Some error happened on fetch of fetchPostDescription:',
          err,
        );
      });
  };

  handleResize = () => {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.setState({
      deviceScreen,
    });
  };

  displayDate = () => {
    const { components } = this.props;
    const { postDescription } = this.state;
    const updatedComponents = [...components];
    if (updatedComponents.length && updatedComponents[0].type === 'TitleLineH1') {
      updatedComponents[0].margin = 'sm:text-3xl md:text-3xl xl:text-38 lg:text-38 leading-tight';
    }
    if (components.length && components[0].type === 'TitleLineH1' && postDescription.date) {
      updatedComponents.splice(1, 0, {
        type: 'NewsDate',
        color: 'black',
        createdAt: postDescription.date,
        margin: 'mt-1',
      });
    }
    this.setState({ updatedComponents });
  };

  render() {
    const {
      components, width, classesLeft, classesRight,
    } = this.props;
    const {
      topComponents,
      bottomComponents,
      isLoading,
      deviceScreen,
      postDescription,
      prevArtist,
      nextArtist,
      bannerIsLoading,
      eventSlugName,
      updatedComponents,
    } = this.state;
    const spotifyPlayer = components.filter(
      comp => comp.type === 'SpotifyPlayer',
    );
    return (
      <React.Fragment>
        <PSResponsiveUtility
          ref={this.responsiveUtility}
          deviceWidthCallback={this.handleResize}
        />
        <>
          {!bannerIsLoading ? (
            <SliderBannerArtistPD
              eventSlugName={eventSlugName}
              {...postDescription}
              prevArtist={prevArtist}
              nextArtist={nextArtist}
            />
          ) : (
            <div className="h-620 md:h-carouselMD sm:h-carouselSM" />
          )}
          <div className="py-4 w-full flex justify-end w-full">
            <ShareMedia description={postDescription.title} />
          </div>
          <div className="mt-5A w-full flex md:flex-wrap sm:flex-wrap mb-12 sm:mb-0 justify-center">
            <div className={`'pt-8 w-ticket-lg xl:w-ticket-xl sm:w-full md:w-full ${classesLeft} '`}>
              {topComponents && <ComponentIterator components={topComponents} />}

              {deviceScreen !== 'sm' && deviceScreen !== 'md' ? (
                <div className="mt-6">
                  {spotifyPlayer.length ? (
                    <>
                      <Separator borderWidth={2} />
                      <ComponentIterator components={spotifyPlayer} />
                    </>
                  ) : null}
                  {bottomComponents.length ? (
                    <ComponentIterator components={bottomComponents} />
                  ) : null}
                </div>
              ) : null}
            </div>
            <div
              className={`sm:w-full pl-6 sm:pl-0 md:pl-0 sm:mb-4 md:w-full xl:w-1200 lg:w-1028 ${classesRight} ${
                topComponents ? '-mt-3' : 'mt-3'
              }`}
            >
              {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                <Separator borderWidth={2} />
              ) : null}
              <div className="md:my-5 mb-10">
                {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                  spotifyPlayer.length ? (
                    <>
                      <ComponentIterator components={spotifyPlayer} />
                      <Separator borderWidth={2} />
                    </>
                  ) : null
                ) : null}
                <>
                  <ComponentIterator
                    components={updatedComponents.filter(
                      comp => comp.type !== 'SpotifyPlayer',
                    )}
                  />
                </>
              </div>
              {/* Previous concerts change place depending on deviceWidth */}
              {deviceScreen === 'md' || deviceScreen === 'sm' ? (
                <div className="mt-6">
                  {bottomComponents.length ? (
                    <ComponentIterator components={bottomComponents} />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          {/* <ComponentIterator components={lineUpSection} /> */}
        </>
      </React.Fragment>
    );
  }
}

SingleArtistPage.propTypes = {
  basicPage: PropTypes.bool,
  components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shareMedia: PropTypes.bool,
  width: PropTypes.number,
  classesLeft: PropTypes.string,
  classesRight: PropTypes.string,
};

SingleArtistPage.defaultProps = {
  basicPage: false,
  shareMedia: false,
  width: 4,
  classesLeft: '',
  classesRight: '',
};

export default withRouter(SingleArtistPage);

SingleArtistPage.contextType = UserProfileContext;
