import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import imageModifier from './helpers/imageModifier';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import VideoPlayer from './VideoPlayer';
import Separator from './Separator';
import Carousel75 from './Carousel75';
import ComponentIterator from '../../containers/ComponentIterator';
import ParagraphLineSM from './Text/ParagraphLineSM';
import ShareMedia from './ShareMedia';
import Tag from './Buttons/Tag';

import translateObj from '../../util/translateObj';
import getQueryVariable from '../../util/getQueryVariable';

const arrowStyles = {
  transform: 'translateY(-50%)',
  top: '50%',
};
class Modal extends Component {
  state = {
    isLoading: true,
    current: {},
    nextSlug: '',
    prevSlug: '',
    related: [],
  };

  componentDidMount() {
    const { filtered, addHandler, handleClose } = this.props;
    this.mounted = true;
    let { type } = filtered[0];
    if (type === undefined) type = 'Video';
    const query = this.props.location.search;
    const slugName = getQueryVariable(query, type);
    this.body = document.querySelector('body');
    this.body.setAttribute('style', 'overflow: hidden');
    // set clicked item first in related
    let related = [...filtered];
    const current = related.find(item => item.slug === slugName || item.assetSlugName === slugName);
    related = related.filter(item => (item.slug !== current.slug || item.assetSlugName !== current.assetSlugName) && item.type !== 'LauncherItemComunicados');
    if (addHandler) related = addHandler([current, ...related]);
    this.setState(
      {
        related,
      },
      () => {
        if (!filtered.some(item => item.slug === slugName || item.assetSlugName === slugName)) return handleClose();
        this.setCurrentItem(current);
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (this.mounted) {
      const {
        filtered,
        location: { search },
      } = this.props;
      const { type } = filtered[0];
      const slugName = getQueryVariable(search, type);
      const current = filtered.find(item => item.slug === slugName || item.assetSlugName === slugName);
      const prevQuery = prevProps.location.search;
      const prevSlug = getQueryVariable(prevQuery, type);
      if (prevSlug !== slugName) {
        this.setCurrentItem(current);
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setCurrentItem = (current) => {
    if (current !== undefined) {
      const { related } = this.state;
      const { index } = current;
      console.log((index + 1) % related.length, 'index');
      console.log((index - (+1)) % related.length, 'prev');
      if (related.length > 1) {
        // if (current.index === related.length - 1) {
        //   this.setState({
        //     nextSlug: related[0].slug || related[0].assetSlugName,
        //     prevSlug: related[index - 1].slug || related[index - 1].assetSlugName,
        //   });
        // } else if (current.index === 0) {
        //   this.setState({
        //     nextSlug: related[index + 1].slug || related[index + 1].assetSlugName,
        //     prevSlug: related[related.length - 1].slug || related[related.length - 1].assetSlugName,
        //   });
        // } else {
        //   this.setState({
        //     nextSlug: related[index + 1].slug || related[index + 1].assetSlugName,
        //     prevSlug: related[index - 1].slug || related[index - 1].assetSlugName,
        //   });
        // }
        this.setState({
          nextSlug: related[(index + 1) % related.length].assetSlugName,
          prevSlug: related[(index - 1) % related.length].assetSlugName,
        });
      }
      this.setState({
        current,
        isLoading: false,
      });
    }
  };

  renderArrows = () => {
    const { onClick, type } = this.props;
    const { nextSlug, prevSlug } = this.state;
    return (
      <React.Fragment>
        <div className="h-full flex items-center cursor-pointer w-auto z-40 absolute pin-l" onClick={() => onClick(prevSlug, type)}>
          <span className="font-icon icon-arrow-left text-white text-3xl hover:opacity-85 " style={arrowStyles} />
        </div>
        <div className="h-full flex items-center cursor-pointer w-auto z-40 absolute pin-r" onClick={() => onClick(nextSlug, type)}>
          <span className="font-icon icon-arrow-right text-white text-3xl hover:opacity-85 " style={arrowStyles} />
        </div>
      </React.Fragment>
    );
  };

  renderTagsAndDuration = (localization) => {
    let { startDate, endDate } = this.props;
    const { current } = this.state;

    const clockIcon = 'font-icon icon-clock';
    const durationText = {
      en: 'MIN',
      ca: 'MIN',
      es: 'MIN',
    };
    startDate = moment(startDate);
    endDate = moment(endDate);
    const isLive = moment().isBetween(startDate, endDate);
    const duration = moment.duration(endDate.diff(startDate)).asMinutes();

    return (
      <div className="flex justify-between py-4">
        {current.tags_array_multilang ? (
          <div className="flex flex-wrap">
            {current.tags_array_multilang.map(tag => (
              <Tag key={tag.en} category="white" text={`#${translateObj(tag, localization.language)}`} textColor="radio" borderColor="white" />
            ))}
          </div>
        ) : null}
        <div className="flex items-center text-sm">
          <span className={`w-5 mr-3 ${clockIcon} `} />
          <span className="font-americaMonoRegular uppercase text-white">{`${current.duration} ${translateObj(durationText, localization.language)}`}</span>
        </div>
      </div>
    );
  };

  renderContent = (localization) => {
    const {
      handleClose, type, match, background,
    } = this.props;
    const {
      current,
      related,
      current: { url, title, fileReadableName },
    } = this.state;
    const downloadText = {
      en: 'Download',
      es: 'Descargar',
      ca: 'descarregar',
    };
    const relatedText = {
      en: 'Related',
      es: 'Relacionados',
      ca: 'Relacionados',
    };

    return (
      <div className="overflow-y-auto h-screen pb-4">
        <div className="fixed top-0 right-0 color-white font-icon icon-close cursor-pointer" onClick={handleClose} style={{ right: '20px', top: '20px', zIndex: 101 }} />
        <div className={`relative px-2 sm:pr-0 bg-${background} pt-8`}>
          <div className="m-auto m-auto relative">
            {current.channel && this.renderTagsAndDuration(localization)}
            {related.length > 1 ? this.renderArrows() : null}
            {type === 'Video' ? (
              <div className="sm:w-full xl:w-1/2 lg:w-1/2 mx-auto text-center">
                <VideoPlayer {...current} match={match} />
              </div>
            ) : (
              <React.Fragment>
                <div className="sm:w-full xl:w-1/2 lg:w-1/2 mx-auto text-center">
                  <img src={imageModifier(url, '600x600', true)} className="colorImg block mx-auto" alt="" />
                </div>
                <div className="flex sm:block justify-between items-center mt-4 xl:w-2/3 mx-auto">
                  {fileReadableName && Object.keys(fileReadableName).length > 0 && <p className="text-xs font-tiemposRegular my-0 py-0 sm:pt-3 sm:mb-2">{translateObj(fileReadableName, localization.language)}</p>}
                  <div className="flex items-center">
                    <a href={url} download className="text-white text-xxs">
                      <ParagraphLineSM size="text-xs" classes="cursor-pointer font-bold mt-0 font-americaMonoRegular text-xs uppercase" iconClass="font-icon icon-download1" text={downloadText} />
                    </a>
                    <span className="px-2">|</span>
                    <div className="z-50">
                      <ShareMedia black />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="w-full px-2 sm:pr-0">
          <div className="my-4">{!current.channel ? <Separator color="white" /> : null}</div>
          {related.length > 1 ? (
            <>
              <ParagraphLineSM size="text-xs" classes="uppercase font-bold my-0" text={relatedText} />
              <div className="hidden sm:block my-2 text-white">
                <Carousel75 components={related} />
              </div>
              <div className="bg-black text-white">
                <div className="flex flex-wrap -mx-2 sm:hidden">
                  <ComponentIterator components={related} />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { background, match } = this.props;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div id="modal" className={`w-full text-white font-americaMonoRegular fixed h-screen pin-l pin-t bg-${background}`} style={{ zIndex: 1001 }}>
              {!isLoading ? this.renderContent(localization) : null}
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

Modal.propTypes = {
  background: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  filtered: PropTypes.arrayOf(PropTypes.shape({})),
  slugname: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  addHandler: PropTypes.func,
};

Modal.defaultProps = {
  background: 'black',
  filtered: [],
  slugname: '/',
  color: 'black',
  startDate: null,
  endDate: null,
  type: 'no-type',
  addHandler: null,
};

export default withRouter(Modal);
