import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import Cookies from 'js-cookie';
import { Link, withRouter } from 'react-router-dom';
import translateObj from '../../../../../util/translateObj';
import InfoPopover from '../../../Form/InfoPopover';
import LoadIcon from '../../../LoadIcon';
import StandardFormCheckboxConditions from '../../../Form/StandardFormCheckboxConditions';

import signupUserForm from '../../../../../api/graphql/mutations/signupUserForm';
import userWebLogin from '../../../../../api/graphql/mutations/userWebLogin';
import PSResponsiveUtility from '../../../LineUp/PSResponsiveUtility';
import { useUser } from '../../../../../context/userProfileContext';

const defaultConditionsText = {
  acceptText: {
    es: 'Acepto las ',
    en: 'Acepto las ',
  },
  conditionsText: {
    es: 'CONDICIONES DE ACCESO',
    en: 'CONDICIONES DE ACCESO',
  },
  conditionsLink: {
    es: '/condiciones-de-registro',
    en: '/condiciones-de-registro',
  },
  dataTreatmentText: {
    es: ' y el tratamiento de datos y la elaboración de perfiles según la ',
    en: ' y el tratamiento de datos y la elaboración de perfiles según la ',
  },
  privacyPolicyText: {
    es: 'POLÍTICA DE PRIVACIDAD',
    en: 'POLÍTICA DE PRIVACIDAD',
  },
  privacyPolicyUrl: {
    es: '/politica-de-privacidad',
    en: '/politica-de-privacidad',
  },
};

const fieldErrorText = {
  es: 'Email o contraseña incorrectos',
  en: 'Email o contraseña incorrectos',
};

const SectionPasswordOnDemand = ({
  language,
  handleOnDemandScreenStatus,
  handleModalNotLoggedStatus,
  handleRegisteringStatus,
  translations: { notLoggedModal: translations = {} } = {},
  sourceWeb,
  parentComponent,
}) => {
  const emailRef = useRef('');
  const passwordRef = useRef('');
  const recaptchaRef = useRef();
  const [screen, setScreen] = useState();
  const responsiveUtility = useRef();

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setScreen(deviceScreen);
  };

  const userLoginDetailsInitial = {
    name: '', password: '',
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [isReCaptchaDone, setIsReCaptchaDone] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const [loginDetails, setLoginDetails] = useState(userLoginDetailsInitial);
  const [showPassword, setShowPassword] = useState(false);
  const user = useUser();

  useEffect(() => {
    const loginNames = Object.keys(loginDetails);
    if (loginNames.length) {
      const { name = '', password = '' } = loginDetails;
      // if (name.length > 4 && password.length > 6 && name.includes('@') && name.includes('.')) {
      //   setIsFilled(true);
      // }
      if (name.length > 0 && password.length > 0) {
        setIsFilled(true);
        if (name.includes('@') && name.includes('.')) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      } else {
        setIsFilled(false);
      }
    }
  }, [loginDetails]);

  const handleChange = (e) => {
    if (fieldError === true) {
      setFieldError(false);
    }
    const { value } = e.target;
    let cleanValue = '';
    if (e.target.name === 'name') {
      cleanValue = value.trim();
    } else {
      cleanValue = value;
    }
    const userObj = {
      ...loginDetails,
      [e.target.name]: cleanValue,
    };
    setLoginDetails(userObj);
  };

  const navigateToLastPage = () => {
    const previousUrl = Cookies.get('rdl_lastvisited');
    if (previousUrl) {
      window.location = previousUrl;
    } else {
      const { history } = this.props;
      history.push('/user-profile');
    }
  };

  const handleSubmit = (e, screenStatus, emailStatus) => {
    e.preventDefault();
    setLoginStatus(null);
    setIsReCaptchaDone(false);
    const { name, password } = loginDetails;
    name.trim();

    if (emailStatus === false) {
      setFieldError(true);
    }

    if (screenStatus === 'notFound' && emailStatus === true) {
      setIsLoading(true);
      setIsFilled(false);

      // * create account with email and password, set up a random nickname
      const nameToLowerCase = name.toLocaleLowerCase();
      const userNameFromEmail = nameToLowerCase.split('@')[0];
      const userNameFromEmailNoNumbers = userNameFromEmail.replace('+', '').replace(/[0-9]+/g, '').replace('.', '').replace('-', '')
        .substring(0, 8);
      const userName = userNameFromEmailNoNumbers.length > 4 ? userNameFromEmailNoNumbers : `${userNameFromEmailNoNumbers}PrimaveraS`;
      const formDataUpdated = {
        email: nameToLowerCase, password, name: userName, newsletter: true,
      };
      signupUserForm({ ...formDataUpdated }).then((response) => {
        if (response) {
          if (response.isValid) {
            // this.onReset();
            setIsFilled(true);
            user.setNewAuthToken(response.token);
            setIsLoading(false);
            // !!! need to check this no need to handle ondemandscreenstatus as userEmail in context is being updated already
            // handleOnDemandScreenStatus('logged');

            // Redirect to payment page
            // history.push(`/${language}`);
            navigateToLastPage();
          } else if (response.error) {
            formData.email = '';
            console.log('server-response error:', 'response.error');
            setIsLoading(false);
          } else {
            console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
        }
      });
    }

    const loginObj = {};
    loginObj.email = name;
    loginObj.password = password;
    userWebLogin({ ...loginObj }).then((res) => {
      if (res) {
        if (res.isValid) {
          setLoginStatus('logged');
          emailRef.current.value = '';
          passwordRef.current.value = '';

          user.setNewAuthToken(res.token);

          navigateToLastPage();
        } else if (res.token) {
          let loginStatusQuery = 'notLogged';
          let showErrorMessage = false;
          switch (res.token) {
            case 'notActive':
              loginStatusQuery = 'notActive';
              showErrorMessage = false;
              break;

            case 'notFound':
              loginStatusQuery = 'notFound';
              showErrorMessage = false;
              break;

            case 'wrongPassword':
              loginStatusQuery = 'wrongPassword';
              showErrorMessage = true;
              break;

            case 'notFoundOrWrongPassword':
              loginStatusQuery = 'notFoundOrWrongPassword';
              showErrorMessage = true;
              break;

            case 'tooManyPasswordAttempts':
              loginStatusQuery = 'tooManyPasswordAttempts';
              showErrorMessage = true;
              break;

            case 'createdWithSocial':
              loginStatusQuery = 'createdWithSocial';
              showErrorMessage = false;
              break;

            default:
              break;
          }
          setLoginStatus(loginStatusQuery);
          setFieldError(showErrorMessage);
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - loginForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - loginForm failed to submit!`);
      }
    });
  };

  const handleCheckBoxValue = (isChecked) => {
    if (isChecked) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  };

  useEffect(() => {
    if (loginStatus === 'notFound') {
      setIsFilled(false);
    }
    if (loginStatus === 'logged') {
      window.location.reload();
    }
  }, [loginStatus]);

  const renderNotAbleToAccessOptions = (passwordLoginStatus) => {
    if (passwordLoginStatus === 'notFound') {
      return (

        <StandardFormCheckboxConditions
          getCheckBoxValue={handleCheckBoxValue}
          required
          name="conditions"
          acceptText={translateObj(defaultConditionsText.acceptText, language)}
          conditionsText={translateObj(defaultConditionsText.conditionsText, language)}
          conditionsLink={translateObj(defaultConditionsText.conditionsLink, language)}
          dataTreatmentText={translateObj(defaultConditionsText.dataTreatmentText, language)}
          privacyPolicyText={translateObj(defaultConditionsText.privacyPolicyText, language)}
          privacyPolicyUrl={translateObj(defaultConditionsText.privacyPolicyUrl, language)}
          validationError=""
        />
      );
    } if (passwordLoginStatus === 'wrongPassword') {
      const getRecoverPasswordUrlBasedOnSourceWeb = (source) => {
        switch (source) {
          case 'pslife':
            return `https://www.onps.life/${language || 'en'}/recover-password-pslife`;
          case 'pro-online':
            return `https://www.onps.pro/${language || 'en'}/recover-password-pro-online`;
          case '':
            return '/recover-password';
          default:
            return '/recover-password';
        }
      };
      const toSlug = getRecoverPasswordUrlBasedOnSourceWeb(sourceWeb);
      return (
        <Link to={toSlug} className="mt-1">
          <span className="hover:underline text-black font-bold font-stratos text-14 font-medium cursor-pointer">
            {translateObj(translations.recoverPassword, language)}
          </span>
        </Link>
      );
    }
    if (passwordLoginStatus === 'tooManyPasswordAttempts') {
      const getRecoverPasswordUrlBasedOnSourceWeb = (source) => {
        switch (source) {
          case 'pslife':
            return `https://www.onps.life/${language || 'en'}/recover-password-pslife`;
          case 'pro-online':
            return `https://www.onps.pro/${language || 'en'}/recover-password-pro-online`;
          case '':
            return '/recover-password';
          default:
            return '/recover-password';
        }
      };
      const toSlug = getRecoverPasswordUrlBasedOnSourceWeb(sourceWeb);
      return (
        <Link to={toSlug} className="mt-1">
          <span className="hover:underline text-black font-bold font-stratos text-14 font-medium cursor-pointer">
            {translateObj(translations.recoverPassword, language)}
          </span>
        </Link>
      );
    }


    if (passwordLoginStatus === 'createdWithSocial') {
      return (
        <div className="font-normal font-stratos text-14 cursor-default text-center text-red pt-4">
          {translateObj(translations.createdWithSocial, language)}
        </div>
      );
    }

    if (passwordLoginStatus === 'notFoundOrWrongPassword') {
      return (
        <div className="font-normal font-stratos text-14 cursor-default text-center text-red pt-4">
          {translateObj(translations.notFoundOrWrongPassword, language)}
        </div>
      );
    }

    return null;
  };

  const handleButtonValue = status => (
    <div className="w-full flex items-center justify-end">
      <input
        disabled={!isFilled}
        type="submit"
        value={translateObj(translations.createAccount, language)}
        className={`appearance-none mt-6 border-6 ${isFilled ? 'bg-white text-red border-6 border-red cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'} text-14 font-stratos font-bold w-1/2 text-center`}
        style={{
          alignSelf: 'flex-end',
          minHeight: '2.5rem',
        }}
      />
    </div>
  );

  const loginForm = (status) => {
    if (status === 'notFound') {
      if (isLoading) {
        return (
          <div className="w-full flex items-center justify-end">
            <div
              className={`mt-6 border-6 ${isFilled ? 'bg-white text-red border-6 border-red cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'} text-14 font-stratos font-bold w-1/2 text-center`}
              style={{
                alignSelf: 'flex-end',
                minHeight: '2.5rem',
              }}
            >
              <LoadIcon height={15} width={15} color="black" type="ThreeDots" />

            </div>
          </div>
        );
      }
      return (
        <div className="w-full flex items-center justify-end">
          <input
            disabled={!isFilled}
            type="submit"
            value={translateObj(translations.createAccount, language)}
            className={`appearance-none mt-6 border-6 ${isFilled ? 'bg-white text-red border-6 border-red cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'} text-14 font-stratos font-bold w-1/2 text-center`}
            style={{
              alignSelf: 'flex-end',
              minHeight: '2.5rem',
            }}
          />
        </div>
      );
    }
    if (status === 'wrongPassword') {
      return (
        <div className="w-full flex items-center justify-end">
          <input
            disabled={!isFilled}
            type="submit"
            value={translateObj(translations.accessAccount, language)}
            className={`appearance-none mt-6 border-6 ${isFilled ? 'bg-white text-red border-6 border-red cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'} text-14 font-stratos font-bold w-1/2 text-center`}
            style={{
              alignSelf: 'flex-end',
              minHeight: '2.5rem',
            }}
          />
        </div>
      );
    }
    if (status === 'tooManyPasswordAttempts') {
      const enableButtonIfCaptchaIsMissingOrNotFilled = isReCaptchaDone && isFilled;
      return (
        <div className="w-full flex flex-col items-center justify-end">
          <div>
            <ReCAPTCHA
              ref={recaptchaRef}
              onChange={() => setIsReCaptchaDone(true)}
              sitekey="6LeVsbsUAAAAAI8Crd2wQ8eNINyoLzAT0uM01KKe"
            />
          </div>
          <input
            disabled={!enableButtonIfCaptchaIsMissingOrNotFilled}
            type="submit"
            value={translateObj(translations.accessAccount, language)}
            className={`appearance-none ${screen === 'mobile' ? 'mt-2' : 'mt-4'} border-6 ${isFilled ? 'bg-white text-red border-6 border-red cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'} text-14 font-stratos font-bold w-1/2 text-center`}
            style={{
              alignSelf: 'flex-end',
              minHeight: '2.5rem',
            }}
          />
        </div>
      );
    }
    return (
      <div className="w-full flex items-center justify-end">
        <input
          disabled={!isFilled}
          type="submit"
          value={translateObj(translations.accessAccount, language)}
          className={`appearance-none mt-6 border-6 ${isFilled ? 'bg-white text-red border-6 border-red cursor-pointer ' : 'bg-white text-gray200 border-grey cursor-not-allowed'} text-14 font-stratos font-bold w-1/2 text-center`}
          style={{
            alignSelf: 'flex-end',
            minHeight: '2.5rem',
          }}
        />
      </div>
    );
  };

  return (
    <>
      <form
        className={`flex flex-col w-full h-full ${parentComponent === 'Paywall' ? 'px-2' : 'px-8'} pt-4 relative`}
        onSubmit={e => handleSubmit(e, loginStatus, isValidEmail)}
      >
        <label className={`flex flex-col w-full ${fieldError ? 'mb-3' : 'mb-9'}`}>
          <input
            ref={emailRef}
            type="text"
            name="name"
            onChange={handleChange}
            placeholder={translateObj(translations.userTitle, language)}
            className={`appearance-none p-4 py-3.5 bg-inherit text-15 leading-22 bg-white w-full font-stratos ${fieldError ? 'border-2 border-red text-red placeholder-red font-semibold' : 'border-1 border-black text-black placeholder-gray font-normal'}`}
          />
          {fieldError && <div className="font-stratos text-14 font-normal text-red mt-2">{translateObj(fieldErrorText, language)}</div>}
        </label>
        <label className={`flex flex-col w-full relative ${fieldError ? 'mb-3' : 'mb-9'}`}>
          <div className="flex">
            <div className="ml-4"><InfoPopover text={translateObj(translations.passwordInfo, language)} /></div>
          </div>
          <input
            ref={passwordRef}
            type={showPassword ? 'text' : 'password'}
            name="password"
            onChange={handleChange}
            placeholder={translateObj(translations.passwordTitle, language)}
            className={`appearance-none p-4 py-3.5 leading-22 bg-inherit text-15 w-full bg-white font-stratos ${fieldError ? 'border-2 border-red text-red placeholder-red font-semibold' : 'border-1 border-black text-black placeholder-gray font-normal'}`}
          />
          {fieldError && <div className="font-stratos text-14 font-normal text-red mt-2">{translateObj(fieldErrorText, language)}</div>}
        </label>

        <Link to="/recover-password" className="cursor-pointer">
          <div className="font-stratos text-14 font-medium text-left text-black">
            {translateObj(translations.forgotPassword, language)}
          </div>
        </Link>

        {renderNotAbleToAccessOptions(loginStatus)}
        <div className={`flex-1 w-full flex items-end justify-end ${screen === 'mobile' ? '' : 'pb-8'}`}>
          {handleButtonValue(loginStatus)}
        </div>
      </form>
    </>
  );
};

SectionPasswordOnDemand.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  handleModalNotLoggedStatus: PropTypes.func.isRequired,
  handleRegisteringStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  sourceWeb: PropTypes.string,
};

SectionPasswordOnDemand.defaultProps = {
  language: 'en',
  sourceWeb: '',
};

export default SectionPasswordOnDemand;
