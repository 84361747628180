import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const SimpleTag = ({
  text, margin, textColor, borderColor, category, match, slider, noLink,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const linkToOwnCategory = category ? category.replace(' ', '-') : 'home';

      const Content = () => (
        <div
          className={`${slider ? 'mt-4 tablet:mt-18px laptop::mt-28px' : ''} ${margin || 'mb-1'}
            font-stratos font-semibold inline-block cursor-pointer leading-none hover:bg-white trans text-10 tablet:text-11 laptop:text-base desktop:text-base text-white hover:text-black px-ticket-gap py-1 laptop:px-4 laptop:py-2 mr-1 bg-red
          `}
        >
          <span>{translateObj(text, localization.language)}</span>
        </div>
      );

      return (
        !noLink ? (
          <Link className={`${slider ? 'block' : ''}`} to={`/${linkToOwnCategory}`}>
            <Content />
          </Link>
        ) : (
          <Content />
        )
      );
    }}
  </RoutesContext.Consumer>
);

SimpleTag.propTypes = {
  text: PropTypes.shape({}),
  match: PropTypes.shape({}).isRequired,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  category: PropTypes.string,
  slider: PropTypes.bool,
  noLink: PropTypes.bool,
};

SimpleTag.defaultProps = {
  margin: null,
  textColor: 'white',
  category: 'white',
  text: {},
  borderColor: '',
  slider: false,
  noLink: false,
};

export default withRouter(SimpleTag);
