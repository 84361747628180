import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Formsy from 'formsy-react';

import Cookies from 'js-cookie';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import getCountryNamesByCode from '../../util/getCountryNamesByCode';

import signupUserForm from '../../api/graphql/mutations/signupUserForm';

import Filter from './Collapsible/Filter';
import FormInput from './Form/FormInput';
import FormCheckbox from './Form/FormCheckbox';
import FormSelectStyle from './Form/FormSelectStyle';
import FormSelectAutoComplete from './Form/FormSelectAutoComplete';
import translateObj from '../../util/translateObj';

const titleByDefault = {
  es: 'REGISTRATE',
  en: 'REGISTER',
  ca: 'REGISTRAT',
};
const fieldsByDefault = {
  name: {
    es: 'Nombre',
    en: 'First name',
    ca: 'Nom',
  },
  lastname: {
    es: 'Apellidos',
    en: 'Last name',
    ca: 'Cognoms',
  },
  email: {
    es: 'Correo electrónico',
    en: 'Email',
    ca: 'Correu electrónic',
  },
  phone: {
    es: 'Teléfono',
    en: 'Phone',
    ca: 'Telefon',
  },
  choose: {
    es: 'Eligir',
    en: 'Choose',
    ca: 'Escullir',
  },
  country: {
    es: 'PAIS*',
    en: 'COUNTRY*',
    ca: 'PAIS*',
  },
  city: {
    es: 'AREA DE CONTACTO*',
    en: 'CITY*',
    ca: 'AREA DE CONTACTO*',
  },
  postalcode: {
    es: 'CÓDIGO POSTAL',
    en: 'POSTAL CODE',
    ca: 'CODI POSTAL',
  },
  password: {
    es: 'CREAR CONTRASEÑA',
    en: 'CREATE PASSWORD',
    ca: 'CREA CONTRASENYA',
  },
  repeatPassword: {
    es: 'REPETIR CONTRASEÑA',
    en: 'RE-ENTER PASSWORD',
    ca: 'REPETIR CONTRASENYA',
  },
};

const validationByDefault = {
  inValidName: {
    es: 'This is not a valid name',
    en: 'This is not a valid name',
    ca: 'This is not a valid name',
  },
  longName: {
    es: 'This name is too long',
    en: 'This name is too long',
    ca: 'This name is too long',
  },
  inValidEmail: {
    es: 'This is not a valid email',
    en: 'This is not a valid email',
    ca: 'This is not a valid email',
  },
  longEmail: {
    es: 'This email is too long',
    en: 'This email is too long',
    ca: 'This email is too long',
  },
  inValidPhone: {
    es: 'This is not a valid phone number',
    en: 'This is not a valid phone number',
    ca: 'This is not a valid phone number',
  },
  longPhone: {
    es: 'This phone number is too long',
    en: 'This phone number is too long',
    ca: 'This phone number is too long',
  },
  invalidpostalcode: {
    es: 'This is not a valid postal code',
    en: 'This is not a valid postal code',
    ca: 'This is not a valid postal code',
  },
  shortPassword: {
    es: 'This password is too short',
    en: 'This password is too short',
    ca: 'This password is too short',
  },
  repeatPassword: {
    es: 'Passwords do not match',
    en: 'Passwords do not match',
    ca: 'Passwords do not match',
  },
};
const textByDefault = {
  es: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Àlaba, 140, 2º 4ª, 08018 de Barcelona.',
  en: 'We inform you que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Àlaba, 140, 2º 4ª, 08018 de Barcelona.',
  ca: 'Te informamos que los datos personales que facilites pasarán a formar parte de un fichero responsabilidad de PRIMAVERA SOUND S.L. para gestionar tu petición. Puedes ejercer los derechos de acceso, rectificación, cancelación u oposición al tratamiento de tus datos en el e-mail señalado lopd@primaverasound.com o en el domicilio sito en C/ Àlaba, 140, 2º 4ª, 08018 de Barcelona.',
};
const conditionsByDefault = {
  es: 'He leído y acepto las condiciones y la Ley de tratamiento de datos.',
  en: 'I read y acepto las condiciones y la Ley de tratamiento de datos.',
  ca: 'He leído y acepto las condiciones y la Ley de tratamiento de datos.',
};
const submitButtonTextByDefault = {
  es: 'ENVIAR',
  en: 'SUBMIT',
  ca: 'ENVIAR',
};
const resetButtonTextByDefault = {
  es: 'BORRAR',
  en: 'RESET',
  ca: 'BORRAR',
};
const submitMessageByDefault = {
  es: 'GRACIAS, SE HA CREADO TU CUENTA',
  en: 'THANKS, YOUR ACCOUNT HAS BEEN CREATED',
  ca: "GRÀCIES, S'HA CREAT EL TEU COMPTE",
};
class LoginForm extends Component {
  state = {
    canSubmit: false,
    didSubmit: false,
    loading: false,
    events: [],
  };

  componentDidMount = () => {
    const body = document.getElementById('bodyChildContainer');
    body.childNodes[0].setAttribute('style', 'border-top: none');
  }

  onHandleCollapsibleMenuSelect = ({ byEvent }) => {
    this.setState({ events: byEvent });
  }

  submitForm = (formData, language) => {
    // const { events } = this.state;
    const { loadTokenForLS, history } = this.props;
    this.setState({
      canSubmit: false,
      loading: true,
    });

    // const formDataAdj = {};
    // formDataAdj.email = formData.email;
    // formDataAdj.password = formData.password;
    // formDataAdj.name = formData.name;
    // formDataAdj.lastname = formData.lastname;

    const formDataUpdated = { ...formData, language };

    signupUserForm({ ...formDataUpdated }).then((response) => {
      if (response) {
        if (response.isValid) {
          // this.onReset();
          this.setState({
            didSubmit: true,
            loading: false,
          }, () => {
            localStorage.setItem(process.env.AUTH_TOKEN, response.token);
            document.cookie = `${process.env.AUTH_TOKEN}=${response.token}; Secure; HttpOnly`;
            loadTokenForLS();
            // TODO: history redirect to previous page
            history.push('/user-profile');
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });

    // let data;

    // if (events && events.length > 0) {
    //   data = { ...formData, events: events.join(', ') };
    // } else {
    //   data = { ...formData, events: 'General' };
    // }

    // postLoginForm({ ...data }).then((response) => {
    //   if (response) {
    //     if (response.isValid) {
    //       this.onReset();
    //       this.setState({
    //         didSubmit: true,
    //         loading: false,
    //       });
    //     } else {
    //       console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
    //     }
    //   } else {
    //     console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
    //   }
    // });
  };

  onReset = () => {
    this.form.reset();

    this.name.setValue('');
    this.lastname.setValue('');
    this.email.setValue('');
    this.phone.setValue('');
    this.oauthId.setValue('');
    this.postalCode.setValue('');
    // this.postalCode.setValue('');
    // this.message.setValue('');
    this.conditions.setValue(false);
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  getCurrentPrefilledValues = (preFilledValues) => {
    const currentValues = {};

    if (preFilledValues) {
      const {
        isSocialLogin, givenName, familyName, email, googleId,
      } = preFilledValues;

      switch (isSocialLogin) {
        case 'google':
          currentValues.name = givenName;
          currentValues.lastname = familyName;
          currentValues.email = email;
          currentValues.isSocialLogin = isSocialLogin;
          currentValues.oauthId = googleId;
          break;
        default:
          break;
      }
    }

    return currentValues;
  }

  _didSubmitScreen = (language) => {
    const { submitMessage } = this.props;
    return (
      <div className="flex w-full py-12 justify-center font-americaMonoRegular">
        <h3>{translateObj(submitMessage, language)}</h3>
      </div>
    );
  }

  _formScreen = (language) => {
    const {
      menu, title, fields, preFilledValues, validation, text,
      conditions, submitButtonText, resetButtonText,
    } = this.props;
    const currentValues = this.getCurrentPrefilledValues(preFilledValues);
    const {
      canSubmit,
    } = this.state;

    return (
      <Fragment>
        <div className={`w-full ${menu && 'py-12'} font-americaMonoRegular `}>
          <div className="my-8">{translateObj(title, language)}</div>
          <Formsy
            ref={(c) => { this.form = c; return this.form; }}
            onValidSubmit={e => this.submitForm(e, language)}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            className="flex sm:flex-col md:flex-col flex-wrap -mx-3 w-full"
          >
            <FormInput
              ref={(c) => { this.name = c; return this.name; }}
              name="name"
              title={translateObj(fields.name, language)}
              validations={{ isSpecialWords: true, maxLength: 50 }}
              validationErrors={{ isSpecialWords: translateObj(validation.inValidName, language), maxLength: translateObj(validation.longName, language) }}
              value={currentValues.name}
              required
            />

            <FormInput
              ref={(c) => { this.lastname = c; return this.lastname; }}
              name="lastname"
              title={translateObj(fields.lastname, language)}
              validations={{ isSpecialWords: true, maxLength: 50 }}
              validationErrors={{ isSpecialWords: translateObj(validation.inValidName, language), maxLength: translateObj(validation.longName, language) }}
              value={currentValues.lastname}
              required
            />

            <FormInput
              ref={(c) => { this.email = c; return this.email; }}
              name="email"
              title={translateObj(fields.email, language)}
              validations={{ isEmail: true, maxLength: 100 }}
              validationErrors={{ isEmail: translateObj(validation.inValidEmail, language), maxLength: translateObj(validation.longEmail, language) }}
              value={currentValues.email}
              required
            />
            {/* <FormInput
                        ref={(c) => { this.phone = c; return this.phone; }}
                        name="phone" title={translateObj(fields.phone, language)}
                        value="" validations={{ isNumeric: true, maxLength: 25 }}
                        validationErrors={{ isNumeric: translateObj(validation.inValidPhone, language), maxLength: translateObj(validation.longPhone, language) }}
                      /> */}

            {/* <FormSelectAutoComplete
                        name="country"
                        title={translateObj(fields.country, language)}
                        validations="isSpecialWords"
                        validationError=""
                        options={getCountryNamesByCode(language)}
                        message="No match"
                        required
                      /> */}

            {/* <FormSelectStyle name="country" title={fields.country[language]} validations="isSpecialWords" validationError="" options={countryOptions} required /> */}

            {/* {fields.city
                        && <FormSelectStyle name="ciutat" title={translateObj(fields.city, language)} validations="isSpecialWords" options={cityOptions} required />}
                       */}

            <FormInput
              ref={(c) => { this.postalCode = c; return this.postalCode; }}
              name="postalCode"
              title={translateObj(fields.postalcode, language)}
              validations={{ maxLength: 10 }}
              validationError={translateObj(validation.invalidpostalcode, language)}
            />

            {!currentValues.isSocialLogin
              ? (
                <div className="w-full">
                  <FormInput
                    ref={(c) => { this.password = c; return this.password; }}
                    name="password"
                    title={translateObj(fields.password, language)}
                    validations={{ minLength: 8 }}
                    validationError={validation.shortPassword[language]}
                    type="password"
                    required
                  />
                  <FormInput
                    ref={(c) => { this.repeatPassword = c; return this.repeatPassword; }}
                    name="repeatPassword"
                    title={translateObj(fields.repeatPassword, language)}
                    validations="equalsField:password"
                    validationError={translateObj(validation.repeatPassword, language)}
                    type="password"
                    required
                  />
                </div>
              ) : (
                <FormInput
                  ref={(c) => { this.oauthId = c; return this.oauthId; }}
                  name="oauthId"
                  title="Social Id Code"
                  value={currentValues.oauthId}
                  hidden
                />
              )
            }

            <div className="flex justify-end mt-8 sm:mt-0">
              <div className="w-1/2 sm:w-full md:w-full sm:px-3 md:pl-3 font-tiemposRegular text-12">
                <p style={{ marginTop: 0 }}>{translateObj(text, language)}</p>
                <div>
                  <FormCheckbox ref={(c) => { this.conditions = c; return this.conditions; }} name="conditions" title={translateObj(conditions, language)} validations="isTrue" validationError="" />
                </div>
                <div className="hidden sm:block sm:w-full mt-12">
                  <button type="submit" disabled={!canSubmit} className={`${canSubmit ? 'bg-black' : 'bg-gray200'} h-12 text-white rounded-full px-3 w-full py-2 font-americaMonoBold`}>{translateObj(submitButtonText, language)}</button>
                </div>
              </div>
              <div className="flex flex-col w-1/4 sm:hidden">
                <button type="submit" disabled={!canSubmit} className={`${canSubmit ? 'bg-black' : 'bg-gray200'} h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold`}>{translateObj(submitButtonText, language)}</button>
              </div>
            </div>
            <div className="m-4 flex items-center ml-auto" />
          </Formsy>
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      match,
    } = this.props;

    const {
      didSubmit, loading,
    } = this.state;

    return (
      <RoutesContext.Consumer>
        {
          ({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            if (loading) return (<div> IS SUBMITTING NOW</div>);
            return (
              <Fragment>
                {
                  didSubmit
                    ? this._didSubmitScreen(localization.language)
                    : this._formScreen(localization.language)
                }
              </Fragment>
            );
          }
        }
      </RoutesContext.Consumer>
    );
  }
}

LoginForm.propTypes = {
  match: PropTypes.shape(),
  menu: PropTypes.shape(),
  title: PropTypes.shape(),
  fields: PropTypes.shape(),
  preFilledValues: PropTypes.shape(),
  validation: PropTypes.shape(),
  text: PropTypes.shape(),
  conditions: PropTypes.shape(),
  submitButtonText: PropTypes.shape(),
  resetButtonText: PropTypes.shape(),
  submitMessage: PropTypes.shape(),
};

LoginForm.defaultProps = {
  match: {},
  menu: {},
  title: titleByDefault,
  fields: fieldsByDefault,
  preFilledValues: {},
  validation: validationByDefault,
  text: textByDefault,
  conditions: conditionsByDefault,
  submitButtonText: submitButtonTextByDefault,
  resetButtonText: resetButtonTextByDefault,
  submitMessage: submitMessageByDefault,
};

export default withRouter(LoginForm);
