/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import RoutesContext from '../../../../../context/routesContext';
import getLocalization from '../../../../../util/localization';
import translateObj from '../../../../../util/translateObj';
import SimpleButton from '../../../Buttons/SimpleButton';
import SimpleRadioButton from '../../../Buttons/SimpleRadioButton';


const StripeCardsFormsy = ({
  match,
  cards,
  favCard,
  addCardCallback,
  removeCardCallback,
  selectPreferredCallback,
}) => {
  const [selected, setSelected] = useState('0');

  const translatedTexts = {
    favouriteCards: {
      en: 'Mis tarjetas (marca la favorita):',
      es: 'Mis tarjetas (marca la favorita):',
    },
    cardEnded: {
      en: 'Tarjeta acabada en',
      es: 'Tarjeta acabada en',
    },
    expires: {
      en: 'Caduca el',
      es: 'Caduca el',
    },
    expired: {
      en: 'Caducada',
      es: 'Caducada',
    },
    addCard: {
      en: 'Añadir tarjeta',
      es: 'Añadir tarjeta',
    },
    noCards: {
      en: 'No hay tarjetas registradas o todavía no se han cargado.',
      es: 'No hay tarjetas registradas o todavía no se han cargado.',
    },
  };

  useEffect(() => {
    if (favCard !== null) setSelected(favCard);
  }, [favCard]);

  const today = new Date();
  const thisMonth = today.getMonth() + 1;
  const thisYear = today.getFullYear();

  const transition = {
    type: 'tween',
    duration: 0.5,
    ease: [0.87, 0, 0.13, 1],
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div>
            <div className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5">
              <div className="font-stratos text-20 text-black col-span-4 tablet:col-span-8 laptop:col-span-5 tablet:col-start-2 laptop:col-start-2">
                <p className="mb-7">
                  {cards !== null && cards.length > 0 ? (
                    translateObj(translatedTexts.favouriteCards, localization.language)
                  ) : (
                    translateObj(translatedTexts.noCards, localization.language)
                  )}
                </p>
              </div>
              {cards !== null && cards.length > 0 && (
                <div className="font-stratos text-20 text-black col-span-4 tablet:col-span-9 laptop:col-span-6 tablet:col-start-1 laptop:col-start-1">
                  <AnimatePresence initial={false}>
                    {cards.map((card) => {
                      const {
                        last4,
                        exp_month,
                        exp_year,
                      } = card.card;

                      let hasExpired;
                      if (exp_year < thisYear) {
                        hasExpired = true;
                      } else if (exp_year === thisYear && exp_month < thisMonth) {
                        hasExpired = true;
                      } else {
                        hasExpired = false;
                      }

                      return (
                        <motion.div
                          key={card.token}
                          className="overflow-y-hidden"
                          transition={transition}
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                        >
                          <div className="flex mb-4 flex items-center justify-between tablet:grid tablet:grid-cols-9 laptop:grid-cols-6 gap-3 laptop:gap-4">
                            <div className="col-span-1 flex items-center justify-end">
                              <button type="button" onClick={() => { selectPreferredCallback(card.token); }}>
                                <SimpleRadioButton active={selected === card.token || cards.length === 1} />
                              </button>
                            </div>
                            <div key="card-body" className="tablet:col-span-8 laptop:col-span-5 flex-1 font-stratos font-bold text-black flex items-center justify-between border border-black p-14px text-base">
                              <span className="block flex-1 mr-14px">
                                {translateObj(translatedTexts.cardEnded, localization.language)}
                                {' '}
                                {last4}
                              </span>
                              <div className="flex-1 flex items-center justify-between">
                                {!hasExpired ? (
                                  <span>
                                    {translateObj(translatedTexts.expires, localization.language)}
                                    {' '}
                                    {exp_month < 10 ? `0${exp_month}` : `${exp_month}`}
                                    /
                                    {`${exp_year}`.slice(-2)}
                                  </span>
                                ) : (
                                  <span className="text-red">{translateObj(translatedTexts.expired, localization.language)}</span>
                                )}
                                <button type="button" className="ml-14px" onClick={() => removeCardCallback(card.token)}>
                                  <span className="font-icon icon-close text-red text-sm font-bold" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      );
                    })}
                  </AnimatePresence>
                </div>
              )}
              <div
                className="col-span-3 laptop:col-span-2 mt-5 col-end-5 tablet:col-end-11 laptop:col-end-9 row-start-3 cursor-pointer flex items-center justify-end"
                onClick={() => addCardCallback()}
              >
                <SimpleButton
                  isDisabled
                  isLowercase
                  isFullWidth
                  theme={{
                    base: 'red',
                    hover: 'red',
                  }}
                  text={translateObj(translatedTexts.addCard, localization.language)}
                />
              </div>
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default StripeCardsFormsy;

StripeCardsFormsy.propTypes = {
  match: PropTypes.shape({}).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      provider: PropTypes.string,
      token: PropTypes.string,
      createdAt: PropTypes.date,
      card: PropTypes.shape({
        last4: PropTypes.string,
        brand: PropTypes.string,
        exp_month: PropTypes.number,
        exp_year: PropTypes.number,
      }),
    }),
  ),
  favCard: PropTypes.string,
  addCardCallback: PropTypes.func.isRequired,
  removeCardCallback: PropTypes.func.isRequired,
  selectPreferredCallback: PropTypes.func.isRequired,
};

StripeCardsFormsy.defaultProps = {
  cards: null,
  favCard: null,
};
