import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

const BodyList = ({ components }) => {
  const [deviceScreen, setDeviceScreen] = useState(null);
  const [bannerHeight, setBannerHeight] = useState(0);
  const responsiveUtility = useRef();

  function handleVerificationBanner(event) {
    if (event.detail.opened) {
      setBannerHeight(event.detail.height);
    } else setBannerHeight(0);
  }

  function handleBodyListFilterEvent(event) {
    // console.log('BodyList.jsx => handleBodyListFilterEvent', event.detail);
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('verificationBannerRenderEvent', handleVerificationBanner);
      window.addEventListener('bodyListFilterEvent', handleBodyListFilterEvent);
    }

    return () => {
      window.removeEventListener('verificationBannerRenderEvent', handleVerificationBanner);
      window.removeEventListener('bodyListFilterEvent', handleBodyListFilterEvent);
    };
  }, []);


  function handleResize(size) {
    setDeviceScreen(size);
  }

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={handleResize} rdlSizes />
      <div
        className="w-full full-width mt-15 tablet:mt-16.5 laptop:mt-29 desktop:mt-30 bg-white"
        style={{ paddingTop: `${bannerHeight}px` }}
      >
        <ComponentIterator components={components} />
      </div>
    </>
  );
};

BodyList.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
};

BodyList.defaultProps = {
  components: null,
};

export default BodyList;
