import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import stripTags from '../helpers/stripTags';
import translateObj from '../../../util/translateObj';

const TitleLineH3 = ({
  text, color, match, parentComponent, firstInBody,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const textColorTailwind = color && !color.includes('#');
      const textColorHex = color && color.includes('#');

      if (parentComponent === 'StandardArticlePage') {
        return (
          <h3
            style={textColorHex ? { color } : null}
            className={
              `${textColorTailwind && `text-${color}`} text-32 leading-28 laptop:text-36 laptop:leading-39 font-stratos font-bold ${firstInBody ? 'laptop:mb-6' : 'laptop:mb-6 laptop:mt-12'}`}
          >
            {stripTags(translateObj(text, localization.language))}
          </h3>
        );
      }

      return (
        <h3
          style={textColorHex ? { color } : null}
          className={
            `${textColorTailwind && `text-${color}`} text-32 leading-28 laptop:text-36 laptop:leading-39 font-stratos font-bold my-10`}
        >
          {stripTags(translateObj(text, localization.language))}
        </h3>
      );
    }}
  </RoutesContext.Consumer>
);

export default withRouter(TitleLineH3);

TitleLineH3.propTypes = {
  color: PropTypes.string,
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  parentComponent: PropTypes.string,
  firstInBody: PropTypes.bool,
};

TitleLineH3.defaultProps = {
  color: 'black',
  text: null,
  parentComponent: '',
  firstInBody: false,
};
