import React from 'react';
import { PropTypes } from 'prop-types';
import getMamObjectsBySlugNameQuery from '../../api/graphql/queries/getMamObjectsBySlugname';
import Image from './Image';
import ModalWithChildren from './ModalWithChildren';
import CarouselWithThumbs from './CarouselWithThumbs';
import InputTextButton from './InputTextButton';
import translateObj from '../../util/translateObj';
import FilterNew from './Collapsible/FilterNew';

class Gallery extends React.Component {
  state = {
    images: [],
    filteredImages: [],
    isModal: false,
    isLoading: true,
    currentSlide: 0,
    checkedItem: '',
    tags: [],
  };

  componentDidMount() {
    const { objects } = this.props;
    this.body = document.querySelector('body');
    if (objects.length) {
      this.fetchMamObjectsBySlugnames(objects);
    }
  }

  fetchMamObjectsBySlugnames = (slugnames) => {
    getMamObjectsBySlugNameQuery(slugnames)
      .then((res) => {
        // const publicImg = res.filter(i => i !== null);
        if (res) {
          this.generateFilterTags(res);
        }
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchMamObjects:', err);
      });
  };

  handleClose = () => {
    this.setState({ isModal: false });
    this.body.setAttribute('style', 'overflow: auto');
  };

  handleClick = (i) => {
    this.setState({ isModal: true, currentSlide: i });
    this.body.setAttribute('style', 'overflow: hidden');
  };

  handleOnChange = (query, lang) => {
    const { images } = this.state;
    const filteredImages = images.filter(item => ((translateObj(item.fileReadableName, lang).toLowerCase().indexOf(query.toLowerCase()) !== -1) || (translateObj(item.description, lang).toLowerCase().indexOf(query.toLowerCase()) !== -1)));
    this.setState({ filteredImages });
  }

  onHandleFilterClick = (val) => {
    const { images } = this.state;
    if (val && val !== 'all') {
      const filteredImages = images.filter(item => (item.tags.includes(val)));
      this.setState({ filteredImages });
    } else if (val === 'all') {
      this.setState({ filteredImages: images });
    }
  }

  generateFilterTags = (images) => {
    const allTags = images.reduce((accum, current) => accum.concat(current.tags), []);
    const uniques = [...new Set(allTags)];
    const tags = uniques.map((el) => {
      const tag = {
        type: 'FilterHolder',
        name: {
          category: 'barcelona',
          text: {
            en: el,
          },
          value: el,
        },
      };
      return tag;
    });
    tags.unshift({
      type: 'FilterHolder',
      name: {
        category: 'barcelona',
        text: {
          en: 'all',
        },
        value: 'all',
      },
    });
    this.setState({
      tags,
      images,
      checkedItem: tags[0].name.value,
      filteredImages: images,
      isLoading: false,
    });
  };

  render() {
    const {
      images, isLoading, isModal, currentSlide, filteredImages, tags, checkedItem,
    } = this.state;
    const { showFilter, showSearch, filterText } = this.props;
    return (
      <React.Fragment>
        {isModal && images.length
          ? (
            <ModalWithChildren handleClose={this.handleClose}>
              <CarouselWithThumbs images={filteredImages} currentSlide={currentSlide} />
            </ModalWithChildren>
          )
          : !isLoading && (
            <>
              {showFilter && <FilterNew checkedItem={checkedItem} noCollapse noMargin filterText={filterText} onHandleCollapsibleMenuSelect={this.onHandleFilterClick} components={tags} />}
              {showSearch
              && (
              <div className="bg-white p-8 flex items-center sm:block md:block items-center rounded-bl-lg rounded-br-lg">
                <div className="bg-white flex-1" style={{ marginTop: '-8px', marginBottom: '-8px' }}>
                  <InputTextButton
                    name="searchQuery"
                    params={{ text: { es: 'buscar', en: 'search', ca: 'cerca' }, placeholder: { es: '', en: '', ca: '' }, searchIcon: 'font-icon icon-search' }}
                    handleOnChange={this.handleOnChange}
                  />
                </div>
              </div>
              )
              }
              <div className="flex flex-wrap md:-mx-2 lg:-mx-2 xl:-mx-2 mt-2 w-full mb-8">
                {filteredImages.length > 0
              && filteredImages.map((image, i) => (
                <div className="sm:w-full md:w-1/3 lg:w-1/4 xl:w-1/5 py-2 px-2 sm:px-0" key={image.assetSlugName}>
                  <Image isInGrid={false} handleClick={this.handleClick} i={i} {...image} />
                </div>
              ))}
              </div>
            </>
          ) }
      </React.Fragment>
    );
  }
}

Gallery.propTypes = {
  title: PropTypes.shape(),
  description: PropTypes.shape(),
  tags: PropTypes.arrayOf(PropTypes.string),
  objects: PropTypes.arrayOf(PropTypes.string),
  showFilter: PropTypes.bool,
  showSearch: PropTypes.bool,
};

Gallery.defaultProps = {
  title: {},
  description: {},
  tags: [],
  objects: [],
  showFilter: false,
  showSearch: false,
};

export default Gallery;
