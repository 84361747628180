import React from 'react';
import PropTypes from 'prop-types';

const TopHeaderMenuContainer = ({ children, name, backgroundColor }) => (
  <div id={name} className="relative flex float-right items-center">
    { children }
  </div>
);

TopHeaderMenuContainer.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
  backgroundColor: PropTypes.string,
};

TopHeaderMenuContainer.defaultProps = {
  backgroundColor: 'transparent',
};

export default TopHeaderMenuContainer;
