import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import MediaTitle from './MediaTitle';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const SidebarPastDates = ({ match, data }) => {
  const translations = {
    es: 'Actuaciones anteriores',
    en: 'Previous concerts',
    ca: 'Actuacions anteriors',
  };
  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        moment.locale(localization.language);

        return (
          <React.Fragment>
            <MediaTitle primaryText={translations} category="black" />
            <div>
              {data && data.length && data[0] !== null && data.map((pastDate, i) => (
                <div className="p-4 border-t border-black border-dashed md:flex md:flex-wrap md:items-center" key={pastDate.dateTimeStartReal + i}>
                  <div className="font-americaMonoRegular text-12 uppercase md:w-1/4">
                    {pastDate.dateTimeStartReal && moment(Number(pastDate.dateTimeStartReal)).format('DD / MM / YY')}
                    {' '}
                  |
                    {' '}
                    {pastDate.venue.location && pastDate.venue.location}
                  </div>
                  <h2 className="font-americaBlack text-18 mt-2 md:mt-0 md:ml-12">{translateObj(pastDate.event.eventReadableName, localization.language) && translateObj(pastDate.event.eventReadableName, localization.language)}</h2>
                </div>
              ))}
            </div>
          </React.Fragment>
        );
      }}
    </RoutesContext.Consumer>
  );
};

SidebarPastDates.propTypes = {
  match: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default withRouter(SidebarPastDates);
