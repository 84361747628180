import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';
import { leftArrowIcon } from '../PdfMagazineGallery';
import { useUser } from '../../../context/userProfileContext';

const Paywall = ({ components, showBackButton }) => {
  const items = [];
  const lastComponentStyles = showBackButton ? 'mt-10' : 'mt-40 tablet:mt-32 laptop:mt-12'; 
  const user = useUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const isLoggedIn = pathname.includes('/contenido-exclusivo') && !!user.token;

  const showLoginModal = () => {
    // TODO: change this to a proper react method like a context
    const loginEvent = new CustomEvent(
      'loginMenuEvent', {
        detail: { close: false },
      },
    );
    window.dispatchEvent(loginEvent);
  };

  components.forEach((component, index) => {
    Object.assign(component, { parentComponent: 'Paywall' });
    if (component.type === 'TicketBlock') {
      items.push(component);
    }
    if (component.type === 'TitleLineH2' && index === (components.length - 1)) {
      Object.assign(component, { includesLink: true, showAlt: isLoggedIn }, { onOpen: isLoggedIn ? () => history.push('/user-profile') : showLoginModal });
    }
  });

  items.forEach((item, index) => {
    Object.assign(item, { position: (index + 1), totalItems: items.length });
  });

  return (
    <>
      <div className="w-full full-width mt-40 tablet:mt-26 laptop:mt-34 px-5 tablet:px-26px laptop:px-24 mb-200px">
        <div>
          <div className="pb-10">
            <ComponentIterator components={[components[0], components[1]]} />
          </div>
          <div className="flex flex-col flex-wrap tablet:flex-row justify-center items-center">
            <ComponentIterator components={items} />
          </div>
          {(!user.token && pathname.includes('/suscribete')) || pathname.includes('/contenido-exclusivo') ? (
            <div className={lastComponentStyles}>
              <ComponentIterator components={[components[components.length - 1]]} />
            </div>
          ) : null}
        </div>
        {
          showBackButton ? (
            <div className="mt-10 flex justify-center">
              <div className="w-302px flex justify-end">
                <BackButton />
              </div>
            </div>
          ) : null
        }
      </div>
    </>
  );
};

Paywall.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
  showBackButton: PropTypes.bool,
};

Paywall.defaultProps = {
  components: null,
  showBackButton: false,
};

const BackButton = () => {
  const { goBack } = useHistory();

  return (
    <button type="button" onClick={() => goBack()}>
      <span className="py-6px laptop:py-1 desktop:py-2 text-black hover:text-grey text-base laptop:text-xl text-center font-stratos font-bold whitespace-pre trans inline-block bg-transparent">
        <span className="text-red align-middle">{leftArrowIcon}</span>
          Retroceder
      </span>
    </button>
  );
};

export default Paywall;
