import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import translateObj from '../../../../util/translateObj';
import LoadIcon from '../../LoadIcon';
import userValidateProVoucher from '../../../../api/graphql/mutations/userValidateProVoucher';
import { useUser } from '../../../../context/userProfileContext';

// import { buttonClasses } from '../OnDemandUtils/onDemandUtils';

const orangeBackground = { backgroundColor: 'rgba(238, 111, 46, 0.7)' };

const getVoucherDefaultText = {
  es: 'No tengo acreditación',
  en: 'I do not have an accreditation',
  ca: 'No tinc acreditació',
  pt: 'I do not have an accreditation',
};
const getVoucherDefaultLink = {
  es: 'https://www.redtkt.com/festivals/primavera-pro-online-2020',
  en: 'https://www.redtkt.com/festivals/primavera-pro-online-2020',
  ca: 'https://www.redtkt.com/festivals/primavera-pro-online-2020',
  pt: 'https://www.redtkt.com/festivals/primavera-pro-online-2020',
};
const exclusiveContentDefaultText = {
  en: 'Exclusive content for Primavera Pro delegates',
  es: 'Contenido exclusivo para acreditados a Primavera Pro',
  ca: 'Contingut exclusiu per acreditats a Primavera Pro',
  pt: 'Exclusive content for Primavera Pro delegates',
};

const ProVoucherOnDemandModal = (props) => {
  const {
    loadTokenForLS, history, imageUrl, postVideoUrl, postVideoRole, userEmail, handleOnDemandScreenStatus, translations, translations: { loggedModal: formText = {} } = {}, language, userSocialInfo: { profileObj: preFilledValues = {} } = {}, closeModal, hasUserRequiredRole,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [voucherId, setVoucherId] = useState(null);
  const [isVoucherLengthOk, setIsVoucherLengthOk] = useState(false);
  const user = useUser();

  const backgroundStyle = {
    backgroundImage: imageUrl,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const handleChange = (e) => {
    e.preventDefault();
    setVoucherId(e.target.value);
    if (e.target.value && e.target.value.length > 15) {
      setIsVoucherLengthOk(true);
    } else {
      setIsVoucherLengthOk(false);
    }
  };

  const handleUserRedirectToVideoPage = () => {
    history.push(`/${language || 'en'}${postVideoUrl && postVideoUrl[0] === '/' ? postVideoUrl : `/${postVideoUrl}`}`);
  };

  const checkRTKTVoucher = async () => {
    if (voucherId) {
      const resultFromValidatingVoucher = await userValidateProVoucher({ email: userEmail, roles: [postVideoRole || 'noRoleProvided'], voucherId });

      const { token: tokenFromUserValidateVoucherMutation = '', isValid = false, error: errorFromUserValidateVoucherMutation = '' } = resultFromValidatingVoucher;

      if (isValid && tokenFromUserValidateVoucherMutation) {
        user.setNewAuthToken(tokenFromUserValidateVoucherMutation);
        if (postVideoUrl) { handleUserRedirectToVideoPage(); } else {
          closeModal();
          if (window && window.location) {
            window.location.reload();
          }
        }
      } else if (errorFromUserValidateVoucherMutation) {
        setError(errorFromUserValidateVoucherMutation);
        setTimeout(() => {
          setError('');
        }, 2000);
      } else {
        closeModal();
      }
    }
  };

  if (hasUserRequiredRole) return <LoadIcon color="black" type="ThreeDots" />;
  return (
    <div>
      <div>
        <div
          className={`${'h-80'} w-full`}
          style={backgroundStyle}
        />
      </div>
      <div className="w-full bg-white-dark pb-2 ">
        <div className=" py-2 px-4 mb-2" style={orangeBackground}>
          <input type="voucherId" name="voucherId" placeholder={translateObj(formText.voucherPlaceholder, language) || 'voucher id'} onChange={handleChange} className="my-4 rounded-full border-1 p-4 py-3.5 bg-inherit border-black text-black text-12 w-full bg-white" />
        </div>
        <div className="py-2 px-4">
          <div className="font-americaBold pb-4 uppercase">
            {translateObj(formText.hasOwnProperty('exclusiveContent') ? formText.exclusiveContent : exclusiveContentDefaultText, language)}
          </div>
          <div className="font-americaBold pb-4">{translateObj(formText.voucherTitle, language)}</div>
          <div className="font-americaRegular pb-4">{translateObj(formText.voucherDescription, language)}</div>
          <div className="font-americaRegular text-xs pb-2">{translateObj(formText.voucherConditions, language)}</div>
        </div>
        {error && <div className=" px-4 pb-6 text-red font-americaBold">{translateObj(formText.incorrectVoucherText, language)}</div>}
        <div className="flex flex-col justify-end px-4 pb-2">
          <button disabled={!isVoucherLengthOk} type="button" onClick={checkRTKTVoucher} className={`uppercase rounded-full font-americaMonoBold w-full cursor-pointer  text-center px-12 py-4 ${isVoucherLengthOk ? 'bg-gray200 text-black border-1 border-gray200 cursor-pointer ' : 'bg-white text-gay200 border-grey cursor-not-allowed'}`}>
            {
          isLoading ? <LoadIcon height={15} width={15} color="black" type="ThreeDots" />
            : translateObj(formText.validateVoucherText, language)
          }
          </button>
          <Link to={translateObj(formText.hasOwnProperty('getVoucherLink') ? formText.getVoucherLink : getVoucherDefaultLink, language)} localizationpath={`/${language}`}>
            <button type="button" className="uppercase rounded-full mt-4 font-americaMonoBold w-full cursor-pointer  text-center px-12 py-4 bg-gray200 text-black border-1 border-gray200 cursor-pointer ">
              {translateObj(formText.hasOwnProperty('getVoucherText') ? formText.getVoucherText : getVoucherDefaultText, language)}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

ProVoucherOnDemandModal.propTypes = {
  userEmail: PropTypes.string.isRequired,
  language: PropTypes.string,
  imageUrl: PropTypes.string,
  otherOptionsButtonUrl: PropTypes.string,
  postVideoUrl: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  hasUserRequiredRole: PropTypes.bool,
};

ProVoucherOnDemandModal.defaultProps = {
  language: 'en',
  imageUrl: '',
  otherOptionsButtonUrl: '',
  postVideoUrl: '',
  hasUserRequiredRole: true,
};

export default withRouter(ProVoucherOnDemandModal);
