import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import getLocalization from '../../../util/localization';
import RoutesContext from '../../../context/routesContext';
import translateObj from '../../../util/translateObj';

const buttonRowMegamenu = ({
  title, link, bold,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage }) => {
      const localization = getLocalization(contextLanguage);
      if (link === null || typeof link === 'undefined') {
        return (
          <div className={`tablet:absolute tablet:pin-t tablet:pin-b tablet:pin-l tablet:flex tablet:items-center tablet:justify-start laptop:block laptop:static laptop:ml-4 text-black laptop:block text-sm tablet:text-lg laptop:text-base uppercase font-stratos ${bold ? 'font-bold' : 'font-light'}`}>
            {translateObj(title, localization.language)}
          </div>
        );
      }
      return (
        <Link
          to={`${link}`}
          className={`mr-4 laptop:mr-0 laptop:ml-4 no-underline text-black block text-sm tablet:text-lg laptop:text-sm uppercase font-stratos ${bold ? 'font-bold' : 'font-light'}`}
        >
          {translateObj(title, localization.language)}
        </Link>
      );
    }}
  </RoutesContext.Consumer>
);

buttonRowMegamenu.propTypes = {
  title: PropTypes.shape().isRequired,
  link: PropTypes.string,
  bold: PropTypes.bool,
};

buttonRowMegamenu.defaultProps = {
  link: '',
  bold: false,
};

export default buttonRowMegamenu;
