/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { PayPalButton } from 'react-paypal-button-v2';

const Paypal = ({
  product,
  resultCallback,
}) => {
  const [error, setError] = useState(null);
  const [resultInfo, setResultInfo] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  return (
    <div className="w-full">
      <PayPalButton
        options={{
          clientId: 'ARAfDPSLGDuBYE2iMy7aAsIuD7_oUQFeTYlEQEnMia5xaHhGh-UG1cHhTvCCOAoDjem6nszMNiymw9b_',
          currency: 'EUR',
          disableFunding: 'credit,card',
        }}
        amount="1"
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        createOrder={(data, actions) => {
          setProcessing(true);
          setError(false);
        }}
        catchError={(err) => {
          setError(`Pago fallido: ${err.message}`);
          setProcessing(false);
          resultCallback({ status: 'failed' });
        }}
        onError={(err) => {
          setError(`Error: ${err.message}`);
          setProcessing(false);
          resultCallback({ status: 'failed' });
        }}
        onSuccess={(details, data) => {
          console.log('🚀 ~ file: Paypal.jsx ~ line 57 ~ details, data', details, data);
          setError(null);
          setSucceeded(true);
          setProcessing(false);
          setResultInfo('Payment succeeded');
          resultCallback({ status: 'succeeded' });
          // alert(`Transaction completed by ${details.payer.name.given_name}`);

          // OPTIONAL: Call your server to save the transaction
          // return fetch('/paypal-transaction-complete', {
          //   method: 'post',
          //   body: JSON.stringify({
          //     orderID: data.orderID,
          //   }),
          // });
        }}
      />
      <div className="flex justify-end mt-8 sm:mt-0">
        {error && (
          <div className="w-full card-error" role="alert">
            {error}
          </div>
        )}
      </div>
      <div className="flex flex-row w-full justify-between items-center">
        <button type="button" onClick={() => resultCallback({ status: 'cancel' })} className="text-grey text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-grey bg-beige mr-2">
          Cancelar
        </button>
      </div>
    </div>
  );
};

Paypal.propTypes = {
  product: PropTypes.object.isRequired,
  resultCallback: PropTypes.func.isRequired,
};

Paypal.defaultProps = {
};

export default Paypal;
