import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

const SharingBlock = ({
  match,
  title,
  isArticleHeader,
  parentComponent,
}) => {
  const [url, setUrl] = useState();
  const facebookPopup = () => window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 'pop', 'width=600, height=400, scrollbars=no');
  const twitterPopup = () => window.open(`http://twitter.com/share?url=${encodeURIComponent(url)}`, 'pop', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUrl(window.location.href);
    }
  }, [url]);

  const getContainerStyles = () => {
    switch (parentComponent) {
      case 'NewsPostPage':
        if (!isArticleHeader) {
          return 'w-20% pb-8';
        }
        return '';
      default:
        return '';
    }
  };

  const alignIcons = () => {
    switch (parentComponent) {
      case 'CoverStoryPostPage':
        return 'justify-center tablet:justify-end';
      case 'StandardArticlePage':
      case 'ListsPostPage':
      case 'RecordsPostPage':
        return 'justify-end laptop:justify-start';
      default:
        return 'justify-end tablet:justify-start';
    }
  };

  const hasTitle = title !== null && Object.entries(title).length > 0;

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);

        return (
          <div className={`${parentComponent === 'NewsPostPage' ? 'mt-6 tablet:mt-10' : 'w-full'} ${(hasTitle && !isArticleHeader) ? 'border-t-3 border-black border-solid' : ''} ${getContainerStyles()}`}>
            <div className="flex flex-col w-full">
              {title && !isArticleHeader && <span className={`text-black font-stratos text-13 font-semibold mt-2 ${!isArticleHeader && parentComponent === 'NewsPostPage' ? 'mb-4' : 'mb-2'}`}>{translateObj(title, localization.language)}</span>}
              <div className={`${parentComponent === 'NewsPostPage' ? 'flex' : 'flex flex-row'} laptop:mt-2 desktop:mt-4 ${hasTitle ? 'justify-start' : alignIcons()} ${isArticleHeader ? 'mb-4' : ''}`}>
                <span onClick={() => facebookPopup()} className={`cursor-pointer font-icon icon-facebook ${hasTitle ? 'text-26 mr-6 tablet:-ml-2 laptop:ml-0 laptop:text-21 laptop:mr-4' : 'text-24 tablet:text-22 laptop:text-28 tablet:-mt-4 laptop:mt-0 laptop:-ml-2 mr-3 laptop:mr-4 desktop:mr-6'}`} />
                <span onClick={() => twitterPopup()} className={`cursor-pointer font-icon icon-twitter ${hasTitle ? 'text-26 laptop:text-21' : 'mr-2 tablet:mr-0 tablet:-mt-4 laptop:mt-0 text-24 tablet:text-22 laptop:text-28'}`} />
              </div>
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

SharingBlock.propTypes = {
  match: PropTypes.shape().isRequired,
  title: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
  }),
  isArticleHeader: PropTypes.bool,
  parentComponent: PropTypes.string,
};

SharingBlock.defaultProps = {
  title: null,
  isArticleHeader: false,
  parentComponent: null,
};

export default withRouter(SharingBlock);
