import React from 'react';
import PropTypes from 'prop-types';
import HoursRow from './HoursRow';
import VenueRow from './VenueRow';
import VenuesColumn from './VenuesColumn';
import DayLabel from './DayLabel';
import SwitchLayoutButton from './SwitchLayoutButton';

const LayoutRow = ({
  day,
  artistsByDay,
  firstColumnRef,
  venues,
  layout,
  startAndEndHours,
  showArtistCallback,
  hideArtistCallback,
  localization,
  fixedColumnRef,
  toggleLayoutCallback,
  draggableRef,
  draggableHeaderRef,
  fixHeadRef,
  contentOverflows,
  dayId,
  hasConferences,
  eventSlug,
}) => (
  <div className="relative w-full">
    <div ref={fixHeadRef} className={`header-${dayId} z-10 w-full bg-white-dark`}>
      <DayLabel day={day} />
      <div className="flex items-stretch relative w-full">
        <SwitchLayoutButton layout={layout} toggleLayoutCallback={toggleLayoutCallback} />
        <div className="overflow-hidden border-b-1 border-solid border-white">
          <div
            ref={draggableHeaderRef}
            className={`inline-flex ${contentOverflows && 'cursor-ew-resize'}`}
            style={{
              backfaceVisibility: 'hidden',
              perspective: 1000,
            }}
          >
            <HoursRow layout={layout} firstColumnRef={firstColumnRef} startAndEndHours={startAndEndHours} day={day} />
          </div>
        </div>
      </div>
    </div>
    <div className="flex items-start w-full relative z-0">
      <VenuesColumn
        layout={layout}
        fixedColumnRef={fixedColumnRef}
        day={day}
        toggleLayoutCallback={toggleLayoutCallback}
        startAndEndHours={startAndEndHours}
        artistsByDay={artistsByDay}
        venues={venues}
        localization={localization}
      />
      <div className="overflow-hidden">
        <div
          ref={draggableRef}
          className={`${contentOverflows && 'cursor-ew-resize'} inline-block`}
          style={{
            backfaceVisibility: 'hidden',
            perspective: 1000,
          }}
        >
          {Object.keys(artistsByDay[day]).map((venue, index) => (
            <VenueRow
              eventSlug={eventSlug}
              artists={artistsByDay[day][venue]}
              day={day}
              hideArtistCallback={hideArtistCallback}
              index={index}
              key={venue}
              layout={layout}
              showArtistCallback={showArtistCallback}
              startAndEndHours={startAndEndHours}
              venue={venues[venue]}
              hasConferences={hasConferences}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
);

LayoutRow.propTypes = {
  day: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  dayId: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  artistsByDay: PropTypes.shape().isRequired,
  startAndEndHours: PropTypes.shape().isRequired,
  venues: PropTypes.shape().isRequired,
  showArtistCallback: PropTypes.func.isRequired,
  hideArtistCallback: PropTypes.func.isRequired,
  localization: PropTypes.shape().isRequired,
  fixedColumnRef: PropTypes.shape().isRequired,
  toggleLayoutCallback: PropTypes.func.isRequired,
  draggableRef: PropTypes.shape().isRequired,
  draggableHeaderRef: PropTypes.shape().isRequired,
  fixHeadRef: PropTypes.shape().isRequired,
  contentOverflows: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  firstColumnRef: PropTypes.object.isRequired,
  hasConferences: PropTypes.bool,
};

LayoutRow.defaultProps = {
  hasConferences: false,
};
export default LayoutRow;
