import React from 'react';
import PropTypes from 'prop-types';

const Separator = ({
  borderWidth, color, marginTop,
}) => <div className="w-full"><div className={`w-full border-t-${borderWidth} border-${color} ${marginTop ? 'my-4' : 'mb-4'}`} /></div>;

Separator.propTypes = {
  borderWidth: PropTypes.number,
  color: PropTypes.string,
  marginTop: PropTypes.bool,
};

Separator.defaultProps = {
  borderWidth: 1,
  color: 'black',
  marginTop: true,
};

export default Separator;
