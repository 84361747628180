import React, { useEffect, useLayoutEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../context/routesContext';
import getPDFURL from '../../api/pdf/getPDFURL';
import translateObj from '../../util/translateObj';

const MOBILE_MAX_WIDTH = 714;
const ZOOM_SCALE = 0.25;
const PDF_CONTAINER = '#pdfContainer';

const compactIcon = <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 15h-4m0 0v4m0-4 4 4M5 9h4m0 0V5m0 4L5 5m14 4h-4m0 0V5m0 4 4-4M5 15h4m0 0v4m0-4-4 4" /></svg>;
const expandIcon = <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19h4m0 0v-4m0 4-4-4M9 5H5m0 0v4m0-4 4 4m6-4h4m0 0v4m0-4-4 4M9 19H5m0 0v-4m0 4 4-4" /></svg>;
export const leftArrowIcon = <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14 7-5 5 5 5" /></svg>;
const rightArrowIcon = <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m10 7 5 5-5 5" /></svg>;
const zoomInIcon = <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m20 20-4.05-4.05m0 0a7 7 0 1 0-9.9-9.9 7 7 0 0 0 9.9 9.9zM8 11h3m3 0h-3m0 0V8m0 3v3" /></svg>;
const zoomOutIcon = <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m20 20-4.05-4.05m0 0a7 7 0 1 0-9.9-9.9 7 7 0 0 0 9.9 9.9zM8 11h6" /></svg>;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const fetchURLs = async (index, key, pages, mobile = false) => {
  let pdf1URL; let pdf2URL;

  if (mobile) {
    if (index < 1) {
      pdf1URL = await getPDFURL(key.replace('.pdf', '-0.pdf'));
      return { pdf1: pdf1URL, pdf2: '' };
    }
    pdf1URL = await getPDFURL(key.replace('.pdf', `-${index}.pdf`));
    return { pdf1: pdf1URL, pdf2: '' };
  }
  if (index < 1) {
    pdf1URL = await getPDFURL(key.replace('.pdf', '-0.pdf'));
    return { pdf1: pdf1URL, pdf2: '' };
  }
  if (index < pages - 1) {
    pdf1URL = await getPDFURL(key.replace('.pdf', `-${index}.pdf`));
    pdf2URL = await getPDFURL(key.replace('.pdf', `-${index + 1}.pdf`));
    return { pdf1: pdf1URL, pdf2: pdf2URL };
  }
  pdf1URL = await getPDFURL(key.replace('.pdf', `-${index}.pdf`));
  return { pdf1: pdf1URL, pdf2: '' };
};

const handleFullscreen = (toggle, setFullscreen, viewerSize) => {
  const el = document.querySelector(PDF_CONTAINER);

  if (!document.fullscreenElement && !document.webkitFullscreenElement) {
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.webkitRequestFullscreen) { /* Safari */
      el.webkitRequestFullscreen();
    } else {
      if (toggle) {
        setFullscreen(false);
        document.body.style = 'overflow-y: auto;';
        el.style = `height: ${viewerSize > MOBILE_MAX_WIDTH ? viewerSize / 1.5 : viewerSize * 1.25}px; touch-action: none`;
      } else {
        setFullscreen(true);
        document.body.style = 'overflow: hidden; touch-action: none;';
        el.style = 'position: fixed; top: 0; left: 0; width: 100%; height: 100%; margin: 0; padding: 0; z-index: 9999; touch-action: none;';
      }
    }
  } else if (document.exitFullscreen) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    }
  }
};

const PdfMagazineGallery = ({ pdf, pages, subtitle }) => {
  // TODO: move state management to useReducer
  const [viewerSize, setViewerSize] = useState(0);
  const [queryPage, setQueryPage] = useState();
  const [querySearch, setQuerySearch] = useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [pdfScale, setPdfScale] = useState(1);
  const [pageDisplayed, setPageDisplayed] = useState(0);
  const [pdfFiles, setPdfFiles] = useState({ pdf1: '', pdf2: '' });
  const [pdfTransform, setPdfTransform] = useState({ dragging: false, x: 0, y: 0, xTranslation: 0, yTranslation: 0 });

  const { language } = useRoutes();
  const history = useHistory();

  if (typeof window === 'undefined') return '';

  const urlSearchParams = new URLSearchParams(window.location.search);

  const pdfSubtitle = translateObj(subtitle, language);

  const isDesktop = viewerSize > MOBILE_MAX_WIDTH;

  const handleResize = () => {
    try {
      const container = document.querySelector(PDF_CONTAINER);
      setViewerSize(container.getBoundingClientRect().width);
    } catch (error) { console.log(error); }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    handleResize();

    const urlPage = urlSearchParams.get('pdfPage') ? Number(urlSearchParams.get('pdfPage')) - 1 : null;
    if (urlPage) setQueryPage(urlPage);

    if (urlSearchParams.get('search')) setQuerySearch(urlSearchParams.get('search'));

    let pdfPage = 0;

    // If the page is passed as a URL param and it's an odd number
    // we use the pdf page before it to load the pages in order to
    // keep the pages in the same pairs all the time.
    if (urlPage) {
      pdfPage = urlPage % 2 ? urlPage : urlPage - 1;
    }

    if (pdf.key && pages) {
      fetchURLs(pdfPage, pdf.key, pages, viewerSize < 750).then((pdfs) => {
        setPageDisplayed(pdfPage);
        setPdfFiles(pdfs);
      });
    }

    return () => window.removeEventListener('resize', handleResize, false);
  }, []);

  const highlightQueryText = () => {
    if (!querySearch || !(pageDisplayed === queryPage || pageDisplayed === queryPage - 1)) return;

    const textNodes = document.querySelectorAll('.react-pdf__Page__textContent > *');
    [...textNodes].map((text) => {
      if (new RegExp(querySearch, 'gi').test(text.innerText)) {
        text.innerHTML = text.innerText.replace(new RegExp(querySearch, 'gi'), `<span style='background-color: rgb(255 70 70 / 50%)'>${querySearch}</span>`);
      }
    });
  };

  useLayoutEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  const handleNext = () => {
    let index;

    if (pageDisplayed < 1) index = 1;
    else if (viewerSize < 750 && pageDisplayed < pages - 1) index = pageDisplayed + 1;
    else if (pageDisplayed < pages - 1) index = pageDisplayed + 2;
    else index = pages;

    setPageDisplayed(index);
    fetchURLs(index, pdf.key, pages, viewerSize < 750).then((pdfs) => setPdfFiles(pdfs));
  };

  const handlePrev = () => {
    let index;

    if (pageDisplayed === 1) index = 0;
    else if (viewerSize < 750 && pageDisplayed < pages - 1) index = pageDisplayed - 1;
    else if (pageDisplayed > 1) index = pageDisplayed - 2;
    else index = 1;

    setPageDisplayed(index);
    fetchURLs(index, pdf.key, pages, viewerSize < 750).then((pdfs) => setPdfFiles(pdfs));
  };

  const handleZoomIn = () => setPdfScale(pdfScale + ZOOM_SCALE);

  const handleZoomOut = () => setPdfScale(pdfScale - ZOOM_SCALE);

  const handleMouseMove = ({ clientX, clientY }) => {
    if (!pdfTransform.dragging) return;
    setPdfTransform({
      dragging: true,
      xTranslation: pdfTransform.xTranslation + clientX - pdfTransform.x,
      x: clientX,
      yTranslation: pdfTransform.yTranslation + clientY - pdfTransform.y,
      y: clientY,
    });
  };

  const handleMouseDown = ({ clientX, clientY }) => {
    setPdfTransform({ dragging: true, xTranslation: pdfTransform.xTranslation, yTranslation: pdfTransform.yTranslation, x: clientX, y: clientY });
  };

  const handleMouseUp = () => {
    setPdfTransform({ ...pdfTransform, dragging: false });
  };

  const handleTouchMove = ({ preventDefault, changedTouches }) => {
    if (!pdfTransform.dragging) return;
    setPdfTransform({
      dragging: true,
      xTranslation: pdfTransform.xTranslation + changedTouches[0].clientX - pdfTransform.x,
      x: changedTouches[0].clientX,
      yTranslation: pdfTransform.yTranslation + changedTouches[0].clientY - pdfTransform.y,
      y: changedTouches[0].clientY,
    });
    preventDefault();
  };

  const handleTouchStart = ({ changedTouches }) => {
    setPdfTransform({ dragging: true, xTranslation: pdfTransform.xTranslation, yTranslation: pdfTransform.yTranslation, x: changedTouches[0].clientX, y: changedTouches[0].clientY });
  };

  const handleTouchEnd = () => {
    setPdfTransform({ ...pdfTransform, dragging: false });
  };

  const handleBack = () => (urlSearchParams.get('search') ? history.push(`/search?q=${urlSearchParams.get('search')}`) : history.goBack());

  return (
    <>
      <div className="w-full">
        <div
          className="border-t-5 border-solid
              border-red text-red inline-block mx-5 tablet:mx-12 laptop:mx-24 my-10 font-stratos font-semibold text-2xl capitalize"
        >
          {pdfSubtitle}
        </div>
      </div>
      <div className="relative w-full mb-10">
        <div id="pdfContainer" onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} style={{ height: `${isDesktop ? viewerSize / 1.5 : viewerSize * 1.25}px`, touchAction: 'none' }} className="relative overflow-hidden bg-grey flex h-full items-center justify-center mx-5 tablet:mx-12 laptop:mx-24 py-5 px-20">
          <div style={{ placeSelf: 'center' }} className="absolute w-full flex justify-between items-center">
            <button type="button" onClick={handlePrev} disabled={!pageDisplayed} className={`${!pageDisplayed ? 'cursor-not-allowed opacity-50' : ''} flex justify-center items-center m-4 w-12 h-12 z-10 bg-white rounded-full font-stratos font-semibold text-2xl`} style={{ filter: 'drop-shadow(0px 5px 10px rgba(0,0,0,0.4))' }}>
              {leftArrowIcon}
            </button>
            <button type="button" onClick={handleNext} disabled={pageDisplayed >= pages - 1} className={`${pageDisplayed >= pages ? 'cursor-not-allowed opacity-50' : ''} flex justify-center items-center m-4 w-12 h-12 z-10 bg-white rounded-full font-stratos font-semibold text-2xl`} style={{ filter: 'drop-shadow(0px 5px 10px rgba(0,0,0,0.4))' }}>
              {rightArrowIcon}
            </button>
          </div>
          <div style={{ placeSelf: 'end' }} className="absolute bottom-0 w-full flex justify-center items-center">
            <button type="button" onClick={handleZoomOut} className="flex justify-center items-center m-4 w-10 h-10 z-10 bg-white rounded-full font-stratos font-semibold text-2xl" style={{ filter: 'drop-shadow(0px 5px 10px rgba(0,0,0,0.4))' }}>
              {zoomOutIcon}
            </button>
            <button type="button" onClick={() => handleFullscreen(fullscreen, setFullscreen, viewerSize)} className="flex justify-center items-center m-4 w-10 h-10 z-10 bg-white rounded-full font-stratos font-semibold text-2xl" style={{ filter: 'drop-shadow(0px 5px 10px rgba(0,0,0,0.4))' }}>
              {fullscreen ? compactIcon : expandIcon}
            </button>
            <button type="button" onClick={handleZoomIn} className="flex justify-center items-center m-4 w-10 h-10 z-10 bg-white rounded-full font-stratos font-semibold text-2xl" style={{ filter: 'drop-shadow(0px 5px 10px rgba(0,0,0,0.4))' }}>
              {zoomInIcon}
            </button>
          </div>
          {/* MOBILE MAGAZINE CONTENT */}
          {!isDesktop
          && (
            <div style={{ transform: `translate(${pdfTransform.xTranslation}px,${pdfTransform.yTranslation}px)` }}>
              <Document file={pdfFiles.pdf1.url} className="relative" loading="" noData="">
                <Page
                  width={viewerSize - 100}
                  scale={pdfScale}
                  pageNumber={1}
                  onGetTextSuccess={highlightQueryText}
                />
                {!!pageDisplayed && <div className="absolute w-full h-full top-0 left-0" style={{ boxShadow: 'rgb(0 0 0 / 10%) -20px 0px 25px -5px inset, rgb(0 0 0 / 4%) -10px 0px 10px -5px inset' }} />}
              </Document>
            </div>
          )}
          {/* DESKTOP MAGAZINE CONTENT */}
          {isDesktop
          && (
          <div className="flex cursor-move" style={{ transform: `translate(${pdfTransform.xTranslation}px,${pdfTransform.yTranslation}px)` }}>
            {!!pdfFiles.pdf1 && (
            <div style={{ width: `${(isDesktop ? viewerSize / 2 - 100 : viewerSize - 100) * pdfScale}px` }}>
              <Document file={pdfFiles.pdf1.url} className="relative" loading="" noData="">
                <Page
                  renderTextLayer={!!querySearch && (pageDisplayed === queryPage || pageDisplayed === queryPage - 1)}
                  width={isDesktop ? viewerSize / 2 - 100 : viewerSize - 100}
                  scale={pdfScale}
                  pageNumber={1}
                  onGetTextSuccess={highlightQueryText}
                />
                {!!pageDisplayed && <div className="absolute w-full h-full top-0 left-0" style={{ boxShadow: 'rgb(0 0 0 / 10%) -20px 0px 25px -5px inset, rgb(0 0 0 / 4%) -10px 0px 10px -5px inset' }} />}
              </Document>
            </div>
            )}
            {!!pdfFiles.pdf2 && (
            <div style={{ width: `${(isDesktop ? viewerSize / 2 - 100 : viewerSize - 100) * pdfScale}px` }}>
              <Document file={pdfFiles.pdf2.url} style={{ width: `${isDesktop ? viewerSize / 2 - 100 : viewerSize - 100}px` }} className="relative" loading="" noData="">
                <Page
                  renderTextLayer={!!querySearch && (pageDisplayed === queryPage || pageDisplayed === queryPage - 1)}
                  width={isDesktop ? viewerSize / 2 - 100 : viewerSize - 100}
                  scale={pdfScale}
                  pageNumber={1}
                  onGetTextSuccess={highlightQueryText}
                />
                <div className="absolute w-full h-full top-0 left-0" style={{ boxShadow: 'rgb(0 0 0 / 10%) 20px 0px 25px -5px inset, rgb(0 0 0 / 4%) 10px 0px 10px -5px inset' }} />
              </Document>
            </div>
            )}
          </div>
          )}
        </div>
      </div>
      <div className="w-full">
        <div className="mx-5 tablet:mx-12 laptop:mx-24 mb-10 flex justify-end">
          <button type="button" onClick={handleBack}>
            <span className="py-6px laptop:py-1 desktop:py-2 text-black hover:text-grey text-base laptop:text-xl text-center font-stratos font-bold whitespace-pre trans inline-block bg-transparent">
              <span className="text-red align-middle">{leftArrowIcon}</span>
              Retroceder
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default PdfMagazineGallery;

PdfMagazineGallery.propTypes = {
  subtitle: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
    pt: PropTypes.string.isRequired,
  }).isRequired,
  pdf: PropTypes.shape().isRequired,
  pages: PropTypes.number.isRequired,
};
