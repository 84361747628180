/* eslint-disable react/forbid-prop-types */

/**
 * Pay with Paypal
*/

/*
Example Paypal response:

details:
  create_time: "2020-12-18T15:34:07Z"
  id: "8SC077979G141391T"
  intent: "CAPTURE"
  links: [{…}]
  payer: {email_address: "mirko+comprador@primaverasound.com", payer_id: "7BEZ7PVMXYBNG", address: {…}, name: {…}}
  purchase_units: [{
    amount: {value: "1.00", currency_code: "EUR"},
    payee: {email_address: "mirko+paypalrdl@primaverasound.com", merchant_id: "PYBUZMZQRVHSA"}
    payments: {captures: Array(1)}
    reference_id: "default"
  }]
  status: "COMPLETED"
  update_time: "2020-12-18T15:34:37Z"

data:
  billingToken: null
  facilitatorAccessToken: "A21AAK6fCiGlQbTXRU-OYjUN-k_17mgFOm0fcX4D4UuSnuHrarAMVxKVO_q8VdXn9T2kkWWzMSN0vr3U-tGNG896iNQn3qu2w"
  orderID: "8SC077979G141391T"
  payerID: "7BEZ7PVMXYBNG"
  paymentID: null
*/
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PayPalButton } from 'react-paypal-button-v2';
import PaypalSubscriptionButton from './PaypalSubscriptionButton';
import PaypalPresentButton from './PaypalPresentButton';
import purchaseWithPaypal from '../../../api/graphql/mutations/purchaseWithPaypal';
import translateObj from '../../../util/translateObj';
import { translations } from '../Login/LoginUtils/onDemandLoginSimpleTexts';
import StandardFormCheckboxConditions from '../Form/StandardFormCheckboxConditions';

const PayWithPaypal = ({
  userContext,
  product,
  resultCallback,
  validateCouponCallback,
  redeemCouponCallback,
  donationProposedAmounts,
  language,
  handleDonationModalStatus,
  couponCode,
  couponValid,
  setCouponCode,
  presentData,
  donationTranslations: { selectAmount: formText = {} } = {},
  conditionsAccepted,
  newAmount,
  validCode,
  translatedTexts,
  defaultConditionsText,
  acceptConditionsCallback,
}) => {
  const [productType, setProductType] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [error, setError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showPayWithPaypal, setShowPayWithPaypal] = useState(false);
  const [showPaypalButton, setShowPaypalButton] = useState(false);

  const { paypal: { clientId, planId } = {} } = product || {};

  const userAlreadySubscribed = userContext.userRoles.includes('rdl-subscription');

  useEffect(() => {
    if (product) {
      setProductType(product.productType);
      if (product.amount) {
        setAmount(product.amount);
      }
      setCurrency(product.currency);
    }
  }, [product]);

  if (userAlreadySubscribed) {
    return (
      <div className="w-full">
        <div className="w-full card-error font-stratos text-16 text-red font-normal mt-3" role="alert">
          {translateObj(translatedTexts.userAlreadySubscribed, language)}
        </div>
      </div>
    );
  }

  return (
    <>
      {showPaypalButton === false && (
        <>
          <div className="mt-3">
            <span className="text-10 font-stratos text-black font-regular uppercase">{translateObj(translatedTexts.havePromotionalCode, language)}</span>
            <input type="text" placeholder={translateObj(translatedTexts.promotionalCode, language).toLowerCase()} onChange={e => setCouponCode(e.target.value)} className={`${error ? 'text-red border-3 border-red' : 'text-black border border-black'} w-full h-12 px-3 font-stratos text-15 font-normal mt-2`} value={couponCode} />
          </div>
          <StandardFormCheckboxConditions
            getCheckBoxValue={acceptConditionsCallback}
            required
            name="conditions"
            acceptText={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.acceptText : defaultConditionsText.acceptText, language)}
            conditionsText={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.conditionsText : defaultConditionsText.conditionsText, language)}
            conditionsLink={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.conditionsLink : defaultConditionsText.conditionsLink, language)}
            dataTreatmentText={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.dataTreatmentText : defaultConditionsText.dataTreatmentText, language)}
            privacyPolicyText={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.privacyPolicyText : defaultConditionsText.privacyPolicyText, language)}
            privacyPolicyUrl={translateObj(translations.hasOwnProperty('conditions') ? translations.conditions.privacyPolicyUrl : defaultConditionsText.privacyPolicyUrl, language)}
            validationError=""
          />
        </>
      )}

      {/* {productType === 'donation' && (
        <>
          <label className="flex flex-col font-stratos" htmlFor="donationAmount">
            {translateObj(formText.howMuchText, language)}
            <div className="w-full flex">
              {(donationProposedAmounts && donationProposedAmounts.length) && donationProposedAmounts.map(donationAmount => <button key={donationAmount} className={`p-4 ${amount === donationAmount ? 'bg-black text-white' : 'bg-gray200'} sm:p-2 mx-8 sm:mx-2`} type="button" onClick={() => { setAmount(donationAmount); }}>{donationAmount}</button>)}
            </div>
            <input name="donationAmount" type="text" className="mt-4 rounded-full border-1 p-4 bg-inherit border-black text-black false border-black text-black" value={amount} onChange={newValue => setAmount(newValue.currentTarget.value)} />
          </label>
          <div className="underline cursor-pointer" onClick={() => { handleDonationModalStatus('hasMultiplePaymentMethods'); }}>{translateObj(formText.changeCard, language)}</div>
        </>
      )} */}

      <div className="relative">
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="absolute w-full card-error font-stratos text-14 text-red font-normal mt-3" role="alert">
            {error.replace('Error:', '')}
          </div>
        )}
      </div>
      { showPaypalButton && amount && currency && clientId && planId && (
        <PaypalSubscriptionButton
          clientId={clientId}
          currency={currency}
          planId={planId}
          amount={amount}
          product={product}
          resultCallback={resultCallback}
          setShowPayWithPaypal={setShowPayWithPaypal}
          couponCode={couponCode}
          presentData={presentData}
          setSucceeded={setSucceeded}
          setProcessing={setProcessing}
          setError={setError}
        />
      )}
      { showPaypalButton && amount && currency && clientId && presentData && presentData.presentDestinationEmail && (
        <PaypalPresentButton
          clientId={clientId}
          currency={currency}
          planId={planId}
          amount={amount}
          product={product}
          resultCallback={resultCallback}
          setShowPayWithPaypal={setShowPayWithPaypal}
          couponCode={couponCode}
          presentData={presentData}
          setSucceeded={setSucceeded}
          setProcessing={setProcessing}
          setError={setError}
        />
      )}
      {/* <div className="flex flex-row w-full justify-between items-center mt-12">
        <button type="button" onClick={() => resultCallback({ status: 'cancel' })} className="text-grey text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-grey bg-beige">
          {translateObj({ en: 'Cancelar', es: 'Cancelar' }, language)}
        </button>
      </div> */}

      <div className="flex flex-row w-full justify-between items-center mt-4">
        <button type="button" onClick={() => resultCallback({ status: 'cancel' })} className="text-grey text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-grey bg-beige">
          {translateObj({ en: 'Cancelar', es: 'Cancelar' }, language)}
        </button>
        {showPaypalButton === false && (
          <button type="button" className={`text-14 font-bold font-stratos px-4 w-32 h-9 border-6 ${conditionsAccepted ? 'border-red text-red' : 'border-grey text-grey bg-beige'} disabled={processing || !conditionsAccepted}`} onClick={() => conditionsAccepted && setShowPaypalButton(true)}>
            {translateObj({ en: 'Continuar', es: 'Continuar' }, language)}
          </button>
        )}
      </div>
      {couponCode && !couponValid && (
        <button type="button" className={`${processing || !conditionsAccepted ? 'text-gray200 border-gray200 cursor-not-allowed' : 'text-red border-red'} text-14 font-bold font-stratos px-4 w-32 h-9 border-6`} disabled={processing || !conditionsAccepted} onClick={validateCouponCallback}>
          {couponCode.length > 0 && !couponValid && (couponCode !== validCode)
            ? translateObj({ en: 'Validar', es: 'Validar' }, language)
            : ''
            }
        </button>
      )}
    </>
  );
};

PayWithPaypal.propTypes = {
  // userContext: PropTypes.objectOf(PropTypes.object()).isRequired,
  userContext: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  resultCallback: PropTypes.func.isRequired,
  validateCouponCallback: PropTypes.func.isRequired,
  redeemCouponCallback: PropTypes.func.isRequired,
  donationProposedAmounts: PropTypes.arrayOf(PropTypes.number),
  language: PropTypes.string,
  handleDonationModalStatus: PropTypes.func,
  couponCode: PropTypes.string,
  setCouponCode: PropTypes.func.isRequired,
  couponValid: PropTypes.bool,
  translatedTexts: PropTypes.object.isRequired,
  defaultConditionsText: PropTypes.object.isRequired,
  presentData: PropTypes.shape({
    presentDestinationName: PropTypes.string,
    presentDestinationEmail: PropTypes.string,
    presentDeliveryDate: PropTypes.instanceOf(Date),
    presentMessage: PropTypes.string,
  }),
  conditionsAccepted: PropTypes.bool,
  validCode: PropTypes.string,
  newAmount: PropTypes.number,
  acceptConditionsCallback: PropTypes.func.isRequired,
};

PayWithPaypal.defaultProps = {
  donationProposedAmounts: [],
  language: 'en',
  handleDonationModalStatus: null,
  couponCode: '',
  couponValid: false,
  presentData: null,
  conditionsAccepted: false,
  validCode: '',
  newAmount: null,
};

export default PayWithPaypal;
