import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import UserMenuPopup from './UserMenuPopup';
import { UserProfileContextConsumer } from '../../context/userProfileContext';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

const LoginHandler = ({ match }) => {
  const [ssrDone, setSsrDone] = useState(false);
  const [activeLoginMenu, setActiveLoginMenu] = useState(false);

  function handleLoginMenuEvent(event) {
    if (event.detail.close === false) {
      setActiveLoginMenu(true);
    } else {
      setActiveLoginMenu(false);
    }
  }

  useEffect(() => {
    setSsrDone(true);
  }, []);

  useEffect(() => {
    if (ssrDone && typeof window !== 'undefined') {
      window.addEventListener('loginMenuEvent', handleLoginMenuEvent);
    }

    return () => {
      window.removeEventListener('loginMenuEvent', handleLoginMenuEvent);
    };
  }, [ssrDone]);

  return (
    <UserProfileContextConsumer>
      {({
        userName, userLastName, token, loadTokenForLS,
      }) => (
        <RoutesContext.Consumer>
          {({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            const ButtonLink = ({ link }) => (
              <span className="flex items-center justify-center w-4 tablet:w-8 h-8 -mb-px uppercase font-stratos mx-2 tablet:mx-0">
                {link.icon && <span className={`${link.icon} font-icon text-black text-18 tablet:text-20 laptop:text-30`} />}
              </span>
            );

            ButtonLink.propTypes = {
              link: PropTypes.shape().isRequired,
            };

            return (
              activeLoginMenu && (
                <div key="activeLoginMenuWrapper">
                  <UserMenuPopup
                    ButtonLink={ButtonLink}
                    // link={link}
                    localization={localization}
                    userName={userName}
                    userLastName={userLastName}
                    token={token}
                    language={localization.language}
                    loadTokenForLS={loadTokenForLS}
                    // history={this.props.history}
                    // formText={link.popUpFormText}
                    // sourceWeb={link.sourceWeb}
                  />
                </div>
              ));
          }}
        </RoutesContext.Consumer>
      )}
    </UserProfileContextConsumer>
  );
};

LoginHandler.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default withRouter(LoginHandler);
