import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

const ArticlesCounterPopoup = ({
  consumedArticles,
  closeCallback,
}) => (
  <div className="fixed pin-x pin-b py-4 laptop:py-8 bg-red z-top flex items-center justify-between">
    <div className="font-stratos text-white text-15 tablet:text-17 laptop:text-22 leading-23 tablet:leading-25 laptop:leading-30 font-normal flex items-center justify-start tablet:ml-8 laptop:ml-12">
      {consumedArticles === 1 && (
        <span className="font-icon icon-caution-circle mr-3 -ml-2 tablet:-ml-6 laptop:-ml-8" />
      )}
      <div className="flex flex-col laptop:flex-row">
        <span>
          {'Te quedan '}
          <span className="font-bold">{`${consumedArticles} artículos`}</span>
          {' por leer este mes'}
        </span>
        <span className="px-3 hidden laptop:block">|</span>
        <span>
          <Link
            className="font-bold mr-1 text-white underline hover:no-underline"
            to="/suscribirse"
          >
            Suscríbete
          </Link>
          {' si quieres tener acceso ilimitado'}
        </span>
      </div>
    </div>
    <span className="font-icon icon-close text-white text-15 tablet:text-17 laptop:text-22 leading-23 tablet:leading-25 laptop:leading-30 tablet:mr-8 laptop:mr-12 cursor-pointer" onClick={() => closeCallback()} />
  </div>
);

ArticlesCounterPopoup.propTypes = {
  consumedArticles: PropTypes.number.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

ArticlesCounterPopoup.defaultProps = {
};

export default withRouter(ArticlesCounterPopoup);
