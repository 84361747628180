import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Link from '../../util/link';
import translateObj from '../../util/translateObj';


const styles = theme => ({
  paper: {
    // right: 0,
    // left: "auto !important",
  },
});

const formTextInComponent = {
  submitTitle: {
    en: 'sign in',
    es: 'iniciar sesión',
    ca: 'iniciar sessió',
    pt: 'iniciar sessāo',
  },
  registerPreTitle: {
    es: '¿No tienes cuenta?',
    en: 'Don\'t have an account?',
    ca: 'No tens compte?',
    pt: 'Não tem conta?',
  },
  registerTitle: {
    es: 'Registrate',
    en: 'Register',
    ca: 'Registra"t',
    pt: 'Registe-se',
  },

  faqsTitle: {
    es: 'AYUDA',
    en: 'HELP',
    ca: 'AJUDA',
    pt: 'AJUDA',
  },
  logoutTitle: {
    es: 'DESCONECTATE',
    en: 'LOG OUT',
    ca: 'DESCONECTA"T',
    pt: 'LOG OUT',
  },
  favoritesTitle: {
    es: 'FAVORITOS',
    en: 'FAVORITES',
    ca: 'FAVORITS',
    pt: 'FAVORITES',
  },
  userProfileTitle: {
    es: 'PERFIL DE USUARIO',
    en: 'USER PROFILE',
    ca: 'PERFIL D"USUARI',
    pt: 'USER PROFILE',
  },
};

class UserMenuPopup extends Component {
  state = {
    anchorEl: null,
    // link: {},
  };

  // popover user logic

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = (lang, loadTokenForLS, sourceWeb = '') => {
    document.cookie = `${process.env.AUTH_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; HttpOnly`;
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(process.env.AUTH_TOKEN);
      loadTokenForLS();
      this.handleClose();
      this.props.history.push(`/${lang}${sourceWeb && `/${sourceWeb}`}`);
    }
  }

  render() {
    const {
      classes, ButtonLink, link, localization, userName, token, language, loadTokenForLS, border, formText, sourceWeb,
    } = this.props;
    const { anchorEl } = this.state;

    // popover logic in render
    const open = Boolean(anchorEl);
    return (
      <>
        <div className="hidden sm:block md:block">
          <Link to={`${link.link}`} localizationpath={`${localization.path}`} className="no-underline flex">
            <ButtonLink link={link} border={border} localization={localization} />
          </Link>
        </div>
        <div
          // aria-describedby={id}
          // onClick={this.handleClick}
          className="cursor-pointer sm:hidden md:hidden"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handleClick}
          onMouseLeave={this.handleClose}
        >
          <ButtonLink link={link} localization={localization} border={border} />
          <Popover
            id="mouse-over-popover"
            // id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ paper: classes.paper }}
            disableRestoreFocus
          >

            <div className="bg-black text-white p-8 max-w-xs">
              {userName && token
                ? (
                  // <div className="w-s15">
                  //   <span className="text-white">{`${userName} ${userLastName}`}</span>
                  // </div>
                  <>

                    <div className={`${!sourceWeb && 'border-b-1 border-white border-dotted'} -mx-5`}>
                      <div className=" border-white border-b-1 border-dotted py-4 mx-5">
                        <Link to={`/user-profile${sourceWeb && `-${sourceWeb}`}`} localizationpath={`${localization.path}`}>
                          <div className="text-white font-americaMonoRegular uppercase text-12 cursor-pointer flex justify-between">
                            <span className="pr-20" onClick={this.handleClose}>{translateObj(formText.userProfileTitle, language)}</span>
                            <span>></span>
                          </div>
                        </Link>
                      </div>
                      {!sourceWeb && (
                      <div className=" py-4 mx-5">
                        <Link to="/user-favorites" localizationpath={`${localization.path}`}>
                          <div className="text-white font-americaMonoRegular uppercase text-12 cursor-pointer flex justify-between">
                            <span className="pr-20" onClick={this.handleClose}>{translateObj(formText.favoritesTitle, language)}</span>
                            <span>></span>
                          </div>
                        </Link>
                      </div>
                      )}
                    </div>

                    <div className="pt-4">
                      <button
                        className="font-americaMonoRegular uppercase text-black bg-red w-full text-center px-4 py-2 rounded-full tracking-submenu px-4 text-12 cursor-pointer"
                        onClick={() => {
                          this.handleLogout(language, loadTokenForLS, sourceWeb);
                        }}
                      >
                        {translateObj(formText.logoutTitle, language)}
                      </button>
                    </div>

                  </>
                )
                : (
                  <>
                    <Link to={`/login-screen${sourceWeb && `-${sourceWeb}`}?e=sign-in`} localizationpath={`${localization.path}`} className="no-underline flex">
                      <div className="w-full">
                        <button className="font-americaMonoRegular uppercase text-black bg-white w-full text-center p-4 rounded-full tracking-submenu px-4 text-12 cursor-pointer" onClick={this.handleClose}>
                          {translateObj(formText.submitTitle, language)}
                        </button>
                      </div>
                    </Link>
                    <Link to={`/login-screen${sourceWeb && `-${sourceWeb}`}?e=register`} localizationpath={`${localization.path}`}>
                      <div className="font-americaMonoRegular text-12 font-underlined cursor-pointer pb-4 pt-2 text-center flex text-white" onClick={this.handleClose}>
                        <span>
                          {translateObj(formText.registerPreTitle, language)}
                        </span>
                        {' '}
                        <span className="pl-4 underline">
                          {translateObj(formText.registerTitle, language)}
                        </span>
                      </div>
                    </Link>
                    {/* <div className="border-t-1 border-white border-dotted -mx-5">
                      <div className=" border-white border-b-1 border-dotted py-4 mx-5 cursor-pointer"> */}
                    {/* <Link to="/barcelona/primavera-sound-2020-barcelona-frequently-asked-questions-faq" localizationpath={`${localization.path}`}>
                          <div className="flex justify-between align-center cursor-pointer text-white font-americaMonoRegular uppercase text-12">
                            <span className="" onClick={this.handleClose}>{translateObj(formText.faqsTitle, language)}</span>
                            <span>></span>

                          </div>
                        </Link> */}
                    {/* </div>
                    </div> */}
                  </>

                )
              }
            </div>
          </Popover>
        </div>

      </>
    );
  }
}

UserMenuPopup.propTypes = {
  border: PropTypes.string,
  sourceWeb: PropTypes.string,
  link: PropTypes.shape(),
  localization: PropTypes.shape(),
  userName: PropTypes.string,
  userLastName: PropTypes.string,
  token: PropTypes.string,
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  ButtonLink: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  formText: PropTypes.shape(),
};

UserMenuPopup.defaultProps = {
  border: '',
  sourceWeb: '',
  link: {},
  localization: {},
  userName: '',
  userLastName: '',
  token: '',
  language: 'en',
  formText: formTextInComponent,
};

export default withStyles(styles)(UserMenuPopup);


// import React, { Component } from "react";
// import { withStyles } from "@material-ui/core/styles";
// import Popover from "@material-ui/core/Popover";
// import Link from "../../util/link";
// import translateObj from "../../util/translateObj";


// const styles = theme => ({
//   paper: {
//     right: 0,
//     left: "auto !important",
//   },
// });

// const submitTitle = {
//   es: "ACCEDER",
//   en: "SIGN IN",
//   ca: "ACCEDIR",
//   pt: "Entrar",
// };
// const registerTitle = {
//   es: "REGISTRATE",
//   en: "REGISTER",
//   ca: "REGISTRA\"T",
//   pt: "REGISTER",
// };

// const faqsTitle = {
//   es: "AYUDA",
//   en: "FAQs",
//   ca: "AJUDA",
//   pt: "FAQS",
// };
// const logoutTitle = {
//   es: "DESCONECTATE",
//   en: "LOG OUT",
//   ca: "DESCONECTA\"T",
//   pt: "LOG OUT",
// };
// const favoritesTitle = {
//   es: "FAVORITOS",
//   en: "FAVORITES",
//   ca: "FAVORITS",
//   pt: "FAVORITES",
// };
// const userProfileTitle = {
//   es: "PERFIL DE USUARIO",
//   en: "USER PROFILE",
//   ca: "PERFIL D\"USUARI",
//   pt: "USER PROFILE",
// };

// class UserMenuPopup extends Component {
//   state = {
//     anchorEl: null,
//   };

//   // popover user logic

//   handleClick = (event) => {
//     this.setState({ anchorEl: event.currentTarget });
//   };

//   handleClose = () => {
//     this.setState({ anchorEl: null });
//   };

//   handleLogout = (lang, loadTokenForLS) => {
//     document.cookie = `${process.env.AUTH_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
//     if (typeof localStorage !== "undefined") {
//       localStorage.removeItem(process.env.AUTH_TOKEN);
//       this.props.history.push(`/${lang}`);
//       loadTokenForLS();
//     }
//   }

//   render() {
//     const {
//       classes, ButtonLink, link, localization, userName, userLastName, token, language, loadTokenForLS,
//     } = this.props;
//     const { anchorEl } = this.state;

//     // popover logic in render
//     const open = Boolean(anchorEl);
//     return (
//       <>
//         <div
//           // aria-describedby={id}
//           // onClick={this.handleClick}
//           className="cursor-pointer"
//           aria-owns={open ? "mouse-over-popover" : undefined}
//           aria-haspopup="true"
//           onMouseEnter={this.handleClick}
//           onMouseLeave={this.handleClose}
//         >
//           <ButtonLink link={link} localization={localization} />
//           <Popover
//             id="mouse-over-popover"
//             // id={id}
//             open={open}
//             anchorEl={anchorEl}
//             onClose={this.handleClose}
//             anchorOrigin={{
//               vertical: "bottom",
//               horizontal: "right",
//             }}
//             transformOrigin={{
//               vertical: "top",
//               horizontal: "right",
//             }}
//             classes={{ paper: classes.paper }}
//             disableRestoreFocus
//           >

//             <div className="bg-black text-white p-8 ">
//               {userName && token
//                 ? (
//                   // <div className="w-s15">
//                   //   <span className="text-white">{`${userName} ${userLastName}`}</span>
//                   // </div>
//                   <>

//                     <div className="border-t-1 border-white border-dotted -mx-5">
//                       <div className=" border-white border-b-1 border-dotted py-4 mx-5">
//                         <Link to="/user-profile">
//                           <span className="text-white font-americaMonoRegular uppercase text-12" onClick={this.handleClose}>{translateObj(userProfileTitle, language)}</span>
//                         </Link>
//                       </div>
//                       <div className=" border-white border-b-1 border-dotted py-4 mx-5">
//                         <Link to="/">
//                           <span className="text-white font-americaMonoRegular uppercase text-12" onClick={this.handleClose}>{translateObj(favoritesTitle, language)}</span>
//                         </Link>
//                       </div>
//                     </div>
//                     <Link to="/login-screen?e=sign-in" localizationpath={`${localization.path}`} className="no-underline flex">
//                       <div className="w-s15 pt-4">
//                         <button className="font-americaMonoRegular uppercase text-black bg-red w-full text-center px-4 py-2 rounded-full tracking-submenu px-4 text-12 cursor-pointer" onClick={() => {
//                           this.handleClose()
//                           this.handleLogout(language, loadTokenForLS)
//                           }}>
//                           {translateObj(logoutTitle, language)}
//                         </button>
//                       </div>
//                     </Link>
//                   </>
//                 )
//                 : (
//                   <>
//                     <Link to="/login-screen?e=sign-in" localizationpath={`${localization.path}`} className="no-underline flex">
//                       <div className="w-s15">
//                         <button className="font-americaMonoRegular uppercase text-black bg-white w-full text-center p-4 rounded-full tracking-submenu px-4 text-12 cursor-pointer" onClick={this.handleClose}>
//                           {translateObj(submitTitle, language)}
//                         </button>
//                       </div>
//                     </Link>
//                     <Link to="/login-screen?e=register">
//                       <div className="font-americaMonoRegular uppercase text-12 font-underlined cursor-pointer pb-4 pt-2 text-center" onClick={this.handleClose}>
//                         <span className="text-white underline">
//                           {translateObj(registerTitle, language)}
//                         </span>
//                       </div>
//                     </Link>
//                     <div className="border-t-1 border-white border-dotted -mx-5">
//                       <div className=" border-white border-b-1 border-dotted py-4 mx-5">
//                         <Link to="/barcelona/primavera-sound-2020-barcelona-frequently-asked-questions-faq">
//                           <span className="text-white font-americaMonoRegular uppercase text-12" onClick={this.handleClose}>{translateObj(faqsTitle, language)}</span>
//                         </Link>
//                       </div>
//                     </div>
//                   </>

//                 )
//               }
//             </div>
//           </Popover>
//         </div>

//       </>
//     );
//   }
// }

// export default withStyles(styles)(UserMenuPopup);
