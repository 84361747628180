import React from 'react';
import { PropTypes } from 'prop-types';
import BannersRowItem from './BannersRowItem';

const BannersRow = ({ items }) => {
  const multiple = items.length > 2;
  return (
    <div
      className={`gap-x-4 flex items-start px-5 mb-12 tablet:flex-row items-stretch justify-between tablet:gap-x-0 tablet:mb-16 laptop:px-20 laptop:mb-24 full-width ${
        multiple ? 'flex-wrap flex-row' : 'flex-col'
      }`}
    >
      {items.map((item, index) => (
        <div
          key={`BannersRowItem-${index + 1}`}
          className={`pb-8 tablet:pb-42px tablet:w-1/2 tablet:px-4 laptop:pb-0 ${
            multiple
              ? `laptop:flex-1 laptop:min-w-1/4 laptop:w-auto ${items.length === 4 ? 'w-48/100' : ''}`
              : 'w-full laptop:w-1/2'
          }`}
        >
          <BannersRowItem
            color={item.color}
            bgImage={item.bgImage}
            preTitle={item.preTitle}
            title={item.title}
            text={item.text}
            link={item.link}
            bannerClick={item.bannerClick}
            overlayImage={null}
            desktopVideoUrl={item.desktopVideoUrl}
          />
        </div>
      ))}
    </div>
  );
};

export default BannersRow;

BannersRow.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
};
