import React from 'react';
import PropTypes from 'prop-types';

const TopHeaderMegamenuFooter = ({ children, name }) => (
  <div
    id={name}
    data-name="megamenu-footer"
    className="relative flex flex-col items-stretch justify-start"
  >
    <div className="w-full bg-red" style={{ height: '2px' }} />
    {children}
  </div>
);

TopHeaderMegamenuFooter.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
};

export default TopHeaderMegamenuFooter;
