/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import translateObj from '../../../util/translateObj';
// import getLocalization from '../../../util/localization';
import RoutesContext from '../../../context/routesContext';
import imageModifier from '../helpers/imageModifier';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import LauncherItemSingle from './LauncherItemSingle';
import SubscriptionTag from '../SubscriptionTag';

const LauncherCarousel = ({
  items, news, defaultSingleItems,
}) => {
  const responsiveUtility = useRef();
  const { language } = useContext(RoutesContext);
  const [deviceScreen, setDeviceScreen] = useState();
  const [loading, setLoading] = useState(true);
  const [squareBigWidth, setSquareBigWidth] = useState();
  const [squareSmallWidth, setSquareSmallWidth] = useState();
  const [rectangleWidth, setRectangleWidth] = useState();
  const [newsItemWidth, setNewsItemWidth] = useState();
  const [newsItemHeight, setNewsItemHeight] = useState();
  const [singleItemWidth, setSingleItemWidth] = useState();
  const [slideOffset, setSlideOffset] = useState();
  const [spaceBetweenItems, setSpaceBetweenItems] = useState();

  const handleResize = (device) => {
    setDeviceScreen(device);
  };

  const handleSizes = (device) => {
    const isMobileNotNews = device === 'mobile' && !news;

    switch (device) {
      case 'mobile':
        setSquareBigWidth(213);
        setSquareSmallWidth(213);
        setRectangleWidth(341);
        setNewsItemWidth(114);
        setNewsItemHeight(162);
        setSingleItemWidth(243);
        if (isMobileNotNews) {
          setSlideOffset(0);
        } else {
          setSlideOffset(32);
        }
        if (defaultSingleItems === true) {
          setSpaceBetweenItems(10.9);
        } else if (news === true) {
          setSpaceBetweenItems(35);
        } else {
          setSpaceBetweenItems(17);
        }
        break;
      case 'tablet':
        setSquareBigWidth(240);
        setSquareSmallWidth(150);
        setRectangleWidth(240);
        setNewsItemWidth(159);
        setNewsItemHeight(211);
        setSingleItemWidth(300);
        if (isMobileNotNews) {
          setSlideOffset(0);
        } else {
          setSlideOffset(42);
        }
        if (defaultSingleItems === true) {
          setSpaceBetweenItems(25.7);
        } else if (news === true) {
          setSpaceBetweenItems(35);
        } else {
          setSpaceBetweenItems(17);
        }
        break;
      case 'laptop':
        setSquareBigWidth(258);
        setSquareSmallWidth(240);
        setRectangleWidth(348);
        setNewsItemWidth(204);
        setNewsItemHeight(260);
        setSingleItemWidth(358);
        if (isMobileNotNews) {
          setSlideOffset(0);
        } else {
          setSlideOffset(96);
        }
        if (defaultSingleItems === true) {
          setSpaceBetweenItems(24.6);
        } else if (news === true) {
          setSpaceBetweenItems(35);
        } else {
          setSpaceBetweenItems(17);
        }
        break;
      case 'desktop':
        setSquareBigWidth(312);
        setSquareSmallWidth(240);
        setRectangleWidth(348);
        setNewsItemWidth(207);
        setNewsItemHeight(260);
        setSingleItemWidth(400);
        if (isMobileNotNews) {
          setSlideOffset(0);
        } else {
          setSlideOffset(96);
        }
        if (defaultSingleItems === true) {
          setSpaceBetweenItems(27.5);
        } else if (news === true) {
          setSpaceBetweenItems(35);
        } else {
          setSpaceBetweenItems(17);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const device = responsiveUtility.current.deviceScreen(true);
    handleResize(device);
    setLoading(true);
    handleSizes(device);
    setLoading(false);
  }, []);

  useEffect(() => {
    handleSizes(deviceScreen);
  }, [deviceScreen]);

  const premiumLogo = item => <img src={imageModifier(item.overlayImage, '45x45')} alt="Premium content" />;

  const renderItem = (item, index, layout) => {
    const isMagazine = layout === 'magazine';
    if (layout === 'discos' || isMagazine) {
      const imageDimensions = isMagazine ? '484x624' : '900x900';
      return (
        <Link key={`launcherCarouselItem-${item.url}`} className={isMagazine ? 'pb-5' : ''} to={item.url} style={{ height: 'auto', width: squareBigWidth }}>
          {item.featured && (
          <div className="absolute px-5 mr-6 bg-white border-8 border-solid border-red pin-r">
            <span className="text-14 tablet:text-base laptop:text-base desktop:text-18 font-bold text-red uppercase font-stratos">#1 RDL 1988</span>
          </div>
          )}
          {item.overlayImage && (
            <div className="absolute pin-t pin-r mt-7 mr-2">
              {premiumLogo(item)}
            </div>
          )}
          <div className="relative mt-4 mb-5">
            <SubscriptionTag className="border-b-5 text-black" isAbsolute rolesAllowed={item.rolesAllowed} />
            <img src={imageModifier(translateObj(item.image, language), imageDimensions)} alt={translateObj(item.title, language)} />
          </div>
          <div className="mb-2 font-bold text-black font-stratos text-18 leading-22">{translateObj(item.title, language)}</div>
          <div className="text-base font-normal leading-20 text-black font-stratos">{translateObj(item.subtitle, language)}</div>
          {item.featured && <span className="font-light text-white uppercase font-stratos text-12 laptop:text-base mt-14">DISCO DESTACADO</span>}
        </Link>
      );
    }
    if (layout === 'curatedList') {
      return (
        <Link key={`launcherCarouselItem-${item.url}`} to={item.url} style={{ minHeight: 'auto', width: squareSmallWidth }}>
          {item.image.hasOwnProperty('en') && item.image.en !== '' ? (
            <img className="mb-5" src={imageModifier(translateObj(item.image, language), '900x900')} alt={translateObj(item.title, language)} />
          ) : (
            <div className="mb-5 w-full bg-grey" style={{ paddingBottom: '100%' }} />
          )}
          <div className="font-stratos mb-2 text-18 leading-22 text-red font-bold">{translateObj(item.title, language)}</div>
          <div className="font-stratos text-base text-black font-normal leading-20">{translateObj(item.subtitle, language)}</div>
          {item.overlayImage && (
            <div className="absolute pin-t pin-r mt-2 mr-2">
              {premiumLogo(item)}
            </div>
          )}
        </Link>
      );
    }
    if (layout === 'noticias') {
      const authorNames = item.authors && item.authors.map(author => author.name).join(', ');
      return (
        <Link key={`launcherCarouselItem-${item.url}`} to={item.url} style={{ minHeight: newsItemHeight, width: newsItemWidth }} className="flex flex-col items-start justify-between pl-4 border-l border-solid border-black">
          {item.overlayImage && (
            <div className="absolute pin-t pin-r mt-2">
              {premiumLogo(item)}
            </div>
          )}
          <div>
            <div className="mb-5 font-bold font-stratos text-13 tablet:text-base text-red">{item.date.replace(/\//g, '.')}</div>
            <div className="text-base font-normal text-black font-stratos tablet:text-24 tablet:font-bold">{translateObj(item.title, language)}</div>
          </div>
          {authorNames && <div className="font-light font-stratos text-12 tablet:text-14 text-grey">{`Por ${authorNames}`}</div>}
        </Link>
      );
    }
    if (layout === 'smallPictures') {
      return (
        <Link key={`launcherCarouselItem-${item.url}`} to={item.url} style={{ height: 'auto', width: rectangleWidth }}>
          {item.overlayImage && (
            <div className="absolute pin-t pin-r mt-2 mr-2">
              {premiumLogo(item)}
            </div>
          )}
          <img className="mb-5" src={imageModifier(translateObj(item.image, language), '1440x900')} alt={translateObj(item.title, language)} />
          <div className="mb-5 font-normal font-stratos text-18 text-red">{translateObj(item.title, language)}</div>
          <div className="text-base font-light text-black font-stratos">{translateObj(item.subtitle, language)}</div>
        </Link>
      );
    }
    if (layout === 'smallPicturesTextInWhite') {
      return (
        <Link key={`launcherCarouselItem-${item.url}`} to={item.url} style={{ height: 'auto', width: rectangleWidth }}>
          {item.overlayImage && (
            <div className="absolute pin-t pin-r mt-2 mr-2">
              {premiumLogo(item)}
            </div>
          )}
          <img className="mb-5" src={imageModifier(translateObj(item.image, language), '1440x900')} alt={translateObj(item.title, language)} />
          <div className="mb-5 font-normal font-stratos text-18 text-white">{translateObj(item.title, language)}</div>
          <div className="text-base font-light text-white font-stratos">{translateObj(item.subtitle, language)}</div>
        </Link>
      );
    }
    return (
      <div key={`launcherCarouselItem-${index}`} style={{ height: 'auto', width: singleItemWidth }}>
        <LauncherItemSingle {...item} position={index} key={`launcherCarouselItem-${index}`} />
      </div>
    );
  };

  const isMobileNotNews = deviceScreen === 'mobile' && !news;

  const params = {
    slidesPerView: 'auto',
    spaceBetween: spaceBetweenItems,
    keyboard: true,
    grabCursor: true,
    slidesOffsetBefore: slideOffset,
    slidesOffsetAfter: slideOffset,
    centeredSlides: isMobileNotNews,
    loop: isMobileNotNews,
    updateOnWindowResize: true,
    shouldSwiperUpdate: true,
  };


  return (
    <div className="full-width">
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={handleResize} rdlSizes />
      {!loading && (
        <div
          className={`h-auto w-screen ${news ? 'pt-4 laptop:pt-8' : ''}`}
        >
          <div className="w-full h-auto">
            <Swiper
              {...params}
            >
              {items.map((item, index) => (
                renderItem(item, index, item.layout, item.rolesAllowed)))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

LauncherCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  news: PropTypes.bool,
  defaultSingleItems: PropTypes.bool,
  rolesAllowed: PropTypes.arrayOf(PropTypes.string),
};

LauncherCarousel.defaultProps = {
  items: null,
  news: false,
  defaultSingleItems: false,
  rolesAllowed: [],
};

export default LauncherCarousel;
