/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { propTypes, withFormsy } from 'formsy-react';

class FormCheckboxConditions extends Component {
  state = {
    value: false,
  };

  shouldComponentUpdate = (nextProps) => {
    const { value } = this.state;

    if (nextProps.getValue() !== value) {
      return true;
    }
    return false;
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { getValue } = this.props;

    if (prevProps !== this.props && getValue() !== undefined) {
      if (prevState.value !== getValue()) {
        this.setState({
          value: !prevState.value,
        });
      }
    }
  };

  changeValue = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.checked);

    this.setState(prevState => ({
      value: !prevState.value,
    }));
  };

  render() {
    const {
      acceptText, conditionsText, conditionsLink = '', dataTreatmentText, required = false, privacyPolicyText, name, privacyPolicyUrl = '',
    } = this.props;
    const { value } = this.state;

    return (
      <div className="flex items-start justify-between pt-6 -ml-2">
        <Checkbox
          icon={<div className="border-1 border-black h-7 w-7" />}
          checkedIcon={<div className="border-1 border-black h-7 w-7 flex items-center justify-center"><span className="font-icon icon-close text-base font-bold text-red p-1" /></div>}
          onChange={this.changeValue}
          id={name}
          checked={value}
          data-checked={value}
          style={{ color: `${value ? '#FF4646' : 'black'}` }}
        />
        <span className="ml-3 mt-2 font-stratos text-14 font-light leading-snug">
          {/* {required && <span className="text-12 align-top text-red-light">* </span>} */}
          {`${acceptText} `}
          {conditionsText && conditionsLink ? <Link className="text-black cursor-pointer" to={conditionsLink}>{conditionsText}</Link> : '' }
          {`${dataTreatmentText} `}
          {privacyPolicyText && privacyPolicyUrl ? <Link className="text-black cursor-pointer" to={privacyPolicyUrl}>{privacyPolicyText}</Link>
            : '' }
        </span>
      </div>
    );
  }
}

FormCheckboxConditions.propTypes = {
  ...propTypes,
};

export default withFormsy(FormCheckboxConditions);
