import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import translateObj from '../../../../util/translateObj';
import Container from './Container';
import { useChat } from '../context/chatContext';
import { useTheme } from '../context/themeContext';
import { CHAT, QUESTIONS, getStringSet } from '../utils/constants';
import Tabs from './Tabs';
import Tab from './Tab';
import UserList from './UserList';
import ChannelList from './ChannelList';
import { useRoutes } from '../../../../context/routesContext';
import { useChatVisibility } from '../context/chatVisibilityContext';

const ACTIVE_DEFAULT = CHAT;

const ChannelToggler = ({
  match, streamContentHeight, device, shouldInitPrivateChat, chatRules,
}) => {
  const [shouldToggle, setShouldToggle] = useState(false);
  const [active, setActive] = useState(ACTIVE_DEFAULT);
  const { theme, color } = useTheme();
  const {
    channelGeneral, channelQuestions, userData, getActiveUsers,
  } = useChat();
  const { isFullscreen } = useChatVisibility();
  const { language } = useRoutes(match);
  const stringSet = getStringSet(language);
  const [activeIndex, setActiveIndex] = useState(0);
  const [styles, setStyles] = useState({});
  const [isVisibleChatRules, setChatrulesVisibility] = useState(true);

  const getStyles = () => {
    if (device === 'sm' && streamContentHeight) {
      if (isFullscreen) {
        setStyles({
          height: `calc(100% - ${streamContentHeight - 17}px`,
        });
      } else {
        setStyles({
          height: `calc(100% - ${streamContentHeight - 17 + 105}px`,
        });
      }
    } else {
      setStyles({});
    }
  };
  useEffect(() => {
    getStyles();
  }, [streamContentHeight]);

  useEffect(() => {
    getStyles();
  }, [isFullscreen]);

  useEffect(() => {
    if (channelGeneral && channelQuestions) {
      setActive(ACTIVE_DEFAULT);
      setShouldToggle(true);
    }
    if (channelQuestions && !channelGeneral) setActive(QUESTIONS);
  }, [channelGeneral, channelQuestions]);

  useEffect(() => {
    if (channelGeneral && userData) {
      if (activeIndex === 2) {
        getActiveUsers();
      }
    }
  }, [userData, channelGeneral, activeIndex]);

  return (
    <div className="chat-sidebar md:w-chat lg:w-chat xl:w-chat flex flex-col w-full fixed pin-r pin-b justify-between" style={styles}>
      <nav className={`sm:hidden py-5 h-8 flex justify-center items-center bg-${theme}-box text-${color}`}>
        <h3 className="font-americaMonoRegular text-xs uppercase">{active !== CHAT ? 'QA' : 'Chat'}</h3>
      </nav>
      <Tabs activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
        <Tab label={stringSet.MAIN_TAB}>
          <div className={`${active !== CHAT ? 'hidden' : 'bg-black flex flex-col justify-between h-94%'}`}>
            {chatRules && chatRules.show && isVisibleChatRules ? (
              <div className="px-4 w-full sm:hidden md:hidden">
                <div className="leding-normal rules-card my-2 px-2 rounded uppercase w-full font-americaMonoRegular py-5 bg-pro-online relative">
                  <div
                    className="absolute pin-t pin-r text-white font-icon icon-close cursor-pointer mr-1 mt-1 z-10 text-xxs"
                    onClick={() => setChatrulesVisibility(false)}
                  />
                  {parse(translateObj(chatRules, language))}
                </div>
              </div>
            ) : null }
            {channelGeneral && (
            <Container
              shouldToggle={shouldToggle}
              channel={channelGeneral}
              {...{ active, setActive }}
              stringSet={stringSet}
            />
            )}
          </div>
          {channelQuestions && (
            <div className={`${active !== QUESTIONS ? 'hidden' : 'bg-black flex flex-col justify-between h-94%'}`}>
              <Container
                shouldToggle={shouldToggle}
                channel={channelQuestions}
                isQuestions
                {...{ setActive }}
                stringSet={stringSet}
              />
            </div>
          )}
        </Tab>
        {shouldInitPrivateChat && userData
          && (
          <Tab label={stringSet.PRIVATE_TAB}>
            <ChannelList />
          </Tab>
          )
      }
        {userData && (
        <Tab label="user-icon">
          <div className="bg-black flex flex-col justify-between h-94%">
            <UserList useNormalCard channel={null} />
          </div>
        </Tab>
        ) }
      </Tabs>
    </div>
  );
};

export default withRouter(ChannelToggler);
