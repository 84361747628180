import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import getLocalization from '../../util/localization';
import RoutesContext from '../../context/routesContext';

class RadioTodayGridHeader extends React.Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      selectedDate: today,
      day: today.date(),
      month: today.month(),
      year: today.year(),
      maxDays: today.daysInMonth(),
    };
  }

  componentDidMount() {
  }

  handleChange(name, e) {
    const { value } = e.target;
    if (value > 0) {
      this.setState({ [name]: value }, () => {
        const { year, month } = this.state;
        this.setState({ maxDays: moment(`${year}-${month}-01`).daysInMonth() });
      });
    }
  }

  renderDays() {
    const options = [];
    const { maxDays } = this.state;
    for (let i = 1; i <= maxDays; i++) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  renderMonths = () => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  renderYears() {
    const options = [];
    const { selectedDate } = this.state;
    for (let i = selectedDate.year(); i >= 2000; i--) {
      options.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return options;
  }

  render() {
    const {
      title, text1, text2, dayBeforeText, dayAfterText, icon1Url, icon2Url, icon3Url, dateFormat, match,
    } = this.props;

    const {
      selectedDate, day, month, year,
    } = this.state;
    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);

          return (
            <div className="w-full text-white font-americaMonoRegular bg-black p-8">
              <div className="flex align-center border-dotted border-white border-b-1 pb-8">
                <div className="flex justify-start">
                  <span className="text-sm flex items-center">
                    <img className="mr-2" src={icon1Url} alt={dayBeforeText[localization.language]} />
                    {dayBeforeText[localization.language]}
                  </span>
                </div>
                <div className="flex-1 text-center font-americaMonoBold uppercase">
                  {selectedDate.isSame(moment(), 'day') ? text1[localization.language] : moment(selectedDate).format(dateFormat[localization.language]) }
                  &nbsp;
                  {title[localization.language]}
                </div>
                <div className="flex justify-end">
                  <span className="text-sm flex items-center">
                    {dayBeforeText[localization.language]}
                    <img className="ml-2" src={icon2Url} alt={dayAfterText[localization.language]} />
                  </span>
                </div>
              </div>
              <div className="items-center pt-8 flex sm:block">
                <div className="uppercase mr-4 sm:mr-0 sm:mb-4">
                  {text2[localization.language]}
                </div>
                <div className="flex">
                  <div className="mr-4">
                    <FormControl>
                      <Select
                        value={day}
                        onChange={this.handleChange.bind(this, 'day')}
                        IconComponent={() => <img src={icon3Url} />}
                      >
                        {this.renderDays()}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mr-4">
                    <FormControl>
                      <Select
                        value={month}
                        onChange={this.handleChange.bind(this, 'month')}
                        IconComponent={() => <img src={icon3Url} />}
                      >
                        {this.renderMonths()}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <Select
                        value={year}
                        onChange={this.handleChange.bind(this, 'year')}
                        IconComponent={() => <img src={icon3Url} />}
                      >
                        {this.renderYears()}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

RadioTodayGridHeader.propTypes = {
  title: PropTypes.shape().isRequired,
  text1: PropTypes.shape().isRequired,
  text2: PropTypes.shape().isRequired,
  text3: PropTypes.shape().isRequired,
  text4: PropTypes.shape().isRequired,
  text5: PropTypes.shape().isRequired,
  dayBeforeText: PropTypes.shape().isRequired,
  dayAfterText: PropTypes.shape().isRequired,
  dateFormat: PropTypes.shape().isRequired,
  icon1Url: PropTypes.string.isRequired,
  icon2Url: PropTypes.string.isRequired,
  icon3Url: PropTypes.string.isRequired,
};

export default withRouter(RadioTodayGridHeader);
