import React from 'react';
import PropTypes from 'prop-types';
import EmptySlot from './EmptySlot';
import ArtistBox from './ArtistBox';

const VenueColumn = ({
  day,
  layout,
  artists,
  index,
  startAndEndHours,
  firstColumnRef,
  showArtistCallback,
  hideArtistCallback,
  hasConferences,
  eventSlug,
}) => (
  <div ref={index === 0 ? firstColumnRef : null} className="border-r-1 border-b-1 border-solid border-white">
    <div className="relative">
      <div>
        {startAndEndHours && (
          Object.keys(startAndEndHours[day].hours).map((hour, i) => (
            <EmptySlot hasEvent={startAndEndHours[day].hours[hour]} index={i} layout={layout} key={hour + i.toString()} />
          )))}
      </div>
      <div className="absolute pin-t pin-x z-1">
        {artists.map(artist => (
          <ArtistBox
            eventSlug={eventSlug}
            day={day}
            layout={layout}
            key={artist.artistSetSlugName}
            artist={artist}
            showArtistCallback={showArtistCallback}
            hideArtistCallback={hideArtistCallback}
            startAndEndHours={startAndEndHours}
            hasConferences={hasConferences}
          />
        ))}
      </div>
    </div>
  </div>
);

VenueColumn.propTypes = {
  day: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  startAndEndHours: PropTypes.shape().isRequired,
  showArtistCallback: PropTypes.func.isRequired,
  hideArtistCallback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  firstColumnRef: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  artists: PropTypes.array.isRequired,
  hasConferences: PropTypes.bool,

};

VenueColumn.defaultProps = {
  hasConferences: false,
};

export default VenueColumn;
