import React, {
  useState, useEffect, useRef,
} from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import ComponentIterator from '../../../containers/ComponentIterator';

const RowMegamenu = ({
  link, subtitle, title, type, components,
}) => {
  const [opened, setOpened] = useState(false);
  const [screen, setScreen] = useState();
  const responsiveUtility = useRef();

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setScreen(deviceScreen);
  };

  const handleDropdownResponseEvent = (response) => {
    if (!['laptop', 'desktop'].includes(screen)) {
      if (
        typeof response.detail.active !== 'undefined'
        && response.detail.active === title.en
      ) {
        setOpened(true);
      } else setOpened(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && components === null) {
      window.addEventListener('dropdownResponseEvent', handleDropdownResponseEvent);
    }

    return () => {
      if (components === null) {
        window.removeEventListener('dropdownResponseEvent', handleDropdownResponseEvent);
      }
    };
  }, []);


  function handleDropdown() {
    const dropdownEvent = new CustomEvent(
      'dropdownEvent', {
        detail: { name: title, isOpened: !opened },
      },
    );
    if (!['laptop', 'desktop'].includes(screen)) {
      window.dispatchEvent(dropdownEvent);
    }
  }

  useEffect(() => {
    if (!['laptop', 'desktop'].includes(screen)) {
      setOpened(false);
    } else setOpened(true);
  }, [screen]);

  const handleMobileComponents = (position) => {
    const mobileArrTop = [];
    const mobileArrBottom = [];
    components.forEach((component) => {
      if (
        (component.type === 'FooterLogosContainer')
        || (component.type === 'buttonRowMegamenu' && component.bold)
      ) {
        mobileArrTop.push(component);
      } else mobileArrBottom.push(component);
    });

    if (position === 'top') return mobileArrTop;
    if (position === 'bottom') return mobileArrBottom;
    return components;
  };

  const dropdownVariants = {
    closed: { height: 0 },
    opened: { height: 'auto' },
  };

  const dropdownArrowVariants = {
    closed: { rotate: 0 },
    opened: { rotate: 180 },
  };

  const transition = {
    type: 'tween',
    duration: 0.5,
    ease: [0.87, 0, 0.13, 1],
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom);
        if (components === null) {
          return (
            <div className="flex-1 w-full laptop:flex-none laptop:border-b-2 border-red">
              <div className="items-center justify-start font-stratos laptop:flex">
                <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} rdlSizes />
                <div
                  className="flex items-center justify-between py-4 border-b-2 cursor-pointer border-red laptop:mb-0 laptop:border-b-0 laptop:cursor-auto laptop:py-4"
                  onClick={() => handleDropdown()}
                >
                  {/* <span className="block font-bold text-30 laptop:text-28 text-red laptop:mr-4">
                    {translateObj(title, localization.language)}
                  </span> */}
                  <Link to={link} className="block font-bold text-30 laptop:text-28 text-red laptop:mr-4">
                    {translateObj(title, localization.language)}
                  </Link>
                  {/* <motion.div
                    animate={opened ? 'opened' : 'closed'}
                    transition={transition}
                    variants={dropdownArrowVariants}
                  >
                    <svg className="w-4 laptop:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10">
                      <path data-name="t4818" d="M8 10l8-10H0z" fill="#ff4646" />
                    </svg>
                  </motion.div> */}
                </div>
                <motion.div
                  // className="overflow-y-hidden laptop:flex laptop:items-center"
                  className="overflow-y-hidden hidden laptop:flex laptop:items-center"
                  animate={opened ? 'opened' : 'closed'}
                  transition={transition}
                  variants={dropdownVariants}
                >
                  <Link
                    to={`${link}`}
                    className="block pb-4 text-lg font-light text-black no-underline border-b-2 laptop:py-1 laptop:text-base laptop:border-0 border-red mt-4 laptop:mt-0"
                  >
                    {translateObj(subtitle, localization.language)}
                  </Link>
                </motion.div>
              </div>
            </div>
          );
        }
        return (
          <>
            <div className="items-center justify-end hidden border-black laptop:flex laptop:border-b-2 laptop:py-2">
              <ComponentIterator components={components} />
            </div>
            <div className="flex flex-col items-stretch justify-start laptop:hidden">
              <div className="relative flex items-center justify-between py-3 border-b tablet:justify-center border-grey">
                <ComponentIterator components={handleMobileComponents('top')} />
              </div>
              <div className="relative flex items-center justify-between py-6 border-b tablet:justify-center border-grey">
                <div className="flex items-center justify-center tablet:absolute tablet:pin-l tablet:pin-t tablet:pin-b">
                  <svg className="w-auto h-3 tablet:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.58 15.679">
                    <path
                      data-name="t21"
                      d="M95.552 9.38l3.858-5.77h-3.39l-2.153 3.458-2.131-3.458h-3.475l3.859 5.814-4.008 5.989h3.39l2.3-3.677 2.3 3.677h3.475zm-11.107 6.034h3.24v-11.8h-3.24v6.584c0 1.586-.789 2.4-2.025 2.4s-1.962-.814-1.962-2.4V3.614h-3.24v7.641c0 2.709 1.428 4.382 3.88 4.382a4.017 4.017 0 003.347-1.894zm-12.093 0h3.241V.36h-3.241zm-1.194-4.734c.021-.308.042-.616.042-.881 0-3.347-1.748-6.408-5.563-6.408-3.325 0-5.649 2.775-5.649 6.144v.044a5.809 5.809 0 005.969 6.1 5.645 5.645 0 004.668-2.2l-1.855-1.7a3.685 3.685 0 01-2.771 1.211 2.671 2.671 0 01-2.792-2.312zm-3.113-2.092h-4.881c.256-1.519 1.13-2.51 2.473-2.51 1.364 0 2.217 1.013 2.409 2.51M55.69 15.414h3.24V.36h-3.24v4.793a4.342 4.342 0 00-3.561-1.762c-2.707 0-5.2 2.158-5.2 6.1v.044c0 3.942 2.537 6.1 5.2 6.1a4.163 4.163 0 003.561-1.916zm.042-5.879c0 1.96-1.258 3.259-2.771 3.259a2.973 2.973 0 01-2.793-3.259v-.044a2.96 2.96 0 012.793-3.259c1.513 0 2.771 1.3 2.771 3.259zM43.186 8.258l4.349-4.647h-3.88l-3.794 4.294V3.611h-3.24v11.8h3.24v-3.589l1.151-1.256 2.92 4.845h3.752zM28.532 9.535v-.044a2.981 2.981 0 012.771-3.215 3.3 3.3 0 012.6 1.3l1.982-2.2a5.537 5.537 0 00-4.562-1.982 5.966 5.966 0 00-5.991 6.144v.044a5.932 5.932 0 005.948 6.1 5.684 5.684 0 004.669-2.158l-1.9-1.982a3.573 3.573 0 01-2.622 1.255 2.986 2.986 0 01-2.9-3.259m-3.618 0v-.044a6.032 6.032 0 00-6.161-6.1 6.091 6.091 0 00-6.2 6.144v.044a6.032 6.032 0 006.161 6.1 6.091 6.091 0 006.2-6.144m-3.2.044a2.982 2.982 0 01-2.963 3.214 3.093 3.093 0 01-3.006-3.259v-.044a2.982 2.982 0 012.963-3.215 3.093 3.093 0 013.006 3.259zm-9.265-4.449v-.044a4.93 4.93 0 00-1.279-3.524A5.836 5.836 0 006.822-.002H0v15.414h3.283V10.48h2.58l3.2 4.932H12.9l-3.646-5.5a4.78 4.78 0 003.2-4.779m-3.325.176c0 1.3-.917 2.18-2.516 2.18H3.287V3.063h3.262c1.6 0 2.58.749 2.58 2.2z"
                      fill="#ff4646"
                    />
                  </svg>
                </div>
                <div className="flex items-center justify-end flex-1 tablet:justify-center tablet:pr-4 laptop:pr-0">
                  <ComponentIterator components={handleMobileComponents('bottom')} />
                </div>
              </div>
            </div>
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

RowMegamenu.propTypes = {
  link: PropTypes.string,
  subtitle: PropTypes.shape(),
  title: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.array,
  type: PropTypes.string.isRequired,
};

RowMegamenu.defaultProps = {
  link: '',
  components: null,
  subtitle: undefined,
  title: undefined,
};

export default RowMegamenu;
