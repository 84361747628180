/* eslint-disable react/forbid-prop-types */

/**
 * Pay with a previously stored card
 * This source code is inspired in this example: https://github.com/stripe-samples/charging-a-saved-card/blob/master/client/script.js
 */
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import {
  useStripe,
  useElements,
  // CardNumberElement,
  // CardCvcElement,
  // CardExpiryElement,
} from '@stripe/react-stripe-js';
import api from './psWorldPaymentsApi';
import StripeCardForm from './PsWorldStripeCardForm';
import translateObj from '../../../util/translateObj';


const useOptions = () => {
  const fontSize = '18px'; // useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          width: '400px',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
  );

  return options;
};

const PayWithStoredCard = ({
  customerInfo,
  paymentMethodId,
  product,
  resultCallback,
  paymentMethodLast4,
  donationProposedAmounts,
  language,
  handleDonationModalStatus,
  couponCode,
  couponValid,
  presentData,
  donationTranslations: { selectAmount: formText = {} } = {},
}) => {
  const [productType, setProductType] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [error, setError] = useState(null);
  const [resultInfo, setResultInfo] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [publicKey, setPublicKey] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [showGetCardInfo, setShowGetCardInfo] = useState(false);
  const [showPayWithStoredCard, setShowPayWithStoredCard] = useState(false);

  const stripe = useStripe();

  useEffect(() => {
    if (product) {
      setProductType(product.productType);
      if (product.amount) {
        setAmount(product.amount);
      }
      setCurrency(product.currency);
    }
  }, [product]);

  /* Shows a success / error message when the payment is complete */
  const orderComplete = (subscription) => {
    setProcessing(false);
    setSucceeded(true);
    setResultInfo(`Subscription Status: ${subscription.status}`);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    setError(false);
    api
      .createPaymentIntentWithStoredCard({
        paymentMethodId,
        customerId: customerInfo.id,
        productSlugName: product.slugName,
        donationAmount: amount,
        couponCode,
        couponValid,
        presentData,
      })
      .then(async (result) => {
        setProcessing(false);
        if (result.stripeError && result.stripeError === 'authentication_required') {
          // Card needs to be authenticatied
          // Reuse the card details we have to use confirmCardPayment() to prompt for authentication
          // Use confirmCardPayment() to ask the customer to authenticate a previously saved card
          stripe
            .confirmCardPayment(result.clientSecret, {
              payment_method: result.paymentMethod,
            })
            .then((stripeJsResult) => {
              if (
                stripeJsResult.error
                && stripeJsResult.error.code === 'payment_intent_authentication_failure'
              ) {
                // TODO: To be tested
                // Authentication failed -- prompt for a new payment method since this one is failing to authenticate
                setPublicKey(result.publicKey);
                setShowPayWithStoredCard(false);
                setClientSecret(result.clientSecret);
                setShowGetCardInfo(true);
              } else if (
                stripeJsResult.paymentIntent
                && stripeJsResult.paymentIntent.status === 'succeeded'
              ) {
                // TODO: To be tested
                // Order was authenticated and the card was charged
                // There's a risk your customer will drop-off or close the browser before this callback executes
                // We recommend handling any business-critical post-payment logic in a webhook
                setShowGetCardInfo(false);
                resultCallback({
                  status: 'succeeded',
                });
              }
            });
        } else if (result.stripeError) {
          // Card was declined off-session -- ask customer for a new card
          // showEl(".requires-pm");
          resultCallback({
            ...result,
            status: 'requireNewCard',
          });
          setShowPayWithStoredCard(false);
          setPublicKey(result.publicKey);
          setClientSecret(result.clientSecret);
          setShowGetCardInfo(true);
        } else if (result.status === 'succeeded') {
          // Card was successfully charged off-session
          setSucceeded(true);
          setProcessing(false);
          // setResultInfo('Thank you!');
          resultCallback({
            status: 'succeeded',
          });
        }
      })
      .catch((err) => {
        setError(err.message);
        setProcessing(false);
      });
  };

  const payWithNewCardResult = (result) => {
    const { status } = result;

    if (status === 'succeeded') {
      setShowGetCardInfo(false);
      resultCallback({
        status: 'succeeded',
      });
    }
  };

  //   stripe.paymentIntents.create(
  //     {
  //       payment_method: paymentMethodId,
  //       description: product.title.en,
  //       amount,
  //       currency: product.currency,
  //       confirm: true,
  //       receipt_email: customerInfo.email,
  //     },
  //     (err, paymentIntent) => {
  //       if (err) {
  //         setError(`Payment failed: ${err.message}`);
  //         setProcessing(false);
  //         console.log('[error]', err);
  //       } else {
  //         setError(null);
  //         setSucceeded(true);
  //         setProcessing(false);
  //         console.log('[PaymentIntent]', paymentIntent);
  //       }
  //     },
  //   );
  // };

  // const donationTitle = `How much do you want to donate? (${currency.toLocaleUpperCase()})`;

  return (
    <>
      <p>
        {product && product.title ? product.title.en : ''}
      </p>

      {productType === 'donation' && (
        <>
          <label className="flex flex-col font-americaMonoBold" htmlFor="donationAmount">
            {translateObj(formText.howMuchText, language)}
            <div className="w-full flex">
              {(donationProposedAmounts && donationProposedAmounts.length) && donationProposedAmounts.map(donationAmount => <button key={donationAmount} className={`p-4 ${amount === donationAmount ? 'bg-black text-white' : 'bg-gray200'} sm:p-2 mx-8 sm:mx-2`} type="button" onClick={() => { setAmount(donationAmount); }}>{donationAmount}</button>)}
            </div>
            <input name="donationAmount" type="text" className="mt-4 rounded-full border-1 p-4 bg-inherit border-black text-black false border-black text-black" value={amount} onChange={newValue => setAmount(newValue.currentTarget.value)} />
          </label>
          <p>{`${translateObj(formText.howMuchLastDigits, language)} ${paymentMethodLast4}`}</p>
          <div className="underline cursor-pointer" onClick={() => { handleDonationModalStatus('hasMultiplePaymentMethods'); }}>{translateObj(formText.changeCard, language)}</div>
        </>
      )}

      {productType !== 'donation' && (
      <h2>
        {currency.toLocaleUpperCase()}
        {' '}
        {amount.toLocaleString('en', {
          // TODO: set language dynamically
          minimumFractionDigits: 2,
        })}
        {' '}
      </h2>
      )}

      <div className="flex justify-end mt-8 sm:mt-0">
        <div className="flex flex-col w-1/2 sm:hidden">
          <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold">
            BACK
          </button>
        </div>
        <div className="flex flex-col w-1/2 sm:hidden">
          <button type="button" className="bg-grey h-12 text-white rounded-full mx-4 py-2 font-americaMonoBold" disabled={processing || !stripe} onClick={handleSubmit}>
            {processing ? 'PROCESSING…' : 'CONTINUE'}
          </button>
        </div>
      </div>
      <div className="flex mt-8 sm:mt-0">
        <div className="flex flex-col">
          {showGetCardInfo && (
          <StripeCardForm customerInfo={customerInfo} product={product} publicKey={publicKey} clientSecret={clientSecret} resultCallback={payWithNewCardResult} />
          )}
        </div>
      </div>
      <div className="flex justify-end mt-8 sm:mt-0">
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="w-full card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? 'w-full result-message' : 'w-full result-message hidden'}>
          Thank you!
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://dashboard.stripe.com/test/payments"
          >
            {' '}
            Stripe dashboard.
          </a>
          {resultInfo && (
            <p>
              {resultInfo}
            </p>
          )}
          Refresh the page to pay again.
        </p>
      </div>
    </>
  );
};

PayWithStoredCard.propTypes = {
  // customerInfo: PropTypes.objectOf(PropTypes.object()).isRequired,
  customerInfo: PropTypes.object.isRequired,
  paymentMethodId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  resultCallback: PropTypes.func.isRequired,
  paymentMethodLast4: PropTypes.string,
  donationProposedAmounts: PropTypes.arrayOf(PropTypes.number),
  language: PropTypes.string,
  handleDonationModalStatus: PropTypes.func,
  couponCode: PropTypes.string,
  couponValid: PropTypes.bool,
  presentData: PropTypes.shape({
    presentDestinationName: PropTypes.string,
    presentDestinationEmail: PropTypes.string,
    presentDeliveryDate: PropTypes.string,
    presentMessage: PropTypes.string,
  }),
};

PayWithStoredCard.defaultProps = {
  paymentMethodLast4: '',
  donationProposedAmounts: [],
  language: 'en',
  handleDonationModalStatus: null,
  couponCode: '',
  couponValid: false,
  presentData: null,
};

export default PayWithStoredCard;
