import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import VerificationBanner from 'ComponentsPath/HeaderPopUpBanners/VerificationBanner';
import TermsAndConditionsBanner from 'ComponentsPath/HeaderPopUpBanners/TermsAndConditionsBanner';

import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import withAllContexts from '../../../context/withAllContexts';
import { UserProfileContext } from '../../../context/userProfileContext';

require('intersection-observer');

class Header extends Component {
  static contextType = UserProfileContext;

  thresholdEl = React.createRef()

  headerRef = React.createRef()

  componentExists = React.createRef(false);

  state = {
    scrolled: false,
    // userActivationRequestSent: false,
    isUserVerified: null,
    userEmail: '',
    headerHeight: '105px',
  }


  componentDidMount() {
    this.componentExists.current = true;
    const { removeRadio } = this.props;
    if (removeRadio) {
      const { hideRadio, hidePlayer } = this.props;
      hidePlayer();
      hideRadio(true);
    }

    const observer = new IntersectionObserver(
      ([entry]) => (
        this.componentExists.current && this.setState(() => ({ scrolled: !entry.isIntersecting }))
      ),
    );

    observer.observe(this.thresholdEl.current);
    // window.addEventListener('scroll', this.handleScroll);
    // this.setBannerState();
  }

  componentWillUnmount() {
    this.componentExists.current = false;
    const { removeRadio } = this.props;
    if (removeRadio) {
      const { hideRadio, showPlayer } = this.props;
      hideRadio(false);
      showPlayer();
    }

    // UNOBSERVE
    // observer.unobserve(this.thresholdEl.current);
    // window.removeEventListener('scroll', this.handleScroll);
  }

  // handleScroll = () => {
  //   const { scrolledFromTop } = this.state;
  //   const newScrolledFromTop = this.headerRef.current.getBoundingClientRect().top;
  //   const { playerPosition, setPlayerPosition, headerContext } = this.props;
  //   const body = document.querySelector('body');
  //   const lineUpContainer = document.getElementById('line-up-container');
  //   // check if we are on line up page and make header always scroll up
  //   if (lineUpContainer) {
  //     return this.setState(() => ({ showHeaderOnScroll: false, headerRelative: true }));
  //   }
  //   if (!headerContext.megaMenuIsOpen) {
  //     if (newScrolledFromTop > scrolledFromTop) {
  //       this.setState(() => ({ showHeaderOnScroll: true, headerRelative: false }));
  //       if (!this.isBottom(body)) {
  //         if (playerPosition.includes('pin-t')) { setPlayerPosition('pin-t'); } else { setPlayerPosition('pin-b'); }
  //       }
  //     } else {
  //       this.setState(() => ({ showHeaderOnScroll: false }));
  //       if (scrolledFromTop < -150) {
  //         this.setState(() => ({ headerRelative: false }));
  //         if (playerPosition === 'pin-b') {
  //           setPlayerPosition('pin-b -mb-16');
  //         } else if (playerPosition === 'pin-t') {
  //           setPlayerPosition('pin-t -mt-16');
  //         }
  //       } else {
  //         this.setState(() => ({ headerRelative: true }));
  //       }
  //     }
  //     this.setState(() => ({ scrolledFromTop: newScrolledFromTop }));
  //   } else {
  //     this.setState(() => ({ showHeaderOnScroll: false, headerRelative: true }));
  //   }
  // }

  isBottom = el => el.getBoundingClientRect().bottom <= window.innerHeight + 315

  recieveInfoFromContext = (isUserVerified, userEmail, headerHeight, isUserUpToDateWithTermsAndConditions) => {
    this.setState({
      isUserVerified, userEmail, headerHeight, isUserUpToDateWithTermsAndConditions,
    });
  }

  render() {
    const {
      children, name, showRadioPlayer, playerPosition, podcast, backgroundColor,
    } = this.props;
    const {
      scrolled, menuIsOpen, headerHeight, userEmail, isUserVerified, isUserUpToDateWithTermsAndConditions,
    } = this.state;

    const { isActivationBarOpen } = this.context;
    return (
      <>
        <div className="w-full h-px -mt-px" ref={this.thresholdEl} />
        <div id="header" className={`z-header fixed pin-t flex w-full trans ${scrolled ? 'bg-white' : 'bg-transparent'}`} ref={this.headerRef}>
          <div
            id={name}
            className={`flex w-full items-center justify-center ${(userEmail && (isUserVerified === false || isUserUpToDateWithTermsAndConditions === false)) && 'flex-col'}`}
          >
            <VerificationBanner context={this.context} sendUserInfoBack={this.recieveInfoFromContext} />
            <TermsAndConditionsBanner context={this.context} sendUserInfoBack={this.recieveInfoFromContext} />
            <div className="pb-4 laptop:pb-8 relative w-full">
              {children}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Header.propTypes = {
  children: PropTypes.shape().isRequired,
  //name: PropTypes.string.isRequired,
  showRadioPlayer: PropTypes.bool.isRequired,
  playerPosition: PropTypes.string.isRequired,
  // setPlayerPosition: PropTypes.func.isRequired,
  headerContext: PropTypes.shape({}).isRequired,
  removeRadio: PropTypes.bool,
  hideRadio: PropTypes.func.isRequired,
  hidePlayer: PropTypes.func.isRequired,
  showPlayer: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
};

Header.defaultProps = {
  removeRadio: true,
  backgroundColor: 'white',
};

export default withAllContexts(withRadioPlayerContext(Header));
