import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';
import imageModifier from './helpers/imageModifier';
import LoadIcon from './LoadIcon';
import FloatingArtistCardLoginButton from './FloatingArtistCardLoginButton';
// const { isFavoritesActive } = process.env;
const isFavoritesActive = true;

const trans = {
  knowMore: {
    ca: 'SABER MÉS',
    es: 'SABER MÁS',
    en: 'Know more',
    pt: 'SABER MAIS',
  },
  add_favourites: {
    ca: 'Afegir a favorits',
    es: 'Agregar a favoritos',
    en: 'Add to Favorites',
    pt: 'Adicionar aos favoritos',
  },
  remove_favourites: {
    ca: 'Eliminar de favorits',
    es: 'Eliminar de favoritos',
    en: 'Remove from favorites',
    pt: 'Remover dos favoritos',
  },
};

const Arrow = () => <span className="font-icon icon-arrow-right text-white text-sm" />;

class FloatingArtistCard extends React.Component {
  state = {
    starDisabled: false,
    favorite: false,
  };

  componentDidMount() {
    const { favorite } = this.props;
    this.setState({ favorite });
  }

  show = () => {
    const { mainRef } = this.props;
    TweenMax.to(mainRef.current, 0.3, {
      autoAlpha: 1,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  hide = (callback) => {
    if (callback) callback();
  };

  handleFavoriteLogic = (artistSetSlugName, lang) => {
    const { starDisabled, favorite } = this.state;
    const {
      isUserLoggedIn, history, handleSetArtistSetFavorite, artist: { artistSlugName, artistSetArr },
    } = this.props;

    if (!starDisabled) {
      if (isUserLoggedIn) {
        this.setState({ starDisabled: true });
        // logic to handle artist with multiple concerts
        if (artistSetArr && artistSetArr.length > 0) {
          const artistSetArrToSet = [];
          artistSetArr.forEach((as) => {
            artistSetArrToSet.push(`${artistSlugName}|${as}`);
          });
          handleSetArtistSetFavorite(artistSetArrToSet);
        } else {
          // logic to update user favs traditional - 1 concert
          handleSetArtistSetFavorite([artistSetSlugName]);
        }
        this.setState({ favorite: !favorite });
        setTimeout(() => {
          this.setState({ starDisabled: false });
        }, 1000);
      } else {
        // please log in
        history.push(`/${lang}/login-screen?e=sign-in`);
      }
    }
  }

  renderArtistCardButton = (language) => {
    const {
      color, artist: { artistSetSlugName, artistSlugName }, isUserLoggedIn, hideArtistCallback,
    } = this.props;
    const { favorite, starDisabled } = this.state;

    if (isFavoritesActive) {
      if (isUserLoggedIn) {
        return (
          <div className={`mt-4 rounded-full mx-4 py-4 flex cursor-pointer items-center font-americaMonoBold justify-center uppercase ${favorite ? `bg-${color} text-black` : 'border-1 border-solid border-white text-white'}`} onClick={() => this.handleFavoriteLogic(`${artistSlugName}|${artistSetSlugName}`, language)} style={{ minHeight: '30px' }}>
            {starDisabled ? <LoadIcon height={15} width={15} color="black" /> : favorite ? <span className="font-icon icon-star-solid text-black text-lg mr-2" /> : <span className="font-icon icon-star text-white text-lg mr-2" />}
            <span className={`${starDisabled && 'hidden'} text-12 `}>
              {favorite ? translateObj(trans.remove_favourites, language) : translateObj(trans.add_favourites, language)}
            </span>
          </div>
        );
      }
      return (
        <FloatingArtistCardLoginButton buttonText={translateObj(trans.add_favourites, language)} language={language} closeArtistCard={hideArtistCallback} />
      );
    }
    return null;
  }

  render() {
    const {
      artist, style, mainRef, hideArtistCallback, color, backgroundClickable, match, eventSlug,
    } = this.props;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div ref={mainRef} className="sm:px-4 pin-t pin-l artistCard fixed sm:fixed sm:pin sm:flex sm:items-center sm:justify-center md:justify-center sm:bg-black20 md:bg-black20 md:fixed md:pin md:flex md:items-center md:bg-black20 p-4" style={style}>
              {backgroundClickable && <div onClick={hideArtistCallback} className="absolute pin" />}
              <div className="w-74 bg-black rounded pb-4 relative z-10">
                <div onClick={hideArtistCallback} className="absolute z-10 pin-r pin-t mr-4 mt-4 w-4 h-4 cursor-pointer">
                  <span className="w-4 h-4 text-white font-icon icon-close cursor-pointer" />
                </div>
                <div className="relative">
                  {artist && artist.image !== null && artist.image.en && <div className="colorImg rounded w-full h-220px" style={{ background: `url(${imageModifier(artist.image.en, '336x220')}) no-repeat center center`, backgroundSize: 'cover' }} />}
                  <div className="filter" />
                  <h3 className="py-7 px-4 font-americaBlack text-18 absolute pin-b text-white">
                    {artist.artistSetReadableName && translateObj(artist.artistSetReadableName, localization.language) ? translateObj(artist.artistSetReadableName, localization.language) : artist.artistName}
                  </h3>
                </div>
                <div className="my-4 mx-4 pb-5 text-sm text-white font-americaMonoBold flex items-center border-b-1 border-white border-dashed">
                  <Link to={`/${eventSlug || 'artist'}${artist.postUri}`} onClick={hideArtistCallback}>
                    <span className="mr-4 cursor-pointer text-white">{translateObj(trans.knowMore, localization.language)}</span>
                    <Arrow />
                  </Link>
                </div>
                {this.renderArtistCardButton(localization.language || 'en')}
              </div>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

FloatingArtistCard.propTypes = {
  artist: PropTypes.shape().isRequired,
  style: PropTypes.shape().isRequired,
  isUserLoggedIn: PropTypes.bool,
  favorite: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  handleSetArtistSetFavorite: PropTypes.func.isRequired,
  color: PropTypes.string,
  hideArtistCallback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mainRef: PropTypes.any.isRequired,
  backgroundClickable: PropTypes.bool,
  eventSlug: PropTypes.string,
};

FloatingArtistCard.defaultProps = {
  color: 'radio',
  backgroundClickable: true,
  eventSlug: null,
  isUserLoggedIn: false,
  favorite: false,

};

export default withRouter(FloatingArtistCard);
