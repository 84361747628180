import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link, withRouter } from 'react-router-dom';
import getLocalization from '../../../util/localization';

import RoutesContext from '../../../context/routesContext';
import { UserProfileContextConsumer } from '../../../context/userProfileContext';
import useResponsiveUtility from '../../../util/responsiveUtility';
import ButtonLinkWithBadge from './ButtonLinkWithBadge';

const TopLogoContainer = ({
  name, image, match, linkTo,
}) => (
  <UserProfileContextConsumer>
    {({
      isActivationBarOpen, isConditionBarOpen,
    }) => (
      <RoutesContext.Consumer>
        {
              ({ language: contextLanguage, enteredFrom }) => {
                const localization = getLocalization(contextLanguage, enteredFrom, match);
                return (
                  <LogoComponent localization={localization} isActivationBarOpen={isActivationBarOpen || isConditionBarOpen} linkTo={linkTo} image={image} name={name} />
                );
              }
            }
      </RoutesContext.Consumer>
    )}
  </UserProfileContextConsumer>
);

const LogoComponent = ({
  name, image, match, linkTo, isActivationBarOpen, localization,
}) => {
  const [isActivationBarOpenState, setIsActivationBarOpenState] = useState(false);
  const { isMobile } = useResponsiveUtility();

  useEffect(() => {
    setIsActivationBarOpenState(isActivationBarOpen);
  }, [isActivationBarOpen]);

  return (
    <div
      id={name}
      className="flex pt-4 laptop:pt-8 flex-row z-50 items-center justify-center"
    >
      { isMobile ? (
        <div style={{
          position: 'absolute',
          left: '12px',
        }}
        >
          <ButtonLinkWithBadge />
        </div>
      ) : null}
      <Link to={linkTo} className="xs:z-50">
        <img
          className="h-auto w-logo-mobile
          tablet:w-logo-tablet
          laptop:w-logo-laptop max-w-322 "
          src={image.size.sm}
          alt={name}
        />
      </Link>
    </div>
  );
};

TopLogoContainer.propTypes = {
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  image: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

TopLogoContainer.defaultProps = {
  linkTo: '/',
};

export default withRouter(TopLogoContainer);
