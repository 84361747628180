/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { propTypes, withFormsy } from 'formsy-react';

class FormTextarea extends Component {
  state = {
    value: '',
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { getValue } = this.props;
    if (prevProps !== this.props && getValue() !== undefined) {
      if (prevState.value !== getValue()) {
        this.setState({
          value: getValue(),
        });
      }
    }
  };

  changeValue = (e) => {
    this.setState({
      value: e.currentTarget.value,
    });
  };

  validate = (e) => {
    const { setValue } = this.props;
    setValue(e.currentTarget.value);
  };

  render() {
    const {
      name,
      title,
      className,
      showError,
      getErrorMessage,
      isValid,
      showRequired,
      required,
      isContactForm,
    } = this.props;

    const { value } = this.state;

    const classNameInput = `form-group ${className || ''} ${isValid() ? 'valid' : ''} ${showRequired() ? 'required' : ''} ${showError() ? 'error' : ''}`;
    const errorMessage = getErrorMessage();

    return (
      <div className={`flex ${isContactForm ? '' : 'px-3 mb-7.5'} w-full flex-col`}>
        <label className="flex flex-col" htmlFor={name}>
          <span className="text-12 font-light text-black uppercase font-stratos">
            {title}
            {required && <span className="text-14 align-top text-red-light font-stratos capitalize">*</span>}
          </span>
          <textarea
            onChange={this.changeValue}
            onBlur={this.validate}
            name={name}
            value={value}
            className={`font-stratos mt-2 border-1 p-4 h-40 bg-inherit ${showError() ? 'border-red text-red' : 'border-black text-black'} ${isValid() && value !== '' ? 'border-green text-green' : 'border-black text-black'}`}
          />
        </label>
        <span className="flex flex-col text-red mt-4">{errorMessage}</span>
      </div>
    );
  }
}

FormTextarea.propTypes = {
  ...propTypes,
};

export default withFormsy(FormTextarea);
