import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SectionSocialOnDemand from './OnDemandModalsSubComponents/SectionSocialOnDemand';
import SectionPasswordOnDemand from './OnDemandModalsSubComponents/SectionPasswordOnDemand';
import { buttonClasses } from '../OnDemandUtils/onDemandUtils';
import translateObj from '../../../../util/translateObj';

const OnDemandModalLogin = (props) => {
  const { translations: { notLoggedModal: translations = {} }, language, handleRegisteringStatus } = props;
  const [notLoggedStatus, setNotLoggedStatus] = useState('social');

  const handleModalNotLoggedStatus = (loggedStatus) => {
    setNotLoggedStatus(loggedStatus);
  };


  return (
    <div className="flex flex-1 flex-col">
      <SectionSocialOnDemand {...props} handleModalNotLoggedStatus={handleModalNotLoggedStatus} handleRegisteringStatus={handleRegisteringStatus} />
      <SectionPasswordOnDemand {...props} handleModalNotLoggedStatus={handleModalNotLoggedStatus} handleRegisteringStatus={handleRegisteringStatus} />
    </div>
  );
};

OnDemandModalLogin.propTypes = {
  language: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  handleRegisteringStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
};

OnDemandModalLogin.defaultProps = {
  language: 'en',
};

export default OnDemandModalLogin;
