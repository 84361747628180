import React from 'react';
import PropTypes from 'prop-types';
import RoutesContext from '../../context/routesContext';

const FooterLogosContainer = ({
  name, mainParent, align, logos,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage }) => (
      <>
        {
          mainParent === 'header' ? (
            <div key={name} className="flex items-center justify-center laptop:justify-end laptop:ml-2">
              {logos && logos.map(logo => (
                <div key={logo.name} className="ml-1 tablet:ml-3 laptop:ml-2">
                  <a href={logo.link} className="p-1">
                    <span className={`text-black block text-24 tablet:text-18 font-icon icon-${logo.name.toLowerCase()}`} />
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <div key={name} className="flex items-start justify-center w-full mt-10 laptop:w-auto laptop:mt-0 tablet:justify-end tablet:mt-42px">
              {logos && logos.map(logo => (
                <div key={logo.name} className="ml-1 mr-1 laptop:mr-0 laptop:ml-6px">
                  <a href={logo.link}>
                    <div className="flex items-center justify-center p-1">
                      <span className={`text-white block tablet:text-20 laptop:text-32 text-28 font-rdl-icons icon-${logo.name.toLowerCase()}`} />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          )
        }
      </>
    )}
  </RoutesContext.Consumer>
);

FooterLogosContainer.propTypes = {
  name: PropTypes.string.isRequired,
  mainParent: PropTypes.string,
  align: PropTypes.string,
  logos: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

FooterLogosContainer.defaultProps = {
  align: null,
  mainParent: null,
};

export default FooterLogosContainer;
