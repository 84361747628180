import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import imageModifier from '../../helpers/imageModifier';
import translateObj from '../../../../util/translateObj';
import ProVoucherOnDemandModal from './ProVoucherOnDemandModal';


const ProOnDemandModalIsLogged = (props) => {
  const {
    image, language, userEmail, handleOnDemandScreenStatus, history, postVideoUrl, postVideoRole, userRoles, closeModal,
  } = props;

  const [hasUserRequiredRole, setHasUserRequiredRole] = useState(true);

  const handleUserRedirectToVideoPage = () => {
    history.push(`/${language || 'en'}${postVideoUrl && postVideoUrl[0] === '/' ? postVideoUrl : `/${postVideoUrl}`}`);
  };

  useEffect(() => {
    if (userRoles && userRoles.length > 0) {
      const videoRoleIndex = userRoles.indexOf(postVideoRole);
      const adminVideoRoleIndex = userRoles.indexOf('admin');
      if (videoRoleIndex >= 0 || adminVideoRoleIndex >= 0) {
        setHasUserRequiredRole(true);
        // * user already has the required role, check where should be sent
        if (postVideoUrl) { handleUserRedirectToVideoPage(); } else {
          closeModal();
          if (window && window.location) {
            window.location.reload();
          }
        }
      } else {
        setHasUserRequiredRole(false);
      }
    }
  }, [userRoles]);


  const imageurl = `url(${imageModifier(translateObj(image, language), '768x510')})`;

  const onDemandModalLoggedRender = () => {
    if (!userEmail) { handleOnDemandScreenStatus('socialLogin'); return; }
    // * user needs to enter Redtkt voucher number
    return (<ProVoucherOnDemandModal {...props} imageUrl={imageurl} hasUserRequiredRole={hasUserRequiredRole} />);
  };

  return (
    <>
      {onDemandModalLoggedRender()}
    </>
  );
};

ProOnDemandModalIsLogged.propTypes = {
  language: PropTypes.string,
  userEmail: PropTypes.string,
  loadTokenForLS: PropTypes.func.isRequired,
  handleOnDemandScreenStatus: PropTypes.func.isRequired,
  translations: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  postVideoUrl: PropTypes.string.isRequired,
  postVideoRole: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.shape(),
  closeModal: PropTypes.func.isRequired,
};

ProOnDemandModalIsLogged.defaultProps = {
  language: 'en',
  userEmail: '',
  image: null,
};

export default withRouter(ProOnDemandModalIsLogged);
