import React, { Children } from 'react';
import PropTypes from 'prop-types';
import {
  TimelineMax, TweenMax, Linear, Power4,
} from 'gsap';
import { Link, withRouter } from 'react-router-dom';
import ComponentIterator from '../../../containers/ComponentIterator';
import BodyChildContainer from '../../../containers/BodyChildContainer';
import { UserProfileContext } from '../../../context/userProfileContext';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import translateObj from '../../../util/translateObj';
import LinkButtonRounded from '../Buttons/LinkButtonRounded';

class TopHeaderMegamenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      footer: props.footer,
      activeLink: props.activeLink,
      history: props.history,
      location: props.location,
      main: props.main,
      match: props.match,
      name: props.name,
      section: props.section,
      logged: false,
      userName: '',
    };
    this.responsiveUtility = React.createRef();
  }

  componentDidMount() {
    const userContext = this.context;
    if (userContext.userEmail !== '') {
      this.setState({
        logged: true,
        userName: userContext.userName,
      });
    }
    document.addEventListener('keydown', this.keydown, false);
    this.animate('show');

    const { history } = this.props;
    this.unlisten = history.listen(() => {
      this.animate('hide');
      this.handleClose();
    });
  }

  componentDidUpdate() { }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
    document.removeEventListener('keydown', this.keydown, false);
  }

  keydown = (event) => {
    if (event.keyCode === 27) {
      const closeEvent = new CustomEvent('closeMegaMenu', {
        detail: { close: true },
      });
      window.dispatchEvent(closeEvent);
    }
  };

  handleClickOnLink(e, link) {
    e.preventDefault();
    if (!link.menu) {
      const { goTo, localization } = this.props;
      goTo(link.link, localization.path);
    } else {
      const { toggleItemCallback, localization } = this.props;
      toggleItemCallback(e, link, localization.path);
    }
  }

  animate(type = 'show') {
    const screen = this.responsiveUtility.current.deviceScreen(true);
    const isMobile = !['laptop', 'desktop'].includes(screen);

    if (type === 'show') {
      const linear = Linear.easeNone;
      const ease = Power4.easeOut;
      const tlMaster = new TimelineMax();
      const tlTop = new TimelineMax();
      const tlMain = new TimelineMax();
      const tlFooter = new TimelineMax();

      // Master timeline animations
      tlMaster.fromTo(
        '[data-name="megamenu"]',
        0.5,
        {
          autoAlpha: 0,
          scale: 0.95,
        },
        {
          autoAlpha: 1,
          scale: 1,
          ease,
        },
      )
        .add(tlTop)
        .add(tlMain)
        .add(tlFooter);

      // Top timeline animations (logo & buttons)
      const megamenuTop = document.querySelector('[data-name="megamenu"] [data-name="megamenu-top"]').childNodes;
      tlMain.staggerFromTo(
        megamenuTop,
        0.5,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          ease: linear,
        },
        0.05,
        '-=0.25',
      );

      if (!isMobile) {
        // Main timeline animations (sections)
        const sectionsEl = document.querySelector('[data-name="megamenu"] [data-name="container-main"]');
        if (!sectionsEl) return;
        const sections = sectionsEl.childNodes;
        tlMain.staggerFromTo(
          sections,
          0.5,
          {
            autoAlpha: 0,
            y: 16,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease,
          },
          0.05,
          '-=0.25',
        );

        // Footer timeline animations
        const footerEl = document.querySelector('[data-name="megamenu"] [data-name="megamenu-footer"]');
        if (!footerEl) return;
        const megamenuFooter = footerEl.childNodes;
        tlFooter.staggerFromTo(
          megamenuFooter,
          0.5,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            ease: linear,
          },
          0.05,
          '-=0.25',
        );
      } else {
        // Main timeline animations (sections)
        const sections = document.querySelectorAll('[data-name="megamenu"] [data-name="megamenu-section"]');
        if (!sections) return;
        const footerEl = document.querySelector('[data-name="megamenu"] [data-name="megamenu-footer"]');
        if (!footerEl) return;
        const megamenuFooter = footerEl.childNodes;
        const mobileSections = [
          ...sections,
          ...megamenuFooter,
        ];

        tlMain.staggerFromTo(
          mobileSections,
          0.5,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            ease,
          },
          0.05,
          '-=0.25',
        );
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleClose() {
    const closeEvent = new CustomEvent('closeMegaMenu', {
      detail: { close: true },
    });
    const tl = new TimelineMax();
    tl.to(
      '[data-name="megamenu"]',
      0.5,
      {
        autoAlpha: 0,
        scale: 0.95,
        ease: Power4.easeOut,
        onComplete: () => window.dispatchEvent(closeEvent),
      },
    );
  }

  render() {
    const {
      // links,
      // localization,
      // menuButtons,
      location,
      main,
      head,
      footer,
    } = this.props;

    const {
      logged,
      userName,
    } = this.state;

    const handleItems = (position) => {
      if (typeof footer[0] !== 'undefined' && typeof footer[0].components !== 'undefined') {
        const items = [...footer[0].components];
        const arr = [];
        const footerArr = [];

        items.forEach((component, index) => {
          if (index === items.length - 1) {
            footerArr.push(component);
          } else arr.push(component);
        });

        if (position === 'top') return arr;
        if (position === 'bottom') return footerArr;
        return footer;
      }
      return [];
    };

    const handleMegamenuButtonClick = () => {
      const loginEvent = new CustomEvent('loginMenuEvent', {
        detail: { close: false },
      });
      window.dispatchEvent(loginEvent);
    };

    const rotateClose = (entering = true) => {
      TweenMax.to('[data-name="megamenu-close-button"] span', 0.5, {
        rotation: entering ? 90 : 0,
        ease: Power4.easeOut,
      });
    };

    return (
      <div
        id="megamenu"
        data-name="megamenu"
        data-menu-type="type"
        className="fixed w-full overflow-y-auto bg-white pin z-header transition-background-04s"
      >
        <PSResponsiveUtility ref={this.responsiveUtility} rdlSizes />
        <div data-name="wrapper" className="flex flex-col items-stretch justify-start laptop:justify-start desktop:mx-auto max-w-rdl-xxl">
          <div data-name="megamenu-top" className="relative flex items-center justify-start w-full py-8 pl-1 pr-8 laptop:items-end laptop:justify-between tablet:px-42px tablet:py-42px flex-0 laptop:pb-8 laptop:pt-24 laptop:pl-24 laptop:pr-0">
            <div data-name="logo" className="items-center justify-start hidden laptop:items-end laptop:self-end flex-0 tablet:flex">
              <Link to="/">
                <svg className="w-auto tablet:h-8 laptop:h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.225 35.303">
                  {/* eslint-disable-next-line max-len */}
                  <path d="M215.153 21.122l8.688-12.991h-7.629l-4.849 7.786-4.8-7.786h-7.825l8.689 13.091-9.026 13.486h7.632l5.184-8.28 5.182 8.28h7.825zm-25.01 13.586h7.3V8.131h-7.3v14.826c0 3.57-1.777 5.4-4.56 5.4s-4.417-1.834-4.417-5.4V8.131h-7.294v17.206c0 6.1 3.215 9.867 8.736 9.867 3.695 0 5.855-2.033 7.536-4.264zm-27.229 0h7.3V.808h-7.3zm-2.688-10.661c.048-.694.095-1.387.095-1.983 0-7.536-3.935-14.429-12.527-14.429-7.488 0-12.721 6.248-12.721 13.834v.1c0 8.131 5.712 13.734 13.441 13.734a12.711 12.711 0 0010.512-4.958l-4.177-3.818a8.3 8.3 0 01-6.24 2.727 6.013 6.013 0 01-6.287-5.207zm-7.009-4.71h-10.991c.576-3.421 2.544-5.652 5.568-5.652 3.072 0 4.992 2.281 5.423 5.652m-27.822 15.371h7.295V.808h-7.295v10.79a9.776 9.776 0 00-8.017-3.967c-6.1 0-11.712 4.859-11.712 13.734v.1c0 8.876 5.713 13.734 11.712 13.734a9.373 9.373 0 008.017-4.314zm.095-13.239c0 4.413-2.832 7.338-6.239 7.338s-6.288-2.926-6.288-7.338v-.1c0-4.463 2.879-7.339 6.288-7.339s6.239 2.926 6.239 7.339zm-28.25-2.876l9.792-10.463h-8.736l-8.544 9.668V8.129h-7.3v26.579h7.3v-8.082l2.592-2.827 6.576 10.909h8.449zm-33 2.876v-.1c0-3.917 2.592-7.239 6.24-7.239 2.592 0 4.225 1.141 5.857 2.926l4.463-4.958c-2.4-2.778-5.425-4.464-10.272-4.464-7.872 0-13.489 6.248-13.489 13.834v.1c0 7.585 5.664 13.734 13.393 13.734a12.8 12.8 0 0010.514-4.859l-4.273-4.463c-1.777 1.735-3.455 2.826-5.905 2.826-3.984 0-6.528-3.272-6.528-7.338m-8.147 0v-.1A13.582 13.582 0 0042.222 7.635a13.716 13.716 0 00-13.968 13.834v.1a13.582 13.582 0 0013.872 13.734 13.715 13.715 0 0013.969-13.834m-7.2.1c0 3.917-2.5 7.238-6.672 7.238-4.031 0-6.768-3.421-6.768-7.338v-.1c0-3.917 2.5-7.239 6.672-7.239 4.032 0 6.769 3.421 6.769 7.338zM28.032 11.553v-.1a11.1 11.1 0 00-2.88-7.934c-2.208-2.28-5.52-3.52-9.792-3.52H-.001v34.709H7.39v-11.11h5.809l7.2 11.106h8.64l-8.209-12.4c4.273-1.637 7.2-5.157 7.2-10.76m-7.487.4c0 2.926-2.064 4.909-5.665 4.909H7.389V6.886h7.345c3.6 0 5.809 1.687 5.809 4.958z" fill="#ff4646" />
                </svg>
              </Link>
            </div>
            <div data-name="megamenu-buttons-wrapper" className="flex items-center justify-start flex-1 tablet:justify-end">
              {head.menuButtons.map((item) => {
                /* if (item.link === '/login-user') {
                  if (logged) {
                    return (
                      <Link
                        to="/user-profile"
                        className="ml-4 font-stratos text-base font-semibold text-red flex items-center justify-center"
                        onClick={() => this.handleClose()}
                        key="user-profile"
                      >
                        <span className="font-icon icon-user text-20 mt-px mr-2" />
                        {userName}
                      </Link>
                    );
                  }
                  return (
                    <div
                      key={item.link}
                      data-name="megamenu-login-button"
                      className="ml-4 cursor-pointer"
                      onClick={() => handleMegamenuButtonClick()}
                    >
                      <LinkButtonRounded
                        text={item.text}
                        url={item.link}
                        color="red"
                        alt={item.altState}
                        isDisabled
                        isLowercase
                        isMenu
                      />
                    </div>
                  );
                }

                return (
                  <div key={item.link} data-name="megamenu-button" className="ml-4">
                    <Link
                      to={item.link}
                      className="hover:bg-white hover:text-red text-white bg-red border-red hover:border-red font-stratos font-bold whitespace-pre trans inline-block text-base desktop:py-2 laptop:py-1 tablet:text-sm py-2px px-4 laptop:text-base text-center border-6 tablet:border-4 laptop:border-5"
                      onClick={() => this.handleClose()}
                    >
                      {translateObj(item.text, location.language)}
                    </Link>
                  </div>
                );
                */ })}
            </div>
            <div data-name="close-wrapper" className="flex items-center self-stretch justify-center ml-4 laptop:ml-0 tablet:w-42px laptop:w-24">
              <button
                data-name="megamenu-close-button"
                type="button"
                className="flex items-center justify-center w-8 h-8 uppercase font-stratos flex-0"
                onClick={() => this.handleClose()}
                onMouseEnter={() => rotateClose(true)}
                onMouseLeave={() => rotateClose(false)}
              >
                <span className="font-icon icon-close text-red text-24 tablet:text-30 pointer-events-none" />
              </button>
            </div>
            <div className="absolute mx-5 -mt-px border-b-2 laptop:mx-24 tablet:mx-42px pin-b pin-l pin-r border-red" />
          </div>
          <div data-name="container-main" className="flex flex-col items-stretch justify-start w-full px-5 laptop:py-6 tablet:px-42px laptop:px-20 laptop:flex-row laptop:items-stretch laptop:justify-between">
            <ComponentIterator components={main} />
          </div>
          <div data-name="megamenu-footer-container" className="w-full px-5 pb-8 tablet:pb-42px -mt-2px laptop:mt-0 tablet:px-42px laptop:px-24 laptop:pb-12">
            <ComponentIterator components={handleItems(footer)} />
          </div>
        </div>
      </div>
    );
  }
}

TopHeaderMegamenu.propTypes = {
  localization: PropTypes.shape(),
  activeLink: PropTypes.shape(),
  history: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  main: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  footer: PropTypes.array.isRequired,
  toggleItemCallback: PropTypes.func,
  goTo: PropTypes.func,
  // links: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  nextSection: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
};

TopHeaderMegamenu.defaultProps = {
  localization: undefined,
  activeLink: undefined,
  toggleItemCallback: undefined,
  goTo: undefined,
};

export default withRouter(TopHeaderMegamenu);
TopHeaderMegamenu.contextType = UserProfileContext;
