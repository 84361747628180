import React from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import RoutesContext from "../../context/routesContext";
import getLocalization from "../../util/localization";
import translateObj from "../../util/translateObj";
import RelatedItem from "./RelatedItem";

const SidebarRelatedNews = ({ match, data }) => {
  const translations = {
    es: "Últimas noticias",
    en: "Latest news",
    ca: "Últimes notícies"
  };
  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(
          contextLanguage,
          enteredFrom,
          match
        );
        return (
          <React.Fragment>
            <p className="font-americaMonoBold text-base pb-7 mt-7.5 uppercase border-b border-black">
              {translateObj(translations, localization.language)}
            </p>
            <div>
              {data &&
                data.map((post, i) => <RelatedItem post={post} key={i} />)}
            </div>
          </React.Fragment>
        );
      }}
    </RoutesContext.Consumer>
  );
};

SidebarRelatedNews.propTypes = {
  match: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default withRouter(SidebarRelatedNews);
