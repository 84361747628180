import React from 'react';
import PropTypes from 'prop-types';

const TopHeaderSubmenu = ({
  children, name, mainParent, backgroundColor, style,
}) => {
  const cssClassesContainer = parent => (
    parent === 'footer'
      ? `ml-0 sm:flex-col sm:h-auto sm:pb-8 sm:pt-4 sm:items-center 
      md:h-16 md:flex-col md:justify-center md:items-end md:flex-col md:justify-center md:items-end
      lg:h-16 lg:flex-col lg:justify-center lg:items-end lg:flex-col lg:justify-center lg:items-end
      xl:pl-15` : (parent === 'footer-xl' ? 'sm:flex-col sm:h-auto sm:pb-8 sm:pt-4 sm:items-center h-auto md:h-16 md:flex-col md:justify-center md:items-end md:flex-col md:justify-end lg:flex-col lg:justify-center lg:items-end lg:flex-col lg:justify-center lg:items-end xl:flex-col xl:items-end' : 'sm:ml-1/3 ml-68')
  );

  const cssClassesTriangle = parent => (
    parent === 'footer' ? 'triangle-transparent sm:hidden' : (parent === 'footer-xl' ? 'triangle-transparent-xl sm:hidden' : `triangle-${backgroundColor}`
    )
  );

  return (
    <div id={name} className={`flex bg-${backgroundColor} h-9 justify-between items-center ${cssClassesContainer(mainParent)}`}>
      {/* <div className={`${style || 'h-9'} ${cssClassesTriangle(mainParent)}`} /> */}
      { children }
    </div>
  );
};

TopHeaderSubmenu.propTypes = {
  name: PropTypes.string.isRequired,
  mainParent: PropTypes.string,
  children: PropTypes.shape().isRequired,
  backgroundColor: PropTypes.string,
  style: PropTypes.string,

};

TopHeaderSubmenu.defaultProps = {
  mainParent: null,
  backgroundColor: 'transparent',
  style: '',

};

export default TopHeaderSubmenu;
