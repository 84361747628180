import React from 'react';
import { PropTypes } from 'prop-types';

export const RDL_SUBSCRIPTION_ROLE = 'rdl-subscription';

export const checkSubscriptionContent = roles => roles.length === 1 && roles.includes(RDL_SUBSCRIPTION_ROLE);

const SubscriptionTag = ({ isAbsolute, className, rolesAllowed }) => {
  if (checkSubscriptionContent(rolesAllowed) === false) return null;
  return (
    <div className={`${isAbsolute ? 'absolute pin-b pin-r border-none' : ''} ${className} flex`}>
      <div className={`${className} border-t-8 border-l-8 px-2 pt-1 pb-1 bg-white leading-tight text-11px border-red font-stratos uppercase font-bold`}>
        <p>Bajo</p>
        <p>Suscripción</p>
      </div>
    </div>
  );
};

SubscriptionTag.propTypes = {
  isAbsolute: PropTypes.bool,
  className: PropTypes.string,
  rolesAllowed: PropTypes.arrayOf(PropTypes.string),
};

SubscriptionTag.defaultProps = {
  isAbsolute: false,
  className: '',
  rolesAllowed: [],
};

export default SubscriptionTag;
