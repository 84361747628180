import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';


const TopHeaderMegamenuSubmenuText = ({
  name, text, match,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <div data-name="submenu-text" className="sm:w-full w-2/5 ml-8px lgsm:px-1 md:px-1 lgsm:w-1/2">
          <span className="block mb-5 sm:mb-3 font-americaMonoRegular text-xs uppercase">{!name[localization.language] ? name.en : name[localization.language]}</span>
          <span className="block mb-6 font-tiemposRegular text-base leading-normal smlg:mb-1 lgsm:pr-2">
            {!text[localization.language] ? text.en : text[localization.language]}
          </span>
          <span className="text-black block flex">
            <span className="font-icon icon-radio-ps text-black text-80" />
            <span className="font-icon icon-seat text-black text-52 pl-3 pt-4" />
          </span>
        </div>
      );
    }
  }
  </RoutesContext.Consumer>
);

TopHeaderMegamenuSubmenuText.propTypes = {
  name: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

TopHeaderMegamenuSubmenuText.defaultProps = {
};

export default withRouter(TopHeaderMegamenuSubmenuText);
