import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import RoutesContext from '../../context/routesContext';
import PSResponsiveUtility from './LineUp/PSResponsiveUtility';

const MagazineBlock = ({ isArticleHeader }) => {
  const responsiveUtility = useRef();
  const [deviceScreen, setDeviceScreen] = useState();
  const [postDescription, setPostDescription] = useState(null);
  const { currentPostDescription = false } = useContext(RoutesContext);

  const handleResize = (device) => {
    setDeviceScreen(device);
  };

  useEffect(() => {
    if (responsiveUtility.current) {
      const device = responsiveUtility.current.deviceScreen(true);
      handleResize(device);
    }
  }, []);

  useEffect(() => {
  }, [deviceScreen]);

  useEffect(() => {
    if (currentPostDescription) {
      setPostDescription(currentPostDescription);
    }
  }, [currentPostDescription]);

  const magazineTitleSplit = postDescription && postDescription.relatedMagazine ? postDescription.relatedMagazine.title.split(/([()][^()]*[()])/) : null;

  if (isArticleHeader && deviceScreen && deviceScreen !== 'mobile') {
    if (postDescription && postDescription.relatedMagazine) {
      return (
        <p className="text-normal text-14 font-stratos text-grey">{`${postDescription.relatedMagazine.title}`}</p>
      );
    }
    return null;
  }

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={handleResize} rdlSizes />
      <div className="w-full">
        <div className="border-t border-black border-solid w-full mb-7 tablet:mb-9 desktop:mb-16">
          {magazineTitleSplit !== null
          && (
          <>
            <p className="text-black font-stratos text-13 laptop:text-14 desktop:text-base pt-1 laptop:pt-4 desktop:pt-3 font-light">{`${(magazineTitleSplit[0] && magazineTitleSplit[0]) || ''}`}</p>
            <p className="text-black font-stratos text-13 laptop:text-14 desktop:text-base font-light">{`${(magazineTitleSplit[1] && magazineTitleSplit[1]) || ''} ${(magazineTitleSplit[2] && magazineTitleSplit[2]) || ''} ${(magazineTitleSplit[3] && magazineTitleSplit[3]) || ''}`}</p>
          </>
          )
          }
        </div>
      </div>
    </>
  );
};

MagazineBlock.propTypes = {
  isArticleHeader: PropTypes.bool,
};
MagazineBlock.defaultProps = {
  isArticleHeader: false,
};

export default MagazineBlock;
