import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const COMMENTS_REMOVE = gql`
  mutation Post($commentId: String!) {
    commentRemove(commentId: $commentId) {
      result
      error
    }
  }
`;

const commentRemove = async (commentId) => {
  try {
    const result = await clientWeb
      .mutate({
        mutation: COMMENTS_REMOVE,
        variables: { commentId },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const {
      data: { commentRemove: commentRemoveData },
    } = result;

    if (commentRemoveData) {
      return commentRemoveData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: commentRemove`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: commentRemove Error: ${error}`);
    return null;
  }
};

export default commentRemove;
