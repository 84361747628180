/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const StandardFormCheckboxConditions = ({
  acceptText, conditionsText, conditionsLink, dataTreatmentText, required, privacyPolicyText, name, privacyPolicyUrl, getCheckBoxValue, cancelationPolicy, monthPolicy,
}) => {
  const [value, setValue] = useState(false);

  const changeValue = (e) => {
    getCheckBoxValue(e.currentTarget.checked);
    setValue(e.currentTarget.checked);
  };

  return (
    <>
      <div className="flex items-start justify-between pt-6 -ml-2">
        <Checkbox
          icon={<div className="border-1 border-black h-7 w-7" />}
          checkedIcon={<div className="border-1 border-black h-7 w-7 flex items-center justify-center"><span className="font-icon icon-close text-base font-bold text-red p-1" /></div>}
          onChange={changeValue}
          id={name}
          checked={value}
          data-checked={value}
          style={{ color: `${value ? '#FF4646' : 'black'}` }} />
        <span className="ml-3 mt-2 font-stratos text-14 font-light leading-snug">
          {/* {required && <span className="text-12 align-top text-red-light">* </span>} */}
          {`${acceptText} `}
          {conditionsText && conditionsLink ? <Link className="text-black cursor-pointer" to={conditionsLink}>{conditionsText}</Link> : ''}
          {`${dataTreatmentText} `}
          {privacyPolicyText && privacyPolicyUrl ? <Link className="text-black cursor-pointer" to={privacyPolicyUrl}>{privacyPolicyText}</Link>
            : ''}
        </span>
      </div>
      { cancelationPolicy && (
      <div className="ml-2 mt-3 font-stratos text-11 text-center font-light italic">
        <span>
          {cancelationPolicy}
        </span>
      </div>
      )}
      { monthPolicy && (
        <div className="ml-2 font-stratos text-11 text-center font-light italic">
          <span>
            {monthPolicy}
          </span>
        </div>
      )}
    </>
  );
};

StandardFormCheckboxConditions.propTypes = {
  getCheckBoxValue: PropTypes.func.isRequired,
  acceptText: PropTypes.string.isRequired,
  conditionsText: PropTypes.string.isRequired,
  dataTreatmentText: PropTypes.string.isRequired,
  privacyPolicyText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  conditionsLink: PropTypes.string,
  privacyPolicyUrl: PropTypes.string,
  cancelationPolicy: PropTypes.string,
  monthPolicy: PropTypes.string,
};

StandardFormCheckboxConditions.defaultProps = {
  conditionsLink: '',
  required: false,
  privacyPolicyUrl: '',
  cancelationPolicy: '',
  monthPolicy: '',
};

export default StandardFormCheckboxConditions;
