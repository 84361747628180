import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import RoutesContext from '../../../../../context/routesContext';
import getLocalization from '../../../../../util/localization';
import translateObj from '../../../../../util/translateObj';
import SimpleButton from '../../../Buttons/SimpleButton';
import MyErrorComponent from '../../../../MyErrorComponent';

const SubscriptionFormsy = ({
  match,
  unsubscribeCallback,
  changeSubscriptionCallback,
  renewSubscription,
  activeSubscription,
  cards,
  renewalProduct,
  nextPaymentDay,
  subscriptionData,
  subscriptionSectionError,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      const translatedTexts = {
        activeSubscription: {
          es: 'Suscripción activa:',
          en: 'Suscripción activa:',
        },
        renewalSubscription: {
          es: 'Próximo cambio a Suscripción:',
          en: 'Próximo cambio a Suscripción:',
        },
        renewalSubscriptionInfo: {
          es: 'el cambio se realizará en fecha',
          en: 'el cambio se realizará en fecha',
        },
        nextPayment: {
          es: 'Próximo cargo en tu tarjeta:',
          en: 'Próximo cargo en tu tarjeta:',
        },
        subscriptionFinalDate: {
          es: 'Suscripción activa hasta:',
          en: 'Suscripción activa hasta:',
        },
        subscriptionAddCard: {
          es: 'Para renovar tu suscripción, deberás añadir un método de pago.',
          en: 'Para renovar tu suscripción, deberás añadir un método de pago.',
        },
        unsubscribe: {
          es: 'Darme de baja',
          en: 'Darme de baja',
        },
        resubscribe: {
          es: 'Reactivar suscripción',
          en: 'Reactivar suscripción',
        },
        changeSubscription: {
          es: 'Cambiar suscripción',
          en: 'Cambiar suscripción',
        },
        notRenewed: {
          en: 'Tu suscripción no será renovada',
          es: 'Tu suscripción no será renovada',
        },
        storedCard: {
          en: 'Tarjeta bancaria',
          es: 'Tarjeta bancaria',
        },
      };

      const subscriptionType = (subscriptionProduct) => {
        switch (subscriptionProduct) {
          case 'rockdelux-subscription-1-month':
            return { es: 'Mensual', en: 'Mensual' };
          case 'rockdelux-subscription-1-year':
            return { es: 'Anual', en: 'Anual' };
          default:
            return { es: 'Ninguna', en: 'Ninguna' };
        }
      };
      const subscriptionLastDate = nextPaymentDay ? moment(Number(nextPaymentDay)).format('DD.MM.YYYY') : translateObj(translatedTexts.notRenewed, localization.language);
      const canBeCharged = nextPaymentDay && !(subscriptionData.provider === 'stripe' && !cards?.length);

      const noRenewalPossibleContent = () => {
        return (
          <MyErrorComponent>
            <p className="mt-2">{translateObj(translatedTexts.subscriptionAddCard, localization.language)}</p>
          </MyErrorComponent>
        )
      }

      return (
        <MyErrorComponent>
          <div className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5">
            <div className="mb-6 laptop:mb-0 font-stratos text-20 text-black col-span-4 tablet:col-span-6 laptop:col-span-5 tablet:col-start-2 laptop:col-start-2">
              <strong className="block">{translateObj(translatedTexts.subscriptionFinalDate, localization.language)}</strong>
              <p>{subscriptionLastDate}</p>
              <p>&nbsp;</p>
              { 
                !canBeCharged && noRenewalPossibleContent()
              }
              { canBeCharged && activeSubscription && (
                <MyErrorComponent>
                  <strong className="block">{translateObj(translatedTexts.activeSubscription, localization.language)}</strong>
                  <p>
                    {translateObj(subscriptionType(activeSubscription), localization.language)}
                    {' '}
                    (
                    {subscriptionData.provider === 'stripe' ? translateObj(translatedTexts.storedCard) : subscriptionData.provider}
                    )
                  </p>
                </MyErrorComponent>
              )}
              { canBeCharged && renewSubscription && activeSubscription && renewalProduct && renewalProduct !== activeSubscription && nextPaymentDay && (
                <div className="mt-8">
                  <MyErrorComponent>
                    <strong className="block">{translateObj(translatedTexts.renewalSubscription, localization.language)}</strong>
                    <p>
                      {translateObj(subscriptionType(renewalProduct), localization.language)}
                      {' '}
                      (
                      {translateObj(translatedTexts.renewalSubscriptionInfo, localization.language)}
                      {' '}
                      {subscriptionLastDate}
                      )
                    </p>
                  </MyErrorComponent>
                </div>
              )}
              { canBeCharged && renewSubscription && activeSubscription && subscriptionLastDate && (
                <div className="mt-8">
                  <strong className="block">{translateObj(translatedTexts.nextPayment, localization.language)}</strong>
                  <p>{subscriptionLastDate}</p>
                </div>
              )}
            </div>
            { canBeCharged && activeSubscription && (
              <div
                className="col-span-3 laptop:col-span-2 col-end-5 tablet:col-end-11 laptop:col-end-9 row-start-2 cursor-pointer flex flex-col items-stretch justify-end laptop:-mt-12"
              >
                <div className="mb-10px" onClick={() => unsubscribeCallback()}>
                  <SimpleButton
                    isDisabled
                    isLowercase
                    isFullWidth
                    theme={{
                      base: 'red',
                      hover: 'red',
                    }}
                    text={translateObj(renewSubscription ? translatedTexts.unsubscribe : translatedTexts.resubscribe, localization.language)}
                  />
                </div>
                { subscriptionData.provider === 'stripe' && (
                  <div onClick={() => changeSubscriptionCallback()}>
                    <SimpleButton
                      isDisabled
                      isLowercase
                      isFullWidth
                      theme={{
                        base: 'red',
                        hover: 'red',
                      }}
                      text={translateObj(translatedTexts.changeSubscription, localization.language)}
                    />
                  </div>
                )}
                {subscriptionSectionError && (<div className="text-red mt-4 font-stratos">{subscriptionSectionError}</div>)}
              </div>
            )}
          </div>
        </MyErrorComponent>
      );
    }}
  </RoutesContext.Consumer>
);

export default SubscriptionFormsy;

SubscriptionFormsy.propTypes = {
  match: PropTypes.shape({}).isRequired,
  unsubscribeCallback: PropTypes.func.isRequired,
  changeSubscriptionCallback: PropTypes.func.isRequired,
  renewSubscription: PropTypes.bool,
  activeSubscription: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      provider: PropTypes.string,
      token: PropTypes.string,
      createdAt: PropTypes.date,
      card: PropTypes.shape({
        last4: PropTypes.string,
        brand: PropTypes.string,
        exp_month: PropTypes.number,
        exp_year: PropTypes.number,
      }),
    }),
  ),
  renewalProduct: PropTypes.string,
  nextPaymentDay: PropTypes.string,
  subscriptionData: PropTypes.shape({}),
  subscriptionSectionError: PropTypes.string,
};

SubscriptionFormsy.defaultProps = {
  activeSubscription: null,
  cards: null,
  renewalProduct: null,
  renewSubscription: false,
  nextPaymentDay: null,
  subscriptionData: {},
  subscriptionSectionError: '',
};
