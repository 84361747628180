import React from 'react';
import PropTypes from 'prop-types';
import VenueColumn from './VenueColumn';
import HoursColumn from './HoursColumn';
import SwitchLayoutButton from './SwitchLayoutButton';
import VenueLabel from './VenueLabel';
import DayLabel from './DayLabel';

const LayoutColumn = ({
  day,
  artistsByDay,
  venues,
  firstColumnRef,
  startAndEndHours,
  layout,
  localization,
  showArtistCallback,
  hideArtistCallback,
  fixedColumnRef,
  toggleLayoutCallback,
  draggableRef,
  draggableHeaderRef,
  fixHeadRef,
  contentOverflows,
  dayId,
  hasConferences,
  eventSlug,
}) => (
  <div className="relative w-full">
    <div ref={fixHeadRef} className={`header-${dayId} z-10 w-full bg-white-dark`}>
      <DayLabel day={day} />
      <div className="flex items-stretch relative w-full">
        <SwitchLayoutButton layout={layout} toggleLayoutCallback={toggleLayoutCallback} />
        <div className="overflow-hidden border-b-1 border-solid border-white">
          <div
            ref={draggableHeaderRef}
            className={`inline-flex ${contentOverflows && 'cursor-ew-resize'}`}
            style={{
              backfaceVisibility: 'hidden',
              perspective: 1000,
            }}
          >
            {Object.keys(artistsByDay[day]).map(venue => (
              <div key={venue} className={`border-r-1 border-solid border-white ${layout === 'columns' ? 'bg-grayE2DDDB' : 'bg-gray200'}`}>
                <VenueLabel layout={layout} title={venues[venue][localization.language]} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="flex items-start w-full relative z-0">
      <HoursColumn
        fixedColumnRef={fixedColumnRef}
        day={day}
        startAndEndHours={startAndEndHours}
        layout={layout}
      />
      <div className="overflow-hidden">
        <div
          ref={draggableRef}
          className={`inline-flex ${contentOverflows && 'cursor-ew-resize'}`}
          style={{
            backfaceVisibility: 'hidden',
            perspective: 1000,
          }}
        >
          {Object.keys(artistsByDay[day]).map((venue, index) => (
            <VenueColumn
              eventSlug={eventSlug}
              artists={artistsByDay[day][venue]}
              artistsByDay={artistsByDay}
              day={day}
              firstColumnRef={firstColumnRef}
              hideArtistCallback={hideArtistCallback}
              index={index}
              key={venue}
              layout={layout}
              localization={localization}
              showArtistCallback={showArtistCallback}
              startAndEndHours={startAndEndHours}
              venue={venues[venue]}
              hasConferences={hasConferences}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
);

LayoutColumn.propTypes = {
  day: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  dayId: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  localization: PropTypes.shape().isRequired,
  artistsByDay: PropTypes.shape().isRequired,
  startAndEndHours: PropTypes.shape().isRequired,
  venues: PropTypes.shape().isRequired,
  showArtistCallback: PropTypes.func.isRequired,
  hideArtistCallback: PropTypes.func.isRequired,
  fixedColumnRef: PropTypes.shape().isRequired,
  draggableRef: PropTypes.shape().isRequired,
  draggableHeaderRef: PropTypes.shape().isRequired,
  fixHeadRef: PropTypes.shape().isRequired,
  toggleLayoutCallback: PropTypes.func.isRequired,
  contentOverflows: PropTypes.bool.isRequired,
  hasConferences: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  firstColumnRef: PropTypes.object.isRequired,
};

LayoutColumn.defaultProps = {
  hasConferences: false,
};
export default LayoutColumn;
