import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';

const AuthorBlock = ({
  parentComponent,
  isArticleHeader,
}) => {
  const [postDescription, setPostDescription] = useState(null);
  const { currentPostDescription = false } = useContext(RoutesContext);

  const getContainerStyles = () => {
    let styles = 'w-full';
    if (isArticleHeader) {
      styles = 'laptop:float-left tablet:w-max-content w-full tablet:w-auto';
    }

    return styles;
  };

  const getWrapperStyles = () => {
    let styles = '';
    switch (parentComponent) {
      case 'NewsPostPage':
        styles = 'border-t-3 border-black border-solid w-full mb-5 tablet:border-none tablet:w-auto tablet:mb-0';
        break;
      default:
        styles = 'border-t-3 border-black border-solid w-full mb-5 tablet:mb-6 laptop:mb-13 desktop:mb-15';
        break;
    }
    return styles;
  };

  const getTextStyles = () => {
    let styles = '';
    switch (parentComponent) {
      case 'NewsPostPage':
        styles = 'font-semibold tablet:font-normal text-14 font-stratos text-black tablet:text-grey pt-1 laptop:pt-0';
        break;
      default:
        styles = 'text-black font-stratos text-14 laptop:text-15 desktop:text-18 pt-1 laptop:pt-3 font-semibold';
        break;
    }
    return styles;
  };

  useEffect(() => {
    if (currentPostDescription) {
      setPostDescription(currentPostDescription);
    }
  }, [currentPostDescription]);

  const authorNames = postDescription && postDescription.authors && postDescription.authors.length; // postDescription.authors.map(author => author.name).join('∑').replace(/[∑](?=.*[∑])/g, ", ").replace('∑', " y ");
  const createNames = () => {
    const items = [];
    if (postDescription && postDescription.authors && postDescription.authors.length) {
      if (postDescription.authors[0].slug === 'rockdelux-autor') {
        // If first author is Rockdelux, then show only this author
        items.push(<a key="rock-autor" className="text-black" href={`/autor?q=${encodeURIComponent(postDescription.authors[0].name)}`}>{postDescription.authors[0].name}</a>);
      } else {
        postDescription.authors.forEach((author, index) => {
          if (index > 0 && index !== postDescription.authors.length - 1) items.push(<span>, </span>);
          if (index > 0 && index === postDescription.authors.length - 1) items.push(<span> y </span>);
          items.push(<a key={author.name} className="text-black" href={`/autor?q=${encodeURIComponent(author.name)}`}>{author.name}</a>);
        });
      }
    }
    return (<>{items}</>);
  };

  return (
    <>
      <div className={getContainerStyles()}>
        <div className={getWrapperStyles()}>
          {authorNames
              && (
              <p className={getTextStyles()}>
                Por
                {' '}
                {createNames()}
                {parentComponent === 'NewsPostPage' && <span className="hidden tablet:inline-block tablet:px-2">→ </span>}
              </p>
              )}
        </div>
      </div>
    </>
  );
};

AuthorBlock.propTypes = {
  parentComponent: PropTypes.string,
  isArticleHeader: PropTypes.bool,
};

AuthorBlock.defaultProps = {
  parentComponent: null,
  isArticleHeader: false,
};

export default withRouter(AuthorBlock);
