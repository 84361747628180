
import React from 'react';

import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ParagraphLineSM from '../Text/ParagraphLineSM';
import DropDownMenu from './DropDownMenu';

const ExpandLess = () => <span className="font-icon icon-arrow-up font-black text-white text-xs" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down font-black text-white text-xs" />;

const getStyle = (selected, listItemStyle) => {
  if (selected) {
    return {
      color: 'white',
      backgroundColor: 'black',
    };
  }

  if (listItemStyle.backgroundColor === 'black') return { color: 'white' };

  return {};
};


class CollapsibleMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDown: '',
    };
  }

  handleClick = (type) => {
    let dropDown = type;
    this.setState((state) => {
      if (dropDown === state.dropDown) dropDown = '';
      return { dropDown };
    });
  };


  render() {
    const {
      params, filterTypes, filterItems, menuTitle, text,
    } = this.props;
    const { dropDown } = this.state;

    const listItemStyle = {
      borderBottom: 'dashed 1px white',
      backgroundColor: 'black',
      color: 'white',
    };
    return (
      <div>


        <Divider />

        <React.Fragment key={text.en}>


          <ListItem
            style={{
              ...listItemStyle,
              ...getStyle(dropDown === text.en, listItemStyle),
            }}
            value={text.en}
            button
            onClick={() => this.handleClick(text.en)}
          >
            <ListItemText
              primary={(
                <span
                  style={
                    getStyle(dropDown === text.en, listItemStyle)
                  }
                >
                  {text.en.toUpperCase()}
                </span>
              )}
            />

            {dropDown === text.en ? <ExpandLess /> : <ExpandMore />}

          </ListItem>


          <Collapse in={dropDown === text.en} timeout="auto" unmountOnExit>
            <div className="py-8 flex flex-wrap bg-black">
              {
                filterTypes.map(filter => (
                  <div className="w-1/2 my-2">
                    <div className="mx-5">
                      <ParagraphLineSM color="white" text={filter.text} />

                      <div>
                        <DropDownMenu
                          white="true"
                          menuTitle={filter.title}
                          menuItems={
                            filter.items
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Collapse>

        </React.Fragment>


        <Divider />

      </div>
    );
  }
}

CollapsibleMenu.propTypes = {
  classes: PropTypes.shape({}),
  listItemStyle: PropTypes.object,
  params: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.shape({
      en: PropTypes.string.isRequired,
      es: PropTypes.string.isRequired,
      content: PropTypes.array,
    }).isRequired,
  })),
  children: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CollapsibleMenu;
