import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';

class FooterComponent extends Component {
  componentDidMount = () => { }

  render() {
    const { children, playerPosition, showRadioPlayer } = this.props;

    return (
      <div id="footerComponent" className="flex w-full flex-col static pin-b">
        { children}
      </div>
    );
  }
}

FooterComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  playerPosition: PropTypes.string,
  showRadioPlayer: PropTypes.bool,
};

FooterComponent.defaultProps = {
  playerPosition: null,
  showRadioPlayer: null,
};

export default withRadioPlayerContext(FooterComponent);
