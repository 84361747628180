import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

const NotificationBanner = (
  {
    isActivationBarOpen,
    messageText,
    buttonText,
    buttonOnClick,
  },
) => {
  const [userEmail, setUserEmail] = useState('');
  const [isBannerClosed, setIsBannerClosed] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);

  const responsiveUtility = useRef;
  const bannerRef = useRef;


  const setBannerState = () => {
    // const heightAdj = (userEmail && isActivationBarOpen) ? '145px' : '105px';
    setIsBannerClosed(!isActivationBarOpen);
  };

  useEffect(() => {
    const event = new CustomEvent('checkBodyTopSpacing');
    window.dispatchEvent(event);
    setBannerState();
  }, [userEmail, isActivationBarOpen]);

  const getBannerHeight = () => {
    const event = new CustomEvent('checkBodyTopSpacing');
    window.dispatchEvent(event);
    const banner = bannerRef.current;
    if (banner && banner.current) {
      setBannerHeight(banner.offsetHeight);
      this.dispatchCustomEvent();
    }
  };

  useEffect(() => {
    if (!isBannerClosed) {
      getBannerHeight();
    }
  });

  const closeBanner = () => {
    setIsBannerClosed(true);
    const event = new CustomEvent('checkBodyTopSpacing', {
      detail: {
        closed: true,
      },
    });
    window.dispatchEvent(event);
  };

  return (
    <>
      {(!isBannerClosed && messageText) ? (
        <>
          <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getBannerHeight} />
          <div id="banner" ref={bannerRef} className="bg-red w-full p-5 pr-5 laptop:pr-10 laptop:pr-12 desktop:p-6 desktop:pr-15 flex items-center z-radio justify-between">
            <div className="pl-1 flex items-center justify-start">
              <span className="font-icon icon-caution-circle font-black text-white text-26" />
              <span className="ml-1 tablet:ml-2 text-sm laptop:text-18 desktop:text-20 font-stratos font-bold text-white mr-2">{messageText}</span>
            </div>
            <div className="flex items-center justify-end">
              {buttonText && (
                <button
                  type="button"
                  className="flex justify-center trans items-center font-stratos text-sm laptop:text-base desktop:text-sm border-5 border-white font-bold hover:text-red hover:border-red hover:bg-white px-6 py-6px text-white"
                  onClick={buttonOnClick}
                >
                  <span>{buttonText}</span>
                </button>
              )}
              <button type="button" className="ml-3 tablet:ml-4 laptop:ml-8 desktop:ml-7" onClick={closeBanner}>
                <span className="font-icon icon-close font-black text-white text-18 laptop:text-20" />
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

NotificationBanner.propTypes = {
  messageText: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
};

NotificationBanner.defaultProps = {
  buttonText: '',
  buttonOnClick: null,
};

export default NotificationBanner;
