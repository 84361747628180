import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const FooterLogo = ({ url, name, match }) => (
  // color of the tag is determined by the tag category
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div data-name="footer-logo" id={name} className="flex items-center justify-center tablet:flex-1 tablet:block">
            <Link to="/">
              <img className="w-182px tablet:w-246px" src={translateObj(url, localization.language)} alt={name} />
            </Link>
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

FooterLogo.propTypes = {
  url: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
};

export default withRouter(FooterLogo);
