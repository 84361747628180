import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentIterator from '../../../containers/ComponentIterator';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import RoutesContext from '../../../context/routesContext';
import SubscriptionTag from '../SubscriptionTag';

class StandardArticlePage extends Component {
  static contextType = RoutesContext

  state = {
    deviceScreen: null,
  };

  responsiveUtility = React.createRef();

  componentDidMount() {
    const deviceScreen = this.responsiveUtility.current.deviceScreen(true);
    this.handleResize(deviceScreen);
    this.setParentComponent();
  }

  componentDidUpdate() {
    this.setParentComponent();
  }


  handleResize = (deviceScreen) => {
    this.setState({ deviceScreen });
  }

  setParentComponent = () => {
    const { components } = this.props;
    if (components && components.length > 0) {
      components.forEach((component) => {
        Object.assign(component, { parentComponent: 'StandardArticlePage' });
        if (component.type === 'TaggedItem') {
          Object.assign(component, { overlap: true });
        }
        if (Object.keys(component).includes('text')) {
          if (Object.keys(component.text).length > 0 && component.text.en) {
            if (['<p></p>', '<p><br></p>'].includes(component.text.en)) {
              Object.assign(component, { hideWhileEmptyText: true });
            }
          }
        }
        // console.log("🚀 ~ file: StandardArticlePage.jsx ~ line 31 ~ StandardArticlePage ~ components.forEach ~ component", component)
      });
    }
  }

  render() {
    const { currentSlugName, currentRolesAllowed: { rolesAllowed } } = this.context;
    const { deviceScreen } = this.state;
    let { components } = this.props;

    const filteredComponents = components.filter(component => !component.hideWhileEmptyText || component.hideWhileEmptyText !== true);
    components = filteredComponents;

    let header = {};
    let tag = {};
    let title = {};
    let subTitle = {};
    let enhanced = {};
    let author = {};
    let date = {};
    let magazine = {};
    let socialTop = {};
    let socialBottom = {};
    let tags = {};
    let related = {};
    let comments = {};
    let body = [];

    if (components && components.length > 0) {
      header = components.find(component => component.type === 'MediaDescription' && typeof component.layout !== 'undefined' && ['head', 'head_alternative'].includes(component.layout)) || {};
      tag = components.find(component => component.type === 'TaggedItem') || {};
      title = components.find(component => component.type === 'TitleLineH1') || {};
      subTitle = components.find(component => component.type === 'TitleLineH2' && !component.enhanced) || {};
      enhanced = components.find(component => ((component.type === 'NormalBodyText' && component.enhanced === true) || (component.type === 'TitleLineH2' && component.enhanced === true))) || {};
      author = components.find(component => component.type === 'AuthorBlock') || {};
      date = components.find(component => component.type === 'DateBlock') || {};
      magazine = components.find(component => component.type === 'MagazineBlock') || {};
      socialTop = components.find(component => component.type === 'SharingBlock' && component.size !== 'small') || {};
      socialBottom = components.find(component => component.type === 'SharingBlock' && component.size === 'small') || {};
      tags = components.find(component => component.type === 'TagsBlock') || {};
      comments = components.find(component => component.type === 'CommentsBox') || {};
      related = components.find(component => component.type === 'GroupedItems') || {};

      const bodyItems = [];
      components.forEach((item) => {
        if (![header, tag, title, subTitle, enhanced, author, date, magazine, socialTop, socialBottom, tags, comments, related].includes(item)) {
          bodyItems.push(item);
        }
      });
      body = bodyItems;
    }

    const itemsMissing = (Object.keys(subTitle).length === 0) || (Object.keys(enhanced).length === 0);
    const bodyStartsWithTitle = body[0].type.includes('TitleLine');
    const bodyStartsWithEnhancedText = body[0].type === 'NormalBodyText' && body[0].enhanced === true;

    if (bodyStartsWithTitle || bodyStartsWithEnhancedText) {
      Object.assign(body[0], { firstInBody: true });
    }

    return (
      <div key={currentSlugName} className="full-width">
        <PSResponsiveUtility ref={this.responsiveUtility} deviceScreenCallback={this.handleResize} rdlSizes />
        <div className="w-full">
          <div className="w-full">
            {components && header && (
              <ComponentIterator components={[header]} />
            )}
          </div>
          <div className="grid w-full grid-cols-6 gap-3 px-4 tablet:px-11 laptop:px-90px tablet:grid-cols-12 laptop:gap-4 desktop:mx-auto max-w-rdl-xxl">
            <div className="z-10 col-start-1 col-span-6 tablet:col-span-12 laptop:col-start-2 laptop:col-end-12">
              {components && (tag || title || subTitle || enhanced) && (
                <ComponentIterator components={[tag, title, subTitle, enhanced]} />
              )}
            </div>
          </div>
          {['laptop', 'desktop'].includes(deviceScreen) ? (
            <div className="w-full laptop:-mt-5 desktop:-mt-2">
              <div className={`grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl ${itemsMissing ? 'mt-8 laptop:mt-15' : ''}`}>
                <div className="col-start-2 col-end-4 mt-1 desktop:mt-2 pointer-events-auto">
                  {components && (author || date || magazine || socialTop) && (
                    <ComponentIterator components={[author, date, magazine, socialTop]} />
                  )}
                </div>
                <div className="col-start-5 col-end-12">
                  <SubscriptionTag className="mb-6" rolesAllowed={rolesAllowed} />
                  {components && body && body.length > 0 && (
                    <ComponentIterator components={bodyStartsWithTitle ? [body[0], body[1]] : [body[0]]} />
                  )}
                </div>
              </div>
              {components && body && body.map((bodyItem, index) => {
                if ((bodyStartsWithTitle && ![0, 1].includes(index)) || (!bodyStartsWithTitle && index !== 0)) {
                  // MediaDescription layout variables
                  if (bodyItem.type === 'MediaDescription') {
                    if (bodyItem.layout === 'over_text') {
                      return (
                        <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                          <div className="col-start-2 col-end-12">
                            <ComponentIterator components={[bodyItem]} />
                          </div>
                        </div>
                      );
                    }
                    if (bodyItem.layout === 'width_text') {
                      return (
                        <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                          <div className="col-start-5 col-end-12">
                            <ComponentIterator components={[bodyItem]} />
                          </div>
                        </div>
                      );
                    }
                    if (bodyItem.layout === 'none') {
                      return (
                        <div className={`w-full ${bodyItem.type === 'NormalBodyText' && bodyItem.quote === true ? 'my-1 tablet:my-16' : ''}`} key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`}>
                          <ComponentIterator components={[bodyItem]} />
                        </div>
                      );
                    }
                  }
                  // Dynamic components
                  if (bodyItem.type === 'NormalBodyText' && bodyItem.quote === true) {
                    return (
                      <div className={`w-full ${bodyItem.type === 'NormalBodyText' && bodyItem.quote === true ? 'my-1 tablet:my-16' : ''}`} key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`}>
                        <ComponentIterator components={[bodyItem]} />
                      </div>
                    );
                  }
                  if (['NormalBodyText', 'TitleLineH3', 'VideoPlayer'].includes(bodyItem.type)) {
                    return (
                      <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                        <div className="col-start-5 col-end-12">
                          <ComponentIterator components={[bodyItem]} />
                        </div>
                      </div>
                    );
                  }
                  if (bodyItem.type === 'NumberedList') {
                    return (
                      <div key={`ListsPostPage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                        <div className="col-start-4 col-end-12">
                          <ComponentIterator components={[bodyItem]} />
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                      <div className="col-start-2 col-end-12">
                        <ComponentIterator components={[bodyItem]} />
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              <div className="grid w-full px-90px grid-cols-12 gap-4 desktop:mx-auto max-w-rdl-xxl">
                <div className="col-start-2 col-end-4 mt-6">
                  {components && socialBottom && (
                    <ComponentIterator components={[socialBottom]} />
                  )}
                </div>
                <div className="col-start-5 col-end-12 mt-6">
                  {components && tags && (
                    <ComponentIterator components={[tags]} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full">
              <div className="grid grid-cols-6 col-start-1 col-end-6 gap-3 px-4 mt-12 tablet:grid-cols-12 tablet:px-11">
                <div className="col-start-1 col-end-4 row-start-1 laptop:mt-16">
                  {components && (author || date || magazine) && (
                    <ComponentIterator components={[author, date, magazine]} />
                  )}
                </div>
                <div className="col-start-5 col-end-7 row-start-1 tablet:col-start-11 tablet:col-end-13 laptop:mt-16 tablet:mt-4">
                  {components && socialTop && (
                    <ComponentIterator components={[socialTop]} />
                  )}
                </div>
              </div>
              <div rolesAllowed={rolesAllowed} className="grid w-full px-4 tablet:px-11 grid-cols-6 tablet:grid-cols-12 gap-3 my-6 tablet:my-8">
                <SubscriptionTag rolesAllowed={rolesAllowed} />
              </div>
              {components && body && body.map((bodyItem, index) => {
              // MediaDescription layout variables
                if (bodyItem.type === 'MediaDescription') {
                  if (['over_text', 'width_text'].includes(bodyItem.layout)) {
                    return (
                      <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-4 tablet:px-11 grid-cols-6 tablet:grid-cols-12 gap-3 my-6 tablet:my-8">
                        <div className="col-start-1 col-end-7 tablet:col-end-13">
                          <ComponentIterator components={[bodyItem]} />
                        </div>
                      </div>
                    );
                  }
                  if (bodyItem.layout === 'none') {
                    return (
                      <div className="w-full" key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`}>
                        <ComponentIterator components={[bodyItem]} />
                      </div>
                    );
                  }
                }
                // Dynamic components
                if (bodyItem.type === 'NormalBodyText' && bodyItem.quote === true) {
                  return (
                    <div className="w-full" key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`}>
                      <ComponentIterator components={[bodyItem]} />
                    </div>
                  );
                }
                return (
                  <div key={`StandardArticlePage_${bodyItem.compId}_${index + 1}`} className="grid w-full px-4 tablet:px-11 grid-cols-6 tablet:grid-cols-12 gap-3 my-6 tablet:my-8">
                    <div className="col-start-1 col-end-7 tablet:col-end-13">
                      <ComponentIterator components={[bodyItem]} />
                    </div>
                  </div>
                );
              })}
              <div className="grid grid-cols-6 tablet:grid-cols-12 gap-3 px-4 tablet:px-11">
                <div className="tablet:row-start-1 col-start-1 col-end-7 tablet:col-start-4 tablet:col-end-13 tablet:mt-7">
                  {components && tags && (
                    <ComponentIterator components={[tags]} />
                  )}
                </div>
                <div className="tablet:row-start-1 col-start-1 col-end-3 mt-6 mt-7">
                  {components && socialBottom && (
                    <ComponentIterator components={[socialBottom]} />
                  )}
                </div>
              </div>

            </div>
          )}
          <div className="w-full mt-20 laptop:mt-32">
            <div className="grid grid-cols-6 tablet:grid-cols-12 gap-3 tablet:gap-4 px-4 tablet:px-11 laptop:px-90px">
              <div className="col-start-1 col-end-7 tablet:col-end-13 laptop:col-start-2 laptop:col-end-12">
                {components && comments && (
                  <ComponentIterator components={[comments]} />
                )}
              </div>
            </div>
            {components && related && (
              <ComponentIterator components={[related]} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

StandardArticlePage.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
};

StandardArticlePage.defaultProps = {
  components: null,
};

export default StandardArticlePage;
