import React from 'react';
import { PropTypes } from 'prop-types';
import RoutesContext from '../../../../../context/routesContext';
import getLocalization from '../../../../../util/localization';
import translateObj from '../../../../../util/translateObj';
import SimpleButton from '../../../Buttons/SimpleButton';

const DeleteFormsy = ({ match, deleteCallback, renewalCallback }) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);

      const translatedTexts = {
        titleOne: {
          en: '¿Quieres borrar tu usuario de Rockdelux?',
          es: '¿Quieres borrar tu usuario de Rockdelux?',
        },
        titleTwo: {
          en: '¿Perderé toda la información de mi cuenta?',
          es: '¿Perderé toda la información de mi cuenta?',
        },
        titleThree: {
          en: 'Información pública',
          es: 'Información pública',
        },
        titleFour: {
          en: '¿Qué ocurrirá con mi suscripción?',
          es: '¿Qué ocurrirá con mi suscripción?',
        },
        titleFive: {
          en: 'Cancelar la renovación automática de mi suscripción',
          es: 'Cancelar la renovación automática de mi suscripción',
        },
        textOne: {
          en: 'Sí, una vez elimines tu usuario de Rockdelux se perderá todo tu historial: comentarios, método de pago, etc.',
          es: 'Sí, una vez elimines tu usuario de Rockdelux se perderá todo tu historial: comentarios, método de pago, etc.',
        },
        textTwo: {
          en: 'Todos los comentarios, así como cualquier información que hayas hecho pública, no será accesible desde el momento en que elimines tu usuario.',
          es: 'Todos los comentarios, así como cualquier información que hayas hecho pública, no será accesible desde el momento en que elimines tu usuario.',
        },
        textThree: {
          en: 'Si tienes una suscripción activa también se eliminará. No podrás hacer uso de los días que te queden.',
          es: 'Si tienes una suscripción activa también se eliminará. No podrás hacer uso de los días que te queden.',
        },
        textFour: {
          en: 'Si no quieres continuar con tu actual suscripción y que no se te hagan más cargos en tu tarjeta, deberás cambiar la autorrenovación de la suscripción',
          es: 'Si no quieres continuar con tu actual suscripción y que no se te hagan más cargos en tu tarjeta, deberás cambiar la autorrenovación de la suscripción',
        },
        here: {
          en: ' aquí',
          es: ' aquí',
        },
      };

      return (
        <div>
          <div className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mb-5">
            <div className="font-stratos text-20 text-black col-span-4 tablet:col-span-9 laptop:col-span-5 tablet:col-start-2 laptop:col-start-2">
              <strong className="block">{translateObj(translatedTexts.titleOne, localization.language)}</strong>
              <div className="mt-8">
                <strong className="block">{translateObj(translatedTexts.titleTwo, localization.language)}</strong>
                <p>{translateObj(translatedTexts.textOne, localization.language)}</p>
              </div>
              <div className="mt-8">
                <strong className="block">{translateObj(translatedTexts.titleThree, localization.language)}</strong>
                <p>{translateObj(translatedTexts.textTwo, localization.language)}</p>
              </div>
              <div className="mt-8">
                <strong className="block">{translateObj(translatedTexts.titleFour, localization.language)}</strong>
                <p>{translateObj(translatedTexts.textThree, localization.language)}</p>
              </div>
              <div className="mt-8">
                <strong className="block">{translateObj(translatedTexts.titleFive, localization.language)}</strong>
                <p>
                  {translateObj(translatedTexts.textFour, localization.language)}
                  <a className="cursor-pointer" onClick={() => renewalCallback()}>
                    <span className="text-red">{translateObj(translatedTexts.here, localization.language)}</span>
                  </a>
                  .
                </p>
              </div>
            </div>
            <div
              className="col-span-3 laptop:col-span-2 mt-1 col-end-5 tablet:col-end-11 laptop:col-end-9 row-start-2 cursor-pointer flex items-center justify-end"
              onClick={() => deleteCallback()}
            >
              <SimpleButton
                isDisabled
                isLowercase
                isFullWidth
                theme={{
                  base: 'red',
                  hover: 'red',
                }}
                text={{
                  en: 'Eliminar cuenta',
                  es: 'Eliminar cuenta',
                }}
              />
            </div>
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

export default DeleteFormsy;

DeleteFormsy.propTypes = {
  match: PropTypes.shape({}).isRequired,
  deleteCallback: PropTypes.func.isRequired,
  renewalCallback: PropTypes.func.isRequired,
};
