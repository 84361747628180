import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const FooterColumnOptions = ({ textOptionsAreInBold, params }) => (
  <RoutesContext.Consumer>
    {
      ({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom);
        return (
          <div className={`font-stratos text-13px leading-23px laptop:text-base tracking-tight-3 w-1/2 tablet:pt-3 laptop:pt-1 text-center tablet:text-left ${textOptionsAreInBold === true ? 'font-semibold laptop:w-1/4 desktop:w-2/5' : 'font-light laptop:w-1/3 tablet:pl-2 laptop:pl-0 desktop:pl-4 laptop:flex-1'}`}>
            {params.map((item) => {
              const trans = translateObj(item.text, localization.language);
              return (
                <Link key={trans} to={item.link} className="block text-white no-underline mb-6px tablet:mb-10px hover:underline">
                  {translateObj(item.text, localization.language)}
                </Link>
              );
            })}
          </div>
        );
      }
    }
  </RoutesContext.Consumer>
);

FooterColumnOptions.propTypes = {
  textOptionsAreInBold: PropTypes.bool.isRequired,
  params: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

export default FooterColumnOptions;
