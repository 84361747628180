import React, {
  useState, useEffect, useRef,
} from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { Link, withRouter } from 'react-router-dom';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const TopHeaderMegamenuSubmenu = ({
  name, link, items, children, match, isFullHeight,
}) => {
  const [opened, setOpened] = useState(false);
  const [screen, setScreen] = useState();
  const responsiveUtility = useRef();

  const handleDropdownResponseEvent = (response) => {
    if (!['laptop', 'desktop'].includes(screen)) {
      if (
        typeof response.detail.active !== 'undefined'
        && response.detail.active === name.en
      ) {
        setOpened(true);
      } else setOpened(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') window.addEventListener('dropdownResponseEvent', handleDropdownResponseEvent);

    return () => window.removeEventListener('dropdownResponseEvent', handleDropdownResponseEvent);
  }, []);

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setScreen(deviceScreen);
  };


  function handleDropdown() {
    const dropdownEvent = new CustomEvent(
      'dropdownEvent', {
        detail: { name, isOpened: !opened },
      },
    );
    if (!['laptop', 'desktop'].includes(screen)) {
      window.dispatchEvent(dropdownEvent);
    }
  }

  useEffect(() => {
    if (!['laptop', 'desktop'].includes(screen)) {
      setOpened(false);
    } else setOpened(true);
  }, [screen]);

  const dropdownVariants = {
    closed: { height: 0 },
    opened: { height: 'auto' },
  };

  const dropdownArrowVariants = {
    closed: { rotate: 0 },
    opened: { rotate: 180 },
  };

  const transition = {
    type: 'tween',
    duration: 0.5,
    ease: [0.87, 0, 0.13, 1],
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <div data-name="megamenu-section" className="laptop:flex-1">
            <div className="font-stratos laptop:px-4 laptop:pb-5 laptop:mb-4">
              <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} rdlSizes />
              <div
                data-name="megamenu-section-title"
                className="flex items-center justify-between py-4 border-b-2 border-black cursor-pointer laptop:mb-0 laptop:border-0 laptop:cursor-auto laptop:pt-0"
                // onClick={() => handleDropdown()}
              >
                <Link
                  to={link}
                  className="font-bold text-34 laptop:text-26 text-black w-full"
                >
                  {translateObj(name, localization.language)}
                </Link>
                {/* <motion.div
                  animate={opened ? 'opened' : 'closed'}
                  transition={transition}
                  variants={dropdownArrowVariants}
                >
                  <svg className="w-4 laptop:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10">
                    <path data-name="t4818" d="M8 10l8-10H0z" fill="#ff4646" />
                  </svg>
                </motion.div> */}
              </div>
              {['laptop', 'desktop'].includes(screen) && (
              <motion.div
                className="overflow-y-hidden laptop:block"
                animate={opened ? 'opened' : 'closed'}
                transition={transition}
                variants={dropdownVariants}
              >
                {/* {link && (
                  <a
                    href={link}
                    className="laptop:hidden pb-4 no-underline text-black block font-light text-18 border-b border-grey mb-4 pt-4"
                  >
                    <span data-name="megamenu-section-item" className="block">
                      Ver categoría
                    </span>
                  </a>
                )} */}
                {items && items.map((item, i) => (
                  <Link
                    key={`${i + item.link}`}
                    to={`${item.link}`}
                    className={`
                      pb-4 laptop:pb-1 no-underline text-black block font-light text-18 laptop:text-base laptop:border-0
                      ${i !== items.length - 1 ? 'border-b border-grey mb-4 laptop:mb-0' : 'border-b-2 border-black'}
                      ${i === 0 ? 'laptop:pt-1' : ''}
                    `}
                  >
                    <span data-name="megamenu-section-item" className="block">
                      {translateObj(item.text, localization.language)}
                    </span>
                  </Link>
                ))}
              </motion.div>
              )}
            </div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

TopHeaderMegamenuSubmenu.propTypes = {
  name: PropTypes.shape().isRequired,
  link: PropTypes.string,
  match: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  children: PropTypes.arrayOf(PropTypes.shape().isRequired),
  isFullHeight: PropTypes.bool,
};

TopHeaderMegamenuSubmenu.defaultProps = {
  children: null,
  isFullHeight: false,
  link: '',
};

export default withRouter(TopHeaderMegamenuSubmenu);
