import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBraze } from '../../context/useBraze';

const BrazeTrigger = ({ brazeEvent }) => {
  const { appboy, logCustomEvent } = useBraze();

  useEffect(() => {
    if (!appboy) return;
    if (brazeEvent) logCustomEvent(brazeEvent);
    appboy.subscribeToInAppMessage(inAppMessage => appboy.display.showInAppMessage(inAppMessage));
  }, [appboy]);
  return null;
};

BrazeTrigger.propTypes = {
  brazeEvent: PropTypes.string,
};

BrazeTrigger.defaultProps = {
  brazeEvent: null,
};

export default BrazeTrigger;
