import React from 'react';
import PropTypes from 'prop-types';

const SpotifyPlayer = ({
  listId, albumId, width, height, margin, parentComponent,
}) => (
  <>
    {listId
  && <iframe className={`${parentComponent === 'NewsPostPage' ? 'my-10 laptop:my-15' : 'desktop:mb-12 laptop:mb-13 tablet:mb-0'} ${margin ? 'mt-12' : ''} max-w-full`} title="spotify" src={`https://open.spotify.com/embed/playlist/${listId}`} width={width} height={height} frameBorder="0" allow="encrypted-media" />
  }
    {albumId
  && <iframe className={`${parentComponent === 'NewsPostPage' ? 'my-10 laptop:my-15' : 'my-10 laptop:my-10'} ${margin ? 'mt-12' : ''} max-w-full`} title="spotify" src={`https://open.spotify.com/embed/album/${albumId}`} width={width} height={height} frameBorder="0" allow="encrypted-media" />
  }
  </>
);

SpotifyPlayer.propTypes = {
  listId: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.bool,
  parentComponent: PropTypes.string,
};

SpotifyPlayer.defaultProps = {
  width: '100%',
  height: 255,
  margin: false,
  parentComponent: '',
};

export default SpotifyPlayer;
