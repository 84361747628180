import React, { useEffect, useState } from 'react';
import getComments from '../../../api/graphql/queries/getComments';

const CommentsTester = () => {
  const [postSlugName, setPostSlugName] = useState('jackie-o-motherfucker-bloom');
  const [commentList, setCommentList] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');


  useEffect(() => {
    getComments(postSlugName)
      .then((comments) => {
        setCommentList(comments);
      });
  }, []);

  return (
    <div>
      <h1>
        Comments for post {postSlugName}
      </h1>

      <div>
        {commentList.map(comment => (
          <p>
            {JSON.stringify(comment)}
            <br />
            <br />
          </p>
        ))}
      </div>

      <div className="flex mt-8 sm:mt-0">
        {error && (
          <div className="w-full card-error" role="alert">
            Error:
            {' '}
            {error}
          </div>
        )}
        {message && (
          <div className="w-full">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

CommentsTester.propTypes = {
};

CommentsTester.defaultProps = {
};

export default CommentsTester;
