import React, { useState, useRef } from 'react';
// import { PropTypes } from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import Container from './Container';
import SearchInputDropdown from './SearchInputDropdown';

const searchClient = algoliasearch('RZ9FRJCM47', 'beb2b1dd8b0e8db3d2da8eebbe162754');

const SearchInputListing = () => {
  const [query, setQuery] = useState('');
  const inputEl = useRef(null);

  const onChange = (event) => {
    setQuery(event.target.value);
  };

  const dropdownItems = [
    '[Lo último]',
    '[Item 1]',
    '[Item 2]',
    '[Item 3]',
  ];

  const focusInput = () => {
    if (inputEl.current !== 'null') {
      inputEl.current.focus();
    }
  };

  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="demo_ecommerce">
        <SearchBox />
        <Hits />
      </InstantSearch>
    </div>
  );

  // return (
  //   <Container>
  //     <div className="flex items-end justify-between font-stratos text-black mb-10 laptop:pt-1 desktop:pt-0 tablet:mb-14 laptop:mb-10 desktop:mb-14">
  //       <div className="border-gray border-b flex items-center justify-start flex-1 tablet:mb-2">
  //         <span
  //           className="h-full flex items-center justify-start text-sm tablet:text-20 font-semibold flex-0 text-dark-grey mt-2 tablet:mt-1"
  //           onClick={() => focusInput()}
  //         >
  //           [Buscar]
  //         </span>
  //         <input
  //           ref={inputEl}
  //           value={query}
  //           onChange={onChange}
  //           className="w-full pb-2 pl-10 text-38 font-semibold flex-1 bg-transparent caretRed"
  //         />
  //       </div>
  //       <div key="dropdown-wrapper" className="ml-4 hidden tablet:flex flex-col items-stertch justify-start -mr-1">
  //         <span className="block mb-3 font-semibold text-black text-12">[Ordenar por]</span>
  //         <SearchInputDropdown items={dropdownItems} />
  //       </div>
  //     </div>
  //   </Container>
  // );
};

export default SearchInputListing;

SearchInputListing.propTypes = {
};

SearchInputListing.defaultProps = {
};
