import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';

const FooterParagraphLine = ({ text, name, match }) => {
  const divClasses = parent => (
    parent === 'footerBottom' ? 'w-1/4 sm:w-full md:w-full lg:w-full flex items-center sm:justify-center sm:mt-4 sm:pt-4 sm:border-t sm:border-white justify-end' : 'mb-0 mt-3 w-1/2 sm:mt-0 sm:mb-4'
  );

  const paragraphClasses = parent => (
    parent === 'footerBottom' ? 'text-white text-xxs font-tiemposRegular md:pt-0 xl:pr-0 xl:self-end xl:mt-0 ' : 'text-xs uppercase font-americaMonoRegular '
  );

  return (
    <RoutesContext.Consumer>
      {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div data-name="FooterParagraphLine" className="w-full">
              <p className="font-stratos font-bold text-sm">
                {translateObj(text, localization.language)}
              </p>
            </div>
          );
        }
      }
    </RoutesContext.Consumer>
  );
};

FooterParagraphLine.propTypes = {
  text: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
    ca: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string,
  match: PropTypes.shape().isRequired,
};
FooterParagraphLine.defaultProps = {
  name: null,
};

export default withRouter(FooterParagraphLine);
