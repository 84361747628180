// getCommentsForPost(postSlugName: String!)
// commentCreate(postSlugName: String!, commentText: String!):
// commentRemove(commentId: String!)

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import SimpleButton from './Buttons/SimpleButton';
import getComments from '../../api/graphql/queries/getComments';
import commentCreate from '../../api/graphql/mutations/commentCreate';
import commentRemove from '../../api/graphql/mutations/commentRemove';
import { useUser } from '../../context/userProfileContext';
import RoutesContext from '../../context/routesContext';
import DropdownContainer from './Login/ProfileForm/DropdownContainer';

const CommentsBox = ({ forceMobileLayout }) => {
  const [textareaValue, setTextareaValue] = useState('');
  const [comments, setComments] = useState([]);
  const [sendButtonText, setSendButtonText] = useState('Enviar...');
  const [sending, setSending] = useState(false);
  const [errorSending, setErrorSending] = useState(false);
  const [showAddComment, setShowAddComment] = useState(false);
  const { token, userId } = useUser();
  const [slugName, setSlugName] = useState(null);
  const { currentSlugName = false } = useContext(RoutesContext);
  const [activeDropdown, setActiveDropdown] = useState(false);

  const fetchCommentsData = async () => {
    const fetchedComments = await getComments(slugName);
    if (fetchedComments) setComments(fetchedComments);
  };

  useEffect(() => {
    if (slugName) {
      fetchCommentsData();
    }
  }, [slugName]);


  useEffect(() => {
    if (currentSlugName) {
      setSlugName(currentSlugName);
    }
  }, [currentSlugName]);

  useEffect(() => {
    if (sending) {
      setSendButtonText('Enviando...');
    } else {
      if (errorSending) {
        setSendButtonText('Se ha producido un error. Prueba otra vez.');
      } else {
        setSendButtonText('Enviar');
      }
    }
  }, [sending, errorSending]);

  const handleAddComment = () => {
    if (token) {
      setShowAddComment(true);
    } else {
      const loginEvent = new CustomEvent(
        'loginMenuEvent', {
          detail: { close: false },
        },
      );
      window.dispatchEvent(loginEvent);
    }
  };

  const handleSendComment = async () => {
    if (textareaValue.length === 0) return;
    setSending(true);
    setErrorSending(false);
    const res = await commentCreate(slugName, textareaValue);
    if (res) {
      setTextareaValue('');
      setShowAddComment(false);
      await fetchCommentsData();
      setActiveDropdown(true);
    } else {
      setErrorSending(true);
    }
  };

  const handleDeleteComment = async (item) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('¿Eliminar comentario?')) {
      if (item.userId === userId) {
        const res = await commentRemove(item.commentId);
        if (res) {
          await fetchCommentsData();
        }
      }
    }
  };

  const handleTextarea = (e) => {
    setTextareaValue(e.target.value);
  };

  const dropdownClick = (id) => {
    setActiveDropdown(!activeDropdown);
  };

  useEffect(() => {
    console.log('activeDropdown', activeDropdown);
  }, [activeDropdown]);

  const filteredCommentsLength = comments.filter(item => !item.deleted && !item.censored).length;

  // eslint-disable-next-line react/prop-types
  const Comment = ({ item }) => (
    <div className="bg-beige p-8 mb-6 font-stratos">
      <h6 className="text-red text-base">{item.nickname}</h6>
      <span className="block font-bold text-grey text-xs">{dayjs(Number(item.createdAt)).format('DD.MM.YYYY / HH:mm[h]')}</span>
      <div className="pl-16">
        <span className="block mt-6 text-18">{item.commentText}</span>
        {item.userId === userId && <span className="mt-4 text-sm text-gray cursor-pointer" onClick={() => handleDeleteComment(item)}>Eliminar comentario</span>}
      </div>
    </div>
  );

  return (
    <div className={`grid grid-cols-6 gap-3 mb-8 ${forceMobileLayout ? '' : 'tablet:grid-cols-10 laptop:gap-4'}`}>
      <div className="col-span-2">
        <SimpleButton isFullWidth callback={handleAddComment} text="Comentar" theme={{ base: 'red', hover: 'red' }} />
      </div>
      <div className={`col-span-6 ${forceMobileLayout ? '' : 'tablet:col-start-4 tablet:col-span-7'}`}>
        <div className={`relative mb-8 ${showAddComment ? 'block' : 'hidden'}`}>
          <textarea placeholder="Deja un comentario..." onChange={handleTextarea} value={textareaValue} cols="30" rows="7" className="border-red border-12 p-4 w-full block resize-y font-stratos text-base text-red" />
          <span className="absolute pin-b pin-r">
            <SimpleButton disabled={setSending} theme={{ base: 'bgred' }} text={sendButtonText} callback={handleSendComment} />
          </span>
        </div>
        <DropdownContainer
          borderTop={false}
          parentComponent="CommentsBox"
          borderBottom
          activeDropdown={activeDropdown}
          callback={dropdownClick}
          ContainerclassName="mt-4 mb-4"
          title={`Comentarios (${filteredCommentsLength})`}
        >
          <div>
            {comments.map((item, index) => {
              if (!item.deleted && !item.censored) {
                return (
                  <>
                    <Comment item={item} />
                    {index !== filteredCommentsLength && <div className="w-full h-px bg-grey mb-4" />}
                  </>
                );
              }
              return null;
            })}
          </div>
        </DropdownContainer>
      </div>
    </div>
  );
};

CommentsBox.propTypes = {
  forceMobileLayout: PropTypes.bool,
};

CommentsBox.defaultProps = {
  forceMobileLayout: false,
};

export default CommentsBox;
