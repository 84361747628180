import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import RoutesContext from "../../context/routesContext";
import getLocalization from "../../util/localization";
import translateObj from "../../util/translateObj";

import Separator from "./Separator";
import NormalBodyText from "./Text/NormalBodyText";

const InstructionsSimple = ({
  paragraph,
  includeSeparator,
  icon,
  background,
  name,
  title,
  match,
  logoUrl
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const imgSize = background === "white" ? "6rem" : "8rem";
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      return (
        <div
          className={`md:flex lg:flex xl:flex mb-8 font-tiemposRegular items-start flex-none sm:mt-0 ${background ===
            "white" && "bg-white py-8 px-12 rounded-lg"} ${!icon &&
            !logoUrl &&
            "md:flex-none lg:flex-none"}`}
        >
          <div className="md:w-1/5 lg:w-1/5 xl:w-1/5 self-start sm:mt-6">
            {logoUrl && (
              <img
                className="max-h-120px max-w-120 mt-4 object-contain"
                src={logoUrl}
                alt={name}
              />
            )}
            {!logoUrl && icon && (
              <span
                className={`font-icon text-110 text-black ${icon}`}
                style={{ maxWidth: imgSize }}
              />
            )}
            {name === "instructionText" && (
              <p
                className="font-americaMonoBold text-14 uppercase mt-0"
                style={{ marginBottom: "4rem" }}
              >
                {translateObj(title, localization.language)}
              </p>
            )}
          </div>
          <div
            className={`${
              background === "transparent"
                ? " md:w-4/5 lg:w-4/5 xl:w-4/5"
                : "sm:w-full sm:pl-0 sm:mt-6 w-4/5 pl-12"
            } ${!icon && !title && "mt-6 xl:ml-48 md:w-full lg:w-full"}`}
          >
            {includeSeparator && (
              <Separator borderWidth={1.0} hideOnMobile={false} paddingBottom />
            )}
            <NormalBodyText text={paragraph} classes="text-sm" />
          </div>
        </div>
      );
    }}
  </RoutesContext.Consumer>
);

InstructionsSimple.propTypes = {
  match: PropTypes.shape().isRequired,
  logoUrl: PropTypes.string,
  paragraph: PropTypes.shape({}),
  icon: PropTypes.string,
  background: PropTypes.oneOf(["white", "transparent"]),
  name: PropTypes.string,
  title: PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
    ca: PropTypes.string
  }),
  includeSeparator: PropTypes.bool
};

InstructionsSimple.defaultProps = {
  background: "transparent",
  logoUrl: "",
  name: "",
  icon: "",
  title: {},
  paragraph: {},
  includeSeparator: false
};

export default withRouter(InstructionsSimple);
