import React, { useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import { responseGoogle } from './Login/LoginUtils/handleSocialNetworkResponses';
import { useUser } from '../../context/userProfileContext';

const CLIENT_ID = '374001476805-jdr4d87slvoaobdajc0bas161r661rfs.apps.googleusercontent.com'; // NOTE: should better come from the env vars
const SCRIPT_ID = 'gapi_auth_lib';

const removeGoogleSSOScript = () => {
  const gscript = document.getElementById(SCRIPT_ID);
  document.body.removeChild(gscript);
};

const GoogleSSO = ({ handleRegisteringStatus, QRcodeCallback, setError, type = 'modal' }) => {
  const history = useHistory();
  const { setNewAuthToken } = useUser();

  const setToken = (res) => {
    if (!res) {
      return;
    }
    const { token, needToRegister, userPayload, error = '' } = res;

    if (token) {
      setNewAuthToken(token);
      history.push('/');
      // NOTE: NO IDEA WHAT THE CODE FROM LINE 72 TO 88 DOES, JUST COPIED AND PASTED FROM THE OLD CODE :ESPELMA:
      QRcodeCallback?.();
    } else {
      if (error) {
        setError?.(error);
      }
      if (needToRegister && handleRegisteringStatus) {
        const userPayloadWithSocialNetwork = {
          ...userPayload,
          profileObj:
          { ...userPayload.profileObj, isSocialLogin: 'google' },
        };
        handleRegisteringStatus(true, userPayloadWithSocialNetwork, 'google');
      } else {
        console.log('**** User Not Valid by Server, Sorry ****');
      }
    }
  };

  const getGoogleAuth = async (auth) => {
    const { credential } = auth;
    if (!credential) return undefined;

    const decodedAuth = jwt.decode(credential);

    const profileObj = {
      googleId: decodedAuth.sub,
      imageUrl: decodedAuth.picture,
      email: decodedAuth.email,
      name: decodedAuth.name,
      givenName: decodedAuth.given_name,
      familyName: decodedAuth.family_name,
    };

    const res = await responseGoogle({ tokenId: credential, profileObj });
    await setToken(res);
    return res;
  };

  const loadGoogleSSOScript = () => new Promise((res, rej) => {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    script.src = 'https://accounts.google.com/gsi/client';
    script.id = SCRIPT_ID;

    document.body.appendChild(script);

    script.onerror = (error) => {
      rej(Error(`Error loading Google SSO script: ${JSON.stringify(error, null, 2)}`));
    };

    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: getGoogleAuth,
      });
      window.google.accounts.id.renderButton(
        document.getElementById('google_login_button'),
        { theme: 'filled_black', size: 'large', shape: 'circle', type: type === 'modal' ? 'icon' : 'standard' }, // customization attributes
      );
    };
  });

  useEffect(() => {
    loadGoogleSSOScript();
    return () => removeGoogleSSOScript();
  }, []);

  return <div id="google_login_button" />;
};

export default GoogleSSO;
