import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import RoutesContext from '../../context/routesContext';
import translateObj from '../../util/translateObj';

const TagsBlock = ({ title, parentComponent }) => {
  const [postDescription, setPostDescription] = useState(false);
  const {
    language = 'en',
    currentPostDescription = false,
  } = useContext(RoutesContext);

  useEffect(() => {
    if (currentPostDescription) {
      setPostDescription(currentPostDescription);
    }
  }, [currentPostDescription]);

  const { tags = [] } = postDescription;

  const orderedTags = () => {
    const theTags = tags;
    if (tags && typeof tags !== 'undefined' && tags.length > 0) {
      const sort = Array.from(theTags).sort((a, b) => (
        a.localeCompare(b, 'es', { sensitivity: 'base' })
      ));
      return sort;
    }
    return null;
  };

  return (
    <div className={`w-full ${parentComponent === 'CoverStoryPostPage' ? 'mb-10' : ''}`}>
      <div className="flex flex-col w-full border-t-3 border-black border-solid">
        {title && <span className="text-black font-stratos text-13 font-semibold mt-2 mb-4">{translateObj(title, language)}</span>}
        <div className="flex flex-row flex-wrap">
          {tags && tags.length > 0 && orderedTags !== null && orderedTags().map((tag, index) => (
            <span key={tag} className={`text-red font-stratos desktop:text-15 text-14 font-semibold ${index !== 0 && 'ml-1'}`}>
              {index !== 0 && '/ '}
              <a className="text-red visited:text-red" href={`/buscar?tag="${tag}"`}>{tag}</a>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

TagsBlock.propTypes = {
  title: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
    ca: PropTypes.string,
  }),
  parentComponent: PropTypes.string,
};

TagsBlock.defaultProps = {
  title: {},
  parentComponent: null,
};

export default withRouter(TagsBlock);
