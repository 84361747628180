import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { useRoutes } from '../../../context/routesContext';
import { useUser } from '../../../context/userProfileContext';
import translateObj from '../../../util/translateObj';
import { translations } from './LoginUtils/onDemandLoginSimpleTexts';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';

import SimpleOnDemandModalScreen from '../OnDemand/SimplifiedOnDemandModals/SimpleOnDemandModalScreen';

const modalStyles = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    padding: '0',
    // padding: '2rem',
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.68)',
    zIndex: 9999,
  },
  closeButton: {
    top: '18px',
    right: '18px',
  },
};

const modalStylesMobile = {
  modal: {
    background: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    padding: '0',
    zIndex: 6000,
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.68)',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    padding: '0',
    zIndex: 6000,
  },
  closeButton: {
    top: '18px',
    right: '18px',
    zIndex: 7000,
  },
};

const MenuPopUpLoginButton = ({
  language,
  isCentered,
  buttonText,
  textColor,
  icon,
  sourceWeb,
  postVideoUrl,
  postVideoRole,
  iconClasses,
  showText,
}) => {
  const userProps = useUser();
  const [isModalOpen, setModalOpen] = useState(true);
  const [screen, setScreen] = useState();
  const responsiveUtility = useRef();

  const onOpenModal = () => {
    // window.scrollTo(0, 0);
    setModalOpen(true);
  };

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setScreen(deviceScreen);
  };

  const onCloseModal = () => {
    const loginEvent = new CustomEvent(
      'loginMenuEvent', {
        detail: { close: true },
      },
    );
    window.dispatchEvent(loginEvent);
    setModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerStyle = screen !== 'mobile'
    ? `flex ${isCentered ? 'justify-center' : 'sm:justify-end md:justify-end justify-start'}`
    : 'w-screen h-screen';

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} rdlSizes />
      <div className={containerStyle}>
        <Modal
          onClose={onCloseModal}
          open={isModalOpen}
          styles={screen !== 'mobile' ? modalStyles : modalStylesMobile}
          focusTrapped={false}
          closeOnOverlayClick
          center
        >
          {screen !== 'mobile'
            ? (
              <SimpleOnDemandModalScreen
                {...userProps}
                language={language}
                translations={translations}
                closeModal={onCloseModal}
                postVideoUrl={postVideoUrl}
                postVideoRole={postVideoRole}
                sourceWeb={sourceWeb}
              />
            ) : (
              <div className="fixed">
                <SimpleOnDemandModalScreen
                  {...userProps}
                  language={language}
                  translations={translations}
                  closeModal={onCloseModal}
                  postVideoUrl={postVideoUrl}
                  postVideoRole={postVideoRole}
                  sourceWeb={sourceWeb}
                />
              </div>
            )}
        </Modal>
      </div>
    </>
  );

  // return (
  //   <div className={`flex ${isCentered ? 'justify-center' : 'sm:justify-end md:justify-end justify-start'}`}>
  //     <div
  //       className={` text-${textColor} font-americaMonoRegular cursor-pointer  text-center px-4  ${(!sourceWeb && showText) && `xl:border-r lg:border-r border-${textColor}`} uppercase text-12 flex justify-center items-center`}
  //       onClick={onOpenModal}
  //     >
  //       {icon && <span className={`${iconClasses} ${icon}`} />}
  //       {showText && <span className="text-12 sm:hidden md:hidden align-text-top mb-px">{translateObj(buttonText, language)}</span> }

  //     </div>
  //     <Modal open={isModalOpen} onClose={onCloseModal} center styles={modalStyles} closeOnOverlayClick focusTrapped={false}>
  //       <SimpleOnDemandModalScreen {...userProps} language={language} translations={translations} closeModal={onCloseModal} postVideoUrl={postVideoUrl} postVideoRole={postVideoRole} sourceWeb={sourceWeb} />
  //     </Modal>
  //   </div>

  // );
};

MenuPopUpLoginButton.propTypes = {
  buttonText: PropTypes.shape(),
  icon: PropTypes.bool,
  postVideoRole: PropTypes.string,
  postVideoUrl: PropTypes.string,
  isCentered: PropTypes.bool,
  sourceWeb: PropTypes.string,
  language: PropTypes.string,
  textColor: PropTypes.string,
  iconClasses: PropTypes.string,
  showText: PropTypes.bool,
};

MenuPopUpLoginButton.defaultProps = {
  isCentered: false,
  sourceWeb: '',
  language: 'en',
  postVideoRole: '',
  postVideoUrl: '',
  textColor: 'black',
  icon: false,
  iconClasses: 'px-4 sm:text-xl md:text-xl text-base',
  buttonText: {
    en: 'iniciar sesión',
    es: 'iniciar sesión',
  },
  showText: true,
};
export default withRouter(MenuPopUpLoginButton);
