
const checkIfStreamModuleHasRoles = (comps, index = 0, userRoles) => {
  const { requiredRole = '' } = comps[index];
  if (!requiredRole) return true;
  if (userRoles.includes(requiredRole) || userRoles.includes('admin')) return true;
  return false;
};
const checkIfUserHasTheRequiredRolesToSeeTheChat = (comps, userRoles) => {
  const isFirstComponentStreamModule = Boolean(comps[0].type === 'StreamModule');
  if (isFirstComponentStreamModule) {
    const doesStreamModuleContainChat = checkIfStreamModuleHasRoles(comps, 0, userRoles);
    if (!doesStreamModuleContainChat) {
      return false;
    }
  } else {
    const streamModuleIndex = comps.findIndex(comp => comp.type === 'StreamModule');
    if (streamModuleIndex) {
      const doesStreamModuleContainChat = checkIfStreamModuleHasRoles(comps, streamModuleIndex, userRoles);
      if (!doesStreamModuleContainChat) {
        return false;
      }
    }
  }
  return true;
};


export { checkIfUserHasTheRequiredRolesToSeeTheChat };
