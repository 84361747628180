import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const GET_PAYMENT_QUERY = gql`
  query Get($productGroup: String) {
    getPayments(productGroup: $productGroup) {
      provider
      user
      productSlugName
      contractType
      productGroup
      subscriptionLastDate
      renewSubscription
      provider
      payed
      datePayment
      subscriptionRenewalLastError {
        date
        code
        message
      }
    }
  }
`;

const getPayments = async (productGroup) => {
  try {
    const result = await clientWeb
      .query({
        query: GET_PAYMENT_QUERY,
        variables: { productGroup, fetchDate: new Date().getTime().toString() },
        fetchPolicy: 'no-cache',
      })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });
    const {
      data: { getPayments: getPaymentsData },
    } = result;

    if (getPaymentsData) {
      return getPaymentsData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: getPayments`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: getPayments Error: ${error}`);
    return null;
  }
};

export default getPayments;
