/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import translateObj from '../../../util/translateObj';
import getLocalization from '../../../util/localization';
import RoutesContext from '../../../context/routesContext';
import { checkIsAuth } from '../../../context/userProfileContext';
import ComponentIterator from '../../../containers/ComponentIterator';

const TicketBlock = ({
  title,
  image,
  features,
  textUnderBox,
  components,
  layout,
  match,
  position,
  totalItems,
  parentComponent,
}) => {
  const isAuth = checkIsAuth();

  const openLoginModal = () => {
    const loginEvent = new CustomEvent(
      'loginMenuEvent', {
        detail: { close: false },
      },
    );
    window.dispatchEvent(loginEvent);
  };

  const onCloseModal = () => {
    const loginEvent = new CustomEvent(
      'loginMenuEvent', {
        detail: { close: true },
      },
    );
    window.dispatchEvent(loginEvent);
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        const borderColor = layout === 'grey' ? 'beige' : layout;
        return (
          <div className={`relative w-full tablet:w-1/2 laptop:w-1/4 max-w-302px h-488px ${position === totalItems ? 'laptop:mr-0' : 'laptop:mr-5'} tablet:mx-2 laptop:mx-0 ${position === 1 ? 'mt-0 tablet:mt-8' : 'mt-8 tablet:mt-8'}`}>
            <div className={`flex flex-col justify-between w-full h-full border-12 border-${borderColor} mb-7 tablet:mb-0`}>
              <div className="p-5 pb-0">
                <div className="border-b border-grey pb-4">
                  <h1 className={`font-stratos font-bold text-30 ${layout === 'red' ? 'text-black' : 'text-red'}`}>
                    {translateObj(title, localization.language)}
                  </h1>
                </div>
                <div className="flex items-center justify-center border-b border-grey h-131px overflow-hidden">
                  <img className="w-auto h-auto" alt="price" src={translateObj(image, localization.language)} />
                </div>
                <div className="pt-4">
                  {features.map(feature => (
                    <div key={`feature-${feature.title}`} className="flex flex-row pb-4">
                      <span className="font-icon icon-check text-red text-20 mr-5" />
                      <h3 className="text-16 font-stratos font-normal">
                        {translateObj(feature.title, localization.language)}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
              {components && components.map((component) => {
                Object.assign(component, { layout }, { parentComponent }, { onOpen: openLoginModal }, { onClose: onCloseModal });
                if (component.type !== 'MenuPopUpLoginButton') {
                  if (isAuth) {
                    return (
                      <div key={`${component.type}-${component.productSlugName || '-1'}`} className="flex flex-row items-center justify-center">
                        <ComponentIterator components={[component]} />
                      </div>
                    );
                  }
                  return (
                    <div key={`${component.type}-${component.productSlugName || '-1'}`} className="flex flex-row items-center justify-center">
                      <button
                        className="text-14 text-red border-red border-6 py-1 w-32 mb-4 uppercase font-bold font-stratos flex flex-row justify-center items-center"
                        type="button"
                        onClick={openLoginModal}
                      >
                        {translateObj(component.buttonText, localization.language)}
                      </button>
                    </div>
                  );
                }
                return (
                  <div key={`${component.type}-${component.productSlugName || '-1'}`} className="flex flex-row items-center justify-center">
                    <button
                      className="text-14 text-red border-red border-6 py-1 w-32 mb-4 uppercase font-bold font-stratos flex flex-row justify-center items-center"
                      type="button"
                      onClick={openLoginModal}
                    >
                      {translateObj(component.buttonText, localization.language)}
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="absolute tablet:mt-4 text-15 text-grey font-normal font-stratos text-center">{translateObj(textUnderBox, localization.language)}</div>
          </div>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default withRouter(TicketBlock);

TicketBlock.propTypes = {
  title: PropTypes.shape().isRequired,
  image: PropTypes.shape(),
  features: PropTypes.arrayOf(PropTypes.shape()),
  match: PropTypes.shape().isRequired,
  components: PropTypes.arrayOf(PropTypes.shape()),
  layout: PropTypes.string,
  position: PropTypes.number,
  totalItems: PropTypes.number,
  parentComponent: PropTypes.string,
  textUnderBox: PropTypes.shape(),
};

TicketBlock.defaultProps = {
  features: [],
  image: {},
  components: null,
  layout: '',
  position: null,
  totalItems: null,
  parentComponent: '',
  textUnderBox: null,
};
