import React from 'react';
import { PropTypes } from 'prop-types';
import BannersRowItem from './BannersRowItem';

const BannersRow = ({
  items,
}) => (
  <div className="flex flex-col items-start px-5 mb-12 laptop:flex-row justify-stretch laptop:items-stretch laptop:justify-between tablet:mb-16 tablet:px-42px laptop:px-20 laptop:mb-24 full-width">
    {items.map((item, index) => (
      <div
        key={`BannersRowItem-${index + 1}`}
        className="w-full pb-8 tablet:pb-42px laptop:w-1/2 laptop:px-4 laptop:pb-0"
      >
        <BannersRowItem
          color={item.color}
          bgImage={item.bgImage}
          preTitle={item.preTitle}
          title={item.title}
          text={item.text}
          link={item.link}
          bannerClick={item.bannerClick}
          overlayImage={null}
          desktopVideoUrl={item.desktopVideoUrl}
        />
      </div>
    ))}
  </div>
);

export default BannersRow;

BannersRow.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
};
