import React from 'react';

import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { withRouter } from 'react-router-dom';
import { TweenMax, Sine } from 'gsap';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import ComponentIterator from '../../../containers/ComponentIterator';
import SimpleTag from '../Buttons/SimpleTag';

const ExpandLess = () => <span className="font-icon icon-arrow-up text-white text-sm" />;
const ExpandMore = () => <span className="font-icon icon-arrow-down text-black text-sm font-black" />;

const style = {
  list: {
    padding: 0,
    paddingTop: '16px',
    marginBottom: 0,
  },
  root: {
    backgroundColor: 'black',
    height: '2px',
  },
};

const getStyle = (selected, border, listItemStyle, bgWhite) => {
  if (selected && !border && !bgWhite) {
    return {
      color: 'white',
      backgroundColor: 'black',
    };
  }
  if (bgWhite) {
    return {
      color: 'black',
      backgroundColor: 'white',
      padding: '0 16px',
    };
  }
  if (border) {
    return null;
  }

  return listItemStyle;
};

const getTextStyle = (selected, border, bgWhite) => {
  if (selected && !border && !bgWhite) {
    return {
      color: 'white',
    };
  }
};

const getBorder = (border, lowercase, bgWhite) => {
  if (border && lowercase === false) {
    return {
      paddingLeft: '2rem',
      height: 64,
    };
  }
  if (lowercase) {
    return {
      height: 'auto',
      minHeight: 74,
    };
  }
  if (bgWhite) {
    return {
      marginBottom: 0,
      padding: '2rem 1rem',
      height: 'auto',
    };
  }
  return {
    marginTop: 10,
    padding: '1rem',
    height: 'auto',
  };
};

class CollapsibleListItem extends React.Component {
  state = {
    dropDown: '',
  };

  listRef = React.createRef();

  componentDidMount() {
    const { location: { hash }, handleClick } = this.props;
    // if (hash) {
    //   this.setState({ hasLocation: true }, () => {
    //     this.handleClick(hash);
    //   });
    // }
  }

  componentDidUpdate(prevProps) {

  }

  handleClick = (type, hasReusableContent) => {
    const { handleClick } = this.props;
    const { current } = this.listRef;
    handleClick(type, hasReusableContent);
  }

  render() {
    const {
      comp, handleClick, dropDown, bgWhite, color, match, noLink, border, lowercase,
    } = this.props;

    const listItemStyle = color === 'white'
      ? {
        backgroundColor: 'rgba(255,255,255,0.9)',
      }
      : {};

    const hasReusableContent = comp.components && comp.components.length && comp.components[0].type === 'ReusableComponent';
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const normalizedString = comp.text.en.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

          return (
            <React.Fragment>
              <div ref={this.listRef} className="list-item" id={normalizedString.replace(/\s+/g, '-').toLowerCase()} />
              <ListItem
                style={{
                  ...getStyle(dropDown === comp.text.en, false, listItemStyle, bgWhite),
                  ...getBorder(border, lowercase),
                }}
                value={comp.text.en}
                button
                onClick={() => this.handleClick(comp.text.en, hasReusableContent)}
                disableRipple
                disableTouchRipple
              >
                {comp.button && comp.button.category && (
                <div className="w-48 sm:w-auto">
                  <SimpleTag {...comp.button} margin="w-28 text-center py-1 font-americaMonoMedium" noLink={noLink} />
                </div>
                )}
                <ListItemText
                  primary={(
                    <span className={`uppercase ${border ? 'font-americaMonoMedium' : 'font-americaMonoBold sm:text-14 sm:leading-under'}`} style={getTextStyle(dropDown === comp.text.en, border, bgWhite)}>
                      <span className="mr-1 sm:hidden">\</span>
                      {`${translateObj(comp.text, localization.language)}`}
                    </span>
                        )}
                />
                {border ? dropDown === comp.text.en ? <span className="sm:mr-0 font-icon text-10 icon-less" /> : <span className="mr-4 font-icon icon-plus text-10" /> : <span className="sm:mr-0">{dropDown === comp.text.en ? <ExpandLess /> : <ExpandMore />}</span>}
              </ListItem>
              <Collapse in={dropDown === comp.text.en} timeout="auto" unmountOnExit classes={{ entered: 'none' }}>
                {bgWhite ? (
                  <div className="px-4">
                    <div className="border border-b border-dotted border-black" />
                    <ComponentIterator components={comp.components || comp.content} />
                  </div>
                )
                  : <ComponentIterator components={comp.components || comp.content} />

                      }
              </Collapse>
            </React.Fragment>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

CollapsibleListItem.propTypes = {
  classes: PropTypes.shape({}),
  components: PropTypes.arrayOf(PropTypes.shape({})),
  border: PropTypes.bool,
  lowercase: PropTypes.bool,
  firstClosed: PropTypes.bool,
  noLink: PropTypes.bool,
};

CollapsibleListItem.defaultProps = {
  border: false,
  lowercase: false,
  firstClosed: true,
  noLink: true,
  components: null,
  classes: '',
};

export default withRouter(withStyles(style)(CollapsibleListItem));
