import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext, { useRoutes } from '../../../context/routesContext';
import api from '../Payments/psWorldPaymentsApi';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import { translations } from '../Login/LoginUtils/onDemandLoginSimpleTexts';
import { UserProfileContext } from '../../../context/userProfileContext';
import userSetPaymentMethods from '../../../api/graphql/mutations/userSetPaymentMethods';
import getPaymentForProductGroup from '../../../api/graphql/queries/getPaymentForProductGroup';
import userGetPaymentMethods from '../../../api/graphql/queries/userGetPaymentMethods';
import PsWorldStripeCardFormWrapper from './StripeCardFormWrapper';

const ProductChosenModalContentAddCard = ({ match, closeCallback }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [productData, setProductData] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(null);
  const [customerPaymentMethodsSelectorOptions, setCustomerPaymentMethodsSelectorOptions] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [paymentProvider, setPaymentProvider] = useState('stripe');
  const [paymentActive, setPaymentActive] = useState(null);
  const [showGetCardInfo, setShowGetCardInfo] = useState(false);
  const [finishedProcedure, setFinishedProcedure] = useState(false);

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const userContext = useContext(UserProfileContext);
  const { language = 'en' } = useRoutes(match);

  const translatedTexts = {
    paymentMethod: {
      en: 'Método de pago',
      es: 'Método de pago',
    },
    creditCard: {
      en: 'Tarjeta de crédito',
      es: 'Tarjeta de crédito',
    },
    step: {
      en: 'Paso',
      es: 'Paso',
    },
    of: {
      en: 'de',
      es: 'de',
    },
    cancel: {
      en: 'Cancelar',
      es: 'Cancelar',
    },
    completed: {
      en: 'Tarjeta agregada con éxito',
      es: 'Tarjeta agregada con éxito',
    },
    completedText: {
      en: 'La tarjeta se ha registrado con éxito como nuevo método de pago.',
      es: 'La tarjeta se ha registrado con éxito como nuevo método de pago.',
    },
    close: {
      en: 'Cerrar',
      es: 'Cerrar',
    },
    addAnother: {
      en: 'Añadir otra',
      es: 'Añadir otra',
    },
  };

  useEffect(() => {
    if (paymentProvider === 'stripe' && !customerInfo && userContext.userEmail) {
      const cleanUserEmail = userContext.userEmail.trim();
      api.getOrCreateCustomer(cleanUserEmail).then((customerData) => {
        setCustomerInfo(customerData);
        if (!customerData || !Object.keys(customerData).length) {
          // setMessage(`Customer ${userContext.userEmail} could not be retrieved nor created`);
        }
      });
    }
  }, [paymentProvider, userContext.userEmail, customerInfo]);

  useEffect(() => {
    if (productData && productData.productGroup) {
      getPaymentForProductGroup(productData.productGroup).then((payment) => {
        if (payment) {
          setPaymentActive(payment);
        }
      });
    }
  }, [productData]);

  // Handle payment card selection
  useEffect(() => {
    if (customerPaymentMethods) {
      const options = customerPaymentMethods.map((paymentOption) => {
        const {
          token, card: { last4 }, card: { exp_month }, card: { exp_year },
        } = paymentOption;
        // return { value: id, label: last4 };
        // return `<option value="${token}">**** **** **** ${last4}</option>`;
        return (
          <option key={token} value={token}>
            **** **** ****
            {' '}
            {last4}
            {' '}
            {exp_month}
            /
            {exp_year}
          </option>
        );
      });
      setCustomerPaymentMethodsSelectorOptions(options);
      const preferredMethod = customerPaymentMethods.find(method => method.preferred === true);
      if (preferredMethod) {
        setPaymentMethodId(preferredMethod.token);
      } else if (customerPaymentMethods.length) {
        setPaymentMethodId(customerPaymentMethods[0].token);
      }
    }
  }, [customerPaymentMethods]);

  useEffect(() => {
    const logged = !!userContext.userEmail;
    setIsUserLoggedIn(logged);

    if (!logged) {
      return;
    }

    userGetPaymentMethods().then(paymentMethods => setCustomerPaymentMethods(paymentMethods));
  }, [userContext]);

  const getCustomerPaymentMethodsFromStripe = async () => {
    setError('');
    // setMessage('');
    if (!userContext.userEmail) {
      setError('Please log in to get payment methods');
      return;
    }
    if (!customerInfo || !customerInfo.id) {
      setError('Please get customer info first');
      return;
    }

    // Get payment methods from api (stripe)
    api.getCustomerPaymentMethods(customerInfo.id).then(async (paymentMethods) => {
      // if (!paymentMethods || !paymentMethods.length) {
      //   setMessage(`No Customer payent methods info for ${userContext.userEmail}`);
      // }
      // Save payment methods to back:
      // [{"id":"pm_1HbiKRLnN1U6bnmqIF8oNpXa","object":"payment_method","billing_details":{"address":{"city":null,"country":null,"line1":null,"line2":null,"postal_code":null,"state":null},"email":null,"name":null,"phone":null},"card":{"brand":"visa","checks":{"address_line1_check":null,"address_postal_code_check":null,"cvc_check":"pass"},"country":"US","exp_month":12,"exp_year":2021,"fingerprint":"Urj9nfSO3phdXZQ9","funding":"credit","generated_from":null,"last4":"4242","networks":{"available":["visa"],"preferred":null},"three_d_secure_usage":{"supported":true},"wallet":null},"created":1602575632,"customer":"cus_HJ3wQPaIeVWSGb","livemode":false,"metadata":{},"type":"card"},{"id":"pm_1GlWqNLnN1U6bnmqoyCK732r","object":"payment_method","billing_details":{"address":{"city":null,"country":null,"line1":null,"line2":null,"postal_code":null,"state":null},"email":null,"name":null,"phone":null},"card":{"brand":"visa","checks":{"address_line1_check":null,"address_postal_code_check":null,"cvc_check":"pass"},"country":"US","exp_month":4,"exp_year":2024,"fingerprint":"Urj9nfSO3phdXZQ9","funding":"credit","generated_from":null,"last4":"4242","networks":{"available":["visa"],"preferred":null},"three_d_secure_usage":{"supported":true},"wallet":null},"created":1590138427,"customer":"cus_HJ3wQPaIeVWSGb","livemode":false,"metadata":{},"type":"card"}]
      // id, card.last4, card.brand, card.exp_month, card.exp_year

      const paymentMethodsSend = paymentMethods.map((method) => {
        const {
          id: token,
          card: {
            last4, brand, exp_month, exp_year,
          },
          created,
        } = method;

        const createdAt = new Date(created * 1000);

        return {
          provider: 'stripe',
          token,
          card: {
            last4,
            brand,
            exp_month,
            exp_year,
          },
          createdAt,
        };
      });
      // Save payment methods in our database
      await userSetPaymentMethods({ provider: 'stripe', paymentMethods: paymentMethodsSend }); // TODO: get the preferred method from response and set it with setPaymentMethodId()
      setCustomerPaymentMethods(paymentMethodsSend);
    });
  };

  const storeNewCardResult = (result) => {
    const { status } = result;

    if (status === 'cancel') {
      closeCallback();
      return;
    }

    if (status === 'succeeded') {
      setShowGetCardInfo(false);
      setFinishedProcedure(true);

      const event = new CustomEvent('updatePaymentMethod');
      window.dispatchEvent(event);
    }

    // setMessage('Store Card failed');
  };

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        return (
          <>
            {/* <h1 className="text-black font-stratos text-18 font-bold text-center uppercase">
              {productData && productData.title[language]}
            </h1> */}

            {/* TARJETA DE CREDITO */}
            {isUserLoggedIn && paymentProvider === 'stripe' && customerInfo && customerPaymentMethods && !finishedProcedure && (
              <div className="flex flex-col items-center justify-between mt-4">
                <h1 className="uppercase font-stratos text-18 font-bold text-red">{translateObj(translatedTexts.creditCard, language)}</h1>
                <div className="mt-6">
                  <PsWorldStripeCardFormWrapper customerInfo={customerInfo} product={productData} resultCallback={storeNewCardResult} />
                </div>
              </div>
            )}


            {/* TARJETA AÑADIDA CORRECTAMENTE */}
            {isUserLoggedIn && paymentProvider === 'stripe' && customerInfo && customerPaymentMethods && finishedProcedure && (
              <div className="flex flex-col items-stretch justify-start h-full">
                <div className="flex-0 flex items-start justify-between">
                  <span className="font-icon icon-check-circle text-red text-40" />
                  <h3 className="ml-4 font-stratos font-bold text-red text-36">{translateObj(translatedTexts.completed, language)}</h3>
                </div>
                <div className="flex-1">
                  <p className="font-stratos font-20 my-5">{translateObj(translatedTexts.completedText, language)}</p>
                </div>
                <div className="flex flex-0 items-center justify-between mb-12">
                  <button
                    type="button"
                    className="text-grey text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-grey bg-beige mr-2"
                    onClick={closeCallback}
                  >
                    {translateObj(translatedTexts.close, language)}
                  </button>
                  <button
                    type="button"
                    className="text-red text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-red ml-2"
                    onClick={() => setFinishedProcedure(false)}
                  >
                    {translateObj(translatedTexts.addAnother, language)}
                  </button>
                </div>
              </div>
            )}

            {/* Show any error that happens when processing the payment */}
            {/* <div className="flex mt-8 sm:mt-0">
              {error && (
                <div className="w-full text-red-600" role="alert">
                  Error:
                  {' '}
                  {error}
                </div>
              )}
              {message && (
                <div className="w-full">
                  {message}
                </div>
              )}
            </div> */}
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

export default withRouter(ProductChosenModalContentAddCard);

ProductChosenModalContentAddCard.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
};

ProductChosenModalContentAddCard.defaultProps = {
};
