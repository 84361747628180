import React from 'react';
import { PropTypes } from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { Link, withRouter } from 'react-router-dom';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import SearchResult from './SearchResult';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import SearchInputListing from './SearchInputListing';

const searchClient = algoliasearch('RZ9FRJCM47', 'beb2b1dd8b0e8db3d2da8eebbe162754');
class SearchBasicLayout extends React.Component {
  state = {
    categories: null,
    matchingArtists: null,
    filteredResults: null,
    activeFilters: [],
  }

  componentDidMount() {
    const { results } = this.props;
    if (results && results.length > 0) {
      this.getRelatedArtists();
      this.getCategories();
      this.setState({
        filteredResults: results,
      });
    } else {
      this.setState({
        matchingArtists: null,
        categories: null,
        filteredResults: null,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { results } = this.props;
    if (prevProps.results !== results) {
      if (results && results.length > 0) {
        this.getRelatedArtists();
        this.getCategories();
        this.setState({
          filteredResults: results,
        });
      } else {
        this.setState({
          matchingArtists: null,
          categories: null,
          filteredResults: null,
        });
      }
    }
  }

  getRelatedArtists() {
    const { results } = this.props;
    const relatedArtistsNames = [];
    const relatedArtists = [];

    results.forEach((r) => {
      if (r.postDescription.relatedArtists && r.postDescription.relatedArtists.length > 0) {
        r.postDescription.relatedArtists.forEach((a) => {
          if (!relatedArtistsNames.includes(a.name) && a.name !== null) {
            relatedArtistsNames.push(a.name);
            relatedArtists.push(a);
          }
        });
      }
    });
    this.setState({ matchingArtists: relatedArtists });
  }

  getCategories() {
    const { results } = this.props;
    const postCategories = [];
    const repeatedCategories = [];

    let currentCategory = null;
    let cnt = 0;

    results.forEach((r) => {
      repeatedCategories.push(r.postCategory[0]);
    });
    repeatedCategories.sort();
    repeatedCategories.forEach((c) => {
      if (c !== currentCategory) {
        if (cnt > 0 && !postCategories.includes(currentCategory)) {
          postCategories.push({
            category: currentCategory,
            numberOfResults: cnt,
          });
        }
        currentCategory = c;
        cnt = 1;
      } else {
        cnt++;
      }
    });
    this.setState({ categories: postCategories });
  }

  filterResultsByCategories(categories) {
    const { results } = this.props;
    let filteredResults = [];
    if (categories.length > 0) {
      results.forEach((r) => {
        if (categories.includes(r.postCategory[0])) {
          filteredResults.push(r);
        }
      });
    } else {
      filteredResults = results;
    }
    this.setState({ filteredResults });
  }

  handleFilters(category) {
    const { activeFilters } = this.state;
    const filtersArray = activeFilters;

    if (filtersArray.includes(category)) {
      const position = filtersArray.indexOf(category);
      filtersArray.splice(position, 1);
    } else {
      filtersArray.push(category);
    }
    this.setState({ activeFilters: filtersArray });
    this.filterResultsByCategories(filtersArray);
  }

  render() {
    const { results, match, hasSortOption } = this.props;
    const {
      categories,
      matchingArtists,
      filteredResults,
      activeFilters,
    } = this.state;

    const TagMatchingArtist = (name, slug, color) => (
      <Link
        className={`border-4 border-solid border-${color} py-1 px-4 bg-white mr-2 mb-2`}
        to={`/artista/${slug}`}
      >
        <span className={`font-stratos font-semibold text-${color} text-base`}>{name}</span>
      </Link>

    );

    const TagCategory = (tag, color) => (
      <div onClick={() => this.handleFilters(tag.category)} key={tag.category} className={`cursor-pointer border-4 border-solid border-${color} py-1 px-2 tablet:px-4 bg-${color} tablet:bg-${activeFilters.includes(tag.category) ? color : 'white'} mr-2 mb-2`}>
        <span className={`hidden tablet:block laptop:block desktop:block font-stratos font-semibold text-${activeFilters.includes(tag.category) ? 'white' : color} text-base capitalize`}>{tag.category}</span>
        <span className="tablet:hidden laptop:hidden desktop:hidden font-stratos font-semibold text-white text-base capitalize">{`${tag.category} (${tag.numberOfResults})`}</span>
      </div>
    );

    if (hasSortOption) {
      return (
        <SearchInputListing />
      );
    }

    return (
      <RoutesContext.Consumer>
        {
        ({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const firstTitle = {
            en: 'Matching artists',
            ca: 'Artistes coincidents',
            es: 'Artistas coincidentes',
            pt: 'Artistas correspondentes',
          };
          const secondTitle = {
            en: 'Categories',
            ca: 'Categories',
            es: 'Categorías',
            pt: 'Categorias',
          };

          return (
            <div className="w-full">
              <div className="w-full tablet:w-1/2">
                {matchingArtists && matchingArtists.length > 0 && (
                  <div className={`${categories && categories.length > 0 ? 'pb-8' : 'pb-12'} w-full`}>
                    <h2 className="font-stratos font-semibold text-20 tablet:text-base desktop:text-22 text-black pb-4">{firstTitle[localization.language]}</h2>
                    <div className="flex flex-wrap">
                      {matchingArtists.map(artistTag => (
                        TagMatchingArtist(artistTag.name, artistTag.slug, 'black')
                      ))}
                    </div>
                  </div>
                )}
                {categories && categories.length > 0 && (
                <div className="pb-12 laptop:pb-20 desktop:pb-12 w-full">
                  <h2 className="font-stratos font-semibold text-20 tablet:text-base desktop:text-22 text-red pb-4">{secondTitle[localization.language]}</h2>
                  <div className="flex flex-wrap">
                    {categories.map(categoryTag => (
                      TagCategory(categoryTag, 'red')
                    ))}
                  </div>
                </div>
                )}
              </div>
              <div className="w-full flex flex-col tablet:flex-row tablet:flex-wrap">
                {
                filteredResults && filteredResults.length > 0
                  ? filteredResults.map((searchResult, index) => (
                    <SearchResult key={searchResult.slugName} {...searchResult} index={index} />
                  ))
                  : null
                }
              </div>
            </div>
          );
        }
      }
      </RoutesContext.Consumer>
    );
  }
}

SearchBasicLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.array.isRequired,
};

export default SearchBasicLayout;
