import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import userUpdateRoles from '../../../../api/graphql/mutations/userUpdateRoles';
import OnDemandModalNotLogged from '../OnDemandModals/OnDemandModalNotLogged';
// import OnDemandModalIsLogged from '../OnDemandModals/OnDemandModalIsLogged';
import PSResponsiveUtility from '../../LineUp/PSResponsiveUtility';
import LoadIcon from '../../LoadIcon';
import { useUser } from '../../../../context/userProfileContext';

const SimpleOnDemandModalScreen = ({
  userName,
  userLastName,
  userEmail,
  token,
  loadTokenForLS,
  isUserVerified,
  language,
  translations,
  image,
  closeModal,
  postVideoUrl,
  otherOptionsButtonUrl,
  userTrialWasUsed,
  userSubscriptionDetails,
  history,
  postVideoRole,
  sourceWeb,
  parentComponent,
  hideCloseButton,
}) => {
  const [screenStatus, setScreenStatus] = useState('loading');
  const [error, setError] = useState(null);
  const [screen, setScreen] = useState();
  const responsiveUtility = useRef();
  const user = useUser();

  const getDeviceScreen = () => {
    const deviceScreen = responsiveUtility.current.deviceScreen(true);
    setScreen(deviceScreen);
  };

  const handleUserRedirectToVideoPage = () => {
    history.push(`/${language || 'en'}${postVideoUrl && postVideoUrl[0] === '/' ? postVideoUrl : `/${postVideoUrl}`}`);
  };
  const handleUpdatingUserRoles = async (emailFromFn) => {
    setScreenStatus('loading');
    const email = emailFromFn || userEmail;
    const resultFromUpdatingUserRoles = await userUpdateRoles({ email, roles: [postVideoRole || 'noRoleProvided'] });
    const { token: tokenFromUserUpdateRolesQuery = '', isValid = false, error: errorFromUserUpdateRolesQuery = '' } = resultFromUpdatingUserRoles;
    if (isValid && tokenFromUserUpdateRolesQuery) {
      user.setNewAuthToken(tokenFromUserUpdateRolesQuery);
      if (postVideoUrl) { handleUserRedirectToVideoPage(); } else {
        closeModal();
        if (window && window.location) {
          window.location.reload();
        }
      }
    } else if (errorFromUserUpdateRolesQuery) {
      setError(errorFromUserUpdateRolesQuery);
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (!userEmail) {
      setScreenStatus('socialLogin');
    } else {
      const noFinalVideoUrlProvided = !postVideoUrl;
      const noFinalVideoRoleProvided = !postVideoRole;
      // setScreenStatus('logged');
      if (noFinalVideoUrlProvided && noFinalVideoRoleProvided) {
        closeModal();
      } else {
        // * update user (add role) and send to final video url
        handleUpdatingUserRoles(userEmail);
      }
    }
  }, [userEmail]);

  const handleOnDemandScreenStatus = (screenStatusFromFn, email = '') => {
    if (screenStatusFromFn === 'logged') {
      // * update user (add role) and send to final video url
      // handleUpdatingUserRoles(email);
      // console.log('!!!: handleOnDemandScreenStatus -> screenStatusFromFn', screenStatusFromFn);
    } else {
      setScreenStatus(screenStatusFromFn);
    }
  };


  const renderLogic = () => {
    switch (screenStatus) {
      case 'loading':
        return (
          <div
            className="bg-gray200 p-8 sm:p-2 w-full"
          >
            <LoadIcon color="black" type="ThreeDots" />
            {error && <div className="text-red font-stratos">Incorrect User, please try again later</div>}
          </div>
        );
      case 'socialLogin':
        return (
          <>
            <OnDemandModalNotLogged parentComponent={parentComponent} language={language} loadTokenForLS={loadTokenForLS} handleOnDemandScreenStatus={handleOnDemandScreenStatus} translations={translations} sourceWeb={sourceWeb} />
          </>
        );
        // case 'logged':
        //   return (
        //     <OnDemandModalIsLogged userEmail={userEmail} language={language} userSubscriptionDetails={userSubscriptionDetails} loadTokenForLS={loadTokenForLS} userTrialWasUsed={userTrialWasUsed} handleOnDemandScreenStatus={handleOnDemandScreenStatus} translations={translations} image={image} closeModal={closeModal} postVideoUrl={postVideoUrl} otherOptionsButtonUrl={otherOptionsButtonUrl} />
        //   );

      default:
        return (
          <div
            className="bg-gray200 p-8 sm:p-2 w-full"
          >
            <div>please review...</div>
          </div>
        );
    }
  };

  const handleCloseClick = () => {
    closeModal();
  };

  if (parentComponent === 'Paywall') {
    return renderLogic();
  }

  const modalStyle = screen !== 'mobile'
    ? 'mx-auto max-w-342 max-h-692 h-692 w-342 tablet:h-screen tablet:w-full bg-white border-red border-11 overflow-auto'
    : 'w-screen h-screen pb-16 bg-white border-white border-11 overflow-auto';

  return (
    <>
      <PSResponsiveUtility ref={responsiveUtility} deviceScreenCallback={getDeviceScreen} rdlSizes />
      <div className={modalStyle}>
        <div className={`${screen === 'mobile' ? 'bg-white h-screen80' : 'h-full'} flex flex-col items-stretch justify-start relative`}>
          <div key="button-wrapper" className="flex items-center justify-end px-6 pt-5 pb-2" style={{ minHeight: '26px' }}>
            { !hideCloseButton ? (
            <button
              type="button"
              className="focus:outline-none font-icon icon-close text-red text-18 tablet:text-20"
              onClick={handleCloseClick}
            /> ) : null }
          </div>
          <div data-name="logo-wrapper" className={`flex items-center justify-center px-6 ${screen === 'mobile' ? 'pb-5' : 'pb-10'}`}>
            <svg className="w-2/3 h-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.225 35.303">
              {/* eslint-disable-next-line max-len */}
              <path d="M215.153 21.122l8.688-12.991h-7.629l-4.849 7.786-4.8-7.786h-7.825l8.689 13.091-9.026 13.486h7.632l5.184-8.28 5.182 8.28h7.825zm-25.01 13.586h7.3V8.131h-7.3v14.826c0 3.57-1.777 5.4-4.56 5.4s-4.417-1.834-4.417-5.4V8.131h-7.294v17.206c0 6.1 3.215 9.867 8.736 9.867 3.695 0 5.855-2.033 7.536-4.264zm-27.229 0h7.3V.808h-7.3zm-2.688-10.661c.048-.694.095-1.387.095-1.983 0-7.536-3.935-14.429-12.527-14.429-7.488 0-12.721 6.248-12.721 13.834v.1c0 8.131 5.712 13.734 13.441 13.734a12.711 12.711 0 0010.512-4.958l-4.177-3.818a8.3 8.3 0 01-6.24 2.727 6.013 6.013 0 01-6.287-5.207zm-7.009-4.71h-10.991c.576-3.421 2.544-5.652 5.568-5.652 3.072 0 4.992 2.281 5.423 5.652m-27.822 15.371h7.295V.808h-7.295v10.79a9.776 9.776 0 00-8.017-3.967c-6.1 0-11.712 4.859-11.712 13.734v.1c0 8.876 5.713 13.734 11.712 13.734a9.373 9.373 0 008.017-4.314zm.095-13.239c0 4.413-2.832 7.338-6.239 7.338s-6.288-2.926-6.288-7.338v-.1c0-4.463 2.879-7.339 6.288-7.339s6.239 2.926 6.239 7.339zm-28.25-2.876l9.792-10.463h-8.736l-8.544 9.668V8.129h-7.3v26.579h7.3v-8.082l2.592-2.827 6.576 10.909h8.449zm-33 2.876v-.1c0-3.917 2.592-7.239 6.24-7.239 2.592 0 4.225 1.141 5.857 2.926l4.463-4.958c-2.4-2.778-5.425-4.464-10.272-4.464-7.872 0-13.489 6.248-13.489 13.834v.1c0 7.585 5.664 13.734 13.393 13.734a12.8 12.8 0 0010.514-4.859l-4.273-4.463c-1.777 1.735-3.455 2.826-5.905 2.826-3.984 0-6.528-3.272-6.528-7.338m-8.147 0v-.1A13.582 13.582 0 0042.222 7.635a13.716 13.716 0 00-13.968 13.834v.1a13.582 13.582 0 0013.872 13.734 13.715 13.715 0 0013.969-13.834m-7.2.1c0 3.917-2.5 7.238-6.672 7.238-4.031 0-6.768-3.421-6.768-7.338v-.1c0-3.917 2.5-7.239 6.672-7.239 4.032 0 6.769 3.421 6.769 7.338zM28.032 11.553v-.1a11.1 11.1 0 00-2.88-7.934c-2.208-2.28-5.52-3.52-9.792-3.52H-.001v34.709H7.39v-11.11h5.809l7.2 11.106h8.64l-8.209-12.4c4.273-1.637 7.2-5.157 7.2-10.76m-7.487.4c0 2.926-2.064 4.909-5.665 4.909H7.389V6.886h7.345c3.6 0 5.809 1.687 5.809 4.958z" fill="#ff4646" />
            </svg>
          </div>
          <div key="content-wrapper" className="flex-1 flex tablet:h-full">
            {/* Aquí dentro se renderizan OnDemandModals con la siguiente función  */}
            {renderLogic()}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SimpleOnDemandModalScreen);
