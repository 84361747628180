import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const AudioPlayer = ({
  audioFile,
}) => (
  <>
    <div className="w-full">
      <audio
        className="w-full block outline-none"
        controls
        src={audioFile}
        controlsList="nodownload"
      />
    </div>
  </>
);

AudioPlayer.propTypes = {
  audioFile: PropTypes.string.isRequired,
};

AudioPlayer.defaultProps = {
};

export default withRouter(AudioPlayer);
