import React from 'react';
import { PropTypes } from 'prop-types';
import { motion } from 'framer-motion';

const RadioFormButton = ({ active }) => {
  const bulletVariants = {
    active: { scale: 1 },
    inactive: { scale: 0 },
  };

  return (
    <div className="w-8 h-8 rounded-full border border-black flex items-center justify-center">
      <motion.div
        className="bg-red rounded-full"
        style={{ width: '10px', height: '10px' }}
        animate={active ? 'active' : 'inactive'}
        variants={bulletVariants}
      />
    </div>
  );
};

export default RadioFormButton;

RadioFormButton.propTypes = {
  active: PropTypes.bool.isRequired,
};
