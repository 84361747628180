import fetch from 'node-fetch';

const getPDFURL = async (key) => {
  try {
    const publicUrlResponse = await fetch(`https://pdfapi.rockdelux.com/production/get-public-url?key=${key}`, { method: 'GET' });

    if (publicUrlResponse.status >= 400) {
      throw new Error('Could not get public S3 URL for file');
    }

    return publicUrlResponse.json();
  } catch (error) {
    console.log(error);
    return { url: '' };
  }
};

export default getPDFURL;
