import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import SearchResult from '../SearchResult';

const InfiniteHits = ({
  hits,
  hasMore,
  refineNext,
  isListPage,
  listPageType,
  sorting,
  query,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const history = useHistory();
  const buttonRef = useRef();
  const hitRef = useRef();

  const handleLoadMore = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = urlParams.get('page') || 0;

    urlParams.set('page', Number(page) + 1);

    history.replace(`${history.location.pathname}?${urlParams}`);
    setFirstLoad(false);
    refineNext();
  };

  const handleLastVisitedItemPosition = (slug) => {
    const urlParams = new URLSearchParams(history.location.search);

    urlParams.delete('last');
    history.replace(`${history.location.pathname}?${urlParams}`);

    const item = document.getElementById(slug);

    if (item) {
      item.scrollIntoView({ block: 'center', inline: 'nearest' });
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = urlParams.get('page') || 0;
    const lastSlugName = urlParams.get('last');

    if (firstLoad) {
      setFirstLoad(false);
      if (Number(page) > 0) {
        if (!lastSlugName) {
          urlParams.delete('page');
          history.replace(`${history.location.pathname}?${urlParams}`);
        }
        for (let i = 1; i <= Number(page); i++) {
          setTimeout(() => {
            if (buttonRef && buttonRef.current) {
              const evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              buttonRef.current.dispatchEvent(evt);

              setTimeout(() => {
                handleLastVisitedItemPosition(lastSlugName);
              }, 0);
            }
          }, 0);
        }
      } else {
        setTimeout(() => {
          handleLastVisitedItemPosition(lastSlugName);
        }, 0);
      }
    }
  }, []);

  return (
    <div className="w-full">
      <div ref={hitRef} className={`w-full flex ${listPageType === 'records' || listPageType === 'hemeroteca' ? 'flex-row flex-wrap' : 'flex-col tablet:flex-row tablet:flex-wrap '}`}>
        {hits.map((hit) => (
          <SearchResult
            showFeatured={(sorting.includes('desc') && query.length === 0 && isListPage && listPageType !== 'hemeroteca')}
            key={`${hit.slugName}_${hit.__position}`}
            {...hit}
            isAlgolia
            index={hit.__position}
            isListPage={isListPage}
            listPageType={listPageType}
            query={query}
          />
        ))}
        {hits.length === 0 && <div className="font-stratos text-black mb-24 text-center">No se han encontrado resultados.</div>}
      </div>
      {hasMore && (
      <div className="text-center">
        <button className="font-stratos text-black mb-12 text-20 font-semibold hover:underline" type="button" onClick={handleLoadMore}>Cargar más</button>
        <button className="hidden" type="button" onClick={refineNext} ref={buttonRef} />
      </div>
      )}
    </div>
  );
};

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

export default CustomInfiniteHits;

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  hits: PropTypes.arrayOf(PropTypes.shape()),
  refineNext: PropTypes.func,
  isListPage: PropTypes.bool,
  listPageType: PropTypes.string,
  sorting: PropTypes.string,
  query: PropTypes.string,
  custom: PropTypes.string,
};

InfiniteHits.defaultProps = {
  hits: [],
  refineNext: () => {},
  isListPage: false,
  listPageType: null,
  sorting: 'desc',
  query: '',
  custom: '',
};
