/* eslint-disable react/forbid-prop-types */

/**
 * This component shows a card form so the customer can enter the card data
 * Normally it will be called when the customer has no card stored, calling stripe.confirmCardSetup
 *
 * If can also be called when a user uses a stored card but this failed for some reason. In this case,
 * a paymentIntentClientSecret of the paymentIntent from server is passed and it will do a stripe.confirmCardPayment
 */
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
// import jwt from 'jsonwebtoken';
import api from '../Payments/psWorldPaymentsApi';
// import translateObj from '../../util/translateObj';
// import getProducts from '../../api/graphql/queries/getProducts';
// import FormInput from '../Form/FormInput';


const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '15px',
          color: '#2B2E34',
          letterSpacing: '0.025em',
          lineHeight: '1.4em',
          fontFamily: 'Stratos',
          '::placeholder': {
            color: '#2B2E34',
          },
        },
        invalid: {
          fontSize: '15px',
          color: '#FF4646',
          fontFamily: 'Stratos',
          '::placeholder': {
            color: '#FF4646',
          },
        },
      },
    }),
  );

  return options;
};

const PsWorldStripeCardForm = ({
  customerInfo,
  product,
  paymentIntentClientSecret, // If present, it will do a stripe.confirmCardPayment. If not: stripe.confirmCardSetup
  publicKey,
  resultCallback,
}) => {
  const [cardSetupSecret, setCardSetupSecret] = useState(null);
  const [setupIntent, setSetupIntent] = useState(null);
  const [error, setError] = useState(null);
  const [resultInfo, setResultInfo] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [cardNumberStatus, setCardNumberStatus] = useState(null);
  const [cardExpiryStatus, setCardExpiryStatus] = useState(null);
  const [cardCvcStatus, setCardCvcStatus] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const inputStyle = `${error ? 'border-3 border-red' : 'border border-black'} w-full h-12 p-3 mt-2 mb-3`;
  const inputStyleShort = `${error ? 'border-3 border-red' : 'border border-black'} w-40 h-12 p-3 mt-2 mb-3`;
  //const faqText = 'Para más información sobre los métodos de pago, puedes visitar nuestra FAQ.';
  const faqText = '';

  useEffect(() => {
    if (!customerInfo) {
      return;
    }

    if (paymentIntentClientSecret) {
      // If there is already a client secret passed as prop, don't create setup intent!
      return;
    }

    api
      .createSetupIntent({
        customer: customerInfo.id,
      })
      .then(async (setupIntentObject) => {
        setSetupIntent(setupIntentObject);
        setCardSetupSecret(setupIntentObject.client_secret);
      })
      .catch((err) => {
        setError(err.message);
        setProcessing(false);
      });
  }, [customerInfo]);

  useEffect(() => {
    if (
      (cardNumberStatus === 'valid' && cardExpiryStatus === 'valid' && cardCvcStatus === 'valid')
      || (!cardNumberStatus && !cardExpiryStatus && !cardCvcStatus)
    ) {
      setError(null);
    }
  }, [cardNumberStatus, cardExpiryStatus, cardCvcStatus]);

  /* Shows a success / error message when the payment is complete */
  const orderComplete = (subscription) => {
    setProcessing(false);
    setSucceeded(true);
    setResultInfo(`Subscription Status: ${subscription.status}`);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    setError(false);

    if (paymentIntentClientSecret) {
      // If paymentIntentClientSecret prop is passed, itmeans we have a payment intent (due to a failed offline payment intent) instead of a setupintent
      stripe
        .confirmCardPayment(paymentIntentClientSecret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
            save_payment_method: true,
          })
        .then(async (result) => {
          if (result.error) {
            setError(`Payment failed: ${result.error.message}`);
            setProcessing(false);
            // console.log('[error]', result.error);
            resultCallback({ status: 'failed' });
          } else {
            setError(null);
            setSucceeded(true);
            setProcessing(false);
            setResultInfo('Payment succeeded');
            // console.log('[PaymentIntent]', result);
            resultCallback({ status: 'succeeded' });
          }
        })
        .catch((err) => {
          setError(err.message);
          setProcessing(false);
        });
    } else {
      // Normal first time get card details
      stripe
        .confirmCardSetup(cardSetupSecret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
            // save_payment_method: true,
          })
        .then(async (result) => {
          if (result.error) {
            setError(`Card setup failed: ${result.error.message}`);
            setProcessing(false);
            // console.log('[error]', result.error);
            resultCallback({ status: 'failed' });
          } else {
            setError(null);
            setSucceeded(true);
            setProcessing(false);
            setResultInfo('Card setup succeeded');
            // console.log('[SetupIntent]', result);
            resultCallback({ status: 'succeeded' });
          }
        })
        .catch((err) => {
          setError(err.message);
          setProcessing(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <label className="font-stratos text-10 text-black uppercase font-semilight" htmlFor="cardnumber">
        NÚMERO TARJETA
        <div className={`${inputStyle} flex flex-row justify-between items-center`}>
          <CardNumberElement
            options={options}
            className="w-90%"
            onReady={() => {
              // console.log('CardNumberElement [ready]');
            }}
            onChange={(event) => {
              // console.log('CardNumberElement [change]', event);
              if (event && event.error) {
                setError(event.error.code);
                setCardNumberStatus('invalid');
              } else if (event && event.complete) {
                setCardNumberStatus('valid');
              } else if (event && event.empty) {
                setCardNumberStatus(null);
              }
            }}
            onBlur={() => {
              // console.log('CardNumberElement [blur]');
            }}
            onFocus={() => {
              // console.log('CardNumberElement [focus]');
            }}
          />
          <div className="w-10%">
            {cardNumberStatus === 'invalid' && <span className="font-icon icon-caution-circle text-center text-red text-20" />}
            {cardNumberStatus === 'valid' && <span className="font-icon icon-check text-center text-red text-20" />}
          </div>
        </div>
      </label>
      <label className="font-stratos text-10 text-black uppercase font-semilight" htmlFor="expirationDate">
        FECHA CADUCIDAD
        <div className={`${inputStyle} flex flex-row justify-between items-center`}>
          <CardExpiryElement
            options={options}
            className="w-90%"
            onReady={() => {
              // console.log('CardNumberElement [ready]');
            }}
            onChange={(event) => {
              // console.log('CardNumberElement [change]', event);
              if (event && event.error) {
                setError(event.error.code);
                setCardExpiryStatus('invalid');
              } else if (event && event.complete) {
                setCardExpiryStatus('valid');
              } else if (event && event.empty) {
                setCardExpiryStatus(null);
              }
            }}
            onBlur={() => {
              // console.log('CardNumberElement [blur]');
            }}
            onFocus={() => {
              // console.log('CardNumberElement [focus]');
            }}
          />
          <div className="w-10%">
            {cardExpiryStatus === 'invalid' && <span className="font-icon icon-caution-circle text-center text-red text-20" />}
            {cardExpiryStatus === 'valid' && <span className="font-icon icon-check text-center text-red text-20" />}
          </div>
        </div>
      </label>
      <label className="font-stratos text-10 text-black uppercase font-semilight" htmlFor="cvc">
        CVV
        <div className={`${inputStyleShort} flex flex-row justify-between items-center`}>
          <CardCvcElement
            options={options}
            className="w-80%"
            onReady={() => {
              // console.log('CardNumberElement [ready]');
            }}
            onChange={(event) => {
              // console.log('CardNumberElement [change]', event);
              if (event && event.error) {
                setError(event.error.code);
                setCardCvcStatus('invalid');
              } else if (event && event.complete) {
                setCardCvcStatus('valid');
              } else if (event && event.empty) {
                setCardCvcStatus(null);
              }
            }}
            onBlur={() => {
              // console.log('CardNumberElement [blur]');
            }}
            onFocus={() => {
              // console.log('CardNumberElement [focus]');
            }}
          />
          <div className="w-20%">
            {cardCvcStatus === 'invalid' && <span className="font-icon icon-caution-circle text-center text-red text-20" />}
            {cardCvcStatus === 'valid' && <span className="font-icon icon-check text-center text-red text-20" />}
          </div>
        </div>
      </label>
      <div className="text-14 text-black font-semilight font-stratos w-full py-8 tablet:pt-26 tablet:pb-10">
        {faqText}
      </div>
      <div className="flex flex-row w-full justify-between items-center">
        <button type="button" onClick={() => resultCallback({ status: 'cancel' })} className="text-grey text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-grey bg-beige mr-2">
          Cancelar
        </button>
        <button type="submit" className="text-red text-14 font-bold font-stratos px-4 w-32 h-9 border-6 border-red ml-2" disabled={processing || !stripe}>
          {processing ? 'Procesando…' : 'Continuar'}
        </button>
      </div>
      {/* <div className="flex justify-end mt-8 sm:mt-0">
        Show any error that happens when processing the payment
        {error && (
          <div className="w-full card-error" role="alert">
            {error}
          </div>
        )}
        Show a success message upon completion
        <p className={succeeded ? 'w-full result-message' : 'w-full result-message hidden'}>
          Card setup, see the result in your
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://dashboard.stripe.com/test/payments"
          >
            {' '}
            Stripe dashboard.
          </a>
        </p>
        {resultInfo && (
          <p>
            {resultInfo}
          </p>
        )}
      </div> */}
    </form>
  );
};

PsWorldStripeCardForm.propTypes = {
  // customerInfo: PropTypes.objectOf(PropTypes.object()).isRequired,
  customerInfo: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  paymentIntentClientSecret: PropTypes.string, // If present, it will do a stripe.confirmCardPayment. If not: stripe.confirmCardSetup
  publicKey: PropTypes.string,
  resultCallback: PropTypes.func.isRequired,
};

PsWorldStripeCardForm.defaultProps = {
  paymentIntentClientSecret: null,
  publicKey: null,
};

export default PsWorldStripeCardForm;
