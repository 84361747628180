import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import userPasswordRecoverUserSendsNewPassword from '../../api/graphql/mutations/userPasswordRecoverUserSendsNewPassword';
import SimpleButton from './Buttons/SimpleButton';

// import getLocalization from '../../util/localization';
// import RoutesContext from '../../context/routesContext';
import { useRoutes } from '../../context/routesContext';
import { UserProfileContextConsumer, UserProfileContext, useUser } from '../../context/userProfileContext';

import translateObj from '../../util/translateObj';

// !!! pending improving design in all sizes...


const RecoverPasswordEnterNewPassword = ({
  title, sourceWeb, emailTitle, emailTitle2, submitTitle, passwordUpdated, history, match, location: { search }, errorsText: { errorTooShort, errorFirstPasswordFirst, errorPasswordsDoNotMatch },
}) => {
  const { language = 'en' } = useRoutes(match);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isNewPasswordSubmited, setIsNewPasswordSubmited] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const user = useUser();

  const handleChange = (e) => {
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else {
      setPassword2(e.target.value);
    }
  };

  useEffect(() => {
    if (!password) {
      if (error1) {
        setError1('');
      }
    }
  }, [password]);
  useEffect(() => {
    if (!password2) {
      if (error2) {
        setError2('');
      }
    }
  }, [password2]);

  useEffect(() => {
    if (!error1 && !error2 && password && password2) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [error1, error2, password2, password]);

  useEffect(() => {
    if (isNewPasswordSubmited) {
      // const redirectTo = sourceWeb ? `/${language}/${sourceWeb}` : '/';
      const redirectTo = sourceWeb ? `/${sourceWeb}` : '/';
      setTimeout(() => {
        history.push(redirectTo);
        window.location.reload();
      }, 700);
    }
  }, [isNewPasswordSubmited]);


  const validateIfPasswordIsCorrect = (secondaryPassword, language) => {
    if (!secondaryPassword) {
      if (password.length < 6) {
        // const err1 = 'Your password is too short';
        const err1 = translateObj(errorTooShort, 'es');
        setError1(err1);
      } else {
        setError1('');
      }
    } else {
      let err2;
      if (!password) {
        // err2 = 'Please provide the main password first';
        err2 = translateObj(errorFirstPasswordFirst, 'es');
      } else if (password !== password2) {
        // err2 = 'Please double check, your passwords do not match';
        err2 = translateObj(errorPasswordsDoNotMatch, 'es');
      } else {
        err2 = '';
      }
      setError2(err2);
    }
  };

  const getQueryVariable = (query) => {
    const vars = query.split('&');
    const queryvars = [];
    for (let i = 0; i < vars.length; i++) {
      const index = vars[i].indexOf('=');
      const item = vars[i].substring(index + 1);
      queryvars.push(item);
    }
    if (queryvars.length) {
      return queryvars;
    }
    return false;
  };
  // prepare info from search query string on load
  useEffect(() => {
    const queryValues = getQueryVariable(search);
    const [code, emailFromUrl] = queryValues;
    setToken(code);
    setEmail(emailFromUrl);
  }, []);

  const sendNewPassword = () => {
    if (email && token) {
      userPasswordRecoverUserSendsNewPassword({ email, code: token, password }).then((res) => {
        if (res) {
          if (res.isValid) {
            user.setNewAuthToken(res.token);
            setIsNewPasswordSubmited(true);
          } else if (res.error) {
            switch (res.error) {
              case 'userCouldNotBeUpdatedinDB':
                setError2('user not update, please try again later');
                break;
              case 'noUserFound':
                setError2('User not found, please try again later');
                break;

              default:
                setError2('Something went wrong, please try again later');
                break;
            }
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - recover password send new password failed to submit!`);
          setError2('Something went wrong, please try again later');
        }
      });
    } else {
      setError2('Something went wrong with the query string, please try again later');
    }

    // setIsNewPasswordSubmited(true);
  };

  const enterDoublePasswordToUpdateUser = () => (
    <div className="mt-24 laptop:mt-32">
      {!isNewPasswordSubmited ? (
        <div>
          <div className={`${'w-full tablet:p-8 flex flex-col items-center'} `}>
            <p className="font-stratos m-0">
              {translateObj(title, 'es')}
            </p>
            <div className="tablet:flex w-full pb-8 pt-4 tablet:flex-col tablet:items-center">
              <div className="mt-4">
                <div className="inline-block w-full">
                  <div className="flex flex-col">
                    <label className="flex-col pr-6 font-stratos text-12 sm:pr-0 sm:w-full text-12 font-light text-black uppercase pb-4">
                      {translateObj(emailTitle, 'es')}
                    </label>
                    <input type="password" name="password" onChange={handleChange} className="px-6 mr-4 sm:mr-0 font-stratos text-12 border-1 p-4 bg-inherit border-black text-black text-12 w-full tablet:w-120" value={password} onBlur={() => { validateIfPasswordIsCorrect(false, language); }} />
                    {error1 && <span className="text-red mt-4 font-stratos uppercase font-light text-12">{error1}</span>}
                    <label className="flex-col pr-6 font-stratos text-12 sm:pr-0 sm:w-full text-12 font-light text-black uppercase py-4">
                      {translateObj(emailTitle2, 'es')}
                    </label>
                    <input type="password" name="password2" onChange={handleChange} className="px-6 mr-4 sm:mr-0 font-stratos text-12 border-1 p-4 bg-inherit border-black text-black text-12 w-full tablet:w-120" value={password2} onBlur={() => { validateIfPasswordIsCorrect(true, language); }} />
                    {error2 && <span className="text-red mt-4 font-stratos uppercase font-light text-12">{error2}</span>}
                    <div className="flex flex-row justify-end w-full tablet:w-120 mt-4">
                      <button
                        onClick={() => sendNewPassword()}
                        type="submit"
                        disabled={!canSubmit}
                      >
                        <SimpleButton
                          isDisabled
                          isLowercase
                          isFullWidth
                          className="min-w-cta py-6px laptop:py-1 desktop:py-2 text-base tablet:text-sm laptop:text-base px-6px laptop:px-4"
                          theme={canSubmit ? { base: 'red', hover: 'red' } : { base: 'disabled' }}
                          text={translateObj(submitTitle, 'es')}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
        : (
          <div className="font-stratos font-bold uppercase w-full h-full flex items-center justify-center">
            {translateObj(passwordUpdated, 'es') || 'your password has been updated'}
          </div>
        )
      }
    </div>
  );


  return (
    <UserProfileContextConsumer>
      {({ token }) => (
        <>
          {enterDoublePasswordToUpdateUser()}
        </>
      )}
    </UserProfileContextConsumer>
  );
};

RecoverPasswordEnterNewPassword.propTypes = {
  match: PropTypes.shape().isRequired,
  text: PropTypes.shape({}),
  userTitle: PropTypes.shape({}),
  submitTitle: PropTypes.shape({}),
  sourceWeb: PropTypes.string,
};

RecoverPasswordEnterNewPassword.defaultProps = {
  text: null,
  userTitle: null,
  submitTitle: null,
  sourceWeb: '',
};


export default withRouter(RecoverPasswordEnterNewPassword);
