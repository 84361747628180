import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import { useRoutes } from '../../context/routesContext';
import InputTextButton from './InputTextButton';
import translateObj from '../../util/translateObj';
import validateCoupon from '../../api/graphql/queries/validateCoupon';
import { UserProfileContext } from '../../context/userProfileContext';
import MenuPopUpLoginButton from './Login/MenuPopUpLoginButton';

const CouponExchange = ({
  match, title,
}) => {
  const { language = 'en' } = useRoutes(match);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const userContext = useContext(UserProfileContext);
  const category = 'black';

  useEffect(() => {
    setIsUserLoggedIn(!!userContext.userEmail);
  }, [userContext]);

  const handleEnter = async (couponCode) => {
    console.log('handleEnter -> couponCode', couponCode);
    if (couponCode) {
      const responseTexts = {
        invalid: {
          ca: 'Cupó invàlid o caducat',
          en: 'Cupón inválido o caducado',
          es: 'Invalid or expired coupon',
        },
      };
      const result = await validateCoupon({ couponCode });
      setError(result.error);
      setMessage(result.valid ? 'Valid code' : translateObj(responseTexts.invalid, language));
    }
  };

  return (
    <div className="p-8 bg-white mt-8 w-full">
      {(isUserLoggedIn ? '' : <MenuPopUpLoginButton showText isCentered iconClasses=" sm:pl-0 text-base sm:text-base md:text-base" textColor={category} sourceWeb={category === 'pro-online' ? 'pro-online' : ''} language={language} icon="font-icon  icon-star" />)}
      <span className="font-americaMonoBold block text-sm text-black uppercase pb-8 mb-8 border-dashed border-black border-b-1">{translateObj(title, language)}</span>
      <InputTextButton
        name="searchQuery"
        params={{
          text: {
            es: 'validar', en: 'validate', ca: 'validar',
          },
          placeholder: { es: '', en: '', ca: '' },
        }}
        handleClick={handleEnter}
      />

      <div className="flex mt-8 sm:mt-0">
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="w-full card-error" role="alert">
            Error:
            {' '}
            {error}
          </div>
        )}
        {message && (
          <div className="w-full">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

CouponExchange.propTypes = {
  match: PropTypes.shape().isRequired,
  title: PropTypes.shape({}).isRequired,
};

export default withRouter(CouponExchange);
