import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleButton from './Buttons/SimpleButton';
import { useBraze } from '../../context/useBraze';
import userWebVerification from '../../api/graphql/mutations/userWebVerification';

import { HAS_CONSUMED_ALL_FREE_ARTICLES } from '../../util/brazeEvents';

const ConsumedArticles = () => {
  const { logCustomEvent } = useBraze();

  useEffect(() => {
    logCustomEvent(HAS_CONSUMED_ALL_FREE_ARTICLES);
  }, []);

  return (
    <>
      <h1 className="text-red text-22 tablet:text-28 laptop:text-48 mb-7 tablet:mb-8 laptop:mb-12">
        <span className="block mb-2 laptop:mb-4 text-48">!!!</span>
        Has superado el límite
        <br />
        de artículos mensuales
      </h1>
      <div>
        <SimpleButton
          isLowercase
          text="Suscríbete"
          url="/suscribirse"
          borders="border-5 desktop:border-6"
          className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
        />
      </div>
    </>
  );
};

const MustVerify = ({ userEmail }) => {
  const [userActivationRequestSent, setUserActivationRequestSent] = useState(false);

  // Send email verification on verification status button click
  const handleResendVerification = () => {
    if (userEmail) {
      setUserActivationRequestSent(true);
      userWebVerification({ email: userEmail }).then((res) => {
        if (res) {
          if (res.error !== null) {
            console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ API: Error`, res.error);
          } else {
            setUserActivationRequestSent(true);
          }
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - webVerification failed to submit!`);
        }
      });
    }
  };

  MustVerify.propTypes = {
    userEmail: PropTypes.bool,
  };

  MustVerify.defaultProps = {
    userEmail: '',
  };

  return (
    <>
      <h1 className="text-red font-bold text-22 tablet:text-28 laptop:text-48 tracking-3">
        Debes validar tu cuenta
      </h1>
      <p className="max-w-paywallMobile tablet:max-w-full pt-5 pb-8 laptop:pb-12 desktop:pb-10 text-base laptop:text-20 text-black leading-normal">
        Para poder leer el contenido tienes que validar tu cuenta.
        <br />
        Pulsa el enlace que te hemos enviado en el email de registro y podrás
        acceder a 3 artículos gratis al mes.
      </p>

      <p className="max-w-paywallMobile tablet:max-w-full pt-5 pb-8 laptop:pb-12 desktop:pb-10 text-base laptop:text-20 text-black leading-normal">
        Si ya has validado tu cuenta y ves este mensaje, debes cerrar la
        sesión y volver a entrar con tu usuario.
      </p>

      { !userActivationRequestSent && (
        <p>
          <a
            href="#"
            className="text-red"
            onClick={(e) => {
              e.preventDefault();
              handleResendVerification();
            }}
          >
          Reenviar email de verificación
          </a>
        </p>
      )
      }
      { userActivationRequestSent && (
        <p className="text-red">
          Email de verificación enviado
        </p>
      )
      }
      {/* <div>
        <SimpleButton
          isLowercase
          text="Suscríbete"
          url="/suscribirse"
          borders="border-5 desktop:border-6"
          className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
        />
      </div> */}
    </>
  );
};

const ExclusiveContent = ({ originPost }) => {
  const showLoginModal = () => {
    const loginEvent = new CustomEvent(
      'loginMenuEvent', {
        detail: { close: false },
      },
    );
    window.dispatchEvent(loginEvent);
  };

  return (
    <>
      <h1 className="text-red font-bold text-22 tablet:text-28 laptop:text-48 tracking-3">
        Contenido exclusivo
      </h1>
      <p className="max-w-paywallMobile tablet:max-w-full pt-5 pb-8 laptop:pb-12 desktop:pb-10 text-base laptop:text-20 text-black leading-normal">
        Para poder leer el contenido tienes que estar registrado.
        <br />
        <span onClick={showLoginModal} className="text-red font-bold cursor-pointer">Regístrate</span>
        {' '}
        y podrás acceder a 3 artículos gratis al mes.
      </p>
      <div className="flex justify-center">
        <div className="mr-1 laptop:mr-2 desktop:mr-4">
          <SimpleButton
            isLowercase
            text="Suscríbete"
            url={`/suscribirse${originPost && `?originPost=${originPost}`}`}
            borders="border-5 desktop:border-6"
            className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
          />
        </div>
        <div className="ml-1 laptop:ml-2 desktop:ml-4">
          <SimpleButton
            isLowercase
            text="Inicia sesión"
            callback={showLoginModal}
            borders="border-5 desktop:border-6"
            className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
          />
        </div>
      </div>
    </>
  );
};

ExclusiveContent.propTypes = {
  originPost: PropTypes.string,
};

ExclusiveContent.defaultProps = {
  originPost: null,
};

const MustPayContent = () => (
  <>
    <h1 className="text-red font-bold text-22 tablet:text-28 laptop:text-48 tracking-3">
      Contenido exclusivo
    </h1>
    <p className="max-w-paywallMobile tablet:max-w-full pt-5 pb-8 laptop:pb-12 desktop:pb-10 text-base laptop:text-20 text-black leading-normal">
      Para poder leer este contenido tienes que estar suscrito.
    </p>
    <div>
      <SimpleButton
        isLowercase
        text="Suscríbete"
        url="/suscribirse"
        borders="border-5 desktop:border-6"
        className="laptop:text-20 desktop:text-26 py-1 laptop:py-2 desktop:py-3 px-4 min-w-dropdownTablet laptop:min-w-ctaBig"
      />
    </div>
  </>
);

const Content = ({ consumedArticles, mustPay, originPost, mustVerify, userEmail }) => {
  if (mustPay) return <MustPayContent />;
  if (consumedArticles) return <ConsumedArticles />;
  if (mustVerify) return <MustVerify userEmail={userEmail} />;
  return <ExclusiveContent originPost={originPost} />;
};

Content.propTypes = {
  consumedArticles: PropTypes.bool.isRequired,
  mustPay: PropTypes.bool.isRequired,
  mustVerify: PropTypes.bool.isRequired,
  userEmail: PropTypes.string,
  originPost: PropTypes.string,
};

Content.defaultProps = {
  originPost: null,
  userEmail: '',
};

const AccessPaywallOverlay = ({ consumedArticles, mustPay, originPost, mustVerify, userEmail }) => (
  <div className="fixed pin-x pin-y py-8 text-center bg-paywall z-top flex items-center justify-center">
    <div className="font-stratos flex flex-col items-center justify-center">
      <Content {...{ consumedArticles, mustPay, originPost, mustVerify, userEmail }} />
    </div>
  </div>
);

AccessPaywallOverlay.propTypes = {
  consumedArticles: PropTypes.bool.isRequired,
  mustPay: PropTypes.bool.isRequired,
  mustVerify: PropTypes.bool,
  userEmail: PropTypes.string,
  originPost: PropTypes.string,
};

AccessPaywallOverlay.defaultProps = {
  originPost: null,
  mustVerify: false,
  userEmail: '',
};

export default AccessPaywallOverlay;
