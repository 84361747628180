import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import CollapsibleListItem from './CollapsibleListItem';
import slugify from '../../../util/slugify';

const style = {
  list: {
    padding: 0,
    marginBottom: 0,
  },
  root: {
    backgroundColor: 'black',
    height: '2px',
  },
};
class CollapsibleList extends React.Component {
  state = {
    dropDown: '',
    values: [],
    titles: [],
    categories: [],
  };

  componentDidMount() {
    const { firstClosed, components } = this.props;
    const values = components.map(comp => slugify(comp.text.en));
    const titles = components.map(comp => comp.text.en);
    const categories = components.map(comp => comp.button.category.replace(' ', '-'));
    let dropDown = '';
    const query = this.getQueryVariable();
    if (!firstClosed) {
      dropDown = components[0].text.en;
    } else {
      if (query && values.find(val => query.includes(val))) {
        const activeValue = values.find(val => query.includes(val));
        const activeIndex = values.indexOf(activeValue);
        if (activeIndex > -1) {
          dropDown = titles[activeIndex];
        }
      } else if (query && categories.find(cat => query.includes(cat))) {
        const activeCat = categories.find(val => query.includes(val));
        const activeIndex = categories.indexOf(activeCat);
        if (activeIndex > -1) {
          dropDown = titles[activeIndex];
        }
      }
    }
    this.setState({
      dropDown, values, titles, categories,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dropDown } = this.state;
    const { location: { search } } = this.props;
    if (dropDown === prevState.dropDown && search !== prevProps.location.search) {
      this.updatePath();
    }
  }

  handleClick = (type) => {
    const { dropDown } = this.state;
    let dd;
    if (type !== dropDown) {
      dd = type;
    } else {
      dd = '';
    }
    this.setState({ dropDown: dd });
    this.pushToPath(type);
  };

  pushToPath = (clickedEvent) => {
    const { values, titles, categories } = this.state;
    const eventIndex = titles.indexOf(clickedEvent);
    const hashedEvent = values[eventIndex];
    const currentCat = categories[eventIndex];
    const query = this.getQueryVariable();
    const {
      location: { pathname }, history,
    } = this.props;
    let filters = query || [];
    if (query && (query.includes(hashedEvent))) {
      filters = query.filter(val => val !== hashedEvent);
    }
    if (query && (query.includes(currentCat))) {
      filters = query.filter(val => val !== currentCat);
    }
    if (!query && clickedEvent) {
      filters.push(hashedEvent);
    }
    if (query && clickedEvent && !query.includes(hashedEvent) && !query.includes(currentCat)) {
      filters = [hashedEvent];
    }
    if (!filters.length) {
      history.push(`${pathname}`);
    } else {
      history.push(`${pathname}?e=${filters.join(',')}`);
    }
  }

  updatePath = () => {
    const { values, titles, categories } = this.state;
    const query = this.getQueryVariable();
    if (query && values.find(val => query.includes(val))) {
      const activeValue = values.find(val => query.includes(val));
      const activeIndex = values.indexOf(activeValue);
      if (activeIndex > -1) {
        this.setState({ dropDown: titles[activeIndex] });
      }
    } else if (query && categories.find(val => query.includes(val))) {
      const activeValue = categories.find(val => query.includes(val));
      const activeIndex = categories.indexOf(activeValue);
      if (activeIndex > -1) {
        this.setState({ dropDown: titles[activeIndex] });
      }
    } else {
      this.setState({ dropDown: '' });
    }
  }

  getQueryVariable = () => {
    const { location: { search } } = this.props;
    if (search) {
      const filters = search.split('=');
      if (filters.length > 1) {
        const vars = filters[1].split(',');
        return vars;
      }
    }
    return null;
  }

  render() {
    const {
      components, classes, match, bgWhite,
    } = this.props;

    const { dropDown } = this.state;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div className="my-4 w-full">
              <List classes={{ root: classes.list }} style={{ background: `${bgWhite ? 'white' : ''}` }}>
                {components.map((comp, i) => (
                  <CollapsibleListItem key={comp.text.en + i} {...this.props} comp={comp} dropDown={dropDown} handleClick={this.handleClick} />
                ))}
              </List>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

CollapsibleList.propTypes = {
  classes: PropTypes.shape({}),
  components: PropTypes.arrayOf(PropTypes.shape({})),
  border: PropTypes.bool,
  lowercase: PropTypes.bool,
  firstClosed: PropTypes.bool,
  noLink: PropTypes.bool,
  hasFilter: PropTypes.bool,
};

CollapsibleList.defaultProps = {
  border: false,
  lowercase: false,
  firstClosed: true,
  noLink: true,
  components: null,
  classes: '',
  hasFilter: false,
};

export default withRouter(withStyles(style)(CollapsibleList));
