/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import withRadioPlayerContext from '../../../context/withRadioPlayerContext';
import getLocalization from '../../../util/localization';
import ComponentIterator from '../../../containers/ComponentIterator';
import RadioHeader1 from './RadioHeader1';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import Link from '../../../util/link';

class RadioTunerCard extends React.Component {
  state = {
    open: this.props.header.open || false,
  }

  updateOpen = (open) => {
    this.setState({ open });
  };

  render() {
    const {
      url, match, program_title_multilang, title_multilang, trackMultilang, tags, header, image, channel, slug, updateRadio,
    } = this.props;
    const { open } = this.state;
    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const hasParentheses = image.includes(')');
          return (
            <div className={`sm:w-full w-full px-2 sm:px-0 ${channel !== 3 && 'sm:border-b-1 sm:border-grey'}`}>
              <RadioHeader1 {...header} programTitle={program_title_multilang} channel={`ch${channel}`} open={open} toggleCallback={this.updateOpen} />
              <div className={`relative ${open ? 'sm:block' : 'sm:hidden'}`}>
                {hasParentheses
                  ? (
                    <div className="relative launcherGradientContainer h-48 overflow-hidden">
                      {channel !== 3
                        ? <img src={imageModifier(image, '890x520')} alt="" />

                        : <img src={image} alt="" />

                    }
                    </div>
                  )
                  : (
                    <div
                      className="relative launcherGradientContainer h-48"
                      style={{
                        backgroundImage: channel !== 3 ? `url(${imageModifier(image, '890x520')})` : `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }}
                    />
                  )

              }
                <div className="absolute pin-b pin-x sm:p-2 md:p-2 p-5">
                  <h2 className="font-americaBlack text-xl text-white">
                    <Link to={url} localizationpath={localization.path}>
                      <span className="text-white">{trackMultilang ? translateObj(trackMultilang, localization.language) : translateObj(title_multilang, localization.language)}</span>
                    </Link>
                  </h2>
                  <h3 className="font-americaBlack text-sm text-white">
                    { title_multilang ? translateObj(title_multilang, localization.language) : translateObj(program_title_multilang, localization.language)}
                  </h3>
                  {tags.length ? <div><ComponentIterator components={tags} /></div> : null }
                </div>
              </div>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

RadioTunerCard.propTypes = {
  match: PropTypes.shape().isRequired,
  header: PropTypes.shape().isRequired,
  image: PropTypes.string.isRequired,
  trackMultilang: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.arrayOf(PropTypes.string),
};

RadioTunerCard.defaultProps = {
  tags: [],
  trackMultilang: null,
};

export default withRouter(withRadioPlayerContext(RadioTunerCard));
