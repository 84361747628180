/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Formsy from 'formsy-react';
import {
  TweenMax, TimelineMax, Power3, Power1,
} from 'gsap';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';

import postNewsletterForm from '../../api/graphql/mutations/postNewsletterForm';

import FormNewsletterInput from './Form/FormNewsletterInput';

const params = {
  title: {
    es: 'Suscríbete a nuestro newsletter',
    en: 'Subscribe to our newsletter',
    ca: 'Subscriu-te a la nostra newsletter',
    pt: 'Subscrever a nossa newsletter',
  },
  text: {
    es: 'enviar',
    en: 'send',
    ca: 'enviar',
    pt: 'enviar',
  },
  placeholder: {
    es: 'Introduce aquí tu email',
    en: 'Enter your email here',
    ca: 'Introdueix el teu email',
    pt: 'Introduzir e-mail',
  },
};
class NewsletterSubscribeWidgetReusable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      didSubmit: false,
      error: false,
      isValidStatus: '',
    };

    this.childrenComponent = null;
    // this.buttonTween = new TimelineMax();
    // this.buttonLabelTween = new TimelineMax();
  }

  submitForm = (formData) => {
    const { scopes, match: { params: { lang, section, post } } } = this.props;
    const scopeList = scopes[lang] && scopes[lang].length ? scopes[lang] : scopes.en;
    let origin = post;
    if (!post) {
      origin = section;
    }
    const formDataWithLang = {
      ...formData,
      language: lang,
      scopes: scopeList,
      origin,
    };

    this.setState({ canSubmit: false, didSubmit: true });
    // this.animateButton();

    postNewsletterForm({ ...formDataWithLang }).then((response) => {
      if (!response.isValid) {
        this.setState({
          error: true,
          isValidStatus: 'error',
        });
      } else {
        this.setState({
          isValidStatus: 'valid',
        });
      }
    });
  };

  // animateButton = () => {
  //   this.buttonLabelTween.stop();
  //   this.buttonTween
  //     .to(this.submitButton, 0.35, { x: -80, opacity: 0.3, ease: Power3.easeOut })
  //     .to(this.submitButton, 0.35, { x: -40, opacity: 0.0, ease: Power3.easeIn })
  //     .play();
  // };

  // animateButtonLabel = () => {
  //   TweenMax.to(this.submitButtonLabel, 0, { opacity: 1 });
  //   this.buttonLabelTween
  //     .to(this.submitButtonLabel, 0.4, { opacity: 0, ease: Power1.easeIn })
  //     .to(this.submitButtonLabel, 0.4, { opacity: 1, ease: Power1.easeOut })
  //     .repeat(-1)
  //     .play();
  // };

  // enableButton = () => {
  //   this.setState({ canSubmit: true });
  //   this.buttonLabelTween.stop();
  //   TweenMax.to(this.submitButtonLabel, 0, { opacity: 1 });
  //   this.animateButtonLabel();
  // };

  // disableButton = () => {
  //   this.setState({ canSubmit: false });
  //   this.buttonLabelTween.stop();
  //   TweenMax.to(this.submitButtonLabel, 0, { opacity: 1 });
  // };

  render() {
    const {
      match, name, successNow, category, title, isLauncherItem,
    } = this.props;
    const { text = null, placeholder } = params;
    const {
      canSubmit, didSubmit, submitMessage, error, isValidStatus,
    } = this.state;

    const customTitle = title || params.title;

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          const inputStyle = `py-2 px-4 md:py-1 md:px-2 ${isLauncherItem ? 'h-10' : 'h-50px'} md:h-8 md:w-24 md:text-xxs w-123px sm:text-right sm:w-110px sm:flex sm:justify-around sm:items-baseline xl:block`;
          const definedLangPlaceholder = translateObj(placeholder, localization.language);
          // if (!placeholder[localization.language]) { definedLangPlaceholder = placeholder.en; } else { definedLangPlaceholder = placeholder[localization.language]; }

          return (
            <div className="w-full">
              <Formsy
                ref={(c) => {
                  this.form = c;
                  return this.form;
                }}
                name={name}
                onValidSubmit={this.submitForm}
                onValid={this.enableButton}
                onInvalid={this.disableButton}
                className={`my-4 w-full ${!isLauncherItem ? `bg-${category} p-4` : ''}`}
              >
                <div data-name="input-text-button" className="text-left">
                  {translateObj(title, localization.language) && <h2 className="text-black text-xs font-americaMonoRegular uppercase mb-4 w-full">{translateObj(title, localization.language)}</h2>}
                  <div className="relative">
                    <FormNewsletterInput
                      ref={(c) => {
                        this.email = c;
                        return this.email;
                      }}
                      name="email"
                      placeholder={!didSubmit ? definedLangPlaceholder : ''}
                      didSubmit={didSubmit}
                      validations={{ isEmail: true, maxLength: 50 }}
                      validationErrors=""
                      language="es"
                      error={error}
                      successNow={submitMessage}
                      isValidStatus={isValidStatus}
                      isLauncherItem={isLauncherItem}
                      required
                    />
                    {/* {!didSubmit && ( */}
                    <button
                      type="submit"
                      ref={(c) => {
                        this.submitButton = c;
                        return this.submitButton;
                      }}
                      disabled={!canSubmit}
                      className={`absolute pin-r pin-t pin-b leading-normal font-americaMonoBold text-xs rounded-full border-2 ${isLauncherItem ? 'border-white text-white' : 'border-black text-black'} uppercase ${inputStyle}`}
                      style={{ outline: 'none' }}
                    >
                      <span
                        className="pl-2"
                        ref={(c) => {
                          this.submitButtonLabel = c;
                          return this.submitButtonLabel;
                        }}
                      >
                        {translateObj(text, localization.language)}
                      </span>
                      <span className={`font-icon icon-arrow-right ${isLauncherItem ? 'text-white' : 'text-black'} text-xxs pl-3 pr-1 sm:pr-0`} />
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </Formsy>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}

NewsletterSubscribeWidgetReusable.propTypes = {
  match: PropTypes.shape().isRequired,
  name: PropTypes.string,
  scopes: PropTypes.shape(),
  title: PropTypes.shape(),
  successNow: PropTypes.shape(),
  category: PropTypes.string,
  isLauncherItem: PropTypes.bool,
};

NewsletterSubscribeWidgetReusable.defaultProps = {
  scopes: {
    en: ['scope_rockdelux'], es: [], ca: [], pt: [],
  },
  name: 'newsletter',
  successNow: null,
  category: 'white',
  isLauncherItem: false,
  title: null,
};

export default withRouter(NewsletterSubscribeWidgetReusable);
