import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import Formsy from 'formsy-react';

import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';

import postContactForm from '../../api/graphql/mutations/postContactForm';
import FormInput from './Form/FormInput';
import FormCheckbox from './Form/FormCheckbox';
import FormTextarea from './Form/FormTextarea';
import translateObj from '../../util/translateObj';

const recaptchaRef = React.createRef();

class ContactForm extends Component {
  state = {
    canSubmit: false,
    didSubmit: false,
    events: 'rockdelux',
    captchaValue: false,
  };

  componentDidMount = () => {
  }

  // onHandleCollapsibleMenuSelect = (value) => {
  //   this.setState({ events: value, showForm: true });
  // }

  submitForm = (formData) => {
    // recaptchaRef.current.execute();
    // const recaptchaValue = recaptchaRef.current.getValue();
    // console.log(recaptchaValue, 'recaptcha value');

    const { events } = this.state;
    this.setState({ canSubmit: false });
    const data = { ...formData, events };

    postContactForm({ ...data }).then((response) => {
      if (response) {
        if (response.isValid) {
          this.onReset();
          this.setState({
            didSubmit: true,
          });
        } else {
          console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm validated, but returned isValid = false!`);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Error - submitForm failed to submit!`);
      }
    });
  };

  onChange = (captchaValue) => {
    this.setState({ captchaValue: true });
  }

  onReset = () => {
    this.form.reset();
    this.firstName.setValue('');
    this.lastName.setValue('');
    this.email.setValue('');
    this.subject.setValue('');
    this.message.setValue('');
    this.conditions.setValue(false);
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  render() {
    const {
      match,
      title,
      fields,
      validation,
      text,
      conditions,
      submitButtonText,
      submitMessage,
    } = this.props;

    const {
      canSubmit, didSubmit, captchaValue, showForm,
    } = this.state;

    return (
      <RoutesContext.Consumer>
        {
          ({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            return (
              <div className="w-full">
                <div className="mt-24 laptop:mt-38 desktop:mt-36 grid grid-cols-4 tablet:grid-cols-12 gap-3 laptop:gap-4 px-4 tablet:px-11 laptop:px-90px -mx-4">
                  <div className="col-start-1 col-end-7 tablet:col-start-2 tablet:col-end-12 laptop:col-start-3 laptop:col-end-11">
                    <div className="font-stratos text-red font-bold uppercase text-26 py-6 border-b border-black mb-7">{translateObj(title, localization.language)}</div>
                    <Formsy ref={(c) => { this.form = c; return this.form; }} onValidSubmit={this.submitForm} onValid={this.enableButton} onInvalid={this.disableButton} className="flex flex-col">
                      <div className="flex flex-col laptop:flex-row justify-between">
                        <FormInput
                          ref={(c) => { this.firstName = c; return this.firstName; }}
                          name="firstName"
                          title={translateObj(fields.firstName, localization.language)}
                          validations={{ isSpecialWords: true, maxLength: 50 }}
                          validationErrors={{ isSpecialWords: translateObj(validation.inValidName, localization.language), maxLength: translateObj(validation.longName, localization.language) }}
                          required
                          className="w-full laptop:mr-2"
                        />
                        <FormInput
                          ref={(c) => { this.lastName = c; return this.lastName; }}
                          name="lastName"
                          title={translateObj(fields.lastName, localization.language)}
                          validations={{ isSpecialWords: true, maxLength: 50 }}
                          validationErrors={{ isSpecialWords: translateObj(validation.inValidName, localization.language), maxLength: translateObj(validation.longName, localization.language) }}
                          required
                          className="w-full laptop:ml-2"
                        />
                      </div>
                      <div className="flex flex-col laptop:flex-row justify-between mb-5">
                        <FormInput
                          ref={(c) => { this.email = c; return this.email; }}
                          name="email"
                          title={translateObj(fields.email, localization.language)}
                          validations={{ isEmail: true, maxLength: 50 }}
                          validationErrors={{ isEmail: translateObj(validation.inValidEmail, localization.language), maxLength: translateObj(validation.longEmail, localization.language) }}
                          required
                          className="w-full laptop:mr-2"
                        />
                        <FormInput
                          ref={(c) => { this.subject = c; return this.subject; }}
                          name="subject"
                          title={translateObj(fields.subject, localization.language)}
                          validations={{ maxLength: 250 }}
                          validationError={validation.longSubject[localization.language]}
                          required
                          className="w-full laptop:ml-2"
                        />
                      </div>
                      <FormTextarea
                        ref={(c) => { this.message = c; return this.message; }}
                        name="message"
                        title={translateObj(fields.message, localization.language)}
                        validations={{ maxLength: 500 }}
                        validationError={validation.longMessage[localization.language]}
                        required
                        isContactForm
                      />
                      { !didSubmit && (
                      <>
                        <div className="grid grid-cols-4 tablet:grid-cols-10 laptop:grid-cols-8 gap-3 laptop:gap-4 mt-4">
                          <div className="font-stratos text-14 text-black font-semilight col-start-1 col-end-4 tablet:col-end-7 laptop:col-end-6 row-start-1 -mt-4 tablet:mt-0">
                            <FormCheckbox
                              ref={(c) => { this.conditions = c; return this.conditions; }}
                              name="conditions"
                              title={translateObj(conditions, localization.language)}
                              validations="isTrue"
                              validationError=""
                            />
                          </div>
                          <div className="col-start-1 col-end-5 tablet:col-start-2 tablet:col-end-11 laptop:col-start-1 laptop:col-end-6 row-start-3 tablet:row-start-2">
                            <p style={{ marginTop: 0 }} className="font-stratos text-14 text-grey font-semilight laptop:pl-14 pt-6 laptop:pt-0">
                              {translateObj(text, localization.language)}
                            </p>
                          </div>
                          {/* <ReCAPTCHA
                            ref={recaptchaRef}
                            onChange={this.onChange}
                            sitekey="6LeVsbsUAAAAAI8Crd2wQ8eNINyoLzAT0uM01KKe"
                          /> */}
                          <div className="col-start-3 col-end-5 tablet:col-start-8 tablet:col-end-11 laptop:col-start-7 laptop:col-end-9 row-start-2 tablet:row-start-1 mt-6 tablet:mt-0">
                            <button type="submit" disabled={!canSubmit} className={`${canSubmit ? 'border-4 border-red text-red hover:text-white hover:bg-red' : 'border-4 border-grey text-grey'} bg-white h-10 w-full py-1 font-stratos text-16 font-bold`}>{translateObj(submitButtonText, localization.language)}</button>
                          </div>
                        </div>
                      </>
                      )}
                      { didSubmit && (
                      <div className="font-stratos">
                        <h3 className="text-green text-sm">{translateObj(submitMessage, localization.language)}</h3>
                      </div>
                      ) }
                      <div className="m-4 flex items-center ml-auto" />
                    </Formsy>
                  </div>
                </div>
              </div>
            );
          }
        }
      </RoutesContext.Consumer>
    );
  }
}

ContactForm.propTypes = {
  match: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  validation: PropTypes.shape().isRequired,
  text: PropTypes.shape().isRequired,
  conditions: PropTypes.shape().isRequired,
  submitButtonText: PropTypes.shape().isRequired,
  submitMessage: PropTypes.shape().isRequired,
};

ContactForm.defaultProps = {
};

export default withRouter(ContactForm);
